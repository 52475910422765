import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {UserState, SetUserActionPayloadType} from './types';

const initialState:UserState = {
    user: undefined,
    permissions: undefined,
    partnership: false,
    companies: [],
};

const convertDtoMapping = {
    login_allowed: 'loginAllowed',
    admin_read: 'adminRead',
    admin_write: 'adminWrite',
    admin_delete: 'adminDelete',
    policy_unrestricted: 'policyUnrestricted',
    submission_write: 'submissionWrite',
}

const convertUserPermissionsDTO = (permissions: Array<string>) => {
    return permissions.map(permission => convertDtoMapping[permission as keyof typeof convertDtoMapping])
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<SetUserActionPayloadType>) {
            state.user = action.payload.user_dump;
            state.permissions = convertUserPermissionsDTO(action.payload.permissions);
            state.partnership = action.payload.partnership;
        },
        setCompanies(state, action) {
            state.companies = action.payload;
        },
    },
});

export const {
    setUser,
    setCompanies,
} = userSlice.actions;

export default userSlice.reducer;
