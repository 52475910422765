import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';


const Loading = ({ size = '40px', color = 'primary' }) => {
    return (
        <Box position="absolute" height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress style={{ height: size, width: size }} color={color} />
        </Box>
    )
}

export default Loading;
