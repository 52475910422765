import React from 'react';
import { useSelector } from 'react-redux';
import { StyledTable, StyledTypography, StyledDivider, StyledCard } from 'components';
import { useBreakpoint } from 'hooks';
import accounting from 'accounting';
import { QuoteDetails } from 'components/quote';
import { Coverages } from 'components/policy';
import { Documents } from 'components/option-to-quote';
import { getIsAnnualPolicy } from 'stores/selectors';
import { displayClaimLimit } from 'utils/policyRequest';
import {
    Box,
    Hidden,
    Table,
    TableHead,
    TableBody,
    TableRow,
    Typography,
    CircularProgress,
    Icon,
} from '@material-ui/core';
import CommissionAndPolicyTotal from './commission-and-policy-total-bind/CommissionAndPolicyTotal';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const QuoteReview = ({ readOnly, premiumIsLoading }) => {
    const isSm = useBreakpoint('down', 'sm');
    const isMd = useBreakpoint('down', 'md');
    const { optionToQuote } = useSelector(state => state.company);
    const { policy_request: policyRequest } = optionToQuote.quote;
    const isAnnualPolicy = getIsAnnualPolicy(optionToQuote.quote);

    return (
        <StyledCard.Partial>
            <Hidden mdDown>
                <Box mb={3} fontWeight="fontWeightLight">
                    <Typography variant="subtitle2">
                        {readOnly ? 'Summary' : `01 Review ${policyRequest.product.name} quote option`}
                    </Typography>
                </Box>
            </Hidden>
            <Hidden mdUp>
                <Box mb={1}>
                    <Box display="flex" alignItems="center" pb={0.8}>
                        <StyledTypography.FadedTypography variant="overline" component="span">
                            {isAnnualPolicy ? 'TOTAL' : 'PRO-RATED TOTAL'}
                        </StyledTypography.FadedTypography>
                        {/* keeping this for when the button should show a tooltip */}
                        <Box
                            component={props => <StyledTypography.FadedTypography variant="body1" {...props} />}
                            display="flex"
                            alignItems="center">
                            <InfoOutlinedIcon />
                        </Box>
                    </Box>
                    <Box fontWeight="fontWeightLight">
                        <Typography variant="h5">
                            {premiumIsLoading ? (
                                <CircularProgress color="primary" size={24} />
                            ) : (
                                <>
                                    ${accounting.formatNumber(optionToQuote.quote.original_total_premium)}
                                    {!isAnnualPolicy && '*'}
                                </>
                            )}
                        </Typography>
                    </Box>
                </Box>
            </Hidden>
            <Table>
                <TableHead>
                    <TableRow>
                        <Hidden smDown>
                            <Box component={props => <StyledTable.TableCell {...props} padding="none" />} width="40%">
                                <Box pl={isMd ? 0 : 7} display="flex" alignItems="center">
                                    <StyledTypography.FadedTypography variant="overline">
                                        {isAnnualPolicy ? 'TOTAL' : 'PRO-RATED TOTAL'}
                                    </StyledTypography.FadedTypography>
                                    {/* keeping this for when the button should show a tooltip */}
                                    <Box
                                        component={props => (
                                            <StyledTypography.FadedTypography variant="body1" {...props} />
                                        )}
                                        display="flex"
                                        alignItems="center">
                                        <InfoOutlinedIcon />
                                    </Box>
                                </Box>
                            </Box>
                            <Box component={props => <StyledTable.TableCell {...props} padding="none" />} width="35%">
                                <Box component={StyledTypography.FadedTypography} variant="overline">
                                    AG.{displayClaimLimit(policyRequest, optionToQuote) ? ' / CLAIMS' : ''} LIMIT
                                </Box>
                            </Box>
                            <StyledTable.TableCell padding="none">
                                <Box component={StyledTypography.FadedTypography} variant="overline">
                                    RETENTION
                                </Box>
                            </StyledTable.TableCell>
                        </Hidden>
                        <Hidden mdUp>
                            <StyledTable.TableCell padding="none">
                                <StyledTypography.FadedTypography variant="overline">
                                    AG.{displayClaimLimit(policyRequest, optionToQuote) ? ' / CLAIMS' : ''} LIMIT
                                </StyledTypography.FadedTypography>
                            </StyledTable.TableCell>
                            <StyledTable.TableCell padding="none">
                                <StyledTypography.FadedTypography variant="overline">
                                    RETENTION
                                </StyledTypography.FadedTypography>
                            </StyledTable.TableCell>
                        </Hidden>
                    </TableRow>
                </TableHead>
                <TableBody pb={1}>
                    <QuoteDetails
                        optionToQuote={optionToQuote}
                        premiumIsLoading={premiumIsLoading}
                        policyRequest={policyRequest}
                    />
                    <TableRow
                        style={{
                            borderBottom: `4px solid ${policyRequest.product.background_color}`,
                        }}>
                        <StyledTable.TableCell colSpan={isSm ? 2 : 3} padding="none">
                            <Box pt={2} />
                        </StyledTable.TableCell>
                    </TableRow>
                    <Coverages readOnly={readOnly} />
                    <TableRow>
                        <StyledTable.TableCell colSpan={3} padding="none">
                            <Box px={[0, 0, 5, 7]} my={2}>
                                <StyledDivider.ExpandableRowDivider />
                            </Box>
                        </StyledTable.TableCell>
                    </TableRow>
                    <Documents optionToQuote={optionToQuote} readOnly={readOnly} policyRequest={policyRequest} />
                    <TableRow>
                        <StyledTable.TableCell colSpan={3} padding="none">
                            <Box px={[0, 0, 5, 7]} my={2}>
                                <StyledDivider.ExpandableRowDivider />
                            </Box>
                        </StyledTable.TableCell>
                    </TableRow>
                    <CommissionAndPolicyTotal policyRequest={policyRequest} readOnly={readOnly} />
                </TableBody>
            </Table>
        </StyledCard.Partial>
    );
};

export default QuoteReview;
