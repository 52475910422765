import React from 'react';
import { useHistory } from 'react-router-dom';
import { useBreakpoint } from 'hooks';
import { totalHumanize } from 'utils';
import Styled from './styled-components';
import {
    RoundedButton,
    StyledTypography,
    StyledAction,
    Icon,
    IconBox,
} from 'components';
import { ReactComponent as ClipboardCheckIcon } from 'components/icons/clipboard-check.svg'
import CloseIcon from '@material-ui/icons/Close';
import {
    Box,
    IconButton,
    Typography,
} from '@material-ui/core';


const BindRequestedPopup = ({ optionToQuote, open, handleClose }) => {
    const history = useHistory();
    const isSm = useBreakpoint('down', 'sm');
    const { quote } = optionToQuote;
    const { policy_request: policyRequest } = quote;

    const navigateToClientDetails = () => {
        handleClose();
        history.push(`/company/${policyRequest.company_id}`);
    }

    const navigateToPolicyDetails = () => {
        handleClose();
        history.push(`/submission/policy_details/${optionToQuote.id}`);
    }


    return (
        <Styled.Dialog
            open={open}
            onClose={navigateToClientDetails}
            fullWidth
        >
            <Styled.DialogTitle>
                <Box width="100%" display="flex" justifyContent="space-between" fontWeight="fontWeightBold" alignItems="flex-start">
                    <Box>
                        <Typography variant="h6">
                            Bind Requested
                        </Typography>
                        <StyledTypography.FadedTypography variant="h6">
                            ${totalHumanize(quote.aggregate_limit)} {policyRequest.product.name}
                        </StyledTypography.FadedTypography>
                    </Box>
                    <IconButton onClick={navigateToClientDetails} data-testid="close-popup">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Styled.DialogTitle>
            <Styled.DialogContent>
                <Box px={[0, 0, 4]} my={[0, 0, 2]} mb={[5, 5, 10]}>
                    <IconBox
                        sizes={['48px', '48px', '56px']}
                        bgcolor={policyRequest.product.background_color}
                        color={policyRequest.product.text_color}
                        borderRadius="50%"
                        mr={2}>
                        <Icon
                            icon={<ClipboardCheckIcon />}
                            typographyProps={{ variant: 'h5' }}
                        />
                    </IconBox>
                    <Box borderBottom={`4px solid ${policyRequest.product.background_color}`} my={2} />
                    <Box mb={1} fontWeight="fontWeightBold">
                        <Typography variant="h6">
                            {policyRequest.company_name}
                        </Typography>
                    </Box>
                    <Box fontWeight="fontWeightLight">
                        <Typography variant="body1">
                            Thank you! An underwriter will review your request. In the meantime, you can review and upload documentation to support any contingencies.
                        </Typography>
                    </Box>
                </Box>
            </Styled.DialogContent>
            <Box component={Styled.DialogActions} display="flex" justifyContent={['space-between', 'space-between', 'flex-end']}>
                <Box component={StyledAction.Base} onClick={navigateToClientDetails} mr={2} fontWeight="fontWeightBold">
                    <Typography color="primary" variant="subtitle2">
                        {isSm ? 'Acct' : 'Account'} Details
                    </Typography>
                </Box>
                <RoundedButton onClick={navigateToPolicyDetails} color="primary" py={0.3}>
                    <Typography variant="subtitle2">
                        Review {isSm ? '': 'Contingencies'}
                    </Typography>
                </RoundedButton>
            </Box>
        </Styled.Dialog>
    );
}


export default BindRequestedPopup;
