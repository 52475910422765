import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const NoFocus = styled(Box)`
    &&& {
        &:focus {
            outline: none;
        }
    }
`;

const FadedBox = styled(Box)`
    &&& {
        opacity: 0.54;
        &:hover {
            color: ${({ theme }) => theme.palette.primary.main};
            opacity: 1;
        }
    }
`;

const ScrollXBox = styled(Box)`
    &&& {
        ::-webkit-scrollbar {
            display: none;
        }
        /* firefox and IE */
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
`;

const MobilePolicyAndCommissionBox = styled(Box)`
    &&& {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 8;
    }
`;

export default {
    NoFocus,
    FadedBox,
    ScrollXBox,
    MobilePolicyAndCommissionBox,
};
