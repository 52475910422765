import React from 'react';
import { useBreakpoint } from 'hooks';
import { Box, Typography } from '@material-ui/core';
import { DynamicIcon, IconBox } from 'components';
import { NodeType, ValueType } from 'utils/enums';
import FreeTextQuestion from './FreeTextQuestion';
import SingleChoiceQuestion from './SingleChoiceQuestion';
import MultipleChoicesQuestion from './MultipleChoicesQuestion';
import NumberQuestion from './NumberQuestion';
import BooleanQuestion from './BooleanQuestion';
import DomainQuestion from './DomainQuestion';
import AddressQuestion from './AddressQuestion';
import Note from './Note';
import CompanyNameQuestion from './CompanyNameQuestion';

const QuestionsSection = ({
    section,
    questions,
    background_color,
    text_color,
    updateForm,
    policy_request_id,
    width,
    submitFailed,
    setScroll,
    scroll,
    setEditAnswerMode,
    setSubmissionDisabled,
    setCheckTriedToSubmit,
    reSubmitted,
    setChangeMade,
}) => {
    const isSm = useBreakpoint('down', 'sm');
    const isLg = useBreakpoint('up', 'lg');
    const valueTypeToComponent = {
        [ValueType.SINGLE_CHOICE]: SingleChoiceQuestion,
        [ValueType.MULTIPLE_CHOICES_WITHOUT_NONE]: MultipleChoicesQuestion,
        [ValueType.MULTIPLE_CHOICES_WITH_NONE]: MultipleChoicesQuestion,
        [ValueType.NUMBER]: NumberQuestion,
        [ValueType.INTEGER]: NumberQuestion,
        [ValueType.FREE_TEXT]: FreeTextQuestion,
        [ValueType.ADDRESS]: AddressQuestion,
        [ValueType.DOMAIN]: DomainQuestion,
        [ValueType.BOOLEAN]: BooleanQuestion,
        [ValueType.COMPANY_NAME]: CompanyNameQuestion,
    }

    const getQuestionByType = (question, idx) => {
        if (question.type === NodeType.NOTE) {
            return <Note key={`${question.datapoint.id}_note`} question={question}/>
        }

        // Q2134 is a boolean question that handled separately in the domain question
        if (question.type === ValueType.BOOLEAN && question.datapoint.datapoint_id === 'Q2134') {
            return undefined;
        }

        const Question = valueTypeToComponent[question.type];

        return (
            <Question
                updateForm={updateForm}
                question={question}
                key={`${question.datapoint.id}_question`}
                policy_request_id={policy_request_id}
                firstInSection={idx === 0}
                submitFailed={submitFailed}
                setScroll={setScroll}
                scroll={scroll}
                setEditAnswerMode={setEditAnswerMode}
                setSubmissionDisabled={setSubmissionDisabled}
                setCheckTriedToSubmit={setCheckTriedToSubmit}
                reSubmitted={reSubmitted}
                setChangeMade={setChangeMade}
            />
        )
    };

    return (
        <Box display="flex" flexDirection="column" width={width}>
            <Box display="flex" pl={isLg ? 5 : isSm ? 4 : 0} pt={5} justifyContent={isSm || isLg ? 'flex-start' : 'center'}>
                {!isSm && (
                    <IconBox
                        bgcolor={background_color}
                        color={text_color}
                        borderRadius="50%"
                        mr={5}
                        width="56px"
                        height="56px">
                        <DynamicIcon name={section.icon} typographyProps={{ variant: 'h5' }} />
                    </IconBox>
                )}
                <Box alignSelf="center" fontWeight="fontWeightBold" width={isSm ? 'unset' : '424px'} textAlign="left" pr={isLg ? 0 : isSm ? 0 : 12}>
                    <Typography variant="h6">{section.title}</Typography>
                </Box>
            </Box>
            <Box pt={2} pb={isSm ? 2 : 6.5}>
                {questions.map((question, idx) => getQuestionByType(question, idx))}
            </Box>
        </Box>
    );
};

export default QuestionsSection;
