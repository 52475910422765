import React, { useState } from 'react';
import { RoundedButton } from 'components';
import { TableRow, Typography, Box, Hidden } from '@material-ui/core';
import { useBreakpoint } from 'hooks';
import Styled from 'components/table/styled-components';
import { InnerDataCellWithoutOption } from 'components/table/SharedComponents';
import {
    PermissionDenied,
    PermissionGranted,
    PermissionRequired
} from 'components/permission-required';
import { Permissions } from 'utils/enums';

const InnerTableInactiveBucket = ({ collapseData, handleButton }) => {
    const isSm = useBreakpoint('down', 'sm');
    const [disabled, setDisabled] = useState(false);
    const handleOnClick = () => {
        setDisabled(true);
        handleButton();
    };

    const RoundedButtonComponent = () => {
        return (
            <RoundedButton py={isSm ? 0.5 : 0.25} fullWidth={isSm} onClick={handleOnClick} disabled={disabled}>
                <PermissionRequired requiredPermissions={[Permissions.SUBMISSION_WRITE]}>
                    <PermissionGranted>
                        <Typography variant="subtitle2">Update Application</Typography>
                    </PermissionGranted>
                    <PermissionDenied>
                        <Typography variant="subtitle2">View Application</Typography>
                    </PermissionDenied>
                </PermissionRequired>
            </RoundedButton>
        );
    };

    return (
        <>
            <Hidden mdUp>
                <TableRow>
                    <InnerDataCellWithoutOption collapseData={collapseData} />
                </TableRow>
                <TableRow>
                    <Styled.TableCell className="inner black" align="center" width="100%">
                        <Box pt={1.5}>
                            <RoundedButtonComponent />
                        </Box>
                    </Styled.TableCell>
                </TableRow>
            </Hidden>
            <Hidden smDown>
                <TableRow>
                    <InnerDataCellWithoutOption collapseData={collapseData} />
                    <Styled.TableCell className="inner alignEnd button black">
                        <RoundedButtonComponent />
                    </Styled.TableCell>
                </TableRow>
            </Hidden>
        </>
    );
};

export default InnerTableInactiveBucket;
