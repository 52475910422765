import React, {useEffect, useState} from 'react';
import {useAxiosClient, useEnv} from 'hooks';
import Box from '@material-ui/core/Box';
import {makeToast} from 'utils';
import Styled from './styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import {CircularProgress} from '@material-ui/core';


const ExportAll = () => {
    const axiosClient = useAxiosClient();
    const [questionnaireList, setQuestionnaireList] = useState([]);
    const [downloadQuestionsLoading, setDownloadQuestionsLoading] = useState(false);

    const [{}, getQuestionnaireJson] = axiosClient({
        method: 'get',
        responseType: 'blob'
    }, {manual: true});

    const [{}, getQuestions] = axiosClient({
        method: 'get',
        baseURL: '/svc/api/gui/v1',
        url: '/questionnaire_builder/datapoint/create',
        responseType: 'blob'
    }, {manual: true});

    const [{data, error}, getQuestionnaires] = axiosClient({
        method: 'get',
        url: '/questionnaire/'
    }, {manual: true});

    useEffect(() => {
        if (data) {
            data.data.forEach((el) => {
                el.loading = false;
            })
            setQuestionnaireList(data.data);
        }

        if (error) {
            makeToast('error', 'Something went wrong.');
        }
    }, [data, error])

    const makeFileNameForDownload = (id, name) => {
        name = name.split(' ');
        name = name.join('_');
        return `questionnaire_${id}_${name}`;
    }

    const changeLoadingState = (idx, value) => {
        const copy = [...questionnaireList];
        if (copy[idx].loading !== value) {
            copy[idx].loading = value;
            setQuestionnaireList(copy);
        }

    }

    const downloadQuestionnaire = (idx) => {
        const obj = questionnaireList[idx];
        const fileName = makeFileNameForDownload(obj.id, obj.product_name)
        changeLoadingState(idx, true);

        getQuestionnaireJson({url: `/questionnaire/export/${obj.id}`})
            .then(data => {
                saveToFile(data.data, fileName);
                changeLoadingState(idx, false);
            }).catch(() => {
                makeToast('error', 'Something went wrong.');
                changeLoadingState(idx, false);
            });
    }

    const downloadQuestions = () => {
        setDownloadQuestionsLoading(true);
        getQuestions()
            .then((data) => {
                saveToFile(data.data, 'questions.json');
                setDownloadQuestionsLoading(false);
            }).catch(() => {
            makeToast('error', 'Something went wrong.');
            setDownloadQuestionsLoading(false);
        });
    }

    useEffect(() => {
        getQuestionnaires();
        return () => {
        }
    }, [])

    const saveToFile = (content, fileName) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(content);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    return <Box pt={12} component={Styled.Container}>
        <Box width="100%" display="flex" flexDirection="column" mb={2}>
            <Box width="100%" display="flex" justifyContent="space-between">
                <Styled.Typography variant="subtitle2" className="sticky">

                </Styled.Typography>
            </Box>
        </Box>
        <TableContainer>
            <Table stickyHeader aria-label="sticky table" mb={12}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Styled.Typography variant="subtitle2" className="sticky">
                                Download Questions
                            </Styled.Typography>
                        </TableCell>
                        <TableCell>
                            <Button variant="contained" onClick={() => downloadQuestions()} size="small"
                                    style={{'minWidth': '95px'}}>
                                {downloadQuestionsLoading &&
                                <CircularProgress style={{height: 15, width: 15}} />}
                                {!downloadQuestionsLoading && <>Download</>}
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Styled.Typography variant="subtitle2" className="sticky">
                                Questionnaires
                            </Styled.Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {questionnaireList.map((row, idx) =>
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                            <TableCell>
                                <Styled.Typography variant="subtitle2" component="div" className="title">
                                    {row.product_name}
                                </Styled.Typography>

                                <Styled.Typography variant="subtitle2" component="div" className="subtitle">
                                    {row.product_name_internal}
                                </Styled.Typography>
                            </TableCell>
                            <TableCell>
                                <Button variant="contained" size="small" style={{'minWidth': '95px'}}
                                        onClick={() => downloadQuestionnaire(idx)}>
                                    {row.loading && <CircularProgress style={{height: 10, width: 10}} />}
                                    {!row.loading && <>Download</>}
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}

                </TableBody>

                {/*<TableHead>*/}
                {/*    <TableRow>*/}
                {/*        <TableCell>*/}
                {/*            <Styled.Typography variant="subtitle2" className="sticky">*/}
                {/*                Download Questions & All Questionnaires*/}
                {/*            </Styled.Typography>*/}
                {/*        </TableCell>*/}
                {/*        <TableCell>*/}
                {/*        </TableCell>*/}
                {/*    </TableRow>*/}
                {/*</TableHead>*/}
            </Table>
        </TableContainer>
    </Box>;
};

export default ExportAll;
