import React from 'react';
import { Box, SvgIcon, Typography } from '@material-ui/core';

const Icon = ({ icon, TypographyComponent = Typography, typographyProps, svgIconStyle }) => {
    return (
        <Box component={props => <TypographyComponent {...typographyProps} {...props} />} display="flex">
            <SvgIcon style={svgIconStyle}>{icon}</SvgIcon>
        </Box>
    );
};

export default Icon;
