import styled from 'styled-components';
import { Card as _Card } from '@material-ui/core';


const Base = styled(_Card)`
    &&& {
        &.overflow-visible {
            overflow: visible;
        }
    }
`;

const BaseCard = styled(Base)`
    &&& {
        border-radius: 0;
        margin-bottom: 25px;
        box-shadow: none;
        ${({ theme }) => `
            padding-left: ${theme.spacing(4)}px;
            padding-right: ${theme.spacing(4)}px;
            padding-top: ${theme.spacing(4)}px;;
            padding-bottom: ${theme.spacing(1.5)}px;
            ${theme.breakpoints.down('md')} {
                padding-left: ${theme.spacing(7)}px;
                padding-right: ${theme.spacing(7)}px;
                padding-bottom: ${theme.spacing(2)}px;
            }
            ${theme.breakpoints.down('sm')} {
                padding-left: ${theme.spacing(4)}px;
                padding-right: ${theme.spacing(4)}px;
                padding-top: ${theme.spacing(3)}px;
                padding-bottom: ${theme.spacing(2)}px;
            }
        `}
    }
`;

const Card = styled(BaseCard)`
    &&& {
        color: #636363;
        box-shadow: 0 2px 40px rgba(214,215,219,.6);
    }
`;

const RegisterCard = styled(Card)`
    &&& {
        margin-top: 9px;
        padding-top: 12px;
        align-self: center;
        ${({ theme }) => `
            ${theme.breakpoints.down('md')} {
                padding-left: ${theme.spacing(4)}px;
                padding-right: ${theme.spacing(4)}px;
                padding-bottom: ${theme.spacing(1.5)}px;
            }
            ${theme.breakpoints.down('sm')} {
                width: 100%;
                max-width: unset;
                padding: 25px 0px 12px;
            }
        `}
    }
`;

const ResetCard = styled(Card)`
    &&& {
        padding-bottom: 32px;
        margin-top: 50px;
        width: 550px;
        align-self: center;
        ${({ theme }) => `
            ${theme.breakpoints.down('md')} {
                padding-left: ${theme.spacing(4)}px;
                padding-right: ${theme.spacing(4)}px;
                padding-top: ${theme.spacing(4)}px;;
                padding-bottom: ${theme.spacing(4)}px;
            }
            ${theme.breakpoints.down('sm')} {
                width: 100%;
                padding-left: 0px;
                padding-right: 0px;
            }
        `}
        &.small {
            margin-top: 0px;
            width: 370px;
            ${({ theme }) => `
                ${theme.breakpoints.down('sm')} {
                    width: 100%;
                    max-width: 370px;
                    padding-left: 0px;
                    padding-right: 0px;
                }
            `}
        }
    }
`;

const Shadowless = styled(Card)`
    &&& {
        color: unset;
        box-shadow: none;
    }
`;

const Partial = styled(Shadowless)`
    &&& {
        padding-bottom: 0;
        margin-bottom: 0;
        &.pt-0 {
            padding-top: 0;
        }
    }
`;

export default {
    Base,
    BaseCard,
    Card,
    RegisterCard,
    ResetCard,
    Shadowless,
    Partial,
};
