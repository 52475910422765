import accounting from 'accounting';
import _ from 'lodash';

//This function matches the total_humanize jinja2 template filter in cyberjack_core.utils.total_humanize
export const totalHumanize = (val, withSpace = false) => {
    var order = val > 0 ? Math.floor(Math.log10(val)) : 0,
        divisor, suffix, allowDecimal;
    if (order >= 9) {
        divisor = 1000000000;
        suffix = 'B';
        allowDecimal = true;
    } else if (order >= 6) {
        divisor = 1000000;
        suffix = 'M';
        allowDecimal = true;
    } else if (order >= 3) {
        divisor = 1000;
        suffix = 'K';
        allowDecimal = true;
    } else {
        divisor = 1;
        suffix = '';
        allowDecimal = false;
    }

    var normalized = val / divisor,
        /*
         # 1 decimal place if number is:
            a) greater than 1000
            b) the decimal is not zero e.g. is not 14.0
            c) there are 2 or less digits before the comma
         */
        numDecimalPlaces = (allowDecimal &&
            _.round(normalized, 1) % 1 !== 0 &&
            Math.floor(Math.log10(normalized)) < 2) ? 1 : 0;
    const joiner = withSpace ? ' ' : '';
    return `${accounting.formatNumber(normalized, numDecimalPlaces)}${joiner}${suffix}`;
}
