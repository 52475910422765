import styled from 'styled-components';
import { default as _TableHead } from '@material-ui/core/TableHead';
import { default as _TableCell } from '@material-ui/core/TableCell';
import { default as _TableRow } from '@material-ui/core/TableRow';
import { default as _TableContainer } from '@material-ui/core/TableContainer';
import { default as _Box } from '@material-ui/core/Box';
import { default as _Avatar } from '@material-ui/core/Avatar/Avatar';

const TableHead = styled(_TableHead)`
    &&& {
        background-color: #f2f2f2;
        align-items: center;
        text-transform: uppercase;
        ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightRegular};
            &.inner{
                background-color: unset;
            }
    `}
    }
`;
const TableCellHead = styled(_TableCell)`
    &&& {
        color: #919191;
        border: none;
        padding-left: 0;
        padding-right: 0;
        ${({ theme }) => `
            padding-top : ${theme.spacing(1.25)}px;
            padding-bottom : ${theme.spacing(1)}px;
        `}
        &.inner {
            color: #696969;
            padding-left: 0;
        }
        &.MuiTableCell-sizeSmall {
            padding-right: 0;
        }
    }
`;
const TableCell = styled(_TableCell)`
    &&& {
        border-bottom: none;
        padding: 0px 0px;
        ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightLight};
            ${theme.breakpoints.down('sm')} {
                border-bottom-width: 0px;
                max-height: 16px;
            }
        `}
        &.inner {
            border: none;
            padding: 0;
        }
        &.alignEnd {
            text-align: end;
        }
        &.button {
            padding-bottom: 8px;
            &.grey {
                height: 32px;
            }
        }
        &.black {
            color: #191919;
        }
        &.grey {
            color: #696969;
        }
        &.space {
            background-color: #f2f2f2;
            height: 4px;
        }
    }
`;

const TableRow = styled(_TableRow)`
    &&& {
        &.expanded {
            &:hover {
                cursor: pointer;
            }
        }
        & td {
            background: white;
        }
        & td:first-child {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
        }
        & td:last-child {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
        }
    }
`;

const TableContainer = styled(_TableContainer)`
    &&& {
        box-shadow: none;
    }
`;

const InformationBox = styled(_Box)`
    &&& {
        background-color: white;
        display: flex;
        color: #696969;
        ${({ theme }) => `
            ${theme.breakpoints.up('md')} {
                padding: 16px 32px;
            }
            ${theme.breakpoints.down('md')} {
                padding: 12px 32px;
            }
    `}
        > div > div {
            color: #cccccc;
        }

        &:hover,
        &:hover > div > div {
            color: #5489ff;
        }
    }
`;

const TagBox = styled(_Box)`
    &&& {
        background-color: #f2f2f2;
        &.green {
            background-color: rgba(4, 196, 38, 0.07);
            color: #04c426;
        }
        &.yellow {
            background-color: rgba(255, 255, 0, 0.15);
            color: #bda802;
        }
        &.blue {
            background-color: rgba(115, 170, 255, 0.07);
            color: #73aaff;
        }
        &.red {
            background-color: rgba(240, 42, 38, 0.07);
            color: #f02a26;
        }
        display: inline-block;
        text-transform: uppercase;
        ${({ theme }) => `
            padding-left : ${theme.spacing(0.5)}px;
            padding-right : ${theme.spacing(0.5)}px;
            font-weight: ${theme.typography.fontWeightRegular};
    `}
    }
`;

const SmBox = styled(_Box)`
    &&& {
        border-bottom: ${({ checked }) => (checked ? '2px solid #191919' : 'none')};
    }
`;

const Avatar = styled(_Avatar)`
    &&& {
        width: 25px;
        height: 25px;
        font-size: 10px;
        font-weight: bold;
    }
`;

export default {
    TableHead,
    TableCellHead,
    TableRow,
    TableContainer,
    TableCell,
    InformationBox,
    TagBox,
    SmBox,
    Avatar,
};
