import React, { useEffect, useState } from 'react';
import CustomForm from './CustomForm';
import CustomTextField from './CustomField';
import { NotificationBanner } from 'components/banner';
import { useRemoveMember } from 'api/manage-teams';


const RemoveMember = () => {
    const [teamId, setTeamId] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userEmailValidationError, setUserEmailValidationError] = useState('');
    const [teamIdValidationError, setTeamIdValidationError] = useState('');
    const [openNotification, setOpenNotification] = useState(false);
    const [{ data, loading, error }, removeMember] = useRemoveMember();

    useEffect(() => {
        if (!loading && data) {
            setOpenNotification(true);
            setUserEmailValidationError('');
            setTeamIdValidationError('');
            setTeamId('');
            setUserEmail('');
        } else if (error?.response.data.invalid_team) {
            setTeamIdValidationError(`invalid team - no team with id ${teamId}`);
        } else if (error?.response.data.invalid_email) {
            setUserEmailValidationError(error.response.data.invalid_email);
        }
    }, [data, error]);

    const submitRemoveMember = e => {
        e.preventDefault();
        removeMember({
            data: {
                team_id: teamId,
                user_email: userEmail,
            },
        });
    };

    return (
        <>
            <CustomForm
                handleSubmit={submitRemoveMember}
                header="Remove team member"
                buttonText="Remove team member"
                fieldsList={[
                    <CustomTextField
                        value={teamId}
                        setValue={setTeamId}
                        name="team id"
                        validationError={teamIdValidationError}
                        setValidationError={setTeamIdValidationError}
                        type="number"
                    />,
                    <CustomTextField
                        value={userEmail}
                        setValue={setUserEmail}
                        name="user email"
                        validationError={userEmailValidationError}
                        setValidationError={setUserEmailValidationError}
                        type="email"
                    />,
                ]}
            />
            <NotificationBanner
                openNotification={openNotification} alertText="Team member was removed."
                setOpenNotification={setOpenNotification} isError={false}
            />
        </>
    );
};

export default RemoveMember;
