import React from 'react';
import { StyledTypography } from 'components';


const Actions = () => {
    return (
        <StyledTypography.FadedTypography variant="subtitle2">
            View quotes to see coverage offerings / bind a policy.
        </StyledTypography.FadedTypography>
    )
}


export default Actions;
