import React from 'react';
import { StyledTypography } from 'components';

const Actions = () => {
    return (
        <StyledTypography.FadedTypography variant="subtitle2">
            Complete our online application for your At-Bay quote and risk analysis.
        </StyledTypography.FadedTypography>
    )
}


export default Actions;
