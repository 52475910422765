import React from 'react';
import accounting from 'accounting';
import Styled from '../styled-components';
import { useSelector } from 'react-redux';
import { Box, Typography, Hidden } from '@material-ui/core';
import RequestedReviewPopup from './components/RequestedReviewPopup';
import ResetButton from './components/ResetButton';
import { useDataTrackBase } from 'hooks/customization';

const Footer = ({ handleReset, handleCalculation, requestedReviewPopup }) => {
    const { customizationLoading, customizedOptionToQuote } = useSelector(
        state => state.company
    );

    const dataTrackBase = useDataTrackBase();

    const handleSaveQuote = event => {
        handleCalculation(event.target.value, null, null, true);
    };

    return (
        <Box
            width="100%"
            position="fixed"
            bottom="0"
            boxShadow="0px -6px 5px 0px rgba(0,0,0,0.10)"
            bgcolor="white"
        >
            <Hidden lgUp>
                <Box width="100%">
                    <Styled.BottomDottedLineBox>
                        <ResetButton handleReset={handleReset} />
                        <Box ml="auto" mb={0.3} fontWeight={500}>
                            <Typography variant="subtitle2">
                                {customizationLoading.loadingPremium ? (
                                    <Styled.CircularProgress
                                        color="primary"
                                        size={20}
                                    />
                                ) : customizationLoading.requiredReview ? (
                                    '*Requires Review'
                                ) : (
                                    <Box color="#5489FF" fontWeight="700">
                                        <Typography variant="h4">
                                            $
                                            {accounting.formatNumber(
                                                customizedOptionToQuote?.quote
                                                    .premium
                                            )}
                                        </Typography>
                                    </Box>
                                )}
                            </Typography>
                        </Box>
                    </Styled.BottomDottedLineBox>
                </Box>
            </Hidden>
            <Box
                display="flex"
                position="relative"
                alignItems="center"
                height="80px"
            >
                <Box mx="auto">
                    <Box
                        component={Styled.Button}
                        ml="auto"
                        onClick={handleSaveQuote}
                        disabled={customizationLoading.disableSaveQuote}
                        data-track={`${dataTrackBase}${
                            customizationLoading.requiredReview
                                ? '_request_quote'
                                : '_save_quote'
                        }`}
                    >
                        {customizationLoading.loadingSaveQuote ? (
                            <Styled.CircularProgressV2 size={20} />
                        ) : customizationLoading.requiredReview ? (
                            <Typography variant="subtitle2">
                                Request customized option
                            </Typography>
                        ) : (
                            <Typography variant="subtitle2">
                                Save customized option
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Hidden mdDown>
                    <ResetButton
                        handleReset={handleReset}
                        data-track={`${dataTrackBase}_reset_quote`}
                    />
                </Hidden>
            </Box>
            <Box>
                <RequestedReviewPopup
                    requestedReviewState={requestedReviewPopup}
                />
            </Box>
        </Box>
    );
};

export default Footer;
