import React from 'react';
import Styled from './styled-components';
import { NavLink, SearchBar } from 'components';

export default function DesktopNav({ guest, navigation, hideSearch=false }) {
    return (
        <Styled.DesktopNavList guest={guest}>
            {!hideSearch && <SearchBar />}
            {navigation.map((item, idx) => (
                <NavLink
                    handleMouseOver={item.handleMouseOver}
                    key={'d_nav' + idx}
                    idx={idx}
                    endpoint={item.path}
                    text={item.text}
                />
            ))}
        </Styled.DesktopNavList>
    );
}
