import React, { useMemo } from 'react';
import { LinearProgress } from '@material-ui/core';
import { withStyles, lighten } from '@material-ui/core/styles';


const _ColoredLinearProgress = ({ classes, ...props }) => {
    return <LinearProgress {...props} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}} />;
}

const ColoredLinearProgress = ({ color, ...props }) => {
    const ProgressBar = useMemo(() => {
        const styles = () => ({
            colorPrimary: {
                backgroundColor: lighten(color, 0.62),
            },
            barColorPrimary: {
                backgroundColor: color
            }
        });
        return withStyles(styles)(_ColoredLinearProgress);
    }, []);
    return <ProgressBar {...props} />
}


export default ColoredLinearProgress;
