import React from 'react';
import { SubmissionsBucket } from 'utils/enums';
import { StyledTypography } from 'components';
import { Box, Hidden } from '@material-ui/core';
import { formatDatePickerDate } from 'utils';

const Actions = ({ policyRequest }) => {
    const numberOfUncheckedContingencies = policyRequest.unchecked_post_bind_contingencies_count + policyRequest.unchecked_pre_bind_contingencies_count;
    return (
        <StyledTypography.FadedTypography variant="subtitle2">
            {policyRequest.athena_submissions_bucket === SubmissionsBucket.BIND_REQUESTED ? (
                'All outstanding contingencies must be fulfilled within 30 days from issuance of the binder and validated in order to receive the policy.'
            ) : policyRequest.conditionally_bound ? (
                numberOfUncheckedContingencies ?
                    <>Please complete {policyRequest.unchecked_post_bind_contingencies_count} contingency requirements to receive the policy.</>
                    :
                    <>Policy issuance is in progress and documents will be available shortly</>
            ) : (
                <>
                    <Hidden smDown>View policy details or download application, policy and billing documents.</Hidden>
                    <Hidden mdUp>View quotes to see coverage offerings / bind a policy.</Hidden>
                    <Box fontWeight="fontWeightMedium">
                        The current policy will expire on {formatDatePickerDate(policyRequest.expiration_date)}
                    </Box>
                </>
            )}
        </StyledTypography.FadedTypography>
    );
}


export default Actions;
