import React from 'react';
import {Switch, Route, useRouteMatch, Redirect} from 'react-router-dom';
import { Error } from 'components';
import { useEnv } from 'hooks';
import {
    PartnershipQuestionnaireReview,
    WhiteLabel,
    PendingUsers,
    ExportAll,
    ManageTeam,
} from './pages';
import {Builder} from 'modules/questionnaire/pages';

const AdminRoutes = () => {
    const { path } = useRouteMatch();
    const { teamView } = useEnv();
    return (
        <Switch>
            <Route exact path={`${path}/dashboard`}>
                <Redirect to="/admin/pending_users" />
            </Route>
            <Route exact path={`${path}/partnership_questionnaire_preview/:partnershipId`}>
                <PartnershipQuestionnaireReview />
            </Route>
            <Route exact path={`${path}/white_label`}>
                <WhiteLabel />
            </Route>
            <Route exact path={`${path}/pending_users`}>
                <PendingUsers />
            </Route>
            <Route exact path={`${path}/export_all`}>
                <ExportAll />
            </Route>
            <Route exact path={`${path}/questionnaire_builder`}>
                <Builder />
            </Route>
            <Route exact path={`${path}/manage_team`}>
                {teamView ? <ManageTeam /> : <Error errorStatus="404" />}
            </Route>
            <Route>
                <Error errorStatus="404" />
            </Route>
        </Switch>
    );
};

export default AdminRoutes;
