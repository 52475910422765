import React from 'react';
import { Icon, IconBox, StyledTypography } from 'components';
import { Box, Hidden, Typography } from '@material-ui/core';


const AdditionalAction = ({ icon, overlineTitle, title, description, actions, DescriptionTypography = StyledTypography.FadedTypography }) => {
    return (
        <Box px={[0, 4, 7, 10]} py={[0, 3, 6, 7]} bgcolor="white" mt={0.5}>
            <Box display="flex">
                <Hidden smDown>
                    <IconBox sizes="56px" borderRadius="50%" bgcolor="#F2F2F2" mr={2}>
                        <Icon icon={icon}
                            TypographyComponent={StyledTypography.FadedTypography}
                            typographyProps={{ variant: 'h5' }}
                        />
                    </IconBox>
                </Hidden>
                <Box>
                    {overlineTitle && (
                        <StyledTypography.FadedTypography variant="overline">
                            {overlineTitle}
                        </StyledTypography.FadedTypography>
                    )}
                    <Box fontWeight="fontWeightBold">
                        <Typography variant="h5" >
                            {title}
                        </Typography>
                    </Box>
                    <Box fontWeight="fontWeightLight">
                        <DescriptionTypography variant="subtitle2" component="div">
                            {description}
                        </DescriptionTypography>
                    </Box>
                    <Box mt={2}>
                        {actions}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}


export default AdditionalAction;
