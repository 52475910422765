import React from 'react';
import { StyledTypography } from 'components';
import { Box } from '@material-ui/core';
import { theme } from 'components/theme/styles';


const Actions = ({ policyRequest }) => {
    return (
        <>
            <StyledTypography.SecondaryFadedTypography component="span" variant="subtitle2">
                Please provide any relevant notes or documents such as a full application, supplementals,
                etc. If you have any questions, please contact us at&nbsp;
            </StyledTypography.SecondaryFadedTypography>
            <Box component="span" fontWeight="500" color={theme.palette.primary.main}>
                {policyRequest.uw_email}
            </Box>
        </>
    )
}


export default Actions;
