import Styled from './styled-components';
import React, { FC, useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';

interface Props {
    label: string;
    labelAfterClick: string;
    icon: React.ReactNode;
    onClick: () => void;
}

const SingleActionButton: FC<Props> = ({ label, icon, labelAfterClick, onClick }) => {
    const [isClicked, setIsClicked] = useState(false);

    const handleOnClick = () => {
        setIsClicked(true);
        onClick();
    };

    return (
        <Styled.Button
            variant="text"
            size="small"
            onClick={handleOnClick}
            startIcon={isClicked ? <CheckIcon /> : icon}
            disabled={isClicked}>
            {isClicked ? labelAfterClick : label}
        </Styled.Button>
    );
};

export default React.memo(SingleActionButton);
