import React, { useState, useContext } from 'react';
import { StyledTypography, StyledTable, StyledAction, RoundedButton } from 'components';
import Styled from './styled-components';
import ReactHtmlParser from 'react-html-parser';
import { useRenderedEmailTemplate } from 'api/option_to_quote/queries/useRenderedEmailTemplate';
import { TableRow, Box, Typography } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { UponRenewalPolicyContext } from 'modules/company/pages/company/components/policy-requests-tab/components/UponRenewalPolicyContext';



const QuestionsAndUpdates = ({ policyRequest }) => {
    const [expend, setExpend] = useState(false);

    const {data: renderedTemplate, refetch: getRenderedEmailTemplate} = useRenderedEmailTemplate(
        policyRequest.id,
        'auto_renewal_notice',
        false,
    );
    const { setOpenEditApplicationPopup } = useContext(UponRenewalPolicyContext,);

    const handleClickExpend = async () => {
        setExpend(!expend);
        if (!renderedTemplate) {
            getRenderedEmailTemplate(policyRequest.id, 'auto_renewal_notice');
        }
    };

    return (
        <TableRow>
            <StyledTable.TableCell colSpan={6}>
                <Box
                    component={StyledAction.ExpandableRowControl}
                    display="flex"
                    alignItems="center"
                    mt={0.5}
                    onClick={handleClickExpend}>
                    <StyledTypography.FadedTypography variant="subtitle1">
                        <Box mr={1} display="flex">
                            {expend ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </Box>
                    </StyledTypography.FadedTypography>
                    <StyledTypography.FadedTypography variant="body1" align="justify">
                        {expend ? 'Hide' : 'View'} rapid renewal letter for eligibility questions & updates
                    </StyledTypography.FadedTypography>
                </Box>
                {expend ?
                    renderedTemplate ?
                        <Box>
                            <Styled.HtmlWrapper>
                                {ReactHtmlParser(renderedTemplate.html)}
                            </Styled.HtmlWrapper>
                            <Box textAlign="center" mt={2.5}>
                                <RoundedButton py={0.25} onClick={() => setOpenEditApplicationPopup(true)}>
                                    <Typography variant="subtitle2">Update Application</Typography>
                                </RoundedButton>
                            </Box>
                        </Box>
                        :
                        <Styled.CircularProgressWrapper>
                            <Styled.CircularProgress size={30} />
                        </Styled.CircularProgressWrapper>
                    :
                    <Box/>
                }
            </StyledTable.TableCell>
        </TableRow>
    )
}

export default QuestionsAndUpdates;
