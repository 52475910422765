import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { InfoItem, StyledAction } from 'components'
import { BreachCalculatorPopup, SecurityReportPopup } from 'components/popup';
import { Intercom } from 'services';
import Styled from './styled-components';


const DashboardFooter = () => {
    const [calculatorModalOpen, setCalculatorModalOpen] = useState(false);
    const [securityReportModalOpen, setSecurityReportModalOpen] = useState(false);

    return (
        <Box component={Styled.FooterWrapper} margin={[0, 0, 0, 'auto']}>
            <Box mb={[4, 4, 4, 6]} textAlign="left">
                <Grid container>
                    <Grid item sm={12} md={12} lg={4} style={{paddingRight:40}}>
                        <InfoItem
                            icon="support"
                            px={[4, 4, 4, 0]}
                            iconBGColor="#fff"
                            header="Contact Us"
                            text="For general questions and requests, use our chat. For account inquiries, email your underwriter."
                            link={<StyledAction.Base onClick={() => Intercom.openChat()}>Open chat.</StyledAction.Base>}
                        />
                    </Grid>
                    <Grid item sm={12} md={12} lg={4}>
                        <InfoItem
                            icon="shield-shoot-discover"
                            px={[4, 4, 4, 0]}
                            iconBGColor="#fff"
                            header="Security Report"
                            text="Run security reports for clients and prospects."
                            link={<StyledAction.Base onClick={() => setSecurityReportModalOpen(true)}>Request report.</StyledAction.Base>}
                        />
                    </Grid>
                    <Grid item sm={12} md={12} lg={4}>
                        <InfoItem
                            icon="calculator"
                            px={[4, 4, 4, 0]}
                            iconBGColor="#fff"
                            header="Breach Calculator"
                            text="Calculate and download data breach cost scenarios."
                            link={<StyledAction.Base onClick={() => setCalculatorModalOpen(true)}>Start calculation.</StyledAction.Base>}
                        />
                    </Grid>
                </Grid>
            </Box>
            <BreachCalculatorPopup
                handleClose={() => setCalculatorModalOpen(false)}
                open={calculatorModalOpen}/>
            <SecurityReportPopup
                handleClose={() => setSecurityReportModalOpen(false)}
                open={securityReportModalOpen}/>
        </Box>
    );
}

export default DashboardFooter;
