import { formatDatePickerDate, totalHumanize, dateDifference, makeDatePickerDate } from 'utils';
import accounting from 'accounting';
import {
    QuotesSubBuckets,
    PoliciesSubBuckets,
    LostSubmissionReasons,
    SubmissionsBucket,
    RenewalType,
    ProductsAbbreviatedName,
} from 'utils/enums';

import _ from 'lodash';
import { RenewalsSubBuckets } from 'utils/enums';

export const truncate = (text, maxLength) => {
    if (text.length > maxLength) {
        return `${text.substring(0, maxLength)}...`;
    } else {
        return text;
    }
};

export const updateDataQuotes = (data, subBucket) => {
    data.status = [];
    const today = new Date();
    const expires_in = Math.ceil(dateDifference(makeDatePickerDate(data.expiration_date), today));
    switch (subBucket) {
        case QuotesSubBuckets.OPEN:
            if (data.expiration_date && 1 <= expires_in && expires_in <= 10) {
                data.status.push({
                    text: `EXPIRES IN ${expires_in} DAYS`,
                    type: 'yellow',
                    priority: 1,
                });
            }
            if (data.option_to_quotes.length > 0) {
                const newQuote = data.option_to_quotes.filter(
                    ({ _, publish_time }) =>
                        publish_time && Math.ceil(dateDifference(today, makeDatePickerDate(publish_time))) <= 1,
                );
                if (newQuote.length > 0) {
                    data.status.push({ text: 'NEW QUOTE', type: 'blue', priority: 1 });
                }
            }
            if (data.unchecked_pre_bind_contingencies_count === 0) {
                data.status.push({ text: 'READY TO BIND', type: 'green', priority: 2 });
            }
            if (data.unchecked_pre_bind_contingencies_count > 0) {
                let error_text = `${data.unchecked_pre_bind_contingencies_count} ${
                    data.unchecked_pre_bind_contingencies_count === 1 ? 'CONTINGENCY' : 'CONTINGENCIES'
                }`;
                data.status.push({ text: error_text, type: 'red', priority: 2 });
            }
            break;
        case QuotesSubBuckets.INCOMPLETE:
            if (!data.product) {
                data.button = { text: 'Start Application' };
                data.status.push({
                    text: 'SELECT A PRODUCT',
                    type: 'blue',
                    priority: 1,
                });
            } else {
                data.status.push({
                    text: `${data.num_of_answers}/${data.num_of_visible_questions} ANSWERED`,
                    type: 'blue',
                    priority: 2,
                });
            }
            break;
        case QuotesSubBuckets.PENDING:
            if (data.blocked_by_bor) {
                data.status.push({ text: 'Broker Conflict', type: 'blue', priority: 1 });
            } else {
                data.status.push({ text: 'REFERRED', type: 'blue', priority: 1 });
            }

            break;
        case QuotesSubBuckets.BIND_REQUEST:
            data.status.push({ text: 'Underwriter reviewing', type: 'blue', priority: 1 });
            break;
        case QuotesSubBuckets.INACTIVE:
            if (data.athena_submissions_bucket === SubmissionsBucket.LOST) {
                data.status.push({
                    text:
                        data.athena_status === LostSubmissionReasons.QUOTED_FOR_TOO_LONG
                            ? 'expired after 60 days'
                            : 'expired',
                    priority: 1,
                });
            } else {
                data.status.push({
                    text: `${data.athena_submissions_bucket}`,
                    priority: 1,
                });
            }
            break;
        default:
            return;
    }
    data.status.sort((status1, status2) => status1.priority > status2.priority);
};

const numOfQuotes = data => {
    return data
        .map(product => product.option_to_quotes.length)
        .reduce((numOfQuotes1, numOfQuotes2) => numOfQuotes1 + numOfQuotes2);
};

export const updateDataPolicies = (data, subBucket) => {
    data.status = [];
    data.policy_period = `${formatDatePickerDate(data.effective_date)}-${formatDatePickerDate(data.expiration_date)}`;
    switch (subBucket) {
        case PoliciesSubBuckets.ACTIVE:
            let numberOfUncheckedContingencies = undefined;
            if (data.conditionally_bound) {
                numberOfUncheckedContingencies =
                    data.unchecked_post_bind_contingencies_count + data.unchecked_pre_bind_contingencies_count;
                const text = numberOfUncheckedContingencies === 1 ? 'Contingency' : 'Contingencies';
                if (numberOfUncheckedContingencies > 0) {
                    data.button = { text: 'View Binder' };
                    data.status.push({ text: `${numberOfUncheckedContingencies} ${text}`, type: 'yellow' });
                }
            }
            if (!numberOfUncheckedContingencies && data.erp_info.expiration_date) {
                data.status.push({ text: 'Extended Reporting', type: 'blue' });
            }
            break;
        case PoliciesSubBuckets.RENEWING:
            // if the renewal policy hasn't been created yet, we should show MANUAL
            if (!data.renewal_policy_request) {
                data.status.push({
                    text: 'MANUAL',
                    type: 'blue',
                });
            } else if (
                [SubmissionsBucket.LOST, SubmissionsBucket.DECLINED].includes(
                    data.renewal_policy_request?.athena_submissions_bucket,
                )
            ) {
                data.status.push({ text: 'Not Renewing', type: 'red' });
            } else {
                const isManual = [RenewalType.MANUAL, RenewalType.SOLICITATION_MANUAL].includes(
                    data.renewal_policy_request?.renewal_type.name,
                );
                data.status.push({
                    text: isManual ? 'MANUAL' : 'RAPID',
                    type: isManual ? 'blue' : 'green',
                });
            }
            break;
        case PoliciesSubBuckets.INACTIVE:
            if (data.erp_info.is_active) {
                data.status.push({ text: 'Extended Reporting', type: 'blue' });
            }
            data.status.push({ text: `${data.athena_submissions_bucket}` });
            break;
        case RenewalsSubBuckets.MANUAL:
            if (data.athena_submissions_bucket === SubmissionsBucket.PRE_QUOTE) {
                if (data.last_submitted_at) {
                    data.status.push({ text: 'Pending Review', type: 'blue' });
                } else {
                    data.status.push({ text: 'App Required', type: 'red' });
                }
            } else if (data.athena_submissions_bucket === SubmissionsBucket.QUOTED) {
                if (data.unchecked_pre_bind_contingencies_count > 0) {
                    numberOfUncheckedContingencies =
                        data.unchecked_post_bind_contingencies_count + data.unchecked_pre_bind_contingencies_count;
                    const text = numberOfUncheckedContingencies === 1 ? 'Contingency' : 'Contingencies';
                    data.status.push({ text: `${numberOfUncheckedContingencies} ${text}`, type: 'yellow' });
                } else {
                    data.status.push({ text: 'Ready to Bind', type: 'green' });
                }
            } else if (data.athena_submissions_bucket === SubmissionsBucket.BOUND) {
                if (data.conditionally_bound) {
                    numberOfUncheckedContingencies =
                        data.unchecked_post_bind_contingencies_count + data.unchecked_pre_bind_contingencies_count;
                    const text = numberOfUncheckedContingencies === 1 ? 'Contingency' : 'Contingencies';
                    if (numberOfUncheckedContingencies > 0) {
                        data.status.push({ text: `${numberOfUncheckedContingencies} ${text}`, type: 'yellow' });
                    }
                } else {
                    data.status.push({ text: 'Issued', type: 'green' });
                }
            }
            break;
        case RenewalsSubBuckets.RAPID:
            if (data.athena_submissions_bucket === SubmissionsBucket.BOUND && !data.conditionally_bound) {
                data.status.push({ text: 'Issued', type: 'green' });
            }
            break;
        default:
            return;
    }
};

export const createDataWithInnerOptions = (clientName, data, subBucket) => {
    data.policies.map(current => updateDataQuotes(current, subBucket));
    return {
        clientName,
        data: data.policies,
        company: {
            domain: data.domain,
            financial_exposure_ready: data.financial_exposure_ready,
            indirect_broker_name: data.indirect_broker_name,
            industry: data.industry,
            latest_submitted_policy_request: data.latest_submitted_policy_request,
        },
        num_of_quotes: numOfQuotes(data.policies),
        policiesRequestRows: data.policies.map(policyRequest => ({
            header: policyRequest.product.name,
            id: policyRequest.id,
            broker: policyRequest.broker_user,
            optionsToQuote: policyRequest.option_to_quotes.map(optionToQuote => ({
                id: optionToQuote.id,
                premium: `$${accounting.formatNumber(optionToQuote.quote.premium)}`,
                total_premium: `$${accounting.formatNumber(optionToQuote.quote.total_premium)}`,
                limit: `$${totalHumanize(optionToQuote.quote.aggregate_limit)}`,
                retention: `$${totalHumanize(optionToQuote.quote.aggregate_retention)}`,
                claimLimit: `$${totalHumanize(optionToQuote.quote.per_claim_limit)}`,
                pending: optionToQuote.pending,
            })),
        })),
    };
};

export const createDataWithoutInnerOptions = (clientName, data, subBucket) => {
    data.policies.map(current => updateDataQuotes(current, subBucket));
    return {
        clientName,
        data: data.policies,
        company: {
            domain: data.domain,
            financial_exposure_ready: data.financial_exposure_ready,
            indirect_broker_name: data.indirect_broker_name,
            industry: data.industry,
            latest_submitted_policy_request: data.latest_submitted_policy_request,
        },
        num_of_quotes: numOfQuotes(data.policies),
        policiesRequestRows: data.policies.map(policyRequest => ({
            button: {
                text:
                    subBucket === QuotesSubBuckets.INCOMPLETE && !policyRequest.product
                        ? 'Start Application'
                        : 'Complete Application',
            },
            header: policyRequest.product?.name,
            id: policyRequest.id,
            policy_id: policyRequest.id,
            broker: policyRequest.broker_user,
            collapseData: [
                subBucket === QuotesSubBuckets.INCOMPLETE
                    ? !policyRequest.product
                        ? data.financial_exposure_ready == null
                            ? 'Security score report requested. Please select a product to generate a quote.'
                            : 'Data breach cost calculated. Please select a product to generate a quote.'
                        : 'Please complete the application to generate a quote.'
                    : subBucket === QuotesSubBuckets.INACTIVE
                    ? policyRequest.athena_submissions_bucket === SubmissionsBucket.DECLINED
                        ? 'At-Bay was unable to provide quotes for this submission. If details have changed, update application answers to receive terms.'
                        : 'Your previous quotes have expired. Please update application answers to receive refreshed terms.'
                    : policyRequest.blocked_by_bor
                    ? 'Please verify that you have provided a full address (including suite, floor, etc.)'
                    : 'At-Bay underwriters are reviewing the application.',
            ],
        })),
    };
};

export const createDataBindRequest = (clientName, data, subBucket) => {
    data.policies.map(current => updateDataQuotes(current, subBucket));
    return {
        clientName,
        data: data.policies,
        company: {
            domain: data.domain,
            financial_exposure_ready: data.financial_exposure_ready,
            indirect_broker_name: data.indirect_broker_name,
            industry: data.industry,
            latest_submitted_policy_request: data.latest_submitted_policy_request,
        },
        num_of_quotes: numOfQuotes(data.policies),
        policiesRequestRows: data.policies.map(policyRequest => ({
            header: policyRequest.product.name,
            id: policyRequest.id,
            broker: policyRequest.broker_user,
            optionsToQuote: policyRequest.option_to_quotes
                .filter(option => option.quote.preferred)
                .map(option => ({
                    id: option.id,
                    premium: `$${accounting.formatNumber(option.quote.premium)}`,
                    total_premium: `$${accounting.formatNumber(option.quote.total_premium)}`,
                    limit: `$${totalHumanize(option.quote.aggregate_limit)}`,
                    claimLimit: `$${totalHumanize(option.quote.per_claim_limit)}`,
                    retention: `$${totalHumanize(option.quote.aggregate_retention)}`,
                })),
        })),
    };
};

export const createDataInactive = (clientName, data, subBucket) => {
    data.policies.map(current => updateDataQuotes(current, subBucket));
    return {
        clientName,
        data: data.policies,
        company: {
            domain: data.domain,
            financial_exposure_ready: data.financial_exposure_ready,
            indirect_broker_name: data.indirect_broker_name,
            industry: data.industry,
            latest_submitted_policy_request: data.latest_submitted_policy_request,
        },
        num_of_quotes: numOfQuotes(data.policies),
    };
};

export const createDataPolicies = (clientName, data, subBucket) => {
    data.policies.map(productData => updateDataPolicies(productData, subBucket));
    return {
        clientName,
        data: data.policies,
        company: {
            domain: data.domain,
            financial_exposure_ready: data.financial_exposure_ready,
            indirect_broker_name: data.indirect_broker_name,
            industry: data.industry,
            latest_submitted_policy_request: data.latest_submitted_policy_request,
        },
        num_of_quotes: numOfQuotes(data.policies),
        policiesRequestRows: data.policies.map(policyRequest => ({
            button: {
                text:
                    subBucket === RenewalsSubBuckets.NOT_RENEWING
                        ? 'View Details'
                        : subBucket === RenewalsSubBuckets.RAPID
                        ? 'Review Renewal'
                        : subBucket === PoliciesSubBuckets.ACTIVE &&
                          (policyRequest.unchecked_post_bind_contingencies_count ||
                              policyRequest.unchecked_pre_bind_contingencies_count)
                        ? 'View Binder'
                        : 'View Policy',
                path: [RenewalsSubBuckets.NOT_RENEWING, RenewalsSubBuckets.RAPID].includes(subBucket)
                    ? `/company/${data.id}`
                    : null,
                query: subBucket === RenewalsSubBuckets.RAPID ? '?tab=renewals' : null,
            },
            broker: policyRequest.broker_user,
            header: [RenewalsSubBuckets.RAPID, RenewalsSubBuckets.NOT_RENEWING].includes(subBucket)
                ? policyRequest.product.name
                : `${policyRequest.product.name} Policy No. ${policyRequest.hsb_policy_number}`,
            id: policyRequest.id,
            policy_period: policyRequest.policy_period,
            expiration_date: policyRequest.expiration_date,
            optionsToQuote: policyRequest.option_to_quotes
                .filter(option => option.quote.preferred)
                .map(option => ({
                    id: option.id,
                    premium: `$${accounting.formatNumber(option.quote.premium)}`,
                    total_premium: `$${accounting.formatNumber(option.quote.original_total_premium)}`,
                    limit: `$${totalHumanize(option.quote.aggregate_limit)}`,
                    claimLimit: `$${totalHumanize(option.quote.per_claim_limit)}`,
                    retention: `$${totalHumanize(option.quote.aggregate_retention)}`,
                })),
        })),
    };
};

const getTextOrPath = (type, policyRequest, data) => {
    if (
        policyRequest.athena_submissions_bucket === SubmissionsBucket.BIND_REQUESTED ||
        (policyRequest.athena_submissions_bucket === SubmissionsBucket.BOUND &&
            (policyRequest.unchecked_post_bind_contingencies_count ||
                policyRequest.unchecked_pre_bind_contingencies_count))
    ) {
        return type === 'text'
            ? 'View Contingencies'
            : type === 'path'
            ? `/submission/policy_details/${
                  policyRequest.option_to_quotes.find(option => option.quote.preferred).id
              }/?contingencies=true`
            : null;
    } else if (policyRequest.athena_submissions_bucket === SubmissionsBucket.BOUND) {
        return type === 'text'
            ? 'View Policy'
            : type === 'path'
            ? `/submission/policy_details/${policyRequest.option_to_quotes.find(option => option.quote.preferred).id}`
            : null;
    } else if (policyRequest.athena_submissions_bucket === SubmissionsBucket.PRE_QUOTE) {
        if (policyRequest.last_submitted_at) {
            return type === 'text' ? 'View Submission' : type === 'path' ? `/company/${data.id}/?tab=renewals` : null;
        } else {
            return type === 'text'
                ? 'Complete Application'
                : type === 'path'
                ? `/questionnaire/policy/${policyRequest.id}`
                : { editMode: true };
        }
    } else {
        return null;
    }
};

export const createManualRenewalData = (clientName, data, subBucket) => {
    data.policies.map(productData => updateDataPolicies(productData, subBucket));
    return {
        clientName,
        data: data.policies,
        company: {
            domain: data.domain,
            financial_exposure_ready: data.financial_exposure_ready,
            indirect_broker_name: data.indirect_broker_name,
            industry: data.industry,
            latest_submitted_policy_request: data.latest_submitted_policy_request,
        },
        num_of_quotes: numOfQuotes(data.policies),
        policiesRequestRows: data.policies.map(policyRequest => ({
            isPreQuote: policyRequest.athena_submissions_bucket === SubmissionsBucket.PRE_QUOTE,
            button: {
                text: getTextOrPath('text', policyRequest, data),
                path: getTextOrPath('path', policyRequest, data),
                state: getTextOrPath('state', policyRequest, data),
            },
            broker: policyRequest.broker_user,
            header:
                policyRequest.athena_submissions_bucket === SubmissionsBucket.BOUND &&
                (policyRequest.unchecked_post_bind_contingencies_count ||
                    policyRequest.unchecked_pre_bind_contingencies_count)
                    ? `${policyRequest.product.name} Binder No. ${policyRequest.hsb_policy_number}`
                    : policyRequest.athena_submissions_bucket === SubmissionsBucket.BOUND
                    ? `${policyRequest.product.name} Policy No. ${policyRequest.hsb_policy_number}`
                    : policyRequest.product.name,
            id: policyRequest.id,
            policy_period: policyRequest.policy_period,
            optionsToQuote:
                policyRequest.athena_submissions_bucket === SubmissionsBucket.PRE_QUOTE
                    ? null
                    : policyRequest.option_to_quotes.map(option => ({
                          id: option.id,
                          premium: `$${accounting.formatNumber(option.quote.premium)}`,
                          total_premium: `$${accounting.formatNumber(option.quote.original_total_premium)}`,
                          limit: `$${totalHumanize(option.quote.aggregate_limit)}`,
                          claimLimit: `$${totalHumanize(option.quote.per_claim_limit)}`,
                          retention: `$${totalHumanize(option.quote.aggregate_retention)}`,
                          pending: option.pending,
                      })),
            collapseData: [
                policyRequest.athena_submissions_bucket === SubmissionsBucket.PRE_QUOTE
                    ? policyRequest.last_submitted_at
                        ? 'Submission is pending review from At-Bay. Please provide any relevant notes or documents.'
                        : 'Please complete the application to receive renewal terms.'
                    : null,
            ],
        })),
    };
};

export const createCompanyList = policyRequests => {
    const companyMap = {};
    for (const policyRequest of policyRequests) {
        if (policyRequest.company_id in companyMap) {
            companyMap[policyRequest.company_id].policyRequests.push(policyRequest);
        } else {
            companyMap[policyRequest.company_id] = {
                company_name: policyRequest.company_name,
                last_update: policyRequest.company.last_update,
                policyRequests: [policyRequest],
            };
        }
    }
    return Object.values(companyMap);
};

export const companyHasQuestionsAndAnswers = company => {
    const policyRequest = company.data[0];

    // The validation is relevant for mpl only
    const productName = policyRequest.product?.name;
    if (productName !== ProductsAbbreviatedName.MPL) {
        return true;
    }
    return !_.isNil(policyRequest.num_of_answers) && !_.isNil(policyRequest.num_of_visible_questions);
};
