import React from 'react';
import { ButtonBar, RoundedButton } from 'components';
import { TableRow, Typography, Box, Hidden } from '@material-ui/core';
import { useBreakpoint } from 'hooks';
import Styled from 'components/table/styled-components';
import { ProductsAbbreviatedName } from 'utils/enums';
import { InnerDataCellWithOption } from 'components/table/SharedComponents';

const InnerTableOpenBucket = ({ policyRequest, optionToQuoteData, handleButton, optionToQuoteId }) => {
    const classes = `inner ${optionToQuoteData.pending ? 'grey' : 'black'}`;
    const isSm = useBreakpoint('down', 'sm');
    const productMpl = policyRequest.product?.abbreviated_name === ProductsAbbreviatedName.MPL;

    const ButtonBarComponent = () => {
        return (
            <ButtonBar
                fullWidth={isSm}
                py={isSm ? '8px' : '6.5px'}
                variant="subtitle2"
                clickHandler={handleButton}
                data={[
                    {
                        content: 'Customize',
                        primary: false,
                        id: `${optionToQuoteData.id}:customize`,
                    },
                    {
                        content: policyRequest.unchecked_pre_bind_contingencies_count === 0 ? 'Bind' : 'Review',
                        primary: true,
                        id: `${optionToQuoteData.id}:bind`,
                    },
                ]}
            />
        );
    };

    const RoundedButtonComponent = () => {
        return (
            <RoundedButton py={isSm ? 0.5 : 0.25} px={isSm ? 4 : 7} fullWidth={isSm} onClick={handleButton}>
                <Typography variant="subtitle2">{policyRequest.unchecked_pre_bind_contingencies_count === 0 ? 'Bind' : 'Review'}</Typography>
            </RoundedButton>
        );
    };

    return (
        <>
            <Hidden mdUp>
                {optionToQuoteId === optionToQuoteData.id && (
                    <>
                        <TableRow>
                            <InnerDataCellWithOption
                                policyRequest={policyRequest}
                                optionToQuoteData={optionToQuoteData}
                            />
                        </TableRow>
                        <TableRow>
                            {productMpl ? (
                                <Styled.TableCell
                                    className="inner black"
                                    align="center"
                                    style={{ width: '100%' }}
                                    colSpan={3}>
                                    {!optionToQuoteData.pending && (
                                        <Box pt={1.5}>
                                            <RoundedButtonComponent />
                                        </Box>
                                    )}
                                </Styled.TableCell>
                            ) : (
                                <Styled.TableCell
                                    className={classes}
                                    align="center"
                                    style={{ width: '100%' }}
                                    colSpan={3}>
                                    {!optionToQuoteData.pending && (
                                        <Box pt={1.5} width="100%">
                                            <ButtonBarComponent />
                                        </Box>
                                    )}
                                </Styled.TableCell>
                            )}
                        </TableRow>
                    </>
                )}
            </Hidden>
            <Hidden smDown>
                <TableRow>
                    <InnerDataCellWithOption policyRequest={policyRequest} optionToQuoteData={optionToQuoteData} />
                    <Styled.TableCell className={`alignEnd button ${classes}`}>
                        {!optionToQuoteData.pending && !productMpl && <ButtonBarComponent />}
                        {!optionToQuoteData.pending && productMpl && <RoundedButtonComponent />}
                    </Styled.TableCell>
                </TableRow>
            </Hidden>
        </>
    );
};

export default InnerTableOpenBucket;
