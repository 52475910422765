import React, { FC, useCallback } from 'react';
import Styled from './styled-components';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { StyledAction } from '../../../../../../components';
import ClearIcon from '@material-ui/icons/Clear';

interface Props {
    handleOnChange: (value: string) => void;
    value: string;
}

interface ClearIconProps {
    isVisible: boolean;
    onClick: () => void;
}

const StyledSearchIcon: FC = React.memo(() => {
    return (
        <InputAdornment position="start">
            <Styled.SearchIconContainer>
                <SearchIcon fontSize={'inherit'} />
            </Styled.SearchIconContainer>
        </InputAdornment>
    );
});

const StyledClearIcon: FC<ClearIconProps> = React.memo(({ isVisible, onClick }) => {
    return isVisible ? (
        <InputAdornment position="end">
            <StyledAction.Base>
                <Styled.ClearIconContainer>
                    <ClearIcon onClick={onClick} />
                </Styled.ClearIconContainer>
            </StyledAction.Base>
        </InputAdornment>
    ) : (
        <></>
    );
});

const SearchInput: FC<Props> = ({ handleOnChange, value }) => {
    const handleClearInput = useCallback(() => {
        handleOnChange('');
    }, []);

    return (
        <Styled.SearchBarForm>
            <Styled.TextField
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                    handleOnChange(e.target.value)
                }
                variant="outlined"
                placeholder="Search..."
                InputProps={{
                    value,
                    startAdornment: <StyledSearchIcon />,
                    endAdornment: <StyledClearIcon onClick={handleClearInput} isVisible={!!value} />,
                }}></Styled.TextField>
        </Styled.SearchBarForm>
    );
};

export default React.memo(SearchInput);
