import React, { Fragment } from 'react';
import { StyledTypography, StyledTable } from 'components';
import { totalHumanize } from 'utils';
import _ from 'lodash';
import {
    Box,
    Hidden,
    Table,
    TableHead,
    TableBody,
    TableRow,
    Typography,
} from '@material-ui/core';


const CoveragesTable = ({
    quote, firstColSpan = 1, numOfExtraCols = 0, childrenToggled = true,
    variant = 'subtitle2', subVariant = 'body2', firstColPadding = 0, subCoverageSpacing = 0,
    formatFunc = totalHumanize,
}) => {
    const innerTablePadding = 2;
    return (
        <>
            <Hidden smDown>
                {quote.coverages.line_items.map(coverage => (
                    <Fragment key={coverage.coverage_name}>
                        <Box component={TableRow} fontWeight="fontWeightLight" className="head">
                            <StyledTable.TableCell className="head" colSpan={firstColSpan} padding="none">
                                <Typography variant={variant} component="span">
                                    <Box component="p" mt={2} mb={0} pl={firstColPadding}>
                                        {coverage.coverage_name}
                                    </Box>
                                </Typography>
                            </StyledTable.TableCell>
                            <StyledTable.TableCell className="head" padding="none">
                                <Typography variant={variant} component="span">
                                    <Box component="p" mt={2} mb={0}>
                                        ${formatFunc(coverage.limit)}
                                    </Box>
                                </Typography>
                            </StyledTable.TableCell>
                            <StyledTable.TableCell className="head" padding="none">
                                <Typography variant={variant} component="span">
                                    <Box component="p" mt={2} mb={0}>
                                        {coverage.retention ? `$${formatFunc(coverage.retention)}` : ''}
                                    </Box>
                                </Typography>
                            </StyledTable.TableCell>
                            {[...Array(numOfExtraCols)].map((_, i) => <StyledTable.TableCell key={i} padding="none" />)}
                        </Box>
                        {childrenToggled && (
                            <>
                                {coverage.children.map(subCoverage => (
                                    <Box component={TableRow} key={subCoverage.coverage_name} fontWeight="fontWeightLight">
                                        <StyledTable.TableCell colSpan={firstColSpan} padding="none">
                                            <Box pl={firstColPadding} mt={subCoverageSpacing}>
                                                <StyledTypography.FadedTypography variant={variant} component="span">
                                                    {'- '}
                                                </StyledTypography.FadedTypography>
                                                <StyledTypography.FadedTypography variant={subVariant} component="span">
                                                    {subCoverage.coverage_name}
                                                </StyledTypography.FadedTypography>
                                            </Box>
                                        </StyledTable.TableCell>
                                        <StyledTable.TableCell padding="none">
                                            <Box mt={subCoverageSpacing}>
                                                <StyledTypography.FadedTypography variant={subVariant}>
                                                    ${formatFunc(subCoverage.limit)}
                                                </StyledTypography.FadedTypography>
                                            </Box>
                                        </StyledTable.TableCell>
                                        <StyledTable.TableCell padding="none">
                                            <Box mt={subCoverageSpacing}>
                                                <StyledTypography.FadedTypography variant={subVariant}>
                                                    {coverage.retention ? `$${formatFunc(subCoverage.retention)}` : ''}
                                                </StyledTypography.FadedTypography>
                                            </Box>
                                        </StyledTable.TableCell>
                                        {[...Array(numOfExtraCols)].map((_, i) => <StyledTable.TableCell key={i} padding="none" />)}
                                    </Box>
                                ))}
                                {coverage.other_parameter && (
                                    <Box component={TableRow} key={coverage.other_parameter.name} fontWeight="fontWeightLight">
                                        <StyledTable.TableCell colSpan={firstColSpan} padding="none">
                                            <Box pl={firstColPadding} mt={subCoverageSpacing}>
                                                <StyledTypography.FadedTypography variant={variant} component="span">
                                                    {'- '}
                                                </StyledTypography.FadedTypography>
                                                <StyledTypography.FadedTypography variant={subVariant} component="span">
                                                    {coverage.other_parameter.name}
                                                </StyledTypography.FadedTypography>
                                            </Box>
                                        </StyledTable.TableCell>
                                        <StyledTable.TableCell padding="none">
                                            <Box mt={subCoverageSpacing}>
                                                <StyledTypography.FadedTypography variant={subVariant}>
                                                    {coverage.other_parameter.value} {_.capitalize(coverage.other_parameter.units)}
                                                </StyledTypography.FadedTypography>
                                            </Box>
                                        </StyledTable.TableCell>
                                        <StyledTable.TableCell padding="none">
                                            <Box mt={subCoverageSpacing}>
                                                <StyledTypography.FadedTypography variant={subVariant} />
                                            </Box>
                                        </StyledTable.TableCell>
                                        {[...Array(numOfExtraCols)].map((_, i) => <StyledTable.TableCell key={i} padding="none" />)}
                                    </Box>
                                )}
                            </>
                        )}
                    </Fragment>
                ))}
            </Hidden>
            <Hidden mdUp>
                <Box mt={2} />
                {quote.coverages.line_items.map((coverage, i) => (
                    <Fragment key={coverage.coverage_name}>
                        <Box fontWeight="fontWeightMedium">
                            <Typography variant="subtitle1">
                                {coverage.coverage_name}
                            </Typography>
                        </Box>
                        <Box component={Table} fontWeight="fontWeightLight">
                            <TableHead>
                                <TableRow>
                                    <Box component={props => <StyledTable.TableCell {...props} padding="none" />} width="65%">
                                        <Box component={StyledTypography.FadedTypography} variant="overline" pl={innerTablePadding}>
                                            AG. LIMIT
                                        </Box>
                                    </Box>
                                    {!!coverage.retention && (
                                        <StyledTable.TableCell padding="none">
                                            <Box component={StyledTypography.FadedTypography} variant="overline" pl={innerTablePadding}>
                                                RETENTION
                                            </Box>
                                        </StyledTable.TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <StyledTable.TableCell padding="none">
                                        <Box component={Typography} variant={variant} pl={innerTablePadding}>
                                            ${formatFunc(coverage.limit)}
                                        </Box>
                                    </StyledTable.TableCell>
                                    <StyledTable.TableCell padding="none">
                                        <Box component={Typography} variant={variant} pl={innerTablePadding}>
                                            {coverage.retention ? `$${formatFunc(coverage.retention)}` : ''}
                                        </Box>
                                    </StyledTable.TableCell>
                                </TableRow>
                                {!!coverage.children.length && (
                                    <Box component={TableRow} borderBottom="2px solid #F2F2F2">
                                        <Box component={props => <StyledTable.TableCell {...props} padding="none" colSpan={2} />}>
                                            <Box pt={2} component="span" display="block" />
                                        </Box>
                                    </Box>
                                )}
                                {childrenToggled && (
                                    <>
                                        {coverage.children.map(subCoverage => (
                                            <Fragment key={subCoverage.coverage_name}>
                                                <TableRow>
                                                    <StyledTable.TableCell colSpan={2} padding="none">
                                                        <Box component={Typography} variant={subVariant} pl={innerTablePadding} pt={2}>
                                                            {subCoverage.coverage_name}
                                                        </Box>
                                                    </StyledTable.TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <StyledTable.TableCell padding="none">
                                                        <Box component={StyledTypography.FadedTypography} variant={variant} pl={innerTablePadding} pt={1}>
                                                            ${formatFunc(subCoverage.limit)}
                                                        </Box>
                                                    </StyledTable.TableCell>
                                                    <StyledTable.TableCell padding="none">
                                                        <Box component={StyledTypography.FadedTypography} variant={variant} pl={innerTablePadding} pt={1}>
                                                            {coverage.retention ? `$${formatFunc(subCoverage.retention)}` : ''}
                                                        </Box>
                                                    </StyledTable.TableCell>
                                                </TableRow>
                                            </Fragment>
                                        ))}
                                        {coverage.other_parameter && (
                                            <>
                                                <TableRow>
                                                    <StyledTable.TableCell colSpan={2} padding="none">
                                                        <Box component={Typography} variant={subVariant} pl={innerTablePadding} pt={2}>
                                                            {coverage.other_parameter.name}
                                                        </Box>
                                                    </StyledTable.TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <StyledTable.TableCell padding="none">
                                                        <Box component={StyledTypography.FadedTypography} variant={variant} pl={innerTablePadding} pt={1}>
                                                            {coverage.other_parameter.value} {_.capitalize(coverage.other_parameter.units)}
                                                        </Box>
                                                    </StyledTable.TableCell>
                                                    <StyledTable.TableCell padding="none">
                                                        <Box component={StyledTypography.FadedTypography} variant={variant} pl={innerTablePadding} pt={1} />
                                                    </StyledTable.TableCell>
                                                </TableRow>
                                            </>
                                        )}
                                    </>
                                )}
                            </TableBody>
                        </Box>
                        <Box my={3} mx={-4} borderBottom="2px solid #F2F2F2" />
                    </Fragment>
                ))}
            </Hidden>
        </>
    );
}

export default CoveragesTable;
