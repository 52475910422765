import * as FS from '@fullstory/browser';
import { env } from '../env'


const FullStory = {
    init: () => {
        const { REACT_APP_FULL_STORY_ORG, REACT_APP_FULL_STORY_ON } = env;

        if (REACT_APP_FULL_STORY_ORG) {
            FS.init({
                orgId: REACT_APP_FULL_STORY_ORG,
                devMode: REACT_APP_FULL_STORY_ON !== 'true',
                host: 'fullstory-relay.at-bay.com',
                script: 'fullstory-relay.at-bay.com/s/fs.js'
            });
        }
    },
    identify: (uid, args) => {
        uid = String(uid);
        FS.identify(uid, args);
    },
    anonymize: () => {
        FS.anonymize();
    },
    setUserVars: (args) => {
        FS.setUserVars(args);
    },
    event: (name, params) => {
        FS.event(name, params);
    },
    restart: () => {
        FS.restart();
    },
    shutdown: () => {
        FS.shutdown();
    }
};

export default FullStory;
