import React from 'react';
import { useSelector } from 'react-redux';
import SelectLimit from './SelectLimit';
import SelectRetention from './SelectRetention';
import SelectOther from './SelectOther';
import { Box, Typography } from '@material-ui/core';

const Mobile = ({ handleChangeLineItem }) => {
    const { customizedOptionToQuote } = useSelector(state => state.company);

    return (
        <>
            {customizedOptionToQuote.quote.coverages.line_items.map(
                lineItem => {
                    return (
                        <Box
                            key={`${lineItem.coverage_name}-${lineItem.code}`}
                            display="flex"
                            justifyContent="space-around"
                        >
                            <Box display="flex" flexDirection="column">
                                <Box fontWeight={500} mb={1}>
                                    <Typography variant="subtitle2">
                                        {lineItem.coverage_name}
                                    </Typography>
                                </Box>
                                <Box display="flex" mb={2}>
                                    <SelectLimit
                                        lineItem={lineItem}
                                        handleChangeLineItem={
                                            handleChangeLineItem
                                        }
                                    />
                                    <SelectRetention
                                        lineItem={lineItem}
                                        handleChangeLineItem={
                                            handleChangeLineItem
                                        }
                                    />
                                    <SelectOther
                                        lineItem={lineItem}
                                        handleChangeLineItem={
                                            handleChangeLineItem
                                        }
                                    />
                                </Box>
                            </Box>
                        </Box>
                    );
                }
            )}
        </>
    );
};

export default Mobile;
