import styled from 'styled-components';
import {
    Alert as _Alert,
} from '@material-ui/lab';
import { fade } from '@material-ui/core/styles/colorManipulator';


const BaseAlert = styled(_Alert)`
    &&& {
        display: flex;
        align-items: center;
    }
    &&& .MuiAlert-icon {
        align-items: center;
    }
    &&&.no-border-radius {
        border-radius: 0;
    }
`;

const Alert = styled(BaseAlert)`
    &&& {
        border: 1px solid ${({ theme }) => theme.palette.primary.main};
        font-size: 12px;
        margin-bottom: 20px;
    }
`;

const ItemAlert = styled(BaseAlert)`
    &&& {
        color: ${({ theme }) => theme.palette.text.primary};
        background-color: ${({ theme }) => fade(theme.palette.info.main)};
        & .MuiAlert-icon {
            color: ${({ theme }) => theme.palette.text.primary};
            align-self: flex-start;
            padding: 2px 0px;
        }
        & .MuiAlert-message {
            padding: 0px;
        }
    }
`;

export default {
    Alert,
    BaseAlert,
    ItemAlert,
};
