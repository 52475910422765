import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';
import Desktop from './components/Desktop';
import Mobile from './components/Mobile';


const useStyles = makeStyles({
    TableCoverageName: {
        width: '280px',
    },
    Select: {
        width: '180px',
        marginRight: '8px',
    },
    MobileSelect: {
        width: '151px',
        marginRight: '8px',
    },
    MobileClaimSelect: {
        width: '310px',
    },
});


const LoadedAggregate = ({ handleCalculation, handleCreateQuoteBuilder }) => {
    const classes = useStyles();

    const handleChangeAggregate = event => {
        handleCreateQuoteBuilder(event.target.name, event.target.value);
    }

    const props = {
        handleChangeAggregate,
        handleCalculation,
        classes,
    }

    return (
        <>
            <Hidden smDown>
                <Desktop {...props}/>
            </Hidden>
            <Hidden mdUp>
                <Mobile {...props}/>
            </Hidden>
        </>
    )
}

export default LoadedAggregate;
