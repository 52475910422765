import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import ReactGA from 'react-ga';

function PublicRoute({ children, location, ...rest }) {
    const { isAuthenticated } = useSelector(state => state.newAuth);
    const trackPage = page => {
        ReactGA.set({
            page,
        });
        ReactGA.pageview(page);
    };

    useEffect(() => {
        trackPage(location.pathname);
    }, [location.pathname]);

    return (
        <Route
            {...rest}
            render={({ location }) => {
                return !isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: location.state?.from || '',
                            state: { from: location.pathname },
                        }}
                    />
                );
            }}
        />
    );
}
export default PublicRoute;
