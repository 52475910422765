import React from 'react';
import { useBreakpoint, useEnv } from 'hooks';
import { useSelector } from 'react-redux';
import { StyledTypography, StyledLink } from 'components';
import { IndustryAndServices } from 'components/policy';
import { totalHumanize, formatPolicyNumber } from 'utils';
import { SubmissionsBucket } from 'utils/enums';
import {
    Box,
    Typography,
} from '@material-ui/core';


const Header = ({ readOnly }) => {
    const isContainer = useBreakpoint('down', 'container');
    const isSm = useBreakpoint('down', 'sm');
    const { mode } = useEnv();
    const {
        athena_submissions_bucket,
        company_name,
        company_id,
        product,
        hsb_policy_number,
        conditionally_bound
    } = useSelector(state => state.company.optionToQuote.quote.policy_request);
    const { quote } = useSelector(state => state.company.optionToQuote);

    return (
        <Box mx={isSm ? 4 : isContainer ? 7 : 0} mb={4}>
            <Box fontWeight="fontWeightBold">
                <Typography variant="subtitle1" color="primary">
                    <StyledLink.Default to={`/company/${company_id}?policyId=${quote.policy_request.id}`}>
                        {company_name}
                    </StyledLink.Default>
                </Typography>
            </Box>
            <Box mb={1} fontWeight="fontWeightBold">
                <StyledTypography.FadedTypography variant="h3" component="span">
                    {product.name}
                </StyledTypography.FadedTypography>
                <Typography variant="h3" component="span">
                    {readOnly ? (
                        athena_submissions_bucket === SubmissionsBucket.BOUND ? (
                            ` ${conditionally_bound ? 'Conditional Binder' : 'Policy'} #${formatPolicyNumber(mode, hsb_policy_number)}`
                        ) : (
                            ' Bind request in progress'
                        )
                    ) : ` Bind $${totalHumanize(quote.aggregate_limit)}`}
                </Typography>
            </Box>
            <IndustryAndServices policyRequest={quote.policy_request} bgcolor="#FFFFFF"/>
        </Box>
    );
}

export default Header;
