import React, { useState } from 'react';
import { TableRow, Collapse, TableCell, Typography, Box, TableBody, Hidden } from '@material-ui/core';
import { useBreakpoint } from 'hooks';
import StyledTable from 'components/table/styled-components';
import Table from '@material-ui/core/Table';
import InnerTableInactiveBucket from './InnerTableInactiveBucket';
import { useHistory } from 'react-router-dom';
import {CreateSubmissionFormType, Permissions} from 'utils/enums';
import { makeToast } from 'utils';
import * as Sentry from '@sentry/react';
import { useCreateSubmission } from 'api/dashboard/mutations/useCreateSubmission';
import { MainRowMobile, MainRowTabletDesktop } from 'components/table/QuotesSharedComponents';
import { getBrokerInitialsColor } from '../../utils';
import CompanyInfo from 'components/table/CompanyInfo';
import { useSelector } from 'react-redux';
import { usePermissions } from 'hooks/usePermissions';

const QuoteInactiveRow = ({ client, companyId }) => {
    const history = useHistory();
    const companyPolicies = client.data;
    const companyDetails = client.company;
    const numOfPolicies = companyPolicies.length;
    const multiplePolicies = numOfPolicies > 1;
    const isSm = useBreakpoint('down', 'sm');
    const [open, setOpen] = useState(false);
    const { user } = useSelector(state => state.user);
    const submissionWriteAuthorized = usePermissions()([Permissions.SUBMISSION_WRITE]);
    const teamMembersIds = user.team_members_ids;
    const handleOnSubmitRequestSuccess = submission => {
        history.push({ pathname: `/questionnaire/policy/${submission.policy_request_id}`, state: { resubmit: true } });
    };

    const handleOnSubmitRequestFail = error => {
        makeToast('error', 'Something went wrong.');
        Sentry.captureException(error);
    };

    const { mutate: createSubmission } = useCreateSubmission(handleOnSubmitRequestSuccess, handleOnSubmitRequestFail);

    const handleCreateSubmission = () => {
        createSubmission({
            company_id: companyPolicies[0].company_id,
            product_id: companyPolicies[0].product.id,
            url: companyDetails.domain,
            company_name: companyPolicies[0].company_name,
            form_name: CreateSubmissionFormType.QUOTE,
        });
    };

    const handleRedirectQuestionnaire = () => {
        history.push(`/questionnaire/policy/${companyPolicies[0].id}`);
    }

    return (
        <>
            <Hidden smDown>
                <MainRowTabletDesktop
                    multiplePolicies={multiplePolicies}
                    setOpen={setOpen}
                    open={open}
                    numOfPolicies={numOfPolicies}
                    client={client}
                    companyPolicies={companyPolicies}
                    companyId={companyId}
                />
            </Hidden>
            <Hidden mdUp>
                <MainRowMobile
                    multiplePolicies={multiplePolicies}
                    setOpen={setOpen}
                    open={open}
                    numOfPolicies={numOfPolicies}
                    client={client}
                    companyPolicies={companyPolicies}
                    companyId={companyId}
                />
            </Hidden>
            <TableRow>
                <TableCell
                    style={{
                        padding: 0,
                        borderBottom: 'none',
                        backgroundColor: open ? '#F8F8F8' : '#F2F2F2',
                        borderTop: open && client.policiesRequestRows ? '1px solid #F2F2F2' : 'none',
                    }}
                    colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {client.policiesRequestRows &&
                            client.policiesRequestRows.map((policyRequest, index) => (
                                <Box
                                    key={`inner_row_${policyRequest.id}_${companyId}`}
                                    borderBottom="2px solid #f2f2f2"
                                    my={2}
                                    px={4}
                                    fontWeight="fontWeightBold"
                                    mb={0}
                                    pb={2}>
                                    <Box
                                        border="none"
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                        gridGap={12}>
                                        <StyledTable.Avatar
                                            hidden={!policyRequest?.broker?.initials}
                                            style={{
                                                backgroundColor: getBrokerInitialsColor(
                                                    policyRequest?.broker,
                                                    teamMembersIds,
                                                ),
                                                height: !isSm && '32px',
                                                width: !isSm && '32px',
                                                fontSize: !isSm && '14px',
                                            }}>
                                            {policyRequest?.broker?.initials}
                                        </StyledTable.Avatar>
                                        <Typography variant="subtitle2" component="div">
                                            {policyRequest.header || 'Get a Quote'}
                                        </Typography>
                                    </Box>
                                    <Table size="small">
                                        <StyledTable.TableHead className="inner">
                                            {!isSm && (
                                                <StyledTable.TableRow bottom="1px solid #F2F2F2" _bordertop="0" />
                                            )}
                                            <StyledTable.TableRow _bordertop="none">
                                                <StyledTable.TableCellHead
                                                    className="inner"
                                                    width={isSm ? '33%' : '15%'}>
                                                    <Typography variant="overline">NOTE</Typography>
                                                </StyledTable.TableCellHead>
                                                {isSm ? (
                                                    <StyledTable.TableCellHead className="inner" width="0%">
                                                        <Typography variant="overline" />
                                                    </StyledTable.TableCellHead>
                                                ) : (
                                                    <StyledTable.TableCellHead className="inner" width="55%" />
                                                )}
                                            </StyledTable.TableRow>
                                        </StyledTable.TableHead>
                                        <TableBody>
                                            <InnerTableInactiveBucket
                                                collapseData={policyRequest.collapseData[0]}
                                                handleButton={submissionWriteAuthorized ? handleCreateSubmission : handleRedirectQuestionnaire}
                                            />
                                        </TableBody>
                                    </Table>
                                </Box>
                            ))}
                        <CompanyInfo client={client} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default QuoteInactiveRow;
