import styled from 'styled-components';
import {
    TextField as _TextField,
    Typography as _Typography,
    Button as _Button,
    Container as _Container,
} from '@material-ui/core';

const FormContainer = styled(_Container)`
    &&& {
        max-width: 320px;
        box-sizing: content-box;

        ${({ theme }) => `
            .Mui-error {
                font-size: ${theme.typography.body2.fontSize};
            }

            ${theme.breakpoints.down('sm')} {
                margin-top: ${theme.spacing(4)}px;
            }            
        `}
    }
`;

const TextField = styled(_TextField)`
    &&& {
        width: 100%;

        .MuiInput-underline:before {
            border-bottom: 1px solid #e6e6e6;
        }

        ${({ theme }) => `
            margin-bottom: ${theme.spacing(5)}px;
            font-weight: ${theme.typography.fontWeightLight};

            .MuiInputBase-input {
                font-size: ${theme.typography.subtitle2.fontSize};
            }

            ${theme.breakpoints.down('sm')} {
                font-size: ${theme.typography.body1.fontSize};
            }            
        `}
    }
`;

const FormHeader = styled(_Typography)`
    &&& {
        font-family: America;

        ${({ theme }) => `
            margin-bottom: ${theme.spacing(1)}px;
            font-size: ${theme.typography.h5.fontSize};
            font-weight: ${theme.typography.fontWeightBold};
            
            ${theme.breakpoints.up('md')} {
                font-size: ${theme.typography.h3.fontSize};
            }
        `}
    }
`;

const FormText = styled.div`
    &&& {
        color: ${props => (props.errorState ? '#D31D1A' : 'inherit')};

        ${({ theme }) => `
            margin-bottom: ${theme.spacing(1.25)}px;
            font-weight: ${theme.typography.fontWeightRegular};

            ${theme.breakpoints.down('sm')} {
                font-size: ${theme.typography.body1.fontSize};
            }
        `}
    }
`;

const CancelButton = styled(_Button)`
    &&& {
        text-transform: none;

        ${({ theme }) => `
            color: ${theme.palette.primary.main};
            background-color: transparent;
            font-size: ${theme.typography.subtitle2.fontSize};
            font-weight: ${theme.typography.fontWeightBold};
        `}
    }
`;

const LogoContainer = styled.div`
    position: absolute;
    top: 20px;
    left: 32px;
`;

export default {
    FormContainer,
    TextField,
    FormHeader,
    FormText,
    CancelButton,
    LogoContainer,
};
