import Box from '@material-ui/core/Box';
import Styled from './styled-components';
import React, { FC } from 'react';
import { DirectBrokerDetails } from '../../utils';
import { StyledTypography } from 'components';

type Props = Pick<DirectBrokerDetails['indirect_broker_user'], 'email' | 'username'>;

const ContactDetails: FC<Props> = ({ username, email }) => {
    return (
        <Box display="flex" flexDirection={'column'} flex={1}>
            <Styled.Typography variant="subtitle2" className="subtitle">
                {username}
            </Styled.Typography>
            <StyledTypography.SecondaryTypography variant="body2">{email}</StyledTypography.SecondaryTypography>
        </Box>
    );
};

export default React.memo(ContactDetails);
