import React from 'react';
import { useSelector } from 'react-redux';
import { useEnv } from 'hooks';
import { StyledTypography, IconBox, StyledLink, StyledBox } from 'components';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import EmailIcon from '@material-ui/icons/Email';
import { Box, Hidden, Typography } from '@material-ui/core';

const Underwriter = () => {
    const { apiBaseUrl } = useEnv();
    const { uw_name, uw_email, uw_phone, uw_user } = useSelector(state => state.company.optionToQuote.quote.policy_request);

    return (
        <>
            <Hidden smDown>
                <Box px={[0, 4, 7, 4]} py={6} my={0.5} style={{ backgroundColor: '#f8f8f8' }}>
                    <Box display="flex" alignItems="center" width='60%'>
                        <Box mr={2}>
                            <IconBox sizes={['48px', '48px', '56px']}>
                                <img src={`${apiBaseUrl}/questionnaire/underwriter_photo/${uw_user?.id || 0}`}
                                    alt="Your underwriter" style={{borderRadius: '50%'}} height="100%" />
                            </IconBox>
                        </Box>
                        <Box width="100%" fontWeight="fontWeightLight">
                            <Typography variant="h3">{uw_name}</Typography>
                            <Box display="flex" justifyContent="space-between">
                                <StyledBox.FadedBox display="flex" alignItems="center">
                                    <StyledTypography.FadedTypography variant="overline">YOUR UNDERWRITER</StyledTypography.FadedTypography>
                                </StyledBox.FadedBox>
                                <StyledBox.FadedBox display="flex" alignItems="center">
                                    <Typography variant="body2" component="span">
                                        <Box mr={1} display="flex" alignItems="center">
                                            <SmartphoneIcon />
                                        </Box>
                                    </Typography>
                                    <StyledLink.Anchor href={`tel:${uw_phone}`}>
                                        <Typography variant="body2" component="span">{uw_phone}</Typography>
                                    </StyledLink.Anchor>
                                </StyledBox.FadedBox>
                                <StyledBox.FadedBox display="flex" alignItems="center">
                                    <Typography variant="body2" component="span">
                                        <Box mr={1} display="flex" alignItems="center">
                                            <EmailIcon />
                                        </Box>
                                    </Typography>
                                    <StyledLink.Anchor href={`mailto:${uw_email}`}>
                                        <Typography variant="body2" component="span">{uw_email}</Typography>
                                    </StyledLink.Anchor>
                                </StyledBox.FadedBox>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Hidden>
            <Hidden mdUp>
                <Box my={2} px={4}>
                    <Box display="flex" alignItems="center" width="100%">
                        <Box mr={2}>
                            <IconBox sizes={['48px', '48px', '56px']}>
                                <img src={`${apiBaseUrl}/questionnaire/underwriter_photo/${uw_user?.id || 0}`}
                                    alt="Your underwriter" style={{borderRadius: '50%'}} height="100%" />
                            </IconBox>
                        </Box>
                        <Box width="100%" fontWeight="fontWeightLight">
                            <Typography variant="h6">{uw_name}</Typography>
                            <StyledTypography.FadedTypography variant="overline">YOUR UNDERWRITER</StyledTypography.FadedTypography>
                        </Box>
                    </Box>
                    <Box ml={8}>
                        <Box borderBottom="2px solid #E0E0E0" my={2} />
                        <StyledBox.FadedBox display="flex" alignItems="center" mb={1}>
                            <Typography variant="subtitle2" component="span">
                                <Box mr={1} display="flex" alignItems="center">
                                    <SmartphoneIcon />
                                </Box>
                            </Typography>
                            <StyledLink.Anchor href={`tel:${uw_phone}`}>
                                <Typography variant="body1" component="span">{uw_phone}</Typography>
                            </StyledLink.Anchor>
                        </StyledBox.FadedBox>
                        <StyledBox.FadedBox display="flex" alignItems="center">
                            <Typography variant="subtitle2" component="span">
                                <Box mr={1} display="flex" alignItems="center">
                                    <EmailIcon />
                                </Box>
                            </Typography>
                            <StyledLink.Anchor href={`mailto:${uw_email}`}>
                                <Typography variant="body1" component="span">{uw_email}</Typography>
                            </StyledLink.Anchor>
                        </StyledBox.FadedBox>
                    </Box>
                </Box>
            </Hidden>
        </>
    );
}

export default Underwriter;
