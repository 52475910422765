import styled from 'styled-components';
import { TabList as _TabList } from '@material-ui/lab';

const TabList = styled(_TabList)(({ theme }) => ({
    '& .MuiTab-root': {
        minWidth: 120,
        marginLeft: 10,
    },

    '& .MuiTab-textColorInherit.Mui-selected': {
        fontWeight: 'bold',
    },

    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            maxWidth: 100,
            width: '100%',
            backgroundColor: theme.palette.text.primary,
        },
    },
}));

export default {
    TabList,
};
