//@ts-ignore
import { useParams } from 'react-router-dom';
import { useAxiosInstance } from '../../../hooks';
import { ErrorResponseType, SuccessResponseType } from '../../mutationTypes';
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { DashboardQueryKeys } from '../../queryKeys';
import { AxiosInstance } from 'axios';

type MutationReturnType = {
    mutate: UseMutateFunction<SuccessResponseType, ErrorResponseType>;
    error: ErrorResponseType | null;
    isLoading: boolean;
};

const submitPolicy = async (policyRequestId: number, axiosInstance: AxiosInstance): Promise<SuccessResponseType> => {
    const res = await axiosInstance.post(`/questionnaire/policy_request/submit/${policyRequestId}`);
    return res.data?.data as SuccessResponseType;
};

export const useSubmitPolicy = (
    onSuccessCallback: () => void,
    onErrorCallback: (error: ErrorResponseType) => void,
): MutationReturnType => {
    const { policyRequestId } = useParams();
    const queryClient = useQueryClient();
    const axiosInstance = useAxiosInstance();

    const { mutate, error, isLoading } = useMutation(() => submitPolicy(policyRequestId, axiosInstance), {
        onSuccess: onSuccessCallback,
        onError: onErrorCallback,
        onSettled: () => {
            queryClient.resetQueries([DashboardQueryKeys.BUCKETS.QUOTES, DashboardQueryKeys.SUB_BUCKETS.INCOMPLETE]);
            queryClient.resetQueries([DashboardQueryKeys.BUCKETS.QUOTES, DashboardQueryKeys.SUB_BUCKETS.PENDING]);
            queryClient.resetQueries([DashboardQueryKeys.BUCKETS.QUOTES, DashboardQueryKeys.SUB_BUCKETS.OPEN]);
            queryClient.resetQueries([DashboardQueryKeys.SNAPSHOT]);
        },
    });

    return { mutate, error, isLoading };
};
