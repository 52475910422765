import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Anchor = styled.a`
    &&& {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
        
        &:visited {
            color: inherit;
        }
    }
`;

const Default = styled(Link)`
    &&& {
        text-decoration: none;
        color: inherit;

        &:visited {
            color: inherit;
        }
    }
`;

export default {
    Anchor,
    Default,
};
