import { parse } from 'marked';
import React, { useCallback } from 'react';
import GeneralStyled from '../../../components/styled-components';

const QuestionHeader = ({ question, grayOutQuestion=false }) => {
    const getFormattedQuestionText = useCallback(questionObj => {
        const questionText = (questionObj.required ? '' : '(Optional) ') + questionObj.question;
        const markedHtmlQuestion = parse(questionText);
        // question that includes for example words like: "Applicant's" after parse become "Applicant&#39;s"
        // so it's a temp solution to ignore this chars.
        return markedHtmlQuestion.replace('&#39;', '\'');
    }, []);
    if (grayOutQuestion) {
        return (
            <GeneralStyled.GrayStyledMarkDown>{getFormattedQuestionText(question)}</GeneralStyled.GrayStyledMarkDown>);
    }
    else {
        return <GeneralStyled.StyledMarkDown>{getFormattedQuestionText(question)}</GeneralStyled.StyledMarkDown>;
    }
};

export default QuestionHeader;
