import styled from 'styled-components';
import { 
    Container as _Container,
    Box as _Box,
    Typography
} from '@material-ui/core';


const CoverContainer = styled.div`
    position: relative;
    height: 100vh;
    background-image: url(${(props) => props.backgroundImage });
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    ${({ theme }) => `
        ${theme.breakpoints.down('sm')} {
           height: 215px;
        }
    `}
`;

const CoverIconsContainer = styled(_Container)`
    &&& {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        ${({ theme }) => `
            ${theme.breakpoints.down('sm')} {
                height: auto;
                padding: 5px 0 ${theme.spacing(4)}px;
            }
        `}
    }
`;

const CoverLayer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.primary.main};
    opacity: 0.9;
`;

const ItemContainer = styled(_Box)`
    ${({ theme }) => `
        padding: ${theme.spacing(4)}px 0;
    `}
`;

const IconContainer = styled(_Box)`
    display: inline-block;

    &&& {
        ${({ theme }) => `
            ${theme.breakpoints.down('md')} {
                svg {
                    width: 60px;
                    height: 60px;
                }
            }

            ${theme.breakpoints.down('sm')} {
                margin-right: ${theme.spacing(2)}px;
                svg {
                    rect, path {
                        fill: ${theme.palette.primary.main};
                    }
                }
            }
        `}
    }
`;

const TextContainer = styled(_Box)`
    display: inline-block;
    width: 175px;
    vertical-align: top;

    ${({ theme }) => `
        margin-left: ${theme.spacing(2)}px;
        padding-top: ${theme.spacing(2)}px;

        ${theme.breakpoints.down('md')} {
            display: block;
            margin-left: 0;
        }

        ${theme.breakpoints.down('sm')} {
            display: inline-block;
            padding-top: 0;
        }
    `}
`;

const IconTitle = styled(Typography)`
    &&& {
        color: #ffffff;

        ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightBold};

            ${theme.breakpoints.down('sm')} {
                color: #191919
            }              
        `}
    }
`;

const IconText = styled(Typography)`
    &&& {
        color: #ffffff;

        ${({ theme }) => `
            ${theme.breakpoints.down('sm')} {
                color: #191919
            }              
        `}
    }
`;

const LogoContainer = styled(_Box)`
    position: absolute;
    top: 20px;
    left: 32px;

    ${({ theme }) => `
        ${theme.breakpoints.up('md')} {
           display: none;
        }
    `}
`;

export default {
    CoverContainer,
    CoverIconsContainer,
    CoverLayer,
    ItemContainer,
    IconContainer,
    IconTitle,
    IconText,
    TextContainer,
    LogoContainer,
}
