import styled from 'styled-components';
import { default as _TextField } from '@material-ui/core/TextField';
import { default as _InputLabel } from '@material-ui/core/InputLabel';
import { default as _Checkbox } from '@material-ui/core/Checkbox';
import { KeyboardDatePicker as _KeyboardDatePicker } from '@material-ui/pickers';

const TextField = styled(_TextField)`
    &&& {
        width: 100%;
    }
    &&& input {
        display: block;
        padding: 0 19px;
        height: 32px;
        width: 100%;
        border-radius: 3px;
        border: 1px solid #d5d8db;
        background: #fafafa;
        color: #050505;
        font-size: 13px;
        box-shadow: none;
        ::placeholder {
            font-weight: ${({ theme }) => theme.typography.fontWeightLight};
        }
    }
    &&& .Mui-focused input {
        border: 1px solid ${({ theme }) => theme.palette.primary.main};
        ::placeholder {
            font-weight: ${({ theme }) => theme.typography.fontWeightLight};
        }
    }
    &&& .Mui-focused {
        color: #636363;
    }
    &&& .MuiInput-formControl {
        margin-top: 22px;
    }
`;

const InputLabel = styled(_InputLabel)`
    &&& {
        margin: 0 0 6px;
        width: 100%;
        text-align: left;
    }
`;

const Checkbox = styled(_Checkbox)`
    &&:hover,
    &&.Mui-checked:hover {
        background-color: transparent;
    }
    &&.MuiCheckbox-colorPrimary.Mui-checked {
        color: ${({ theme }) => theme.palette.primary.main};
    }
`;

const RegisterTextField = styled(TextField)`
    &&& .MuiFormHelperText-root.Mui-error {
        color: red;
    }
    &&& .MuiFormLabel-root.Mui-error {
        color: rgba(0, 0, 0, 0.54);
    }
    &&& .Mui-focused input {
        border-color: ${({ checked, theme }) => (checked ? '#f44336' : theme.palette.primary.main)};
    }
    &&& input {
        border-color: ${({ checked }) => (checked ? '#f44336' : 'd5d8db')};
    }
    &&& {
        &.small {
            width: 48%;
        }
    }
    &&& .MuiInput-formControl {
        margin-top: 25px;
    }
`;

const FormTextField = styled(_TextField)`
    &&& {
        max-width: 248px;
        min-width: 100%;
        max-height: 57px;
        margin-bottom: 0px;
        .MuiFormLabel-root {
            font-size: 16px;
            font-weight: ${({ theme }) => theme.typography.fontWeightLight};
            color: rgba(26, 26, 26, 0.54);
            line-height: 22px;
        }
        &&& .MuiInputLabel-shrink {
            font-size: 12px;
            font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
            color: #919191;
        }
        .MuiInputBase-input {
            font-size: 16px;
            font-weight: ${({ theme }) => theme.typography.fontWeightLight};
            line-height: 22px;
            &:focus {
                background-color: transparent;
            }
        }
        .MuiInput-underline:before {
            opacity: 0.42;
            border-bottom: 1px solid #010101;
        }

        .MuiInput-underline:after {
            opacity: 1;
        }
    }
`;

const KeyboardDatePicker = styled(_KeyboardDatePicker)`
    &&& {
        &&& .MuiInput-underline:before {
            border-bottom: 1px solid #e6e6e6;
        }
        &.no-shrink-label {
            &&& .MuiInputLabel-shrink {
                display: none;
            }
        }
    }
`;

export default {
    TextField,
    InputLabel,
    Checkbox,
    RegisterTextField,
    FormTextField,
    KeyboardDatePicker,
};
