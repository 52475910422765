import styled from 'styled-components';
import {
    Typography as _Typography,
    Container as _Container,
    AppBar as _AppBar,
    Toolbar as _Toolbar,
} from '@material-ui/core';
import { StyledForm } from 'components';

const Container = styled(_Container)`
    &&& {
        padding: 0;
        margin: 0;
        ${({ theme }) => `
            ${theme.breakpoints.down('md')} {
                display: flex;
                margin: ${theme.spacing(0.5)}px;
                flex-wrap: nowrap;
                overflow-x: auto;
            }
            ${theme.breakpoints.up('lg')} {
                max-width: 310px;
            }
        `}
    }
`;

const FullWidthSnapshotContainer = styled(_Container)`
    &&& {
        padding: 0;
        margin: 0;
        width: 50%;
        ${({ theme }) => `
            ${theme.breakpoints.down('md')} {
                display: flex;
                margin: ${theme.spacing(0.5)}px;
                flex-wrap: nowrap;
                overflow-x: auto;
            }
        `}
    }
`;

const FormContainer = styled(_Container)`
    &&& {
        min-height: 332px;
        padding: 32px;
        background-color: white;
        display: flex;
        flex-direction: column;
        border-radius: 2px;
    }
`;

const Typography = styled(_Typography)`
    &&& {
        color: #191919;
        text-align: left;
        ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightBold};
        `}
        &.sticky {
            ${({ theme }) => `
                font-weight: ${theme.typography.fontWeightBold};
                margin-top: ${theme.spacing(2)}px;
            `}
        }
        &.bucket{
            font-weight: ${({ theme, selected }) => selected ? theme.typography.fontWeightMedium : theme.typography.fontWeightLight};
            padding-bottom: ${({ theme, selected }) => selected ? theme.spacing(0.75) : theme.spacing(0)}px;
            color: ${({ selected }) => selected ? '#191919' : '#919191'};
            border-bottom: ${({ selected }) => selected ? '3px solid #191919' : 'none'};
            margin-top: unset;
        }
    }
`;

const FormControl = styled(StyledForm.FormControl)`
    &&& {
        margin: 0 0 6px;
        min-height: 57px;
    }

    &&& .MuiInputBase-input {
        padding: ${({ theme }) => theme.spacing(1)}px;
    }
`;

const AppBar = styled(_AppBar)`
    &&& {
        top: 80px;
        bottom: auto;
        background-color: #f2f2f2;
        box-shadow: none;
    }
`;
const Toolbar = styled(_Toolbar)`
    &&& {
        ${({ theme }) => `
            ${theme.breakpoints.up('lg')} {
                max-width: 960px;
                width: inherit;
                align-self: center;
            }
            ${theme.breakpoints.down('md')} {
                padding-left: ${theme.spacing(4)}px;
                padding-right: ${theme.spacing(4)}px;
            }
        `}
        &.header {
            ${({ theme }) => `
                padding-left: ${theme.spacing(0)}px;
                padding-right: ${theme.spacing(0)}px;
            `}
        }
    }
`;

export default {
    Container,
    FullWidthSnapshotContainer,
    FormContainer,
    Typography,
    FormControl,
    AppBar,
    Toolbar,
};
