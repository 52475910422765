import React, { useEffect, useState } from 'react';
import CustomForm from './CustomForm';
import CustomTextField from './CustomField';
import { NotificationBanner } from 'components/banner';
import { useChangeTeamName } from 'api/manage-teams';


const ChangeTeamName = () => {
    const [newTeamName, setNewTeamName] = useState('');
    const [teamId, setTeamId] = useState('');
    const [teamIdValidationError, setTeamIdValidationError] = useState('');
    const [openNotification, setOpenNotification] = useState(false);
    const [{ data, loading, error }, changeTeamName] = useChangeTeamName();

    useEffect(() => {
        if (!loading && data) {
            setOpenNotification(true);
            setTeamIdValidationError('');
            setNewTeamName('');
            setTeamId('');
        } else if (error?.response.data.invalid_team) {
            setTeamIdValidationError(`invalid team - no team with id ${teamId}`);
        }
    }, [data, error]);

    const submitCreateTeam = e => {
        e.preventDefault();
        changeTeamName({
            data: {
                team_id: teamId,
                new_name: newTeamName,
            },
        });
    };

    return (
        <>
            <CustomForm
                handleSubmit={submitCreateTeam}
                header="Change team name"
                buttonText="Change team name"
                fieldsList={[
                    <CustomTextField
                        value={teamId}
                        setValue={setTeamId}
                        name="team id"
                        validationError={teamIdValidationError}
                        setValidationError={setTeamIdValidationError}
                        type="number"
                    />,
                    <CustomTextField
                        value={newTeamName}
                        setValue={setNewTeamName}
                        name="new team name"
                        validationError=""
                        setValidationError={() => {}}
                    />,
                ]}
            />
            <NotificationBanner
                openNotification={openNotification} alertText="Team name was changed."
                setOpenNotification={setOpenNotification} isError={false}
            />
        </>
    );
};

export default ChangeTeamName;
