import React, { useEffect, useState } from 'react';
import NewQuestionnaire from './pages/new-questionnaire/components/NewQuestionnaire';
import { Policy, Questionnaire } from './pages';
import { useAxiosClient, useEnv } from 'hooks';
import { basicPolicyRequestQuery } from 'queries/policyRequest';
import { LinearProgress } from '@material-ui/core';
import GeneralStyled from './pages/components/styled-components';
import { ProductsAbbreviatedName } from 'utils/enums';
import Box from '@material-ui/core/Box';
import { useParams } from 'react-router-dom';
import ErrorBoundary from '../../hoc/ErrorBoundary';


const ChooseQuestionnaireByProduct = () => {
    const [policyRequest, setPolicyRequest] = useState(null);
    const [productName, setProductName] = useState(null);
    const { newQuestionnaire } = useEnv();
    const { policyRequestId } = useParams();
    const newQuestionnaireProductNames = [ProductsAbbreviatedName.MPL];
    const axiosClient = useAxiosClient();
    const [, getFullPolicyRequest] = axiosClient(
        {
            method: 'post',
            url: `/policyRequest/${policyRequestId}`,
            data: { query: basicPolicyRequestQuery },
        },
        { manual: true },
    );

    useEffect(() => {
        const fetchAndSetPolicyRequest = async () => {
            const res = await getFullPolicyRequest();
            const policyReq = res.data;
            setPolicyRequest(policyReq);
            setProductName(policyReq.product.abbreviated_name);
        };

        fetchAndSetPolicyRequest();
    }, []);

    return (
        <Box component={GeneralStyled.QuestionnaireContainer}>
            {!policyRequest || !productName ? (
                <LinearProgress />
            ) : newQuestionnaireProductNames.includes(productName) ? (
                <ErrorBoundary>
                    <NewQuestionnaire fullPolicyRequest={policyRequest} />
                </ErrorBoundary>
            ) : newQuestionnaire ? (
                <Questionnaire fullPolicyRequest={policyRequest} />
            ) : (
                <Policy />
            )}
        </Box>
    );
};

export default ChooseQuestionnaireByProduct;
