import { useContext } from 'react';
import { axiosClientContext } from 'context';

export const useAxiosClient = () => {
    const { axiosClient } = useContext(axiosClientContext);
    return axiosClient;
}

export const useAxiosInstance = () => {
    const { axiosInstance } = useContext(axiosClientContext);
    return axiosInstance;
}
