import React, { useState } from 'react';
import { CompanyPopup } from 'components';
import { Box, Collapse, Hidden, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { useBreakpoint } from 'hooks';
import StyledTable from 'components/table/styled-components';
import Table from '@material-ui/core/Table';
import { useHistory } from 'react-router-dom';
import InnerTableIncompleteBucket from './InnerTableIncompleteBucket';
import { MainRowMobile, MainRowTabletDesktop } from 'components/table/QuotesSharedComponents';
import { getBrokerInitialsColor } from '../../utils';
import CompanyInfo from 'components/table/CompanyInfo';
import { useSelector } from 'react-redux';

const QuoteIncompleteRow = ({ client, companyId }) => {
    const [open, setOpen] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const companyPolicies = client.data;
    const numOfPolicies = companyPolicies.length;
    const multiplePolicies = numOfPolicies > 1;
    const isSm = useBreakpoint('down', 'sm');
    const history = useHistory();
    const { user } = useSelector(state => state.user);
    const teamMembersIds = user.team_members_ids;

    return (
        <>
            <Hidden smDown>
                <MainRowTabletDesktop
                    multiplePolicies={multiplePolicies}
                    setOpen={setOpen}
                    open={open}
                    numOfPolicies={numOfPolicies}
                    client={client}
                    companyPolicies={companyPolicies}
                    companyId={companyId}
                />
            </Hidden>
            <Hidden mdUp>
                <MainRowMobile
                    multiplePolicies={multiplePolicies}
                    setOpen={setOpen}
                    open={open}
                    numOfPolicies={numOfPolicies}
                    client={client}
                    companyPolicies={companyPolicies}
                    companyId={companyId}
                />
            </Hidden>
            <TableRow>
                <TableCell
                    style={{
                        padding: 0,
                        borderBottom: 'none',
                        backgroundColor: open ? '#F8F8F8' : '#F2F2F2',
                        borderTop: open && client.policiesRequestRows ? '1px solid #F2F2F2' : 'none',
                    }}
                    colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {client.policiesRequestRows &&
                            client.policiesRequestRows.map((policyRequest, index) => (
                                <Box
                                    key={`inner_row_${policyRequest.id}_${companyId}`}
                                    borderBottom="2px solid #f2f2f2"
                                    my={2}
                                    px={4}
                                    fontWeight="fontWeightBold"
                                    mb={0}
                                    pb={2}>
                                    <Box
                                        border="none"
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                        gridGap={12}>
                                        <StyledTable.Avatar
                                            hidden={!policyRequest?.broker?.initials}
                                            style={{
                                                backgroundColor: getBrokerInitialsColor(
                                                    policyRequest?.broker,
                                                    teamMembersIds,
                                                ),
                                                height: !isSm && '32px',
                                                width: !isSm && '32px',
                                                fontSize: !isSm && '14px',
                                            }}>
                                            {policyRequest?.broker?.initials}
                                        </StyledTable.Avatar>
                                        <Typography variant="subtitle2" component="div">
                                            {policyRequest.header || 'Get a Quote'}
                                        </Typography>
                                    </Box>
                                    <Table size="small">
                                        <StyledTable.TableHead className="inner">
                                            {!isSm && (
                                                <StyledTable.TableRow bottom="1px solid #F2F2F2" _bordertop="0" />
                                            )}
                                            <StyledTable.TableRow _bordertop="none">
                                                <StyledTable.TableCellHead
                                                    className="inner"
                                                    width={isSm ? '33%' : '15%'}>
                                                    <Typography variant="overline">NOTE</Typography>
                                                </StyledTable.TableCellHead>
                                                {isSm ? (
                                                    <StyledTable.TableCellHead className="inner" width="0%">
                                                        <Typography variant="overline" />
                                                    </StyledTable.TableCellHead>
                                                ) : (
                                                    <StyledTable.TableCellHead className="inner" width="55%" />
                                                )}
                                            </StyledTable.TableRow>
                                        </StyledTable.TableHead>
                                        <TableBody>
                                            <InnerTableIncompleteBucket
                                                collapseData={policyRequest.collapseData[0]}
                                                handleButton={
                                                    !companyPolicies[index].product
                                                        ? () => setOpenPopup(true)
                                                        : () =>
                                                            history.push(`/questionnaire/policy/${policyRequest.id}`)
                                                }
                                                buttonText={
                                                    !companyPolicies[index].product
                                                        ? 'Start Application'
                                                        : 'Complete Application'
                                                }
                                            />
                                        </TableBody>
                                    </Table>
                                </Box>
                            ))}
                        <CompanyInfo client={client} />
                    </Collapse>
                </TableCell>
            </TableRow>
            <CompanyPopup
                open={openPopup}
                handleClose={() => setOpenPopup(false)}
                company={companyPolicies[0].company}
                policyRequestID={companyPolicies[0].id}
            />
        </>
    );
};

export default QuoteIncompleteRow;
