export default {
    myAccounts: {
        title: 'My Accounts',
        showAccounts: 'Show Accounts:',
        actions: {
            addAccount: 'ADD ACCOUNT',
        },
        table: {
            headers: {
                company: 'COMPANY',
                lastUpdated: 'LAST_UPDATED',
            }
        },
        dialog: {
            addAccount: {
                title: 'Get a Qoute',
                step1: {
                    title: 'Let’s get started with the basics.',
                    inputs: {
                        companyName: 'Company Name',
                        companyWebsite: 'Company Website',
                        industry: 'Industry',
                    },
                    errors: {
                        companyName: 'Company Name is required',
                        companyWebsite: 'Company URL is required',
                        industry: 'Industry is required',
                    },
                },
                step2: {
                    title: 'What product are you looking for?',
                },
            },
        },
    },
};
