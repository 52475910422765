import { useSelector } from 'react-redux';
import { useMemo } from 'react';


export const useHasInvalidDates = () => {
    const {dateValidity} = useSelector(state => state.company);
    const hasInvalidDates = useMemo(() => {
        return Object.values(dateValidity).includes(false);
    }, [dateValidity]);

    return hasInvalidDates;
};
