import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/react';
import Styled from './styled-components';
import store from 'stores/store';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { setCompany, setCompanyAttribute, setUpdatingCompanyName, setPolicyRequestAttribute } from 'stores/company/companySlice';
import { companyQuery, companyScanQuery } from 'queries/company';
import { useEnv, useBreakpoint, useAxiosClient } from 'hooks';
import { SecurityScoreStatus, ProductsAbbreviatedName, SubmissionsBucket, Permissions } from 'utils/enums';
import TextTruncate from 'react-text-truncate';
import { Error, StyledContainer, StyledTypography, StyledAction, StyledLink, Loading, StyledInput } from 'components';
import PolicyRequest from './components/policy-request';
import PolicyRequestsTab from './components/policy-requests-tab';
import QuotePacketMonitor from './components/policy-request/components/QuotePacketMonitor';
import RecommendedProduct from './components/recommended-product';
import AdditionalAction from './components/additional-action';
import NotificationBanner from 'components/banner/NotificationBanner';
import { ReactComponent as PieChartIcon } from 'components/icons/pie-chart.svg';
import { ReactComponent as CalculatorIcon } from 'components/icons/calculator.svg';
import { ReactComponent as ScaleIcon } from 'components/icons/scale.svg';
import { PermissionDenied, PermissionGranted, PermissionRequired } from 'components/permission-required';
import { Box, CircularProgress, Typography, FormControl, InputAdornment } from '@material-ui/core';
import { formatDatePickerDate } from 'utils';
import CreateIcon from '@material-ui/icons/Create';
import { theme } from 'components/theme/styles';
import _ from 'lodash';


const getSecurityScoreStatus = company => {
    const minutesFromCompanyCreation = (dayjs.utc().unix() - dayjs.utc(company.created_at).unix()) / 60;
    if (company.latest_scan?.completed_successfully === null || (!company.latest_scan && minutesFromCompanyCreation < 2)) {
        return SecurityScoreStatus.IN_PROGRESS;
    } else if (company.latest_scan?.completed_successfully === false) {
        return SecurityScoreStatus.ERROR;
    } else if (
        company.latest_scan?.completed_successfully &&
            dayjs.utc(dayjs.utc()).diff(company.latest_scan?.end_time, 'days') <= 13
    ) {
        return SecurityScoreStatus.COMPLETED;
    } else {
        return SecurityScoreStatus.NOT_REQUESTED;
    }
}

const securityScoreStatusToDescription = {
    [SecurityScoreStatus.NOT_REQUESTED]: 'See findings from our scan of vulnerabilities, technologies, and signs of digital risk. This report is based on the company\'s most recent submission.',
    [SecurityScoreStatus.IN_PROGRESS]: 'Your Security Score report will be available to download shortly.',
    [SecurityScoreStatus.COMPLETED]: 'See findings from our scan of vulnerabilities, technologies, and signs of digital risk. This report is based on the company\'s most recent submission.',
    [SecurityScoreStatus.ERROR]: (
        <>
            <StyledTypography.FadedTypography component="span" variant="subtitle2">
                We found something that needs a closer look. We’ll get back to you shortly, but if you have any questions, please contact us at
            </StyledTypography.FadedTypography>
            <Box fontWeight="fontWeightMedium" component="span">
                {' '} underwriting@at-bay.com.
            </Box>
        </>
    ),
}


const Company = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isSm = useBreakpoint('down', 'sm');
    const isLg = useBreakpoint('up', 'lg');
    const axiosClient = useAxiosClient();
    const [showCaseStudies, setShowCaseStudies] = useState(false);
    const [openNotification, setOpenNotification] = useState(true);
    const { company, updatingCompanyName } = useSelector(state => state.company);
    const [companyUpdatedName, setCompanyUpdatedName] = useState(company?.name || '');
    const [editingName, setEditingName] = useState(false);
    const [editNameToggled, setEditNameToggled] = useState(false);
    const [nameError, setNameError] = useState(false);
    const { companyId } = useParams();
    const { apiBaseUrl, editCompanyName, teamView, newBpRenewal } = useEnv();
    const scanDate = dayjs.utc(company?.latest_scan?.end_time);
    const [boldSnackbarText, setBoldSnackbarText] = useState('');
    const [optionToQuotesId, setOptionToQuotesId] = useState();

    const snackbarText = useMemo(() => {
        if (company?.policies_owners) {
            const policies_owners_name = [...new Set(company.policies_owners.map(owner => owner.broker_user.username))];
            if (policies_owners_name.length > 1) {
                let snackbarText = [...policies_owners_name].splice(0, 2);
                if (policies_owners_name.length > 2) {
                    const otherBrokersCount = policies_owners_name.length - 2;
                    snackbarText = `${snackbarText.join(', ')} and ${otherBrokersCount}
                                    other${otherBrokersCount > 1 ? 's' : ''}`;
                }
                else {
                    snackbarText = snackbarText.join(' and ');
                }
                return snackbarText;
            }
        }
        return null;
    }, [company?.policies_owners]);
    const newSnackbarText = useMemo(() => {
        setOptionToQuotesId(null);
        if (!company) {
            return null;
        }
        const bindRequestedSubmissions = company.viewable_policy_requests.filter(
            policy => policy.athena_submissions_bucket === SubmissionsBucket.BIND_REQUESTED,
        );
        const conditionalBinderSubmissions = company.viewable_policy_requests.filter(
            policy => policy.athena_submissions_bucket === SubmissionsBucket.BOUND && policy.conditionally_bound,
        );
        const policyRequests = bindRequestedSubmissions.concat(conditionalBinderSubmissions);
        const numberOfPolicies = policyRequests;
        let numberOfPreBindContingencies = 0;
        let numberOfPostBindContingencies = 0;

        policyRequests.forEach((policy) => {
            if (policy.contingencies) {
                policy.contingencies.forEach(contingency => {
                    if (!contingency.checked) {
                        if (contingency.is_pre_bind) {
                            numberOfPreBindContingencies += 1;
                        } else {
                            numberOfPostBindContingencies += 1;
                        }
                    }
                });
            }
        });
        if (numberOfPostBindContingencies + numberOfPreBindContingencies < 1) {
            return null;
        }
        else if (numberOfPolicies > 1) {
            const productsList = policyRequests.map(policy => policy.product.name);
            setBoldSnackbarText('');
            return `Conditional binders for the following products have outstanding contingencies:
            ${productsList.join(', ')} | To review contingencies for each product, hit the “View Details” button below`;
        } else if (numberOfPreBindContingencies > 0) {
            const selectedPolicy = _.head(policyRequests);
            const preferredOptionToQuote = selectedPolicy?.option_to_quotes?.find(
                optionToQuote => optionToQuote.quote.preferred
            );
            setBoldSnackbarText('Complete pre-bind contingencies');
            setOptionToQuotesId(preferredOptionToQuote?.id);
            return `${selectedPolicy?.product?.name} bind request under review | `;
        } else {
            const selectedPolicy = _.head(policyRequests);
            const preferredOptionToQuote = selectedPolicy?.option_to_quotes?.find(
                optionToQuote => optionToQuote.quote.preferred
            );
            setBoldSnackbarText('Complete outstanding contingencies');
            setOptionToQuotesId(preferredOptionToQuote.id);
            return `${selectedPolicy?.product?.name} conditional binder will expire in 30 days. | `;
        }
    }, [company?.viewable_policy_requests]);

    const hasEditablePolicies = useMemo(() => {
        if (!company) {
            return true;
        }
        return company.viewable_policy_requests.filter(policy => !policy.was_bound).length >= 1;
    }, [company])
    const displaySecurityScoreAction = useMemo(() => {
        if (!company) {
            return false;
        }
        return company.viewable_policy_requests.filter(policy => policy.product?.abbreviated_name !== ProductsAbbreviatedName.MPL).length >= 1;
    }, [company])
    const canEditCompanyName = hasEditablePolicies && editCompanyName;


    const securityScoreStatus = useMemo(() => {
        if (!company) {
            return null;
        }
        return getSecurityScoreStatus(company);
    }, [company?.latest_scan]);

    const [{ loading, error }, getCompany] = axiosClient({
        method: 'post',
        url: `/company/${companyId}`,
    }, { manual: true });

    const [, getCompanyExtraData] = axiosClient({
        method: 'post',
        url: `/company/${companyId}`,
    }, { manual: true });

    const [, triggerSecurityScoreReport] = axiosClient({
        method: 'post',
        url: `/company/extended_scan/${companyId}`,
    }, { manual: true });

    const [, updateCompanyName] = axiosClient({
        method: 'put',
        url: `/company/${companyId}`,
    }, { manual: true });

    const requestSecurityScoreReport = async () => {
        const latestScan = { ...company.latest_scan, completed_successfully: null };
        dispatch(setCompanyAttribute({
            name: 'latest_scan',
            value: latestScan,
        }));
        const res = await triggerSecurityScoreReport({ data: { query: companyScanQuery } });
        dispatch(setCompanyAttribute({
            name: 'latest_scan',
            value: res.data.company.latest_scan,
        }));
        monitorSecurityScoreStatus(res.data.company)
    }

    const monitorSecurityScoreStatus = async company => {
        if (getSecurityScoreStatus(company) === SecurityScoreStatus.IN_PROGRESS) {
            const state = store.getState();
            if (!state.company.company) {
                return;
            }
            const res = await getCompanyExtraData({ data: { query: companyScanQuery } });
            for (const key in res.data.company) {
                dispatch(setCompanyAttribute({
                    name: key,
                    value: res.data.company[key],
                }));
            }
            setTimeout(() => monitorSecurityScoreStatus(res.data.company), 2000);
        }
    }

    const handleSaveChange = () => {
        if (!companyUpdatedName) {
            return;
        }
        closeAndSaveNewName();
    }

    const closeAndSaveNewName = async () => {
        setEditingName(false);

        if (companyUpdatedName) {
            if (companyUpdatedName.trim() !== company.name) {
                dispatch(setUpdatingCompanyName(true));
                try {
                    await updateCompanyName({ data: { query: {} , company_name: companyUpdatedName}});
                    setEditNameToggled(false);
                    dispatch(setCompanyAttribute({
                        name: 'name',
                        value: companyUpdatedName,
                    }));
                    for (const policyRequest of company.viewable_policy_requests) {
                        const policyRequestID = policyRequest.id;
                        dispatch(setPolicyRequestAttribute({
                            policyRequestID,
                            name: 'generating_quote_packet',
                            value: true
                        }));
                    }
                    dispatch(setUpdatingCompanyName(false));
                } catch (error) {
                    if (error.response?.data?.invalid_company_name) {
                        setNameError(true);
                    }
                    dispatch(setUpdatingCompanyName(false));
                    Sentry.captureException(error);
                }
            } else {
                setEditNameToggled(false);
                dispatch(setUpdatingCompanyName(false));
            }
        } else {
            setCompanyUpdatedName(company.name);
        }
    }

    const quotePacketsAreGenerating = useMemo(() => {
        if (!company) {
            return true;
        }
        for (const policyRequest of company.viewable_policy_requests){
            if ([undefined, true].includes(policyRequest.generating_quote_packet) || policyRequest.inProgress) {
                return true;
            }
        }
        return false;
    }, [company])

    const handleOnClick = () => {
        if (canEditCompanyName && !updatingCompanyName && !quotePacketsAreGenerating) {
            setEditNameToggled(true);
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            closeAndSaveNewName();
        } else if (e.key === 'Escape') {
            setCompanyUpdatedName(company.name);
            setEditNameToggled(false);
            setEditingName(false);
        }
    }

    useEffect(() => {
        const fetchCompany = async () => {
            const res = await getCompany({ data: { query: companyQuery }});
            dispatch(setCompany(res.data.company));
            setOpenNotification(true);
            if (displaySecurityScoreAction) {
                monitorSecurityScoreStatus(res.data.company);
            }
        }
        fetchCompany();
        return () => dispatch(setCompany(null));
    }, [companyId]);

    useEffect(() => {
        if (company) {
            setCompanyUpdatedName(company.name);
        }
    }, [company]);

    if ((!company && !error) || loading) {
        return <Loading />
    } else if (error) {
        return <Error />
    }

    return (
        <Box mb={2}>
            {!newBpRenewal && teamView && snackbarText &&
                <NotificationBanner
                    openNotification={openNotification}
                    setOpenNotification={setOpenNotification}
                    isError={false}
                    withIcon={false}
                    withContainer={true}
                    alertText={'Assigned to'}
                    markedText={snackbarText}
                />
            }
            {newBpRenewal && newSnackbarText && (
                <NotificationBanner
                    openNotification={openNotification}
                    setOpenNotification={setOpenNotification}
                    isError={false}
                    withIcon={false}
                    withContainer={true}
                    alertText={newSnackbarText}
                    markedText={boldSnackbarText}
                    onMarkedTextClick={
                        optionToQuotesId ? () => history.push(`/submission/policy_details/${optionToQuotesId}`) : null
                    }
                />
            )}
            <StyledContainer.BaseContainer disableGutters>
                <Box>
                    {canEditCompanyName ? (
                        editNameToggled ? (
                            <FormControl
                                style={{
                                    marginBottom: isSm ? '20px' : '26px',
                                    width: '100%',
                                    backgroundColor: editingName && 'white',
                                }}>
                                <StyledInput.FormTextField
                                    autoFocus
                                    inputProps={{
                                        style: {
                                            fontSize: isSm ? 24 : isLg ? 32 : 26,
                                            fontWeight: 700,
                                            padding: '4px 0px',
                                        },
                                    }}
                                    value={companyUpdatedName}
                                    error={nameError}
                                    helperText={nameError ? 'A similar name is already used in your Accounts' : ''}
                                    onChange={e => {
                                        setNameError(false);
                                        setCompanyUpdatedName(e.target.value);
                                        setEditingName(e.target.value ? true : false);
                                    }}
                                    onBlur={() => {
                                        setCompanyUpdatedName(company.name);
                                        setEditNameToggled(false);
                                        setEditingName(false);
                                        setNameError(false);
                                    }}
                                    onKeyDown={e => handleKeyPress(e)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                style={{ alignItems: 'center', cursor: 'pointer', paddingTop: '4px' }}>
                                                <Box
                                                    data-track="updateNameButton"
                                                    fontWeight="fontWeightMedium"
                                                    onClick={handleSaveChange}
                                                    onMouseDown={e => e.preventDefault()}
                                                    color={editingName ? theme.palette.primary.main : '#AFAFAF'}
                                                    fontSize={['12px', '12px', '16px']}>
                                                    {updatingCompanyName ? (
                                                        <CircularProgress color="primary" size={15} />
                                                    ) : (
                                                        'Update'
                                                    )}
                                                </Box>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        ) : (
                            <Box fontWeight="fontWeightBold" px={isSm ? 4 : isLg ? 0 : 7}>
                                <QuotePacketMonitor
                                    policyRequests={company.viewable_policy_requests}
                                    monitor={updatingCompanyName || quotePacketsAreGenerating}
                                />
                                <Styled.CompanyNameBox onClick={handleOnClick}>
                                    <Typography
                                        variant="h3"
                                        data-track="CompanyNameAndPencil"
                                        style={{ overflowWrap: 'anywhere' }}>
                                        {company.name}
                                        {updatingCompanyName || quotePacketsAreGenerating ? (
                                            <span style={{ paddingLeft: '8px' }}>
                                                <CircularProgress color="inherit" size={15} />
                                            </span>
                                        ) : (
                                            <Box
                                                component={Styled.IconSpan}
                                                pt={1}
                                                data-testid="pencil-icon"
                                                position="relative"
                                                top="2px"
                                                fontSize={[24, 24, 30]}>
                                                <CreateIcon />
                                            </Box>
                                        )}
                                    </Typography>
                                </Styled.CompanyNameBox>
                            </Box>
                        )
                    ) : (
                        <Box fontWeight="fontWeightBold" pl={isSm ? 4 : isLg ? 0 : 7}>
                            <Typography variant="h3" style={{ overflowWrap: 'anywhere' }}>
                                {company.name}
                            </Typography>
                        </Box>
                    )}
                    <Box mb={5} fontWeight="fontWeightBold" pl={isSm ? 4 : isLg ? 0 : 7}>
                        {company.domain && (
                            <Styled.A href={`http://${company.domain}`} target="_blank" rel="noopener noreferrer">
                                <Typography variant="subtitle1" component="span">
                                    {company.domain}
                                </Typography>
                            </Styled.A>
                        )}
                    </Box>
                </Box>
                {newBpRenewal ? (
                    <PolicyRequestsTab />
                ) : (
                    company.viewable_policy_requests.map(policyRequest => (
                        <PolicyRequest policyRequestID={policyRequest.id} company={company} key={policyRequest.id} />
                    ))
                )}

                <PermissionRequired requiredPermissions={[Permissions.SUBMISSION_WRITE]}>
                    <PermissionGranted>
                        {company.recommended_products.length > 0 && (
                            <Box py={[0, 3, 6, 10]} px={[0, 4, 7, 10]} bgcolor="white" mb={3}>
                                <Box fontWeight="fontWeightBold">
                                    <Typography variant="h5">Additional Quotes</Typography>
                                </Box>
                                <Box
                                    component={StyledTypography.FadedTypography}
                                    variant="subtitle2"
                                    fontWeight="fontWeightLight">
                                    Based on your answers, you qualify for additional insurance products. Answer only a few more
                                    questions to get your At-Bay quote.
                                </Box>
                                <Box width="100%" height="4px" bgcolor="#E0E0E0" my={3} />
                                {company.recommended_products.map(product => (
                                    <RecommendedProduct key={product.id} product={product} companyId={company.id} />
                                ))}
                            </Box>
                        )}
                    </PermissionGranted>
                    <PermissionDenied>
                        <></>
                    </PermissionDenied>
                </PermissionRequired>
                {displaySecurityScoreAction && (
                    <AdditionalAction
                        icon={<PieChartIcon />}
                        title="Security Score"
                        description={securityScoreStatusToDescription[securityScoreStatus]}
                        DescriptionTypography={
                            securityScoreStatus === SecurityScoreStatus.ERROR ? Typography : undefined
                        }
                        actions={
                            <Box fontWeight="fontWeightMedium">
                                {securityScoreStatus === SecurityScoreStatus.COMPLETED ? (
                                    <Box fontWeight="fontWeightLight">
                                        <Box fontWeight="fontWeightMedium" component="span">
                                            <Typography variant="subtitle2" color="primary" component="span">
                                                <StyledLink.Anchor
                                                    href={`${apiBaseUrl}/company/security_score_report/${company.id}/${company.security_score_filename}`}
                                                    target="_blank">
                                                    Download Report
                                                </StyledLink.Anchor>
                                            </Typography>
                                        </Box>
                                        <Box component="span" mx={1}>
                                            <StyledTypography.FadedTypography variant="subtitle2" component="span">
                                                ·
                                            </StyledTypography.FadedTypography>
                                        </Box>
                                        <StyledTypography.FadedTypography variant="subtitle2" component="span">
                                            Updated{' '}
                                            {dayjs.utc().isSame(scanDate, 'date')
                                                ? scanDate.fromNow()
                                                : formatDatePickerDate(scanDate)}
                                        </StyledTypography.FadedTypography>
                                    </Box>
                                ) : securityScoreStatus === SecurityScoreStatus.NOT_REQUESTED ? (
                                    <StyledAction.Base onClick={requestSecurityScoreReport}>
                                        <Typography variant="subtitle2" color="primary">
                                            Request Report
                                        </Typography>
                                    </StyledAction.Base>
                                ) : securityScoreStatus === SecurityScoreStatus.IN_PROGRESS ? (
                                    <Box display="flex" alignItems="center" flexDirection="row">
                                        <StyledTypography.FadedTypography variant="subtitle2">
                                            <Box mr={[0, 2]} display="flex">
                                                <CircularProgress color="inherit" size={15} />
                                            </Box>
                                        </StyledTypography.FadedTypography>
                                        <StyledTypography.FadedTypography variant="subtitle2">
                                            Generating report
                                        </StyledTypography.FadedTypography>
                                    </Box>
                                ) : null}
                            </Box>
                        }
                    />
                )}
                <AdditionalAction
                    icon={<CalculatorIcon />}
                    title="Data Breach Cost Calculator"
                    description="See findings from our scan of vulnerabilities, technologies, and signs of digital risk."
                    actions={
                        <>
                            {company.financial_exposure_ready && (
                                <>
                                    <Box fontWeight="fontWeightMedium" component="span">
                                        <Typography variant="subtitle2" color="primary" component="span">
                                            <StyledLink.Anchor
                                                href={`${apiBaseUrl}/company/export_calculator/${company.id}`}>
                                                Download PPTX
                                            </StyledLink.Anchor>
                                        </Typography>
                                    </Box>
                                    <Box borderLeft="1px solid #E0E0E0" mx={2} component="span" />
                                </>
                            )}
                            <Box fontWeight="fontWeightMedium" component="span">
                                <Typography variant="subtitle2" color="primary" component="span">
                                    <StyledLink.Default to={`/company/calculator/${company.id}`}>
                                        Open Calculator
                                    </StyledLink.Default>
                                </Typography>
                            </Box>
                        </>
                    }
                />
                {company.case_studies.length > 0 && !showCaseStudies && (
                    <AdditionalAction
                        icon={<ScaleIcon />}
                        overlineTitle={`CASE STUDY [${company.case_studies.length}]`}
                        title={company.case_studies[0].title}
                        description={<TextTruncate line={1} text={company.case_studies[0].long_text} />}
                        actions={
                            <Box fontWeight="fontWeightMedium">
                                <StyledAction.Base onClick={() => setShowCaseStudies(true)}>
                                    <Typography variant="subtitle2" color="primary">
                                        - Show More
                                    </Typography>
                                </StyledAction.Base>
                            </Box>
                        }
                    />
                )}
                {showCaseStudies &&
                    company.case_studies.map((caseStudy, index) => (
                        <AdditionalAction
                            key={index}
                            icon={<ScaleIcon />}
                            overlineTitle="CASE STUDY"
                            title={caseStudy.title}
                            description={caseStudy.long_text}
                            actions={
                                index + 1 === company.case_studies.length ? (
                                    <Box fontWeight="fontWeightMedium">
                                        <StyledAction.Base onClick={() => setShowCaseStudies(false)}>
                                            <Typography variant="subtitle2" color="primary">
                                                - Show Less
                                            </Typography>
                                        </StyledAction.Base>
                                    </Box>
                                ) : null
                            }
                        />
                    ))}
            </StyledContainer.BaseContainer>
        </Box>
    );
};

export default Company;
