import React, { useEffect, useState, useRef, useMemo } from 'react';
import { InputAdornment, FormControl, Box } from '@material-ui/core';
import GeneralStyled from '../../../components/styled-components';
import Markdown from 'markdown-to-jsx';
import InfoTooltip from '../../../components/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setQuestionValue } from 'stores/questions/questionsSlice';
import { questionHasError, scrollToQuestionError } from 'utils/questionnaireSequence';
import { setNewQuestionData } from 'stores/questionsErrors/questionsErrorsSlice';
import { useBreakpoint, useTextQuestionStyles } from 'hooks';
import {
    addAnswer,
    addVisibleQuestion,
    setQuestionnaireStateToAnswerChanging,
    setQuestionnaireStateToChangesSaved,
} from 'stores/questionnaire/questionnaireSlice';
import NumberFormat from 'react-number-format';
import { AnswersValuesType } from 'utils/enums';
import Styled from 'modules/questionnaire/pages/new-questionnaire/components/questions/styled-components';

function NumberFormatCustom(props) {
    const { type, inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            allowNegative={false}
            decimalScale={props.type === AnswersValuesType.INTEGER_ANSWER ? 0: undefined }
            thousandSeparator
        />
    );
}

const NumberQuestion = ({ question, updateForm, setScroll, scroll, reSubmitted, setChangeMade }) => {
    const questionRef = useRef();
    const inputFieldRef = useRef();
    const placeholderLabelRef = useRef();
    const dispatch = useDispatch();
    const answer = useSelector(state => state.questions.questionsToAnswers[question.id]);
    const questionMetaData = useSelector(
        state => state.questionnaire.questionsMetadata.find(item => item.questionId === question.id)?.metadata,
    );
    const answerType = question.optional_answers[0].type
    const { firstQuestionWithError, submitFailed } = useSelector(state => state.questionErrors);
    const isSm = useBreakpoint('down', 'sm');
    const [firstChange, setFirstChange] = useState(true);
    const [values, setValues] = useState(answer || '');
    const [focus, setFocus] = useState(Boolean(values));
    const placeholder = useMemo(() => {
        return values || focus ? '' : question.name;
    }, [values, focus, question.name]);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(questionHasError(submitFailed, answer, question.required, true));
    const [inputStyles, inputComponentStyles] = useTextQuestionStyles(placeholder, placeholderLabelRef, inputFieldRef);

    useEffect(() => {
        dispatch(
            setNewQuestionData({
                questionId: question.id,
                hasError: questionHasError(submitFailed, answer, question.required, true),
            }),
        );
    }, [submitFailed]);

    useEffect(() => {
        if (scroll && firstQuestionWithError === question.id) {
            scrollToQuestionError(questionRef);
            inputFieldRef.current.focus();
            setScroll(false);
        }
        if (!scroll) {
            setError(questionHasError(submitFailed, values, question.required, true));
        }
    }, [firstQuestionWithError, scroll]);

    useEffect(() => {
        dispatch(
            setNewQuestionData({
                questionId: question.id,
                hasError: questionHasError(submitFailed, answer, question.required, true),
            }),
        );
        if (question.required || values) {
            dispatch(addVisibleQuestion(question.id));
        }
        if (values) {
            dispatch(addAnswer(question.id));
        }
    }, []);

    useEffect(() => {
        setValues(answer || '');
    }, [answer])

    const handleBlur = e => {
        setError(questionHasError(submitFailed, values, question.required, true));
        dispatch(
            setNewQuestionData({
                questionId: question.id,
                hasError: questionHasError(submitFailed, answer, question.required, true),
            }),
        );
        dispatch(setQuestionnaireStateToChangesSaved());
        setFirstChange(true);
        setFocus(Boolean(values));
        dispatch(setQuestionValue({ name: question.id, value: values }));
        updateForm({
            questionId: question.id,
            answerId: question.optional_answers[0].id,
            answerType: question.optional_answers[0].type,
            value: values,
            invalid: false,
            required: question.required,
            questionType: question.type,
        });
    };

    return (
        <Box
            ref={questionRef}
            display="flex"
            px={isSm ? 4 : 0}
            key={`${question.id}_question`}
            pb={1.625}
            justifyContent="center">
            <FormControl fullWidth style={{ maxWidth: isSm ? 'unset' : '424px' }}>
                <GeneralStyled.TextField
                    inputRef={inputFieldRef}
                    autoFocus={Boolean(question.id === 'Q2136' && reSubmitted)}
                    fullWidth
                    name={question.id}
                    label={
                        <Markdown variant="body1">
                            {(question.required ? '' : '(Optional) ') + question.question}
                        </Markdown>
                    }
                    placeholder={placeholder}
                    onFocus={() => setFocus(true)}
                    multiline={true}
                    className="number"
                    onChange={e => {
                        setValues(e.target.value);
                        setError(false);
                        dispatch(
                            setNewQuestionData({
                                questionId: question.id,
                                hasError: false,
                            }),
                        );
                        setChangeMade(true);
                        if (firstChange) {
                            setFirstChange(false);
                            dispatch(setQuestionnaireStateToAnswerChanging());
                        }
                    }}
                    onBlur={handleBlur}
                    value={values}
                    type={answerType}
                    error={error}
                    helperText={
                        error
                            ? 'This field is required'
                            : question.id === 'Q2136' && reSubmitted
                                ? 'Please ensure the Applicant’s revenue is up to date and reflects the latest financial year'
                                : null
                    }
                    InputLabelProps={{
                        shrink: focus,
                        ref: placeholderLabelRef,
                    }}
                    inputProps={{
                        'data-track': `questionnaire_question_${question.id}`,
                        style: placeholder ? inputStyles.withPlaceholder : inputStyles.withoutPlaceholder,
                    }}
                    InputProps={{
                        style: placeholder
                            ? inputComponentStyles.withPlaceholder
                            : inputComponentStyles.withoutPlaceholder,
                        inputComponent: NumberFormatCustom,
                        startAdornment: focus && question.application_parameters.unit && (
                            <InputAdornment
                                position={
                                    (question.application_parameters.unit_position === 'left' && 'start') ||
                                    (question.application_parameters.unit_position === 'right' && 'end')
                                }
                                style={{ marginTop: 0.5 }}>
                                {question.application_parameters.unit}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end" style={{ marginTop: 8 }}>
                                {question.application_parameters.help_text && (
                                    <InfoTooltip
                                        dataTrack={`questionnaire_tooltip_${question.id}`}
                                        placement="bottom-end"
                                        open={open}
                                        setOpen={setOpen}
                                        text={question.application_parameters.help_text}
                                        select={false}
                                    />
                                )}
                                {questionMetaData?.savingQuestion && !error && <Styled.CircularProgress size={15} />}
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>
        </Box>
    )
};

export default NumberQuestion;
