import React, { useState, useEffect } from 'react'
import { useBreakpoint } from 'hooks';
import { formatDatePickerDate, makeDatePickerDate, dateDifference } from 'utils';
import { MplRetroactiveDateType } from 'utils/enums';
import { StyledTypography, StyledInput } from 'components';
import {
    Box,
    Hidden,
    Typography,
    RadioGroup,
    FormControlLabel,
} from '@material-ui/core';
import CustomRadio from 'components/radio/CustomRadio';


const DateRadioInput = ({
    shown, label, initialDate, initialValue = '', setValue, text, changeHandler, showDisclaimer, changeFunc, border = true,
    first = false, empty = false, minDate = undefined, maxDate = undefined, onInvalidDate = () => {}, onValidDate = () => {},
}) => {
    const isSm = useBreakpoint('down', 'sm');
    const [date, setDate] = useState(initialDate ? makeDatePickerDate(initialDate) : null);
    const [error, setError] = useState('MM/DD/YYYY');
    const DateTypography = empty ? StyledTypography.FadedTypography : Typography;

    useEffect(() => {
        changeFunc(initialValue);
    }, [initialValue]);

    const errorHandler = (error) => {
        if (initialValue === MplRetroactiveDateType.TO_MATCH_EXPIRING) {
            if (error) {
                // TO_MATCH_EXPIRING was chosen and error -> set error and set date to invalid
                setError(error);
                onInvalidDate();
            } else if (date) {
                // TO_MATCH_EXPIRING was chosen and valid date -> set date to be valid and no error
                setError('');
                onValidDate();
            } else {
                // TO_MATCH_EXPIRING was chosen and no date and no error -> set the date to invalid (use have to choose a date)
                setError('');
                onInvalidDate();
            }
        }
    }

    return (
        <>
            <Box
                width={['auto', 'auto', '50%']}
                ml={isSm ? 0 : first ? 4.5 : shown ? 10 : 0}
                my={[2, 2, 4]}>
                <Box display="flex" alignItems="center" component="span">
                    <StyledTypography.FadedTypography variant="overline">
                        {label}
                    </StyledTypography.FadedTypography>
                </Box>
                {!shown &&
                    <Box pt={0.5}>
                        <Box display="flex" alignItems="end" fontWeight="fontWeightLight">
                            <DateTypography variant="body1">
                                {date ? `${formatDatePickerDate(date)} (${text})` : text}
                            </DateTypography>
                        </Box>
                    </Box>
                }
                <Hidden mdUp>
                    <RadioGroup
                        onChange={e => setValue(e.target.value)}
                        value={initialValue}>
                        <FormControlLabel
                            style={{ marginLeft: 0 }}
                            onClick={e => e.preventDefault()}
                            label={
                                <Box width="100%" display={shown ? 'flex' : 'none'} ml={1.5} mb={1}>
                                    <StyledInput.KeyboardDatePicker
                                        autoOk
                                        label="MM/DD/YYYY"
                                        className="no-shrink-label"
                                        value={date}
                                        onChange={date => {
                                            setDate(date);
                                            if ((!minDate || Math.ceil(dateDifference(makeDatePickerDate(date), makeDatePickerDate(minDate))) >= 0) &&
                                                (!maxDate || Math.floor(dateDifference(makeDatePickerDate(maxDate), makeDatePickerDate(date))) >= 0)) {
                                                changeHandler(date);
                                            }
                                        }}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        format="MM/DD/YYYY"
                                        helperText={error}
                                        fullWidth
                                        onError={error => errorHandler(error)}
                                    />
                                </Box>
                            }
                            control={<CustomRadio variant="h5" onClick={e => e.stopPropagation()} />}
                            value={MplRetroactiveDateType.TO_MATCH_EXPIRING}
                        />
                        <FormControlLabel
                            style={{ marginLeft: 0 }}
                            onClick={e => e.preventDefault()}
                            label={
                                <Box ml={1.5} fontWeight="fontWeightRegular" color="#919191">
                                    <Typography variant="body1" color="#919191">
                                        {text}
                                    </Typography>
                                </Box>
                            }
                            control={
                                <CustomRadio
                                    variant="h5"
                                    onClick={e => {
                                        setDate(null);
                                        onValidDate();
                                        e.stopPropagation();
                                    }}
                                />
                            }
                            value={MplRetroactiveDateType.FULL_PRIOR_ACTS}
                        />
                    </RadioGroup>
                </Hidden>
                <Hidden smDown>
                    <RadioGroup
                        style={{ display: shown ? 'auto' : 'none'}}
                        onChange={e => setValue(e.target.value)}
                        value={initialValue}>
                        <FormControlLabel
                            style={{ marginLeft: 0 }}
                            onClick={e => e.preventDefault()}
                            label={
                                <Box fontWeight="fontWeightLight" display={shown ? 'block' : 'none'} ml={1.5} mb={1}>
                                    <StyledInput.KeyboardDatePicker
                                        autoOk
                                        fullWidth
                                        label="MM/DD/YYYY"
                                        className="no-shrink-label"
                                        value={date}
                                        onChange={date => {
                                            setDate(date);
                                            if ((!minDate || Math.ceil(dateDifference(makeDatePickerDate(date), makeDatePickerDate(minDate))) >= 0) &&
                                                (!maxDate || Math.ceil(dateDifference(makeDatePickerDate(maxDate), makeDatePickerDate(date))) >= 0)) {
                                                changeHandler(date);
                                            }
                                        }}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        format="MM/DD/YYYY"
                                        helperText={error}
                                        onError={error => errorHandler(error)}
                                    />
                                </Box>
                            }
                            control={<CustomRadio variant="h5" onClick={e => e.stopPropagation()} />}
                            value={MplRetroactiveDateType.TO_MATCH_EXPIRING}
                        />
                        <FormControlLabel
                            style={{ marginLeft: 0 }}
                            onClick={e => e.preventDefault()}
                            label={
                                <Box ml={1.5} fontWeight="fontWeightRegular" color="#919191">
                                    <Typography variant="body1">
                                        {text}
                                    </Typography>
                                </Box>
                            }
                            control={
                                <CustomRadio
                                    variant="h5"
                                    onClick={e => {
                                        setDate(null);
                                        onValidDate();
                                        e.stopPropagation();
                                    }} />
                            }
                            value={MplRetroactiveDateType.FULL_PRIOR_ACTS}
                        />
                    </RadioGroup>
                </Hidden>
            </Box>
            {shown && showDisclaimer && (
                <Hidden smDown>
                    <Box fontWeight="fontWeightLight" mb={4} px={[0, 4.5]}>
                        <StyledTypography.FadedTypography variant="body2">
                            Updates to the {label.replace(/_/g, ' ').toLowerCase()} date may result in additional contingencies.
                            Please carefully review all contingencies prior to binding or requesting to bind online.
                        </StyledTypography.FadedTypography>
                    </Box>
                </Hidden>
            )}
            {(border || isSm) && (
                <Box px={[0, 4.5]} mx={[-4, -4, 0]} borderBottom="2px solid #E0E0E0" />
            )}
        </>
    );
}

export default DateRadioInput;
