import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useResubmitPolicyRequest, useEnv } from 'hooks';
import { Icon, IconBox, StyledTypography, RoundedButton } from 'components';
import { IndustryAndServices } from 'components/policy';
import { Box, Typography } from '@material-ui/core';
import { SubmissionsBucket } from 'utils/enums';
import ToolsButton from 'components/tools-toggle/tools-button';
import { DeletePolicyRequestPopup } from 'components/popup';
import { EditApplicationPopup } from 'components/popup';


const dropdownActions = {
    EDIT_APPLICATION: 'EditApplication',
    DELETE_SUBMISSION: 'DeleteSubmission',
}

const PolicyHeader = ({
    policyRequest,
    ProductTextComponent,
    Actions,
    removeMargin,
    company,
    getIcon,
    iconColor,
    iconBgColor,
    setMargin,
}) => {
    const HeaderIcon = getIcon(policyRequest);
    const { teamView } = useEnv();
    const [disabled, setDisabled] = useState(false);
    const [openEditApplicationPopup, setOpenEditApplicationPopup] = useState(false);
    const [dropdownItems, ] = useState(initiateDropdownItems);
    const [deleteSubmissionPopup, setDeleteSubmissionPopup] = useState(false);
    const { id: companyId } = useSelector(state => state.company.company);
    const handleResubmitPolicyRequest = useResubmitPolicyRequest(company, policyRequest);


    const resubmit = () => {
        setDisabled(true);
        handleResubmitPolicyRequest();
    };

    const handleSelectDropdownItem = selectedItem => {
        switch(selectedItem.id) {
            case dropdownActions.DELETE_SUBMISSION:
                setDeleteSubmissionPopup(true);
                break;
            case dropdownActions.EDIT_APPLICATION:
                setOpenEditApplicationPopup(true);
                break;
            default:
                break;
        }
    };


    // Function because of hoisting issue
    function initiateDropdownItems() {
        let availableDropdownItems = [];
        if (policyRequest.is_deletable && policyRequest.athena_submissions_bucket !== SubmissionsBucket.DECLINED) {
            availableDropdownItems.push(
                {
                    id: dropdownActions.DELETE_SUBMISSION,
                    href: '',
                    text: 'Delete Submission',
                }
            );
        }

        if (policyRequest.product && !policyRequest.blocked_by_bor) {
            const allowedSubmissionBuckets = [SubmissionsBucket.QUOTED, SubmissionsBucket.PRE_QUOTE];
            if (allowedSubmissionBuckets.includes(policyRequest.athena_submissions_bucket)) {
                availableDropdownItems.push(
                    {
                        id: dropdownActions.EDIT_APPLICATION,
                        href: '',
                        text: 'Edit Application',
                    }
                );
            }
        }
        return availableDropdownItems;
    }


    return (
        <Box display="flex" flexDirection="column" alignItems="start" position="relative">
            <Box mb={removeMargin ? 0 : setMargin ? setMargin : [3, 3, 6, 6]} width="100%">
                <Box display="flex" alignItems="start" position="relative" pb={[1.5, 1.5, 3, 3]} width="100%">
                    <IconBox
                        sizes={['48px', '48px', '56px']}
                        bgcolor={iconBgColor || policyRequest.product?.background_color || '#EEE'}
                        color={iconColor || policyRequest.product?.text_color || null}
                        borderRadius="50%"
                        position={['absolute', 'absolute', 'relative']}
                        top="0"
                        mt={[-7, -7, 0]}
                        mr={2}>
                        <Icon icon={<HeaderIcon />} typographyProps={{ variant: 'h5' }} />
                    </IconBox>
                    <Box width="100%">
                        <Box display="flex" justifyContent="space-between">
                            <Box fontWeight="fontWeightBold">
                                <StyledTypography.FadedTypography variant="h5" component="span">
                                    {policyRequest.product?.name || ''}
                                    {policyRequest.product?.name ? ' ' : ''}
                                </StyledTypography.FadedTypography>
                                <Typography variant="h5" component="span">
                                    <ProductTextComponent policyRequest={policyRequest} />
                                </Typography>
                            </Box>
                            {teamView && dropdownItems?.length !== 0 &&
                                <ToolsButton
                                    dropdownItems={dropdownItems}
                                    handleSelectDropdownItem={handleSelectDropdownItem}
                                />
                            }
                        </Box>
                        <Box fontWeight="fontWeightLight">
                            <Actions policyRequest={policyRequest} />
                        </Box>
                    </Box>
                </Box>
                <IndustryAndServices policyRequest={policyRequest} />
            </Box>
            {[SubmissionsBucket.DECLINED, SubmissionsBucket.LOST].includes(policyRequest.athena_submissions_bucket) &&
                !policyRequest.blocked_by_bor && (
                <RoundedButton mt={8} py={0.25} onClick={resubmit} disabled={disabled}>
                    <Typography variant="subtitle2">Update Application</Typography>
                </RoundedButton>
            )}
            <EditApplicationPopup
                open={openEditApplicationPopup}
                handleClose={() => setOpenEditApplicationPopup(false)}
                policyRequestID={policyRequest.id}
            />
            <DeletePolicyRequestPopup
                open={deleteSubmissionPopup}
                handleClose={() => setDeleteSubmissionPopup(false)}
                policyRequestID={policyRequest.id}
                companyID={companyId}
                singlePolicyRequest={policyRequest}
            />
        </Box>
    );
};

export default PolicyHeader;
