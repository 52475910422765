import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getSignatureStatusText } from 'utils';
import { useBreakpoint, useQuery } from 'hooks';
import { SubmissionsBucket, NoteIdentifier } from 'utils/enums';
import { PolicyDataDialog, RoundedButton, StyledAction, StyledTypography, StyledCard, StyledBox } from 'components';
import { SignaturePopup } from 'components/popup';
import Contingency from './Contingency';
import GestureIcon from '@material-ui/icons/Gesture';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Box, Hidden, Typography } from '@material-ui/core';
import Dropzone from 'react-dropzone';

const Contingencies = () => {
    const policyRequest = useSelector(state => state.company.optionToQuote.quote.policy_request);
    const { id: policyRequestId, contingencies, conditionally_bound, athena_submissions_bucket } = policyRequest;

    const sortedContingencies = useMemo(
        () =>
            [...contingencies].reduce((prev, current) => {
                return current.identifier === NoteIdentifier.SIGNATURE
                    ? [current].concat(prev)
                    : prev.concat([current]);
            }, []),
        [contingencies],
    );

    const [preBindContingencies, postBindContingencies] = useMemo(() => {
        return [
            sortedContingencies.filter(contingency => contingency.is_pre_bind),
            sortedContingencies.filter(contingency => !contingency.is_pre_bind),
        ];
    }, [sortedContingencies]);

    const hasUncheckedPreBindContingencies = preBindContingencies.some(contingency => !contingency.checked);

    const [numPreBindChecked, numPostBindChecked] = useMemo(() => {
        return [
            preBindContingencies.filter(contingency => contingency.checked).length,
            postBindContingencies.filter(contingency => contingency.checked).length,
        ];
    }, [preBindContingencies, postBindContingencies]);

    const isSm = useBreakpoint('down', 'sm');
    const isMd = useBreakpoint('down', 'md');
    const isLg = useBreakpoint('up', 'lg');
    const [uploadedFile, setUploadedFile] = useState(null);
    const [preBindContingenciesToggled, setPreBindContingenciesToggled] = useState(false);
    const [postBindContingenciesToggled, setPostBindContingenciesToggled] = useState(false);
    const checkedContingencies = contingencies.filter(contingency => contingency.checked);
    const [openSignaturePopup, setOpenSignaturePopup] = useState(false);
    const completeContingencies = conditionally_bound || athena_submissions_bucket === SubmissionsBucket.BIND_REQUESTED;
    const signatureStatusText = getSignatureStatusText(policyRequest);
    const query = useQuery();
    const scrollToContingencies = query.get('contingencies') === 'true';
    const contingencyRef = useRef(null);
    const executeScroll = () => contingencyRef.current.scrollIntoView();
    useEffect(() => {
        if (scrollToContingencies) {
            executeScroll();
            setPreBindContingenciesToggled(true);
            setPostBindContingenciesToggled(true);
        }
    }, []);

    const toggleContingencyDropdowns = useCallback(() => {
        setPreBindContingenciesToggled(oldValue => !oldValue);
        setPostBindContingenciesToggled(oldValue => !oldValue);
    }, [setPreBindContingenciesToggled, setPostBindContingenciesToggled]);
    const handleFiles = acceptedFiles => {
        setUploadedFile(acceptedFiles);
        toggleContingencyDropdowns();
    };

    return (
        <Box
            component={StyledCard.Partial}
            mt={0.5}
            className="pt-0"
            ref={scrollToContingencies ? contingencyRef : null}>
            <Box fontWeight="fontWeightLight">
                <Hidden mdDown>
                    <Box fontWeight="fontWeightLight" mt={[2, 2, 3]}>
                        <Typography variant="subtitle2">
                            {completeContingencies
                                ? `Complete outstanding contingencies / ${checkedContingencies.length} of ${contingencies.length} contingencies complete`
                                : '03 Complete policy contingencies'}
                        </Typography>
                    </Box>
                </Hidden>
                <Box>
                    <Box
                        mx={[0, 0, 0, 7]}
                        mt={[2, 2, 4, 3]}
                        mb={contingencies.length ? 2 : [2, 2, 4]}
                        display="flex"
                        alignItems="center"
                        fontWeight="fontWeightLight">
                        <Box component={StyledTypography.FadedTypography} variant="subtitle1">
                            <Box mr={2}>
                                <GestureIcon />
                            </Box>
                        </Box>
                        {signatureStatusText ? (
                            <Box component={Typography} variant={isSm ? 'h5' : 'h3'} fontWeight="fontWeightLight">
                                {signatureStatusText}
                            </Box>
                        ) : (
                            <Box display="flex" width="100%" flexDirection={isSm ? 'column-reverse' : 'row'}>
                                {!hasUncheckedPreBindContingencies ? (
                                    <>
                                        {(isLg || isSm) && (
                                            <>
                                                <Dropzone
                                                    onDrop={handleFiles}
                                                    multiple={false}
                                                    data-track="upload_signed_app_title">
                                                    {({ getRootProps, getInputProps }) => (
                                                        <StyledBox.NoFocus
                                                            fontWeight="fontWeightLight"
                                                            {...getRootProps()}>
                                                            <Box fontWeight="fontWeightLight" width="100%">
                                                                <Typography
                                                                    variant={isSm ? 'h5' : 'h3'}
                                                                    color="primary">
                                                                    <StyledAction.Base>
                                                                        Upload signed application
                                                                        <input hidden {...getInputProps()} />
                                                                    </StyledAction.Base>
                                                                </Typography>
                                                            </Box>
                                                        </StyledBox.NoFocus>
                                                    )}
                                                </Dropzone>
                                                <Box fontWeight="fontWeightLight" mx={1}>
                                                    <StyledTypography.FadedTypography variant={isSm ? 'h5' : 'h3'}>
                                                        or
                                                    </StyledTypography.FadedTypography>
                                                </Box>
                                            </>
                                        )}
                                        <Box
                                            component={StyledAction.Base}
                                            onClick={() => setOpenSignaturePopup(true)}
                                            fontWeight="fontWeightLight"
                                            data-track="request_e_signature_title">
                                            <Typography variant={isSm ? 'h5' : 'h3'} color="primary">
                                                {isMd ? 'Request client e-signature' : 'Request e-signature'}
                                            </Typography>
                                        </Box>
                                    </>
                                ) : (
                                    <Box
                                        component={StyledAction.Base}
                                        onClick={toggleContingencyDropdowns}
                                        fontWeight="fontWeightLight"
                                        data-track="request_e_signature_title">
                                        <Typography variant={isSm ? 'h5' : 'h3'} color="primary">
                                            Review Contingencies
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>

                {preBindContingencies.length > 0 && (
                    <Box ml={[0, 4, 0, 7]} mb={3}>
                        <Box
                            component={StyledAction.ExpandableRowControl}
                            display="flex"
                            alignItems="center"
                            justifyContent={isSm ? 'space-between' : null}
                            flexDirection={isSm ? 'row-reverse' : 'row'}
                            onClick={() => setPreBindContingenciesToggled(oldValue => !oldValue)}
                            borderTop={isMd ? '2px solid #F2F2F2' : 'none'}
                            pt={[2, 2, 2, 0]}>
                            <StyledTypography.FadedTypography variant="subtitle1">
                                <Box mr={isSm ? 0 : 2} component="span" display="flex" alignItems="center">
                                    {isSm ? (
                                        <KeyboardArrowRightIcon />
                                    ) : preBindContingenciesToggled ? (
                                        <ExpandLessIcon />
                                    ) : (
                                        <ExpandMoreIcon />
                                    )}
                                </Box>
                            </StyledTypography.FadedTypography>
                            <StyledTypography.FadedTypography variant="subtitle2">
                                {preBindContingenciesToggled ? 'Hide' : 'Show'} pre-bind contingencies /{' '}
                                {numPreBindChecked} of {preBindContingencies.length} completed
                            </StyledTypography.FadedTypography>
                        </Box>
                        {preBindContingenciesToggled && (
                            <>
                                <Hidden smDown>
                                    <Box pl={[0, 4.5]} pt={3}>
                                        {preBindContingencies.map((contingency, i) => (
                                            <Contingency
                                                key={contingency.id}
                                                last={i === contingencies.length - 1}
                                                policyRequestId={policyRequestId}
                                                openSignaturePopup={() => setOpenSignaturePopup(true)}
                                                {...contingency}
                                                uploadedFile={
                                                    contingency.identifier === NoteIdentifier.SIGNATURE && uploadedFile
                                                }
                                            />
                                        ))}
                                    </Box>
                                </Hidden>

                                <Hidden mdUp>
                                    <PolicyDataDialog
                                        open={preBindContingenciesToggled}
                                        headerExtraText="Review contingencies"
                                        footer={
                                            <RoundedButton fullWidth onClick={toggleContingencyDropdowns}>
                                                <Typography variant="subtitle2">Done</Typography>
                                            </RoundedButton>
                                        }>
                                        <Box pt={3}>
                                            {preBindContingencies.map((contingency, i) => (
                                                <Contingency
                                                    key={contingency.id}
                                                    last={i === contingencies.length - 1}
                                                    policyRequestId={policyRequestId}
                                                    openSignaturePopup={() => setOpenSignaturePopup(true)}
                                                    {...contingency}
                                                    uploadedFile={
                                                        contingency.identifier === NoteIdentifier.SIGNATURE &&
                                                        uploadedFile
                                                    }
                                                />
                                            ))}
                                        </Box>
                                    </PolicyDataDialog>
                                </Hidden>
                            </>
                        )}
                    </Box>
                )}

                {postBindContingencies.length > 0 && (
                    <Box ml={[0, 4, 0, 7]} mb={3}>
                        <Box
                            component={StyledAction.ExpandableRowControl}
                            display="flex"
                            alignItems="center"
                            justifyContent={isSm ? 'space-between' : null}
                            flexDirection={isSm ? 'row-reverse' : 'row'}
                            onClick={() => setPostBindContingenciesToggled(oldValue => !oldValue)}
                            borderTop={isMd ? '2px solid #F2F2F2' : 'none'}
                            pt={[2, 2, 2, 0]}>
                            <StyledTypography.FadedTypography variant="subtitle1">
                                <Box mr={isSm ? 0 : 2} component="span" display="flex" alignItems="center">
                                    {isSm ? (
                                        <KeyboardArrowRightIcon />
                                    ) : postBindContingenciesToggled ? (
                                        <ExpandLessIcon />
                                    ) : (
                                        <ExpandMoreIcon />
                                    )}
                                </Box>
                            </StyledTypography.FadedTypography>
                            <StyledTypography.FadedTypography variant="subtitle2">
                                {postBindContingenciesToggled ? 'Hide' : 'Show'} post-bind contingencies /{' '}
                                {numPostBindChecked} of {postBindContingencies.length} completed
                            </StyledTypography.FadedTypography>
                        </Box>
                        {postBindContingenciesToggled && (
                            <>
                                <Hidden smDown>
                                    <Box pl={[0, 4.5]} pt={3}>
                                        {postBindContingencies.map((contingency, i) => (
                                            <Contingency
                                                key={contingency.id}
                                                last={i === contingencies.length - 1}
                                                policyRequestId={policyRequestId}
                                                openSignaturePopup={() => setOpenSignaturePopup(true)}
                                                {...contingency}
                                                uploadedFile={
                                                    contingency.identifier === NoteIdentifier.SIGNATURE && uploadedFile
                                                }
                                            />
                                        ))}
                                    </Box>
                                </Hidden>
                                <Hidden mdUp>
                                    <PolicyDataDialog
                                        open={postBindContingenciesToggled}
                                        headerExtraText="Review contingencies"
                                        footer={
                                            <RoundedButton fullWidth onClick={toggleContingencyDropdowns}>
                                                <Typography variant="subtitle2">Done</Typography>
                                            </RoundedButton>
                                        }>
                                        <Box pt={3}>
                                            {postBindContingencies.map((contingency, i) => (
                                                <Contingency
                                                    key={contingency.id}
                                                    last={i === contingencies.length - 1}
                                                    policyRequestId={policyRequestId}
                                                    openSignaturePopup={() => setOpenSignaturePopup(true)}
                                                    {...contingency}
                                                    uploadedFile={
                                                        contingency.identifier === NoteIdentifier.SIGNATURE &&
                                                        uploadedFile
                                                    }
                                                />
                                            ))}
                                        </Box>
                                    </PolicyDataDialog>
                                </Hidden>
                            </>
                        )}
                    </Box>
                )}
            </Box>
            {!signatureStatusText && (
                <SignaturePopup
                    open={openSignaturePopup}
                    handleClose={() => setOpenSignaturePopup(false)}
                    policyRequestID={policyRequestId}
                />
            )}
        </Box>
    );
};

export default Contingencies;
