import React from 'react';
import { IFrame } from 'components';
import { useEnv } from 'hooks';

const WhiteLabel = () => {
    const { apiBaseUrl } = useEnv();
    const url = `${apiBaseUrl}/admin/white_label`;

    return <IFrame src={url} />;
};

export default WhiteLabel;
