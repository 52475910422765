import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';

import {
    Login,
    PasswordReset,
    QuoteRequested,
    QuoteRequestedRegister,
    SignUp,
    Cover,
} from './pages';

import { PublicRoute, Error, SplitScreen } from 'components';


const UserRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <PublicRoute path={`${path}/login`}>
                <Login />
            </PublicRoute>
            <PublicRoute exact path={`${path}/reset/:token`}>
                <SplitScreen
                    leftComponent={<PasswordReset />}
                    rightComponent={<Cover backgroundImage="/images/people.png" />}
                />
            </PublicRoute>
            <PublicRoute path={`${path}/quote_requested/:token`}>
                <QuoteRequested />
            </PublicRoute>
            <PublicRoute path={`${path}/quote_requested_register/:token`}>
                <QuoteRequestedRegister />
            </PublicRoute>
            <PublicRoute exact path={`${path}/register`}>
                <SplitScreen
                    leftComponent={<Cover backgroundImage="/images/city.png" />}
                    rightComponent={<SignUp />}
                    pushRightPanelBottomMobile={true}
                />
            </PublicRoute>
            <Route>
                <Error errorStatus="404" />
            </Route>
        </Switch>
    );
};

export default UserRoutes;
