import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setShowFooter, setShowHeader } from 'stores/footer/footerSlice';

import Styled from './styled-components';

const SplitScreen = ({leftComponent, rightComponent, pushRightPanelBottomMobile}) => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(setShowFooter(false));
        dispatch(setShowHeader(false));
        return () => {
            dispatch(setShowFooter(true));
            dispatch(setShowHeader(true));
        }
    }, [])

    return (
        <Styled.SplitScreenContainer>
            <Styled.LeftPanel>
                {leftComponent}
            </Styled.LeftPanel>
            <Styled.RightPanel className={pushRightPanelBottomMobile ? 'push-right-panel-bottom' : ''}>
                {rightComponent}
            </Styled.RightPanel>
        </Styled.SplitScreenContainer>
    );
}

export default SplitScreen;
