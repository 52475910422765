import React from 'react';
import StyledDialog from 'components/popup/styled-components';
import StyledCustom from '../../../components/styled-components';
import { Box, IconButton, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Close as CloseIcon } from '@material-ui/icons';
import { ReactComponent as CheckedIcon } from 'components/icons/checked-icon-blue.svg';
import { useDataTrackBase } from 'hooks/customization';

const RequestedReviewPopup = ({ requestedReviewState }) => {
    const { optionToQuote } = useSelector(state => state.company);
    const dataTrackData = useDataTrackBase(optionToQuote);

    return (
        <StyledDialog.Dialog open={requestedReviewState}>
            <Box>
                <StyledDialog.DialogTitle>
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        fontWeight="fontWeightBold"
                        alignItems="flex-start"
                    >
                        <Box width="30em">
                            <Typography variant="h6">
                                Customized Quote Request
                            </Typography>
                        </Box>
                        <StyledCustom.Link
                            to={`/company/${optionToQuote?.quote.policy_request.company_id}`}
                            data-track={`${dataTrackData}_requested_review_popup_confirm`}
                        >
                            <IconButton>
                                <CloseIcon />
                            </IconButton>
                        </StyledCustom.Link>
                    </Box>
                </StyledDialog.DialogTitle>
                <StyledDialog.DialogContent>
                    <Box my={[8, 8, 18.75, 18,75]}>
                        <Box textAlign="center">
                            <CheckedIcon />
                        </Box>
                        <Box
                            color="#191919"
                            textAlign="center"
                            fontWeight="700"
                            my={1}
                        >
                            <Typography variant="h6">Request sent!</Typography>
                        </Box>
                        <Box
                            textAlign="center"
                            color="#919191"
                            width="85%"
                            mx="auto"
                        >
                            <Typography variant="body1">
                                Thank you for your customization request. An
                                underwriter has been notified to review any
                                requested changes and will get back to you
                                shortly!
                            </Typography>
                        </Box>
                    </Box>
                </StyledDialog.DialogContent>
                <Box textAlign="center" mb={3.75}>
                    <StyledCustom.Link
                        to={`/company/${optionToQuote?.quote.policy_request.company_id}`}
                    >
                        <Box
                            component={StyledCustom.ReturnButton}
                            data-track={`${dataTrackData}_requested_review_popup_confirm`}
                        >
                            <Typography variant="subtitle2">
                                Return to Client Detail Page
                            </Typography>
                        </Box>
                    </StyledCustom.Link>
                </Box>
            </Box>
        </StyledDialog.Dialog>
    );
};

export default RequestedReviewPopup;
