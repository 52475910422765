import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useBreakpoint, useAxiosClient } from 'hooks';
import { makeToast } from 'utils';
import { removePolicyRequest, setCompanyAttribute } from 'stores/company/companySlice';
import Styled from './styled-components';
import { RoundedButton, StyledAction, Icon } from 'components';
import CloseIcon from '@material-ui/icons/Close';
import ReportIcon from '@material-ui/icons/Report';
import * as Sentry from '@sentry/react';
import { Box, IconButton, Typography } from '@material-ui/core';
import { updateCompanyQuery } from 'queries/company';
import { useDeleteSubmission } from '../../api/policy/mutations/useDeleteSubmission';

const DeletePolicyRequestPopup = ({ policyRequestID, companyID, singlePolicyRequest, open, handleClose }) => {
    const history = useHistory();
    const isSm = useBreakpoint('down', 'sm');
    const axiosClient = useAxiosClient();
    const dispatch = useDispatch();

    const [, getCompanyRecommendedProducts] = axiosClient(
        {
            method: 'post',
            url: `/company/${companyID}`,
        },
        { manual: true },
    );

    const handleOnSubmitRequestSuccess = async res => {
        handleClose();
        if (res.company_deleted) {
            history.push('/');
        } else {
            const products = await getCompanyRecommendedProducts({
                data: {
                    query: updateCompanyQuery,
                },
            });
            dispatch(
                setCompanyAttribute({
                    name: 'recommended_products',
                    value: products.data.company.recommended_products,
                }),
            );
            dispatch(
                setCompanyAttribute({
                    name: 'viewable_policy_requests',
                    value: products.data.company.viewable_policy_requests.map(policyRequest => ({
                        ...policyRequest,
                        forceReload: true,
                    })),
                }),
            );
            dispatch(setCompanyAttribute({ name: 'case_studies', value: products.data.company.case_studies }));
            dispatch(removePolicyRequest({ id: policyRequestID }));
        }
    };

    const handleOnSubmitRequestFail = error => {
        handleClose();
        makeToast('error', 'Something went wrong.');
        Sentry.captureException(error);
    };

    const { mutate: deleteSubmission } = useDeleteSubmission(handleOnSubmitRequestSuccess, handleOnSubmitRequestFail);

    const handleDeleteSubmission = () => {
        deleteSubmission(policyRequestID);
    };

    return (
        <Styled.Dialog open={open} onClose={handleClose} fullWidth>
            <Styled.DialogTitle>
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    fontWeight="fontWeightBold"
                    alignItems="flex-start">
                    <Box>
                        <Typography variant="h6">Delete submission</Typography>
                    </Box>
                    <IconButton onClick={handleClose} data-testid="close-popup">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Styled.DialogTitle>
            <Styled.DialogContent>
                <Box px={[0, 0, 4]} my={[0, 0, 2]} mb={[5, 5, singlePolicyRequest ? 7 : 10]}>
                    <Box color="#F02B26" pb={4}>
                        <Icon icon={<ReportIcon />} typographyProps={{ variant: 'h1' }} />
                    </Box>
                    <Box fontWeight="fontWeightLight">
                        <Box component={Typography} variant="body1" pb={2}>
                            Please confirm that you wish to delete this submission and all associated quotes. Deletion
                            is permanent and cannot be reversed.
                        </Box>
                        {singlePolicyRequest && (
                            <Box component={Typography} variant="body1" pb={2}>
                                This is the only submission for this client. The client will be removed from your
                                accounts.
                            </Box>
                        )}
                        <Box component={Typography} variant="body1">
                            If you wish to resubmit, click "Cancel" and edit the application instead.
                        </Box>
                    </Box>
                </Box>
            </Styled.DialogContent>
            <Box
                component={Styled.DialogActions}
                display="flex"
                justifyContent={['space-between', 'space-between', 'flex-end']}>
                <Box component={StyledAction.Base} onClick={handleClose} mr={2} fontWeight="fontWeightBold">
                    <Typography color="primary" variant="subtitle2">
                        Cancel
                    </Typography>
                </Box>
                <RoundedButton onClick={handleDeleteSubmission} color="primary" py={0.3}>
                    <Typography variant="subtitle2">Delete {isSm ? '' : 'Submission'}</Typography>
                </RoundedButton>
            </Box>
        </Styled.Dialog>
    );
};

export default DeletePolicyRequestPopup;
