import React from 'react';
import { StyledTypography, StyledTable } from 'components';
import { RenewalType, ViewablePolicyType } from 'utils/enums';
import OptionToQuote from './OptionToQuote';
import {
    Box,
    Hidden,
    Table,
    TableHead,
    TableBody,
    TableRow,
    CircularProgress,
} from '@material-ui/core';
import QuotedNextSteps from 'components/policy/next-steps/QuotedNextSteps';
import RenewalQuotedNextSteps from 'components/policy/next-steps/RenewalQuotedNextSteps';
import {displayClaimLimit, getSortedOptionsToQuote} from 'utils/policyRequest';
import { ProductsAbbreviatedName } from 'utils/enums';


const DesktopTablet = ({ policyRequest }) => {
    return (
        <Box component={props => <Table padding="none" {...props} />} fontWeight="fontWeightLight">
            <TableHead>
                <Box component={TableRow} borderBottom={`4px solid ${policyRequest.product.background_color}`}>
                    <Hidden mdDown>
                        <Box
                            component={StyledTable.TableCell}
                            padding="none"
                            className="py-1"
                            width="220px"
                            maxWidth="220px">
                            <StyledTypography.FadedTypography variant="overline">
                                OPTION / TOTAL
                            </StyledTypography.FadedTypography>
                        </Box>
                    </Hidden>
                    <Hidden lgUp>
                        <Box
                            component={StyledTable.TableCell}
                            padding="none"
                            className="py-1"
                            width="220px"
                            maxWidth="220px">
                            <StyledTypography.FadedTypography variant="overline">
                                OPTION / TOTAL
                            </StyledTypography.FadedTypography>
                        </Box>
                    </Hidden>
                    {displayClaimLimit(policyRequest) ? (
                        <Box
                            component={StyledTable.TableCell}
                            padding="none"
                            className="py-1"
                            width={['100px', '100px', '150px', '150px']}
                            maxWidth={['100px', '100px', '150px', '150px']}>
                            <StyledTypography.FadedTypography variant="overline">
                                AG. / CLAIM LIM
                            </StyledTypography.FadedTypography>
                        </Box>
                    ) : (
                        <Box
                            component={StyledTable.TableCell}
                            padding="none"
                            className="py-1"
                            width={['100px', '100px', '100px', '150px']}
                            maxWidth={['100px', '100px', '100px', '150px']}>
                            <StyledTypography.FadedTypography variant="overline">
                                AGG. LIMIT
                            </StyledTypography.FadedTypography>
                        </Box>
                    )}
                    <StyledTable.TableCell padding="none" className="py-1">
                        <StyledTypography.FadedTypography variant="overline">
                            RETENTION
                        </StyledTypography.FadedTypography>
                    </StyledTable.TableCell>
                    <StyledTable.TableCell padding="none" className="py-1" width="32px" />
                    <Box
                        component={StyledTable.TableCell}
                        padding="none"
                        className="py-1"
                        width={
                            policyRequest.product.abbreviated_name === ProductsAbbreviatedName.MPL ||
                            (policyRequest.policyType === ViewablePolicyType.UPON_RENEWAL &&
                                RenewalType.isAuto(policyRequest?.renewal_type.name)
                            )
                                ? '164px'
                                : '245px'
                        }
                    />
                </Box>
            </TableHead>
            <TableBody>
                <TableRow>
                    <StyledTable.TableCell colSpan={6}>
                        <Box mt={1} />
                    </StyledTable.TableCell>
                </TableRow>
                {getSortedOptionsToQuote(policyRequest.option_to_quotes).map((optionToQuote, index) => {
                    return (
                        <OptionToQuote
                            policyRequest={policyRequest}
                            optionToQuote={optionToQuote}
                            key={optionToQuote.id}
                            index={index}
                        />
                    );
                })}
                {policyRequest.inProgress && (
                    <TableRow>
                        <StyledTable.TableCell colSpan={6}>
                            <Box display="flex" alignItems="center">
                                <StyledTypography.FadedTypography variant="subtitle1">
                                    <Box mr={[0, 2, 2.5, 3]} display="flex">
                                        <CircularProgress color="inherit" size={15} />
                                    </Box>
                                </StyledTypography.FadedTypography>
                                <StyledTypography.FadedTypography variant="subtitle2" align="justify">
                                    Generating options
                                </StyledTypography.FadedTypography>
                            </Box>
                        </StyledTable.TableCell>
                    </TableRow>
                )}
                {policyRequest.policyType === ViewablePolicyType.UPON_RENEWAL &&
                RenewalType.isAuto(policyRequest?.renewal_type.name) ?
                    (
                        <RenewalQuotedNextSteps policyRequest={policyRequest} />
                    ) : (
                        <QuotedNextSteps policyRequest={policyRequest} />
                    )
                }
            </TableBody>
        </Box>
    );
}


export default DesktopTablet;
