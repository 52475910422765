import React from 'react';
import NextSteps from './index';
import LossRun from './components/LossRun';
import ErpInvoice from './components/ErpInvoice';
import ErpEndorsement from './components/ErpEndorsment';
import { SubmissionsBucket } from 'utils/enums';
import CompleteContingencies from './components/CompleteContingencies';
import { useEnv } from 'hooks';


const BoundNextSteps = ({ policyRequest }) => {
    const { newBpRenewal } = useEnv();

    return (
        <NextSteps>
            {policyRequest.erp_info.expiration_date && (
                <>
                    <ErpInvoice policyRequest={policyRequest} />
                    <ErpEndorsement policyRequest={policyRequest} />
                </>
            )}
            {newBpRenewal &&
            (policyRequest.conditionally_bound ||
                policyRequest.athena_submissions_bucket === SubmissionsBucket.BIND_REQUESTED) ? (
                    <CompleteContingencies policyRequest={policyRequest} />
                ) : (
                    <LossRun policyRequest={policyRequest} />
                )}
        </NextSteps>
    );
}

export default BoundNextSteps;
