import styled from 'styled-components';
import { Link as _Link } from 'react-router-dom';

const Link = styled(_Link)`
    &&& {
        color: inherit;
        text-decoration-line: none;
        &:hover {
            text-decoration: none;
        }
        &:visited {
            text-decoration: none;
        }
    }
`;

const NavItem = styled.li`
    &&& {
        float: right;
    }

    &.active > a > div {
        color: #fff;
    }
`;

export default {
    Link,
    NavItem,
};
