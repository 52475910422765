import { makeStyles } from '@material-ui/core/styles';


const MaxAggregateLimitAllowedToBroker = 5000000;

const useStyles = makeStyles({
    TableCellTitle: {
        color: '#919191',
        letterSpacing: '1.8px',
        fontWeight: '400',
    },
    TableCoverageName: {
        width: '280px',
        minWidth: '180px',
    },
    Select: {
        width: '120px',
    },
    SelectMobile: {
        width: '100px',
    },
    DisabledSelect: {
        opacity: '0.45',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
});


const filterAllowedValues = (allowed_values, current_value=null) => {
    return allowed_values.filter(
        obj => obj.value !== 0 && (obj.value <= MaxAggregateLimitAllowedToBroker || obj.value === current_value)
    );
};

export {
    filterAllowedValues,
    useStyles,
    MaxAggregateLimitAllowedToBroker
};
