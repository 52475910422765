import React from 'react';
import { ProductsAbbreviatedName } from 'utils/enums';


const ProductTextComponent = ({ policyRequest }) => {
    return (
        <>
            {policyRequest.inProgress
                ? policyRequest.product.abbreviated_name === ProductsAbbreviatedName.MPL
                    ? 'Quote calculation in progress'
                    : 'Security scan in progress'
                : policyRequest.broker_submitted ? 'Submission under review'
                    :  'Application in progress'}
        </>
    )
}


export default ProductTextComponent;
