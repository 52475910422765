import React from 'react';
import { useBreakpoint, useEnv } from 'hooks';
import { StyledLink } from 'components';
import { dateDifference } from 'utils';
import { SubmissionsBucket } from 'utils/enums';
import { Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const getDaysToRenewal = policyRequest => {
    if (!policyRequest.renewal_policy_request?.effective_date) {
        return null;
    }
    const today = new Date();
    const renewalEffectiveDate = new Date(policyRequest.renewal_policy_request.effective_date);
    if (renewalEffectiveDate >= today) {
        return dateDifference(renewalEffectiveDate, today);
    }
    return null;
}

const getPolicyDuration = policyRequest => {
    if (!policyRequest.effective_date || !policyRequest.expiration_date) {
        return null;
    }
    const today = new Date();
    const effectiveDate = new Date(policyRequest.effective_date);
    const expirationDate = new Date(policyRequest.expiration_date);
    if (expirationDate >= today && today >= effectiveDate) {
        return dateDifference(expirationDate, today) + 1;
    }
    return null;
}


const Notice = ({ policyRequest }) => {
    const { apiBaseUrl } = useEnv();
    const { renewalEditApplication } = useEnv();
    const isSm = useBreakpoint('down', 'sm');
    const daysToRenewal = Math.ceil(getDaysToRenewal(policyRequest));
    const policyDuration = Math.ceil(getPolicyDuration(policyRequest));
    const binderExpiration = Math.ceil(dateDifference(new Date(policyRequest.binder_expiration_date), new Date()));
    const preferredOptionToQuote = policyRequest.option_to_quotes.find(optionToQuote => optionToQuote.quote.preferred);
    const expiresWithPolicyDuration = policyDuration && policyDuration <= 120 && (!policyRequest.renewal_policy_request || !daysToRenewal);
    const expiresWithDaysToRenewal = daysToRenewal && !policyRequest.renewal_policy_request.is_in_auto_renew_process_without_populate;
    const renews = daysToRenewal && policyRequest.renewal_policy_request.is_in_auto_renew_process_without_populate;
    const bindRequestWithUncheckedContingencies = (
        policyRequest.athena_submissions_bucket === SubmissionsBucket.BIND_REQUESTED &&
        policyRequest.contingencies.some(contingency => !contingency.checked)
    );
    const showNotice = (
        policyRequest.conditionally_bound || policyRequest.renewal_policy_request || bindRequestWithUncheckedContingencies ||
        (policyRequest.athena_submissions_bucket === SubmissionsBucket.BOUND && (expiresWithPolicyDuration || expiresWithDaysToRenewal || renews))
    );

    const history = useHistory();
    function handleclick() {
        history.push({pathname: `/questionnaire/policy/${policyRequest.renewal_policy_request.id}`, state: {editMode: true}});
    }
    function handleCustomizeClick() {
        const optionToQuoteId = policyRequest.renewal_policy_request.option_to_quotes[0].id
        history.push(`/submission/quote_customization/${optionToQuoteId}`)
    }

    return Boolean(showNotice) && (
        <Box py={3} px={[0, 4, 7, 10]} bgcolor="#FAFAFA" fontWeight="fontWeightLight">
            <Typography variant="subtitle2" component="span">
                {policyRequest.conditionally_bound ? (
                    binderExpiration > 0 ? (
                        `Binder expires in ${binderExpiration} Day${binderExpiration > 1 ? 's' : ''}`
                    ) : (
                        `Binder expired ${binderExpiration * -1} Day${binderExpiration < -1 ? 's' : ''} ago`
                    )
                ) : bindRequestWithUncheckedContingencies ? (
                    'Bind request under review'
                ) : (
                    (expiresWithPolicyDuration) ? (
                        `This policy expires in ${policyDuration} Day${policyDuration > 1 ? 's' : ''}`
                    ) : (expiresWithDaysToRenewal) ? (
                        `This policy expires in ${daysToRenewal} Day${daysToRenewal > 1 ? 's' : ''}`
                    ) : (renews) ? (
                        `This policy will renew in ${daysToRenewal} Day${daysToRenewal > 1 ? 's' : ''}`
                    ) : null
                )}
            </Typography>
            {(policyRequest.renewal_policy_request || policyRequest.conditionally_bound || bindRequestWithUncheckedContingencies) && (
                <>
                    <Typography variant="subtitle2" component="span">
                        {' / '}
                    </Typography>
                    {(policyRequest.conditionally_bound || bindRequestWithUncheckedContingencies) ? (
                        <Typography variant="subtitle2" color="primary" component={isSm ? 'p' : 'span'}>
                            <Box component={StyledLink.Default}
                                fontWeight="fontWeightMedium"
                                to={`/submission/policy_details/${preferredOptionToQuote.id}`}>
                                Complete {policyRequest.conditionally_bound ? 'outstanding'  : 'pre-bind'} contingencies
                            </Box>
                        </Typography>
                    ) : (
                        <Typography variant="subtitle2" color="primary" component="span">
                            <Box component={StyledLink.Anchor}
                                fontWeight="fontWeightMedium"
                                target="_blank"
                                href={`${apiBaseUrl}/questionnaire/render_policy_request/${policyRequest.id}/questionnaire`}>
                                Download renewal application
                            </Box>
                            {renewalEditApplication && (
                                <Box onClick={handleclick}>
                                    Edit Renewal Application
                                </Box>
                            )}
                            {renewalEditApplication && (
                                <Box onClick={handleCustomizeClick}>
                                    Customize Renewal Quote
                                </Box>
                            )}
                        </Typography>
                    )}
                </>
            )}
        </Box>
    )
}


export default Notice;
