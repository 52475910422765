import React from 'react';
import { StyledTypography, StyledLink } from 'components';
import { Box, Typography } from '@material-ui/core';
import { SubmissionsBucket } from 'utils/enums';

const Actions = ({ policyRequest }) => {

    return (
        <>
            <StyledTypography.FadedTypography variant="subtitle2" component="span">
                {policyRequest.athena_submissions_bucket === SubmissionsBucket.DECLINED
                    ? 'At-Bay was unable to provide quotes for this submission. If details have changed, please update application answers to receive terms.'
                    : policyRequest.athena_submissions_bucket === SubmissionsBucket.LOST
                        ? 'Your previous quotes have expired. Please update application answers to receive refreshed terms.'
                        : 'For a new quote, please contact your underwriter at'}
            </StyledTypography.FadedTypography>
            {![SubmissionsBucket.DECLINED, SubmissionsBucket.LOST].includes(
                policyRequest.athena_submissions_bucket,
            ) && (
                <Box component="span" fontWeight="fontWeightMedium">
                    <Typography variant="subtitle2" component="span" color="primary">
                        <StyledLink.Anchor href={`mailto:${policyRequest.uw_email}`}>
                            {' '}{policyRequest.uw_email}
                        </StyledLink.Anchor>
                    </Typography>
                </Box>
            )}
        </>
    );
}


export default Actions;
