import React, { FC } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { Loading } from 'components';

interface Props {
    onNextButtonClick: (() => void) | undefined;
    onPrevButtonClick: (() => void) | undefined;
    currentPage: number;
    children: JSX.Element;
    isLoading?: boolean;
}

const PaginationLayout: FC<Props> = ({
    children,
    onNextButtonClick,
    onPrevButtonClick,
    currentPage,
    isLoading = false,
}) => {
    if (isLoading) {
        return (
            <Box position="relative" minHeight={250} width="100%">
                <Loading />
            </Box>
        );
    }

    return (
        <Box display="flex" flexDirection="column" minHeight={250}>
            <Box display="flex" flex={1}>
                {children}
            </Box>
            <Box
                height={50}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                style={{ gap: 15 }}>
                <IconButton
                    data-testid="pagination-next-page"
                    disabled={!onPrevButtonClick}
                    onClick={onPrevButtonClick}>
                    <KeyboardArrowLeft />
                </IconButton>
                {currentPage}
                <IconButton
                    data-testid="pagination-prev-page"
                    disabled={!onNextButtonClick}
                    onClick={onNextButtonClick}>
                    <KeyboardArrowRight />
                </IconButton>
            </Box>
        </Box>
    );
};

export default PaginationLayout;
