import React, { useState, useEffect } from 'react';
import Styled from './styled-components';
import {Box, IconButton, Typography} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { RoundedButton, StyledAction } from '../index';


const ChangePasswordPopup = ({ open, handleClose, handleSubmit, successResponse }) => {
    const [continueDisabled, setContinueDisabled] = useState(false);
    const handleContinue = () => {
        setContinueDisabled(true);
        handleSubmit();
    }

    useEffect(() => {
        if (successResponse !== undefined) {
            setContinueDisabled(false);
        }
    }, [open, successResponse]);

    return (
        <Styled.Dialog
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <Styled.DialogTitle>
                <Box width="100%" display="flex" justifyContent="space-between" fontWeight="fontWeightBold" alignItems="center">
                    <Typography variant="h6">
                        Reset Password
                    </Typography>
                    <IconButton onClick={handleClose} data-testid="close-popup">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Styled.DialogTitle>
            <Styled.DialogContent>
                <Box fontWeight="fontWeightLight">
                    <Typography component="div" variant="body1">
                        {successResponse !== undefined
                            ?
                            <Box mb={2}>
                                {successResponse
                                    ?
                                    'A password reset email was sent to your inbox. Follow the instructions in the email to create a new password.'
                                    :
                                    'Oops! Something went wrong with your request. Please try again.'
                                }
                            </Box>
                            :
                            <>
                                <Box mb={2}>
                                    Upon clicking "Continue" a password reset email will be sent to your inbox.
                                    Follow the instructions in the email to create a new password.
                                </Box>
                                <Box>Once your password is reset, you'll need to log in again with your new credentials.</Box>
                            </>
                        }
                    </Typography>
                </Box>
            </Styled.DialogContent>
            {successResponse === undefined &&
                <Styled.DialogActions>
                    <Box component={StyledAction.Base} mr={2} fontWeight="fontWeightBold">
                        <Typography color="primary" onClick={handleClose} variant="subtitle2">
                            Cancel
                        </Typography>
                    </Box>
                    <RoundedButton onClick={handleContinue} disabled={continueDisabled} color="primary" py={0.15}>
                        <Typography variant="subtitle2">
                            { continueDisabled ? <Box width="70px" py={0}>
                                <Styled.CircularProgressBlack color="primary" size={13} />
                            </Box> : 'Continue' }
                        </Typography>
                    </RoundedButton>
                </Styled.DialogActions>
            }
        </Styled.Dialog>
    );
};

export default ChangePasswordPopup;
