import { useState, useEffect } from 'react';

export const useTextQuestionStyles = (placeholder, placeholderLabelRef, inputFieldRef) => {
    const [inputStyles, setInputStyles] = useState({});
    const [inputComponentStyles, setInputComponentStyles] = useState({});

    const setInputWithPlaceholderStyles = () => {
        const placeholderHeight = placeholderLabelRef.current.clientHeight;
        const textFieldHeight = inputFieldRef.current.clientHeight;
        const computedStyle = getComputedStyle(placeholderLabelRef.current);
        const lineHeightParsed = parseInt(computedStyle.lineHeight.replace('px', ''));
        const multiline = placeholderLabelRef.current.offsetHeight >= lineHeightParsed * 2;
        setInputStyles(currentStyles => ({
            ...currentStyles,
            withPlaceholder: {
                paddingTop: multiline ? Math.abs(placeholderHeight - textFieldHeight) : '6px',
            },
        }));
    };

    const setInputWithoutPlaceholderStyles = () => {
        setInputStyles(currentStyles => ({
            ...currentStyles,
            withoutPlaceholder: {
                paddingTop: '6px',
                paddingBottom: '7px',
            },
        }));
    };

    const setInputComponentWithPlaceholderStyles = () => {
        setInputComponentStyles(currentStyles => ({
            ...currentStyles,
            withPlaceholder: null,
        }));
    };

    const setInputComponentWithoutPlaceholderStyles = () => {
        const placeholderHeight = placeholderLabelRef.current.clientHeight;
        const textFieldHeight = inputFieldRef.current.clientHeight;
        setInputComponentStyles(currentStyles => ({
            ...currentStyles,
            withoutPlaceholder: {
                marginTop: Math.max(Math.abs(placeholderHeight - textFieldHeight), 20),
            },
        }));
    };

    const setStyles = () => {
        if (placeholder) {
            if (!inputStyles.withPlaceholder) {
                setInputWithPlaceholderStyles();
            }
            if (!inputComponentStyles.withPlaceholder) {
                setInputComponentWithPlaceholderStyles();
            }
        } else {
            if (!inputStyles.withoutPlaceholder) {
                setInputWithoutPlaceholderStyles();
            }
            if (!inputComponentStyles.withoutPlaceholder) {
                setInputComponentWithoutPlaceholderStyles();
            }
        }
    }

    useEffect(() => {
        if (!placeholderLabelRef.current || !inputFieldRef.current) {
            return;
        }
        setStyles();
    }, [placeholder, placeholderLabelRef.current, inputFieldRef.current]);

    return [inputStyles, inputComponentStyles];
};
