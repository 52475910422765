import { ErrorResponseType } from '../../mutationTypes';
import { UseMutateFunction, useMutation } from 'react-query';
import {useAxiosInstance, useEnv} from 'hooks';
import { AxiosInstance } from 'axios';

type SuccessResponseType = {
    correlation_id: string,
    created_at: string,
    message: string,
    request_id: string,
    status: string,
};

type MutationReturnType = {
    mutate: UseMutateFunction<SuccessResponseType, ErrorResponseType, string>;
    error: ErrorResponseType | null;
    isLoading: boolean;
};

type RequestBodyType = {
    app_id: string;
    return_endpoint: string;
    audience_api: string;
    ttls: number;
    verify_email: boolean;
    email_template_name: string;
    email_params: {
        subject: string;
        from_email: string;
        application_name: string;
    };
};

const changePassword = async (requestBody: RequestBodyType, axiosInstance: AxiosInstance) => {
    const baseApi = document.location.origin;
    const res = await axiosInstance.post(`${baseApi}/auth/user_send_email`, requestBody);
    return res.data as SuccessResponseType;
};

export const useChangePassword = (
    onSuccessCallback: () => void,
    onErrorCallback: (error: ErrorResponseType) => void,
): MutationReturnType => {
    const axiosInstance = useAxiosInstance();
    const { authAppId } = useEnv();
    const returnEndpoint = `${document.location.origin}/svc/api/gui/v2/auth/auth_logout`
    const requestBody = {
        app_id: authAppId,
        audience_api: 'https://keeprisk.at-bay.com',
        return_endpoint: returnEndpoint,
        ttls: 7200,
        flow: 'reset',
        verify_email: false,
        email_template_name: 'reset_password_sso_email_default',
        email_params: {
            subject: 'Reset Your Broker Platform Password',
            from_email: 'notifications@at-bay.com',
            application_name: 'Broker platform',
        },
    }
    const { mutate, error, isLoading } = useMutation(
        (optionToQuoteID: string) => changePassword(requestBody, axiosInstance),
        {
            onSuccess: onSuccessCallback,
            onError: onErrorCallback,
        },
    );
    return { mutate, error, isLoading };
};
