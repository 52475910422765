import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setOptionToQuote } from 'stores/company/companySlice';
import {
    Header,
    QuoteReview,
    Dates,
    Contingencies,
    BindSection,
    Underwriter,
    AdditionalInfo,
    PolicyOwner,
} from 'components/policy';
import { Box } from '@material-ui/core';
import { Error, StyledContainer, Loading } from 'components';
import { useAxiosClient, useEnv } from 'hooks';
import { SubmissionsBucket } from 'utils/enums';
import { getOptionToQuoteQuery, premiumQuery } from 'queries/optionToQuote';

const Bind = () => {
    const dispatch = useDispatch();
    const axiosClient = useAxiosClient();
    const { optionToQuoteId } = useParams();
    const { optionToQuote } = useSelector(state => state.company);
    const { athena_submissions_bucket } = optionToQuote?.quote?.policy_request || {};
    const [savingDates, setSavingDates] = useState(false);
    const { teamView } = useEnv();

    const [{ data: optionToQuoteData, error: optionToQuoteError }, getOptionToQuote] = axiosClient({
        method: 'post',
        url: `/optionToQuote/${optionToQuoteId}`,
    }, { manual: true });

    const [{ loading }, getUpdatedPremium] = axiosClient({
        method: 'post',
        url: `/optionToQuote/${optionToQuoteId}`,
        data: { query: { quote: premiumQuery } }
    }, { manual: true });

    useEffect(() => {
        if (!optionToQuote || optionToQuote.id !== Number(optionToQuoteId)) {
            getOptionToQuote({
                data: {
                    query: getOptionToQuoteQuery({ includePolicyRequest: true })
                }
            });
        }
        return () => dispatch(setOptionToQuote(null));
    }, []);

    useEffect(() => {
        if (optionToQuoteData) {
            dispatch(setOptionToQuote(optionToQuoteData.optionToQuote));
        }
    }, [optionToQuoteData])

    if (optionToQuoteError || (athena_submissions_bucket && athena_submissions_bucket !== SubmissionsBucket.QUOTED)) {
        return <Error errorStatus="404" />
    }

    if (!optionToQuote || !optionToQuote?.quote?.policy_request) {
        return <Loading />;
    }

    return (
        <Box mb={2}>
            <StyledContainer.BaseContainer disableGutters>
                <Header />
                <Box mb={1} pt={0.5}>
                    {teamView && (
                        <PolicyOwner
                            userName={optionToQuote?.quote?.policy_request?.broker_user?.username}
                            policyRequest={optionToQuote?.quote?.policy_request}
                        />
                    )}
                </Box>
                <QuoteReview premiumIsLoading={loading} />
                <Dates getUpdatedPremium={getUpdatedPremium} setSavingDates={setSavingDates} />
                <Contingencies />
                <BindSection savingDates={savingDates} />
                <Underwriter />
                <AdditionalInfo />
            </StyledContainer.BaseContainer>
        </Box>
    );
};

export default Bind;
