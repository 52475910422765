import { DirectBrokerDetails, IndirectBrokerActiveStatus, IndirectBrokersDetailsByActiveStatus } from '../utils';
import { Action, State } from './types';

const getIndirectBrokersListByActiveStatus = (
    brokerDetails: DirectBrokerDetails[],
): IndirectBrokersDetailsByActiveStatus => {
    const brokerDetailsListByActiveStatus: IndirectBrokersDetailsByActiveStatus = {
        [IndirectBrokerActiveStatus.ACTIVE]: [],
        [IndirectBrokerActiveStatus.INACTIVE]: [],
    };

    brokerDetails.forEach((broker: DirectBrokerDetails) => {
        const currentBrokerStatus = (+broker.active).toString() as IndirectBrokerActiveStatus;
        (brokerDetailsListByActiveStatus[currentBrokerStatus] as DirectBrokerDetails[]).push(broker);
    });

    return brokerDetailsListByActiveStatus;
};

export const retailPartnersContextReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'set':
            return {
                ...state,
                brokersListByActiveStatus: getIndirectBrokersListByActiveStatus(action.payload),
                brokersList: action.payload,
            };
        case 'add':
            const newList = state.brokersList.concat(action.payload);

            return {
                ...state,
                brokersListByActiveStatus: getIndirectBrokersListByActiveStatus(newList),
                brokersList: newList,
            };
        case 'edit':
            const updatedList = state.brokersList.map(broker =>
                broker.id !== action.payload.id ? broker : action.payload,
            );

            return {
                ...state,
                brokersListByActiveStatus: getIndirectBrokersListByActiveStatus(updatedList),
                brokersList: updatedList,
            };
        default:
            return {
                ...state,
            };
    }
};
