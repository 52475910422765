import styled from 'styled-components';
import {
    DialogContent as _DialogContent,
    DialogTitle as _DialogTitle,
    DialogActions as _DialogActions,
} from '@material-ui/core'

const DialogTitle = styled(_DialogTitle)`
    &&& {
        ${({ theme }) => `
            transition: 0.25s;
            padding: ${theme.spacing(4)}px ${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(4)}px;
            &.scrolled {
                padding: ${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(4)}px;
                box-shadow: 0px 4px 8px rgba(118, 118, 118, 0.25);
            }
        `}
    }
`;

const DialogActions = styled(_DialogActions)`
    &&& {
        display: block;
        background-color: inherit;
        padding: ${({ theme }) => theme.spacing(3)}px ${({ theme }) => theme.spacing(4)}px;
        box-shadow: ${props => (props.boxShadow ? '0px -4px 8px rgba(118, 118, 118, 0.25)' : 'none')};
    }
`;

const DialogContent = styled(_DialogContent)`
    &&& {
        padding: 0 ${({ theme }) => theme.spacing(4)}px ${({ theme }) => theme.spacing(5)}px ${({ theme }) => theme.spacing(4)}px;
    }
`;

export default {
    DialogActions,
    DialogContent,
    DialogTitle,
};
