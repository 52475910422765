import styled from 'styled-components';
import { default as _Chip } from '@material-ui/core/Chip';
import { default as _Popper } from '@material-ui/core/Popper';
import { CircularProgress as _CircularProgress } from '@material-ui/core';

const Chip = styled(_Chip)`
    &&& {
        margin-right: ${({ theme }) => theme.spacing(1.25)}px;
        margin-bottom: ${({ theme }) => theme.spacing(1.25)}px;
        background-color: #F2F2F2;
        color: #00005F;

        & .MuiChip-label {
            padding-left: ${({ theme }) => theme.spacing(2)}px;
            padding-right: ${({ theme }) => theme.spacing(2)}px;
        }
        &:hover {
            background: ${({ theme }) => theme.palette.primary.main};
            color: white;
            .MuiChip-deleteIcon {
                color: white;
                cursor: pointer;
            }
        }
    }
`;

const MultipleChoicesWithSearchBarQuestionPopper = styled(_Popper)`
    &&& {
        & .MuiAutocomplete-noOptions {
            display: none;
        }
        & .MuiAutocomplete-option {
            padding-left: 0;
            &[data-focus='true'], &[aria-selected='true'] {
                & .multiselect-option-line {
                    border-bottom: 3px solid black;
                }
                background-color: transparent;
            }
            & .multiselect-option-line-div {
                flex: 1;
                padding-right: ${({ theme }) => theme.spacing(0.625)}px;
                }
            & .multiselect-option-line {
                height: 40%;
                width: 10px;
                border-bottom: 3px solid white;
            }
        }
    }
}`;

const CircularProgress = styled(_CircularProgress) `
    &&& {
        color: #AFAFAF;
        align-self: self-end;
        margin-bottom: -${({ theme }) => theme.spacing(0.5)}px;
        margin-left: ${({ theme }) => theme.spacing(0.5)}px;
    }
`

const MultipleChoiceCircularProgress = styled(_CircularProgress) `
    &&& {
        color: #AFAFAF;
        align-self: self-end;
        align: right;
        margin-bottom: ${({ theme }) => theme.spacing(1)}px;
    }
`

const MultipleChoicesWithSearchBarCircularProgress = styled(_CircularProgress) `
    &&& {
        color: #AFAFAF;
        align-self: self-end;
        align: right;
    }
`

export default {
    Chip,
    MultipleChoicesWithSearchBarQuestionPopper,
    CircularProgress,
    MultipleChoiceCircularProgress,
    MultipleChoicesWithSearchBarCircularProgress
};
