import { Box } from '@material-ui/core';
import React, { FC, useCallback, useContext } from 'react';
import { TabPanel } from '@material-ui/lab';
import { DirectBrokerDetails, RetailPartnersTab } from '../../utils';
import RetailerPartnersDetails from '../retail-partner-details-row';
import { RetailPartnersContext } from '../../context/RetailPartnersContext';

interface Props {
    tabs: RetailPartnersTab[];
}

const RetailPartnerDetailsWrapper: FC<Props> = ({ tabs }) => {
    const { brokerListByActiveStatus, searchFilterText } = useContext(RetailPartnersContext);

    const isFiltered = searchFilterText !== undefined && searchFilterText !== '';

    const renderTabPanel = useCallback(
        (currentTab: RetailPartnersTab) => {
            return (brokerListByActiveStatus[currentTab.value] as DirectBrokerDetails[])?.map(
                (details: DirectBrokerDetails, index) => {
                    if (isFiltered) {
                        if (details.indirect_broker_user.username.includes(searchFilterText))
                            return <RetailerPartnersDetails key={index} partner={details} />;
                    } else {
                        return <RetailerPartnersDetails key={index} partner={details} />;
                    }
                },
            );
        },
        [brokerListByActiveStatus, searchFilterText],
    );

    return (
        <Box>
            {tabs.map((currentTab, index) => (
                <TabPanel key={index} value={currentTab.value} style={{ padding: '24px 0px 0px 0px' }}>
                    {renderTabPanel(currentTab)}
                </TabPanel>
            ))}
        </Box>
    );
};

export default React.memo(RetailPartnerDetailsWrapper);
