import React, { useState } from 'react';
import { StyledAction, StyledTable } from 'components';
import { RenewalType, ViewablePolicyType } from 'utils/enums';
import { useEnv } from 'hooks';
import { Box, Typography, TableRow } from '@material-ui/core';
import { EditApplicationPopup } from 'components/popup';
import { Hidden } from '@material-ui/core';

const EditApplication = ({ policyRequest }) => {
    const { newBpRenewal } = useEnv();
    const isUponRenewal = newBpRenewal && policyRequest.policyType === ViewablePolicyType.UPON_RENEWAL;
    const isAutoRenewal = RenewalType.isAuto(policyRequest?.renewal_type.name)
    const editApplicationQuestionText =
    isUponRenewal && isAutoRenewal ? 'Any changes to the insured’s business?' : 'Need to change application answers?';
    const editApplicationText = isUponRenewal && isAutoRenewal ? '– Update the renewal application' : '– Edit the application.';
    // ToDo: we should remove the next line and comment out the line after, once we open the newBpRenewal FF
    const [openEditApplicationPopup, setOpenEditApplicationPopup] = useState(false);
    // const { setOpenEditApplicationPopup } = useContext(
    //     UponRenewalPolicyContext,
    // );

    return (
        <>
            <Hidden smDown>
                <TableRow>
                    <StyledTable.TableCell colSpan={6}>
                        <Box component="span" fontWeight="fontWeightLight">
                            <Typography variant="subtitle2" component="span">
                                {editApplicationQuestionText}
                            </Typography>
                        </Box>
                        <Box component="span" fontWeight="fontWeightMedium" ml={1}>
                            <StyledAction.Base onClick={() => setOpenEditApplicationPopup(true)} data-track="edit_application">
                                <Typography variant="subtitle2" component="span" color="primary">
                                    {editApplicationText}
                                </Typography>
                            </StyledAction.Base>
                        </Box>
                    </StyledTable.TableCell>
                </TableRow>
            </Hidden>
            <Hidden mdUp>
                <TableRow>
                    <StyledTable.TableCell colSpan={6}>
                        <Box fontWeight="fontWeightLight">
                            <Typography variant="subtitle2" component="span">
                                {editApplicationQuestionText}
                            </Typography>
                        </Box>
                        <Box fontWeight="fontWeightMedium">
                            <StyledAction.Base onClick={() => setOpenEditApplicationPopup(true)} data-track="edit_application">
                                <Typography variant="subtitle2" color="primary">
                                    {editApplicationText}
                                </Typography>
                            </StyledAction.Base>
                        </Box>
                    </StyledTable.TableCell>
                </TableRow>
            </Hidden>
            <EditApplicationPopup
                open={openEditApplicationPopup}
                handleClose={() => setOpenEditApplicationPopup(false)}
                policyRequestID={policyRequest.id}
            />
        </>
    );
};

export default EditApplication;
