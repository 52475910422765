import React from 'react';
import { RoundedButton } from 'components';
import { TableRow, Typography, Box, Hidden } from '@material-ui/core';
import { useBreakpoint } from 'hooks';
import Styled from 'components/table/styled-components';
import { InnerDataCellWithoutOption } from 'components/table/SharedComponents';

const InnerTableIncompleteBucket = ({ collapseData, handleButton, buttonText }) => {
    const isSm = useBreakpoint('down', 'sm');

    const RoundedButtonComponent = () => {
        return (
            <RoundedButton py={isSm ? 0.5 : 0.25} fullWidth={isSm} onClick={handleButton}>
                <Typography variant="subtitle2">{buttonText}</Typography>
            </RoundedButton>
        );
    };

    return (
        <>
            <Hidden mdUp>
                <TableRow>
                    <InnerDataCellWithoutOption collapseData={collapseData} />
                </TableRow>
                <TableRow>
                    <Styled.TableCell className="inner black" align="center" width="100%">
                        <Box pt={1.5}>
                            <RoundedButtonComponent />
                        </Box>
                    </Styled.TableCell>
                </TableRow>
            </Hidden>
            <Hidden smDown>
                <TableRow>
                    <InnerDataCellWithoutOption collapseData={collapseData} />
                    <Styled.TableCell className="inner alignEnd button black">
                        <RoundedButtonComponent />
                    </Styled.TableCell>
                </TableRow>
            </Hidden>
        </>
    );
};

export default InnerTableIncompleteBucket;
