import styled from 'styled-components';
import {
    Dialog as _Dialog,
    DialogTitle as _DialogTitle,
    DialogContent as _DialogContent,
    DialogActions as _DialogActions, CircularProgress as _CircularProgress,
} from '@material-ui/core';


const Dialog = styled(_Dialog)`
    &&& {
        ${({ theme }) => `
            & .MuiDialog-paper {
                max-width: 311px;
            }
            ${theme.breakpoints.up('md')} {
                & .MuiDialog-paper {
                    max-width: 560px;
                }
            }
        `}
    }
`;

const DialogTitle = styled(_DialogTitle)`
    &&& {
        padding-left: ${({ theme }) => theme.spacing(4)};
        padding-right: ${({ theme }) => theme.spacing(4)};
        background: #F2F2F2;
        & .MuiIconButton-root {
            padding: 0;
        }
    }
`;

const DialogContent = styled(_DialogContent)`
    &&& {
        &.expandable {
            overflow: hidden;
            transition: max-height 0.3s ease-in-out;
            height: auto;
            max-height: 600px;
            &.collapsed {
                max-height: 100px;
            }
        }
        ${({ theme }) => `
            padding-left: ${theme.spacing(4)}px;
            padding-right: ${theme.spacing(4)}px;
            padding-top: ${theme.spacing(3)}px;
            padding-bottom: ${theme.spacing(5)}px;
            ${theme.breakpoints.down('sm')} {
                padding-left: ${theme.spacing(3)}px;
                padding-right: ${theme.spacing(3)}px;
                padding-top: ${theme.spacing(2)}px;
                padding-bottom: ${theme.spacing(2)}px;
            }
        `}
    }
`;

const DialogActions = styled(_DialogActions)`
    &&& {
        ${({ theme }) => `
            margin-left: ${theme.spacing(4)}px;
            margin-right: ${theme.spacing(4)}px;
            padding: ${theme.spacing(3)}px 0;
            border-top: 2px solid #E6E6E6;
            ${theme.breakpoints.down('sm')} {
                margin-left: ${theme.spacing(3)}px;
                margin-right: ${theme.spacing(3)}px;
                padding-top: ${theme.spacing(2)}px;
            }
        `}
    }
`;

const WideDialog = styled(_Dialog)`
    &&& {
        ${({ theme }) => `
            & .MuiDialog-paper {
                max-width: 90%;
            }
        `}
    }
`;

const WideDialogContent = styled(_DialogContent)`
    &&& {
        ${({ theme }) => `
            padding: 0;
        `}
    }
`;

const CircularProgressBlack = styled(_CircularProgress)`
    &&& {
        color: black;
    }
`;

export default {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    WideDialog,
    WideDialogContent,
    CircularProgressBlack
};
