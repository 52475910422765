import React from 'react';
// @ts-ignore
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'stores/rootReducer';
import { Permissions } from 'utils/enums';

interface Props {
    children: React.ReactElement;
    bpUserValidation?: boolean;
    allowedPermissions?: Array<String>;
}

const RequireAuth: React.FC<Props> = ({
    children,
    allowedPermissions = [Permissions.LOGIN],
    bpUserValidation = true,
    ...rest
}) => {
    const { isAuthenticated } = useSelector((state: RootState) => state.newAuth);
    const { permissions: userPermissions, user } = useSelector((state: RootState) => state.user);
    const handleRedirectPage = () => {
        if (isAuthenticated === undefined || (isAuthenticated && !userPermissions)) {
            return null;
        }

        if (!isAuthenticated) {
            // Redirect them to the /login page, but save the current location they were
            // trying to go to when they were redirected
            // we save it in session storage if that location is a valid claim path
            // This allows us to send them along to that page after they login
            sessionStorage.setItem('originalPath', window.location.pathname);
            return <Redirect to={`/user/login?next=${window.location.href}`} />;
        } else if (bpUserValidation && !user?.is_bp_user) {
            return <Redirect to="/dashboard" />;
        } else if (allowedPermissions.some(permission => userPermissions?.includes(permission))) {
            // get the original path from session storage and redirect to that path after login and remove it from session storage
            const prevPage = sessionStorage.getItem('originalPath');
            if (prevPage) {
                sessionStorage.removeItem('originalPath');
                return <Redirect to={prevPage} />;
            }
            return children;
        }
    };
    return <Route {...rest} render={() => handleRedirectPage()} />;
};

export default RequireAuth;
