
import { setAuthenticated } from 'stores/auth/auth';
import { useAuthCheck } from './useAuthCheck';
import { useDispatch } from 'react-redux';


export const useAuth = () => {
    const { data, isLoading, error } = useAuthCheck();
    const dispatch = useDispatch();

    if (data) {
        dispatch(setAuthenticated(data));
    }

    return {
        data,
        isLoading,
    };
};
