import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Styled from './styled-components';
import DashboardFooter from 'components/footer/DashboardFooter';

const Footer = () => {
    const { t } = useTranslation('footer');
    const { pathname } = useLocation();
    const { authenticated } = useSelector(state => state.auth);
    const showDashboardFooter = useMemo(() => (authenticated && (pathname === '/dashboard')), [authenticated, pathname]);
    const year = new Date().getFullYear();

    return (
        <Styled.Footer>
            {showDashboardFooter && <DashboardFooter />}
            <span>
                &copy; {t('title', {year})}
            </span>
            <span>
                <Styled.Link href="https://at-bay.com/licenses" target="_blank">{t('licenses')}</Styled.Link>
                <Styled.Link href="https://at-bay.com/privacy" target="_blank">{t('privacy')}</Styled.Link>
                <Styled.Link href="https://at-bay.com/terms-of-service" target="_blank">{t('terms')}</Styled.Link>
            </span>
        </Styled.Footer>
    );
}

export default Footer;
