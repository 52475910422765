import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import Styled from './styled-components';
import { useBreakpoint, useEnv } from 'hooks';


const PolicyOwner = ({ userName, policyRequest }) => {
    const { pathname } = useLocation();
    const { newBpRenewal } = useEnv();
    const isSm = useBreakpoint('down', 'sm');
    const getUserNameInitials = () => {
        if (policyRequest?.broker_user?.username) {
            const fullName = policyRequest.broker_user.username;
            return fullName.split(' ').splice(0, 2).map(name => name.charAt(0).toUpperCase()).join('');
        }
        return '';
    };

    return (
        <Box display="flex" justifyContent="end">
            {(pathname.match('/submission/bind/*') || !newBpRenewal || !isSm) && (
                <Box display="flex" variant="subtitle2" fontWeight="fontWeightRegular">
                    <Box>Owned by</Box>
                    <Box fontWeight="fontWeightMedium" pl={0.5}>
                        {userName}
                    </Box>
                </Box>
            )}
            <Styled.UserIconButton>
                <Typography variant="body2" fontWeight="fontWeightBold">
                    {getUserNameInitials()}
                </Typography>
            </Styled.UserIconButton>
        </Box>
    );
};

export default PolicyOwner;
