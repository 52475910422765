import React from 'react';
import Styled from './styled-components';
import { RoundedButton } from 'components';
import {
    Box,
    Typography,
} from '@material-ui/core';

const ButtonBar = ({ data, clickHandler, variant = 'subtitle2', py = 1, fullWidth, smRenewalCard, px = 4 }) => {
    const [secondaryButton, primaryButton] = data;

    return (
        <Box component={Styled.Span} mr={fullWidth || smRenewalCard ? 0 : -3.5}>
            <RoundedButton
                width={smRenewalCard ? '70%' : fullWidth ? '50%' : 'auto'}
                className="secondary"
                onClick={() => clickHandler(secondaryButton.id)}
                disableRipple
                py={py}
                px={px}
            >
                <Typography variant={variant}>
                    {secondaryButton.content}
                </Typography>
            </RoundedButton>
            <RoundedButton
                width={smRenewalCard ? '30%' : fullWidth ? '50%' : 'auto'}
                className="primary"
                onClick={() => clickHandler(primaryButton.id)}
                disableRipple
                py={py}
                px={px}
            >
                <Typography variant={variant}>
                    {primaryButton.content}
                </Typography>
            </RoundedButton>
        </Box>
    );
}


export default ButtonBar;
