import styled from 'styled-components';
import { RoundedButton as _RoundedButton } from 'components';
import {
    Box as _Box,
    TextField as _TextField,
    IconButton as _IconButton,
    CircularProgress as _CircularProgress,
} from '@material-ui/core';


const Box = styled(_Box)`
    &&& {
        background-color: #F2F2F2;
        opacity: ${props => props.customdisable ? 0.6 : 1};
    }`;

const CircularProgress = styled(_CircularProgress) `
    &&& {
        color: #FFFFFF;
    }
`

const CloseIcon = styled(_IconButton)`
    &&& {
        font-family: i;
        cursor: ${props => props.customdisable ? 'default' : 'pointer'};
        &::before {
            font-size: 12px;
            content: '\\e926';
        }
        &.small {
            font-size: 8px;
            padding: 0px;
        }
    }
`;

const RoundedButton = styled(_RoundedButton)`
    &&& {
        cursor: ${props => props.customdisable ? 'default' : 'pointer'};
        :disabled {
            background-color: #bdb9b9;
            border: none !important;
        }
    }
`;

const errorMessage = styled(_Box) `
    &&& {
        color: #f44336;
    }
`;

const TextField = styled(_TextField)`
    .MuiOutlinedInput-notchedOutline {
        border-color: #E6E6E6;
    }

    &&& {
        background-color: #FFFFFF;
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
        .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
            border-color: ${props => props.inputerror ? 'red' : 'rgb(175, 175, 175)'};
            border-width: 1px;
        }
    }`;


export default { Box, TextField, CloseIcon, RoundedButton, CircularProgress, errorMessage }
