import { QueryClient } from 'react-query';
import { InitQueryClientFunctionType } from './queryClientTypes';

export const initQueryClient: InitQueryClientFunctionType = () => {
    return new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 60 * 1000,
                cacheTime: 15 * 60 * 1000,
                retry: 3,
                refetchOnMount: false,
                refetchOnWindowFocus: false,
                refetchOnReconnect: false,
            },
        },
    });
};
