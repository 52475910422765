import styled from 'styled-components';

import {
    Divider as _Divider,
} from '@material-ui/core';

const Divider = styled(_Divider)`
    &&& {
        height: 4px;
        background: #F2F2F2;
        &.thin {
            height: 2px;
        }
    }
`;

const ExpandableRowDivider = styled(_Divider)`
    &&& {
        height: 2px;
    }
`;

const VerticalDivider = styled(_Divider)`
    &&& {
        height: 22px;
        margin: 0 ${({ theme }) => theme.spacing(2)}px;
    }
`;

export default {
    Divider,
    ExpandableRowDivider,
    VerticalDivider,
};
