import React from 'react';
import { useHistory } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import { Typography, Hidden, Box } from '@material-ui/core';
import { RoundedButton, StyledDivider } from 'components';
import Styled from '../styled-components';


const UserExistsPage = ({ email }) => {
    const history = useHistory();
    const theme = useTheme();

    const handleReturnToLogin = () => {
        history.push('/user/login');
    };

    const handleReturnToResetPassword = () => {
        history.push('/user/login?show_forgot_password=true');
    };

    return (
        <>
            <Styled.FormHeader style={{ marginBottom: theme.spacing(1) }}>You look familiar...</Styled.FormHeader>
            <Styled.FormText variant="subtitle2" style={{ marginBottom: theme.spacing(5) }}>
                We already have an account associated with
                <span style={{ fontWeight: theme.typography.fontWeightMedium }}> {email}</span>. Don’t remember?
                Reset your password to get back in.
            </Styled.FormText>
            <Box textAlign="center">
                <RoundedButton py={0.4} width="117px" marginBottom={`${theme.spacing(1)}px`} onClick={handleReturnToLogin}>
                    <Typography variant="subtitle2">Sign In</Typography>
                </RoundedButton>
                <Box>
                    <Styled.CancelButton onClick={handleReturnToResetPassword}>Reset Password</Styled.CancelButton>
                </Box>
            </Box>
            <Hidden mdUp>
                <StyledDivider.Divider />
            </Hidden>
        </>
    );
}

export default UserExistsPage;