import React from 'react';
import { Hidden } from '@material-ui/core';
import Desktop from './components/Desktop';
import Mobile from './components/Mobile';


const LoadedCoverages = ({ handleCalculation }) => {
    // One day we might use this function to let the broker know which line items are out of bound
    // const isOutOfBounds = lineItem => {
    //     const originalLineItem = optionToQuote.quote.coverages.line_items.find(
    //         originalLineItem => originalLineItem.code === lineItem.code
    //     );

    //     if (!originalLineItem || !originalLineItem.other_parameter)
    //         return false;

    //     if (lineItem.type === LineItemType.WAITING_PERIOD) {
    //         return originalLineItem.other_parameter.value > lineItem.other_parameter.value
    //     } else if (lineItem.type === LineItemType.PERIOD_OF_INDEMNITY) {
    //         return originalLineItem.other_parameter.value < lineItem.other_parameter.value;
    //     }
    //     return false;
    // }

    const handleChangeLineItem = (event, type, code) => {
        handleCalculation(event.target.value, type, code);
    };

    return (
        <>
            <Hidden smDown>
                <Desktop handleChangeLineItem={handleChangeLineItem} />
            </Hidden>
            <Hidden mdUp>
                <Mobile handleChangeLineItem={handleChangeLineItem} />
            </Hidden>
        </>
    );
}

export default LoadedCoverages;
