import React from 'react';
import { RoundedButton } from 'components';
import { TableRow, Typography, Box, Hidden } from '@material-ui/core';
import { useBreakpoint } from 'hooks';
import Styled from 'components/table/styled-components';
import { InnerDataCellWithOption } from 'components/table/SharedComponents';

const InnerTableActiveBucket = ({ policyRequest, optionToQuoteData, handleButton, buttonText }) => {
    const classes = `inner ${optionToQuoteData.pending ? 'grey' : 'black'}`;
    const isSm = useBreakpoint('down', 'sm');

    const RoundedButtonComponent = () => {
        return (
            <RoundedButton py={isSm ? 0.5 : 0.25} fullWidth={isSm} onClick={handleButton}>
                <Typography variant="subtitle2">{buttonText}</Typography>
            </RoundedButton>
        );
    };

    return (
        <>
            <Hidden mdUp>
                <TableRow>
                    <InnerDataCellWithOption policyRequest={policyRequest} optionToQuoteData={optionToQuoteData} />
                </TableRow>
                <TableRow>
                    <Styled.TableCell className={classes} align="center" style={{ width: '100%' }} colSpan={3}>
                        {!optionToQuoteData.pending && (
                            <Box pt={1.5} width="100%">
                                <RoundedButtonComponent />
                            </Box>
                        )}
                    </Styled.TableCell>
                </TableRow>
            </Hidden>
            <Hidden smDown>
                <TableRow>
                    <InnerDataCellWithOption policyRequest={policyRequest} optionToQuoteData={optionToQuoteData} />
                    <Styled.TableCell className={`alignEnd button ${classes}`}>
                        <RoundedButtonComponent />
                    </Styled.TableCell>
                </TableRow>
            </Hidden>
        </>
    );
};

export default InnerTableActiveBucket;
