import styled from 'styled-components';
import { Container as _Container } from '@material-ui/core';

const BaseContainer = styled(_Container)`
    &&& {
        margin-top: 140px;
        max-width: ${({ theme }) => theme.breakpoints.values.container}px;
    }
`;

const BasicFormContainer = styled(_Container)`
    &&& {
        margin-top: 100px;
        ${({ theme }) => `
            max-width: 600px;
            ${theme.breakpoints.up('md')} {
                margin-top: 150px;
            }
            ${theme.breakpoints.up('lg')} {
                margin-top: 200px;
            }
        `}
    }
`;

const BasicFlexContainer = styled(BaseContainer)`
    &&& {
        ${({ theme }) => `
            margin-top: 112px;
            ${theme.breakpoints.down('md')} {
                margin-top: 96px;
                max-width: unset;
            }
            ${theme.breakpoints.down('sm')} {
                margin-top: 88px;
            }
        `}
    }
`;

export default {
    BaseContainer,
    BasicFormContainer,
    BasicFlexContainer,
};
