import styled from 'styled-components';
import {
    MenuItem as _MenuItem,
    Select as _Select,
    InputLabel as _InputLabel,
} from '@material-ui/core';

const InputLabel = styled(_InputLabel)`
     &&& {
        &.MuiFormLabel-root {
            font-size: 16px;
            font-weight: ${({ theme }) => theme.typography.fontWeightLight};
            color: rgba(26, 26, 26, 0.54);
            line-height: 22px;
        }
        &.MuiInputLabel-shrink {
            font-size: 12px;
            font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
            color: #919191;
        }
    }
`

const Select = styled(_Select)`
    &&& {
        width: 100%;
        &.MuiInput-underline:before {
            opacity: 0.42;
            border-bottom: 1px solid #010101;
        }
        &.MuiInput-underline:after {
            opacity: 1;
            border-bottom: 1px solid #010101;
        }
        .MuiSelect-select {
            font-size: 16px;
            font-weight: ${({ theme }) => theme.typography.fontWeightLight};
            line-height: 22px;
            &:focus {
                background-color: transparent;
            }
        }
    }
`;

const MenuItem = styled(_MenuItem)`
    &&&& {
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        background-color: transparent;
        white-space: break-spaces;

        &:before {
            left: 0;
            transition: 0.2s;
            content: "";
            display: block;
            position: absolute;
            top: 14px;
            width: ${(props) => props.selected ? '12px' : '0px'};
            height: 3px;
            background: black;
        }

        &:hover&:before {
            width: 12px;
        }
    }
`;

export default {
    MenuItem,
    Select,
    InputLabel
};
