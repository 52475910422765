import React from 'react';
import { RoundedButton, ButtonBar } from 'components';
import { TableRow, Typography, Box, Hidden } from '@material-ui/core';
import { useBreakpoint } from 'hooks';
import Styled from 'components/table/styled-components';
import { InnerDataCellWithOption, InnerDataCellWithoutOption } from 'components/table/SharedComponents';

const InnerTableRenewals = ({ policyRequest, optionToQuoteData, handleButton, optionToQuoteId, buttonText }) => {
    const classes = policyRequest.isPreQuote ? null : `inner ${optionToQuoteData.pending ? 'grey' : 'black'}`;
    const isSm = useBreakpoint('down', 'sm');

    const RoundedButtonComponent = () => {
        return buttonText ? (
            <RoundedButton py={isSm ? 0.5 : 0.25} px={isSm ? 4 : 4} fullWidth={isSm} onClick={handleButton}>
                <Typography variant="subtitle2">{buttonText}</Typography>
            </RoundedButton>
        ) : (
            <ButtonBar
                fullWidth={isSm}
                py={isSm ? '8px' : '6.5px'}
                variant="subtitle2"
                clickHandler={handleButton}
                data={[
                    {
                        content: 'Customize',
                        primary: false,
                        id: `${optionToQuoteData.id}:customize`,
                    },
                    {
                        content: policyRequest.unchecked_pre_bind_contingencies_count === 0 ? 'Bind' : 'Review',
                        primary: true,
                        id: `${optionToQuoteData.id}:bind`,
                    },
                ]}
            />
        );
    };

    return (
        <>
            {policyRequest.isPreQuote ? (
                <>
                    <Hidden mdUp>
                        <TableRow>
                            <InnerDataCellWithoutOption collapseData={policyRequest.collapseData} />
                        </TableRow>
                        <TableRow>
                            <Styled.TableCell className="inner black" align="center" width="100%">
                                <Box pt={1.5}>
                                    <RoundedButtonComponent />
                                </Box>
                            </Styled.TableCell>
                        </TableRow>
                    </Hidden>
                    <Hidden smDown>
                        <TableRow>
                            <InnerDataCellWithoutOption collapseData={policyRequest.collapseData} />
                            <Styled.TableCell className="inner alignEnd button black">
                                <RoundedButtonComponent />
                            </Styled.TableCell>
                        </TableRow>
                    </Hidden>
                </>
            ) : (
                <>
                    <Hidden mdUp>
                        {optionToQuoteId === optionToQuoteData.id && (
                            <>
                                <TableRow>
                                    <InnerDataCellWithOption
                                        policyRequest={policyRequest}
                                        optionToQuoteData={optionToQuoteData}
                                    />
                                </TableRow>
                                <TableRow>
                                    <Styled.TableCell
                                        className={classes}
                                        align="center"
                                        style={{ width: '100%' }}
                                        colSpan={3}>
                                        {!optionToQuoteData.pending && (
                                            <Box pt={1.5} width="100%">
                                                <RoundedButtonComponent />
                                            </Box>
                                        )}
                                    </Styled.TableCell>
                                </TableRow>
                            </>
                        )}
                    </Hidden>
                    <Hidden smDown>
                        <TableRow>
                            <InnerDataCellWithOption
                                policyRequest={policyRequest}
                                optionToQuoteData={optionToQuoteData}
                            />
                            <Styled.TableCell className={`alignEnd button ${classes}`}>
                                {!optionToQuoteData.pending && <RoundedButtonComponent />}
                            </Styled.TableCell>
                        </TableRow>
                    </Hidden>
                </>
            )}
        </>
    );
};

export default InnerTableRenewals;
