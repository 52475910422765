import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAxiosClient } from 'hooks';
import useTheme from '@material-ui/core/styles/useTheme';
import {  CreateSubmissionFormType } from 'utils/enums';
import { StyledTypography, StyledAction, Loading } from 'components';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Box, Typography, Tooltip, ClickAwayListener } from '@material-ui/core';


const RecommendedProduct = ({ product, companyId, ...props }) => {
    const history = useHistory();
    const theme = useTheme();
    const axiosClient = useAxiosClient();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [{ loading }, createPolicyRequest] = axiosClient({
        method: 'post',
        url: '/company/create_submission',
    }, { manual: true });

    const createPolicyFromProduct = async () => {
        const res = await createPolicyRequest({
            data: {
                company_id: companyId,
                product_id: product.id,
                form_name: CreateSubmissionFormType.QUOTE,
            }
        });
        history.push(`/questionnaire/policy/${res.data.policy_request_id}`);
    }

    return (
        <Box {...props} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Box display="flex">
                <Box
                    component={props => <StyledTypography.FadedTypography variant="subtitle2" component="span" {...props}/>}
                    display="flex"
                    alignItems="center"
                    mr={2}>
                    <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
                        <Tooltip
                            arrow
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={() => setTooltipOpen(false)}
                            open={tooltipOpen}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={product.description}
                        >
                            <Box component={StyledAction.Base} onClick={() => setTooltipOpen(!tooltipOpen)} display="flex" mr={1}>
                                <InfoOutlinedIcon />
                            </Box>
                        </Tooltip>
                    </ClickAwayListener>
                </Box>
                <Box component={props => <Typography variant="subtitle2" component="span" {...props}/>}
                    fontWeight="fontWeightMedium">
                    {product.name}
                </Box>
            </Box>
            {loading ? (
                <Box position="relative"
                    height={theme.typography.subtitle2.fontSize}
                    width={theme.typography.subtitle2.fontSize}
                    mt={[0.25, 0.25, 0.5]}>
                    <Loading size="100%" color="inherit" />
                </Box>
            ) : (
                <Typography variant="subtitle2" component="span" color="primary">
                    <Box component={StyledAction.Base} onClick={createPolicyFromProduct}>
                        <KeyboardArrowRightIcon />
                    </Box>
                </Typography>
            )}
        </Box>
    )
}


export default RecommendedProduct;
