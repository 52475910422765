import styled from 'styled-components';

const FormText = styled.div`
    &&& {
        color: ${props => (props.errorState ? '#D31D1A' : 'inherit')};

        ${({ theme }) => `
            margin-bottom: ${theme.spacing(1.25)}px;
            font-weight: ${theme.typography.fontWeightRegular};

            ${theme.breakpoints.down('sm')} {
                font-size: ${theme.typography.body1.fontSize};
            }
        `}
    }
`;

const PasswordBullet = styled.div`
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 2px;

    ${({ theme }) => `
        margin-bottom: ${theme.spacing(0.25)}px;
        background: ${theme.palette.text.primary};
    `}
`;

export default {
    FormText,
    PasswordBullet,
};
