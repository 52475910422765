import { useQuery } from 'react-query';
import { queryKeys } from '../queryKeys';
import { AxiosInstance } from 'axios';
import { useAxiosInstance } from 'hooks';
import { ErrorResponseType } from '../../mutationTypes';


type CheckAuthServerType = {
    is_authorized: boolean;
};

const convertUserDetails = (checkAuthObj: CheckAuthServerType) => {
    return {
        isAuthenticated: checkAuthObj.is_authorized,
    };
};

type CheckAuthClientType = ReturnType<typeof convertUserDetails>;

type QueryReturnType = {
  data: CheckAuthClientType | undefined;
  error: ErrorResponseType | undefined;
  isLoading: boolean;
  isFetching: boolean;
};

const checkAuth = async (axiosInstance: AxiosInstance,): Promise<CheckAuthServerType> => {
    const url = '/auth/is_authorized';
    const { data } = await axiosInstance.get(url);

    return data;
};

export const useAuthCheck = (): QueryReturnType => {
    const axiosInstance = useAxiosInstance();
    const { data, error, isLoading, isFetching } = useQuery(
        [queryKeys.CHECK_AUTH],
        () => checkAuth(axiosInstance),
        { select: convertUserDetails, cacheTime: 0, staleTime: 0, retry: 2 },
    );

    return { data, error, isLoading, isFetching } as QueryReturnType;
};
