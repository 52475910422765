import { useAxiosInstance } from '../../../hooks';
import { ErrorResponseType } from '../../mutationTypes';
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { queryKeys } from '../queryKeys';
import { AxiosInstance } from 'axios';

export type CreateSubmissionResponseType = {
    company_id: number;
    policy_request_id: number;
    resubmit: boolean;
    status: 'success';
};

export type CreateSubmissionType = {
    company_id?: number;
    company_name: string;
    form_name: string;
    url: string;
    product_id?: number;
    industry_id?: string;
    has_website?: string;
};

type MutationReturnType = {
    mutate: UseMutateFunction<CreateSubmissionResponseType, ErrorResponseType, CreateSubmissionType, unknown>;
    error: ErrorResponseType | null;
    isLoading: boolean;
};

const createSubmission = async (
    createSubmissionData: CreateSubmissionType,
    axiosInstance: AxiosInstance,
): Promise<CreateSubmissionResponseType> => {
    const res = await axiosInstance.post('/company/create_submission', {
        ...createSubmissionData,
    });

    return res?.data as CreateSubmissionResponseType;
};

export const useCreateSubmission = (
    onSuccessCallback: (result: CreateSubmissionResponseType) => void,
    onErrorCallback: (error: ErrorResponseType) => void,
): MutationReturnType => {
    const queryClient = useQueryClient();
    const axiosInstance = useAxiosInstance();

    const { mutate, error, isLoading } = useMutation(
        (createSubmissionData: CreateSubmissionType) => createSubmission(createSubmissionData, axiosInstance),
        {
            onSuccess: onSuccessCallback,
            onError: onErrorCallback,
            onSettled: () => {
                queryClient.resetQueries([queryKeys.BUCKETS.QUOTES, queryKeys.SUB_BUCKETS.INCOMPLETE]);
                queryClient.resetQueries([queryKeys.BUCKETS.QUOTES, queryKeys.SUB_BUCKETS.INACTIVE]);
            },
        },
    );

    return { mutate, error, isLoading };
};
