import styled from 'styled-components';
import { Typography as _Typography, Container as _Container } from '@material-ui/core';

const FormContainer = styled(_Container)`
    &&& {
        max-width: 320px;
        box-sizing: content-box;

        .Mui-error {
            font-size: 12px;
        }

        ${({ theme }) => `
            ${theme.breakpoints.down('sm')} {
                margin-top: 32px;
            }            
        `}
    }
`;

const FormHeader = styled(_Typography)`
    &&& {
        font-size: 24px;
        font-family: America;
        margin-bottom: 15px;

        ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightBold};
            
            ${theme.breakpoints.up('md')} {
                font-size: 32px;
            }
        `}
    }
`;

const FormText = styled.div`
    &&& {
        color: ${props => (props.errorState ? '#D31D1A' : 'inherit')};
        line-height: 20px;

        ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightRegular};

            ${theme.breakpoints.down('sm')} {
                font-size: ${theme.typography.body1.fontSize};
            }
        `}
    }
`;

const FormTextBold = styled.div`
    color: ${props => (props.errorState ? '#D31D1A' : 'inherit')};
    margin-bottom: 25px;

    ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightBold};

            ${theme.breakpoints.down('sm')} {
                font-size: ${theme.typography.body1.fontSize};
            }
        `}
`;

const EmailLink = styled(_Typography)`
    &&& {
        margin-bottom: 35px;

        ${({ theme }) => `
            font-size: ${theme.typography.subtitle2.fontSize};
            font-weight: ${theme.typography.fontWeightMedium};
            color: ${theme.palette.primary.main};

            ${theme.breakpoints.down('sm')} {
                font-size: ${theme.typography.body1.fontSize};
            }
        `}
    }

    a {
        text-decoration: none;
    }
`;

const LogoContainer = styled.div`
    position: absolute;
    top: 20px;
    left: 32px;
`;

export default {
    FormContainer,
    FormHeader,
    EmailLink,
    LogoContainer,
    FormText,
    FormTextBold,
};
