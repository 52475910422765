import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Icon } from 'components';
import Styled from './styled-components';


const Snapshot = ({icon, setHandler, snapshotsText, text, submissions, premiums, snapshots, fullWidth}) => {
    return (
        <Styled.OuterBox
            mb={0.5}
            p={[0.5, 0.5, 1]}
            bgcolor="white"
            borderRadius="2px"
            flex="0 0 auto"
            height={['106px', '106px', '136px', '148px']}
            maxWidth={fullWidth ? 'default' : ['303px', '303px', '294px', '294px']}
            width={fullWidth ? 'default' : ['100%', '100%', '100%', 'inherit']}
            mr={[1, 1, 1, 0]}>
            <Box
                onClick={() => setHandler(snapshotsText)}
                bgcolor={snapshots === snapshotsText ? 'rgb(238, 242, 254)' : 'white'}
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                data-testid={snapshots === snapshotsText ? `activeSnapshot-${snapshotsText}` : `snapshot-${snapshotsText}`}
            >
                <Box display="flex">
                    <Box pt={2.1875} pl={2}>
                        <Icon icon={icon} typographyProps={{ variant: 'h1', color: 'primary'}}/>
                    </Box>
                    <Box pt={2} pl={0.5} fontWeight="fontWeightLight">
                        <Typography variant="h1" color="primary">
                            {submissions}
                        </Typography>
                    </Box>
                </Box>
                <Box pb={{sm: 1.5, md: 2}}>
                    <Styled.TextBox>
                        <Typography variant="subtitle1">{text}</Typography>
                    </Styled.TextBox>
                </Box>
            </Box>
        </Styled.OuterBox>
    );
}

export default Snapshot;
