import React, { useEffect, useState } from 'react';
import { makeUseAxios } from 'axios-hooks';
import { useSelector } from 'react-redux';
import { Intercom } from 'services';
import { useEnv } from 'hooks';
import Cookies from 'js-cookie';
import axios from 'axios';

export const axiosClientContext = React.createContext(null);
export const AxiosClientContextProvider = ({ children }) => {
    const { apiGuiBaseUrl } = useEnv();
    const { user, permissions: userPermissions } = useSelector(state => state.user);
    let atbayCsrfToken = Cookies.get('at-bay-csrf-token');
    if (!atbayCsrfToken) {
        atbayCsrfToken = '';
    }
    const config = {
        baseURL: apiGuiBaseUrl,
        headers: {
            'x-at-bay-csrf': atbayCsrfToken,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    };
    const axiosInstance = axios.create(config);
    const [axiosClient] = useState(() =>
        makeUseAxios({
            axios: axiosInstance,
        }),
    );
    // Add middleware to handle 401 status-code responses
    axiosInstance.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error?.response?.status === 401) {
                if (error?.response?.config?.url?.includes('/auth/is_authorized')) {
                    // When we get a 401 response from the /auth/is_authorized endpoint, we understand that the user
                    // might be authorized, but he is missing some cookies to be fully authorized. In this case we want
                    // to force the user to logout.
                    window.location.href = '/svc/api/gui/v2/auth/auth_logout';
                }
                else {
                    // When we get a 401 response, we redirect the user to the login page
                    window.location.href = '/login';
                }
            }
            return Promise.reject(error);
        },
    );

    useEffect(() => {
        if (user && userPermissions) {
            Intercom.setUserContext(user, userPermissions);
        }
    }, [user, userPermissions]);

    return <axiosClientContext.Provider value={{ axiosClient, axiosInstance }}>{children}</axiosClientContext.Provider>;
};
