import React, { Fragment } from 'react';
import { StyledForm, RoundedButton } from 'components';
import { Typography } from '@material-ui/core';
import Styled from '../styled-components';


const CustomForm = ({handleSubmit, header, fieldsList, buttonText}) => {
    return (
        <Styled.FormContainer>
            <Styled.FormHeader>{header}</Styled.FormHeader>
            <StyledForm.Form onSubmit={handleSubmit}>
                {fieldsList.map((field, index) => (
                    <Fragment key={`field_${index}`}>
                        {field}
                    </Fragment>
                ))}
                <RoundedButton type="submit" py={0.4} mt={2} disabled={false}>
                    <Typography variant="subtitle2">{buttonText}</Typography>
                </RoundedButton>
            </StyledForm.Form>
        </Styled.FormContainer>
    );
}

export default CustomForm;
