import React, { FC, useCallback } from 'react';
import { StyledTypography } from 'components';
import { ExpandableRow } from 'components/expandable-row/ExpandableRow';
import { TableDotDivider } from '../table-divider';
import { OptionToQuote } from 'types/optionToQuote';
import PolicyPricesTable from './PolicyPricesTable';
import CommissionTable from './CommisionTable';

interface Props {
    optionToQuote: OptionToQuote;
    commission: number;
}

const CommissionAndPolicyTotal: FC<Props> = ({ commission, optionToQuote }) => {
    const firstColSpan = 1,
        variant = 'subtitle2',
        subVariant = 'body2',
        firstColPadding = 0,
        subCoverageSpacing = 1;

    return (
        <>
            <PolicyPricesTable
                variant={variant}
                subVariant={subVariant}
                firstColSpan={firstColSpan}
                firstColPadding={firstColPadding}
                subCoverageSpacing={subCoverageSpacing}
                optionToQuote={optionToQuote}
            />
            <TableDotDivider />
            <CommissionTable
                variant={variant}
                firstColSpan={firstColSpan}
                firstColPadding={firstColSpan}
                commission={commission}
            />
        </>
    );
};

export const CommissionAndPolicyTotalDetails: FC<Props> = ({ commission, optionToQuote }) => {
    const renderTitle = useCallback(isToggled => {
        return (
            <StyledTypography.FadedTypography variant="body2" align="justify">
                {`${isToggled ? 'Hide' : 'View'} policy total & commission`}
            </StyledTypography.FadedTypography>
        );
    }, []);

    return (
        <>
            <ExpandableRow
                renderTitle={renderTitle}
                renderContent={<CommissionAndPolicyTotal commission={commission} optionToQuote={optionToQuote} />}
                needSpace={false}
            />
        </>
    );
};
