import React, { FC, Fragment } from 'react';
import { Box, Hidden, TableBody } from '@material-ui/core';
import { CompanyPolicies } from 'types/CompanyPolicies';
import Styled from 'components/table/styled-components';
import { EmptyTable } from 'components/table/SharedComponents';
import { ReactComponent as ClipboardIcon } from 'components/icons/clipboard.svg';
import Table from '@material-ui/core/Table';
import { createClientsListPreviewObjectByBucket } from './utils';
import { BucketSubBucketType, BucketType, SubBucketType } from './types';
import { bucketToHeaderComponentMap, subBucketToEmptyTextMap, subBucketToRowComponentMap } from './consts';
import _ from 'lodash';

interface Props {
    companies: CompanyPolicies[];
    bucket: BucketType;
    subBucket: SubBucketType;
}

const CompaniesList: FC<Props> = ({ bucket, subBucket, companies }) => {
    const clientsListPreview = createClientsListPreviewObjectByBucket(companies, bucket, subBucket);
    const TableHead = bucketToHeaderComponentMap[bucket];
    const ListItem = subBucketToRowComponentMap[(bucket + subBucket) as BucketSubBucketType];
    const emptyText = subBucketToEmptyTextMap[(bucket + subBucket) as BucketSubBucketType];

    return (
        <>
            <Styled.TableContainer>
                {clientsListPreview.length === 0 ? (
                    <Box bgcolor="white">
                        <Box>
                            <EmptyTable
                                icon={emptyText.icon || <ClipboardIcon />}
                                title={emptyText.title}
                                text={emptyText.subTitle}
                                extraText={emptyText.text}
                            />
                        </Box>
                    </Box>
                ) : (
                    <Table aria-label="simple table" style={{ borderCollapse: 'separate' }}>
                        <Hidden smDown>
                            <TableHead subBucket={subBucket} />
                        </Hidden>
                        <TableBody>
                            {clientsListPreview.map(client => (
                                <Fragment key={`company_${_.head(client.data)?.company_id}`}>
                                    <ListItem
                                        client={client}
                                        companyId={_.head(client.data)?.company_id}
                                        subBucket={subBucket}
                                    />
                                    <Styled.TableRow>
                                        <Styled.TableCell className="space" />
                                    </Styled.TableRow>
                                </Fragment>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Styled.TableContainer>
        </>
    );
};

export default CompaniesList;
