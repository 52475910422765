import React from 'react';
import { useBreakpoint } from 'hooks';
import LoadingAggregate from './components/LoadingAggregate';
import LoadedAggregate from './components/LoadedAggregate/index';
import Styled from '../styled-components';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';


const AggregateWindow = ({handleCalculation, handleCreateQuoteBuilder}) => {
    const { customizationLoading, customizedOptionToQuote } = useSelector(state => state.company);
    const isSm = useBreakpoint('down', 'sm');
    const borderColor = customizedOptionToQuote?.quote.policy_request.product.background_color;


    return (
        <Box component={Styled.LongContainer} borderTop={`6px solid ${borderColor || 'transparent'}`}>
            <Box style={isSm ? { display: 'flex', flexDirection: 'column', alignItems: 'center' } : {}}>
                <Box mb={1.5}>
                    <Box fontWeight={700}>
                        <Box width="310px">
                            <Typography variant="h6">
                                Aggregate Limit & Retention
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {customizationLoading.loadingAggregate ?
                    <LoadingAggregate /> :
                    <LoadedAggregate
                        handleCreateQuoteBuilder={handleCreateQuoteBuilder}
                        handleCalculation={handleCalculation}
                    />}
            </Box>
        </Box>
    )
}

export default AggregateWindow;
