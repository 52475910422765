import React, { FC } from 'react';
import { StyledTable } from 'components/index';
import { Box, TableRow, Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
interface Props {
    variant: Variant | 'inherit';
    firstColSpan: number;
    firstColPadding: number;
    subCoverageSpacing: number;
    totalPremium: number;
}

const PolicyTableHeader: FC<Props> = ({ variant, firstColSpan, firstColPadding, subCoverageSpacing, totalPremium }) => {
    return (
        <Box component={TableRow} className="head">
            <StyledTable.TableCell className="head" colSpan={firstColSpan} padding="none">
                <Typography variant={variant} component="span">
                    <Box mt={1.5} mb={subCoverageSpacing} pl={firstColPadding} fontSize={16} fontWeight={'bold'}>
                        Policy Total
                    </Box>
                </Typography>
            </StyledTable.TableCell>
            <StyledTable.TableCell className="head" padding="none">
                <Typography variant={variant} component="span">
                    <Box fontSize={16}> </Box>
                </Typography>
            </StyledTable.TableCell>
            <StyledTable.TableCell className="head" padding="none">
                <Typography variant={variant} component="span">
                    <Box fontSize={16} fontWeight={'bold'}>
                        $ {totalPremium}
                    </Box>
                </Typography>
            </StyledTable.TableCell>
        </Box>
    );
};

export default PolicyTableHeader;
