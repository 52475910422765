import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useBreakpoint, useIsMounted } from 'hooks';
import { Box, FormControl, RadioGroup, Typography } from '@material-ui/core';
import GeneralStyled from '../../../components/styled-components';
import InfoTooltip from '../../../components/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setQuestionValue } from 'stores/questions/questionsSlice';
import { questionHasError, scrollToQuestionError } from 'utils/questionnaireSequence';
import { setNewQuestionData } from 'stores/questionsErrors/questionsErrorsSlice';
import CustomRadio from 'components/radio/CustomRadio';
import QuestionHeader from '../shared/QuestionHeader';
import Styled from 'modules/questionnaire/pages/new-questionnaire/components/questions/styled-components';
import { getSortedAnswers } from '../../utils';

const SingleChoiceQuestion = ({ question, firstInSection, updateForm, setScroll, scroll, setChangeMade, answers }) => {
    const questionRef = useRef(null);
    const dispatch = useDispatch();
    const answer = useSelector(state => state.questions?.questionsToAnswers[question.id]);
    const { firstQuestionWithError, submitFailed } = useSelector(state => state.questionErrors);
    const isSm = useBreakpoint('down', 'sm');
    const isLg = useBreakpoint('up', 'lg');
    const [value, setValue] = useState(answer || '');
    const [currentProcessingValue, setCurrentProcessingValue] = useState(null);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const questionMetaData = useSelector(
        state => state.questionnaire.questionsMetadata.find(item => item.questionId === question.id)?.metadata,
    );
    const disabledQuestion = question.disabled_question;
    const isMounted = useIsMounted();

    const sortedOptionalAnswers = useMemo(() => {
        return getSortedAnswers(question);
    }, [question.optional_answers]);

    useEffect(() => {
        dispatch(
            setNewQuestionData({
                questionId: question.id,
                hasError: questionHasError(submitFailed, answer, question.required, true),
            }),
        );
    }, [submitFailed]);

    useEffect(() => {
        if (scroll && firstQuestionWithError === question.id) {
            scrollToQuestionError(questionRef);
            setScroll(false);
        }
        if (!scroll) {
            setError(questionHasError(submitFailed, answer, question.required, true));
        }
    }, [firstQuestionWithError, scroll]);

    useEffect(() => {
        dispatch(
            setNewQuestionData({
                questionId: question.id,
                hasError: questionHasError(submitFailed, answer, question.required, true),
            }),
        );
    }, []);

    useEffect(() => {
        if (!questionMetaData?.savingQuestion && isMounted()) {
            setCurrentProcessingValue(null);
        }
    }, [questionMetaData?.savingQuestion]);

    useEffect(() => {
        setValue(answer || '');
    }, [answer]);

    const handleChange = e => {
        const currentValue = e.target.value;
        setCurrentProcessingValue(currentValue);
        setValue(currentValue);
        setError(false);
        dispatch(setNewQuestionData({ questionId: question.id, hasError: false }));
        dispatch(setQuestionValue({ name: question.id, value: currentValue }));
        setChangeMade(true);
        const currentAnswer = question.optional_answers.filter(item => item.id === e.target.value)[0];
        updateForm({
            questionId: question.id,
            answerId: currentAnswer.id,
            answerType: currentAnswer.type,
            value: currentValue,
            invalid: false,
            required: question.required,
            questionType: question.type,
        });
    };
    return (
        <Box
            ref={questionRef}
            display="flex"
            px={isSm ? 4 : 0}
            key={`${question.id}_question`}
            pb={0.5}
            justifyContent="center">
            <FormControl fullWidth style={{ maxWidth: isSm ? 'unset' : '424px' }} error={error}>
                <Box
                    component={GeneralStyled.FormLabel}
                    display="flex"
                    alignItems="center"
                    className={firstInSection ? 'nospace' : null}>
                    <Typography variant="body1">
                        <QuestionHeader question={question} grayOutQuestion={disabledQuestion} />
                        {question.application_parameters.help_text && (
                            <InfoTooltip
                                dataTrack={`questionnaire_tooltip_${question.id}`}
                                placement="bottom"
                                open={open}
                                setOpen={setOpen}
                                text={question.application_parameters.help_text}
                                select={true}
                            />
                        )}
                    </Typography>
                </Box>
                <RadioGroup
                    onChange={e => handleChange(e)}
                    value={value}
                    data-track={`questionnaire_question_${question.id}`}>
                    {sortedOptionalAnswers.map(choiceAnswer => {
                        return (
                            <Box display="flex" width="100%" justifyContent="space-between">
                                <GeneralStyled.FormControlLabel
                                    // onClick functions handled ticket https://cyberjack.atlassian.net/browse/BP-2256
                                    onClick={e => e.preventDefault()}
                                    key={choiceAnswer.id}
                                    label={
                                        <Typography
                                            variant="body1"
                                            style={{
                                                paddingBottom: isLg ? 1 : 0,
                                                paddingTop: isLg ? 0 : 3,
                                                paddingLeft: 12,
                                            }}
                                            onClick={e => e.stopPropagation()}>
                                            <Box
                                                component="span"
                                                color={
                                                    disabledQuestion
                                                        ? '#b8b8b8'
                                                        : answer === choiceAnswer.id
                                                            ? '#191919'
                                                            : '#696969'
                                                }>
                                                {choiceAnswer.display_name}
                                            </Box>
                                        </Typography>
                                    }
                                    control={
                                        <CustomRadio
                                            variant={isLg ? 'subtitle2' : 'h4'}
                                            onClick={e => e.stopPropagation()}
                                        />
                                    }
                                    disabled={disabledQuestion}
                                    value={choiceAnswer.id}
                                />
                                {currentProcessingValue === choiceAnswer.id &&
                                    questionMetaData?.savingQuestion &&
                                    !error && (
                                    <Styled.MultipleChoiceCircularProgress
                                        style={{ alignSelf: 'end', marginTop: '-1px' }}
                                        size={18}
                                    />
                                )}
                            </Box>
                        );
                    })}
                </RadioGroup>
            </FormControl>
        </Box>
    );
};

export default SingleChoiceQuestion;
