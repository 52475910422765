import styled from 'styled-components';
import { Box as _Box } from '@material-ui/core';


const DeleteSubmissionBox = styled(_Box)`
    &&& {
        display: flex;
        color: #BDBDBD;
        justify-content: flex-end;
        padding-bottom: ${({ theme }) => theme.spacing(1)}px;
        &:hover > p {
            color:  ${({ theme }) => theme.palette.primary.main};
        }
    }
`;

export default {
    DeleteSubmissionBox,
};
