import styled from 'styled-components';
import {
    TextField as _TextField,
    Typography as _Typography,
    Container as _Container,
} from '@material-ui/core';

const FormContainer = styled(_Container)`
    &&& {
        max-width: 495px;
        background: ${({ theme }) => theme.palette.primary.contrastText};
        text-align: center;

        ${({ theme }) => `
            margin: ${theme.spacing(5)}px auto ${theme.spacing(3.75)}px;
            &.first {
                margin-top: ${theme.spacing(20)}px;
            }
            padding: ${theme.spacing(7)}px ${theme.spacing(8)}px;
            
            .Mui-error {
                font-size: ${theme.typography.body2.fontSize};
            }

            ${theme.breakpoints.down('sm')} {
                margin-top: ${theme.spacing(10)}px;
                padding: ${theme.spacing(7)}px ${theme.spacing(1)}px;
            }            
        `}
    }
`;

const TextField = styled(_TextField)`
    &&& {
        width: 100%;

        .MuiInput-underline:before {
            border-bottom: 1px solid #e6e6e6;
        }

        ${({ theme }) => `
            margin-bottom: ${theme.spacing(4)}px;
            font-weight: ${theme.typography.fontWeightLight};

            .MuiInputBase-input {
                font-size: ${theme.typography.subtitle2.fontSize};
            }

            ${theme.breakpoints.down('sm')} {
                font-size: ${theme.typography.body1.fontSize};
            }            
        `}
    }
`;

const FormHeader = styled(_Typography)`
    &&& {
        font-family: America;
        ${({ theme }) => `
            margin-bottom: ${theme.spacing(2)}px;
            font-size: ${theme.typography.h5.fontSize};
            font-weight: ${theme.typography.fontWeightBold};
            
            ${theme.breakpoints.up('md')} {
                font-size: ${theme.typography.h3.fontSize};
            }
        `}
    }
`;


export default {
    FormContainer,
    TextField,
    FormHeader,
}
