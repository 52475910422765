import styled from 'styled-components';
import { Button } from '@material-ui/core';


const RoundedButton = styled(Button)`
    &&& {
        text-transform: none;
        color: ${({ theme }) => theme.palette.primary.contrastText};
        border-radius: 300px;
        &:not([disabled]) {
            background: ${({ theme }) => theme.palette.primary.main};
            &.black {
                background: ${({ theme }) => theme.palette.secondary.contrastText};
            }
        }
        &[disabled] {
            cursor: default;
            color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.26);
            background: #CCCCCC;
        }
    }
`;

export default {
    RoundedButton,
};
