import React from 'react';
import LoadingPremium from './components/LoadingPremium';
import LoadedPremium from './components/LoadedPremium';
import SkeletonPremium from './components/SkeletonPremium';
import RequiredReview from './components/RequiredReview';
import Styled from '../styled-components';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';


const PremiumWindow = () => {
    const { customizationLoading, customizedOptionToQuote } = useSelector(state => state.company);
    const borderColor = customizedOptionToQuote?.quote.policy_request.product.background_color;

    return (
        <Box component={Styled.Container} borderTop={`6px solid ${borderColor || 'transparent'}`}>
            {
                customizationLoading.loadingPremium ? <LoadingPremium /> :
                    customizationLoading.skeletonPremium ? <SkeletonPremium /> :
                        customizationLoading.requiredReview ? <RequiredReview /> :
                            <LoadedPremium />
            }
        </Box>
    )
}

export default PremiumWindow;
