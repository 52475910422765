import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAxiosClient } from 'hooks';
import { setOptionToQuote } from 'stores/company/companySlice';
import { Header, QuoteReview, Dates, Contingencies, BindSection, Underwriter, AdditionalInfo } from 'components/policy';
import { Box } from '@material-ui/core';
import { StyledContainer, Loading, Error } from 'components';
import { SubmissionsBucket } from 'utils/enums';
import { getOptionToQuoteQuery } from 'queries/optionToQuote';

const PolicyDetails = () => {
    const dispatch = useDispatch();
    const axiosClient = useAxiosClient();
    const { optionToQuoteId } = useParams();
    const { optionToQuote } = useSelector(state => state.company);
    const [{ data: optionToQuoteData, error: optionToQuoteError }, getOptionToQuote] = axiosClient({
        method: 'post',
        url: `/optionToQuote/${optionToQuoteId}`,
        data: {
            query: getOptionToQuoteQuery({ includePolicyRequest: true })
        }
    }, { manual: true });

    useEffect(() => {
        getOptionToQuote();
        return () => dispatch(setOptionToQuote(null));
    }, []);

    useEffect(() => {
        if (optionToQuoteData) {
            dispatch(setOptionToQuote(optionToQuoteData.optionToQuote));
        }
    }, [optionToQuoteData])

    const { athena_submissions_bucket, conditionally_bound } = optionToQuote?.quote?.policy_request || {};
    const enableActions = conditionally_bound || athena_submissions_bucket === SubmissionsBucket.BIND_REQUESTED;

    if (optionToQuoteError ||
        (athena_submissions_bucket && ![SubmissionsBucket.BOUND, SubmissionsBucket.BIND_REQUESTED].includes(athena_submissions_bucket)) ||
        (optionToQuote?.quote?.preferred !== undefined && !optionToQuote.quote.preferred)) {
        return <Error errorStatus="404" />
    }

    if (!optionToQuote) {
        return <Loading />;
    }

    return (
        <Box mb={2}>
            <StyledContainer.BaseContainer disableGutters>
                <Header readOnly />
                <QuoteReview readOnly />
                <Dates readOnly />
                {enableActions && <Contingencies />}
                {enableActions && <BindSection getOptionToQuote={getOptionToQuote}/>}
                <Underwriter />
                <AdditionalInfo />
            </StyledContainer.BaseContainer>
        </Box>
    );
};

export default PolicyDetails;
