import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { theme } from 'components/theme/styles';
import { Typography, ClickAwayListener } from '@material-ui/core';
import Styled from './styled-components';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Markdown from 'markdown-to-jsx';

const useStylesBootstrap = makeStyles(props => ({
    arrow: {
        color: theme.palette.primary.main,
    },
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        marginTop: props => (props.select ? theme.spacing(0) : theme.spacing(0.5)),
        marginLeft: props => (props.select ? theme.spacing(0.5) : theme.spacing(0)),
        fontWeight: theme.typography.fontWeightLight,
    },
}));


const StyledTooltip = props => {
    const classes = useStylesBootstrap(props);
    return <Tooltip disableHoverListener disableTouchListener interactive arrow classes={classes} {...props} />;
};

const InfoTooltip = ({ placement, open, setOpen, text, select, dataTrack }) => {
    return (
        <StyledTooltip
            title={
                <Typography variant="body2">
                    <Styled.StyledMarkDownWithLink variant="body1">
                        {text}
                    </Styled.StyledMarkDownWithLink>
                </Typography>
            }
            placement={placement}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            select={select}>
            <Styled.IconButton
                data-track={dataTrack}
                className={select && 'select'}
                style={{ padding: !select && 0, color: '#919191', cursor: 'pointer' }}
                onClick={() => setOpen(!open)}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <InfoOutlined />
                </ClickAwayListener>
            </Styled.IconButton>
        </StyledTooltip>
    );
};

export default InfoTooltip;
