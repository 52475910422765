import React, { useMemo } from 'react';
import { Box, LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const _QuestionnaireLinearProgress = ({ classes, ...props }) => {
    return (
        <Box bgcolor="white" height="9px" borderRadius="6px" pt="3px" px="3px">
            <LinearProgress
                {...props}
                classes={{
                    colorPrimary: classes.colorPrimary,
                    barColorPrimary: classes.barColorPrimary,
                    bar: classes.bar,
                    root: classes.root,
                }}
            />
        </Box>
    );
};

const QuestionnaireLinearProgress = ({ color, ...props }) => {
    const ProgressBar = useMemo(() => {
        const styles = () => ({
            colorPrimary: {
                backgroundColor: 'white',
            },
            barColorPrimary: {
                backgroundColor: color,
            },
            bar: {
                borderRadius: '3px',
            },
            root: {
                height: '6px',
                borderRadius: '3px',
                backgroundColor: 'white',
            },
        });
        return withStyles(styles)(_QuestionnaireLinearProgress);
    }, []);
    return <ProgressBar {...props} />;
};

export default QuestionnaireLinearProgress;
