import React, { FC } from 'react';
import Styled from './styled-components';
import _ from 'lodash';

const instructions: InstructionTextProps[] = [
    {
        firstPart: '01',
        secondPart: 'Invite. ',
        thirdPart: (
            <Styled.Typography variant="subtitle2">
                Add your retail partner and optionally set your broker fee to send an email invitation.
            </Styled.Typography>
        ),
    },
    {
        firstPart: '02',
        secondPart: 'Submit Applications. ',
        thirdPart: (
            <Styled.Typography variant="subtitle2">
                Retail partners can access your branded application and submit applications using the emailed link.
            </Styled.Typography>
        ),
    },
    {
        firstPart: '03',
        secondPart: 'Instant Quotes. ',
        thirdPart: (
            <Styled.Typography variant="subtitle2">
                Within minutes of submitting, you and your retail partner will receive an email with the quotes or
                status.
            </Styled.Typography>
        ),
    },
    {
        firstPart: '04',
        secondPart: 'Track. ',
        thirdPart: (
            <Styled.Typography>
                Keep track of submissions from your partners on the{' '}
                <Styled.BlueLink href="https://keeprisk.at-bay.com/">At-Bay Broker Platform </Styled.BlueLink>
            </Styled.Typography>
        ),
    },
    {
        firstPart: '05',
        secondPart: 'Bind. ',
        thirdPart: (
            <Styled.Typography>
                When ready, customize and{' '}
                <Styled.BlueLink href="https://www.at-bay.com/articles/how-to-bind-a-policy/">bind</Styled.BlueLink>{' '}
                quotes online.
            </Styled.Typography>
        ),
    },
    {
        firstPart: 'Tip: ',
        secondPart: '',
        thirdPart: (
            <Styled.Typography variant="subtitle2">
                To preview the retailer experience, add yourself as a retail partner.
            </Styled.Typography>
        ),
    },
];

interface InstructionTextProps {
    firstPart: string;
    secondPart: string;
    thirdPart: React.ReactNode;
}

const InstructionText: FC<InstructionTextProps> = ({ firstPart, secondPart, thirdPart }) => {
    return (
        <Styled.Row>
            {!_.isEmpty(firstPart) && <Styled.FadedTypography variant="subtitle2">{firstPart}</Styled.FadedTypography>}
            {!_.isEmpty(secondPart) && <Styled.TypographyBold variant="subtitle2">{secondPart}</Styled.TypographyBold>}
            {!_.isEmpty(thirdPart) && thirdPart}
        </Styled.Row>
    );
};

const NoResults: FC = () => {
    return (
        <>
            <iframe
                style={{ border: 0 }}
                width="100%"
                height="540"
                src="https://www.youtube.com/embed/QYW0Y8e_bxA"
                allowFullScreen={true}></iframe>

            <Styled.Box>
                {instructions.map((item: InstructionTextProps, index) => {
                    return (
                        <>
                            {index === instructions.length - 1 && <Styled.Divider />}
                            <InstructionText {...item} />
                        </>
                    );
                })}
            </Styled.Box>
        </>
    );
};

export default React.memo(NoResults);
