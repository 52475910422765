import {createSlice} from '@reduxjs/toolkit'
import {QuestionsState} from './types';

const initialState: QuestionsState = {
    questionsToAnswers: undefined,
};

const questionsSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {
        setQuestionsToAnswers(state, action) {
            state.questionsToAnswers = action.payload;
        },
        setQuestionValue(state, action) {
            const {name, value} = action.payload;
            if (state.questionsToAnswers)
                state.questionsToAnswers[name] = value;
        },
    },
});

export const {
    setQuestionsToAnswers,
    setQuestionValue,
} = questionsSlice.actions;

export default questionsSlice.reducer;
