import React from 'react';
import { StyledTypography } from 'components';


const Actions = () => {

    return (
        <StyledTypography.FadedTypography variant="subtitle2">
            Please submit an updated application for this renewal.
        </StyledTypography.FadedTypography>
    )
}


export default Actions;
