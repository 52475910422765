import styled from 'styled-components';
import { Skeleton as _Skeleton } from '@material-ui/lab';
import { Link as _Link } from 'react-router-dom';
import {
    Container as _Container,
    Select as _Select,
    MenuItem as _MenuItem,
    Box as _Box,
    Button as _Button,
    CircularProgress as _CircularProgress,
    DialogContent as _DialogContent,
} from '@material-ui/core';
import _TableCell from '@material-ui/core/TableCell';

const Container = styled(_Container)`
    & {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        background-color: white;
        padding: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(5)}px
                                   ${theme.spacing(2)}px ${theme.spacing(5)}px`};
        min-width: 240px;
    }
`;

const LongContainer = styled(Container)`
    & {
        margin: 0;
        margin-right: ${({ theme }) => `${theme.spacing(2)}px`};
    }
`;

const Select = styled(_Select)`
    &&& {
        border: 1px solid #e6e6e6;
        padding: ${({ theme }) => `${theme.spacing(0.375)}px ${theme.spacing(1.25)}px`};

        &.MuiInput-underline:before {
            border: none;
        }

        &.MuiListItem-underline:after {
            border: none;
        }

        &.MuiInput-underline:after {
            border: none;
        }

        &.Mui-focused {
            background-color: none;
        }

        .MuiSelect-selectMenu:focus {
            background-color: white;
        }

        .MuiSvgIcon-root {
            width: 0;
            height: 0;
            margin: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(0.625)}px 0 0`};
            border-style: solid;
            border-width: 7px 5px 0 5px;
            border-color: rgba(0, 0, 0, 0.54) transparent transparent transparent;
        }

        .MuiSelect-select {
            font-size: 14px;
            font-weight: ${({ theme }) => theme.typography.fontWeightLight};
            &:focus {
                background-color: transparent;
            }
        }

        &.MuiMenu-paper {
            min-width: 400px;
        }
    }
`;

const MenuItem = styled(_MenuItem)`
    &&& {
        font-weight: 300;
        line-height: 22px;
        background-color: transparent;
        white-space: break-spaces;
        color: #919191;

        &:before {
            left: 0;
            transition: 0.2s;
            content: '';
            display: block;
            position: absolute;
            top: 14px;
            width: ${props => (props.selected ? '12px' : '0px')};
            height: 3px;
            background: black;
        }

        &:hover&:before {
            width: 12px;
        }

        &.MuiListItem-root.Mui-selected {
            color: #191919;
        }
    }
`;

const SelectTitle = styled(_Box)`
    &&& {
        color: #919191;
        border-bottom: 1px solid #f2f2f2;
        letter-spacing: 1.8px;
        pointer-events: none;
        text-transform: uppercase;
    }
`;

const Button = styled(_Button)`
    &&& {
        background-color: ${({ theme }) => theme.palette.primary.main};
        color: white;
        border-radius: 40px;
        width: 240px;
        height: 30px;
        text-transform: capitalize;
        font-weight: 700;
    }
    &&&.Mui-disabled {
        background-color: #e6e6e6;
        color: #afafaf;
    }
`;

const ReturnButton = styled(Button)`
    &&& {
        width: 270px;
    }
`;

const CloseButton = styled(Button)`
    &&& {
        width: 100px;
    }
`;

const ResetButton = styled(_Button)`
    &&& {
        color: ${({ theme }) => theme.palette.primary.main};
        position: absolute;
        left: 10%;
        top: 25%;
        display: flex;
        text-transform: capitalize;
        font-weight: 700;
    }

    &&&.Mui-disabled {
        color: #afafaf;
    }

    ${({ theme }) => `
        ${theme.breakpoints.down('md')} {
            &&& {
                left: 0;
                top: 0;
            }
        }
`}
`;

const Skeleton = styled(_Skeleton)`
    &&& {
        &.MuiSkeleton-text {
            transform: scale(1, 1);
        }
    }
`;

const TableCell = styled(_TableCell)`
    border: None;
    padding: ${({ theme }) => `${theme.spacing(0.5)}px 0`};
    width: 100%;
`;

const PointerBox = styled(_Box)`
    cursor: pointer;
`;

const Link = styled(_Link)`
    &&& {
        color: inherit;
        text-decoration-line: none;
        &:hover {
            text-decoration: none;
        }
        &:visited {
            text-decoration: none;
        }
    }
`;

const CircularProgress = styled(_CircularProgress)`
    &&& {
        color: #5489ff;
    }
`;

const CircularProgressV2 = styled(_CircularProgress)`
    &&& {
        color: black;
    }
`;

const DialogContent = styled(_DialogContent)`
    &&& {
        &.expandable {
            overflow: hidden;
            transition: max-height 0.3s ease-in-out;
            height: auto;
            max-height: 600px;
            &.collapsed {
                max-height: 100px;
            }
        }
        ${({ theme }) => `
            padding-left: ${theme.spacing(4)}px;
            padding-right: ${theme.spacing(4)}px;
            padding-top: ${theme.spacing(3)}px;
            padding-bottom: ${theme.spacing(2)}px;
            ${theme.breakpoints.down('sm')} {
                padding-left: ${theme.spacing(3)}px;
                padding-right: ${theme.spacing(3)}px;
                padding-top: ${theme.spacing(2)}px;
                padding-bottom: ${theme.spacing(2)}px;
            }
        `}
    }
`;

const BottomDottedLineBox = styled(_Box)`
    height: 40px;
    width: 90%;
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 auto;
    border-bottom-style: dotted;
    border-bottom-color: #cccccc;
    border-radius: 1%;
`;

export default {
    Container,
    Select,
    MenuItem,
    SelectTitle,
    Button,
    Skeleton,
    TableCell,
    PointerBox,
    LongContainer,
    ResetButton,
    ReturnButton,
    Link,
    CircularProgress,
    CloseButton,
    DialogContent,
    CircularProgressV2,
    BottomDottedLineBox,
};
