import React from 'react';
import StyledDialog from 'components/popup/styled-components';
import Styled from './styled-components';
import { Box, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';


const ErrorPopup = ({ open, handleClose }) => {
    return (
        <StyledDialog.Dialog open={open}>
            <StyledDialog.DialogTitle>
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    fontWeight="fontWeightBold"
                    alignItems="flex-start"
                >
                    <Box width="30em">Oops!</Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </StyledDialog.DialogTitle>
            <Styled.DialogContent>
                <Box mt={2.5} mb={6}>
                    An unexpected error has occurred, please try again later.
                </Box>
                <Box textAlign="center" mb={1.25}>
                    <Styled.CloseButton onClick={handleClose}>
                        close
                    </Styled.CloseButton>
                </Box>
            </Styled.DialogContent>
        </StyledDialog.Dialog>
    );
};

export default ErrorPopup;
