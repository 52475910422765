import React, { useMemo } from 'react';
import Styled from 'modules/submission/pages/quote-customization/components/styled-components';
import { useBreakpoint } from 'hooks';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { totalHumanize } from 'utils';
import { filterAllowedValues } from 'utils/customization';

const SelectRetention = ({ handleChangeAggregate, classes }) => {
    const { customizedOptionToQuote, optionToQuote } = useSelector(
        state => state.company
    );
    const isSm = useBreakpoint('down', 'sm');

    const allowedAggregateRetentions = useMemo(() => {
        let allowedAggregateRetentions = filterAllowedValues([
            ...customizedOptionToQuote.quote.coverages.allowed_retentions
        ]);
        const parameterIndexForRetention = allowedAggregateRetentions.findIndex(
            (allowed_retention, ind) =>
                allowed_retention.value ===
                    optionToQuote.quote.aggregate_retention && ind !== 0
        );

        if (parameterIndexForRetention > 0) {
            const requiredReviewParameters = allowedAggregateRetentions.splice(
                0,
                parameterIndexForRetention
            );
            allowedAggregateRetentions = [
                ...allowedAggregateRetentions,
                { id: 'fakeId', value: 'RETENTION (REQUIRES REVIEW)' },
                ...requiredReviewParameters,
            ];
        }
        return allowedAggregateRetentions;
    }, [optionToQuote.quote.aggregate_retention]);

    return (
        <Styled.Select
            value={customizedOptionToQuote.quote.aggregate_retention}
            onChange={handleChangeAggregate}
            name="aggregate_retention"
            className={isSm ? classes.MobileSelect : classes.Select}
        >
            <Box component={Styled.SelectTitle} p={1}>
                <Typography variant="overline">RETENTION</Typography>
            </Box>
            {allowedAggregateRetentions.map(retention => {
                if (retention.id === 'fakeId') {
                    return (
                        <Box
                            component={Styled.SelectTitle}
                            borderTop="1px solid #F2F2F2"
                            value={retention.value}
                            key={`aggregate-retention-${retention.id}`}
                            p={1}
                        >
                            <Typography variant="overline">
                                {retention.value}
                            </Typography>
                        </Box>
                    );
                }
                return (
                    <Box
                        component={Styled.MenuItem}
                        value={retention.value}
                        key={`aggregate-retentions-${retention.id}`}
                    >
                        <Typography variant="body1">
                            ${totalHumanize(retention.value)}
                        </Typography>
                    </Box>
                );
            })}
        </Styled.Select>
    );
};

export default SelectRetention;
