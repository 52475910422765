import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Styled from '../../styled-components';
import { ReactComponent as AtbayCustomizationIcon } from 'components/icons/atbay-customization.svg'


const LoadingPremium = () => {

    return (
        <>
            <AtbayCustomizationIcon />
            <Box fontWeight={700} fontSize="20px" my="14px">
                <Typography variant="h6">
                    Calculating...
                </Typography>
            </Box>
            <Styled.CircularProgress color="primary" />
        </>
    )
}


export default LoadingPremium;