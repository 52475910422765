import React, { FC } from 'react';
import { StyledBox } from 'components/index';
import { Box, Typography } from '@material-ui/core';

interface Props {
    title: string;
    value: number;
}

const PolicyTableRow: FC<Props> = ({ title, value }) => {
    return (
        <StyledBox.MobilePolicyAndCommissionBox>
            <Box flexGrow={1}>
                <Typography variant="subtitle2">{title}</Typography>
            </Box>
            <Box id="right">
                <Typography variant="subtitle2"> $ {value}</Typography>
            </Box>
        </StyledBox.MobilePolicyAndCommissionBox>
    );
};

export default PolicyTableRow;
