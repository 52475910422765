import React from 'react';
import Icon from '../';


const DynamicIcon = ({ name, fallback='building', ...iconProps }) => {
    let SvgIcon = null;
    try {
        SvgIcon = require(`components/icons/dynamic/${name}`).default;
    } catch {
        SvgIcon = require(`components/icons/dynamic/${fallback}`).default;
    }
    return <Icon {...iconProps} icon={<SvgIcon />} />;
}


export default DynamicIcon;
