import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LineItemType, LineItemCode, AggregateType } from 'utils/enums';
import { filterAllowedValues } from 'utils/customization';

const useReconstructOtherParameterList = (
    allowedValues,
    lineItemCode,
    lineItemType,
    aggregateType = null,
) => {
    const { optionToQuote } = useSelector(state => state.company);
    return useMemo(() => {
        if (!allowedValues) {
            return [];
        }
        const originalLineItem = optionToQuote.quote.coverages.line_items.find(
            lineItem => lineItem.code === lineItemCode
        );
        if (aggregateType !== AggregateType.LIMIT) {
            allowedValues = filterAllowedValues(allowedValues);
        }
        let index = -1;
        if (aggregateType === AggregateType.LIMIT) {
            allowedValues = filterAllowedValues(allowedValues, originalLineItem.limit);
            index = allowedValues.findIndex(
                allowedValue =>
                    allowedValue.value > originalLineItem[aggregateType]
            );
        } else if (aggregateType === AggregateType.RETENTION) {
            index = allowedValues.findIndex(
                (allowedValue, ind) =>
                    allowedValue.value === originalLineItem[aggregateType] &&
                    ind !== 0
            );
        } else {
            index = allowedValues.findIndex(
                allowedValue =>
                    allowedValue.value ===
                    originalLineItem.other_parameter.value
            );
        }
        // if the allowed value is not the first one, it means we need to split the list into in-bounds and out-of-bounds
        if (index > 0) {
            let reconstructedList = [...allowedValues];
            if (aggregateType === AggregateType.LIMIT) {
                if (lineItemCode === LineItemCode.FF_SEC) {
                    reconstructedList = reconstructedList.slice(0, index);
                } else {
                    reconstructedList.splice(index, 0, {
                        id: 'fakeId',
                        value: `${aggregateType.toUpperCase()} (REQUIRES REVIEW)`
                    });
                }
                return reconstructedList;
            } else if (aggregateType === AggregateType.RETENTION) {
                const requiredReviewValues = allowedValues.splice(0, index);
                return [
                    ...allowedValues,
                    { id: 'fakeId', value: 'RETENTION (REQUIRES REVIEW)' },
                    ...requiredReviewValues
                ];
            } else if (lineItemType === LineItemType.WAITING_PERIOD) {
                const requiredReviewValues = allowedValues.splice(0, index);
                return [
                    ...allowedValues,
                    { id: 'fakeId', value: 'WAITING PERIOD (REQUIRES REVIEW)' },
                    ...requiredReviewValues
                ];
            } else if (lineItemType === LineItemType.PERIOD_OF_INDEMNITY) {
                const reconstructedList = [...allowedValues];
                if (index + 1 !== reconstructedList.length)
                    reconstructedList.splice(index + 1, 0, {
                        id: 'fakeId',
                        value: 'WAITING PERIOD (REQUIRES REVIEW)'
                    });
                return reconstructedList;
            }
        }
        return allowedValues;
    }, [
        optionToQuote,
        allowedValues,
        lineItemCode,
        lineItemType,
        aggregateType
    ]);
};

const useDataTrackBase = () => {
    const { optionToQuote } = useSelector(state => state.company);

    return useMemo(() => {
        return `${optionToQuote?.quote.policy_request.product.abbreviated_name}_customization`;
    }, [optionToQuote]);
};

export { useReconstructOtherParameterList, useDataTrackBase };
