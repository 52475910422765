import styled from 'styled-components';
import { default as _Container } from '@material-ui/core/Container';
import { default as _Box } from '@material-ui/core/Box';
import { default as _FormControlLabel } from '@material-ui/core/FormControlLabel';
import { default as _FormLabel } from '@material-ui/core/FormLabel';
import { default as _Toolbar } from '@material-ui/core/Toolbar';
import { default as _TextField } from '@material-ui/core/TextField';
import { default as _AppBar } from '@material-ui/core/AppBar';
import { default as _MarkDown } from 'markdown-to-jsx';

const StyledMarkDown = styled(_MarkDown)`
    &&& {
        margin: 0;
        ul {
            position: relative;
            margin-left: 0;
            padding-left: ${({ theme }) => theme.spacing(2.25)}px;
        }
        li {
            &:before {
                position: absolute;
                left: 0;
            }
        }
    }
`;
const StyledMarkDownWithLink = styled(_MarkDown)`
    &&& {
        a {
            color: white;
        }
    }
`;

const GrayStyledMarkDown = styled(_MarkDown)`
    &&& {
        margin: 0;
        ul {
            position: relative;
            margin-left: 0;
            padding-left: ${({ theme }) => theme.spacing(2.25)}px;
        }
        li {
            &:before {
                position: absolute;
                left: 0;
            }
        }
        color: #b8b8b8
    }
`;

const DownloadBox = styled(_Box)`
    &&& {
        display: flex;
        color: #919191;
        ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightMedium};
            ${theme.breakpoints.down('md')} {
                flex-direction: column;
                justify-content: center;
            }
        `}
        &:hover {
            color: ${({ theme }) => theme.palette.primary.main};
            cursor: pointer;
        }
    }
`;

const QuestionnaireContainer = styled(_Container)`
    &&& {
        ${({ theme }) => `
            margin-top: ${theme.spacing(15)}px;
            font-weight: ${theme.typography.fontWeightLight};
            ${theme.breakpoints.up('lg')} {
                padding: ${theme.spacing(0)}px ${theme.spacing(20)}px;
            }
            ${theme.breakpoints.down('md')} {
                margin-top: ${theme.spacing(10)}px;
                padding: 0px;
            }
    `}
    }
`;

const FormControlLabel = styled(_FormControlLabel)`
    &&& {
        text-align: start;
        align-items: flex-start;
        margin-left: 0px;
        margin-right: 0px;
        display: flex;
        ${({ theme }) => `
            ${theme.breakpoints.up('lg')} {
                margin-bottom: ${theme.spacing(1)}px;
            }
            ${theme.breakpoints.down('md')} {
                margin-bottom: ${theme.spacing(2.5)}px;
            }
    `}
        > input {
            margin-right: ${({ theme }) => theme.spacing(1.5)}px;
            margin-left: 11px;
        }
        &:hover {
            cursor: default;
            > span {
                cursor: pointer;
            }
        }
    }
`;

const FormLabel = styled(_FormLabel)`
    &&& {
        text-align: start;
        color: #191919;
        line-height: normal;
        ${({ theme }) => `
            margin-top: ${theme.spacing(2.5)}px;
            ${theme.breakpoints.up('md')} {
                padding-bottom: ${theme.spacing(1)}px;
            }
            ${theme.breakpoints.down('sm')} {
                padding-bottom: ${theme.spacing(2)}px;
                margin-top: ${theme.spacing(1)}px;
            }
        `}
        &.nospace {
            margin-top: 0;
        }
        &.Mui-error {
            color: #f44336;
        }
        > * ul {
            padding-left: ${({theme}) => theme.spacing(5)}px;
            margin-top: ${({theme}) => theme.spacing(0.5)}px;
    }
`;

const Toolbar = styled(_Toolbar)`
    &&& {
        ${({ theme }) => `
            ${theme.breakpoints.up('lg')} {
                max-width: 960px;
                width: inherit;
                align-self: center;
            }
            ${theme.breakpoints.down('md')} {
                padding-left: ${theme.spacing(4)}px;
                padding-right: ${theme.spacing(4)}px;
            }
        `}
        &.header {
            ${({ theme }) => `
                padding-left: ${theme.spacing(0)}px;
                padding-right: ${theme.spacing(0)}px;
            `}
        }
    }
`;

const IconButton = styled.span`
    &&& {
        padding: 0px 0px 2px 8px;
        &.select {
            &.MuiIconButton-root {
                color: #919191;
            }
        }
        &:hover {
            color: ${({ theme }) => theme.palette.primary.main} !important;
            background-color: transparent;
        }
    }
`;

const TextField = styled(_TextField)`
    &&& {
        .MuiInput-underline:before {
            border-color: #e6e6e6;
            border-width: 1px;
        }
        .MuiInput-underline:after {
            border-width: 1px;
        }
        &.number {
            .MuiInputAdornment-positionStart {
                margin-right: ${({ theme }) => theme.spacing(0.75)}px;
            }
            .MuiInputAdornment-positionEnd {
                margin-right: ${({theme}) => theme.spacing(0.375)}px;
            }
            ${({ theme }) => `
                ${theme.breakpoints.up('md')} {
                    .MuiInput-formControl {
                        &.MuiInputBase-adornedStart {
                            padding-bottom: 0px;
                            padding-top: 0px;
                        }
                    }
                }
            `}
            &&& .MuiFormHelperText-root.Mui-focused {
                color: ${({ theme }) => theme.palette.primary.main};
            }
        }
        > label {
            text-align: start;
            width: 100%;
            color: #919191;
            &.MuiInputLabel-shrink {
                color: #afafaf;
                &.Mui-focused {
                    color: ${({ theme }) => theme.palette.primary.main};
                    &.Mui-error {
                        color: #f44336;
                    }
                }
                transform: translate(0, 1.5px);
                font-size: 10px;
            }
        }
    }
`;

const AppBar = styled(_AppBar)`
    &&& {
        top: 80px;
        bottom: auto;
        background-color: #f2f2f2;
        box-shadow: none;
        ${({ theme }) => `
            height: 56px;
            ${theme.breakpoints.down('sm')} {
                height: 64px;
            }
        `}
    }
`;

const SectionBox = styled(_Box)`
    &&& {
        position: relative;
        &.disabled {
            text-align: initial;
        }
        &.disabled::before {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: #fff;
            opacity: 0.5;
            z-index: 1000;
            content: '';
        }
    }
`;

export default {
    DownloadBox,
    QuestionnaireContainer,
    FormControlLabel,
    FormLabel,
    Toolbar,
    TextField,
    IconButton,
    AppBar,
    SectionBox,
    StyledMarkDown,
    GrayStyledMarkDown,
    StyledMarkDownWithLink
};
