import styled from 'styled-components';

const MainPage = styled.div`
    &&& {
        height: ${({ extend }) => extend ? '100%' : 'unset'};
        color: ${({ theme }) => theme.palette.text.primary};
    }
`;

export default {
    MainPage,
};
