import React from 'react';
import Styled from 'modules/submission/pages/quote-customization/components/styled-components';
import { useBreakpoint, useReconstructOtherParameterList } from 'hooks';
import { Box, Typography } from '@material-ui/core';
import { AggregateType, LineItemCode } from 'utils/enums';
import { totalHumanize } from 'utils';
import { useStyles } from 'utils/customization';

const SelectRetention = ({ lineItem, handleChangeLineItem }) => {
    const classes = useStyles();
    const allowedRetentions = useReconstructOtherParameterList(
        lineItem.allowed_retentions,
        lineItem.code,
        lineItem.type,
        AggregateType.RETENTION,
    );
    const isSm = useBreakpoint('down', 'sm');

    return (
        <Box mr={1}>
            {lineItem.limit ? (
                lineItem.code === LineItemCode.SF_SEC ? (
                    <Box
                        width={isSm ? '100px' : '120px'}
                        height="37px"
                        bgcolor="#F8F8F8"
                    />
                ) : (
                    <Styled.Select
                        value={lineItem.retention}
                        onChange={event =>
                            handleChangeLineItem(
                                event,
                                AggregateType.RETENTION,
                                lineItem.code,
                            )
                        }
                        className={isSm ? classes.SelectMobile : classes.Select}
                    >
                        <Box component={Styled.SelectTitle} py={1} px={1.5}>
                            <Typography variant="overline">
                                RETENTION
                            </Typography>
                        </Box>
                        {allowedRetentions.map(retention => {
                            if (retention.id === 'fakeId') {
                                return (
                                    <Box
                                        component={Styled.SelectTitle}
                                        borderTop="1px solid #F2F2F2"
                                        value={retention.value}
                                        key={`${lineItem.coverage_name}-aggregate-retention-${retention.id}`}
                                        p={1}
                                    >
                                        <Typography variant="overline">
                                            {retention.value}
                                        </Typography>
                                    </Box>
                                );
                            }
                            return (
                                <Box
                                    component={Styled.MenuItem}
                                    key={`${lineItem.coverage_name}-aggregate-retention-${retention.id}`}
                                    value={retention.value}
                                >
                                    <Typography variant="body1">
                                        ${totalHumanize(retention.value)}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Styled.Select>
                )
            ) : (
                <Typography
                    component={Box}
                    className={classes.DisabledSelect}
                    variant="body1"
                    height="21px"
                    width={isSm ? 82 : 102}
                    border="1px solid #E6E6E6"
                    p={1}
                >
                    $0
                </Typography>
            )}
        </Box>
    );
};

export default SelectRetention;
