import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useAxiosInstance, useBreakpoint, useTextQuestionStyles } from 'hooks';
import Markdown from 'markdown-to-jsx';
import GeneralStyled from '../components/styled-components';
import { Box, FormControl, InputAdornment, Typography } from '@material-ui/core';
import InfoTooltip from '../components/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setQuestionValue } from 'stores/questions/questionsSlice';
import { setQuestionData } from 'stores/questionsErrors/questionsErrorsSlice';
import { conditionedNodeNeedToBeVisible } from 'utils/questionsConditions';
import { questionHasError, scrollToQuestionError } from 'utils/questionnaireSequence';
import {
    addAnswer,
    addVisibleQuestion,
    removeAnswer,
    removeVisibleQuestion,
    setQuestionnaireStateToAnswerChanging,
    setQuestionnaireStateToChangesSaved,
} from 'stores/questionnaire/questionnaireSlice';
import CustomCheckbox from 'components/checkbox/CustomCheckbox';

const HAS_WEBSITE_DATA_POINT_ID = 'Q2134';
const SUPPORTED_NO_DOMAIN_PRODUCT = 'Cyber';

const getStringByBoolean = (value) => {
    return value ? 'True' : 'False';
};

const getBooleanByString = (value) => {
    return value !== 'False';
};

const DomainQuestion = ({ question, updateForm, policy_request_id, setScroll, scroll, setEditAnswerMode, setChangeMade }) => {
    const axiosInstance = useAxiosInstance();
    const questionRef = useRef();
    const inputFieldRef = useRef();
    const placeholderLabelRef = useRef();
    const isSm = useBreakpoint('down', 'sm');
    const isLg = useBreakpoint('up', 'lg');
    const dispatch = useDispatch();
    const { questionsToAnswers } = useSelector(state => state.questions);
    const { sectionsData } = useSelector(state => state.questionnaire);
    const answer = useSelector(state => state.questions.questionsToAnswers[question.datapoint.datapoint_id]);
    const { firstQuestionWithError, submitFailed } = useSelector(state => state.questionErrors);
    const [visible, setVisible] = useState(
        !question.datapoint.display_condition ||
            conditionedNodeNeedToBeVisible(question.datapoint.display_condition, sectionsData, questionsToAnswers)
    );

    const product = useSelector(state => state.questionnaire.product);
    const policyRequest = useSelector(state => state.questionnaire.policyRequest);
    const [value, setValue] = useState(answer || '');
    const [shrink, setShrink] = useState(Boolean(value));
    const placeholder = useMemo(() => {
        return value || shrink ? '' : question.datapoint.text_to_display;
    }, [value, shrink, question.datapoint.text_to_display]);
    const [firstChange, setFirstChange] = useState(true);
    const [open, setOpen] = useState(false);

    const hasDomainAnswer = useSelector(state => state.questions.questionsToAnswers[HAS_WEBSITE_DATA_POINT_ID]);
    const [hasDomainValue, setHasDomainValue] = useState(hasDomainAnswer || '');
    const [isDomainRequiredError, setIsDomainRequiredError] = useState(false);
    const [requiredError, setRequiredError] = useState(questionHasError(submitFailed, answer, question.datapoint.required, visible));
    const [inputStyles, inputComponentStyles] = useTextQuestionStyles(placeholder, placeholderLabelRef, inputFieldRef);
    const [isNoDomainChecked, setIsNoDomainChecked] = useState(!getBooleanByString(hasDomainValue));
    const [error, setError] = useState('');
    const isNoDomainSupported = (product?.name === SUPPORTED_NO_DOMAIN_PRODUCT && product?.is_support_no_domain && policyRequest?.is_questionnaire_support_no_domain) || false;
    
    const validateDomains = async (data) => {
        // we need to use axios instance because if we call the function returned by axiosClient
        // more than once at the same time the first request will be canceled
        await axiosInstance.post(`/questionnaire/validate_domains/${policy_request_id}`, data);
    }

    useEffect(() => {
        // Incase the domain question was answered in the create new submission form,
        // we need to answer the has no domain question
        if (isNoDomainSupported && !hasDomainAnswer && answer) {
            dispatch(setQuestionValue({ name: HAS_WEBSITE_DATA_POINT_ID, value: 'True' }));
            updateForm(HAS_WEBSITE_DATA_POINT_ID, 'True', false, true);
        }
    }, []);

    useEffect(() => {
        dispatch(
            setQuestionData({
                datapointID: question.datapoint.datapoint_id,
                hasError: questionHasError(submitFailed, answer, question.datapoint.required, visible) || (isDomainRequiredError && submitFailed)  || error,
            }),
        );

        if (isNoDomainSupported) {
            dispatch(
                setQuestionData({
                    datapointID: HAS_WEBSITE_DATA_POINT_ID,
                    hasError: questionHasError(submitFailed, hasDomainAnswer, true, visible) || (isDomainRequiredError && submitFailed)  || error,
                }),
            );
        }
    }, [submitFailed, error, isDomainRequiredError]);

    useEffect(() => {
        if (question.datapoint.display_condition) {
            setVisible(
                conditionedNodeNeedToBeVisible(question.datapoint.display_condition, sectionsData, questionsToAnswers)
            );
        }
    }, [questionsToAnswers]);

    useEffect(() => {
        setIsDomainRequiredError(isNoDomainSupported && (hasDomainValue === 'True' || !hasDomainValue) && !value);
    }, [hasDomainValue, value]);

    useEffect(() => {
        dispatch(
            setQuestionData({
                datapointID: question.datapoint.datapoint_id,
                hasError: questionHasError(submitFailed, answer, question.datapoint.required, visible) || (isDomainRequiredError && submitFailed)  || error,
            }),
        );
        if (visible) {
            if (question.datapoint.required || value) {
                dispatch(
                    addVisibleQuestion({
                        datapointId: question.datapoint.datapoint_id,
                        omitFromCount: question.datapoint.omit_from_count
                    })
                );
            }
            if (value) {
                dispatch(
                    addAnswer({
                        datapointId: question.datapoint.datapoint_id,
                        omitFromCount: question.datapoint.omit_from_count
                    })
                );
            }
        } else {
            dispatch(removeVisibleQuestion(question.datapoint.datapoint_id));
            dispatch(removeAnswer(question.datapoint.datapoint_id));
        }

        if (isNoDomainSupported) {
            dispatch(
                setQuestionData({
                    datapointID: HAS_WEBSITE_DATA_POINT_ID,
                    hasError: questionHasError(submitFailed, hasDomainAnswer, true, visible) || (isDomainRequiredError && submitFailed)  || error,
                }),
            );

            dispatch(addVisibleQuestion(HAS_WEBSITE_DATA_POINT_ID));
            if (hasDomainValue || value) {
                dispatch(addAnswer(HAS_WEBSITE_DATA_POINT_ID));
            }
        }
    }, [visible]);

    useEffect(() => {
        if (scroll &&
            (firstQuestionWithError === question.datapoint.datapoint_id ||
            (isNoDomainSupported && scroll && firstQuestionWithError === HAS_WEBSITE_DATA_POINT_ID))) {
            scrollToQuestionError(questionRef);
            inputFieldRef.current.focus();
            setScroll(false);
        }

        if (!scroll) {
            setRequiredError(questionHasError(submitFailed, value, question.datapoint.required, visible));
        }
    }, [firstQuestionWithError, scroll]);


    const handleCheckClick = async e  => {
        e.stopPropagation();
        const hasDomain = getStringByBoolean(isNoDomainChecked);
        setIsNoDomainChecked(!isNoDomainChecked);
        setHasDomainValue(hasDomain);
        const hasError = (hasDomain === 'True' || hasDomain === '') && !value
        setHasDomainQuestion(hasDomain, hasError);
    }

    const setHasDomainQuestion = (domainValue, hasError) => {
        domainValue = hasError ? '' : domainValue;
        setEditAnswerMode(true);
        dispatch(setQuestionData({ datapointID: HAS_WEBSITE_DATA_POINT_ID , hasError }));
        dispatch(setQuestionnaireStateToChangesSaved());
        setChangeMade(true);
        dispatch(setQuestionValue({ name: HAS_WEBSITE_DATA_POINT_ID, value: domainValue }));
        updateForm(HAS_WEBSITE_DATA_POINT_ID, domainValue, hasError, true);
    };

    const handleOnBlur = async e => {
        const hasError = submitFailed && !value && question.datapoint.required && visible;
        setEditAnswerMode(true);
        setRequiredError(hasError);
        dispatch(
            setQuestionData({
                datapointID: question.datapoint.datapoint_id,
                hasError: hasError || (isDomainRequiredError && submitFailed) || error
            }),
        );
        dispatch(setQuestionnaireStateToChangesSaved());
        setFirstChange(true);
        dispatch(setQuestionValue({ name: question.datapoint.datapoint_id, value: value || null }));
        setShrink(Boolean(value));
        try {
            await validateDomains({ domains: value });
            setError('')
            updateForm(question.datapoint.datapoint_id, value, false, question.datapoint.required, question.datapoint.omit_from_count);

            if (isNoDomainSupported && value) {
                setHasDomainQuestion('True', false);
            }

        } catch (error) {
            handleValidateDomainError(error);
        }
    };

    const handleValidateDomainError = (error) => {
        updateForm(question.datapoint.datapoint_id, value, true, question.datapoint.required);
        const invalidDomains = error.response?.data?.invalid_domains;
        const existingDomains = error.response?.data?.existing_domains;
        let errorText = '';

        if (invalidDomains?.length === 1) {
            errorText += `The domain ${invalidDomains[0]} is invalid\n`;
        } else if (invalidDomains?.length > 1) {
            errorText += `The domains: ${invalidDomains.join(', ')} are invalid\n`;
        }

        if (existingDomains?.length === 1) {
            errorText += `The domain ${existingDomains[0]} already exists in your Accounts`;
        } else if (existingDomains?.length > 1) {
            errorText += `The domains: ${existingDomains.join(', ')} already exists in your Accounts`;
        }

        setError(errorText);
        updateForm(question.datapoint.datapoint_id, null, true, question.datapoint.required);

        if (isNoDomainSupported) {
            setHasDomainQuestion('True', true);
        }
    };

    const getErrorText = () => {
        if (error) {
            return error;
        }

        if (isDomainRequiredError && submitFailed) {
            return 'Please complete this field or check the box below';
        }

        if (requiredError) {
            return 'This field is required';
        }

        return '';
    }

    const isError = () => {
        return error !== '' || (isDomainRequiredError && submitFailed) || requiredError;
    }

    return (
        visible && (
            <Box
                ref={questionRef}
                display="flex"
                px={isSm ? 4 : 0}
                key={`${question.datapoint.id}_question`}
                pb={1.625}
                justifyContent="center"
                flexDirection="column"
                alignItems="center">
                <FormControl fullWidth style={{ maxWidth: isSm ? 'unset' : '424px' }}>
                    <GeneralStyled.TextField
                        inputRef={inputFieldRef}
                        fullWidth
                        disabled={isNoDomainSupported && isNoDomainChecked}
                        onChange={e => {
                            setEditAnswerMode(true);
                            setValue(e.target.value);
                            setRequiredError(false);
                            dispatch(
                                setQuestionData({ datapointID: question.datapoint.datapoint_id, hasError: false || error }),
                            );
                            setChangeMade(true);
                            if (firstChange) {
                                setFirstChange(false);
                                dispatch(setQuestionnaireStateToAnswerChanging());
                            }

                            if (isNoDomainSupported && e.target.value.length === 0) {
                                setHasDomainQuestion('True', true);
                            }
                        }}
                        InputLabelProps={{
                            shrink,
                            ref: placeholderLabelRef,
                        }}
                        inputProps={{
                            'data-track': `questionnaire_question_${question.datapoint.datapoint_id}`,
                            style: placeholder ? inputStyles.withPlaceholder : inputStyles.withoutPlaceholder,
                        }}
                        value={value}
                        type="text"
                        name={question.datapoint.datapoint_id}
                        label={<Markdown variant="body1">{question.datapoint.text_to_display}</Markdown>}
                        InputProps={{
                            style: placeholder ? inputComponentStyles.withPlaceholder : inputComponentStyles.withoutPlaceholder,
                            endAdornment: question.datapoint.application_parameters.help_text && (
                                <InputAdornment position="end">
                                    <InfoTooltip
                                        dataTrack={`questionnaire_tooltip_${question.datapoint.datapoint_id}`}
                                        placement="bottom-end"
                                        open={open}
                                        setOpen={setOpen}
                                        text={question.datapoint.application_parameters.help_text}
                                        select={false}
                                    />
                                </InputAdornment>
                            ),
                        }}
                        placeholder={placeholder}
                        onFocus={() => setShrink(true)}
                        onBlur={handleOnBlur}
                        error={isError()}
                        helperText={getErrorText()}
                    />
                </FormControl>

                { isNoDomainSupported && (
                    <FormControl
                        fullWidth
                        style={{ maxWidth: isSm ? 'unset' : '424px' }}
                        onChange={handleCheckClick}
                        disabled={Boolean(value)}>
                        <GeneralStyled.FormControlLabel
                            onClick={e => e.preventDefault()}
                            style={{ paddingBottom: 1, paddingTop: 6 }}
                            label={
                                <Typography
                                    variant="body1"
                                    style={{ paddingLeft: 12 }}
                                    onClick={e => e.stopPropagation()}>
                                    <Box component="span" color={isNoDomainChecked ? '#191919' : '#696969'}>
                                        { 'Applicant does not have a website' }
                                    </Box>
                                </Typography>
                            }
                            control={
                                <CustomCheckbox
                                    onClick={e => e.stopPropagation()}
                                    name={HAS_WEBSITE_DATA_POINT_ID}
                                    value={hasDomainValue}
                                    checked={isNoDomainChecked}
                                    variant= {isLg ? 'subtitle2' : 'h4'}
                                />
                            }
                            value={hasDomainValue}
                        />
                    </FormControl>)}
            </Box>
        )
    );
};

export default DomainQuestion;
