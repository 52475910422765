import React, { useMemo } from 'react';
import Styled from 'modules/submission/pages/quote-customization/components/styled-components';
import { useBreakpoint } from 'hooks';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { totalHumanize } from 'utils';
import { filterAllowedValues } from 'utils/customization';


const SelectLimit = ({ handleChangeAggregate, classes }) => {
    const { customizedOptionToQuote, optionToQuote } = useSelector(state => state.company);
    const allowedAggregateLimits = useMemo(() => {
        const allowedAggregateLimits = filterAllowedValues(
            [...customizedOptionToQuote.quote.coverages.allowed_limits],
            customizedOptionToQuote.quote.aggregate_limit,
        );
        const parameterIndexForLimit = allowedAggregateLimits.findIndex(allowed_limit =>
            allowed_limit.value === optionToQuote.quote.aggregate_limit);
        if (parameterIndexForLimit > 0)
            allowedAggregateLimits.splice(parameterIndexForLimit + 1, 0, {id: 'fakeId', value: 'LIMIT (REQUIRES REVIEW)'});
        return allowedAggregateLimits;
    }, [optionToQuote.quote.aggregate_limit, customizedOptionToQuote.quote.aggregate_limit]);
    const isSm = useBreakpoint('down', 'sm');

    return (
        <Styled.Select
            value={customizedOptionToQuote.quote.aggregate_limit}
            onChange={handleChangeAggregate}
            name='aggregate_limit'
            className={isSm ? classes.MobileSelect : classes.Select}
        >
            <Box component={Styled.SelectTitle} px={1} py={1}>
                <Typography variant="overline">
                    LIMIT
                </Typography>
            </Box>
            {allowedAggregateLimits.map(limit => {
                if (limit.id === 'fakeId') {
                    return (
                        <Box
                            component={Styled.SelectTitle}
                            borderTop="1px solid #F2F2F2"
                            value={limit.value}
                            key={`aggregate-limit-${limit.id}`}
                            px={1} py={1}
                        >
                            <Typography variant="overline">
                                {limit.value}
                            </Typography>
                        </Box>
                    )
                }
                return (
                    <Box
                        component={Styled.MenuItem}
                        value={limit.value}
                        key={`aggregate-limit-${limit.id}`}
                    >
                        <Typography variant="body1">
                            ${totalHumanize(limit.value)}
                        </Typography>
                    </Box>
                )
            })}
        </Styled.Select>
    )
}

export default SelectLimit;