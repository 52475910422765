import React from 'react';
import { Checkbox, makeStyles } from '@material-ui/core';
import { DynamicIcon } from 'components';
import { theme } from 'components/theme/styles';

const useStyles = makeStyles({
    root: {
        padding: theme.spacing(0),
    },
});

const CustomCheckbox = ({ meta, variant, color = null, ...rest }) => {
    const classes = useStyles();
    return (
        <Checkbox
            {...rest}
            className={classes.root}
            checkedIcon={<DynamicIcon name={color ? `checked-icon-${color}` : 'checked-icon'} typographyProps={{ variant: variant}} />}
            icon={<DynamicIcon name="checkbox" typographyProps={{ variant: variant}}/>}
        />
    );
};

export default CustomCheckbox;
