import { CompanyPolicies } from 'types/CompanyPolicies';
import {
    companyHasQuestionsAndAnswers,
    createDataBindRequest,
    createDataPolicies,
    createDataWithInnerOptions,
    createDataWithoutInnerOptions,
    createManualRenewalData,
} from 'components/table/utils';
import { BucketType, Client, CompaniesBuckets, QuotesSubBuckets, SubBucketType } from './types';
import { brokerInitialsColors } from './consts';
import { RenewalsSubBuckets } from 'utils/enums';

export const createClientsListPreviewObjectByBucket = (
    companies: CompanyPolicies[],
    bucket: BucketType,
    subBucket: SubBucketType,
): Client[] => {
    switch (bucket) {
        case CompaniesBuckets.QUOTES:
            return createQuotesPreviewObjectBySubBucket(companies, subBucket);
        case CompaniesBuckets.POLICIES:
            return createPoliciesPreviewObjectBySubBucket(companies, subBucket);
        case CompaniesBuckets.RENEWALS:
            return createRenewalsPreviewObjectBySubBucket(companies, subBucket);
        default:
            return [];
    }
};

export const getBrokerInitialsColor = (brokerUser: { id: number; initials?: string }, teamMembersIds: number[]) => {
    return brokerInitialsColors[teamMembersIds?.indexOf(brokerUser.id) % brokerInitialsColors.length];
};
const createPoliciesPreviewObjectBySubBucket = (companies: CompanyPolicies[], subBucket: SubBucketType): Client[] => {
    return companies.map(company => createDataPolicies(company.name, company, subBucket));
};

const createRenewalsPreviewObjectBySubBucket = (companies: CompanyPolicies[], subBucket: SubBucketType): Client[] => {
    switch (subBucket) {
        case RenewalsSubBuckets.RAPID:
            return companies.map(company => createDataPolicies(company.name, company, subBucket));
        case RenewalsSubBuckets.MANUAL:
            return companies.map(company => createManualRenewalData(company.name, company, subBucket));
        case RenewalsSubBuckets.NOT_RENEWING:
            return companies.map(company => createDataPolicies(company.name, company, subBucket));
        default:
            return [];
    }
};

const createQuotesPreviewObjectBySubBucket = (companies: CompanyPolicies[], subBucket: SubBucketType): Client[] => {
    switch (subBucket) {
        case QuotesSubBuckets.OPEN:
            return companies.map(company => createDataWithInnerOptions(company.name, company, QuotesSubBuckets.OPEN));
        case QuotesSubBuckets.INCOMPLETE:
            return companies
                .map(company => createDataWithoutInnerOptions(company.name, company, QuotesSubBuckets.INCOMPLETE))
                .filter(company => companyHasQuestionsAndAnswers(company));
        case QuotesSubBuckets.PENDING:
            return companies.map(company =>
                createDataWithoutInnerOptions(company.name, company, QuotesSubBuckets.PENDING),
            );
        case QuotesSubBuckets.BIND_REQUEST:
            return companies.map(company =>
                createDataBindRequest(company.name, company, QuotesSubBuckets.BIND_REQUEST),
            );
        case QuotesSubBuckets.INACTIVE:
            return companies.map(company =>
                createDataWithoutInnerOptions(company.name, company, QuotesSubBuckets.INACTIVE),
            );
        default:
            return [];
    }
};
