import { Box, FormControl } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AutoComplete from './AutoComplete';
import { useDispatch, useSelector } from 'react-redux';
import { conditionedNodeNeedToBeVisible } from 'utils/questionsConditions';
import { useBreakpoint } from 'hooks';
import { addVisibleQuestion, removeAnswer, removeVisibleQuestion } from 'stores/questionnaire/questionnaireSlice';

const AddressQuestion = ({ question, updateForm, submitFailed, setScroll, scroll, setEditAnswerMode, setSubmissionDisabled, setCheckTriedToSubmit, setChangeMade }) => {
    const dispatch = useDispatch();
    const { questionsToAnswers } = useSelector(state => state.questions);
    const { sectionsData } = useSelector(state => state.questionnaire);
    const isSm = useBreakpoint('down', 'sm');
    const [visible, setVisible] = useState(
        !question.datapoint.display_condition ||
            conditionedNodeNeedToBeVisible(question.datapoint.display_condition, sectionsData, questionsToAnswers)
    );

    useEffect(() => {
        if (question.datapoint.display_condition) {
            setVisible(
                conditionedNodeNeedToBeVisible(question.datapoint.display_condition, sectionsData, questionsToAnswers),
            );
        }
    }, [questionsToAnswers]);

    useEffect(() => {
        if (visible) {
            if (question.datapoint.required) {
                dispatch(addVisibleQuestion(question.datapoint.datapoint_id));
            }
        } else {
            dispatch(removeVisibleQuestion(question.datapoint.datapoint_id));
            dispatch(removeAnswer(question.datapoint.datapoint_id));
        }
    }, [visible]);

    return (
        visible && (
            <Box display="flex" px={isSm ? 4 : 0} key={`${question.datapoint.id}_question`} pb={1.625} justifyContent="center">
                <FormControl fullWidth style={{maxWidth: isSm ? 'unset' : '424px' }}>
                    <Box>
                        <AutoComplete
                            id={`label-${question.datapoint.datapoint_id}`}
                            name={question.datapoint.datapoint_id}
                            question={question}
                            updateForm={updateForm}
                            submitFailed={submitFailed}
                            visible={visible}
                            setScroll={setScroll}
                            scroll={scroll}
                            setEditAnswerMode={setEditAnswerMode}
                            setSubmissionDisabled={setSubmissionDisabled}
                            setCheckTriedToSubmit={setCheckTriedToSubmit}
                            setChangeMade={setChangeMade}
                        />
                    </Box>
                </FormControl>
            </Box>
        )
    );
};

export default AddressQuestion;
