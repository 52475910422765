import React from 'react';
import { useSelector } from 'react-redux';
import { useEnv } from 'hooks';
import { InfoItem, StyledLink, StyledCard } from 'components';
import {
    Box,
    Typography,
} from '@material-ui/core';
import { ProductsAbbreviatedName } from 'utils/enums';



const AdditionalInfo = () => {
    const { apiBaseUrl } = useEnv();
    const { abbreviated_name: productAbbreviatedName } = useSelector(state => state.company.optionToQuote.quote.policy_request.product);
    if (productAbbreviatedName === ProductsAbbreviatedName.MPL) {
        return (
            <StyledCard.Partial>
                <Box mb={2}>
                    <Typography variant="subtitle2">
                        In an increasingly litigious world, businesses can face legal action simply for doing their job.
                        As a policyholder, your client gets protection for all employees and independent contractors:
                    </Typography>
                </Box>
                <InfoItem icon="handshake"
                    iconBGColor="#F2F2F2"
                    header="A modern solution for modern problems"
                    text="Partner with experts who understand your client’s exposure to unexpected lawsuits"
                />
                <InfoItem icon="mixer"
                    iconBGColor="#F2F2F2"
                    header="Tailored coverage your client can count on"
                    text="We continuously watch over your client so they don't have to worry about cyber threats."
                />
            </StyledCard.Partial>
        );
    }
    return (
        <StyledCard.Partial>
            <Box mb={2}>
                <Typography variant="subtitle2">
                    At-Bay goes above and beyond the typical cyber insurance experience.
                    As a policyholder, your client gets the benefits of our modern approach to risk management:
                </Typography>
            </Box>
            <InfoItem
                icon="paper-badge"
                iconBGColor="#F2F2F2"
                header="Detailed Security Report"
                text="We measure the strength of your client’s security before they purchase a policy."
                link={
                    <StyledLink.Anchor target="_blank" href={`${apiBaseUrl}/static/pdf/At-Bay_Risk_Alerts.pdf`}>
                        See example report
                    </StyledLink.Anchor>
                }
            />
            <InfoItem
                icon="loading-closed-lock"
                iconBGColor="#F2F2F2"
                header="Active Risk Monitoring"
                text="We continuously watch over your client so they don't have to worry about cyber threats."
                link={
                    <StyledLink.Anchor target="_blank" href={`${apiBaseUrl}/static/pdf/At-Bay_How_We_Help.pdf`}>
                        Review our results
                    </StyledLink.Anchor>
                }
            />
            <InfoItem
                icon="support"
                iconBGColor="#F2F2F2"
                header="Dedicated Recovery Team"
                text="We provide your client with a clear action plan in the event they experience an attack."
                link={
                    <StyledLink.Anchor target="_blank" href={`${apiBaseUrl}/static/pdf/At-Bay_Panel_Vendors.pdf`}>
                        Meet our team
                    </StyledLink.Anchor>
                }
            />
        </StyledCard.Partial>
    );
}

export default AdditionalInfo;
