import React, { Fragment } from 'react';
import { StyledLink, StyledTypography, StyledTable } from 'components';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { Box, Hidden, TableRow, Typography } from '@material-ui/core';
import { useEnv } from 'hooks';
import { PacketTemplateType, SubmissionsBucket, BinderTRIADocument } from 'utils/enums';

const DesktopDocument = ({
    documentConfig,
    optionToQuote,
    packetTemplateType,
    firstColPadding,
    firstColSpan,
    numOfExtraCols,
    variant,
    iconVariant,
}) => {
    const { apiBaseUrl } = useEnv();
    return (
        <TableRow>
            <StyledTable.TableCell colSpan={firstColSpan} padding="none">
                <Box display="flex" pl={firstColPadding} py={1}>
                    <Box mr={2} display="flex" alignItems="center" component="span">
                        <StyledTypography.FadedTypography variant={iconVariant}>
                            <DescriptionOutlinedIcon />
                        </StyledTypography.FadedTypography>
                    </Box>
                    <Typography variant={variant} component="span">
                        <StyledLink.Anchor
                            style={{ color: 'inherit' }}
                            href={`${apiBaseUrl}/company/download_document_file_from_packet/${
                                optionToQuote.id
                            }/${packetTemplateType.toUpperCase()}/${documentConfig.code}`}>
                            {documentConfig.document_template_edition.document_template_name}
                            {documentConfig.is_specimen && ' (Specimen)'}
                        </StyledLink.Anchor>
                    </Typography>
                </Box>
            </StyledTable.TableCell>
            {[...Array(numOfExtraCols)].map((_, i) => (
                <StyledTable.TableCell key={`empty-${documentConfig.id}-${i}`} padding="none" />
            ))}
        </TableRow>
    );
};

const MobileDocument = ({
    documentConfig,
    optionToQuote,
    packetTemplateType,
    firstColPadding,
    variant,
    iconVariant,
}) => {
    const { apiBaseUrl } = useEnv();
    return (
        <>
            <Box display="flex" pl={firstColPadding}>
                <Box mr={2} display="flex" alignItems="center" component="span">
                    <StyledTypography.FadedTypography variant={iconVariant}>
                        <DescriptionOutlinedIcon />
                    </StyledTypography.FadedTypography>
                </Box>
                <Typography variant={variant} component="span">
                    <StyledLink.Anchor
                        style={{ color: 'inherit' }}
                        href={`${apiBaseUrl}/company/download_document_file_from_packet/${
                            optionToQuote.id
                        }/${packetTemplateType.toUpperCase()}/${documentConfig.code}`}>
                        {documentConfig.document_template_edition.document_template_name}
                        {documentConfig.is_specimen && ' (Specimen)'}
                    </StyledLink.Anchor>
                </Typography>
            </Box>
            <Box borderBottom="1px solid #F2F2F2" mx={-4} my={2} />
        </>
    );
};

const DocumentList = ({
    optionToQuote,
    policyRequest,
    packetTemplateType,
    firstColMarginRight = '16px',
    firstColPadding = 0,
    firstColSpan = 1,
    numOfExtraCols = 0,
    variant = 'subtitle2',
    iconVariant = 'subtitle1',
}) => {
    const { apiBaseUrl } = useEnv();
    const extraDocumentCodes = [
        BinderTRIADocument[policyRequest.product.abbreviated_name],
        'INIT-BILLING',
        'AB-SER-000',
    ];
    const generatedPacket = optionToQuote.quote[`${packetTemplateType}_generated_packet`];
    const documentConfigs = generatedPacket.generated_documents.map(
        generatedDocument => generatedDocument.document_config,
    );
    const getDocuments = (packetTemplateType, codes) => {
        const documentConfigs = generatedPacket.generated_documents.map(
            generatedDocument => generatedDocument.document_config,
        );
        return documentConfigs.filter(documentConfig => codes.includes(documentConfig.code));
    };
    const extraDocumentConfigs =
        packetTemplateType === PacketTemplateType.POLICY
            ? getDocuments(PacketTemplateType.BINDER, extraDocumentCodes)
            : [];
    return (
        <>
            <Hidden smDown>
                {policyRequest.athena_submissions_bucket === SubmissionsBucket.QUOTED && (
                    <TableRow>
                        <StyledTable.TableCell colSpan={firstColSpan} padding="none">
                            <Box display="flex" pl={firstColPadding} py={1}>
                                <Box mr={firstColMarginRight} display="flex" alignItems="center" component="span">
                                    <StyledTypography.FadedTypography variant={iconVariant}>
                                        <DescriptionOutlinedIcon />
                                    </StyledTypography.FadedTypography>
                                </Box>
                                <Typography variant={variant} component="span">
                                    <StyledLink.Anchor
                                        target="_blank"
                                        style={{ color: 'inherit' }}
                                        href={`${apiBaseUrl}/questionnaire/render_policy_request/${policyRequest.id}/questionnaire`}>
                                        {policyRequest.product.name} Application
                                    </StyledLink.Anchor>
                                </Typography>
                            </Box>
                        </StyledTable.TableCell>
                        {[...Array(numOfExtraCols)].map((_, i) => (
                            <StyledTable.TableCell key={i} padding="none" />
                        ))}
                    </TableRow>
                )}
                {extraDocumentConfigs.map(documentConfig => (
                    <DesktopDocument
                        documentConfig={documentConfig}
                        optionToQuote={optionToQuote}
                        packetTemplateType={PacketTemplateType.BINDER}
                        firstColPadding={firstColPadding}
                        firstColSpan={firstColSpan}
                        numOfExtraCols={numOfExtraCols}
                        variant={variant}
                        iconVariant={iconVariant}
                        key={`${documentConfig.id}-desktop`}
                    />
                ))}
                {documentConfigs.map(documentConfig => (
                    <DesktopDocument
                        documentConfig={documentConfig}
                        optionToQuote={optionToQuote}
                        packetTemplateType={packetTemplateType}
                        firstColPadding={firstColPadding}
                        firstColSpan={firstColSpan}
                        numOfExtraCols={numOfExtraCols}
                        variant={variant}
                        iconVariant={iconVariant}
                        key={`${documentConfig.id}-desktop`}
                    />
                ))}
            </Hidden>
            <Hidden mdUp>
                <Fragment>
                    <Box display="flex" pl={firstColPadding}>
                        <Box mr={2} display="flex" alignItems="center" component="span">
                            <StyledTypography.FadedTypography variant={iconVariant}>
                                <DescriptionOutlinedIcon />
                            </StyledTypography.FadedTypography>
                        </Box>
                        <Typography variant={variant} component="span">
                            <StyledLink.Anchor
                                target="_blank"
                                style={{ color: 'inherit' }}
                                href={`${apiBaseUrl}/questionnaire/render_policy_request/${policyRequest.id}/questionnaire`}>
                                {policyRequest.product.name} Application
                            </StyledLink.Anchor>
                        </Typography>
                    </Box>
                    <Box borderBottom="1px solid #F2F2F2" mx={-4} my={2} />
                </Fragment>
                {extraDocumentConfigs.map(documentConfig => (
                    <MobileDocument
                        documentConfig={documentConfig}
                        optionToQuote={optionToQuote}
                        packetTemplateType={PacketTemplateType.BINDER}
                        firstColPadding={firstColPadding}
                        variant={variant}
                        iconVariant={iconVariant}
                        key={`${documentConfig.id}-mobile`}
                    />
                ))}
                {documentConfigs.map(documentConfig => (
                    <MobileDocument
                        documentConfig={documentConfig}
                        optionToQuote={optionToQuote}
                        packetTemplateType={packetTemplateType}
                        firstColPadding={firstColPadding}
                        variant={variant}
                        iconVariant={iconVariant}
                        key={`${documentConfig.id}-mobile`}
                    />
                ))}
            </Hidden>
        </>
    );
};

export default DocumentList;
