import React from 'react';
import { Box, Typography } from '@material-ui/core';

const DropdownHeader = ({ text }) => {
    return (
        <Box
            marginBottom="7px"
            padding="8px 0 14px 16px"
            borderBottom="1px solid #e6e6e6"
            color="#919191">
            <Typography variant="subtitle2">{text}</Typography>
        </Box>
    );
};

export default DropdownHeader;
