import styled from 'styled-components';
import _Button from '@material-ui/core/Button';

const ButtonOutline = styled(_Button)`
    &&& {
        min-width: 88px;
        padding-left: 29px;
        padding-right: 29px;
        border-radius: 0;
        background: none;
        color: #333;
        border: 1px solid #333;
        font-weight: 400;
        font-size: 0.8rem;
        font-family: Pressura;
        line-height: 1.18181818182;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 0.0833333333em;
        padding: 12px 24px;
        margin: 1rem;
        visibility: ${({ hidden }) => hidden ? 'hidden' : 'unset'}
    }
`;

const Button = styled(ButtonOutline)`
    &&& {
        border: 1px solid #333;
        background: #333;
        color: #fff;
    }
`;

const SecondaryButton = styled(_Button)`
    &&& {
        ${({ theme }) => `
            color: ${theme.palette.primary.contrastText};
            background-color: ${theme.palette.primary.main};
            font-weight: ${theme.typography.fontWeightRegular};
    `   }
        width: 100%;
        font-size: 11px;
        &[disabled] {
            opacity: 0.33;
        }
    }
    &[disabled] {
        opacity: 0.33;
    }
`;


export default {
    Button,
    ButtonOutline,
    SecondaryButton,
};
