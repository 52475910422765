import React, { FC, useCallback } from 'react';
import { StyledTable, StyledTypography } from 'components';
import { ExpandableRow } from 'components/expandable-row/ExpandableRow';
import { Box } from '@material-ui/core';

interface Props {
    commission: number;
}

const CommissionDetailsContent: FC<Props> = ({ commission }) => {
    const commissionPercentageString = `${Number(commission * 100).toLocaleString()}%`;

    return (
        <Box display="flex" justifyContent="space-around" paddingTop="8px">
            <StyledTable.TableCell>Commission</StyledTable.TableCell>
            <StyledTable.TableCell>
                <StyledTypography.Typography>{commissionPercentageString}</StyledTypography.Typography>
            </StyledTable.TableCell>
        </Box>
    );
};

export const CommissionDetails: FC<Props> = ({ commission }) => {
    const renderTitle = useCallback((isToggled: boolean) => {
        return (
            <StyledTypography.FadedTypography variant="body2" align="justify">
                {`${isToggled ? 'Hide' : 'Review'} commission`}
            </StyledTypography.FadedTypography>
        );
    }, []);

    return (
        <>
            <ExpandableRow
                renderTitle={renderTitle}
                renderContent={<CommissionDetailsContent commission={commission} />}
            />
        </>
    );
};
