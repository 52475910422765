import React from 'react';
import { Hidden, TableRow } from '@material-ui/core';
import { InnerDataCellWithOption } from 'components/table/SharedComponents';

const InnerTableRenewingBucket = ({ policyRequest, optionToQuoteData }) => {
    return (
        <>
            <Hidden mdUp>
                <TableRow>
                    <InnerDataCellWithOption policyRequest={policyRequest} optionToQuoteData={optionToQuoteData} />
                </TableRow>
            </Hidden>
            <Hidden smDown>
                <TableRow>
                    <InnerDataCellWithOption policyRequest={policyRequest} optionToQuoteData={optionToQuoteData} />
                </TableRow>
            </Hidden>
        </>
    );
};

export default InnerTableRenewingBucket;
