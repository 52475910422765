import React from 'react';
import { Box } from '@material-ui/core';
import CreateTeam from './components/CreateTeam';
import AddMember from './components/AddMember';
import RemoveMember from './components/RemoveMember';
import ChangeTeamName from './components/ChangeTeamName';
import { useSelector } from 'react-redux';
import { Permissions } from 'utils/enums';
import { Error } from 'components';


const ManageTeam = () => {
    const { permissions } = useSelector(state => state.user);

    return (
        Permissions.isAdmin(permissions) ? (
            <Box mt={15}>
                <CreateTeam/>
                <AddMember/>
                <RemoveMember/>
                <ChangeTeamName/>
            </Box>
        ) : (
            <Error errorStatus="404" />
        )
    );
};

export default ManageTeam;
