import React from 'react';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useBreakpoint } from 'hooks';


const PolicyRequestSkeleton = () => {
    const isSm = useBreakpoint('down', 'sm');
    return (
        <Box p={[0, 4, 7, 10]} bgcolor="white" mb={3}>
            <Box display="flex" alignItems="center" position="relative" mb={[3, 3, 6, 8]}>
                <Box bgcolor="white"
                    borderRadius="50%"
                    position={['absolute', 'absolute', 'relative']}
                    top="0"
                    mt={[-7, -7, 0]}
                    mr={2}>
                    <Skeleton variant="circle" width={isSm ? 48 : 56} height={isSm ? 48 : 56} animation="wave" />
                </Box>
                <Box width="70%">
                    <Box width="100%">
                        <Skeleton variant="text" height={35} animation="wave" />
                    </Box>
                    <Box width="100%">
                        <Skeleton variant="text" height={20} animation="wave" />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default PolicyRequestSkeleton;
