import { useQuery } from 'react-query';
import { useAxiosInstance } from '../../../hooks';
import { queryKeys } from '../queryKeys';
import { AxiosInstance } from 'axios';
import { ErrorResponseType } from '../../mutationTypes';

type SnapshotKeyTypes = 'open_quotes' | 'pending_quotes' | 'active_policies' | 'renewing_policies' | 'manual_renewals' | 'bind_requested_policies';
type SnapshotValueType = { submissions: number };
type SnapshotReturnType = Record<SnapshotKeyTypes, SnapshotValueType>;

type QueryReturnType = {
    data: SnapshotReturnType | undefined;
    error: ErrorResponseType | null;
    isLoading: boolean;
};

const getSnapshot = async (axiosInstance: AxiosInstance): Promise<SnapshotReturnType> => {
    const res = await axiosInstance.get('/user/snapshot');
    return res.data as SnapshotReturnType;
};

export const useSnapshot = (): QueryReturnType => {
    const axiosInstance = useAxiosInstance();

    const { data, error, isLoading } = useQuery({
        queryKey: [queryKeys.SNAPSHOT],
        queryFn: () => getSnapshot(axiosInstance),
        onError: (err: ErrorResponseType | null) => err,
    });

    return { data, error, isLoading };
};
