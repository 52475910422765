import React, { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { isPolicyRequestInProgress } from 'utils';
import { ReactComponent as ClipboardIcon } from 'components/icons/clipboard.svg';
import { ReactComponent as ClipboardCheckIcon } from 'components/icons/clipboard-check.svg';
import { ReactComponent as ClipboardShieldIcon } from 'components/icons/clipboard-shield.svg';
import { ReactComponent as LoadingIcon } from 'components/icons/loading.svg';
import { ReactComponent as ClockIcon } from 'components/icons/clock.svg';
import { Box } from '@material-ui/core';
import { ViewablePolicyType, SubmissionsBucket, RenewalType } from 'utils/enums';
import PolicyHeader from '../PolicyHeader';
import { setPolicyRequest } from 'stores/company/companySlice';
import { AutoRenewActions, AutoRenewText } from './components/renewal-quotes-are-ready/components';
import { PendingReviewActions, PendingReviewProductText } from './components/pending-review/components';
import { CompletedActions, CompletedProductText } from './components/completed/components';
import { InProgressActions, InProgressProductText } from './components/in-progress/components';
import InProgress from './components/in-progress/index';
import AutoRenew from './components/renewal-quotes-are-ready/index';
import Completed from './components/completed/index';
import PendingReview from './components/pending-review/index';
import SubmitManualRenewal from './components/submit-manual-renewal';
import { SubmitManualRenewalActions, SubmitManualRenewalText } from './components/submit-manual-renewal/components';


const UpcomingRenewalPolicy = ({ policyRequestData, monitorPolicyRequest, company }) => {
    const dispatch = useDispatch();
    const { viewable_policy_requests } = useSelector(state => state.company.company);
    const policyRequest = viewable_policy_requests.find(policyRequest => policyRequest.id === policyRequestData.id);
    const firstRequestTime = useMemo(() => dayjs.utc().unix(), []);
    const isSolicitationAndPreSubmitted = policyRequestData.renewal_type.name === RenewalType.SOLICITATION_MANUAL &&
                                          !policyRequestData.last_submitted_at;


    const dispatchPolicyRequest = () => {
        const newPolicyData = {...policyRequestData, policyType: ViewablePolicyType.UPON_RENEWAL}
        const policyRequest = {
            ...newPolicyData,
            inProgress: !isSolicitationAndPreSubmitted && isPolicyRequestInProgress(newPolicyData, firstRequestTime),
        };
        dispatch(setPolicyRequest(policyRequest));
        if (policyRequest.inProgress) {
            setTimeout(() => monitorPolicyRequest(policyRequest.id, firstRequestTime), 2000);
        }
    };

    const getPreQuoteSubmissionData = () => {
        if (isSolicitationAndPreSubmitted) {
            return {
                component: SubmitManualRenewal,
                getIcon: () => ClipboardIcon,
                productTextComponent: SubmitManualRenewalText,
                actions: SubmitManualRenewalActions,
            }
        }

        if (policyRequest.inProgress) {
            return {
                component: InProgress,
                getIcon: () => LoadingIcon,
                productTextComponent: InProgressProductText,
                actions: InProgressActions,
            }
        }

        return  {
            component: PendingReview,
            getIcon: () => ClockIcon,
            productTextComponent: PendingReviewProductText,
            actions: PendingReviewActions,
        }
    }

    const getDataByBucket = () => {
        switch(policyRequest.athena_submissions_bucket) {
            case SubmissionsBucket.PRE_QUOTE:
                return getPreQuoteSubmissionData();
            case SubmissionsBucket.QUOTED:
                return (
                    // Adding condition temporarily until other cards ready
                    policyRequest.renewal_type.value === RenewalType.MANUAL ? null : {
                        component: AutoRenew,
                        getIcon: () => ClipboardCheckIcon,
                        productTextComponent: AutoRenewText,
                        actions: AutoRenewActions,
                    })
            case SubmissionsBucket.BOUND:
            case SubmissionsBucket.BIND_REQUESTED:
                return {
                    component: Completed,
                    getIcon: () => ClipboardShieldIcon,
                    productTextComponent: CompletedProductText,
                    actions: CompletedActions,
                }
            default:
                return null
        }
    }
    const dataByBucket = useMemo(() => {return policyRequest && getDataByBucket()}, [policyRequest])


    useEffect(() => {
        dispatchPolicyRequest();
    }, []);

    const Component = dataByBucket?.component;

    return (
        <>
            {dataByBucket &&
                <Box p={[0, 4, 7, 10]} bgcolor="white" mb={3}>
                    <PolicyHeader
                        removeMargin={dataByBucket.removeMargin}
                        policyRequest={policyRequest}
                        company={company}
                        getIcon={dataByBucket.getIcon}
                        iconColor={dataByBucket.iconColor}
                        iconBgColor={dataByBucket.iconBgColor}
                        ProductTextComponent={dataByBucket.productTextComponent}
                        Actions={dataByBucket.actions}
                        setMargin={dataByBucket.setMargin}
                    />
                    {Component && <Component policyRequest={policyRequest} company={company} />}
                </Box>
            }
        </>
    )
}

export default UpcomingRenewalPolicy;
