import { Zoom, toast } from 'react-toastify';

const toastConfig = {
    position: 'top-center',
    hideProgressBar: true,
    draggable: false,
    transition: Zoom
};

export const makeToast = (type, message, config) => {
    if (!config) {
        config = {}
    }
    toast[type](message, {...toastConfig, ...config})
}
