import React from 'react';
import { SubmissionsBucket } from 'utils/enums';
import { StyledTypography } from 'components';
import { Hidden } from '@material-ui/core';

const Actions = ({ policyRequest }) => {
    return (
        <StyledTypography.FadedTypography variant="subtitle2">
            {policyRequest.athena_submissions_bucket === SubmissionsBucket.BIND_REQUESTED ? (
                'All outstanding contingencies must be fulfilled within 30 days from issuance of the binder and validated in order to receive the policy.'
            ) : (
                <>
                    <Hidden smDown>
                        View policy details or download application, policy and billing documents.
                    </Hidden>
                    <Hidden mdUp>
                        View quotes to see coverage offerings / bind a policy.
                    </Hidden>
                </>
            )}
        </StyledTypography.FadedTypography>
    )
}


export default Actions;
