import dayjs from 'dayjs';

export const dateUTCFormatter = (dateString) => {
    return dayjs(`${dateString} UTC`).format('MMM D YYYY | HH:mm')
};

// this handles dates returned by DatePicker from @material-ui/pickers
export const getDateObject = date => typeof date === 'string' ? makeDatePickerDate(date) : date;

export const formatDatePickerDate = (date, format = 'MM/DD/YYYY') => {
    return makeDatePickerDate(getDateObject(date)).format(format)
};

export const makeDatePickerDate = date => dayjs(date);

export const dateDifference = (date1, date2, unit = 'day') => {
    const unitToDivider = {
        day: 8.64e+7,
        week: 6.048e+8,
        month: 2.628e+9,
        year: 3.154e+10
    }
    return (date1 - date2) / unitToDivider[unit];
};
