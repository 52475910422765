import React, {useState, useEffect, useRef, useMemo, useCallback} from 'react';
import { useAxiosClient, useEnv } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, FormControl, FormControlLabel, FormHelperText, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isValidDomain } from 'utils';
import {
    CompaniesBuckets,
    CreateSubmissionFormType,
    Permissions,
} from 'utils/enums';
import Snapshot from './components/snapshot/Snapshot';
import { setAvailableProducts } from 'stores/globalData/globalDataSlice';
import { StyledForm, RoundedButton, Loading, StyledInput, StyledAction, StyledSelectField, Sty } from 'components';
import { ReactComponent as ClipboardIcon } from 'components/icons/clipboard.svg';
import { ReactComponent as SecurityShieldIcon } from 'components/icons/shield-security.svg';
import { ReactComponent as RecycleShieldIcon } from 'components/icons/recycle-shield.svg';
import { ReactComponent as TriangleClipboardIcon } from 'components/icons/triangle-clipboard.svg';
import Styled from './styled-components';
import { useCreateSubmission } from 'api/dashboard/mutations/useCreateSubmission';
import { PermissionDenied, PermissionGranted, PermissionRequired } from 'components/permission-required';
import { usePermissions } from 'hooks/usePermissions';
import CustomCheckbox from 'components/checkbox/CustomCheckbox';


const quotes_headers = [
    { text: 'client', width: '60.8040201%' },
    {
        text: 'product',
        width: '12.5628141%',
    },
    { text: 'status', width: '20.1005025%' },
    { text: '', width: '6.53266332%' },
];
const policies_headers = [
    { text: 'client', width: '32.5%' },
    {
        text: 'policy period',
        width: '28.3333333%',
    },
    { text: 'product', width: '12.5%' },
    { text: 'status', width: '20.1041667%' },
    { text: '', width: '6.5625%' },
];

const HAS_WEBSITE_KEY = 'has_website';

const DesktopDashboard = ({
    state,
    reducerDispatch,
    subBucket,
    setSubBucket,
    bucket,
    snapshots,
    setSnapshots,
    subBucketsList,
    setOpenNotification,
    setAlertText,
    bucketChange,
}) => {
    const dispatch = useDispatch();
    const snapshotSection = useRef(null);
    const { newRenewalsTab } = useEnv();
    const tableHeaderSection = useRef(null);
    const history = useHistory();
    const axiosClient = useAxiosClient();
    const [submit, setSubmit] = useState(false);
    const [validWebsite, setValidWebsite] = useState(false);
    const [websiteError, setWebsiteError] = useState(false);
    const [companyNameError, setCompanyNameError] = useState(false);
    const [companyViewable, setCompanyViewable] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const [wasClicked, setWasClicked] = useState(false);
    const [stickySection, setStickySection] = useState(false);
    const [existingCompanyId, setExistingCompanyId] = useState(null);
    const [labelProduct, setLabelProduct] = useState('Select Product...');
    const [labelCompany, setLabelCompany] = useState('Company Name...');
    const [productExistsError, setProductExistsError] = useState(false);
    const [matchCompaniesError, setMatchCompaniesError] = useState(false);
    const submissionWriteAuthorized = usePermissions()([Permissions.SUBMISSION_WRITE]);
    const mainError = useMemo(() => {
        return productExistsError
            ? 'Submission already exists for product type'
            : matchCompaniesError
                ? 'Name and Website match different accounts'
                : '';
    }, [productExistsError, matchCompaniesError]);
    const { availableProducts } = useSelector(state => state.globalData);
    const isDomainRequired = useMemo(() => {
        const product = availableProducts.find(product => product.id === state.product);
        return !product?.is_support_no_domain;
    }, [state.product, availableProducts]);
    const [labelWebsite, setLabelWebsite] = useState(isDomainRequired ? 'Website...' : 'Website (optional)');
    const isHasDomainQuestionSupported = useMemo(() => {
        const product = availableProducts.find(product => product.id === state.product);
        return product?.is_support_no_domain && product?.name === 'Cyber';
    }, [state.product, availableProducts]);
    const [hasDomainRequiredError, setHasDomainRequiredError] = useState(
        isHasDomainQuestionSupported && !state.website && state.has_website);

    useEffect(() => {
        setHasDomainRequiredError(isHasDomainQuestionSupported && !state.website && state.has_website);
    }, [isHasDomainQuestionSupported, state.website, state.has_website]);


    useEffect(() => {
        setLabelWebsite(isDomainRequired ? 'Website...' : 'Website (optional)');
    }, [isDomainRequired]);

    const [, getProducts] = axiosClient(
        {
            method: 'post',
            url: '/product/list',
        },
        { manual: true },
    );

    const handleOnSubmitRequestSuccess = submission => {
        history.push({
            pathname: `/questionnaire/policy/${submission.policy_request_id}`,
            state: { resubmit: submission.resubmit },
        });
    };


    const handleOnSubmitRequestFail = error => {
        if (error.response?.data?.url) {
            setWebsiteError(true);
        }
        setWasClicked(false);
        setProductExistsError(error.response?.data?.product_id === 'product_already_exists');
        setMatchCompaniesError(
            error.response?.data?.match_different_accounts > 1 ||
                (error.response?.data?.url === 'domain_exists' &&
                    error.response.data?.invalid_company_name &&
                    error.response?.data?.product_id !== 'product_already_exists'),
        );
        setCompanyNameError(!!error.response.data?.invalid_company_name);
        setExistingCompanyId(error.response.data?.existing_company_id);
        setCompanyViewable(error.response.data?.company_viewable);
    };

    const { mutate: createSubmission } = useCreateSubmission(handleOnSubmitRequestSuccess, handleOnSubmitRequestFail);

    const getTextForPendingSnapshot =  useCallback(() => {
        if (submissionWriteAuthorized) {
            if (state.pending_quotes.submissions === 1) {
                return 'Pending Quote';
            }
            return 'Pending Quotes';
        }
        if (state.pending_quotes.submissions === 1) {
            return 'Bind Request';
        }
        return 'Bind Requests';
    }, []);

    const handleScroll = () => {
        const snapshotTop = snapshotSection.current?.offsetTop;
        const $root = document.querySelector('#root');
        const offset = $root.children[1]?.clientHeight;
        const afterSnapshotHeader = snapshotTop - offset;
        const snapshotSectionHeight = snapshotSection.current.getBoundingClientRect().height;
        const tableHeaderSectionHeight = tableHeaderSection.current.getBoundingClientRect().height;
        if (window.scrollY >= afterSnapshotHeader) {
            setExpanded(false);
        } else if (!window.scrollY) {
            setExpanded(true);
        }
        if (window.scrollY >= snapshotSectionHeight) {
            setStickySection(true);
        }
        if (window.scrollY + offset <= snapshotSectionHeight + tableHeaderSectionHeight) {
            setStickySection(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        setValidWebsite((state.product && !state.website && !isDomainRequired) || isValidDomain(state.website));
    }, [state.website, isDomainRequired]);

    const isValid = () => {
        return state.product && state.company
            && ((isHasDomainQuestionSupported && (state.website || !state.has_website))
                || (!isHasDomainQuestionSupported && !state.website && !isDomainRequired)
                || isValidDomain(state.website));
    };

    const handleSubmit = async () => {
        setSubmit(true);
        if (productExistsError) {
            if (companyViewable) {
                history.push(`/company/${existingCompanyId}`);
            } else {
                setAlertText('This account is not currently accessible. Please contact your underwriter.');
                setOpenNotification(true);
            }
        } else if (isValid()) {
            setWasClicked(true);
            const createSubmissionData = {
                company_name: state.company,
                url: state.website,
                product_id: state.product,
                form_name: CreateSubmissionFormType.QUOTE
            }

            if (isHasDomainQuestionSupported) {
                createSubmissionData[HAS_WEBSITE_KEY] = state.has_website ? 'True' : 'False';
            }

            createSubmission(createSubmissionData);
        }
    };

    const handleExpandClick = () => {
        if (!expanded) {
            setExpanded(!expanded);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const fetchProducts = async () => {
            const res = await getProducts({
                data: {
                    query: { id: {}, name: {}, abbreviated_name: {}, is_technological: {}, is_support_no_domain: {} }
                },
            });
            dispatch(setAvailableProducts(res.data));
        };
        if (!availableProducts) {
            fetchProducts();
        }
    }, []);

    const updateForm = (type, value) => {
        reducerDispatch({ type, payload: value });
        setMatchCompaniesError(false);
        setProductExistsError(false);
        setWebsiteError(false);
        setCompanyNameError(false);
    };

    if (!availableProducts) {
        return <Loading />;
    }

    const SnapshotContainer = submissionWriteAuthorized ?
        Styled.Container : Styled.FullWidthSnapshotContainer;

    const getDomainErrorText = () => {
        if (!submit || (validWebsite && !websiteError && !hasDomainRequiredError)) {
            return '';
        }

        if (!state.website && isDomainRequired) {
            return 'Required'
        }

        if (!validWebsite) {
            return 'Please enter a valid website address'
        }

        if (hasDomainRequiredError) {
            return 'Please complete this field or check the box below'
        }

        return ''
    }

    const isDomainError = () => {
        return submit && (!validWebsite || websiteError || hasDomainRequiredError)
    }

    return (
        <>
            {!expanded && (
                <Box component={Styled.AppBar} height="56px">
                    <Styled.Toolbar onClick={handleExpandClick}>
                        <Box width="100%" display="flex" flexDirection="column">
                            <Box width="100%" display="flex" justifyContent="space-between">
                                <Box
                                    component={StyledAction.Base}
                                    width="inherit"
                                    borderBottom="1px solid #E6E6E6"
                                    mr={3}
                                    pb={1}>
                                    <Styled.Typography variant="subtitle2" className="sticky">
                                        Snapshots
                                        <ExpandMoreIcon />
                                    </Styled.Typography>
                                </Box>
                                <PermissionRequired requiredPermissions={[Permissions.SUBMISSION_WRITE]}>
                                    <PermissionGranted>
                                        <Box
                                            component={StyledAction.Base}
                                            maxWidth="312px"
                                            width="inherit"
                                            borderBottom="1px solid #E6E6E6"
                                            pb={1}>
                                            <Styled.Typography variant="subtitle2" className="sticky">
                                                Start New Quote
                                                <ExpandMoreIcon />
                                            </Styled.Typography>
                                        </Box>
                                    </PermissionGranted>
                                    <PermissionDenied>
                                        <></>
                                    </PermissionDenied>
                                </PermissionRequired>
                            </Box>
                        </Box>
                    </Styled.Toolbar>
                </Box>
            )}
            <Box display="flex" flexDirection="column" width="100%">
                <Box display="flex">
                    <Box display="flex" flexDirection="column" width="100%">
                        <Box mb={1.5}>
                            <Styled.Typography variant="h5">Snapshots</Styled.Typography>
                        </Box>
                        <Box display="flex" ref={snapshotSection}>
                            <SnapshotContainer limitwidth="true">
                                <Box display="flex" flexDirection="column" mr={0.5}>
                                    <Snapshot
                                        icon={<ClipboardIcon />}
                                        fullWidth={!submissionWriteAuthorized}
                                        setHandler={setSnapshots}
                                        snapshotsText="Open"
                                        text={state.open_quotes.submissions === 1 ? 'Open Quote' : 'Open Quotes'}
                                        submissions={state.open_quotes.submissions}
                                        snapshots={snapshots}
                                    />

                                    <Snapshot
                                        icon={<TriangleClipboardIcon />}
                                        fullWidth={!submissionWriteAuthorized}
                                        setHandler={setSnapshots}
                                        snapshotsText={submissionWriteAuthorized ? 'Pending' : 'Bind_Request'}
                                        text={getTextForPendingSnapshot()}
                                        submissions={state.bind_requested_policies.submissions}
                                        snapshots={snapshots}
                                    />
                                </Box>
                            </SnapshotContainer>
                            <SnapshotContainer>
                                <Box display="flex" flexDirection="column" width="100%">
                                    <Snapshot
                                        icon={<SecurityShieldIcon />}
                                        fullWidth={!submissionWriteAuthorized}
                                        setHandler={setSnapshots}
                                        snapshotsText="Active"
                                        text={
                                            state.active_policies.submissions === 1
                                                ? 'Active Policy'
                                                : 'Active Policies'
                                        }
                                        submissions={state.active_policies.submissions}
                                        snapshots={snapshots}
                                    />
                                    {newRenewalsTab ? (
                                        <Snapshot
                                            icon={<RecycleShieldIcon />}
                                            fullWidth={!submissionWriteAuthorized}
                                            setHandler={setSnapshots}
                                            snapshotsText="Manual"
                                            text={
                                                state.manual_renewals.submissions === 1
                                                    ? 'Manual Renewal'
                                                    : 'Manual Renewals'
                                            }
                                            submissions={state.manual_renewals.submissions}
                                            snapshots={snapshots}
                                        />
                                    ) : (
                                        <Snapshot
                                            icon={<RecycleShieldIcon />}
                                            fullWidth={!submissionWriteAuthorized}
                                            setHandler={setSnapshots}
                                            snapshotsText="Renewing"
                                            text={
                                                state.renewing_policies.submissions === 1
                                                    ? 'Renewing Policy'
                                                    : 'Renewing Policies'
                                            }
                                            submissions={state.renewing_policies.submissions}
                                            snapshots={snapshots}
                                        />
                                    )}
                                </Box>
                            </SnapshotContainer>
                        </Box>
                    </Box>
                    <PermissionRequired requiredPermissions={[Permissions.SUBMISSION_WRITE]}>
                        <PermissionGranted>
                            <Box display="flex" flexDirection="column" maxWidth="312px" width="100%">
                                <Box mb={1.5}>
                                    <Styled.Typography variant="h5">Start New Quote</Styled.Typography>
                                </Box>
                                <Box component={Styled.FormContainer} justifyContent="space-between">
                                    <StyledForm.Form onSubmit={handleSubmit}>
                                        <Styled.FormControl required error={submit && !state.product} fullWidth={true}>
                                            <StyledSelectField.InputLabel id="product-label">
                                                {labelProduct}
                                            </StyledSelectField.InputLabel>
                                            <StyledSelectField.Select
                                                labelId="product-label"
                                                id="product-select"
                                                error={submit && !state.product}
                                                onFocus={() => setLabelProduct('Product')}
                                                onChange={e => reducerDispatch({ type: 'product', payload: e.target.value })}
                                                value={state.product}
                                                onBlur={() =>
                                                    state.product
                                                        ? setLabelProduct('Product')
                                                        : setLabelProduct('Select Product...')
                                                }
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    },
                                                    getContentAnchorEl: null,
                                                }}>
                                                {availableProducts.map(product => (
                                                    <StyledSelectField.MenuItem key={product.id} value={product.id}>
                                                        {product.name}
                                                    </StyledSelectField.MenuItem>
                                                ))}
                                            </StyledSelectField.Select>
                                            <FormHelperText>{submit && !state.product ? 'Required' : ''}</FormHelperText>
                                        </Styled.FormControl>
                                        <Styled.FormControl fullWidth>
                                            <StyledInput.FormTextField
                                                required
                                                type="text"
                                                name="company"
                                                error={(submit && !state.company) || companyNameError}
                                                helperText={submit && !state.company ? 'Required' : ''}
                                                onChange={e => {
                                                    updateForm(e.target.name, e.target.value);
                                                    reducerDispatch({ type: e.target.name, payload: e.target.value });
                                                }}
                                                value={state.company}
                                                label={labelCompany}
                                                onFocus={() => setLabelCompany('Company Name')}
                                                onBlur={() =>
                                                    state.company
                                                        ? setLabelCompany('Company Name')
                                                        : setLabelCompany('Company Name...')
                                                }
                                            />
                                        </Styled.FormControl>
                                        <Styled.FormControl fullWidth>
                                            <StyledInput.FormTextField
                                                required={isDomainRequired}
                                                disabled={isHasDomainQuestionSupported && !state.has_website}
                                                onChange={e => {
                                                    updateForm(e.target.name, e.target.value)
                                                    reducerDispatch({ type: HAS_WEBSITE_KEY, payload: true });
                                                }}
                                                value={state.website}
                                                type="url"
                                                name="website"
                                                label={labelWebsite}
                                                error={isDomainError()}
                                                helperText={getDomainErrorText()}
                                                onFocus={() => setLabelWebsite('Website')}
                                                onBlur={() =>
                                                    state.website
                                                        ? setLabelWebsite('Website')
                                                        : setLabelWebsite(isDomainRequired ? 'Website...' : 'Website (optional)')
                                                }
                                            />
                                        </Styled.FormControl>
                                        { (isHasDomainQuestionSupported || false) && (
                                            <FormControl
                                                fullWidth
                                                onChange={e => {
                                                    updateForm(HAS_WEBSITE_KEY, !state.has_website);
                                                }}
                                                disabled={Boolean(state.website)}
                                                name={HAS_WEBSITE_KEY}>
                                                <FormControlLabel
                                                    onClick={e => e.preventDefault()}
                                                    style={{ marginLeft: 0, marginRight: 0, marginTop: isDomainError() ? 5 : 1 }}
                                                    label={
                                                        <Typography
                                                            variant="body1"
                                                            style={{ paddingLeft: 10 }}
                                                            onClick={e => e.stopPropagation()}>
                                                            <Box
                                                                component="span"
                                                                color={!state.has_website ? '#191919' : '#696969'}
                                                                fontWeight="fontWeightLight">
                                                                { 'Applicant does not have a website' }
                                                            </Box>
                                                        </Typography>
                                                    }
                                                    control={
                                                        <CustomCheckbox
                                                            onClick={e => e.stopPropagation()}
                                                            name={HAS_WEBSITE_KEY}
                                                            value={state.has_website}
                                                            checked={!state.has_website}
                                                        />
                                                    }
                                                    value={state.has_website}
                                                />
                                            </FormControl>
                                        )}
                                    </StyledForm.Form>
                                    <Typography color="error" align="center" variant="body2">
                                        {mainError}
                                    </Typography>
                                    <RoundedButton
                                        py={0.5}
                                        disabled={wasClicked}
                                        width="100%"
                                        onClick={() => handleSubmit()}
                                        className={productExistsError ? 'black' : null}>
                                        <Typography variant="subtitle1">
                                            {productExistsError
                                                ? 'Go to Client Details'
                                                : wasClicked
                                                ? 'Please wait...'
                                                : 'Next'}
                                        </Typography>
                                    </RoundedButton>
                                </Box>
                            </Box>
                        </PermissionGranted>
                        <PermissionDenied>
                            <></>
                        </PermissionDenied>
                    </PermissionRequired>
                </Box>
                {stickySection && (
                    <Box component={Styled.AppBar} mt={7}>
                        <Styled.Toolbar>
                            <Box left="32px" right="0" mt={4} width="100%">
                                <Box display="flex" fontWeight="fontWeightBold" mb={[1, 1, 1, 1.5]} ml={[4, 4, 4, 0]}>
                                    <Box
                                        component={StyledAction.Base}
                                        mr={[1, 1, 1.5, 2]}
                                        color={bucket !== CompaniesBuckets.QUOTES ? '#919191' : '#191919'}
                                        onClick={() => bucketChange('quotes')}>
                                        <Typography variant="h3">Quotes</Typography>
                                    </Box>
                                    <Box
                                        component={StyledAction.Base}
                                        mr={2}
                                        color={bucket !== CompaniesBuckets.POLICIES ? '#919191' : '#191919'}
                                        onClick={() => bucketChange('policies')}>
                                        <Typography variant="h3">Policies</Typography>
                                    </Box>
                                    {newRenewalsTab && (
                                        <Box
                                            component={StyledAction.Base}
                                            mr={2}
                                            color={bucket !== CompaniesBuckets.RENEWALS ? '#919191' : '#191919'}
                                            onClick={() => bucketChange('renewals')}>
                                            <Typography variant="h3">Renewals</Typography>
                                        </Box>
                                    )}
                                </Box>
                                <Box pl={[4, 4, 4, 0]} display="flex" borderBottom="1px solid #E6E6E6" mb={{ sm: 1.5 }}>
                                    {subBucketsList.map((bucket, idx) => (
                                        <Box
                                            component={StyledAction.Base}
                                            mr={{
                                                sm: idx === subBucketsList.length - 1 ? 0 : 1.5,
                                                md: idx === subBucketsList.length - 1 ? 0 : 2,
                                            }}
                                            key={bucket.name}>
                                            <Styled.Typography
                                                className="sticky bucket"
                                                variant="subtitle2"
                                                selected={subBucket === bucket.name}
                                                onClick={() => setSubBucket(bucket.name)}>
                                                {bucket.text}
                                            </Styled.Typography>
                                        </Box>
                                    ))}
                                </Box>
                                <Box display="flex" color="#919191" pt={1.5} pb={1}>
                                    {bucket === CompaniesBuckets.QUOTES
                                        ? quotes_headers.map(header => (
                                            <Box key={`sticky_header_${header.text}`} width={header.width}>
                                                <Box pl={header.text === 'client' ? 4 : 0}>
                                                    <Typography variant="overline">
                                                        {header.text.toUpperCase()}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        ))
                                        : policies_headers.map(header => (
                                            <Box key={`sticky_header_${header.text}`} width={header.width}>
                                                <Box pl={header.text === 'client' ? 4 : 0}>
                                                    <Typography variant="overline">
                                                        {header.text.toUpperCase()}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        ))}
                                </Box>
                            </Box>
                        </Styled.Toolbar>
                    </Box>
                )}
                <Box left="32px" right="0" mt={4} ref={tableHeaderSection}>
                    <Box display="flex" fontWeight="fontWeightBold" mb={[1, 1, 1, 1.5]} ml={[4, 4, 4, 0]}>
                        <Box
                            component={StyledAction.Base}
                            mr={[1, 1, 1.5, 2]}
                            color={bucket !== CompaniesBuckets.QUOTES ? '#919191' : null}
                            onClick={() => bucketChange('quotes')}>
                            <Typography variant="h3">Quotes</Typography>
                        </Box>
                        <Box
                            component={StyledAction.Base}
                            mr={2}
                            color={bucket !== CompaniesBuckets.POLICIES ? '#919191' : null}
                            onClick={() => bucketChange('policies')}>
                            <Typography variant="h3">Policies</Typography>
                        </Box>
                        {newRenewalsTab && (
                            <Box
                                component={StyledAction.Base}
                                mr={2}
                                color={bucket !== CompaniesBuckets.RENEWALS ? '#919191' : null}
                                onClick={() => bucketChange('renewals')}>
                                <Typography variant="h3">Renewals</Typography>
                            </Box>
                        )}
                    </Box>
                    <Box pl={[4, 4, 4, 0]} display="flex" borderBottom="1px solid #E6E6E6" mb={{ sm: 1.5 }}>
                        {subBucketsList.map((bucket, idx) => (
                            <Box
                                component={StyledAction.Base}
                                mr={{
                                    sm: idx === subBucketsList.length - 1 ? 0 : 1.5,
                                    md: idx === subBucketsList.length - 1 ? 0 : 2,
                                }}
                                key={bucket.name}>
                                <Styled.Typography
                                    className="sticky bucket"
                                    variant="subtitle2"
                                    selected={subBucket === bucket.name}
                                    onClick={() => setSubBucket(bucket.name)}>
                                    {bucket.text}
                                </Styled.Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default DesktopDashboard;
