import dayjs from 'dayjs';
import {
    NoteIdentifier,
    SubmissionsBucket,
    QuoteStatus,
    PacketTemplateType,
    ProductsAbbreviatedName,
} from './enums';

export const contingencyChecked = (contingencies, identifier = NoteIdentifier.SIGNATURE) =>
    contingencies.find(contingency => contingency.identifier === identifier)?.checked;

export const contingencyAppUploaded = (contingencies, identifier = NoteIdentifier.SIGNATURE) =>
contingencies.find(contingency => contingency.identifier === identifier)?.files.length > 0 ;

export const isPolicyRequestInProgress = (policyRequest, firstRequestTime) => {
    const scanIsTakingTooLong =
        policyRequest.latest_scan?.start_time_unix &&
        (dayjs.utc().unix() - policyRequest.latest_scan.start_time_unix) / 60 > 10 &&
        policyRequest.latest_scan.completed_successfully === undefined;
    const isMpl = policyRequest.product?.abbreviated_name === ProductsAbbreviatedName.MPL;
    if (!policyRequest.broker_submitted) {
        return false;
    }
    if (policyRequest.athena_submissions_bucket === SubmissionsBucket.PRE_QUOTE) {
        if (!policyRequest.is_no_domain_policy && policyRequest.latest_scan) {
            if (policyRequest.latest_scan.completed_successfully === false || scanIsTakingTooLong) {
                return false;
            } else if (
                !policyRequest.quotes_from_auto_quote.length ||
                policyRequest.quotes_from_auto_quote.some(quote => QuoteStatus.isInProgress(quote.latest_status))
            ) {
                return true;
            }
        } else if (policyRequest.is_no_domain_policy) {
            // in case of mpl -> if we have quotes in progress and we are waiting less than 10 mins, return true (still in progress)
            // if we are waiting more than 10 mins, return false
            if (!policyRequest.quotes_from_auto_quote.length ||
                policyRequest.quotes_from_auto_quote.some(quote => QuoteStatus.isInProgress(quote.latest_status))) {
                return ((dayjs.utc().unix() - firstRequestTime) / 60 < 10);
            }
        } else if ((dayjs.utc().unix() - firstRequestTime) / 60 < 10) {
            return true;
        }
    } else if (policyRequest.athena_submissions_bucket === SubmissionsBucket.QUOTED) {
        return policyRequest.quotes_from_auto_quote.some(
            quote => !quote.option_to_quote?.published && QuoteStatus.isInProgress(quote.latest_status)
        );
    }
    return false;
};

export const getRelevantPacketTemplateType = policyRequest => {
    if (policyRequest.conditionally_bound) {
        return PacketTemplateType.BINDER;
    } else if (policyRequest.athena_submissions_bucket === SubmissionsBucket.BOUND) {
        return PacketTemplateType.POLICY;
    } else {
        return PacketTemplateType.QUOTE;
    }
};

export const getSignatureStatusText = policyRequest => {
    if (policyRequest.signed_at) {
        return 'E-signature received';
    } else if (contingencyChecked(policyRequest.contingencies) && !policyRequest.app_disclaimer_checked) {
        return 'Signed application received';
    } else if (contingencyAppUploaded(policyRequest.contingencies) && policyRequest.app_disclaimer_checked) {
        return 'Signed application uploaded';
    } else if (contingencyAppUploaded(policyRequest.contingencies)) {
        return 'Application uploaded';
    } else if (policyRequest.for_signature_pdf_file_id) {
        return 'E-signature requested';
    }
    return null;
}

export const displayClaimLimit = (policyRequest, optionToQuote = null) => {
    if (policyRequest.product?.abbreviated_name === ProductsAbbreviatedName.MPL){
        return true;
    }
    if (optionToQuote) {
        if (optionToQuote.quote) {
            return optionToQuote.quote.aggregate_limit !== optionToQuote.quote.per_claim_limit;
        }
        return optionToQuote.limit !== optionToQuote.claimLimit;
    }
    return policyRequest.option_to_quotes?.find(optionToQuote => optionToQuote.quote.aggregate_limit !== optionToQuote.quote.per_claim_limit);
}

export const getSortedOptionsToQuote = (optionToQuotes) => {
    const arrayForSort = [...optionToQuotes];
    arrayForSort.sort((firstOption, secondOption) =>
        firstOption.quote.aggregate_limit - secondOption.quote.aggregate_limit);
    return arrayForSort;
}
