import styled from 'styled-components';

const UserIconButton = styled.div`
    height: 28px;
    width: 28px;
    line-height: 28px;
    text-align: center;
    border-radius: 50%;
    background: black;
    color: white;
    margin-top: -4px;
    margin-left: 8px;
    margin-right: 18px;
    ${({ theme }) => `
        ${theme.breakpoints.down('md')} {
            margin-right: -6px;
        }
        ${theme.breakpoints.down('sm')} {
            margin-right: 0;
        }
    `}
`;

export default {
    UserIconButton,
};
