import React from 'react';
import { RoundedButton, StyledLink } from 'components';
import { TableRow, Typography, Box, Hidden } from '@material-ui/core';
import { useBreakpoint, useEnv } from 'hooks';
import Styled from 'components/table/styled-components';
import { InnerDataCellWithoutOption } from 'components/table/SharedComponents';

const InnerTablePendingBucket = ({ policyRequestId, collapseData }) => {
    const isSm = useBreakpoint('down', 'sm');
    const { apiBaseUrl } = useEnv();

    const RoundedButtonComponent = () => {
        return (
            <RoundedButton py={isSm ? 0.5 : 0.25} fullWidth={isSm}>
                <StyledLink.Anchor
                    target="_blank"
                    style={{ color: 'inherit' }}
                    href={`${apiBaseUrl}/questionnaire/render_policy_request/${policyRequestId}/questionnaire`}>
                    <Typography variant="subtitle2">Review Application</Typography>
                </StyledLink.Anchor>
            </RoundedButton>
        );
    };

    return (
        <>
            <Hidden mdUp>
                <TableRow>
                    <InnerDataCellWithoutOption collapseData={collapseData} />
                </TableRow>
                <TableRow>
                    <Styled.TableCell className="inner black" align="center" width="100%">
                        <Box pt={1.5}>
                            <RoundedButtonComponent />
                        </Box>
                    </Styled.TableCell>
                </TableRow>
            </Hidden>
            <Hidden smDown>
                <TableRow>
                    <InnerDataCellWithoutOption collapseData={collapseData} />
                    <Styled.TableCell className="inner alignEnd button black">
                        <RoundedButtonComponent />
                    </Styled.TableCell>
                </TableRow>
            </Hidden>
        </>
    );
};

export default InnerTablePendingBucket;
