import React from 'react';
import Styled from 'modules/submission/pages/quote-customization/components/styled-components';
import { useBreakpoint, useReconstructOtherParameterList } from 'hooks';
import { Box, Typography } from '@material-ui/core';
import { LineItemType } from 'utils/enums';
import { useStyles } from 'utils/customization';

const SelectOther = ({ lineItem, handleChangeLineItem }) => {
    const classes = useStyles();
    const allowedOthers = useReconstructOtherParameterList(
        lineItem.other_allowed_parameters,
        lineItem.code,
        lineItem.type,
    );
    const isSm = useBreakpoint('down', 'sm');

    if (lineItem.type !== LineItemType.LINE_ITEM && lineItem.limit) {
        return (
            <Styled.Select
                value={lineItem.other_parameter.value}
                onChange={event =>
                    handleChangeLineItem(
                        event,
                        'other_parameter',
                        lineItem.code,
                    )
                }
                className={isSm ? classes.SelectMobile : classes.Select}
                m={0}
            >
                <Box component={Styled.SelectTitle} p={1}>
                    <Typography variant="overline">
                        {lineItem.other_parameter.name}
                    </Typography>
                </Box>
                {allowedOthers.map(limit => {
                    if (limit.id === 'fakeId') {
                        return (
                            <Box
                                component={Styled.SelectTitle}
                                borderTop="1px solid #F2F2F2"
                                value={limit.value}
                                key={`${lineItem.coverage_name}-other-parameter-${limit.id}`}
                                p={1}
                            >
                                <Typography variant="overline">
                                    {limit.value}
                                </Typography>
                            </Box>
                        );
                    }
                    return (
                        <Box
                            component={Styled.MenuItem}
                            key={`${lineItem.coverage_name}-other-parameter-${limit.id}`}
                            value={limit.value}
                        >
                            <Typography variant="body1">
                                {limit.value}
                                {lineItem.type === LineItemType.WAITING_PERIOD
                                    ? ' Hours'
                                    : ' Days'}
                            </Typography>
                        </Box>
                    );
                })}
            </Styled.Select>
        );
    }

    return (
        <Box width={isSm ? '100px' : '120px'} height="37px" bgcolor="#F8F8F8" />
    );
};

export default SelectOther;
