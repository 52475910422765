import React from 'react';
import {
    Box,
    Typography,
    FormControl,
    Table,
    TableBody,
    TableContainer,
    TableRow,
} from '@material-ui/core';
import Styled from 'modules/submission/pages/quote-customization/components/styled-components';
import SelectLimit from './SelectLimit';
import SelectClaimLimit from './SelectClaimLimit';
import SelectRetention from './SelectRetention';


const Desktop = ({ handleChangeAggregate, handleCalculation, classes }) => {
    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <TableRow>
                        <Box component={Styled.TableCell} className={classes.TableCoverageName}>
                            <Typography variant="body1">
                                Aggregate Limit & Retention
                            </Typography>
                        </Box>
                        <Box component={Styled.TableCell}>
                            <FormControl>
                                <SelectLimit
                                    handleChangeAggregate={handleChangeAggregate}
                                    classes={classes}
                                />
                            </FormControl>
                        </Box>
                        <Styled.TableCell>
                            <FormControl>
                                <SelectRetention
                                    handleChangeAggregate={handleChangeAggregate}
                                    classes={classes}
                                />
                            </FormControl>
                        </Styled.TableCell>
                    </TableRow>
                    <TableRow>
                        <Box component={Styled.TableCell} className={classes.TableCoverageName}>
                            <Typography variant="body1">
                                Per Claim Limit
                            </Typography>
                        </Box>
                        <Styled.TableCell>
                            <FormControl>
                                <SelectClaimLimit
                                    handleCalculation={handleCalculation}
                                    classes={classes}
                                />
                            </FormControl>
                        </Styled.TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default Desktop;
