export const ProductsAbbreviatedName = {
    CYBER: 'CYB',
    EXCESS_CYBER: 'EXCYB',
    CYBER_TEO: 'TEO',
    EXCESS_CYBER_TEO: 'EXTEO',
    MPL: 'MPL',
};

export const SubmissionsBucket = {
    PRE_QUOTE: 'pre_quote',
    QUOTED: 'quoted',
    BIND_REQUESTED: 'bind_requested',
    BOUND: 'bound',
    DECLINED: 'declined',
    LOST: 'lost',
    EXPIRED: 'expired',
    CANCELED: 'canceled',
};

export const SecurityScoreStatus = {
    NOT_REQUESTED: 'not_requested',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
    ERROR: 'error',
};

export const PacketTemplateType = {
    QUOTE: 'quote',
    BINDER: 'binder',
    POLICY: 'policy',
};

export const BinderTRIADocument = {
    CYB: 'AB-BDR-TRIA',
    EXCYB: 'AB-XS-CBDR-TRIA',
    TEO: 'AB-TEO-BDR-TRIA',
    EXTEO: 'AB-XS-CBDR-TRIA',
};

export const Mode = {
    DEV: 'development',
    PROD: 'production',
    TEST: 'test',
};

export const NoteIdentifier = {
    SIGNATURE: 'SIGNATURE',
    CLAIMS_MADE_DATE: 'CLAIMS_MADE_DATE',
};


export const Permissions = {
    LOGIN: 'loginAllowed',
    ADMIN_READ: 'adminRead',
    ADMIN_WRITE: 'adminWrite',
    ADMIN_DELETE: 'adminDelete',
    POLICY_UNRESTRICTED: 'policyUnrestricted',
    SUBMISSION_WRITE: 'submissionWrite',
    isAdmin: function(permissions=[]) {
        return permissions.some(permission => permission === this.ADMIN_READ);
    },
    isLoginAllowed: function(permissions=[]) {
        return permissions.some(permission => permission === this.LOGIN);
    },
}

export const QuotesSubBuckets = {
    OPEN: 'Open',
    INCOMPLETE: 'Incomplete',
    PENDING: 'Pending',
    BIND_REQUEST: 'Bind_Request',
    INACTIVE: 'Inactive',
};

export const PoliciesSubBuckets = {
    ACTIVE: 'Active',
    RENEWING: 'Renewing',
    INACTIVE: 'Inactive',
};

export const RenewalsSubBuckets = {
    RAPID: 'Rapid',
    MANUAL: 'Manual',
    NOT_RENEWING: 'Not_Renewing',
};

export const CompaniesBuckets = {
    QUOTES: 'quotes',
    POLICIES: 'policies',
    RENEWALS: 'renewals',
};

export const SnapshotsBuckets = {
    OPEN: 'Open',
    PENDING: 'Pending',
    ACTIVE: 'Active',
    RENEWING: 'Renewing',
    MANUAL: 'Manual',
    BIND_REQUEST: 'Bind_Request',
};

export const SignatureContactType = {
    INSURED_CLIENT: 'insured_client',
    RETAIL_PARTNER: 'retail_partner',
};

export const LostSubmissionReasons = {
    QUOTED_FOR_TOO_LONG: '60 Days in Quoted',
    CLOSE_TO_EFFECTIVE_DATE: '7 Days after Effective Date',
    STAYED_WITH_INCUMBENT_BROKER: 'Stayed with Incumbent Broker/Carrier',
    NO_RESPONSE_FROM_BROKER: 'No Response from Broker',
    PRICING: 'Pricing',
    COVERAGE: 'Coverage',
    OTHER: 'Other',
};

export const CreateSubmissionFormType = {
    QUOTE: 'quote',
    SECURITY_SCORE_REPORT: 'security_score_report',
    BREACH_CALCULATOR: 'breach_calculator',
};

export const QuoteStatus = {
    CREATED: 'CREATED',
    AQ_CREATED: 'AQ_CREATED',
    CALCULATING: 'CALCULATING',
    CALC_COMPLETED: 'CALC_COMPLETED',
    BROKER_CONFLICT_REFERRAL: 'BROKER_CONFLICT_REFERRAL',
    COLLECTION_ISSUE: 'COLLECTION_ISSUE',
    RED_FLAGS_FOUND: 'RED_FLAGS_FOUND',
    CALC_FAILED: 'CALC_FAILED',
    AUTHORITY_FAILED: 'AUTHORITY_FAILED',
    ELIGIBLE: 'ELIGIBLE',
    QUICK_SCAN_ERROR: 'QUICK_SCAN_ERROR',
    MISSING_QUICK_SCAN: 'MISSING_QUICK_SCAN',
    STALE: 'STALE',
    isInProgress: function(quoteStatus) {
        return [
            this.CREATED,
            this.AQ_CREATED,
            this.CALCULATING,
            this.CALC_COMPLETED,
            this.ELIGIBLE,
            this.RED_FLAGS_FOUND,
        ].includes(quoteStatus);
    },
};

export const AnswersValuesType = {
    FREE_TEXT_ANSWER: 'FreeTextAnswer',
    NUMBER_ANSWER: 'NumberAnswer',
    SELECTION_ANSWER: 'SelectionAnswer',
    INTEGER_ANSWER: 'IntegerAnswer',
};

export const ValueType = {
    FREE_TEXT: 'FREE_TEXT',
    ADDRESS: 'ADDRESS',
    DOMAIN: 'DOMAIN',
    NUMBER: 'NUMBER',
    INTEGER: 'INTEGER',
    BOOLEAN: 'BOOLEAN',
    NOTICE: 'NOTICE',
    SINGLE_CHOICE: 'SINGLE_CHOICE',
    REVENUE_QUESTION: 'REVENUE_QUESTION',
    MULTIPLE_CHOICES: 'MULTIPLE_CHOICES',
    MULTIPLE_CHOICES_WITH_NONE: 'MULTIPLE_CHOICES_WITH_NONE',
    MULTIPLE_CHOICES_WITHOUT_NONE: 'MULTIPLE_CHOICES_WITHOUT_NONE',
    MULTIPLE_CHOICES_WITH_SEARCH_BAR: 'MULTIPLE_CHOICES_WITH_SEARCH_BAR',
    COMPANY_NAME: 'COMPANY_NAME',
};

export const NodeType = {
    SECTION: 'section',
    DATAPOINT: 'datapoint',
    NOTE: 'note',
};

export const SignUpProgressStep = {
    CREATE_ACCOUNT: 'create_account',
    USER_EXISTS: 'user_exists',
    SELECT_AGENCY: 'select_agency',
    NEW_AGENCY: 'new_agency',
    NEW_LOCATION: 'new_location',
    CONFIRMATION: 'confirmation',
};

export const RenewalType = {
    MANUAL: 'MANUAL',
    PER_EXPIRING: 'PER_EXPIRING',
    ENHANCED: 'ENHANCED',
    SOLICITATION_MANUAL: 'SOLICITATION_MANUAL',
    isAuto: function(renewalType) {
        return [this.PER_EXPIRING, this.ENHANCED].includes(renewalType);
    },
};

export const AggregateType = {
    LIMIT: 'limit',
    RETENTION: 'retention',
};

export const LineItemType = {
    LINE_ITEM: 'line_item',
    WAITING_PERIOD: 'waiting_period',
    PERIOD_OF_INDEMNITY: 'period_of_indemnity',
};

export const LineItemCode = {
    SF_SEC: 'sf_sec',
    FF_SEC: 'ff_sec',
};

export const MplRetroactiveDateType = {
    POLICY_INCEPTION: 'POLICY_INCEPTION',
    FULL_PRIOR_ACTS: 'FULL_PRIOR_ACTS',
    TO_MATCH_EXPIRING: 'TO_MATCH_EXPIRING',
};

export const QuestionsTypes = {
    FREE_TEXT_QUESTION: ['FREE_TEXT', 'ADDRESS', 'DOMAIN', 'NUMBER', 'INTEGER', 'REVENUE_QUESTION', 'COMPANY_NAME'],
    SINGLE_CHOICE_QUESTION: ['BOOLEAN', 'SINGLE_CHOICE'],
    MULTI_SELECT_QUESTION: ['MULTIPLE_CHOICES', 'MULTIPLE_CHOICES_WITH_SEARCH_BAR'],
};

export const ViewablePolicyType = {
    CURRENT_POLICY: 'CURRENT_POLICY',
    UPON_RENEWAL: 'UPON_RENEWAL',
    PAST_POLICY: 'PAST_POLICY',
};

export const PolicyType = {
    RENEWAL: 'RENEWAL',
    NEW_BUSINESS: 'NEW_BUSINESS',
};

export const NoteValidation = {
    MAX_CHARACTERS: 3040,
};

export const BrokerRole = {
    WHOLESALE: 'wholesale',
    RETAIL: 'retail',
    SECONDARY: 'secondary',
    RETAIL_NO_SUBMISSION: 'retail_no_submission',
};
