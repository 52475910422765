import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    AddAnswerActionPayloadType,
    AddVisibleQuestionActionPayloadType,
    QuestionnaireState,
    RemoveAnswerActionPayloadType,
    RemoveVisibleQuestionActionPayloadType,
    SetPolicyRequestActionPayloadType,
    SetNumberOfAnswersActionPayloadType,
    SetNumberOfQuestionsActionPayloadType,
    SetProductActionPayloadType,
    SetQuestionMetadataActionPayloadType,
    SetQuestionsAnsweredActionPayloadType,
    SetQuestionsMetadataActionPayloadType,
    SetSectionsDataActionPayloadType
} from './types';

const initialState: QuestionnaireState = {
    sectionsData: undefined,
    numberOfQuestions: undefined,
    visibleDatapointIDs: [],
    questionsAnswered: [],
    numberOfAnswers: undefined,
    textToDisplay: 'All changes saved',
    isCurrentlySavingAnswer: false,
    questionsMetadata: [],
    product: undefined,
    policyRequest: undefined,
    questionsToOmitFromCount: {}
};

const questionnaireSlice = createSlice({
    name: 'questionnaire',
    initialState,
    reducers: {
        setSectionsData(state, action: PayloadAction<SetSectionsDataActionPayloadType>) {
            state.sectionsData = action.payload;
        },
        setQuestionnaireStateToAnswerChanging(state) {
            state.textToDisplay = 'Answer changed...';
            state.isCurrentlySavingAnswer= false;
        },
        setQuestionnaireStateToChangesSaved(state) {
            state.textToDisplay = 'All changes saved';
            state.isCurrentlySavingAnswer= false;
        },
        setQuestionnaireStateToSavingAnswer(state) {
            state.textToDisplay = 'Saving answer...';
            state.isCurrentlySavingAnswer= true;
        },
        setQuestionnaireStateToLocked(state) {
            state.textToDisplay = 'Application locked';
            state.isCurrentlySavingAnswer= false;
        },
        setInitialState(state) {
            state.sectionsData = undefined;
            state.numberOfAnswers = undefined;
            state.visibleDatapointIDs = [];
            state.questionsAnswered = [];
            state.numberOfAnswers = undefined;
            state.textToDisplay = 'All changes saved';
            state.isCurrentlySavingAnswer= false;
            state.product = undefined;
            state.policyRequest = undefined;
            state.questionsToOmitFromCount = {}

        },
        addVisibleQuestion(state, action: PayloadAction<AddVisibleQuestionActionPayloadType>) {
            const datapointsIDs: string[] = [...state.visibleDatapointIDs];
            let datapointId: string;
            let omitFromCount: boolean = false;

            if (typeof action.payload === 'string') {
                datapointId = action.payload
            } else {
                datapointId = action.payload.datapointId;
                omitFromCount = action.payload.omitFromCount ? action.payload.omitFromCount : omitFromCount;
            }

            if (omitFromCount && !(datapointId in state.questionsToOmitFromCount)) {
                state.questionsToOmitFromCount[datapointId] = omitFromCount;
            }

            const datapointID = datapointsIDs.find(id => id === datapointId);
            if (!datapointID) {
                datapointsIDs.push(datapointId);
            }
            state.visibleDatapointIDs = datapointsIDs;
            state.numberOfQuestions = datapointsIDs.filter(id => !(id in state.questionsToOmitFromCount)).length;
        },
        removeVisibleQuestion(state, action: PayloadAction<RemoveVisibleQuestionActionPayloadType>) {
            const datapointsIDs = [...state.visibleDatapointIDs];
            const index = datapointsIDs.findIndex(id => id === action.payload);
            if (index !== -1) {
                datapointsIDs.splice(index, 1);
                state.visibleDatapointIDs = datapointsIDs;
                state.numberOfQuestions = datapointsIDs.filter(id => !(id in state.questionsToOmitFromCount)).length;
            }
        },
        addAnswer(state, action: PayloadAction<AddAnswerActionPayloadType>) {
            const answers: string[] = [...state.questionsAnswered];
            let datapointId: string;
            let omitFromCount: boolean = false;

            if (typeof action.payload === 'string') {
                datapointId = action.payload
            } else {
                datapointId = action.payload.datapointId;
                omitFromCount = action.payload.omitFromCount ? action.payload.omitFromCount : omitFromCount;
            }

            if (omitFromCount && !(datapointId in state.questionsToOmitFromCount)) {
                state.questionsToOmitFromCount[datapointId] = omitFromCount;
            }

            const answer = answers.find(id => id === datapointId);
            if (!answer) {
                answers.push(datapointId);
            }
            state.questionsAnswered = answers;
            state.numberOfAnswers = answers.filter(id => !(id in state.questionsToOmitFromCount)).length;
        },
        removeAnswer(state, action: PayloadAction<RemoveAnswerActionPayloadType>) {
            const answers = [...state.questionsAnswered];
            const index = answers.findIndex(id => id === action.payload);
            if (index !== -1) {
                answers.splice(index, 1);
                state.questionsAnswered = answers;
                state.numberOfAnswers = answers.filter(id => !(id in state.questionsToOmitFromCount)).length;
            }
        },
        setNumberOfQuestions(state, action: PayloadAction<SetNumberOfQuestionsActionPayloadType>) {
            state.numberOfQuestions = action.payload;
        },
        setNumberOfAnswers(state, action: PayloadAction<SetNumberOfAnswersActionPayloadType>) {
            state.numberOfAnswers = action.payload;
        },
        setQuestionsAnswered(state, action: PayloadAction<SetQuestionsAnsweredActionPayloadType>) {
            state.questionsAnswered = action.payload
        },
        setQuestionsMetadata(state, action: PayloadAction<SetQuestionsMetadataActionPayloadType>) {
            state.questionsMetadata = action.payload;
        },
        setQuestionMetadata(state, action: PayloadAction<SetQuestionMetadataActionPayloadType>) {
            const {questionId, metadataToUpdate} = action.payload;
            const question = state.questionsMetadata.find(data => data.questionId === questionId);
            if (question === undefined) {
                const newQuestionError = {questionId: questionId, metadata: metadataToUpdate}
                const updatedQuestionsMetadata = [...state.questionsMetadata]
                updatedQuestionsMetadata.push(newQuestionError)
                state.questionsMetadata = updatedQuestionsMetadata;
            } else {
                question.metadata = {...question.metadata, ...metadataToUpdate };
            }
        },
        setProduct(state, action: PayloadAction<SetProductActionPayloadType>) {
            state.product = action.payload;
        },
        setPolicyRequest(state, action: PayloadAction<SetPolicyRequestActionPayloadType>) {
            state.policyRequest = action.payload;
        }
    },
});

export const {
    setSectionsData,
    setInitialState,
    addVisibleQuestion,
    removeVisibleQuestion,
    addAnswer,
    removeAnswer,
    setNumberOfQuestions,
    setNumberOfAnswers,
    setQuestionsAnswered,
    setQuestionsMetadata,
    setQuestionMetadata,
    setQuestionnaireStateToAnswerChanging,
    setQuestionnaireStateToChangesSaved,
    setQuestionnaireStateToSavingAnswer,
    setQuestionnaireStateToLocked,
    setProduct,
    setPolicyRequest
} = questionnaireSlice.actions;

export default questionnaireSlice.reducer;
