import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAxiosClient } from 'hooks';
import { CreateSubmissionFormType } from 'utils/enums';


export const useResubmitPolicyRequest = (company, policyRequest) => {
    const axiosClient = useAxiosClient();
    const history = useHistory();

    const [, createPolicyRequest] = axiosClient({
        method: 'post',
        url: '/company/create_submission',
    }, { manual: true });

    const resubmitPolicyRequest = useCallback(() => {
        const resubmitAndDirect = async () => {
            const res = await createPolicyRequest({
                data: {
                    company_id: company.id,
                    product_id: policyRequest.product.id,
                    url: company.domain,
                    company_name: company.name,
                    form_name: CreateSubmissionFormType.QUOTE,
                },
            });
            history.push({pathname: `/questionnaire/policy/${res.data.policy_request_id}`, state: {resubmit: true}});
        }
        resubmitAndDirect();

    }, [company, policyRequest]);
    
    return resubmitPolicyRequest;
};