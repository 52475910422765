import React, { FC } from 'react';
import { StyledBox } from 'components/index';
import { Typography } from '@material-ui/core';

interface Props {
    commissionPercentageString: string;
}

const CommissionTable: FC<Props> = ({ commissionPercentageString }) => {
    return (
        <StyledBox.MobilePolicyAndCommissionBox>
            <Typography variant="h6">Commission</Typography>
            <Typography variant="h6">{commissionPercentageString}</Typography>
        </StyledBox.MobilePolicyAndCommissionBox>
    );
};

export default CommissionTable;
