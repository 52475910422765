import React from 'react';
import { StyledDivider } from 'components';
import CoverIcons from '../cover/CoverIcons';
import Styled from './styled-components';
import { Hidden } from '@material-ui/core';
import { Logo } from 'components/icons/logo';

const MaintenanceMode = () => {
    return (
        <Styled.FormContainer>
            <Hidden smDown>
                <Styled.LogoContainer>
                    <Logo />
                </Styled.LogoContainer>
            </Hidden>
            <Styled.FormHeader>
                System Upgrades
                <br /> In Progress
            </Styled.FormHeader>
            <Styled.FormTextBold variant="subtitle2">We apologize for the temporary inconvenience.</Styled.FormTextBold>
            <Styled.FormText variant="subtitle2">
                At-Bay is implementing upgrades to improve your quoting experience; we’ll be back online Tuesday,
                January 19th. If you need immediate assistance, you can contact our underwriters at
            </Styled.FormText>
            <Styled.EmailLink>
                <a href="mailto:underwriting@at-bay.com">underwriting@at-bay.com</a>.
            </Styled.EmailLink>
            <Hidden mdUp>
                <StyledDivider.Divider />
                <CoverIcons />
            </Hidden>
        </Styled.FormContainer>
    );
};

export default MaintenanceMode;
