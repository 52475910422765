import { BucketSubBucketType, BucketType, State, SubBucketType } from '../types';
import { useReducer } from 'react';
import { FIRST_PAGE } from '../consts';
import produce from 'immer';

type NextPageActionPayload = {
    type: 'next';
    payload: { bucketSubBucket: BucketSubBucketType; nextCompanyId: number };
};

type PrevPageActionPayload = {
    type: 'prev';
    payload: { bucketSubBucket: BucketSubBucketType };
};

type Action = NextPageActionPayload | PrevPageActionPayload;

const reducer = produce((draft: State, action: Action) => {
    switch (action.type) {
        case 'next': {
            const { bucketSubBucket, nextCompanyId } = action.payload;
            draft[bucketSubBucket].idsHistory = Array.from(
                new Set(draft[bucketSubBucket].idsHistory.concat(nextCompanyId)),
            );
            draft[bucketSubBucket].currentPage += 1;
            break;
        }
        case 'prev': {
            const { bucketSubBucket } = action.payload;
            if (draft[bucketSubBucket].currentPage !== FIRST_PAGE) {
                draft[bucketSubBucket].currentPage -= 1;
            }
            break;
        }
    }
});

export const usePagination = (initialState: State, bucket: BucketType, subBucket: SubBucketType) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const currentPageState = state[(bucket + subBucket) as BucketSubBucketType];
    const currentPageNextClientIdIndex = state[(bucket + subBucket) as BucketSubBucketType].currentPage - 1;

    const handleNextButtonClick = (nextClientId: number) => {
        dispatch({
            type: 'next',
            payload: {
                bucketSubBucket: (bucket + subBucket) as BucketSubBucketType,
                nextCompanyId: nextClientId,
            },
        });
    };

    const handlePrevButtonClick = () => {
        dispatch({
            type: 'prev',
            payload: { bucketSubBucket: (bucket + subBucket) as BucketSubBucketType },
        });
    };

    return {
        currentPage: currentPageState.currentPage,
        nextClientId: currentPageState.idsHistory[currentPageNextClientIdIndex],
        handleNextButtonClick,
        handlePrevButtonClick,
    };
};
