import React from 'react';
import { useEnv } from 'hooks';
import Signature from './components/Signature';
import EditApplication from './components/EditApplication';
import NextSteps from './index';
import Contingencies from './components/Contingencies';
import { PermissionDenied, PermissionGranted, PermissionRequired } from '../../permission-required';
import { Permissions } from '../../../utils/enums';


const QuotedNextSteps = ({ policyRequest }) => {
    const { editApplication } = useEnv();
    const hasOpenContingencies = policyRequest.contingencies.some(contingency => !contingency.checked)

    return (
        <NextSteps>
            <PermissionRequired requiredPermissions={[Permissions.SUBMISSION_WRITE]}>
                <PermissionGranted>
                    {editApplication && <EditApplication policyRequest={policyRequest} />}
                </PermissionGranted>
                <PermissionDenied>
                    <></>
                </PermissionDenied>
            </PermissionRequired>
            <Signature policyRequest={policyRequest} />
            {hasOpenContingencies && <Contingencies policyRequest={policyRequest} />}
        </NextSteps>
    );
};

export default QuotedNextSteps;
