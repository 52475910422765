import React from 'react';
import Styled from './styled-components';

import { ReactComponent as InsuranceCoverageIcon } from 'components/icons/insurance-coverage.svg';
import { ReactComponent as RiskManagementIcon } from 'components/icons/risk-management.svg';
import { ReactComponent as BrokerToolsIcon } from 'components/icons/broker-tools.svg';

const CoverIcons = () => {
    return (
        <Styled.CoverIconsContainer>
            <Styled.ItemContainer>
                <Styled.IconContainer>
                    <InsuranceCoverageIcon />
                </Styled.IconContainer>
                <Styled.TextContainer>
                    <Styled.IconTitle variant="subtitle2">Instant Quotes</Styled.IconTitle>
                    <Styled.IconText variant="body2">
                        Quote and bind policies in seconds, with fully customizable sub limits and sub retentions.
                    </Styled.IconText>
                </Styled.TextContainer>
            </Styled.ItemContainer>

            <Styled.ItemContainer>
                <Styled.IconContainer>
                    <RiskManagementIcon />
                </Styled.IconContainer>
                <Styled.TextContainer>
                    <Styled.IconTitle variant="subtitle2">Clear, Actionable Security Insights</Styled.IconTitle>
                    <Styled.IconText variant="body2">
                        Clear and actionable security insights to help your client avoid loss.
                    </Styled.IconText>
                </Styled.TextContainer>
            </Styled.ItemContainer>

            <Styled.ItemContainer>
                <Styled.IconContainer>
                    <BrokerToolsIcon />
                </Styled.IconContainer>
                <Styled.TextContainer>
                    <Styled.IconTitle variant="subtitle2">Give Your Retailers Self-Service Access</Styled.IconTitle>
                    <Styled.IconText variant="body2">
                        Send retailers a link to your custom-branded application to save time on data input
                    </Styled.IconText>
                </Styled.TextContainer>
            </Styled.ItemContainer>
        </Styled.CoverIconsContainer>
    );
};

export default CoverIcons;
