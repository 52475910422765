import React from 'react';
import { makeStyles, Radio } from '@material-ui/core';
import { DynamicIcon } from 'components';
import { theme } from 'components/theme/styles';

const useStyles = makeStyles({
    root: {
        padding: theme.spacing(0),
    },
    checked: {},
});

const CustomRadio = ({ meta, variant, ...rest }) => {
    const classes = useStyles();
    return (
        <Radio
            color="default"
            {...rest}
            className={classes.root}
            icon={<DynamicIcon name="radio" typographyProps={{ variant: variant}} />}
            checkedIcon={<DynamicIcon name="checked-radio" typographyProps={{ variant: variant}} />}
        />
    );
};

export default CustomRadio;
