import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    QuestionsErrorsState,
    SetNewQuestionDataActionPayloadType,
    SetQuestionDataActionPayloadType,
    SetQuestionsSequenceDataActionPayloadType,
    SetSubmitFailedActionPayloadType,
    UpdateQuestionsSequenceDataActionPayloadType
} from './types';
import {QuestionsSequenceData} from '../../types/questionsSequence';

const initialState: QuestionsErrorsState = {
    questionsSequenceData: undefined,
    firstQuestionWithError: undefined,
    submitFailed: false,
};

const questionsErrorsSlice = createSlice({
    name: 'questionErrors',
    initialState,
    reducers: {
        setQuestionsSequenceData(state, action: PayloadAction<SetQuestionsSequenceDataActionPayloadType>) {
            state.questionsSequenceData = action.payload;
        },
        updateQuestionsSequenceData(state, action: PayloadAction<UpdateQuestionsSequenceDataActionPayloadType>) {
            if (state.questionsSequenceData) {
                let updatedQuestionsSequenceData = [...state.questionsSequenceData];
                const questions = action.payload;
                for (const question of questions) {
                    if (!updatedQuestionsSequenceData.find(data => data.questionId === question.questionId)) {
                        updatedQuestionsSequenceData.push(question);
                    }
                }
                for (const question of questions) {
                    if (!questions.find(data => data.questionId === question.questionId)) {
                        updatedQuestionsSequenceData = updatedQuestionsSequenceData.filter(
                            item => item.questionId !== question.questionId,
                        );
                    }
                }
                state.questionsSequenceData = updatedQuestionsSequenceData;
            }
        },
        setQuestionData(state, action: PayloadAction<SetQuestionDataActionPayloadType>) {
            const {datapointID, hasError} = action.payload;
            const question = state.questionsSequenceData?.find(data => data.datapointID === datapointID) as QuestionsSequenceData;
            question.hasError = hasError;
            const firstError = state.questionsSequenceData?.find(data => data.hasError);
            state.firstQuestionWithError = firstError?.datapointID;

        },
        setNewQuestionData(state, action: PayloadAction<SetNewQuestionDataActionPayloadType>) {
            const {questionId, hasError} = action.payload;
            const question = state.questionsSequenceData?.find(data => data.questionId === questionId);
            if (question === undefined) {
                const newQuestionError = {questionId, hasError};
                const updatedQuestionsSequenceData = state.questionsSequenceData ? [...state.questionsSequenceData] : [];
                updatedQuestionsSequenceData.push(newQuestionError);
                state.questionsSequenceData = updatedQuestionsSequenceData;
            } else {
                question.hasError = hasError;
                const firstError = state.questionsSequenceData?.find(data => data.hasError);
                state.firstQuestionWithError = firstError?.questionId;
            }
        },
        setSubmitFailed(state, action: PayloadAction<SetSubmitFailedActionPayloadType>) {
            state.submitFailed = action.payload;
        },
    },
});

export const {
    setQuestionsSequenceData,
    updateQuestionsSequenceData,
    setQuestionData,
    setNewQuestionData,
    setSubmitFailed,
} = questionsErrorsSlice.actions;

export default questionsErrorsSlice.reducer;
