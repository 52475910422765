import { Hidden } from '@material-ui/core';
import React from 'react';
import { RenewalType } from 'utils/enums';


const ProductText = ({ policyRequest }) => {
    return (
        <>
            {RenewalType.isAuto(policyRequest?.renewal_type.name)
                ? (
                    <>Rapid renewal will automatically renew</>
                ) : (
                    <>
                        <Hidden smDown>
                            Renewal quote is ready
                        </Hidden>
                        <Hidden mdUp>
                            Quote is ready
                        </Hidden>
                    </>
                )
            }
        </>
    );
};


export default ProductText;
