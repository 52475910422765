import React from 'react';
import { ColoredLinearProgress } from 'components';


const InProgress = ({ policyRequest }) => {
    return (
        <ColoredLinearProgress color={policyRequest.product.background_color}/>
    )
}

export default InProgress;
