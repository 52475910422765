import React, { FC } from 'react';
import { Box, TableRow } from '@material-ui/core';
import { StyledTable } from 'components';
import { useBreakpoint } from 'hooks';

export const TableDivider: FC = () => {
    const isMd = useBreakpoint('down', 'md');

    return (
        <TableRow>
            <StyledTable.TableCell colSpan={isMd ? 4 : 5}>
                <Box borderBottom="2px solid #E0E0E0" mt={2} mb={2} />
            </StyledTable.TableCell>
        </TableRow>
    );
};

export const TableDotDivider: FC = () => {
    const isMd = useBreakpoint('down', 'md');

    return (
        <TableRow>
            <StyledTable.TableCell colSpan={isMd ? 4 : 5}>
                <Box borderBottom="3px dotted #E0E0E0" mt={2} mb={0} />
            </StyledTable.TableCell>
        </TableRow>
    );
};

export const PolicyPageTableDotDivider: FC = () => {
    const isMd = useBreakpoint('down', 'md');

    return (
        <TableRow>
            <StyledTable.TableCell colSpan={isMd ? 4 : 5}>
                <Box borderBottom="3px dotted #E0E0E0" mt={0} ml={5} mr={6} />
            </StyledTable.TableCell>
        </TableRow>
    );
};
