import React, { useEffect, useState, useRef } from 'react';
import { useBreakpoint } from 'hooks';
import { Box, Typography, FormControl, RadioGroup } from '@material-ui/core';
import GeneralStyled from '../components/styled-components';
import Markdown from 'markdown-to-jsx';
import InfoTooltip from '../components/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setQuestionValue } from 'stores/questions/questionsSlice';
import { conditionedNodeNeedToBeVisible } from 'utils/questionsConditions';
import { questionHasError, scrollToQuestionError } from 'utils/questionnaireSequence';
import { addAnswer, addVisibleQuestion, removeAnswer, removeVisibleQuestion } from 'stores/questionnaire/questionnaireSlice';
import { setQuestionData } from 'stores/questionsErrors/questionsErrorsSlice';
import CustomRadio from 'components/radio/CustomRadio';


const SingleChoiceQuestion = ({ question, updateForm, setScroll, scroll, setChangeMade }) => {
    const questionRef = useRef(null);
    const dispatch = useDispatch();
    const { questionsToAnswers } = useSelector(state => state.questions);
    const { sectionsData } = useSelector(state => state.questionnaire);
    const answer = useSelector(state => state.questions?.questionsToAnswers[question.datapoint.datapoint_id]);
    const { firstQuestionWithError, submitFailed } = useSelector(state => state.questionErrors);
    const isSm = useBreakpoint('down', 'sm');
    const isLg = useBreakpoint('up', 'lg');
    const [visible, setVisible] = useState(
        !question.datapoint.display_condition ||
            conditionedNodeNeedToBeVisible(question.datapoint.display_condition, sectionsData, questionsToAnswers)
    );
    const [value, setValue] = useState(answer || '');
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(questionHasError(submitFailed, answer, question.datapoint.required, visible));

    useEffect(() => {
        if (question.datapoint.display_condition) {
            setVisible(
                conditionedNodeNeedToBeVisible(question.datapoint.display_condition, sectionsData, questionsToAnswers),
            );
        }
    }, [questionsToAnswers]);

    useEffect(() => {
        dispatch(
            setQuestionData({
                datapointID: question.datapoint.datapoint_id,
                hasError: questionHasError(submitFailed, answer, question.datapoint.required, visible),
            }),
        );
    }, [submitFailed]);

    useEffect(() => {
        if (scroll && firstQuestionWithError === question.datapoint.datapoint_id) {
            scrollToQuestionError(questionRef);
            setScroll(false);
        }
        if (!scroll) {
            setError(questionHasError(submitFailed, answer, question.datapoint.required, visible));
        }
    }, [firstQuestionWithError, scroll]);

    useEffect(() => {
        dispatch(
            setQuestionData({
                datapointID: question.datapoint.datapoint_id,
                hasError: questionHasError(submitFailed, answer, question.datapoint.required, visible),
            }),
        );
        if (visible) {
            if (question.datapoint.required || value) {
                dispatch(addVisibleQuestion(question.datapoint.datapoint_id));
            }
            if (value) {
                dispatch(addAnswer(question.datapoint.datapoint_id));
            }
        } else {
            dispatch(removeVisibleQuestion(question.datapoint.datapoint_id));
            dispatch(removeAnswer(question.datapoint.datapoint_id));
        }
    }, [visible]);

    const handleChange = e => {
        setValue(e.target.value);
        setError(false);
        dispatch(setQuestionData({datapointID: question.datapoint.datapoint_id, hasError: false}));
        dispatch(setQuestionValue({ name: question.datapoint.datapoint_id, value: e.target.value }));
        setChangeMade(true);
        updateForm(question.datapoint.datapoint_id, e.target.value, false, question.datapoint.required);
    };

    return (
        visible && (
            <Box ref={questionRef} display="flex" px={isSm ? 4 : 0} key={`${question.datapoint.id}_question`} pb={0.5} justifyContent="center">
                <FormControl fullWidth style={{maxWidth: isSm ? 'unset' : '424px' }} error={error}>
                    <Box component={GeneralStyled.FormLabel} display="flex" alignItems="center">
                        <Box component={Typography} variant="body1">
                            <Markdown>{question.datapoint.text_to_display}</Markdown>
                            {question.datapoint.application_parameters.help_text && (
                                <InfoTooltip
                                    dataTrack={`questionnaire_tooltip_${question.datapoint.datapoint_id}`}
                                    placement="bottom"
                                    open={open}
                                    setOpen={setOpen}
                                    text={question.datapoint.application_parameters.help_text}
                                    select={true}
                                />
                            )}
                        </Box>
                    </Box>
                    <RadioGroup
                        onChange={e => handleChange(e)}
                        value={value}
                        data-track={`questionnaire_question_${question.datapoint.datapoint_id}`}>
                        {question.choices.map(choice => (
                            <GeneralStyled.FormControlLabel
                                // onClick functions handled ticket https://cyberjack.atlassian.net/browse/BP-2256
                                onClick={e => e.preventDefault()}
                                key={choice.string_id}
                                label={
                                    <Typography
                                        variant="body1"
                                        style={{ paddingBottom: isLg ? 1 : 0, paddingTop: isLg ? 0 : 3, paddingLeft: 12 }}
                                        onClick={e => e.stopPropagation()}>
                                        <Box component="span" color={answer === choice.string_id ? '#191919' : '#696969'}>
                                            {choice.choice_value}
                                        </Box>
                                    </Typography>
                                }
                                control={<CustomRadio variant={isLg ? 'subtitle2' : 'h4'} onClick={e => e.stopPropagation()}/>}
                                value={choice.string_id}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </Box>
        )
    );
};

export default SingleChoiceQuestion;
