import React from 'react';
import { IFrame } from 'components';
import { useEnv } from 'hooks';
import Box from '@material-ui/core/Box';

const Builder = () => {
    const { apiBaseUrl } = useEnv();
    const url = `${apiBaseUrl}/questionnaire/builder`;

    return <Box pt={4} height="100%"><IFrame src={url} /></Box>;
};

export default Builder;
