import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { RetailPartners } from './pages';
import { Error } from 'components';
import { RetailPartnersContextProvider } from './pages/retail-partners/context/RetailPartnersContext';

const PartnershipManagement = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}/retail_partners`}>
                <RetailPartnersContextProvider>
                    <RetailPartners />
                </RetailPartnersContextProvider>
            </Route>
            <Route>
                <Error errorStatus="404" />
            </Route>
        </Switch>
    );
};

export default PartnershipManagement;
