import React from 'react';
import { useEnv } from 'hooks';
import EditApplication from './components/EditApplication';
import QuestionsAndUpdates from './components/QuestionsAndUpdates';
import NextSteps from './index';

const RenewalQuotedNextSteps = ({ policyRequest }) => {
    const { editApplication } = useEnv();
    return (
        <NextSteps text="REVIEW DETAILS">
            {editApplication && <EditApplication policyRequest={policyRequest} />}
            <QuestionsAndUpdates policyRequest={policyRequest} />
        </NextSteps>
    );
};

export default RenewalQuotedNextSteps;
