import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { isAxa } from 'components/icons/logo';

const theme = createMuiTheme({
    palette: {
        text: {
            primary: '#1A1A1A',
        },
        primary: {
            main: isAxa ? '#00008F' : '#2020EE',
            dark: isAxa ? '#00008F' : '#0006E4',
            light: '#522FDF',
            contrastText: '#FFF'
        },
        secondary: {
            main: '#FFEB00',
            dark: '#FFD100',
            light: '#F0F234',
            contrastText: '#1A1A1A'
        },
        info: {
            main: '#5583F7',
            dark: isAxa ? '#00008F' : '#0006E4',
            light: '#522DFD',
            contrastText: '#FFF'
        }
    },
    typography: {
        fontFamily: [
            'America'
        ].join(','),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
    },
    spacing: 8,
    breakpoints: {
        values: {
            sm: 0,
            md: 768,
            container: 960,
            lg: 1024,
        }
    }
});

theme.overrides = {
    MuiPickersDay: {
        day: {
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightLight,
        }
    },
    MuiIcon: {
        fontSizeSmall: {
            fontSize: 'inherit'
        },
        root: {
            fontSize: 'inherit',
        },
    },
    MuiSvgIcon: {
        fontSizeSmall: {
            fontSize: 'inherit'
        },
        root: {
            fontSize: 'inherit',
        },
    },
    MuiDialogTitle: {
        root: {
            zIndex: 1
        }
    },
    MuiDialogActions: {
        root: {
            zIndex: 1
        }
    },
}

theme.typography.h1 = {
    fontSize: '42px',
    fontWeight: 'unset',
    [theme.breakpoints.down('md')]: {
        fontSize: '36px'
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '32px'
    }
}

theme.typography.h2 = {
    fontSize: '36px',
    fontWeight: 'unset',
    [theme.breakpoints.down('md')]: {
        fontSize: '28px'
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '26px'
    }
}

theme.typography.h3 = {
    fontSize: '32px',
    fontWeight: 'unset',
    [theme.breakpoints.down('md')]: {
        fontSize: '26px'
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '24px'
    }
}

theme.typography.h4 = {
    fontSize: '28px',
    fontWeight: 'unset',
    [theme.breakpoints.down('md')]: {
        fontSize: '24px'
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '22px'
    }
}

theme.typography.h5 = {
    fontSize: '24px',
    fontWeight: 'unset',
    [theme.breakpoints.down('md')]: {
        fontSize: '22px'
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '20px'
    }
}

theme.typography.h6 = {
    fontSize: '20px',
    fontWeight: 'unset',
    [theme.breakpoints.down('md')]: {
        fontSize: '20px'
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '18px'
    }
}

theme.typography.subtitle1 = {
    fontSize: '18px',
    fontWeight: 'unset',
    [theme.breakpoints.down('md')]: {
        fontSize: '18px'
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '16px'
    }
}

theme.typography.subtitle2 = {
    fontSize: '16px',
    fontWeight: 'unset',
    [theme.breakpoints.down('md')]: {
        fontSize: '16px'
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '14px'
    }
}

theme.typography.body1 = {
    fontSize: '14px',
    fontWeight: 'unset',
    [theme.breakpoints.down('md')]: {
        fontSize: '14px'
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
    }
}

theme.typography.body2 = {
    fontSize: '12px',
    fontWeight: 'unset',
    fontFamily: 'unset',
    [theme.breakpoints.down('md')]: {
        fontSize: '12px'
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '10px'
    }
}

theme.typography.caption = {
    fontSize: '10px',
    fontWeight: 'unset',
    [theme.breakpoints.down('md')]: {
        fontSize: '10px'
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '8px'
    }
}

theme.typography.overline = {
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'Pressura',
    letterSpacing: '1.8px',
    [theme.breakpoints.down('md')]: {
        fontSize: '12px'
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '10px'
    }
}

export { theme };
