import styled from 'styled-components';
import { StyledLink } from 'components';

const Link = styled(StyledLink.Anchor)`
    &&& {
        text-decoration: underline;
    }
`;

const H3 = styled.h3`
    &&& {
        font-family: Pressura;
        color: ${({ theme }) => theme.palette.primary.main};
        font-weight: 400;
        text-align: center;
        margin-bottom: 0px;
        font-size: 15px;
    }
`;

export default {
    H3,
    Link,
};
