import {
    CompaniesBuckets,
    PoliciesSubBuckets,
    QuotesSubBuckets,
} from 'modules/company/pages/dashboard/components/companies-pagination/types';

export const queryKeys = {
    BUCKETS: { ...CompaniesBuckets },
    SUB_BUCKETS: {
        ...PoliciesSubBuckets,
        ...QuotesSubBuckets,
    },
    SNAPSHOT: 'Snapshot',
};
