import { getOptionToQuoteQuery } from './optionToQuote';

export const basicPolicyRequestQuery = {
    id: {},
    company_id: {},
    broker_submitted: {},
    product: {
        name: {},
        background_color: {},
        text_color: {},
        abbreviated_name: {},
        id: {},
        is_support_no_domain: {},
    },
    athena_submissions_bucket: {},
    renewal_type: {},
    is_questionnaire_support_no_domain: {},
    is_no_domain_policy: {},
};

export const partialPolicyRequestQuery = {
    ...basicPolicyRequestQuery,
    broker_user: {username: {}},
    broker_submitted: {},
    athena_submissions_bucket: {},
    latest_scan: {
        start_time_unix: {},
        completed_successfully: {},
    },
    quotes_from_auto_quote: {
        option_to_quote: {
            published: {},
        },
        latest_status: {},
    },
    last_option_to_quote_publish_date: {},
    last_submitted_at: {},
    type: {},
};

export const getPolicyRequestQuery = ({ includeOptionToQuotes }) => {
    const query = {
        ...partialPolicyRequestQuery,
        broker_user: {
            id: {},
            username: {},
        },
        commission:{},
        company_name: {},
        questionnaire_id: {},
        is_deletable: {},
        uw_name: {},
        uw_email: {},
        uw_phone: {},
        uw_user: {
            id: {},
        },
        signed_at: {},
        for_signature_pdf_file_id: {},
        conditionally_bound: {},
        effective_date: {},
        expiration_date: {},
        continuity_date: {},
        prior_and_pending_litigation_date: {},
        teo_retroactive_date: {},
        mpl_retroactive_date: {},
        mpl_retroactive_date_type: {
            name: {},
            value: {},
        },
        hsb_policy_number: {},
        num_of_visible_questions: {},
        num_of_answers: {},
        binder_expiration_date: {},
        renewal_type: {name: {}, value: {}},
        renewal_policy_request: {
            effective_date: {},
            is_in_auto_renew_process_without_populate: {},
            id: {},
            option_to_quotes: {},
        },
        contingencies: {
            id: {},
            checked: {},
            text: {},
            is_pre_bind: {},
            identifier: {},
            files: {
                id: {},
                file_name: {},
                locked: {},
            },
        },
        unchecked_post_bind_contingencies_count: {},
        unchecked_pre_bind_contingencies_count: {},
        app_disclaimer_checked: {},
        has_claims: {},
        was_bound: {},
        generating_quote_packet: {},
        industries: {
            industry: {
                id: {},
                naics_title: {},
            },
        },
        services: {
            name: {},
        },
        blocked_by_bor: {},
        erp_info: {
            expiration_date: {},
            duration: {},
            is_active: {},
        },
        previous_policy_revenue_threshold: {},
        last_submitted_at: {},
    };
    if (includeOptionToQuotes) {
        query.option_to_quotes = getOptionToQuoteQuery({ includePolicyRequest: false });
    }
    return query;
};
