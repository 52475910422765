import { DirectBrokerDetails, IndirectBrokerActiveStatus, IndirectBrokersDetailsByActiveStatus } from '../utils';

export type State = {
    brokersListByActiveStatus: IndirectBrokersDetailsByActiveStatus;
    brokersList: DirectBrokerDetails[];
};

export type Action =
    | { type: 'set'; payload: DirectBrokerDetails[] }
    | { type: 'add'; payload: DirectBrokerDetails }
    | { type: 'edit'; payload: DirectBrokerDetails };

export const initialBrokersDetailsByActiveStatus: IndirectBrokersDetailsByActiveStatus = {
    [IndirectBrokerActiveStatus.ACTIVE]: [],
    [IndirectBrokerActiveStatus.INACTIVE]: [],
};

export const initialBrokersDataState: State = {
    brokersListByActiveStatus: initialBrokersDetailsByActiveStatus,
    brokersList: [],
};
