import { useCallback, useRef } from 'react';

export const useDebounceCallback = (callback, delay, deps) => {
    const timeoutId = useRef(null);
    return useCallback((...args) => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }
        timeoutId.current = setTimeout(() => {
            callback(...args);
        }, delay);
    }, deps);
};
