import React from 'react';
import { DesktopTabletMobile } from './components';

const SubmitManualRenewal = ({ policyRequest }) => {
    return (
        <DesktopTabletMobile policyRequest={policyRequest} />
    );
}

export default SubmitManualRenewal;
