import styled from 'styled-components';
import {
    Typography as _Typography,
} from '@material-ui/core';

const Typography = styled(_Typography)`
    &&& {
        font-size: 12px;
    }
`;

const FormHeader = styled(_Typography)`
    &&& {
        margin: 0 0 40px;
        font-family: Pressura;
        font-size: 1.9rem;
        font-weight: 400;
        text-align: center;
    }
`;

const FadedTypography = styled(_Typography)`
    &&& {
        opacity: 0.54;
        &.hoverable:hover {
            opacity: 1;
        }
    }
`;

const SecondaryFadedTypography = styled(_Typography)`
    &&& {
        opacity: 0.65;
    }
`;

const SecondaryTypography = styled(_Typography)`
    &&& {
        color: #696969;
        display: inline-block;
    }
`


export default {
    Typography,
    FormHeader,
    FadedTypography,
    SecondaryTypography,
    SecondaryFadedTypography,
};
