import { ErrorResponseType } from '../../mutationTypes';
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { useAxiosInstance } from '../../../hooks';
import { DashboardQueryKeys } from '../../queryKeys';
import { AxiosInstance } from 'axios';

type RequestBindPolicyDataType = {
    optionToQuoteID: string;
    isAppDisclaimerChecked: boolean;
};

type SuccessResponseType = {
    conditionally_bound: boolean;
    hsb_policy_number: string;
    status: 'success';
};

type MutationReturnType = {
    mutate: UseMutateFunction<SuccessResponseType, ErrorResponseType, RequestBindPolicyDataType, unknown>;
    error: ErrorResponseType | null;
    isLoading: boolean;
};

const bindPolicy = async (
    optionToQuoteID: string,
    isAppDisclaimerChecked: boolean,
    axiosInstance: AxiosInstance,
): Promise<SuccessResponseType> => {
    const res = await axiosInstance.post(`/optionToQuote/${optionToQuoteID}/bind`, {
        app_disclaimer_checked: isAppDisclaimerChecked,
    });
    return res.data as SuccessResponseType;
};

export const useBindPolicy = (
    onSuccessCallback: () => void,
    onErrorCallback: (error: ErrorResponseType) => void,
): MutationReturnType => {
    const queryClient = useQueryClient();
    const axiosInstance = useAxiosInstance();

    const { mutate, error, isLoading } = useMutation(
        ({ optionToQuoteID, isAppDisclaimerChecked }: RequestBindPolicyDataType) =>
            bindPolicy(optionToQuoteID, isAppDisclaimerChecked, axiosInstance),
        {
            onSuccess: onSuccessCallback,
            onError: onErrorCallback,
            onSettled: () => {
                queryClient.resetQueries([DashboardQueryKeys.BUCKETS.QUOTES, DashboardQueryKeys.SUB_BUCKETS.OPEN]);
                queryClient.resetQueries([
                    DashboardQueryKeys.BUCKETS.QUOTES,
                    DashboardQueryKeys.SUB_BUCKETS.BIND_REQUEST,
                ]);
                queryClient.resetQueries([DashboardQueryKeys.BUCKETS.POLICIES, DashboardQueryKeys.SUB_BUCKETS.ACTIVE]);
                queryClient.resetQueries([DashboardQueryKeys.SNAPSHOT]);
            },
        },
    );

    return { mutate, error, isLoading };
};
