import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {GlobalDataState, SetAvailableProductsActionPayloadType} from './types';

const initialState: GlobalDataState = {
    availableProducts: undefined,
};

const globalDataSlice = createSlice({
    name: 'globalData',
    initialState,
    reducers: {
        setAvailableProducts(state, action: PayloadAction<SetAvailableProductsActionPayloadType>) {
            state.availableProducts = action.payload;
        },
    },
});

export const {setAvailableProducts} = globalDataSlice.actions;

export default globalDataSlice.reducer;
