import React, {useEffect, useState, useRef} from 'react';
import Styled from './styled-components';
import {useAxiosClient, useQuery} from 'hooks';
import { isValidEmail, isAgencyEmail } from 'utils';
import CoverIcons from '../cover/CoverIcons';
import { Hidden, Box } from '@material-ui/core';
import { Logo } from 'components/icons/logo';
import CreateAccountPage from './pages/CreateAccountPage';
import {useHistory} from 'react-router-dom';
import UserExistsPage from './pages/UserExistsPage';
import SelectAgencyPage from './pages/SelectAgencyPage';
import ConfirmationPage from './pages/ConfirmationPage';
import {SignUpProgressStep} from 'utils/enums';
import NewOfficeLocationPage from './pages/NewOfficeLocationPage';
import NewAgencyPage from './pages/NewAgencyPage';

const SignUp = () => {
    const emailInput = useRef(null);
    const axiosClient = useAxiosClient();
    const history = useHistory();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [selectLocationError, setSelectLocationError] = useState(false);
    const [activationEmailError, setActivationEmailError] = useState(false);
    const [progressStep, setProgressStep] = useState(1);
    const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
    const [brokerRandomUuid, setBrokerRandomUuid] = useState(null);
    const [agencies, setAgencies] = useState([]);
    const [agencyGroup, setAgencyGroup] = useState('');
    const [states, setStates] = useState([]);
    const [stateLabel, setStateLabel] = useState('Select State...');
    const [agencyLocationLabel, setAgencyLocationLabel] = useState('Select Office Location...');
    const [selectedState, setSelectedState] = useState('');
    const [agenciesInSelectedState, setAgenciesInSelectedState] = useState([]);
    const [selectedAgencyId, setSelectedAgencyId] = useState('');
    const [resendActivationEmailSuccess, setResendActivationEmailSuccess] = useState(false);
    const [progressStepNum, setProgressStepNum] = useState(1);
    const [userInformation, setUserInformation] = useState(undefined);
    const [loadingState, setLoadingState] = useState(false);
    const url_param_email = useQuery().get('email');

    const [, signup] = axiosClient(
        {
            method: 'post',
            url: '/auth/signup',
        },
        { manual: true }
    );

    const [, selectAgency] = axiosClient(
        {
            method: 'post',
            url: '/auth/signup/select_agency',
        },
        { manual: true }
    );

    const [, resendActivationEmail] = axiosClient(
        {
            method: 'post',
            url: '/auth/signup/resend_activation_email',
        },
        { manual: true }
    );

    const [, getUserStatus] = axiosClient(
        {
            method: 'get',
            url: '/auth/user_status',
        },
        { manual: true }
    );

    useEffect(() => {
        if (url_param_email && userInformation === undefined) {
            getUserStatus({ params: { email: url_param_email } }).then(res => {
                setUserInformation(res.data)
                setProgressStepNum(3);
                setEmail(url_param_email)
                setBrokerRandomUuid(res.data.random_uuid);
                resendActivationEmail({ data: { uuid: res.data.random_uuid } });
            });
        }
        else {
            setUserInformation(null);
        }
    }, [url_param_email]);

    useEffect(() => {
        switch (progressStep) {
            case SignUpProgressStep.CREATE_ACCOUNT:
                setProgressStepNum(1);
                break;
            case SignUpProgressStep.SELECT_AGENCY:
                setProgressStepNum(2);
                break;
            case SignUpProgressStep.NEW_AGENCY:
                setProgressStepNum(3);
                break;
            case SignUpProgressStep.CONFIRMATION:
                setProgressStepNum(3);
                break;
            case SignUpProgressStep.NEW_LOCATION:
                setProgressStepNum(3);
                break;
            default:
                setProgressStepNum(1);
                break;
        }
    }, [progressStep]);

    useEffect(() => {
        if (agencies) {
            const distinctStates = [...new Set(agencies.map(agency => agency.state))].sort();
            setStates(distinctStates);
        }
    }, [agencies]);

    useEffect(() => {
        const agenciesInState = agencies.filter(agency => agency.state === selectedState);
        setAgenciesInSelectedState(agenciesInState);
    }, [selectedState]);

    useEffect(() => {
        if (isValidEmail(email) && firstName && lastName) {
            setNextButtonDisabled(false);
        } else {
            setNextButtonDisabled(true);
        }
    }, [email, firstName, lastName]);

    const handleNextSubmit = async e => {
        e.preventDefault();
        setLoadingState(true);
        emailInput.current.querySelector('input').focus();

        if (!firstName) {
            setFirstNameError('Required');
        }
        if (!lastName) {
            setLastNameError('Required');
        }
        if (!email) {
            setEmailError('Required');
        } else if (!isValidEmail(email)) {
            setEmailError('Invalid email');
        } else if (!isAgencyEmail(email)) {
            setEmailError('Must be an agency or company email');
        }

        if (isValidEmail(email) && isAgencyEmail(email) && firstName && lastName) {
            try {
                const res = await signup({ data: { first_name: firstName, last_name: lastName, email } });
                if (res.data.account_status === 'validated' || res.data.account_status === 'preactivation') {
                    setProgressStep(SignUpProgressStep.USER_EXISTS);
                } else if (!res.data.agency_group_name) {
                    setProgressStep(SignUpProgressStep.NEW_AGENCY);
                } else {
                    setBrokerRandomUuid(res.data.random_uuid);
                    setAgencies(res.data.agencies);
                    setAgencyGroup(res.data.agency_group_name);
                    setProgressStep(SignUpProgressStep.SELECT_AGENCY);
                }
                setEmailError('');
            } catch (error) {
                setEmailError('An error has occurred, please try again');
            }
        }
        setLoadingState(false);
    };

    const handleSelectAgencySubmit = async e => {
        e.preventDefault();
        setLoadingState(true);
        if (selectedState && selectedAgencyId) {
            try {
                await selectAgency({ data: { company_id: selectedAgencyId, uuid: brokerRandomUuid } });
                setProgressStep(SignUpProgressStep.CONFIRMATION);
            } catch (error) {
                setSelectLocationError(true);
            }
        }
        setLoadingState(false);
    };

    const handleResendActivationEmail = async e => {
        e.preventDefault();
        setLoadingState(true);
        try {
            await resendActivationEmail({ data: { uuid: brokerRandomUuid } });
            setResendActivationEmailSuccess(true);
        } catch (error) {
            setActivationEmailError(true);
        }
        setLoadingState(false);
    };

    const handleNewLocationRequest = () => {
        setProgressStep(SignUpProgressStep.NEW_LOCATION);
    };

    const renderPage = () => {
        switch (progressStep) {
            case SignUpProgressStep.USER_EXISTS:
                return <UserExistsPage email={email} />;
            case SignUpProgressStep.CREATE_ACCOUNT:
                return renderCreateAccountPage();
            case SignUpProgressStep.SELECT_AGENCY:
                return (
                    <SelectAgencyPage
                        states={states}
                        agencyGroup={agencyGroup}
                        selectedState={selectedState}
                        agenciesInSelectedState={agenciesInSelectedState}
                        selectedAgencyId={selectedAgencyId}
                        setSelectedAgencyId={setSelectedAgencyId}
                        selectLocationError={selectLocationError}
                        stateLabel={stateLabel}
                        agencyLocationLabel={agencyLocationLabel}
                        handleSelectAgencySubmit={handleSelectAgencySubmit}
                        setStateLabel={setStateLabel}
                        setAgencyLocationLabel={setAgencyLocationLabel}
                        setProgressStep={setProgressStep}
                        setSelectedState={setSelectedState}
                        handleNewAgencyRequest={handleNewLocationRequest}
                        email={email}
                        loadingState={loadingState}
                    />
                );
            case SignUpProgressStep.NEW_AGENCY:
                return (
                    <NewAgencyPage email={email}/>
                );
            case SignUpProgressStep.NEW_LOCATION:
                return (
                    <NewOfficeLocationPage email={email}/>
                );
            case SignUpProgressStep.CONFIRMATION:
                return (
                    <ConfirmationPage
                        email={email}
                        handleResendActivationEmail={handleResendActivationEmail}
                        resendActivationEmailSuccess={resendActivationEmailSuccess}
                        activationEmailError={activationEmailError}
                        setActivationEmailError={setActivationEmailError}
                        loadingState={loadingState}
                    />
                );
            default:
                if (userInformation) {
                    if (userInformation.account_status === 'preactivation') {
                        return (
                            <ConfirmationPage
                                email={email}
                                handleResendActivationEmail={handleResendActivationEmail}
                                resendActivationEmailSuccess={resendActivationEmailSuccess}
                                activationEmailError={activationEmailError}
                                setActivationEmailError={setActivationEmailError}
                                loadingState={loadingState}
                            />
                        )
                    }
                    else {
                        history.push('/user/login')
                    }
                }
                return renderCreateAccountPage();
        }
    };

    const renderCreateAccountPage = () => {
        return (
            <CreateAccountPage
                firstName={firstName}
                lastName={lastName}
                email={email}
                firstNameError={firstNameError}
                lastNameError={lastNameError}
                emailError={emailError}
                handleNextSubmit={handleNextSubmit}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setEmail={setEmail}
                emailInput={emailInput}
                nextButtonDisabled={nextButtonDisabled}
                loadingState={loadingState}
            />
        );
    };

    return (
        <Styled.FormContainer>
            <Hidden smDown>
                <Styled.LogoContainer>
                    <Logo />
                </Styled.LogoContainer>
            </Hidden>
            {progressStep !== SignUpProgressStep.USER_EXISTS ? (
                <Box marginBottom="15px">
                    <Styled.ProgressCircle active={progressStepNum >= 1}>
                        <span style={{ position: 'relative', left: '9px', top: '4px' }}>1</span>
                    </Styled.ProgressCircle>
                    <Styled.ProgressCircleDivider />
                    <Styled.ProgressCircle active={progressStepNum >= 2}>
                        <span style={{ position: 'relative', left: '9px', top: '4px' }}>2</span>
                    </Styled.ProgressCircle>
                    <Styled.ProgressCircleDivider />
                    <Styled.ProgressCircle active={progressStepNum >= 3}>
                        <span style={{ position: 'relative', left: '9px', top: '4px' }}>3</span>
                    </Styled.ProgressCircle>
                </Box>
            ) : (
                ''
            )}
            {userInformation !== undefined && renderPage()}
            <Hidden mdUp>
                <CoverIcons />
            </Hidden>
        </Styled.FormContainer>
    );
};

export default SignUp;
