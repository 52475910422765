import React from 'react';
import { env as envfile } from '../../env';
import {isAxa} from 'components/icons/logo';

export const envContext = React.createContext(null);

export const EnvContextProvider = ({ children }) => {
    const apiBaseUrl =  isAxa ? envfile.REACT_APP_API_BASE_PARTNER_URL : envfile.REACT_APP_API_BASE_URL;
    const env = {
        apiBaseUrl,
        apiGuiBaseUrl: envfile.REACT_APP_API_GUI_BASE_URL,
        siteKey: envfile.REACT_APP_SITE_KEY,
        mode: envfile.REACT_APP_MODE,
        athenaHost: envfile.REACT_APP_ATHENA_HOST,
        intercomAppId: envfile.REACT_APP_INTERCOM_APP_ID,
        gaApiKey: envfile.REACT_APP_GA_KEY,
        gaDebugMode: envfile.REACT_APP_ANALYTICS_DEBUG_MODE,
        appPrefix: envfile.REACT_APP_PREFIX || '',
        newSignInPage: envfile.REACT_APP_NEW_SIGN_IN_PAGE === 'true',
        newQuestionnaire: envfile.REACT_APP_NEW_QUESTIONNAIRE === 'true',
        maintenanceMode: envfile.REACT_APP_MAINTENANCE_MODE === 'true',
        editCompanyName: envfile.REACT_APP_EDIT_COMPANY_NAME === 'true',
        googleMapPlacesKey: envfile.REACT_APP_GOOGLE_MAP_PLACES_API_KEY,
        editApplication: envfile.REACT_APP_EDIT_APPLICATION === 'true',
        teamView: envfile.REACT_APP_TEAM_VIEW === 'true',
        renewalEditApplication: envfile.REACT_APP_RENEWAL_EDIT_APPLICATION === 'true',
        newBpRenewal: envfile.REACT_APP_RENEWAL_FF === 'true',
        newRenewalsTab: envfile.REACT_APP_NEW_RENEWALS_TAB === 'true',
        officeRequestFormUrl: envfile.REACT_APP_OFFICE_REQUEST_FORM_URL,
        brokerageAppointmentFormUrl: envfile.REACT_APP_BROKERAGE_APPOINTMENT_URL,
        authAppId: envfile.REACT_APP_AUTH_APP_ID,
    };

    return <envContext.Provider value={env}>{children}</envContext.Provider>;
};
