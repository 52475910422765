export default {
    loading: 'Loading...',
    none: 'None',
    search: 'Search',
    actions: {
        next: 'Next',
        back: 'Back',
        delete: 'Delete',
    },
};
