import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useBreakpoint } from 'hooks';
import { PolicyDataDialog } from 'components';
import { CoveragesTable } from 'components/quote';
import {
    StyledAction,
    StyledLink,
    StyledTypography,
    StyledTable,
    StyledDivider,
    RoundedButton,
} from 'components';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
    Box,
    Hidden,
    TableRow,
    Typography,
} from '@material-ui/core';
import { ProductsAbbreviatedName } from 'utils/enums';


const Coverages = ({ readOnly }) => {
    const { id: optionToQuoteID, quote } = useSelector(state => state.company.optionToQuote);
    const isSm = useBreakpoint('down', 'sm');
    const isMd = useBreakpoint('down', 'md');
    const [coveragesToggled, setCoveragesToggled] = useState(false);
    const isMPL = quote.policy_request.product.abbreviated_name === ProductsAbbreviatedName.MPL;
    return (
        <>
            <TableRow>
                <StyledTable.TableCell padding="none" colSpan={2}>
                    <Box fontWeight="fontWeightLight" component="span">
                        <Box
                            display="flex"
                            alignItems="center"
                            flexDirection={isSm ? 'row-reverse' : 'row'}
                            justifyContent={['space-between', 'space-between', 'inherit']}
                            width={isSm ? '100%' : 'fit-content'}
                            pt={2}
                            pl={isMd ? 0 : 2}
                        >
                            <Box component={StyledAction.ExpandableRowControl}
                                display="flex"
                                alignItems="center"
                                flexDirection={isSm ? 'row-reverse' : 'row'}
                                justifyContent="space-between"
                                onClick={() => setCoveragesToggled(!coveragesToggled)}>
                                <StyledTypography.FadedTypography variant="subtitle1">
                                    <Box mr={[0, 0, 2.5, 3]} display="flex">
                                        {isSm ? <KeyboardArrowRightIcon /> : coveragesToggled ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </Box>
                                </StyledTypography.FadedTypography>
                                <StyledTypography.FadedTypography variant="subtitle2" align="justify">
                                    {coveragesToggled ? 'Hide' : (readOnly ? 'View' : 'Review')}
                                    {/*
                                        This is annoyingly complicated but here are the rules:
                                        1. In anything other than mobile we'll always show "coverages"
                                        2. If we're read only, we also don't show the "customize" button, so we need the text to make sense and not be just "view/review", which is why we include the word "coverages"
                                        3. Currently in MPL we don't show the "customize" button in mobile, so we need the text to make sense and not be just "view/review", which is why we include the word "coverages"
                                    */}
                                    {(!isSm || readOnly || isMPL) && ' coverages'}
                                </StyledTypography.FadedTypography>
                            </Box>
                            {!isMPL && !readOnly && (
                                <>
                                    <Hidden smDown><StyledDivider.VerticalDivider orientation="vertical" /></Hidden>
                                    <StyledTypography.FadedTypography variant="subtitle2" className="hoverable">
                                        <StyledLink.Default
                                            style={{ color: 'inherit' }}
                                            to={`/submission/quote_customization/${optionToQuoteID}`}
                                        >
                                            Customize {isSm && 'coverages'}
                                        </StyledLink.Default>
                                    </StyledTypography.FadedTypography>
                                </>
                            )}
                        </Box>
                    </Box>
                </StyledTable.TableCell>
            </TableRow>
            {coveragesToggled && (
                <Hidden smDown>
                    <CoveragesTable
                        quote={quote}
                        firstColSpan={1}
                        numOfExtraCols={1}
                        firstColPadding={isMd ? 4 : 7}
                        childrenToggled />
                </Hidden>
            )}
            <Hidden mdUp>
                <PolicyDataDialog
                    open={coveragesToggled}
                    headerExtraText={readOnly ? 'View coverages' : 'Review coverages'}
                    footer={(
                        <Box width="100%" display="flex" justifyContent="space-evenly" alignItems="center" fontWeight="fontWeightBold">
                            <Box component={Typography} variant="subtitle2" textAlign="center" width="156px">
                                <StyledLink.Default to={`/submission/quote_customization/${optionToQuoteID}`}>
                                    Customize
                                </StyledLink.Default>
                            </Box>
                            <RoundedButton onClick={() => setCoveragesToggled(false)} width="156px">
                                <Typography variant="subtitle2">
                                    Done
                                </Typography>
                            </RoundedButton>
                        </Box>
                    )}>
                    <CoveragesTable quote={quote} firstColSpan={1} numOfExtraCols={1} childrenToggled />
                </PolicyDataDialog>
            </Hidden>
            {coveragesToggled && (
                <TableRow>
                    <StyledTable.TableCell padding="none">
                        <Box mb={5}></Box>
                    </StyledTable.TableCell>
                </TableRow>
            )}
        </>
    );
}

export default Coverages;
