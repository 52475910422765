import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Error } from 'components';
import { Builder, Preview } from './pages';
import ChooseQuestionnaireByProduct from './ChooseQuestionnaireByProduct';

const QuestionnaireRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/policy/:policyRequestId`}>
                <ChooseQuestionnaireByProduct />
            </Route>
            <Route path={`${path}/preview/:questionnaireId`}>
                <Preview />
            </Route>
            <Route>
                <Error errorStatus="404" />
            </Route>
        </Switch>
    );
};

export default QuestionnaireRoutes;
