import { getPolicyRequestQuery } from './policyRequest';
import { industryQuery } from './industry';


export const updateCompanyQuery = {
    recommended_products: {
        id: {},
        name: {},
        description: {},
    },
    viewable_policy_requests: {
        id: {}
    },
    case_studies: {
        title: {},
        long_text: {},
    }
};

export const companyScanQuery = {
    latest_scan: {
        start_time_unix: {},
        completed_successfully: {},
        end_time: {},
    },
}

export const companyQuery = {
    id: {},
    name: {},
    financial_exposure_ready: {},
    last_update: {},
    created_at: {},
    security_score_filename: {},
    ...companyScanQuery,
    ...updateCompanyQuery,
    domain: {},
    industry: industryQuery,
    policies_owners: {broker_user: {username: {}}},
};

export const policyRequestQuery = getPolicyRequestQuery({
    includeOptionToQuotes: true
});
