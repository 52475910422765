import common from './common';
import company from './company';
import footer from './footer';
import header from './header';

export default {
    common,
    company,
    footer,
    header,
};
