import {
    BucketType,
    CompaniesBuckets,
    SubBucketType,
} from '../modules/company/pages/dashboard/components/companies-pagination/types';
import { QuotesSubBuckets } from '../utils/enums';
import * as _ from 'lodash';

const BASE_QUERY = {
    id: {},
    name: {},
    domain: {},
    last_update: {},
    industry: {
        naics_title: {},
    },
    indirect_broker_name: {},
    latest_submitted_policy_request: {
        revenue: {},
    },
    financial_exposure_ready: {},
    policies: {
        athena_submissions_bucket: {},
        hsb_policy_number: {},
        expiration_date: {},
        effective_date: {},
        // submissions_bucket_reason_name: {},
        athena_status: {},
        id: {},
        company_id: {},
        company_name: {},
        conditionally_bound: {},
        product: {
            id: {},
            name: {},
            abbreviated_name: {},
        },
        option_to_quotes: {
            id: {},
            quote: {
                premium: {},
                total_premium: {},
                aggregate_limit: {},
                aggregate_retention: {},
                preferred: {},
                per_claim_limit: {},
            },
            publish_time: {},
            pending: {},
        },
        broker_user: {
            initials: {},
            id: {},
        },
        blocked_by_bor: {},
    },
};

export const getQueryBySubBucket = (bucket: BucketType, subBucket: SubBucketType) => {
    if (bucket === CompaniesBuckets.POLICIES) {
        return {
            ...BASE_QUERY,
            policies: {
                ...BASE_QUERY.policies,
                option_to_quotes: {
                    ...BASE_QUERY.policies.option_to_quotes,
                    quote: {
                        ...BASE_QUERY.policies.option_to_quotes.quote,
                        original_total_premium: {},
                    },
                },
                unchecked_post_bind_contingencies_count: {},
                unchecked_pre_bind_contingencies_count: {},
                renewal_policy_request: {
                    athena_submissions_bucket: {},
                    renewal_type: {},
                },
                erp_info: {
                    is_active: {},
                    expiration_date: {},
                    duration: {},
                },
            },
        };
    } else if (bucket === CompaniesBuckets.QUOTES) {
        switch (subBucket) {
            case QuotesSubBuckets.INACTIVE:
                return {
                    ...BASE_QUERY,
                    policies: {
                        ...BASE_QUERY.policies,
                        unchecked_post_bind_contingencies_count: {},
                        unchecked_pre_bind_contingencies_count: {},
                        renewal_policy_request: {
                            athena_submissions_bucket: {},
                            renewal_type: {},
                        },
                        erp_info: {
                            is_active: {},
                            expiration_date: {},
                            duration: {},
                        },
                    },
                };
            case QuotesSubBuckets.INCOMPLETE:
                return {
                    ...BASE_QUERY,
                    policies: { ...BASE_QUERY.policies, num_of_visible_questions: {}, num_of_answers: {} },
                };

            case QuotesSubBuckets.OPEN:
                return {
                    ...BASE_QUERY,
                    policies: {
                        ...BASE_QUERY.policies,
                        unchecked_post_bind_contingencies_count: {},
                        unchecked_pre_bind_contingencies_count: {},
                    },
                };
            default:
                return BASE_QUERY;
        }
    } else if (bucket === CompaniesBuckets.RENEWALS) {
        return {
            ...BASE_QUERY,
            policies: {
                ...BASE_QUERY.policies,
                option_to_quotes: {
                    ...BASE_QUERY.policies.option_to_quotes,
                    quote: {
                        ...BASE_QUERY.policies.option_to_quotes.quote,
                        original_total_premium: {},
                    },
                },
                unchecked_post_bind_contingencies_count: {},
                unchecked_pre_bind_contingencies_count: {},
                last_submitted_at: {},
                renewal_policy_request: {
                    athena_submissions_bucket: {},
                    renewal_type: {},
                },
                erp_info: {
                    is_active: {},
                    expiration_date: {},
                    duration: {},
                },
            },
        };
    }
};
