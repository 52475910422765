import React from 'react';
import Styled from '../styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { StyledForm, StyledLink, StyledSelectField, RoundedButton, Icon } from 'components';
import { Typography, FormHelperText } from '@material-ui/core';

const SelectAgencyPage = ({
    states,
    agencyGroup,
    selectedState,
    agenciesInSelectedState,
    selectedAgencyId,
    selectLocationError,
    stateLabel,
    agencyLocationLabel,
    handleSelectAgencySubmit,
    setStateLabel,
    setAgencyLocationLabel,
    setProgressStep,
    setSelectedState,
    setSelectedAgencyId,
    handleNewAgencyRequest,
    email,
    loadingState
}) => {
    const theme = useTheme();

    return (
        <>
            <Styled.FormHeader>Select Location</Styled.FormHeader>
            <Styled.FormText variant="subtitle2">
                Based on your email ({email}), it looks like you work at{' '}
                <span style={{ fontWeight: theme.typography.fontWeightMedium }}>{agencyGroup}</span>.
            </Styled.FormText>
            <Styled.SignUpLink variant="body2" color="primary">
                <StyledLink.Default to="/user/register" onClick={() => setProgressStep('createAccount')}>
                    Use a different email
                </StyledLink.Default>
            </Styled.SignUpLink>
            <StyledForm.Form onSubmit={handleSelectAgencySubmit}>
                <StyledForm.FormControl fullWidth={true} style={{ maxWidth: '400px' }}>
                    <StyledSelectField.InputLabel id="state-label">{stateLabel}</StyledSelectField.InputLabel>
                    <StyledSelectField.Select
                        labelId="state-label"
                        onFocus={() => setStateLabel('State')}
                        onChange={e => setSelectedState(e.target.value)}
                        value={selectedState}
                        onBlur={() => (selectedState ? setStateLabel('State') : setStateLabel('Select State...'))}
                        IconComponent={() => (
                            <Icon icon={<KeyboardArrowDownIcon />} typographyProps={{ variant: 'h5' }} />
                        )}>
                        {states.map((state, index) => (
                            <StyledSelectField.MenuItem
                                key={index}
                                value={state}
                                style={{ padding: `${theme.spacing(0.5)} ${theme.spacing(2)}` }}>
                                {state}
                            </StyledSelectField.MenuItem>
                        ))}
                    </StyledSelectField.Select>
                </StyledForm.FormControl>
                {selectedState ? (
                    <>
                        <StyledForm.FormControl fullWidth={true} style={{ maxWidth: '400px' }} error>
                            <StyledSelectField.InputLabel id="agency-label">
                                {agencyLocationLabel}
                            </StyledSelectField.InputLabel>
                            <StyledSelectField.Select
                                labelId="agency-label"
                                error={selectLocationError}
                                onFocus={() => setAgencyLocationLabel('Office')}
                                onChange={e => setSelectedAgencyId(e.target.value)}
                                value={selectedAgencyId}
                                onBlur={() =>
                                    selectedAgencyId
                                        ? setAgencyLocationLabel('Office')
                                        : setAgencyLocationLabel('Select Office Location...')
                                }
                                IconComponent={() => (
                                    <Icon icon={<KeyboardArrowDownIcon />} typographyProps={{ variant: 'h5' }} />
                                )}>
                                {agenciesInSelectedState.map(agency => (
                                    <StyledSelectField.MenuItem
                                        key={agency.id}
                                        value={agency.id}
                                        style={{
                                            textAlign: 'left',
                                            padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
                                        }}>
                                        {agency.name}
                                        <br />
                                        {agency.street_address} {agency.street_address_2}
                                        <br />
                                        {agency.city}, {agency.state} {agency.zip}
                                    </StyledSelectField.MenuItem>
                                ))}
                            </StyledSelectField.Select>
                            <FormHelperText>
                                {selectLocationError ? 'An error has occurred, please try again' : ''}
                            </FormHelperText>
                        </StyledForm.FormControl>
                    </>
                ) : (
                    ''
                )}
                <RoundedButton
                    type="submit"
                    py={0.4}
                    width="187px"
                    mt={4}
                    disabled={!(selectedState && selectedAgencyId) || loadingState}>
                    <Typography variant="subtitle2">Create Account</Typography>
                </RoundedButton>
                <Styled.NewLocation color="primary">
                    <StyledLink.Default onClick={handleNewAgencyRequest}>
                        Can't find your office location?
                    </StyledLink.Default>
                </Styled.NewLocation>
            </StyledForm.Form>
        </>
    );
};

export default SelectAgencyPage;
