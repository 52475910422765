import styled from 'styled-components';
import {
    Container as _Container,
    Typography as _Typography
} from '@material-ui/core';

const Container = styled(_Container)`
    &&& {
        max-width: 960px;
        width: inherit;
        align-self: center;
        ${({ theme }) => `
            ${theme.breakpoints.down('md')} {
                display: flex;
                margin: ${theme.spacing(0.5)}px;
                flex-wrap: nowrap;
                overflow-x: auto;
            }
        `}
    }
`;


const Typography = styled(_Typography)`
    &&& {
        color: #191919;
        text-align: left;
        ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightBold};
        `}
        &.title {
            ${({ theme }) => `
                font-weight: ${theme.typography.fontWeightBold};
                margin-top: ${theme.spacing(2)}px;
            `}
        }
        &.subtitle{
            font-weight: ${({ theme, selected }) => selected ? theme.typography.fontWeightMedium : theme.typography.fontWeightLight};
            padding-bottom: ${({ theme, selected }) => selected ? theme.spacing(0.75) : theme.spacing(0)}px;
            color: ${({ selected }) => selected ? '#191919' : '#919191'};
            border-bottom: ${({ selected }) => selected ? '3px solid #191919' : 'none'};
            margin-top: unset;
        }
    }
`;

export default {
    Container,
    Typography
};
