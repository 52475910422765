import React from 'react';
import { useHistory } from 'react-router-dom';
import { useBreakpoint, useEnv } from 'hooks';
import { RoundedButton, StyledLink, StyledTypography, ColoredLinearProgress, StyledTable } from 'components'
import { Box, Hidden, Typography, Table, TableHead, TableBody, TableRow } from '@material-ui/core';
import NextSteps from 'components/policy/next-steps/PendingNextSteps';


const InProgress = ({ policyRequest }) => {
    const isSm = useBreakpoint('down', 'sm');
    const { apiBaseUrl } = useEnv();
    const history = useHistory();
    const answered = policyRequest.num_of_answers;
    const total = policyRequest.num_of_visible_questions;
    const submitted = policyRequest.broker_submitted;
    const redirectToQuestionnaire = () => history.push(`/questionnaire/policy/${policyRequest.id}`);

    return (
        !submitted ? (
            <>
                <Box textAlign="right">
                    <Hidden smDown>
                        <Box component={StyledTypography.FadedTypography} variant="overline">
                            {answered} OF {total} QUESTIONS COMPLETE
                        </Box>
                    </Hidden>
                    <Box mb={2} mt={[-2, -2, 2]}>
                        <ColoredLinearProgress
                            value={100 * answered / total}
                            variant="determinate"
                            color={policyRequest.product.background_color} />
                    </Box>
                </Box>
                <RoundedButton onClick={redirectToQuestionnaire} fullWidth={isSm} py={0.3}>
                    <Typography variant="subtitle2">
                        Complete Application
                    </Typography>
                </RoundedButton>
            </>
        ) : (
            policyRequest.inProgress ? (
                <Box mb={2} mt={[-2, -2, 0]}>
                    <ColoredLinearProgress color={policyRequest.product.background_color} />
                </Box>
            ) : (
                <Box component={props => <Table padding="none" {...props} />} fontWeight="fontWeightLight">
                    <TableHead>
                        <TableRow>
                            <StyledTable.TableCell>
                                <Box mb={2} mt={[-2, -2, 0]}>
                                    <ColoredLinearProgress variant="determinate" value={100} color={policyRequest.product.background_color} />
                                </Box>
                            </StyledTable.TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <StyledTable.TableCell colSpan={6}>
                                <Box/>
                            </StyledTable.TableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTable.TableCell colSpan={6}>
                                <RoundedButton fullWidth={isSm} py={0.3}>
                                    <Typography variant="subtitle2">
                                        <StyledLink.Anchor
                                            target="_blank"
                                            style={{ color: 'inherit' }}
                                            href={`${apiBaseUrl}/questionnaire/render_policy_request/${policyRequest.id}/questionnaire`}>
                                            Review Application
                                        </StyledLink.Anchor>
                                    </Typography>
                                </RoundedButton>
                            </StyledTable.TableCell>
                        </TableRow>
                        <NextSteps policyRequest={policyRequest} />
                    </TableBody>
                </Box>
            )
        )
    );
}


export default InProgress;
