import React from 'react';
import {useEnv} from '../../../../../hooks';
import Styled from '../styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import {Typography} from '@material-ui/core';
import {RoundedButton} from '../../../../../components';
import {useHistory} from 'react-router-dom';

const NewOfficeLocationPage = ({
    email,
}) => {
    const { officeRequestFormUrl } = useEnv();
    const theme = useTheme();
    const history = useHistory();

    const handleReturnToLogin = () => {
        history.push('/user/login');
    };

    return (
        <>
            <Styled.FormHeader>Next Steps...</Styled.FormHeader>
            <Styled.FormText variant="subtitle2">
                To create a new office location, complete the New Office Request <a href={officeRequestFormUrl} target="_blank">New Office Request </a>
            </Styled.FormText>
            <Styled.FormText variant="subtitle3">
                Once the location is verified, we'll email
                <span style={{ fontWeight: theme.typography.fontWeightMedium }}> {email} </span>
                an activation link to sign in.
            </Styled.FormText>
            <Styled.SignUpLink>
                <RoundedButton
                    onClick={handleReturnToLogin}
                    py={0.3}
                    width="220px"
                    fontWeight={500}
                    ml={4}
                    mt={4}>
                    <Typography variant="subtitle2">Return to homepage</Typography>
                </RoundedButton>
            </Styled.SignUpLink>
        </>
    );
};

export default NewOfficeLocationPage;
