import { useAxiosInstance } from '../../../hooks';
import { ErrorResponseType } from 'api/mutationTypes';
import { UseMutateFunction, useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

export type CreateReferralDetailsResponseType = {
    status: 'success';
};

export type CreateReferralDetailsType = {
    files: Array<File>;
    note_text: string;
};

export type RequestReferralDetailsData = {
    CreateReferralDetailsData: CreateReferralDetailsType;
    policyRequestId: string;
}

type MutationReturnType = {
    mutate: UseMutateFunction<CreateReferralDetailsResponseType, ErrorResponseType, RequestReferralDetailsData, unknown>;
    error: ErrorResponseType | null;
    isLoading: boolean;
};

const createReferralDetails = async (
    policyRequestId: string,
    CreateReferralDetailsData: CreateReferralDetailsType,
    axiosInstance: AxiosInstance,
): Promise<CreateReferralDetailsResponseType> => {
    const res = await axiosInstance.post(
        `/policyRequest/${policyRequestId}/submit_referral_submission_details`, CreateReferralDetailsData);

    return res?.data as CreateReferralDetailsResponseType;
};

export const useCreateReferralDetails = (
    onSuccessCallback: (result: CreateReferralDetailsResponseType) => void,
    onErrorCallback: (error: ErrorResponseType) => void,
): MutationReturnType => {
    const axiosInstance = useAxiosInstance();

    const { mutate, error, isLoading } = useMutation(
        ({ policyRequestId, CreateReferralDetailsData }: RequestReferralDetailsData) =>
            createReferralDetails(policyRequestId, CreateReferralDetailsData, axiosInstance),
        {
            onSuccess: onSuccessCallback,
            onError: onErrorCallback,
        },
    );

    return { mutate, error, isLoading };
};
