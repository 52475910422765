import { usePermissions } from '../../hooks/usePermissions';
import React, {JSXElementConstructor, ReactNode} from 'react';

type PermissionComponentProps = {
    children: ReactNode;
};

type PermissionRequiredProps = {
    requiredPermissions: string[];
    children: ReactNode[];
};

const PermissionGranted: React.FC<PermissionComponentProps> = ({ children }) => {
    return <>{children}</>;
};

const PermissionDenied: React.FC<PermissionComponentProps> = ({ children }) => {
    return <>{children}</>;
};

const PermissionRequired: React.FC<PermissionRequiredProps> = ({ requiredPermissions, children }) => {
    const checkPermission = usePermissions();

    const getPermissionComponentByType = (requestedType: JSXElementConstructor<PermissionComponentProps>) => {
        return children.find((child: any) => child.type === requestedType);
    };

    const hasPermission = checkPermission ? checkPermission(requiredPermissions) : false;
    const requestedType = hasPermission ? PermissionGranted : PermissionDenied;
    const component = getPermissionComponentByType(requestedType);

    if (component) {
        return <>{component}</>;
    }

    return null;
};

export { PermissionGranted, PermissionDenied, PermissionRequired };
