import React, {useMemo, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEnv } from 'hooks';
import { Hidden } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { Permissions } from 'utils/enums';

import { setToken } from 'stores/auth/authSlice';
import { Logo } from 'components/icons/logo';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';
import SearchBar from '../search-bar/SearchBar';
import DropdownItem from '../dropdown/DropdownItem';
import DropdownHeader from '../dropdown/DropdownHeader';
import Styled from './styled-components';
import { Box, Typography } from '@material-ui/core';
import {StyledLink} from 'components';
import OverflowTip from 'components/overflow-tooltip/overflowTip';
import { FullStory, Intercom } from 'services';
import ChangePasswordPopup from '../popup/ChangePasswordPopup';
import {useChangePassword} from '../../api/auth/mutations/useChangePassword';
import { usePermissions } from '../../hooks/usePermissions';

const getNavigation = (
    userPermissions,
    partnership,
    handleAdminNav,
    userHaveBrokerPermissions,
    userHaveSubmissionWritePermissions,
) => {

    const navigation = [];
    if (Permissions.isAdmin(userPermissions)) {
        navigation.push(
            {
                text: 'Admin',
                icon: <DashboardIcon />,
                path: '/admin/dashboard',
                handleMouseOver: handleAdminNav,
            }
        )
    }
    if (partnership && userHaveSubmissionWritePermissions) {
        navigation.push(
            {
                text: 'Retail Partners',
                icon: <BusinessIcon />,
                path: '/partnership_management/retail_partners',
            }
        );
    }
    if (userHaveBrokerPermissions) {
        navigation.push({
            text: 'Home',
            icon: <DashboardIcon />,
            path: '/dashboard',
        });
    }
    return navigation;
};

const getAdminSubNav = [
    {
        text: 'Pending Users',
        icon: <BusinessIcon />,
        path: '/admin/pending_users',
    },
    {
        text: 'Questionnaire Builder',
        icon: <BusinessIcon />,
        path: '/admin/questionnaire_builder',
    },
    { text: 'Export', icon: <BusinessIcon />, path: '/admin/export_all' },
    {
        text: 'White Label',
        icon: <BusinessIcon />,
        path: '/admin/white_label',
    },
];

const Header = () => {
    const { teamView } = useEnv();
    const [changePasswordPopup, setChangePasswordPopup] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { user, partnership } = useSelector(state => state.user);
    const { isAuthenticated } = useSelector(state => state.newAuth);
    const { permissions: userPermissions } = useSelector(state => state.user);
    const [sideMenuOpen, setSideMenuOpen] = React.useState(false);
    const [mobileSearchBarHidden, setMobileSearchBarHidden] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [adminNavAnchorEl, setadminNavAnchorEl] = React.useState(false);
    const adminNavOpen = Boolean(adminNavAnchorEl);
    const guestNavigation = [{ text: 'Log In', icon: <BusinessIcon />, path: '/user/login' }];
    const { pathname } = useLocation();
    const [accountButtonBorderHidden, setAccountButtonBorderHidden] = React.useState(true);
    const [changePasswordResponseSuccess, setChangePasswordResponseSuccess] = React.useState(undefined);
    const {mutate: changePassword} = useChangePassword(
        () => {setChangePasswordResponseSuccess(true)},
        () => {setChangePasswordResponseSuccess(false)}
    );
    const userHaveBrokerPermissions = Permissions.isLoginAllowed(userPermissions) && user?.is_bp_user
    const userHaveSubmissionWritePermissions = usePermissions()([Permissions.SUBMISSION_WRITE]);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleOnClose = () => {
        setAnchorEl(null);
    };

    const handleChangePasswordPopupOnClose = () => {
        setChangePasswordPopup(false);
        setTimeout(() => setChangePasswordResponseSuccess(undefined), 500);
    }

    const handleAdminNav = event => {
        setadminNavAnchorEl(event.currentTarget);
    };

    const handleAdminNavOnClose = () => {
        setadminNavAnchorEl(null);
    };

    const handleAccountButtonBorderHidden = () => {
        setAccountButtonBorderHidden(!accountButtonBorderHidden);
    };

    const logout = async () => {
        handleOnClose();
        handleAdminNavOnClose();
        Intercom.endChat();
        FullStory.anonymize();
        dispatch(setToken({ token: null, tokenExpiration: 3600 }));
        window.location.href = '/svc/api/gui/v2/auth/auth_logout';
    };

    const changePasswordDropdownHandler = () => {
        handleOnClose();
        setChangePasswordPopup(true);
    };

    const changePasswordHandler = () => {
        changePassword();
    }

    const getUsernameFirstChar = () => {
        return isAuthenticated && user ? user.username.charAt(0).toUpperCase() : '';
    };

    const getUserTeamName = () => {
        return isAuthenticated && user ? user.team?.name : '';
    };

    const navigation = useMemo(() => {
        return getNavigation(
            userPermissions,
            partnership,
            handleAdminNav,
            userHaveBrokerPermissions,
            userHaveSubmissionWritePermissions,
        );
    }, [userPermissions, partnership]);

    return (
        <>
            <Styled.AppBar>
                <Styled.Toolbar>
                    <Styled.Logo hidden={!mobileSearchBarHidden}>
                        <StyledLink.Default to="/dashboard">
                            <Logo size={40} />
                        </StyledLink.Default>
                    </Styled.Logo>
                    {teamView && mobileSearchBarHidden && (
                        <OverflowTip
                            text={getUserTeamName()}
                            component={textTooltipElementRef => {
                                return (
                                    <Styled.TeamName>
                                        <Typography noWrap variant="subtitle2" ref={textTooltipElementRef}>
                                            {getUserTeamName()}
                                        </Typography>
                                    </Styled.TeamName>
                                )
                            }}
                        />
                    )}
                    {isAuthenticated ? (
                        <>
                            <Hidden mdDown>
                                <DesktopNav
                                    navigation={navigation}
                                    handleMouseOver={handleAdminNav}
                                    hideSearch={!userHaveBrokerPermissions}
                                />
                                <Styled.AdminNavPopover
                                    id="admin-nav"
                                    anchorEl={adminNavAnchorEl}
                                    open={adminNavOpen}
                                    onClick={handleAdminNavOnClose}
                                    onClose={handleAdminNavOnClose}
                                    PaperProps={{ onMouseLeave: handleAdminNavOnClose }}
                                    disableAutoFocus
                                    disableEnforceFocus>
                                    {getAdminSubNav.map((subNav, i) => (
                                        <DropdownItem
                                            href={subNav.path}
                                            text={<Typography variant="subtitle2">{subNav.text}</Typography>}
                                            key={i}
                                        />
                                    ))}
                                </Styled.AdminNavPopover>
                                <Box marginRight="15px" sm={1}>
                                    <Styled.UserBox>
                                        <Styled.AccountIconButtonBorder hidden={accountButtonBorderHidden} />
                                        <Styled.AccountIconButton
                                            onClick={handleMenu}
                                            onMouseEnter={handleAccountButtonBorderHidden}
                                            onMouseLeave={handleAccountButtonBorderHidden}
                                            edge="end"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            color="inherit">
                                            {getUsernameFirstChar()}
                                        </Styled.AccountIconButton>
                                    </Styled.UserBox>
                                    <Styled.AccountPopover
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={open}
                                        onClose={handleOnClose}
                                        disableAutoFocus
                                        disableEnforceFocus>
                                        <DropdownHeader text={user?.username} />
                                        <DropdownItem onClickHandler={changePasswordDropdownHandler} text="Change Password" key="0" />
                                        <DropdownItem onClickHandler={logout} text="Logout" key="3" />
                                    </Styled.AccountPopover>
                                </Box>
                            </Hidden>
                            <Hidden lgUp>
                                <Box width="100%">
                                    <Styled.UserBox
                                        onClick={() => setSideMenuOpen(true)}
                                        hidden={!mobileSearchBarHidden}>
                                        <Styled.MobileMenuIcon />
                                    </Styled.UserBox>
                                    <Styled.UserBox
                                        onClick={() => setMobileSearchBarHidden(false)}
                                        hidden={!mobileSearchBarHidden}>
                                        {!Permissions.isAdmin(userPermissions) && <Styled.MobileSearchIcon />}
                                    </Styled.UserBox>
                                    {!Permissions.isAdmin(userPermissions) && <SearchBar hidden={mobileSearchBarHidden} />}
                                    <Styled.UserBox
                                        isClearIcon={true}
                                        onClick={() => setMobileSearchBarHidden(true)}
                                        hidden={mobileSearchBarHidden}>
                                        <Styled.MobileSearchClearIcon />
                                    </Styled.UserBox>
                                </Box>
                            </Hidden>
                        </>
                    ) : (
                        pathname !== '/user/login' && <DesktopNav guest={true} navigation={guestNavigation} />
                    )}
                </Styled.Toolbar>
            </Styled.AppBar>
            <ChangePasswordPopup
                open={changePasswordPopup}
                handleClose={handleChangePasswordPopupOnClose}
                handleSubmit={changePasswordHandler}
                successResponse={changePasswordResponseSuccess}
            />
            {isAuthenticated && (
                <MobileNav
                    open={sideMenuOpen}
                    onClose={() => setSideMenuOpen(false)}
                    onLogout={logout}
                    onChangePassword={changePassword}
                    userName={user?.username}
                    navigation={navigation}
                    isAdmin={Permissions.isAdmin(userPermissions)}
                />
            )}
        </>
    );
};

export default Header;
