import React from 'react';
import { SubmissionsBucket } from 'utils/enums';


const ProductTextComponent = ({ policyRequest }) => {
    return (
        <>
            {policyRequest.athena_submissions_bucket === SubmissionsBucket.DECLINED ? (
                'Quotes are closed'
            ) : [SubmissionsBucket.EXPIRED, SubmissionsBucket.LOST].includes(policyRequest.athena_submissions_bucket) ? (
                'Quotes have expired'
            ) : (
                'Policy has been canceled'
            )}
        </>
    )
}


export default ProductTextComponent;
