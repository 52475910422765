import styled from 'styled-components';

const Span = styled.span`
    display: block;
    &&& {
        > button {
            line-height: 1.2;
        }
        > button.secondary {
            background: rgba(32, 32, 238, 0.1);
            color: ${({ theme }) => theme.palette.primary.main};
        }
    }
`;

export default {
    Span,
};
