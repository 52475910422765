import React from 'react';
import { useHistory } from 'react-router-dom';
import Styled from './styled-components';
import { RoundedButton, StyledAction } from 'components';
import CloseIcon from '@material-ui/icons/Close';
import { Box, IconButton, Typography, Link } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';

const EditApplicationPopup = ({ open, handleClose, policyRequestID }) => {
    const history = useHistory();
    const resubmit = () => {
        history.push({pathname: `/questionnaire/policy/${policyRequestID}`, state: {editMode: true}});
    };

    return (
        <Styled.Dialog open={open} onClose={handleClose} fullWidth>
            <Styled.DialogTitle>
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    fontWeight="fontWeightBold"
                    alignItems="flex-start">
                    <Box>
                        <Typography variant="h6">Edit Application</Typography>
                    </Box>
                    <IconButton onClick={handleClose} data-testid="close-popup">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Styled.DialogTitle>
            <Styled.DialogContent>
                <Box px={[0, 0, 4]} my={[0, 0, 2]} mb={5}>
                    <Box fontWeight="fontWeightLight"pb={3}>
                        <Box component={Typography} variant="body1" pb={1}>
                            Editing application answers may affect premiums and coverage. Any previous quotes using
                            outdated application answers will be revoked and no longer accessible or bindable.
                        </Box>
                        <Typography variant="body1">
                            To only edit the company name, please Cancel and use the pencil icon <CreateIcon viewBox="0 -2 24 24"/> at the top of the page.
                        </Typography>
                        <Box component={Typography} variant="body1" pt={1}>
                            For more details, visit our
                            <Link href="https://at-bay.com/articles/how-to-edit-an-application/"> Knowledge Center.</Link>
                        </Box>
                    </Box>
                </Box>
            </Styled.DialogContent>
            <Box
                component={Styled.DialogActions}
                display="flex"
                justifyContent={['space-between', 'space-between', 'flex-end']}>
                <Box component={StyledAction.Base} onClick={handleClose} mr={2} fontWeight="fontWeightBold">
                    <Typography color="primary" variant="subtitle2">
                        Cancel
                    </Typography>
                </Box>
                <RoundedButton onClick={resubmit} color="primary" py={0.3} data-track="continue_edit_application">
                    <Typography variant="subtitle2">Continue</Typography>
                </RoundedButton>
            </Box>
        </Styled.Dialog>
    );
};

export default EditApplicationPopup;
