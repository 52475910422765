import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { totalHumanize } from 'utils';
import { StyledTypography, SlideLeft } from 'components';
import Styled from './styled-components';
import {
    Box,
    Dialog,
    Typography
} from '@material-ui/core';

const PolicyDataDialog = ({ quote, policyRequest, children, open, footer, centerContent, showDisclaimer, label, headerExtraText = '' }) => {
    const {
        optionToQuote
    } = useSelector(state => state.company);
    const storedQuote = optionToQuote?.quote || {};
    const { policy_request: storedPolicyRequest } = storedQuote;
    const usedPolicyRequest = policyRequest || storedPolicyRequest;
    const usedQuote = quote || storedQuote;
    const [scrolled, setScrolled] = useState(false);
    const content = useRef(null);
    const handleScroll = e => {
        setScrolled(!!content.current.scrollTop);
    }

    return (
        <Dialog
            fullScreen
            scroll="paper"
            open={open}
            TransitionComponent={SlideLeft}>
            <Box component={Styled.DialogTitle}
                className={scrolled ? 'scrolled' : null}
                borderBottom={scrolled ? null : '2px solid #F2F2F2'}>
                <Box fontWeight="fontWeightBold">
                    <StyledTypography.FadedTypography variant="subtitle1">
                        {usedPolicyRequest.company_name}
                    </StyledTypography.FadedTypography>
                </Box>
                <Box fontWeight="fontWeightBold">
                    <Typography variant="subtitle1" component="span" color="primary">
                        {usedPolicyRequest.product.name}
                        {' '}
                        Bind $
                        {totalHumanize(usedQuote.aggregate_limit)}
                    </Typography>
                    {scrolled && (
                        <Typography variant="subtitle1" component="span">
                            <StyledTypography.FadedTypography component="span">
                                {' / '}
                            </StyledTypography.FadedTypography>
                            {headerExtraText}
                        </Typography>
                    )}
                </Box>
                {!scrolled && (
                    <Box fontWeight="fontWeightBold">
                        <Typography variant="h5">
                            {headerExtraText}
                        </Typography>
                    </Box>
                )}
            </Box>
            <Box component={Styled.DialogContent}
                ref={content}
                fontWeight="fontWeightLight"
                display={centerContent ? 'flex' : 'block'}
                justifyContent={centerContent ? 'center' : null}
                onScroll={handleScroll}>
                {children}
            </Box>
            <Styled.DialogActions disableSpacing>
                {showDisclaimer && (
                    <Box px={4} mb={2} textAlign="center">
                        <Typography variant="body2">
                            Updates to the {label.replace(/_/g, ' ').toLowerCase()} date may result in additional contingencies.
                            Please carefully review all contingencies prior to binding or requesting to bind online.
                        </Typography>
                    </Box>
                )}
                <Box>
                    {footer}
                </Box>
            </Styled.DialogActions>
        </Dialog>
    );
}

export default PolicyDataDialog;
