import React, { createContext, useState } from 'react';


const initialContextState = {
    openEditApplicationPopup: false,
    setOpenEditApplicationPopup: () => undefined,
};

export const UponRenewalPolicyContext = createContext(initialContextState);

export const UponRenewalPolicyContextProvider = ({ children }) => {

    const [openEditApplicationPopup, setOpenEditApplicationPopup] = useState(false);
    const [policyRequestId, setPolicyRequestId] = useState(undefined);
    const [currentPolicyExpirationDate, setCurrentPolicyExpirationDate] = useState(undefined);

    return (
        <UponRenewalPolicyContext.Provider
            value={{
                openEditApplicationPopup,
                setOpenEditApplicationPopup,
                policyRequestId,
                setPolicyRequestId,
                currentPolicyExpirationDate,
                setCurrentPolicyExpirationDate,
            }}>
            {children}
        </UponRenewalPolicyContext.Provider>
    );
};
