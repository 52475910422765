import styled from 'styled-components';
import { Box } from '@material-ui/core';
import {
    StyledLink,
} from 'components';


const Footer = styled.footer`
    &&& {
        color: #999;
        width: 100%;
        margin: auto auto 0;
        padding-bottom: 47px;
        text-align: center;
        font-size: 0.75rem;

        ${({ theme }) => `
            ${theme.breakpoints.down('md')} {
                display: flex;
                flex-direction: column;
                padding-bottom: 20px;
                line-height: 1.5;
            }

            ${theme.breakpoints.up('lg')} {
                & > span:last-child:before {
                    content: '|';
                    margin-left: 10px;
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        `}
    }
`;

const Link = styled(StyledLink.Anchor)`
    &&& {
        margin-left: 10px;
    }
`;

const FooterWrapper = styled(Box)`
    &&& {
        color: #191919;
        max-width: ${({ theme }) => theme.breakpoints.values.container}px;
    }
`;



export default {
    Footer,
    Link,
    FooterWrapper,
};
