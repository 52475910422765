import styled from 'styled-components';
import { default as _Tab } from '@material-ui/core/Tab';
import { default as _Tabs } from '@material-ui/core/Tabs';
import { default as _Paper } from '@material-ui/core/Paper';


const Paper = styled(_Paper)`
    &&& {
        padding: 0 62px 0 72px;
        box-shadow: none;
        border-radius: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-size: 100% 6px;
        background-repeat: no-repeat;
        background-position: center bottom;
        ${({ theme }) => `
            ${theme.breakpoints.down('sm')} {
                padding: 0 32px 0 32px;
            }
        `}
    }
`;

const Tabs = styled(_Tabs)`
    &&& {
        .MuiTabs-indicator {
            background: linear-gradient(
                90deg,
                rgba(255,255,255,1) 0%,
                rgba(255,255,255,1) 5%,
                rgba(0,0,0,1) 5%,
                rgba(0,0,0,1) 95%,
                rgba(255,255,255,1) 95%,
                rgba(255,255,255,1) 100%);
            height: 6px;
        }
    }`;

const Tab = styled(_Tab)`
    &&& {
            font-family: 'America';
            font-weight: 300;
            font-size: 16px;
            text-transform: none;
            padding: 15px 8px;
            min-width: 0;
    }
    &.MuiTab-textColorInherit.Mui-selected {
        font-weight: 700;
    }
`;

export default { Tab, Tabs, Paper }
