import React from 'react';
import { useEnv } from 'hooks';
import { formatPolicyNumber } from 'utils';
import { SubmissionsBucket } from 'utils/enums';


const ProductText = ({ policyRequest }) => {
    const { mode } = useEnv();
    return (
        <>
            {policyRequest.athena_submissions_bucket === SubmissionsBucket.BIND_REQUESTED ? (
                'Bind request in progress'
            ) : (
                `${policyRequest.conditionally_bound ? 'Conditional Binder' : 'Policy'} #${formatPolicyNumber(mode, policyRequest.hsb_policy_number)}`
            )}
        </>
    )
}


export default ProductText;
