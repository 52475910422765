import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { newAuthState } from './types';

const initialState: newAuthState = {
    isAuthenticated: undefined,
};
const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthenticated(state, action: PayloadAction<newAuthState>) {
            state.isAuthenticated = action.payload.isAuthenticated;
        },
    },
});

export const { setAuthenticated } = auth.actions;
export default auth.reducer;
