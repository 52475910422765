import React from 'react';
import LoadingCoverages from './components/LoadingCoverages';
import LoadedCoverages from './components/LoadedCoverages/index';
import { useBreakpoint } from 'hooks';
import Styled from '../styled-components';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';


const CoveragesWindow = ({handleCalculation, filterAllowedValues}) => {
    const { customizationLoading, customizedOptionToQuote } = useSelector(state => state.company);
    const borderColor = customizedOptionToQuote?.quote.policy_request.product.background_color;
    const isSm = useBreakpoint('down', 'sm');

    return (
        <Box component={Styled.LongContainer} borderTop={`6px solid ${borderColor || 'transparent'}`}>
            <Box style={isSm ? { display: 'flex', flexDirection: 'column', alignItems: 'center' } : {}}>
                <Box fontWeight={700} mb={1.5} width="312px">
                    <Typography variant="h6">
                        Included Coverages
                    </Typography>
                </Box>
                {customizationLoading.loadingCoverages || !customizedOptionToQuote ?
                    <LoadingCoverages /> :
                    <LoadedCoverages
                        handleCalculation={handleCalculation}
                        filterAllowedValues={filterAllowedValues}
                    />}
            </Box>
        </Box>
    )
}

export default CoveragesWindow;
