import styled from 'styled-components';

const Span = styled.span`
    display: block;
    &&& {
        > button {
            line-height: 1.2;
        }
        > button.primary {
            background: ${({ theme }) => theme.palette.primary.main};
            color: white;
            z-index: 100;
            left: -27px;
        }
        > button.secondary {
            background: rgba(32, 32, 238, 0.1);
            color: ${({ theme }) => theme.palette.primary.main};
            padding-right: 50px;
        }
        > button.secondary::before {
            content: '';
            position: absolute;
            width: 40px;
            height: 110%;
            border-radius: 300px;
            background: white;
            right: -8px;
        }
    }
`;

export default {
    Span,
};
