import React, { useState } from 'react';
import { Loading, StyledAction, StyledTypography } from 'components';
import { totalHumanize } from 'utils';
import { getRelevantPacketTemplateType } from 'utils/policyRequest';
import { DocumentMonitor } from 'components/option-to-quote';
import OptionToQuote from './OptionToQuote';
import { Box, Typography, TableBody, Table } from '@material-ui/core';
import NextSteps from 'components/policy/next-steps/QuotedNextSteps';


const Mobile = ({ policyRequest }) => {
    const [selectedOptionToQuote, setSelectedOptionToQuote] = useState(policyRequest.option_to_quotes[0]);
    const packetTemplateType = getRelevantPacketTemplateType(policyRequest);
    const generatedPacket = selectedOptionToQuote.quote[`${packetTemplateType}_generated_packet`];

    return (
        <>
            <Box borderBottom={`4px solid ${policyRequest.product.background_color}`} my={2} />
            <Box display="flex" alignItems="center" flexWrap="wrap">
                {policyRequest.option_to_quotes.map(optionToQuote => {
                    return (
                        <Box key={optionToQuote.id}
                            fontWeight={optionToQuote.id === selectedOptionToQuote.id ? 'fontWeightMedium' : 'fontWeightLight'}
                            component={StyledAction.Base}
                            borderBottom={optionToQuote.id === selectedOptionToQuote.id ? '2px solid #1A1A1A' : 'none'}
                            onClick={() => setSelectedOptionToQuote(optionToQuote)}
                            mt={0.5} mr={2} pb={optionToQuote.id === selectedOptionToQuote.id ? 1.75 : 2}>
                            {optionToQuote.id === selectedOptionToQuote.id ? (
                                <Typography component="span" variant="subtitle1">
                                    ${totalHumanize(optionToQuote.quote.aggregate_limit)}
                                </Typography>
                            ) : (
                                <StyledTypography.FadedTypography component="span" variant="subtitle1">
                                    ${totalHumanize(optionToQuote.quote.aggregate_limit)}
                                </StyledTypography.FadedTypography>
                            )}
                        </Box>
                    )
                })}
                <Box display={policyRequest.inProgress ? undefined : 'none'} position="relative" height="30px" width="30px" mt={-2}>
                    <Loading size="16px" color="inherit" />
                </Box>
            </Box>
            <DocumentMonitor
                optionToQuote={selectedOptionToQuote}
                policyRequest={policyRequest}
                monitor={!generatedPacket} />
            <OptionToQuote optionToQuote={selectedOptionToQuote} policyRequest={policyRequest} />
            <Box component={props => <Table padding="none" {...props} />} fontWeight="fontWeightLight">
                <TableBody>
                    <NextSteps policyRequest={policyRequest} />
                </TableBody>
            </Box>
        </>
    );
}


export default Mobile;
