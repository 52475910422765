import React, { useEffect, useRef, useState, useMemo } from 'react';
import Script from 'react-load-script';
import { Box, InputAdornment } from '@material-ui/core';
import GeneralStyled from '../../../components/styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setQuestionValue } from 'stores/questions/questionsSlice';
import { setNewQuestionData } from 'stores/questionsErrors/questionsErrorsSlice';
import Markdown from 'markdown-to-jsx';
import InfoTooltip from '../../../components/Tooltip';
import {
    addAnswer,
    setQuestionnaireStateToAnswerChanging,
    setQuestionnaireStateToChangesSaved,
} from 'stores/questionnaire/questionnaireSlice';
import { useEnv, useTextQuestionStyles } from 'hooks';
import { questionHasError, scrollToQuestionError } from 'utils/questionnaireSequence';
import Styled from 'modules/questionnaire/pages/new-questionnaire/components/questions/styled-components';
import { usePermissions } from 'hooks/usePermissions';
import { Permissions } from 'utils/enums';

const AutoComplete = ({
    question,
    updateForm,
    visible,
    setScroll,
    scroll,
    setEditAnswerMode,
    setSubmissionDisabled,
    setCheckTriedToSubmit,
    setChangeMade,
}) => {
    const questionRef = useRef();
    const inputFieldRef = useRef();
    const placeholderLabelRef = useRef();
    const autocomplete = useRef();
    const dispatch = useDispatch();
    const answer = useSelector(state => state.questions?.questionsToAnswers[question.id]);
    const questionMetaData = useSelector(
        state => state.questionnaire.questionsMetadata.find(item => item.questionId === question.id)?.metadata,
    );
    const { firstQuestionWithError, submitFailed } = useSelector(state => state.questionErrors);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState('');
    const [value, setValue] = useState(answer ? JSON.parse(answer).formatted_address : '');
    const [firstChange, setFirstChange] = useState(true);
    const [selected, setSelected] = useState(Boolean(answer));
    const [shrink, setShrink] = useState(Boolean(value));
    const [open, setOpen] = useState(false);
    const [requiredError, setRequiredError] = useState(questionHasError(submitFailed, value, question.required, true));
    const submissionWriteAuthorized = usePermissions()([Permissions.SUBMISSION_WRITE]);
    const { googleMapPlacesKey } = useEnv();
    const placeholder = useMemo(() => {
        return value || shrink ? '' : question.question;
    }, [value, shrink, question.question]);
    const [inputStyles, inputComponentStyles] = useTextQuestionStyles(placeholder, placeholderLabelRef, inputFieldRef);
    const url = `https://maps.googleapis.com/maps/api/js?key=${googleMapPlacesKey}&libraries=places`;

    useEffect(() => {
        if (value) {
            dispatch(addAnswer(question.id));
        }
    }, []);

    useEffect(() => {
        dispatch(
            setNewQuestionData({
                questionId: question.id,
                hasError: questionHasError(submitFailed, value, question.required, visible) || error,
            }),
        );
    }, [submitFailed, error, visible]);

    useEffect(() => {
        if (scroll && firstQuestionWithError === question.id) {
            scrollToQuestionError(questionRef);
            inputFieldRef.current.focus();
            setScroll(false);
        } else if (!scroll) {
            setRequiredError(questionHasError(submitFailed, value, true, visible));
        }
    }, [firstQuestionWithError, scroll]);

    useEffect(() => {
        let formattedAnswer = '';
        if (answer) {
            try {
                formattedAnswer = JSON.parse(answer).formatted_address;
            } catch (err) {
                // use default formatted answer
            }
        }
        setValue(formattedAnswer);
    }, [answer]);

    const handleScriptLoad = () => {
        setLoaded(true);
        const options = {
            types: ['address'],
        };
        /*global google*/
        autocomplete.current = new google.maps.places.Autocomplete(
            document.getElementById(`autocomplete_${question.id}`),
            options,
        );
        autocomplete.current.setFields(['address_components', 'formatted_address']);
        autocomplete.current.addListener('place_changed', handlePlaceSelect);
    };

    const handlePlaceSelect = () => {
        const addressObject = autocomplete.current.getPlace();
        const address = addressObject.address_components;
        if (!address) {
            return;
        }
        setError('');
        setSubmissionDisabled(false);
        setSelected(true);
        // Check if address is valid
        const postalCodes = addressObject.address_components
            .filter(it => it.types.indexOf('postal_code') !== -1)
            .map(it => it.long_name);
        if (address) {
            const answer = JSON.stringify({
                formatted_address: addressObject.formatted_address,
                component_address: address,
            });
            const newValue = JSON.parse(answer).formatted_address || address;
            setValue(newValue);
            dispatch(setQuestionValue({ name: question.id, value: answer }));
            if (postalCodes.length > 0) {
                updateForm({
                    questionId: question.id,
                    answerId: question.optional_answers[0].id,
                    answerType: question.optional_answers[0].type,
                    value: answer,
                    invalid: false,
                    required: question.required,
                    questionType: question.type,
                });
            } else {
                setError('Addresses without a zip code are not supported.');
            }
        } else {
            updateForm({
                questionId: question.id,
                answerId: question.optional_answers[0].id,
                answerType: question.optional_answers[0].type,
                value: '',
                invalid: false,
                required: question.required,
                questionType: question.type,
            });
        }
    };

    const handleOnChange = newValue => {
        setEditAnswerMode(true);
        setRequiredError(false);
        dispatch(setNewQuestionData({ questionId: question.id, hasError: false || error }));
        let address = newValue;
        try {
            if (newValue === '') {
                address = '';
            } else {
                address = JSON.parse(newValue).formatted_address || newValue;
            }
        } catch {}
        setValue(address);
        setSelected(false);
        setChangeMade(true);
        if (firstChange) {
            setFirstChange(false);
            dispatch(setQuestionnaireStateToAnswerChanging());
        }
    };

    const handleBlur = val => {
        if (firstChange && !val) {
            return;
        }
        setRequiredError(questionHasError(submitFailed, val, true, visible));
        dispatch(
            setNewQuestionData({
                questionId: question.id,
                hasError: questionHasError(submitFailed, val, true, visible) || error,
            }),
        );
        dispatch(setQuestionnaireStateToChangesSaved());
        setFirstChange(true);
        if (val) {
            if (!selected) {
                setError('Please select an address from the list of suggested addresses.');
                setEditAnswerMode(false);
                setSubmissionDisabled(true);
                setCheckTriedToSubmit(true);
            }
        } else {
            setError('');
            setShrink(false);
            updateForm({
                questionId: question.id,
                answerId: question.optional_answers[0].id,
                answerType: question.optional_answers[0].type,
                value: null,
                invalid: false,
                required: question.required,
                questionType: question.type,
            });
        }
    };

    return (
        <Box width="100%" ref={questionRef}>
            {!loaded && <Script url={url} onLoad={handleScriptLoad} />}
            <GeneralStyled.TextField
                inputRef={inputFieldRef}
                autoFocus={submissionWriteAuthorized ? Boolean(!value) : false}
                label={
                    <Markdown variant="body1">{(question.required ? '' : '(Optional) ') + question.question}</Markdown>
                }
                fullWidth
                id={`autocomplete_${question.id}`}
                type="text"
                value={value}
                onFocus={() => setShrink(true)}
                onChange={e => handleOnChange(e.target.value)}
                onBlur={e => handleBlur(e.target.value)}
                error={Boolean(error) || requiredError}
                helperText={error ? error : requiredError ? 'This field is required' : ''}
                placeholder={placeholder}
                InputLabelProps={{
                    shrink,
                    ref: placeholderLabelRef,
                }}
                inputProps={{
                    'data-track': `questionnaire_question_${question.id}`,
                    style: placeholder ? inputStyles.withPlaceholder : inputStyles.withoutPlaceholder,
                }}
                InputProps={{
                    style: placeholder ? inputComponentStyles.withPlaceholder : inputComponentStyles.withoutPlaceholder,
                    endAdornment: (
                        <InputAdornment position="end">
                            {question.application_parameters.help_text && (
                                <InfoTooltip
                                    dataTrack={`questionnaire_tooltip_${question.id}`}
                                    placement="bottom-end"
                                    open={open}
                                    setOpen={setOpen}
                                    text={question.application_parameters.help_text}
                                    select={false}
                                />
                            )}
                            {questionMetaData?.savingQuestion && !error && <Styled.CircularProgress size={15} />}
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
};

export default AutoComplete;
