import React from 'react'
import {useQuery} from '../../../../hooks';


const AuthLogin = () => {
    const show_forgot_password = useQuery().get('show_forgot_password');
    let url = '/svc/api/gui/v2/auth/auth_login';
    if (show_forgot_password) {
        url = `${url}?show_forgot_password=true`
    }

    window.location.href = url;
    return <></>;
};

export default AuthLogin;
