import React from 'react';
import { Box } from '@material-ui/core';


const IconBox = ({ sizes, children, ...props }) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={sizes}
            width={sizes}
            minHeight={sizes}
            minWidth={sizes}
            maxHeight={sizes}
            maxWidth={sizes}
            {...props}
        >
            {children}
        </Box>
    )
}

export default IconBox;
