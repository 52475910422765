import styled from 'styled-components';
import { default as _FormControl } from '@material-ui/core/FormControl';

const Form = styled.form`
    &&& {
        text-align: center;
    }
`;

const FormControl = styled(_FormControl)`
    &&& {
        max-width: 308px;
        margin: 0 auto 15px;
        display: block;
        text-align: left;
        font-size: 12px;
    }
`;

export default {
    Form,
    FormControl,
};
