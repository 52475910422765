import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { Box, IconButton, Typography, FormControl } from '@material-ui/core';
import { RoundedButton, StyledAction, StyledInput } from 'components';
import { isValidDomain } from 'utils';
import Styled from './styled-components';
import { CreateSubmissionFormType } from 'utils/enums';
import { useCreateSubmission } from '../../api/dashboard/mutations/useCreateSubmission';

const initialFormState = {
    company_name: '',
    url: '',
};

const BreachCalculatorPopup = ({ open, handleClose }) => {
    const history = useHistory();
    const [formState, setFormState] = useState(initialFormState);
    const [invalidDomain, setInvalidDomain] = useState(false);
    const [websiteError, setWebsiteError] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [companyViewable, setCompanyViewable] = useState(false);
    const [existingCompanyId, setExistingCompanyId] = useState(null);
    const [labelCompany, setLabelCompany] = useState('Company Name...');
    const [labelWebsite, setLabelWebsite] = useState('Website...');
    const [productExistsError, setProductExistsError] = useState(false);
    const [matchCompaniesError, setMatchCompaniesError] = useState(false);
    const [companyNameError, setCompanyNameError] = useState(false);

    const mainError = useMemo(() => {
        return productExistsError
            ? 'Submission already exists'
            : matchCompaniesError
            ? 'Name and Website match different accounts'
            : '';
    }, [productExistsError, matchCompaniesError]);

    const handleOnSubmitRequestSuccess = submission => {
        history.push(`/company/calculator/${submission.company_id}`);
    };

    const handleOnSubmitRequestFail = error => {
        if (error.response?.data?.url) {
            setWebsiteError(true);
        }
        setExistingCompanyId(error.response.data?.existing_company_id);
        setCompanyViewable(error.response.data?.company_viewable);
        setProductExistsError(error.response?.data?.product_id === 'product_already_exists');
        setMatchCompaniesError(
            error.response?.data?.match_different_accounts > 1 ||
                (error.response?.data?.url === 'domain_exists' &&
                    error.response.data?.invalid_company_name &&
                    error.response?.data?.product_id !== 'product_already_exists'),
        );
        setCompanyNameError(error.response.data?.invalid_company_name ? true : false);
    };

    const { mutate: createPolicyRequest, loading } = useCreateSubmission(
        handleOnSubmitRequestSuccess,
        handleOnSubmitRequestFail,
    );

    useEffect(() => {
        setDisabled(Object.values(formState).some(value => !String(value).trim()) || !isValidDomain(formState.url));
    }, [formState]);

    const updateFormState = e => {
        setFormState({ ...formState, [e.target.name]: e.target.value });
        setWebsiteError(false);
        setMatchCompaniesError(false);
        setProductExistsError(false);
        setCompanyNameError(false);
    };

    const validateDomain = () => {
        if (formState.url) {
            setInvalidDomain(!isValidDomain(formState.url));
            setLabelWebsite('Website');
        } else {
            setLabelWebsite('Website...');
        }
    };

    const handleNext = async () => {
        createPolicyRequest({ ...formState, form_name: CreateSubmissionFormType.BREACH_CALCULATOR });
    };

    const handleSubmit = () => {
        productExistsError && companyViewable ? history.push(`/company/${existingCompanyId}`) : handleNext();
    };

    const reset = () => {
        setDisabled(true);
        setLabelCompany('Company Name...');
        setLabelWebsite('Website...');
        setFormState(initialFormState);
        setWebsiteError(false);
        setMatchCompaniesError(false);
        setProductExistsError(false);
        setCompanyNameError(false);
    };

    const closePopup = () => {
        reset();
        handleClose();
    };

    return (
        <Styled.Dialog open={open} onClose={closePopup}>
            <Styled.DialogTitle>
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    fontWeight="fontWeightBold"
                    alignItems="flex-start">
                    <Box>
                        <Typography variant="subtitle1">Data breach calculator</Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Styled.DialogTitle>
            <Styled.DialogContent>
                <Typography variant="body1">Which company do you want to generate a scenario for ?</Typography>
                <Box mt={2}>
                    <FormControl fullWidth>
                        <StyledInput.FormTextField
                            required
                            name="company_name"
                            label={labelCompany}
                            onFocus={() => setLabelCompany('Company Name')}
                            onBlur={() =>
                                formState.company_name
                                    ? setLabelCompany('Company Name')
                                    : setLabelCompany('Company Name...')
                            }
                            value={formState.company_name}
                            onChange={updateFormState}
                            error={companyNameError}
                        />
                    </FormControl>
                </Box>
                <Box mt={2} mb={3}>
                    <FormControl fullWidth>
                        <StyledInput.FormTextField
                            required
                            error={invalidDomain || websiteError}
                            onBlur={validateDomain}
                            name="url"
                            label={labelWebsite}
                            value={formState.url}
                            onChange={updateFormState}
                            onFocus={() => setLabelWebsite('Website')}
                        />
                    </FormControl>
                </Box>
            </Styled.DialogContent>
            <Box display="flex" flexDirection="column" pb={3}>
                <Typography color="error" align="center" variant="body2">
                    {mainError}
                </Typography>
                <Box
                    component={Styled.DialogActions}
                    pb={3}
                    display="flex"
                    justifyContent={['space-between', 'space-between', 'flex-end']}>
                    <Box component={StyledAction.Base} onClick={closePopup} mr={[2, 2, 4]} fontWeight="fontWeightBold">
                        <Typography color="primary" variant="subtitle2">
                            {!loading && 'Close'}
                        </Typography>
                    </Box>
                    <RoundedButton
                        onClick={handleSubmit}
                        disabled={disabled || loading}
                        color="primary"
                        height={32}
                        className={productExistsError ? 'black' : null}>
                        <Typography variant="subtitle2">
                            {loading ? 'Please wait...' : productExistsError ? 'Go to Client Details' : 'Next'}
                        </Typography>
                    </RoundedButton>
                </Box>
            </Box>
        </Styled.Dialog>
    );
};

export default BreachCalculatorPopup;
