import React from 'react';
import Styled from './styled-components';
import { Typography, Box } from '@material-ui/core';


const AdditionalCompanyInfo = ({ companyData }) => {
    return (
        <Styled.EllipsisBox ml={3} mb={0.8} mt={-0.4}>
            <Typography variant="body2" component="div">
                <Box style={{ display: 'inline' }} color="#919191">
                    {companyData.products?.join(', ')}
                    <Box style={{ display: 'inline' }} mx={0.8}>•</Box>
                    {companyData.brokers_full_name?.join(', ')}
                </Box>
            </Typography>
        </Styled.EllipsisBox>
    );
};

export default AdditionalCompanyInfo;
