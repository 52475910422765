import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import PolicyInformation from './components/PolicyInformation';
import { isPolicyRequestInProgress } from 'utils';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { useAxiosClient, useAxiosInstance } from 'hooks';
import { policyRequestQuery } from 'queries/company';
import { partialPolicyRequestQuery } from 'queries/policyRequest';
import PolicyRequestSkeleton from './components/policy-request-skeleton';


export default function PolicyRequestsTab() {
    const { company, } = useSelector(state => state.company);
    const axiosInstance = useAxiosInstance();
    const axiosClient = useAxiosClient();
    const [policiesInformation, setPoliciesInformation] = useState();
    const firstRequestTime = useMemo(() => dayjs.utc().unix(), []);

    // Modified that with react query
    const [, getPoliciesInformation] = axiosClient({
        method: 'post',
        url: '/policyRequest/get_policies_by_current_policies',
        data: {
            policies_id: company.viewable_policy_requests.map(policy => policy.id),
            renewal_policy: true,
            query: partialPolicyRequestQuery,
        },
    }, { manual: true });

    const fetchPoliciesData = async () => {
        const res = await getPoliciesInformation();
        const policiesInformation = res.data.schemas;
        const fullPoliciesInformation = await Promise.all(policiesInformation.map(async policyInformation => {
            let updatedPoliciesInformation = {...policyInformation};
            for (const policyType in policyInformation) {
                if (policyInformation[policyType] &&
                    !isPolicyRequestInProgress(policyInformation[policyType], firstRequestTime)) {
                    const fullPolicyResponse =
                    await axiosInstance.post(`/policyRequest/${policyInformation[policyType].id}`, {
                        query: policyRequestQuery,
                    });
                    updatedPoliciesInformation[policyType] = {...fullPolicyResponse.data};
                }
            }
            return updatedPoliciesInformation;
        }))
        setPoliciesInformation(fullPoliciesInformation);
    }

    useEffect(() => {
        fetchPoliciesData();
    }, []);

    if (!policiesInformation) {
        return (
            <Box>
                {company.viewable_policy_requests.map((_, ind) => (
                    <PolicyRequestSkeleton key={ind} />
                ))}
            </Box>
        );
    }

    return (
        <Box>
            {policiesInformation?.map(policy_schema => (
                <PolicyInformation
                    key={policy_schema.current_policy.id}
                    renewalPolicy={policy_schema.renewal_policy}
                    currentPolicy={policy_schema.current_policy}
                    company={company}
                />
            ))}
        </Box>
    );
}
