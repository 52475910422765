import styled from 'styled-components';
import _Table from '@material-ui/core/Table';
import _TableCell from '@material-ui/core/TableCell';
import _TableContainer from '@material-ui/core/TableContainer';
import _TableRow from '@material-ui/core/TableRow';

const ContentHeader = styled.div`
    &&& {
        display: flex;
        justify-content: space-between;
        padding: 2rem;
    }
`;

const ContentTitle = styled.span`
    &&& {
        font-size: 1.7rem;
    }
`;

const Table = styled(_Table)`
    &&& {
        margin-bottom: 0px;
    }
`;

const TableBodyRow = styled(_TableRow)`
    &&& {
        height: 79px;
    }
`;

const TableContainer = styled(_TableContainer)`
    &&& {
        border-radius: 0;
        box-shadow: none;
    }
`;

const TableHeaderCell = styled(_TableCell)`
    &&& {
        font-size: 0.2rem;
        padding: 0.2rem 1rem;
        font-family: Pressura;
        font-size: 0.625rem;
        font-weight: 400;
        color: #999;
        cursor: pointer;

        &:hover {
            color: #333;
        }
    }
`;

const TableCell = styled(_TableCell)`
    &&& {
        border-bottom: none;
        &.py-1 {
            padding-top: ${({ theme }) => theme.spacing(1)}px;
            padding-bottom: ${({ theme }) => theme.spacing(1)}px;
        }
    }
`;



export default {
    ContentHeader,
    ContentTitle,
    Table,
    TableBodyRow,
    TableCell,
    TableContainer,
    TableHeaderCell,
};
