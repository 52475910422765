import { NodeType, ValueType } from './enums';

export const handleQuestionnaireSequence = (sequence) => {
    const updatedSections = [];
    const answers = {};
    let datapoints = null;
    let section = '';
    for (const node of sequence) {
        if (node.type_ === NodeType.SECTION) {
            if (datapoints !== null) {
                updatedSections.push({ section, datapoints });
            }
            section = node.node;
            datapoints = [];
        } else if (node.type_ === NodeType.NOTE) {
            const datapoint = node.node;
            const choices = node.value_choices;
            const type = NodeType.NOTE;
            datapoints.push({ datapoint, choices, type });
        } else {
            const datapoint = node.node;
            const choices = node.value_choices;
            const type = node.value_type;
            const value = node.value;
            if (value) {
                if (type === ValueType.SINGLE_CHOICE) {
                    const selectedChoice = choices.filter(choice => choice.choice_value === value)[0];
                    answers[datapoint.datapoint_id] = selectedChoice.string_id;
                } else if (
                    [ValueType.MULTIPLE_CHOICES_WITHOUT_NONE, ValueType.MULTIPLE_CHOICES_WITH_NONE].includes(type)
                ) {
                    const selectedChoices = choices.filter(choice => value.includes(choice.choice_value));
                    const selectedChoicesIds = selectedChoices.map(choice => choice.string_id);
                    answers[datapoint.datapoint_id] = selectedChoicesIds;
                } else {
                    answers[datapoint.datapoint_id] = value;
                }
            } else {
                answers[datapoint.datapoint_id] = null;
            }
            datapoints.push({ datapoint, choices, type });
        }
    }
    if (datapoints !== null) {
        updatedSections.push({ section, datapoints });
    }
    return [updatedSections, answers];
};


export const questionsErrorState = sections => {
    const questionErrorState = [];
    for (const section of sections) {
        for (const datapoint of section.datapoints ) {
            questionErrorState.push({datapointID: datapoint.datapoint.datapoint_id, hasError: false});
        }
    }
    return questionErrorState;
}


export const newQuestionsErrorState = sections => {
    const questionErrorState = [];
    for (const section of sections) {
        for (const question of section.questions ) {
            questionErrorState.push({questionId: question.id, hasError: false});
        }
    }
    return questionErrorState;
}

export const questionsMetadataState = sections => {
    const questionMetadataState = [];
    for (const section of sections) {
        for (const question of section.questions ) {
            questionMetadataState.push({questionId: question.id, metadata: {}});
        }
    }
    return questionMetadataState;
}

export const questionHasError = (submitFailed, value, isRequired, visible) => {
    return submitFailed && (!value || value.length === 0) && isRequired && visible;
}

export const scrollToQuestionError = questionRef => {
    const questionTop = questionRef.current.getBoundingClientRect().top;
    const $root = document.querySelector('#root');
    const $innerAppBar = document.querySelector('#innerAppBar');
    const offset = $root.children[1]?.clientHeight;
    const innerAppBarOffset = $innerAppBar ? $innerAppBar.clientHeight : 0;
    const questionToScroll = questionTop + window.pageYOffset - offset - innerAppBarOffset;
    window.scrollTo({ behavior: 'smooth', top: questionToScroll });
}
