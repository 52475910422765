import { useEffect } from 'react';
import store from 'stores/store';
import { setPolicyRequestAttribute } from 'stores/company/companySlice';
import { useDispatch } from 'react-redux';
import { useAxiosInstance } from 'hooks';


const QuotePacketMonitor = ({ policyRequests, monitor }) => {
    const dispatch = useDispatch();
    const axiosInstance = useAxiosInstance();

    const checkIfPacketsAreReady = async () => {
        // since this is a recursive function, we can get here even after the component has been unmounted
        // therefore, we need to check that it's still relevant to run the function by checking the store state
        const state = store.getState();
        if (!state.company.company) {
            return;
        }

        for (const policyRequest of policyRequests) {
            // if packet is generating, check if it's done
            if (policyRequest.generating_quote_packet) {
                const res = await axiosInstance.post(`/policyRequest/${policyRequest.id}`, { query: {generating_quote_packet: {}} });
                // if it's still generating, continue monitoring
                if (res.data.generating_quote_packet) {
                    setTimeout(checkIfPacketsAreReady, 5000);
                    return;
                } else {
                    // if it's done generating then update the policy
                    dispatch(setPolicyRequestAttribute({
                        policyRequestID: policyRequest.id,
                        name: 'generating_quote_packet',
                        value: res.data.generating_quote_packet
                    }));
                }
            } else if (policyRequest.generating_quote_packet === undefined) {
                // if it's undefined it means the page is still loading so we will check again in one sec
                setTimeout(checkIfPacketsAreReady, 1000);
                return;
            }
        }
    }

    useEffect(() => {
        let mounted = true;
        if (monitor && mounted) {
            checkIfPacketsAreReady();
        }
        return () => { mounted = false };
    }, [monitor, policyRequests])

    return null;
}

export default QuotePacketMonitor;
