import React, { useState } from 'react';
import { Box, Hidden, Typography } from '@material-ui/core';
import { NoteValidation } from 'utils/enums';
import { ReactComponent as TextNote } from 'components/icons/text-note.svg';
import UploadDocument from './UploadDocument';
import Styled from '../styled-components';
import { useBreakpoint } from 'hooks';


const ReferralDetailsForm = (
    {
        policyRequest, handleRemoveFile,
        handleNewFiles, uploadedFiles, noteText,
        handleNoteTextChange, handleSubmit, submitLoading,
    }
) => {
    const isSm = useBreakpoint('down', 'sm');
    const [noteErrorMessage, setNoteErrorMessage] = useState(null);

    const handleNoteOnChange = text => {
        if (text.length > NoteValidation.MAX_CHARACTERS) {
            setNoteErrorMessage('Character Limit Reached');
            handleNoteTextChange(text.substring(0, NoteValidation.MAX_CHARACTERS))
            return;
        }
        else if (noteErrorMessage) {
            setNoteErrorMessage(null);
        }
        handleNoteTextChange(text);
    }

    return (
        <>
            <Styled.Box
                borderTop={`4px solid ${policyRequest.product.background_color}`}
                customdisable={submitLoading ? 1 : 0}
                mt={[4, 4, 0]}
            >
                <UploadDocument
                    handleNewFiles={handleNewFiles}
                    handleRemoveFile={handleRemoveFile}
                    uploadedFiles={uploadedFiles}
                    submitLoading={submitLoading}
                />
            </Styled.Box>
            <Styled.Box px={3.5} py={[2.5, 2.5, 3.5]} mt={0.5} customdisable={submitLoading ? 1 : 0}>
                <Box display="flex" width={['auto', 'auto', '89%']}>
                    <Hidden smDown>
                        <Box component={TextNote} mt={1.5} mr={3.5} />
                    </Hidden>
                    <Box width="100%">
                        <Styled.TextField
                            value={noteText}
                            onChange={e => handleNoteOnChange(e.target.value)}
                            disabled={submitLoading}
                            placeholder="Add a note or include a description for the attachment(s)."
                            inputerror={noteErrorMessage ? true : false}
                            multiline
                            variant="outlined"
                            fullWidth
                            size="small"
                        />
                        {noteErrorMessage &&
                        <Styled.errorMessage mt={1}>
                            <Typography variant="body2">
                                {noteErrorMessage}
                            </Typography>
                        </Styled.errorMessage>
                        }
                    </Box>
                </Box>
            </Styled.Box>
            <Styled.Box mt={0.5} customdisable={submitLoading ? 1 : 0}>
                <Box p={[2.75, 3.5]} display="flex" alignItems="center" justifyContent="center">
                    <Styled.RoundedButton
                        fullWidth={isSm}
                        onClick={handleSubmit}
                        width={isSm ? null : '120px'}
                        height="33px"
                        color="primary"
                        py={0.3}
                        data-track="submit_referral_renewal"
                        disabled={!noteText || submitLoading || noteErrorMessage}
                    >
                        <Typography variant="subtitle2">
                            {submitLoading
                                ?
                                <Box display="flex" alignItems="center">
                                    <Styled.CircularProgress size={20}/>
                                </Box>
                                : 'Submit'
                            }
                        </Typography>
                    </Styled.RoundedButton>
                </Box>
            </Styled.Box>
        </>
    )
}


export default ReferralDetailsForm;
