export const quotesSubBuckets = [
    { name: 'Open', text: 'Open' },
    { name: 'Incomplete', text: 'Incomplete' },
    { name: 'Pending', text: 'Pending' },
    { name: 'Bind_Request', text: 'Bind Request' },
    { name: 'Inactive', text: 'Inactive' },
];

export const quotesSubBucketsLimitedPermissions = [
    { name: 'Open', text: 'Open' },
    { name: 'Bind_Request', text: 'Bind Request' },
    { name: 'Inactive', text: 'Inactive' },
];

export const oldPoliciesSubBuckets = [
    { name: 'Active', text: 'Active' },
    { name: 'Renewing', text: 'Renewing' },
    { name: 'Inactive', text: 'Inactive' },
];

export const policiesSubBuckets = [
    { name: 'Active', text: 'Active' },
    { name: 'Inactive', text: 'Inactive' },
];

export const renewalsSubBuckets = [
    { name: 'Rapid', text: 'Rapid' },
    { name: 'Manual', text: 'Manual' },
    { name: 'Not_Renewing', text: 'Not Renewing' },
];
