import React, { useState, useEffect } from 'react';
import { useAxiosClient } from 'hooks';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import BrokerValidateDialog from 'components/popup/BrokerValidateDialog';
import GenericDialog from 'components/popup/GenericDialog';
import { makeToast } from 'utils';
import Styled from './styled-components';


const initialDialogSetting = {
    title: null,
    body: null,
    buttonTitle: null,
    action: null,
    actionId: null
}

const PendingUsers = () => {
    const axiosClient = useAxiosClient();
    const [pendingUsers, setPendingUsers] = useState([]);
    const [genericDialogOpen, setGenericDialogOpen] = useState(false);
    const [validateDialogOpen, setValidateDialogOpen] = useState(false);
    const [validateBrokerId, setValidateBrokerId] = useState(null);
    const [dialogSetting, setDialogSetting] = useState(initialDialogSetting)

    const [{ data, error }, getPendingUsers] = axiosClient({
        method: 'post',
        url: '/user/pending/list',
        data: {
            query: {
                id: {},
                email: {},
                first_name: {},
                last_name: {},
                cell_phone: {},
                company: {
                    name: {}
                }
            }
        }
    }, { manual: true });


    const [{ data: changeStatusData, error: changeStatusError }, changeStatus] = axiosClient({
        method: 'put',
    }, { manual: true });

    useEffect(() => {
        if (data) {
            setPendingUsers(data.pendingUsers);
        }

        if (error) {
            makeToast('error', 'Something went wrong.');
        }
    }, [data, error])

    useEffect(() => {
        if (changeStatusData) {
            getPendingUsers();
        }

        if (changeStatusError) {
            makeToast('error', 'Something went wrong.');
        }

        setGenericDialogOpen(false);
    }, [changeStatusData, changeStatusError])

    useEffect(() => {
        getPendingUsers();
        return () => {}
    }, [])

    const openGenericDialog = (id, action) => {
        let setting = {};
        if (action === 'confirm') {
            setting = {
                title: 'Confirm Broker',
                body: <Box py={[10, 10, 10, 10]}>Are you sure you want to confirm this broker ?</Box>,
                buttonTitle: 'Confirm',
                action,
                actionId: id
            }
        } else {
            setting = {
                title: 'Deny Broker',
                body: <Box py={[10, 10, 10, 10]}>Are you sure you want to deny this broker ?</Box>,
                buttonTitle: 'Deny',
                action,
                actionId: id
            }
        }
        
        setDialogSetting(setting);
        setGenericDialogOpen(true);
    }

    const handleChangeStatue = () => {
        const { action, actionId } = dialogSetting;
        const account_status = (action === 'confirm') ? 'unconfirmed_email' : 'denied'; 
        changeStatus({
            url: `/user/pending/${actionId}`,
            data: {account_status}
        })
    }

    const validateBroker = (id) => {
        setValidateBrokerId(id);
        setValidateDialogOpen(true);
    }

    const closeValidateDialog = () => {
        getPendingUsers();
        setValidateDialogOpen(false);
    }

    return <Box pt={12} component={Styled.Container}>
        <Box width="100%" display="flex" flexDirection="column" mb={2}>
            <Box width="100%" display="flex" justifyContent="space-between">
                <Styled.Typography variant="subtitle2" className="sticky">
                    Pending Users
                </Styled.Typography>
            </Box>
        </Box>
        <TableContainer>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Cell Phone</TableCell>
                        <TableCell>Brokerage Firm</TableCell>
                        <TableCell>Confirm</TableCell>
                        <TableCell>Deny</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pendingUsers.map(row =>
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                            <TableCell>{row.first_name}</TableCell>
                            <TableCell>{row.last_name}</TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.cell_phone}</TableCell>
                            <TableCell>{row.company?.name}</TableCell>
                            <TableCell>
                                {row.company ?
                                    <Button variant="contained" color="secondary" onClick={() => openGenericDialog(row.id, 'confirm')}>
                                        Confirm
                                    </Button>:
                                    <Button variant="contained" color="primary" onClick={() => validateBroker(row.id)}>
                                        Validate
                                    </Button>
                                }
                               
                            </TableCell>
                            <TableCell>
                                <Button variant="contained" onClick={() => openGenericDialog(row.id, 'deny')}>
                                    Deny
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}
                    
                </TableBody>
            </Table>
        </TableContainer>
        <BrokerValidateDialog 
            brokerId={validateBrokerId}
            open={validateDialogOpen} 
            handleClose={closeValidateDialog} />
        <GenericDialog 
            open={genericDialogOpen} 
            title={dialogSetting.title}
            body={dialogSetting.body}
            buttonTitle={dialogSetting.buttonTitle}
            handleClose={() => setGenericDialogOpen(false)}
            handleButton={handleChangeStatue} />
    </Box>;
};

export default PendingUsers;
