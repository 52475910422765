import styled from 'styled-components';
import { Box, CircularProgress as _CircularProgress, } from '@material-ui/core';
import { default as _ListItem } from '@material-ui/core/ListItem';
import { default as _Typography } from '@material-ui/core/Typography';


const ListItem = styled(_ListItem)`
    &&& {
        padding: 0 0 4px 6px;
        margin-left: 15px;
    }

    > span {
        position: relative;
        left: -3px;
        bottom: -2px;
    }

    ::marker {
        display: flex;
        align-items: center;
        font-size: 9px;
    }
`;

const Typography = styled(_Typography)`
    &&& {
        margin: 8px 0 0 26px;
        line-height: 17px;
    }
`;

const HtmlWrapper = styled(Box)`
    &&& {
        line-height: 18px;
        margin-left: 27px;
        margin-top: 11px;
        width: 700px;
        div {
            font-size: 14px !important;
        }
        br + br {
            content: "";
            margin: 2em;
            display: block;
            font-size: 24%;
        }
    }
`

const CircularProgress = styled(_CircularProgress) `
    &&& {
        color: black;
    }
`

const CircularProgressWrapper = styled(Box) `
    &&& {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        margin-right: 20px;
    }
`


export default {
    ListItem,
    Typography,
    HtmlWrapper,
    CircularProgress,
    CircularProgressWrapper
};
