import React from 'react';
import { Typography, Box } from '@material-ui/core';
import SelectRetention from './SelectRetention';
import SelectLimit from './SelectLimit';
import SelectClaimLimit from './SelectClaimLimit';

const Mobile = ({ handleChangeAggregate, handleCalculation, classes }) => {
    return (
        <Box>
            <Box>
                <Box fontWeight={500} mb={1}>
                    <Typography variant="subtitle2">
                        Aggregate Limit & Retention
                    </Typography>
                </Box>
                <Box mb={2} minWidth="318px">
                    <SelectLimit
                        handleChangeAggregate={handleChangeAggregate}
                        classes={classes}
                    />
                    <SelectRetention
                        handleChangeAggregate={handleChangeAggregate}
                        classes={classes}
                    />
                </Box>
            </Box>
            <Box>
                <Box fontWeight={500}>
                    <Typography variant="subtitle2">Per Claim Limit</Typography>
                </Box>
                <Box>
                    <SelectClaimLimit
                        handleCalculation={handleCalculation}
                        classes={classes}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default Mobile;
