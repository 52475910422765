import styled from 'styled-components';
import { Box as _Box, TextField as _TextField } from '@material-ui/core';

const SearchBarForm = styled.form`
    display: flex;
    flex: 1;
    padding: 10px 24px;
    ${({ theme }) => `
        ${theme.breakpoints.down('md')} {
            display: block;
        }
    `}
    .MuiFormControl-root {
        width: 100%;
    }
`;

const TextField = styled(_TextField)`
    .MuiOutlinedInput-root {
        width: 100%;
        border-radius: 21px;
    }

    .MuiInputBase-input {
        width: 100%;
        padding: 10px 10px;
        font-size: 16px;
    }

    ${({ theme }) => `
        ${theme.breakpoints.down('md')} {
            .MuiOutlinedInput-root {
                width: 100%;
                border-radius: 21px;
            }

            .MuiInputBase-input {
                width: 100%;
                padding: 10px 10px;
                font-size: 16px;
            }

        }
    `}
`;

const ClearIconContainer = styled(_Box)`
    &&& {
        .MuiSvgIcon-root {
            position: relative;
            top: 2px;
        }
    }
`;

const SearchIconContainer = styled(_Box)`
    margin-top: 5px;

    svg {
        font-size: 24px;
    }
`;

export default {
    SearchBarForm,
    TextField,
    ClearIconContainer,
    SearchIconContainer,
};
