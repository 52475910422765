import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useBreakpoint, useAxiosClient, useHasInvalidDates } from 'hooks';
import { lockPolicyFiles, setSubmitting } from 'stores/company/companySlice';
import { StyledCard, RoundedButton } from 'components';
import { BindRequestedPopup, BoundPopup, SubmitDocumentationPopup } from 'components/popup';
import { totalHumanize, makeToast } from 'utils';
import { SubmissionsBucket, NoteIdentifier, MplRetroactiveDateType, ProductsAbbreviatedName } from 'utils/enums';
import * as Sentry from '@sentry/react';
import { Box, Paper, Hidden, Typography } from '@material-ui/core';
import { useBindPolicy } from '../../api/policy/mutations/useBindPolicy';

const BindSectionContent = ({ getOptionToQuote, savingDates }) => {
    const dispatch = useDispatch();
    const axiosClient = useAxiosClient();
    const history = useHistory();
    const { optionToQuote, submitting } = useSelector(state => state.company);
    const hasInvalidDates = useHasInvalidDates();
    const { id: optionToQuoteID, quote } = optionToQuote;
    const { policy_request } = quote;
    const {
        athena_submissions_bucket,
        effective_date,
        contingencies,
        app_disclaimer_checked,
        mpl_retroactive_date,
        mpl_retroactive_date_type,
        product,
    } = policy_request;
    const isBindable = () => contingencies.every(contingency => contingency.checked || !contingency.is_pre_bind);
    const isContingencyChecked = () =>
        contingencies.find(contingency => contingency.identifier === NoteIdentifier.SIGNATURE).checked;
    const [canBind, setCanBind] = useState(isBindable());
    const [bindRequestedPopupOpen, setBindRequestedPopupOpen] = useState(false);
    const [boundPopupOpen, setBoundPopupOpen] = useState(false);
    const [documentationPopupOpen, setDocumentationPopupOpen] = useState(false);
    const [bindData, setBindData] = useState({});
    const bindText = `Bind $${totalHumanize(quote.aggregate_limit)} Option`;
    const bindRequestedOrBound = [SubmissionsBucket.BIND_REQUESTED, SubmissionsBucket.BOUND].includes(
        athena_submissions_bucket,
    );
    const bindRequestedAndCanBind = athena_submissions_bucket === SubmissionsBucket.BIND_REQUESTED && canBind;
    const submissionQuoted = athena_submissions_bucket === SubmissionsBucket.QUOTED;
    const isMPL = product.abbreviated_name === ProductsAbbreviatedName.MPL;
    const hasUncheckedPreBindContingencies =
        contingencies.some(contingency => contingency.is_pre_bind && !contingency.checked);

    const isButtonEnabled = () => {
        if (!effective_date) {
            return false;
        } else if (hasInvalidDates) {
            return false;
        } else if (
            isMPL &&
            mpl_retroactive_date_type?.name !== MplRetroactiveDateType.FULL_PRIOR_ACTS &&
            mpl_retroactive_date_type !== MplRetroactiveDateType.FULL_PRIOR_ACTS &&
            !mpl_retroactive_date
        ) {
            return false;
        } else if (submissionQuoted) {
            return true;
        } else if (bindRequestedAndCanBind) {
            return true;
        } else if (bindRequestedOrBound) {
            return (
                contingencies.some(contingency => contingency.files.some(file => !file.locked)) ||
                policy_request.app_disclaimer_checked ||
                isContingencyChecked()
            );
        }
    };
    const [buttonEnabled, setButtonEnabled] = useState(isButtonEnabled());

    useEffect(() => {
        setCanBind(isBindable());
        setButtonEnabled(isButtonEnabled());
    }, [policy_request, hasInvalidDates]);

    const [, lockPolicyFilesAPICall] = axiosClient(
        {
            method: 'post',
            url: `/policyRequest/${policy_request.id}/lockPolicyFiles`,
        },
        { manual: true },
    );

    const [, requestToBind] = axiosClient(
        {
            method: 'post',
            url: `/optionToQuote/${optionToQuoteID}/requestToBind`,
        },
        { manual: true },
    );

    const handleOnSubmitRequestSuccess = data => {
        setBindData(data);
        setBoundPopupOpen(true);
    };

    const handleOnSubmitRequestFail = bindError => {
        Sentry.captureException(bindError);
        if (bindError.response?.data?.hsb_dates_validation_error) {
            makeToast(
                'error',
                'This option is temporarily unavailable. We are holding your request. For further assistance please contact your underwriter.',
            );
        } else {
            makeToast('error', 'Something went wrong. We have notified your underwriter, please try again later.');
        }
        setTimeout(() => {
            history.push(
                {
                    pathname: `/company/${policy_request.company_id}`,
                    search: `?policyId=${policy_request.id}`,
                }
            );
        }, 5000);
    };

    const { mutate: bindPolicy, isLoading: isBindProcessing } = useBindPolicy(
        handleOnSubmitRequestSuccess,
        handleOnSubmitRequestFail,
    );

    const handleBind = async () => {
        bindPolicy({ optionToQuoteID, isAppDisclaimerChecked: app_disclaimer_checked });
    };

    const handleSubmit = async () => {
        if (submitting || isBindProcessing) {
            return;
        }
        if (savingDates) {
            makeToast('error', 'There was an error, please try again shortly.');
            return;
        }

        dispatch(setSubmitting(true));
        dispatch(lockPolicyFiles());
        try {
            if (bindRequestedAndCanBind) {
                await handleBind();
            } else if (bindRequestedOrBound) {
                if (app_disclaimer_checked || contingencies.every(contingency => contingency.checked)) {
                    await handleBind();
                } else {
                    await lockPolicyFilesAPICall();
                    setDocumentationPopupOpen(true);
                }
            } else if (canBind) {
                await handleBind();
            } else {
                await requestToBind();
                setBindRequestedPopupOpen(true);
            }
        } catch (e) {
            Sentry.captureException(e);
        }
        dispatch(setSubmitting(false));
    };

    const getFullStoryDataTrackText = () => {
        const prefix = window.location.pathname.includes('bind') ? 'binder_button' : 'policy_button';
        if (submitting) {
            return `${prefix}_wait`;
        } else if (bindRequestedAndCanBind) {
            return `${prefix}_bind`;
        } else if (bindRequestedOrBound) {
            return app_disclaimer_checked && !isContingencyChecked()
                ? `${prefix}_submit`
                : `${prefix}_submit_for_review`;
        } else if (canBind) {
            return `${prefix}_bind`;
        } else {
            return `${prefix}_request_bind`;
        }
    };

    return (
        <>
            <Box textAlign="center" mb={2} fontWeight="fontWeightLight">
                <Typography variant="body2">
                    {!effective_date
                        ? `Select effective dates${hasUncheckedPreBindContingencies ? ' and complete the contingencies above' : ''} to ${canBind ? 'bind' : 'request to bind'
                        } this quote option`
                        : hasInvalidDates
                            ? `Select the required dates${hasUncheckedPreBindContingencies ? ' and complete the contingencies above' : ''} to bind this quote option`
                            : bindRequestedOrBound
                                ? 'A policy will be issued/emailed once contingency documentation has been verified.'
                                : !optionToQuote.quote.quote_generated_packet
                                    ? 'We are working on your documents, bind will be available soon.'
                                    : canBind
                                        ? `By selecting “Bind $${totalHumanize(
                                            quote.aggregate_limit,
                                        )} Option” below you are binding this quote and activating your client’s coverage.`
                                        : 'By selecting “Request to Bind” below you are requesting to bind this quote. All outstanding contingencies need to be validated by an underwriter.'}
                </Typography>
            </Box>
            <Box textAlign="center" mb={4}>
                <RoundedButton
                    disabled={
                        !optionToQuote.quote.quote_generated_packet || submitting || !buttonEnabled || isBindProcessing
                    }
                    onClick={handleSubmit}
                    py={1.375}
                    data-track={getFullStoryDataTrackText()}>
                    <Typography variant="h5">
                        {submitting
                            ? 'Please Wait...'
                            : bindRequestedAndCanBind
                            ? bindText
                            : bindRequestedOrBound
                            ? app_disclaimer_checked || isContingencyChecked()
                                ? 'Submit'
                                : 'Submit Documentation for Review'
                            : canBind
                            ? bindText
                            : `Request ${bindText}`}
                    </Typography>
                </RoundedButton>
            </Box>
            <BindRequestedPopup
                optionToQuote={optionToQuote}
                open={bindRequestedPopupOpen}
                handleClose={() => setBindRequestedPopupOpen(false)}
            />
            <BoundPopup
                optionToQuote={optionToQuote}
                open={boundPopupOpen}
                data={bindData || {}}
                handleClose={() => {
                    setBoundPopupOpen(false);
                    getOptionToQuote();
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
            />
            <SubmitDocumentationPopup
                optionToQuote={optionToQuote}
                open={documentationPopupOpen}
                handleClose={() => setDocumentationPopupOpen(false)}
            />
        </>
    );
};

const BindSection = ({ getOptionToQuote = () => {}, savingDates }) => {
    const isSm = useBreakpoint('down', 'sm');
    const boxRef = useRef(null);
    useEffect(() => {
        // @TODO: consider revisiting this code and improving.
        // When we have a fixed element, the content of the page should be able to be above it when scrolling down
        // Changing the 'height' rule of #root is necessary because otherwise the padding isn't working
        // but when changed, it breaks all pages with iframes.
        const $root = document.querySelector('#root');
        $root.style.paddingBottom = isSm ? `${boxRef.current?.clientHeight || 0}px` : 0;
        $root.style.height = isSm ? 'auto' : '100%';
        return () => {
            $root.style.paddingBottom = 0;
            $root.style.height = '100%';
        };
    }, [isSm]);
    return (
        <>
            <Hidden smDown>
                <Box component={StyledCard.Partial} mt={0.5}>
                    <BindSectionContent getOptionToQuote={getOptionToQuote} savingDates={savingDates} />
                </Box>
            </Hidden>
            <Hidden mdUp>
                <Box
                    component={Paper}
                    elevation={4}
                    mt={0.5}
                    position="fixed"
                    bottom="0"
                    width="100%"
                    zIndex="1"
                    ref={boxRef}>
                    <Box component={StyledCard.Partial}>
                        <BindSectionContent getOptionToQuote={getOptionToQuote} savingDates={savingDates} />
                    </Box>
                </Box>
            </Hidden>
        </>
    );
};

export default BindSection;
