import React from 'react';
import Styled from '../../styled-components';
import { Box, Hidden } from '@material-ui/core';


const LoadingAggregate = () => {
    return (
        <>
            <Hidden smDown>
                <Box>
                    <Box mt={2} display="flex" alignItems="center">
                        <Box>
                            <Box component={Styled.Skeleton} width="160px" height="19px" mr={10} animation={false} sx={{transform: 'scale(1,1)'}}/>
                        </Box>
                        <Box display="flex" height="37px" mt={0}>
                            <Box component={Styled.Skeleton} mr={1} variant="text" width="180px" animation="wave" />
                            <Box component={Styled.Skeleton} mr={1} variant="text" width="180px" animation="wave" />
                        </Box>
                    </Box>
                    <Box mt='10px' display="flex" alignItems="center">
                        <Box>
                            <Box component={Styled.Skeleton} width="160px" height="19px" mr={10} animation={false} sx={{transform: 'scale(1,1)'}}/>
                        </Box>
                        <Box display="flex" height="37px" mt={0}>
                            <Box component={Styled.Skeleton} variant="text" width="180px" animation="wave" />
                            <Box mr={1} variant="text" width="180px" />
                        </Box>
                    </Box>
                </Box>
            </Hidden>
            <Hidden mdUp>
                <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="column">
                        <Box component={Styled.Skeleton} height="19px" width="180px" animation={false} sx={{transform: 'scale(1,1)'}}/>
                        <Box display="flex" height="34px" mt={1}>
                            <Box component={Styled.Skeleton} mr={1} variant="text" width="150px" animation="wave" />
                            <Box component={Styled.Skeleton} variant="text" width="150px" animation="wave" />
                        </Box>
                    </Box>
                    <Box mt={2} display="flex" flexDirection="column">
                        <Box component={Styled.Skeleton} height="19px" width="180px" animation={false} sx={{transform: 'scale(1,1)'}}/>
                        <Box display="flex" height="34px" mt={1}>
                            <Box component={Styled.Skeleton} mr={1} variant="text" width="308px" animation="wave" />
                        </Box>
                    </Box>
                </Box>
            </Hidden>
        </>
    )
}

export default LoadingAggregate;
