import React, { FC, Fragment } from 'react';
import PolicyTableTitle from './PolicyTableTitle';
import PolicyTableRow from './PolicyTableRow';

import { OptionToQuote } from 'types/optionToQuote';
import { Variant } from '@material-ui/core/styles/createTypography';

interface Props {
    variant: Variant;
    subVariant: Variant;
    firstColSpan: number;
    firstColPadding: number;
    subCoverageSpacing: number;

    optionToQuote: OptionToQuote;
}

const PolicyPricesTable: FC<Props> = ({
    variant,
    subVariant,
    firstColSpan,
    firstColPadding,
    optionToQuote,
    subCoverageSpacing,
}) => {
    return (
        <>
            <Fragment>
                <PolicyTableTitle
                    variant={variant}
                    firstColSpan={firstColSpan}
                    firstColPadding={firstColPadding}
                    subCoverageSpacing={subCoverageSpacing}
                    totalPremium={optionToQuote.quote.total_premium}
                />
                {optionToQuote.quote.premium !== optionToQuote.quote.total_premium && (
                    <>
                        <PolicyTableRow
                            subVariant={subVariant}
                            firstColSpan={firstColSpan}
                            firstColPadding={firstColPadding}
                            subCoverageSpacing={subCoverageSpacing}
                            title={'Premium'}
                            value={optionToQuote.quote.premium}
                        />
                        {optionToQuote.quote.embedded_security_fee && (
                            <PolicyTableRow
                                subVariant={subVariant}
                                firstColSpan={firstColSpan}
                                firstColPadding={firstColPadding}
                                subCoverageSpacing={subCoverageSpacing}
                                title={'Embedded Security'}
                                value={optionToQuote.quote.embedded_security_fee}
                            />
                        )}
                    </>
                )}
            </Fragment>
        </>
    );
};

export default PolicyPricesTable;
