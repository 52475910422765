import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { ReactComponent as CheckCircleIcon } from 'components/icons/check-circle-icon.svg';
import Styled from '../styled-components';
import { theme } from 'components/theme/styles';


const DetailsSentCard = ({ policyRequest, setShowDetailsSentCard }) => {
    return (
        <Styled.Box
            borderTop={`4px solid ${policyRequest.product.background_color}`}
            display="flex"
            justifyContent="space-around"
            mt={[4,4,0]}
        >
            <Box mt={6.5} mb={7.3} display="flex" flexDirection="column" alignItems="center" textAlign="center">
                <CheckCircleIcon />
                <Box mt={1.5}>
                    <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                        Details sent!
                    </Typography>
                </Box>
                <Box mt={1} color="#696969">
                    <Typography variant="body1">
                        <Box>
                            Thanks for sending your note and/or documents.
                        </Box>
                        <Box>
                            We will review the account and get back to you shortly.
                        </Box>
                    </Typography>
                </Box>
                <Box color={theme.palette.primary.main} mt={2.2} onClick={() => setShowDetailsSentCard(false)}>
                    <Typography variant="body1" style={{ fontWeight: 600, cursor: 'pointer' }}>
                        Send another Note or Documents
                    </Typography>
                </Box>
            </Box>
        </Styled.Box>
    )
}


export default DetailsSentCard;
