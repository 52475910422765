import React from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import _ from 'lodash';
import { QuestionsTypes } from 'utils/enums';

export const getSortedAnswers = question => {
    const sortnFn = (firstAnswer, secondAnswer) =>
        question.order_alphabetical
            ? firstAnswer.display_name.localeCompare(secondAnswer.display_name)
            : firstAnswer.question_number - secondAnswer.question_number;
    const answersNeedToBeInTheEnd = ['Other', 'None of the above']
    const answers = question.optional_answers;
    const otherAnswer = answers.find(answer => answersNeedToBeInTheEnd.includes(answer.display_name.trim()));
    const sortedAnswers = answers.filter(answer => !answersNeedToBeInTheEnd.includes(answer.display_name.trim())).sort(sortnFn);
    return [...sortedAnswers, ...(!_.isNil(otherAnswer) ? [otherAnswer] : [])];
};

const updateEditAppQuestionToAnswer = ({ editAppQuestionToAnswer, question, answerValue }) => {
    if (QuestionsTypes.SINGLE_CHOICE_QUESTION.includes(question.type)) {
        editAppQuestionToAnswer[question.id] = {
            answerId: answerValue,
            questionType: question.type,
            answerType: question.optional_answers[0].type,
        };
    }
    if (QuestionsTypes.MULTI_SELECT_QUESTION.includes(question.type)) {
        editAppQuestionToAnswer[question.id] = {
            answersIds: answerValue,
            questionType: question.type,
            answerType: question.optional_answers[0].type,
        };
    }
    if (QuestionsTypes.FREE_TEXT_QUESTION.includes(question.type)) {
        editAppQuestionToAnswer[question.id] = {
            answerId: question.optional_answers[0].id,
            questionType: question.type,
            answerType: question.optional_answers[0].type,
            freeTextValue: answerValue,
        };
    }
};

export const extractQuestionnaireToStore = (questionnaireSectionsData, resQuestionToAnswer) => {
    let questionToAnswer = {};
    let numberOfQuestions = 0;
    let editAppQuestionToAnswer = {};

    for (const section of questionnaireSectionsData) {
        for (const question of section.questions) {
            if (question.type !== 'NOTICE') {
                questionToAnswer[question.id] = resQuestionToAnswer[question.id]
                    ? resQuestionToAnswer[question.id]
                    : null;
                if (question.required || resQuestionToAnswer[question.id]) {
                    numberOfQuestions += 1;
                }
                const answerValue = resQuestionToAnswer[question.id];
                if (answerValue) {
                    updateEditAppQuestionToAnswer({ editAppQuestionToAnswer, question, answerValue });
                }
            }
        }
    }
    return [questionToAnswer, numberOfQuestions, editAppQuestionToAnswer];
};

export const handleMultipleChoiceWithSearchBarRenderOption = (option, { inputValue }) => {
    const matches = match(option.display_name, inputValue, { insideWords: true });
    const parts = parse(option.display_name, matches);

    return (
        <div style={{ display: 'flex' }}>
            <div className="multiselect-option-line-div">
                <div className="multiselect-option-line"></div>
            </div>
            {parts.map((part, index) => (
                <span
                    key={index}
                    style={{
                        fontWeight: part.highlight ? 700 : 400,
                        marginTop: part.highlight ? '-0.5px' : '0',
                        whiteSpace: 'pre',
                    }}>
                    {part.text}
                </span>
            ))}
        </div>
    );
};
