import React, { Fragment } from 'react';
import { truncate } from './utils';
import { CompaniesBuckets, RenewalsSubBuckets } from 'utils/enums';
import { Typography, Box } from '@material-ui/core';
import { Icon, StyledTypography, IconBox } from 'components';
import Styled from './styled-components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useBreakpoint } from 'hooks';
import { displayClaimLimit } from 'utils/policyRequest';
import { getBrokerInitialsColor } from '../../modules/company/pages/dashboard/components/companies-pagination/utils';
import { useSelector } from 'react-redux';
import { formatDatePickerDate } from 'utils';
import _ from 'lodash';

export const EmptyTable = ({ icon, title, text, extraText }) => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" color="#CCC" py={10} px={[4, 4, 7, 16.25]}>
            <IconBox sizes={['32px', '48px', '56px']} bgcolor="#F2F2F2" borderRadius="50%" color="#AFAFAF">
                <Icon
                    icon={icon}
                    TypographyComponent={StyledTypography.FadedTypography}
                    typographyProps={{ variant: 'h5' }}
                />
            </IconBox>
            <Box fontWeight="fontWeightBold" pb={1}>
                <Typography variant="subtitle1">{title}</Typography>
            </Box>
            <Box fontWeight="fontWeightLight" textAlign="center">
                <Typography variant="body1">{text}</Typography>
            </Box>
            <Box pt={2} fontWeight="fontWeightLight" textAlign="center">
                <Typography variant="body1">{extraText}</Typography>
            </Box>
        </Box>
    );
};

export const FirstPolicyCell = ({ open, multiplePolicies, numOfPolicies, bucket, client }) => {
    const isSm = useBreakpoint('down', 'sm');
    const isMd = useBreakpoint('down', 'md');
    return (
        <Styled.TableCell
            open={open}
            className={isSm ? 'inner black' : 'black'}
            rowSpan={multiplePolicies && !isSm ? numOfPolicies + 1 : null}
            style={{
                paddingTop: ((!isSm && multiplePolicies) || isSm) && '12px',
                paddingBottom: !isSm && multiplePolicies && '4px',
            }}
            colSpan={isSm ? (bucket === CompaniesBuckets.QUOTES ? 6 : 7) : 3}>
            <Box
                borderBottom="0"
                pl={4}
                pb={isSm ? 0.5 : 0}
                height={multiplePolicies && !isSm ? numOfPolicies * 24 + 8 : null}>
                <Typography variant="subtitle2">{truncate(client.clientName, isMd ? 21 : 24)}</Typography>
            </Box>
        </Styled.TableCell>
    );
};

export const ArrowCell = ({ multiplePolicies, open, bucket }) => {
    const isSm = useBreakpoint('down', 'sm');
    return (
        <Styled.TableCell
            className={isSm ? 'alignEnd inner grey' : 'alignEnd grey'}
            align="right"
            colSpan={isSm && bucket === CompaniesBuckets.QUOTES ? 3 : null}
            style={{
                width: isSm ? '100%' : null,
                border: multiplePolicies && !isSm && 'none',
                paddingTop: multiplePolicies && !isSm && '12px',
                paddingBottom: multiplePolicies && !isSm && '4px',
            }}>
            <Box
                pr={4}
                pt={isSm ? 1 : 0}
                py={{ md: !multiplePolicies && !isSm ? 1.25 : null, lg: !multiplePolicies && !isSm ? 1.5 : null }}>
                <Box color="#191919" p={0} display="flex" justifyContent="flex-end">
                    <Icon
                        icon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        typographyProps={{ variant: 'h5' }}
                    />
                </Box>
            </Box>
        </Styled.TableCell>
    );
};

export const FirstPolicyInfo = ({ companyPolicies, open, multiplePolicies, bucket, subBucket }) => {
    const firstPolicy = _.head(companyPolicies);
    const { user } = useSelector(state => state.user);
    const teamMembersIds = user.team_members_ids;
    return (
        <>
            {[CompaniesBuckets.POLICIES, CompaniesBuckets.RENEWALS].includes(bucket) && (
                <Styled.TableCell
                    open={multiplePolicies ? null : open}
                    className="grey"
                    align="left"
                    colSpan={1}
                    style={{ border: multiplePolicies && 'none', paddingTop: multiplePolicies && '12px' }}>
                    {subBucket === RenewalsSubBuckets.NOT_RENEWING ? (
                        <Typography variant="subtitle2">
                            {formatDatePickerDate(firstPolicy['expiration_date'])}
                        </Typography>
                    ) : (
                        <Typography variant="subtitle2">{firstPolicy['policy_period']}</Typography>
                    )}
                </Styled.TableCell>
            )}
            <Styled.TableCell
                open={multiplePolicies ? null : open}
                className="grey"
                align="left"
                style={{ border: multiplePolicies && 'none', paddingTop: multiplePolicies && '16px' }}>
                <Box border="none" display={'flex'} flexDirection={'row'} alignItems={'center'} gridGap={12}>
                    <Styled.Avatar
                        style={{ backgroundColor: getBrokerInitialsColor(firstPolicy?.broker_user, teamMembersIds) }}>
                        {firstPolicy?.broker_user?.initials}
                    </Styled.Avatar>
                    <Typography variant="subtitle2">{firstPolicy['product']?.name || null}</Typography>
                </Box>
            </Styled.TableCell>
            <Styled.TableCell
                open={multiplePolicies ? null : open}
                className="grey"
                style={{
                    border: multiplePolicies && 'none',
                    paddingTop: multiplePolicies && '14px',
                    paddingBottom: multiplePolicies && '2.5px',
                }}>
                <Styled.TagBox className={firstPolicy['status'][0]?.type}>
                    <Typography variant="overline">{firstPolicy['status'][0]?.text}</Typography>
                </Styled.TagBox>
            </Styled.TableCell>
            <ArrowCell bucket={bucket} open={open} multiplePolicies={multiplePolicies} />
        </>
    );
};

export const InnerDataCellWithOption = ({ policyRequest, optionToQuoteData }) => {
    const classes = `inner ${optionToQuoteData.pending ? 'grey' : 'black'}`;
    return (
        <Fragment>
            <Styled.TableCell className={classes} component="th" scope="row">
                <Typography variant="subtitle2">
                    {optionToQuoteData.pending ? 'Pending' : optionToQuoteData.total_premium}
                </Typography>
            </Styled.TableCell>
            <Styled.TableCell className={classes}>
                {displayClaimLimit(policyRequest, optionToQuoteData) ? (
                    <Typography variant="subtitle2">
                        {optionToQuoteData.limit} / {optionToQuoteData.claimLimit}
                    </Typography>
                ) : (
                    <Typography variant="subtitle2">{optionToQuoteData.limit}</Typography>
                )}
            </Styled.TableCell>
            <Styled.TableCell className={classes}>
                <Typography variant="subtitle2">{optionToQuoteData.retention}</Typography>
            </Styled.TableCell>
        </Fragment>
    );
};

export const InnerDataCellWithoutOption = ({ collapseData }) => {
    const isSm = useBreakpoint('down', 'sm');
    return (
        <Styled.TableCell className="inner black" component="th" scope="row" colSpan={isSm ? 0 : 3}>
            <Typography variant="subtitle2" style={{ maxWidth: !isSm && '500px' }}>
                {collapseData}
            </Typography>
        </Styled.TableCell>
    );
};
