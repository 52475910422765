import React from 'react';
import Styled from './styled-components';
import { Box } from '@material-ui/core';


const RoundedButton = ({ children, py = 1, px=4, fontWeight = 'fontWeightBold', ...props }) => {
    return (
        <Box component={Styled.RoundedButton} py={py} px={px} fontWeight={fontWeight} {...props}>{children}</Box>
    )
}

export default RoundedButton;
