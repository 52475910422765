import React from 'react';
import {
    Box,
    Typography,
    FormControl,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import SelectLimit from './SelectLimit';
import SelectRetention from './SelectRetention';
import SelectOther from './SelectOther';
import Styled from 'modules/submission/pages/quote-customization/components/styled-components';
import { useStyles } from 'utils/customization';


const Desktop = ({ handleChangeLineItem }) => {
    const classes = useStyles();
    const { customizedOptionToQuote } = useSelector(state => state.company);

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <Box component={Styled.TableCell} className={classes.TableCellTitle} />
                        <Box component={Styled.TableCell} className={classes.TableCellTitle} py={1} px={1.5}>
                            <Typography variant="overline">
                                LIMIT
                            </Typography>
                        </Box>
                        <Box component={Styled.TableCell} className={classes.TableCellTitle} py={1} px={1.5}>
                            <Typography variant="overline">
                                RETENTION
                            </Typography>
                        </Box>
                        <Box component={Styled.TableCell} className={classes.TableCellTitle} py={1} px={1.5}>
                            <Typography variant="overline">
                                OTHER
                            </Typography>
                        </Box>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {customizedOptionToQuote.quote.coverages.line_items.map(
                        lineItem => {
                            return (
                                <TableRow
                                    key={`${lineItem.coverage_name}-${lineItem.code}`}
                                >
                                    <Box component={Styled.TableCell} className={classes.TableCoverageName}>
                                        <Typography variant="body1">
                                            {lineItem.coverage_name}
                                            {/* 'i' Icon will be disabled till next phase */}
                                            {/* <Styled.PointerBox display="flex" alignItems="center" ml="3px" color="grey">
                                            <InfoOutlinedIcon color="disabled" onClick={() => setInfoPanelPopup({open: true, item: lineItem})}/>
                                        </Styled.PointerBox> */}
                                        </Typography>
                                    </Box>
                                    <Styled.TableCell>
                                        <FormControl>
                                            <SelectLimit
                                                lineItem={lineItem}
                                                handleChangeLineItem={handleChangeLineItem}
                                            />
                                        </FormControl>
                                    </Styled.TableCell>
                                    <Styled.TableCell>
                                        <FormControl>
                                            <SelectRetention
                                                lineItem={lineItem}
                                                handleChangeLineItem={handleChangeLineItem}
                                            />
                                        </FormControl>
                                    </Styled.TableCell>
                                    <Styled.TableCell>
                                        <FormControl>
                                            <SelectOther
                                                lineItem={lineItem}
                                                handleChangeLineItem={handleChangeLineItem}
                                            />
                                        </FormControl>
                                    </Styled.TableCell>
                                </TableRow>
                            );
                        }
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default Desktop;
