import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { StyledBox } from 'components/index';

interface Props {
    totalPremium: number;
}

const PolicyTableHeader: FC<Props> = ({ totalPremium }) => {
    return (
        <StyledBox.MobilePolicyAndCommissionBox>
            <Typography variant="h6">Policy Total</Typography>
            <Typography variant="h6"> $ {totalPremium}</Typography>
        </StyledBox.MobilePolicyAndCommissionBox>
    );
};

export default PolicyTableHeader;
