import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './translations/en/resources';

// Currently we only support English so we dont need this yet.
// But once we have more supported languages - use this!
// import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en,
};

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //   .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        fallbackLng: 'en',
        debug: false,
        initImmediate: false,
        lng: 'en',  // in the future LanguageDetector will be used
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        react: {
            useSuspense: false,
        }
    });

export default i18n;
