import React, { FC, useState } from 'react';
import { useBreakpoint } from 'hooks';
import { Box, CircularProgress, TableRow } from '@material-ui/core';
import { StyledAction, StyledTable, StyledTypography } from 'components';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface Props {
    renderTitle: (isToggled: boolean) => JSX.Element;
    renderContent: JSX.Element;
    isLoadingData?: boolean;
    needSpace?: boolean;
}

export const ExpandableRow: FC<Props> = ({ renderTitle, renderContent, isLoadingData = false, needSpace = true }) => {
    const isMd = useBreakpoint('down', 'md');
    const [isToggled, setIsToggled] = useState(false);

    return (
        <>
            <TableRow>
                <StyledTable.TableCell colSpan={isMd ? 5 : 6}>
                    <Box display="flex" alignItems="center">
                        <Box
                            component={StyledAction.ExpandableRowControl}
                            display="flex"
                            alignItems="center"
                            onClick={() => {
                                !isLoadingData && setIsToggled(!isToggled);
                            }}>
                            <StyledTypography.FadedTypography variant="subtitle1">
                                <Box mr={2} display="flex">
                                    {isLoadingData ? (
                                        <CircularProgress color="inherit" size={15} />
                                    ) : isToggled ? (
                                        <ExpandLessIcon />
                                    ) : (
                                        <ExpandMoreIcon />
                                    )}
                                </Box>
                            </StyledTypography.FadedTypography>
                            {renderTitle(isToggled)}
                        </Box>
                    </Box>
                </StyledTable.TableCell>
            </TableRow>
            {isToggled && (
                <>
                    {needSpace ? (
                        <TableRow>
                            <StyledTable.TableCell colSpan={isMd ? 5 : 6}>
                                <Box mb={1} />
                            </StyledTable.TableCell>
                        </TableRow>
                    ) : (
                        <></>
                    )}
                    {renderContent}
                </>
            )}
        </>
    );
};
