import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useBreakpoint = (func, breakpoint) => {
    const theme = useTheme();
    let breakpointValue = breakpoint;
    if (!theme.breakpoints.keys.includes(breakpoint)) {
        breakpointValue = theme.breakpoints.values[breakpoint];
    }
    return useMediaQuery(theme.breakpoints[func](breakpointValue));
}

export default useBreakpoint;
