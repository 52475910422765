import React from 'react';
import { Box } from '@material-ui/core';
import { ReactComponent as AtbayCustomizationIcon } from 'components/icons/atbay-customization.svg'
import Styled from '../../styled-components';



const SkeletonPremium = () => {

    return (
        <>
            <AtbayCustomizationIcon />
            <Box my="14px">
                <Styled.Skeleton animation={false} height="27px" width="90px" />
            </Box>
            <Box>
                <Styled.Skeleton animation={false} height="50px" width="130px" />
            </Box>
        </>
    )
}


export default SkeletonPremium;