import React, { FC, useCallback, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { StyledDivider, StyledLink, StyledTypography } from 'components';
import { PacketTemplateType } from 'utils/enums';
import { DocumentList } from 'components/option-to-quote';
import { useBreakpoint, useEnv } from 'hooks';
import { getRelevantPacketTemplateType } from 'utils/policyRequest';
import { ExpandableRow } from 'components/expandable-row/ExpandableRow';
import _ from 'lodash';
import { OptionToQuote, Quote } from 'types/optionToQuote';
import { PolicyRequest } from 'types/PolicyRequest';

interface Props {
    optionToQuote: OptionToQuote;
    policyRequest: PolicyRequest;
}

export const DocumentsDetails: FC<Props> = ({ optionToQuote, policyRequest }) => {
    const { apiBaseUrl } = useEnv();
    const isMd = useBreakpoint('down', 'md');

    const packetTemplateType = getRelevantPacketTemplateType(policyRequest);
    const generatedPacketKey = `${packetTemplateType}_generated_packet` as keyof Quote;
    const generatedPacket = optionToQuote.quote[generatedPacketKey];
    const isGeneratingPacket = _.isEmpty(generatedPacket);

    const renderTitle = useCallback(
        isToggled => (
            <Box display="flex" flexDirection="row" alignItems="center" style={{ gap: 8 }}>
                <StyledTypography.FadedTypography variant="body2" align="justify">
                    {isGeneratingPacket ? 'Generating' : isToggled ? 'Hide' : 'Review'} documents
                </StyledTypography.FadedTypography>
                {!isGeneratingPacket && (
                    <>
                        <StyledDivider.VerticalDivider orientation="vertical" />
                        <StyledTypography.FadedTypography variant="body2" className="hoverable">
                            <StyledLink.Anchor
                                style={{ color: 'inherit' }}
                                href={`${apiBaseUrl}/company/download_packet_merged_file/${
                                    optionToQuote.id
                                }/${PacketTemplateType.QUOTE.toUpperCase()}`}>
                                Download all documents
                            </StyledLink.Anchor>
                        </StyledTypography.FadedTypography>
                    </>
                )}
            </Box>
        ),
        [],
    );

    const renderContent = useMemo(
        () => (
            <DocumentList
                firstColSpan={isMd ? 4 : 5}
                firstColPadding={0.5}
                variant="body2"
                iconVariant="body1"
                optionToQuote={optionToQuote}
                packetTemplateType={PacketTemplateType.QUOTE}
                policyRequest={policyRequest}
            />
        ),
        [],
    );
    return <ExpandableRow renderTitle={renderTitle} renderContent={renderContent} isLoadingData={isGeneratingPacket} />;
};
