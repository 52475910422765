import React from 'react';
import { SubmissionsBucket } from 'utils/enums';

const ProductTextComponent = ({ policyRequest }) => {
    return (
        <>
            {policyRequest.athena_submissions_bucket === SubmissionsBucket.DECLINED
                ? 'Quotes are closed'
                : policyRequest.athena_submissions_bucket === SubmissionsBucket.LOST
                    ? 'Quotes have expired'
                    : [SubmissionsBucket.EXPIRED, SubmissionsBucket.CANCELED].includes(policyRequest.athena_submissions_bucket)
                        ? policyRequest.erp_info.is_active
                            ? 'Policy is in ERP'
                            : `Policy has been ${policyRequest.athena_submissions_bucket}`
                        : ''}
        </>
    );
};

export default ProductTextComponent;
