import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEnv } from 'hooks';
import { AdminRoutes } from 'modules/admin';
import { CompanyRoutes } from 'modules/company';
import { QuestionnaireRoutes } from 'modules/questionnaire';
import { SubmissionRoutes } from 'modules/submission';
import { UserRoutes } from 'modules/user';
import { PartnershipManagementRoutes } from 'modules/partnership-management';
import { Error, SplitScreen } from 'components';
import { Cover, MaintenanceMode } from './user/pages';
import { Permissions } from 'utils/enums';
import HomePage from 'modules/company/pages/dashboard';
import RequireAuth from 'modules/auth/RequireAuth';
import { useAuth } from 'api/auth/queries/useAuth';

const MainRoutes = () => {
    const { maintenanceMode } = useEnv();
    const { permissions: userPermissions, user } = useSelector(state => state.user);

    const { data, isLoading } = useAuth();

    if (!data || isLoading) {
        return <></>;
    }

    const getDashboardComponent = () => {
        if (Permissions.isAdmin(userPermissions) && (!Permissions.isLoginAllowed(userPermissions) || !user.is_bp_user)) {
            return <Redirect to="/admin/pending_users" />;
        } else if (Permissions.isLoginAllowed(userPermissions) && user.is_bp_user) {
            return <HomePage />;
        }
        else {
            return <Error errorStatus="401" />;
        }
    }

    return (
        <>
            {maintenanceMode ? (
                <Switch>
                    <Route path="/*">
                        <SplitScreen
                            leftComponent={<MaintenanceMode />}
                            rightComponent={<Cover backgroundImage="/images/people.png" />}
                        />
                    </Route>
                </Switch>
            ) : (
                <Switch>
                    <Route path="/user">
                        <UserRoutes />
                    </Route>
                    <RequireAuth path="/admin" allowedPermissions={[Permissions.ADMIN_READ]} bpUserValidation={false}>
                        <AdminRoutes />
                    </RequireAuth>
                    <RequireAuth path="/company">
                        <CompanyRoutes />
                    </RequireAuth>
                    <RequireAuth path="/partnership_management">
                        <PartnershipManagementRoutes />
                    </RequireAuth>
                    <RequireAuth path="/questionnaire" bpUserValidation={false}>
                        <QuestionnaireRoutes />
                    </RequireAuth>
                    <RequireAuth path="/submission">
                        <SubmissionRoutes />
                    </RequireAuth>
                    <RequireAuth
                        exact
                        path="/dashboard"
                        allowedPermissions={[Permissions.ADMIN_READ, Permissions.LOGIN]}
                        bpUserValidation={false}
                    >
                        {getDashboardComponent()}
                    </RequireAuth>
                    <RequireAuth exact path="">
                        <Redirect to="/dashboard" />
                    </RequireAuth>
                    <Route>
                        <Error errorStatus="404" />
                    </Route>
                </Switch>
            )}
        </>
    );
};

export default MainRoutes;
