import React from 'react';
import {default as BaseDesktopTablet} from 'modules/company/pages/company/components/policy-requests-tab/components/completed/components/desktop-tablet/index'


const DesktopTablet = ({ policyRequest }) => {
    return <BaseDesktopTablet policyRequest={policyRequest}/>;
}


export default DesktopTablet;
