import React from 'react';
import {
    Box,
    Table,
    TableBody,
} from '@material-ui/core';
import NextSteps from 'components/policy/next-steps/BoundNextSteps';


const DesktopTablet = ({ policyRequest }) => {
    return (
        <Box component={props => <Table padding="none" {...props} />} fontWeight="fontWeightLight">
            <TableBody>
                <NextSteps policyRequest={policyRequest} />
            </TableBody>
        </Box>
    );
}


export default DesktopTablet;
