import React from 'react';
import { useBreakpoint } from 'hooks';
import { Box, Typography } from '@material-ui/core';
import { DynamicIcon, IconBox } from 'components';
import { NodeType, ValueType } from 'utils/enums';
import Note from '../../new-policy/Note';
import FreeTextQuestion from './questions/FreeTextQuestion';
import SingleChoiceQuestion from './questions/SingleChoiceQuestion';
import MultipleChoicesQuestion from './questions/MultipleChoicesQuestion';
import AddressQuestion from './questions/AddressQuestion';
import NumberQuestion from './questions/NumberQuestion';
import DomainQuestion from './questions/DomainQuestion';
import Notice from './questions/Notice';
import MultipleChoicesWithSearchBarQuestion from './questions/MultipleChoicesWithSearchBarQuestion';
import CompanyNameQuestion from './questions/CompanyNameQuestion';


const QuestionsSection = ({
    section,
    questions,
    background_color,
    text_color,
    updateForm,
    policy_request_id,
    width,
    submitFailed,
    setScroll,
    scroll,
    setEditAnswerMode,
    setSubmissionDisabled,
    setCheckTriedToSubmit,
    reSubmitted,
    setChangeMade,
}) => {
    const isSm = useBreakpoint('down', 'sm');
    const isLg = useBreakpoint('up', 'lg');
    const valueTypeToComponent = {
        [ValueType.SINGLE_CHOICE]: SingleChoiceQuestion,
        [ValueType.BOOLEAN]: SingleChoiceQuestion,
        [ValueType.MULTIPLE_CHOICES]: MultipleChoicesQuestion,
        [ValueType.MULTIPLE_CHOICES_WITH_SEARCH_BAR]: MultipleChoicesWithSearchBarQuestion,
        [ValueType.FREE_TEXT]: FreeTextQuestion,
        [ValueType.ADDRESS]: AddressQuestion,
        [ValueType.DOMAIN]: DomainQuestion,
        [ValueType.REVENUE_QUESTION]: NumberQuestion,
        [ValueType.NOTICE]: Notice,
        [ValueType.NUMBER]: NumberQuestion,
        [ValueType.COMPANY_NAME]: CompanyNameQuestion,
    };
    const getQuestionByType = (question, idx) => {
        if (question.type === NodeType.NOTE) {
            return <Note key={`${question.id}_note`} question={question} />;
        }
        const Question = valueTypeToComponent[question.type];

        return (
            <Question
                updateForm={updateForm}
                question={question}
                key={`${question.id}_question`}
                policy_request_id={policy_request_id}
                firstInSection={idx === 0}
                submitFailed={submitFailed}
                setScroll={setScroll}
                scroll={scroll}
                setEditAnswerMode={setEditAnswerMode}
                setSubmissionDisabled={setSubmissionDisabled}
                setCheckTriedToSubmit={setCheckTriedToSubmit}
                reSubmitted={reSubmitted}
                setChangeMade={setChangeMade}
                answers={question.optional_answers}
            />
        );
    };
    return (
        <Box display="flex" flexDirection="column" width={width}>
            <Box
                display="flex"
                pl={isLg ? 5 : isSm ? 4 : 0}
                pt={5}
                justifyContent={isSm || isLg ? 'flex-start' : 'center'}>
                {!isSm && (
                    <IconBox
                        bgcolor={background_color}
                        color={text_color}
                        borderRadius="50%"
                        mr={5}
                        width="56px"
                        height="56px">
                        <DynamicIcon name={section.icon} typographyProps={{ variant: 'h5' }} />
                    </IconBox>
                )}
                <Box
                    alignSelf="center"
                    fontWeight="fontWeightBold"
                    width={isSm ? 'unset' : '424px'}
                    marginBottom="15px"
                    textAlign="left"
                    pr={isLg ? 0 : isSm ? 0 : 12}>
                    <Typography variant="h6">{section.title}</Typography>
                </Box>
            </Box>
            <Box pb={isSm ? 2 : 6.5}>
                {questions.map((question, idx) => getQuestionByType(question, idx))}
            </Box>
        </Box>
    );
};

export default QuestionsSection;
