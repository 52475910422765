import styled from 'styled-components';
import { Popover as _Popover } from '@material-ui/core';


const Popover = styled(_Popover)`
    &&& {
        .MuiPopover-paper {
            width: 184px;
            position: absolute;
            padding: 8px 0;
            margin: 2px 0 0 5px;
            border-radius: 2px;
        }
    }
`;

export default {
    Popover,
};
