import styled from 'styled-components';
import { Container as _Container, Typography as _Typography, Button as _Button, Box as _Box } from '@material-ui/core';

const Container = styled(_Container)`
    &&& {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex: 1;
        gap: 24px;
        background-color: white;
        align-items: center;
        border-bottom: 0.3px solid grey;
        padding: 16px 32px;
    }

    &:first-child {
        border-top: 0.3px solid grey;
    }
    &:last-child {
        border-bottom: none !important;
    }
`;

const Typography = styled(_Typography)`
    &&& {
        margin-bottom: 2px;
        color: #191919;
        text-align: left;

        ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightRegular};
        `}
    }
`;

const Button = styled(_Button)`
    &&& {
        text-transform: none;
        min-width: 95px;
        color: ${({ theme }) => theme.palette.primary.main};
        background-color: transparent;
        font-size: 14px;
        &[disabled] {
            opacity: 0.33;
        }
    }
`;

const MenuItem = styled(_Box)`
    &&& {
        position: relative;
        padding: 4px 0 7px 23px;
        font-size: 16px;
        color: #191919;

        &:before {
            left: 0;
            transition: 0.2s;
            content: '';
            display: block;
            position: absolute;
            top: calc(50% - 4px);
            width: ${({ selected }: any) => (selected ? '12px' : '0px')};
            height: 4px;
            background: #191919;
        }

        &:hover::before {
            width: 12px;
        }

        &:hover {
            cursor: pointer;
        }
    }
`;

export default {
    MenuItem,
    Container,
    Typography,
    Button,
};
