import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import {
    QuoteCustomization,
    Bind,
    PolicyDetails,
} from './pages';
import { Error } from 'components';

const SubmissionRoutes = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/quote_customization/:optionToQuoteId`}>
                <QuoteCustomization />
            </Route>
            <Route path={`${path}/bind/:optionToQuoteId`}>
                <Bind />
            </Route>
            <Route path={`${path}/policy_details/:optionToQuoteId`}>
                <PolicyDetails />
            </Route>
            <Route>
                <Error errorStatus="404"/>
            </Route>
        </Switch>
    );
};

export default SubmissionRoutes;
