import React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import Styled from '../styled-components';
import { StyledForm, StyledLink, Icon } from 'components';
import { Box } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';


const CreateAccountPage = ({
    firstName,
    lastName,
    email,
    firstNameError,
    lastNameError,
    emailError,
    handleNextSubmit,
    setFirstName,
    setLastName,
    setEmail,
    emailInput,
    nextButtonDisabled,
    loadingState,
}) => {
    const theme = useTheme();

    return (
        <>
            <Styled.FormHeader style={{ marginBottom: '35px' }}>Create Account</Styled.FormHeader>
            <StyledForm.Form onSubmit={handleNextSubmit}>
                <Box display="inline-block" width="46%">
                    <Styled.TextField
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                        required
                        type="text"
                        name="first_name"
                        placeholder="First Name..."
                        label={
                            <Styled.InputLabel style={{ visibility: firstName ? 'unset' : 'hidden' }}>
                                First Name
                            </Styled.InputLabel>
                        }
                        InputLabelProps={{
                            required: false,
                            shrink: true,
                        }}
                        error={Boolean(firstNameError)}
                        helperText={firstNameError}
                    />
                </Box>

                <Box display="inline-block" width="46%" marginLeft="20px">
                    <Styled.TextField
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                        required
                        type="text"
                        name="last_name"
                        placeholder="Last Name..."
                        label={
                            <Styled.InputLabel style={{ visibility: lastName ? 'unset' : 'hidden' }}>
                                Last Name
                            </Styled.InputLabel>
                        }
                        InputLabelProps={{
                            required: false,
                            shrink: true,
                        }}
                        error={Boolean(lastNameError)}
                        helperText={lastNameError}
                    />
                </Box>
                <Box>
                    <Styled.TextField
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        type="email"
                        name="email"
                        required
                        placeholder="Work Email Address..."
                        label={
                            email ? (
                                <Styled.InputLabel>Work Email Address</Styled.InputLabel>
                            ) : (
                                <Styled.InputLabel style={{ visibility: 'hidden' }}>
                                    Work Email Address
                                </Styled.InputLabel>
                            )
                        }
                        InputLabelProps={{
                            required: false,
                            shrink: true,
                        }}
                        InputProps={{ ref: emailInput }}
                        error={Boolean(emailError)}
                        helperText={
                            Boolean(emailError) ? (
                                emailError
                            ) : (
                                <Styled.InputLabel style={{ fontWeight: theme.typography.fontWeightLight }}>
                                    Agency email required to verify address
                                </Styled.InputLabel>
                            )
                        }
                    />
                </Box>
                <Styled.Button type="submit" disabled={nextButtonDisabled || loadingState}>
                    Next
                </Styled.Button>
            </StyledForm.Form>

            <Styled.SignUpText variant="subtitle1" color="primary">
                Already have an account?
            </Styled.SignUpText>
            <Styled.SignUpLink variant="subtitle1" color="primary">
                <StyledLink.Default to="/user/login">
                    Sign in to the broker platform
                    <Box display="inline-block" position="relative" top="3px">
                        <Icon icon={<KeyboardArrowRightIcon />} typographyProps={{ variant: 'h6' }} />
                    </Box>
                </StyledLink.Default>
            </Styled.SignUpLink>
        </>
    );
};

export default CreateAccountPage;
