import React, {useCallback, useState} from 'react';
import { StyledAction, StyledTable } from 'components';
import { Box, Typography, TableRow } from '@material-ui/core';
import { Hidden } from '@material-ui/core';
import ContingencyPopup from '../../../popup/ContingencyPopup';
import {PolicyRequest} from '../../../../types/PolicyRequest';

interface Props {
    policyRequest: PolicyRequest
}
const Contingencies = ({ policyRequest }: Props) => {
    const [openContingencyPopup, setOpenContingencyPopup] = useState(false);
    const handleCloseContingencyPopup = useCallback(() => {
        setOpenContingencyPopup(false);
    }, [])
    const handleOpenContingencyPopup = useCallback(() => {
        setOpenContingencyPopup(true);
    }, []);

    return (
        <>
            <Hidden smDown>
                <TableRow>
                    <StyledTable.TableCell colSpan={6}>
                        <Box component="span" fontWeight="fontWeightLight">
                            <Typography variant="subtitle2" component="span">
                                Contingencies
                            </Typography>
                        </Box>
                        <Box component="span" fontWeight="fontWeightMedium" ml={1}>
                            <StyledAction.Base onClick={handleOpenContingencyPopup}>
                                <Typography variant="subtitle2" component="span" color="primary">
                                    – Review contingencies
                                </Typography>
                            </StyledAction.Base>
                        </Box>
                    </StyledTable.TableCell>
                </TableRow>
            </Hidden>
            <Hidden mdUp>
                <TableRow>
                    <StyledTable.TableCell colSpan={6}>
                        <Box fontWeight="fontWeightLight">
                            <Typography variant="subtitle2" component="span">
                                Pre-bind contingencies
                            </Typography>
                        </Box>
                        <Box fontWeight="fontWeightMedium">
                            <StyledAction.Base onClick={handleOpenContingencyPopup} data-track="contingency-modal-button">
                                <Typography variant="subtitle2" color="primary">
                                    – Review contingencies
                                </Typography>
                            </StyledAction.Base>
                        </Box>
                    </StyledTable.TableCell>
                </TableRow>
            </Hidden>
            <ContingencyPopup
                isOpen={openContingencyPopup}
                handleClose={handleCloseContingencyPopup}
                policyRequest={policyRequest}
            />
        </>
    );
};

export default Contingencies;
