import React, { useMemo } from 'react';
import Styled from 'modules/submission/pages/quote-customization/components/styled-components';
import { useBreakpoint } from 'hooks';
import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { totalHumanize } from 'utils';
import { filterAllowedValues, MaxAggregateLimitAllowedToBroker } from 'utils/customization';


const SelectClaimLimit = ({ handleCalculation, classes }) => {
    const { customizedOptionToQuote } = useSelector(state => state.company);
    const isSm = useBreakpoint('down', 'sm');
    const perClaimLimitList = useMemo(() => {
        const filterAggregateLimitFromAllowedValues = customizedOptionToQuote.quote.coverages.allowed_limits.filter(
            allowedLimitParameter => allowedLimitParameter.value < customizedOptionToQuote.quote.aggregate_limit)
        return filterAllowedValues(filterAggregateLimitFromAllowedValues, customizedOptionToQuote.quote.per_claim_limit);
    }, [customizedOptionToQuote.quote.coverages.allowed_limits]);


    return (
        <Styled.Select
            value={customizedOptionToQuote.quote.per_claim_limit}
            className={isSm ? classes.MobileClaimSelect : classes.Select}
            onChange={event => handleCalculation(event.target.value, 'per_claim_limit', null)}
            name='per_claim_limit'
        >
            { (customizedOptionToQuote.quote.aggregate_limit <= MaxAggregateLimitAllowedToBroker ||
                customizedOptionToQuote.quote.per_claim_limit === customizedOptionToQuote.quote.aggregate_limit) &&
                <Box
                    component={Styled.MenuItem}
                    key="match"
                    value={customizedOptionToQuote.quote.aggregate_limit}
                >
                    <Typography variant="body1">
                        Match
                    </Typography>
                </Box>
            }
            {perClaimLimitList.map(allowedParameter => {
                return (
                    <Box
                        component={Styled.MenuItem}
                        key={`match-${allowedParameter.id}`}
                        value={allowedParameter.value}
                    >
                        <Typography>
                            ${totalHumanize(allowedParameter.value)}
                        </Typography>
                    </Box>
                )
            })}
        </Styled.Select>
    )
}

export default SelectClaimLimit;
