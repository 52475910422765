import { useAxiosClient } from 'hooks';

export const useRemoveMember = () => {
    const axiosClient = useAxiosClient();
    const [{ data, loading, error }, removeMember] = axiosClient(
        {
            method: 'post',
            url: '/team/remove_member',
        },
        { manual: true },
    );
    return [{data, loading, error}, removeMember]
}

export const useCreateTeam = () => {
    const axiosClient = useAxiosClient();
    const [{ data, loading, error }, createTeam] = axiosClient(
        {
            method: 'post',
            url: '/team/create',
        },
        { manual: true },
    );
    return [{data, loading, error}, createTeam]
}

export const useChangeTeamName = () => {
    const axiosClient = useAxiosClient();
    const [{ data, loading, error }, changeTeamName] = axiosClient(
        {
            method: 'post',
            url: '/team/edit_name',
        },
        { manual: true },
    );
    return [{data, loading, error}, changeTeamName]
}

export const useAddMember = () => {
    const axiosClient = useAxiosClient();
    const [{ data, loading, error }, addMember] = axiosClient(
        {
            method: 'post',
            url: '/team/add_member',
        },
        { manual: true },
    );
    return [{data, loading, error}, addMember]
}
