import React from 'react';
import Styled from './styled-components';
import CloseIcon from '@material-ui/icons/Close';
import {
    RoundedButton,
    StyledAction,
} from 'components';
import {
    Box,
    IconButton,
    Typography,
} from '@material-ui/core';

const GenericDialog = ({ title, body, buttonTitle, open, handleClose, handleButton }) => {
    return (
        <Styled.Dialog
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <Styled.DialogTitle>
                <Box width="100%" display="flex" justifyContent="space-between" fontWeight="fontWeightBold" alignItems="flex-start">
                    <Box>
                        <Typography variant="subtitle1">
                            {title}
                        </Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Styled.DialogTitle>
            <Styled.DialogContent>
                {body}
            </Styled.DialogContent>
            <Box component={Styled.DialogActions} display="flex" justifyContent={['space-between', 'space-between', 'flex-end']}>
                <Box component={StyledAction.Base} onClick={handleClose} mr={[2, 2, 4]}>
                    <Typography color="primary" variant="subtitle2">
                    Close
                    </Typography>
                </Box>
                <RoundedButton onClick={handleButton} color="primary">
                    <Typography variant="subtitle2">
                        {buttonTitle}
                    </Typography>
                </RoundedButton>
            </Box>
        </Styled.Dialog>
    )
}


export default GenericDialog;
