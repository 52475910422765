import React, { FC, Fragment } from 'react';
import { StyledTable } from 'components';
import { Box, TableRow, Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';

interface Props {
    variant: Variant | 'inherit';
    firstColSpan: number;
    firstColPadding: number;
    commission: number;
}
const CommissionTable: FC<Props> = ({ variant, firstColSpan, firstColPadding, commission }) => {
    const commissionPercentageString = `${Number(commission * 100).toLocaleString()}%`;

    return (
        <>
            <Fragment>
                <Box component={TableRow} fontWeight="fontWeightLight" className="head">
                    <StyledTable.TableCell className="head" colSpan={firstColSpan} padding="none">
                        <Typography variant={variant} component="span">
                            <Box component="p" mt={2} mb={0} pl={firstColPadding} fontSize={12} fontWeight={'bold'}>
                                Commission
                            </Box>
                        </Typography>
                    </StyledTable.TableCell>
                    <StyledTable.TableCell className="head" padding="none">
                        <Typography variant={variant} component="span">
                            <Box component="p" mt={2} mb={0} fontSize={12}>
                                {' '}
                            </Box>
                        </Typography>
                    </StyledTable.TableCell>
                    <StyledTable.TableCell className="head" padding="none">
                        <Typography variant={variant} component="span">
                            <Box component="p" mt={2} mb={0} fontSize={12} fontWeight={'bold'}>
                                {commissionPercentageString}
                            </Box>
                        </Typography>
                    </StyledTable.TableCell>
                </Box>
            </Fragment>
        </>
    );
};

export default CommissionTable;
