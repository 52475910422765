import { ErrorResponseType } from '../../mutationTypes';
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { useAxiosInstance } from 'hooks';
import { DashboardQueryKeys } from '../../queryKeys';
import { AxiosInstance } from 'axios';

type SuccessResponseType = {
    company_deleted: boolean;
};

type MutationReturnType = {
    mutate: UseMutateFunction<SuccessResponseType, ErrorResponseType, string>;
    error: ErrorResponseType | null;
    isLoading: boolean;
};

const deleteSubmission = async (policyRequestID: string, axiosInstance: AxiosInstance) => {
    const res = await axiosInstance.delete(`/policyRequest/${policyRequestID}`);
    return res.data as SuccessResponseType;
};

export const useDeleteSubmission = (
    onSuccessCallback: () => void,
    onErrorCallback: (error: ErrorResponseType) => void,
): MutationReturnType => {
    const queryClient = useQueryClient();
    const axiosInstance = useAxiosInstance();

    const { mutate, error, isLoading } = useMutation(
        (optionToQuoteID: string) => deleteSubmission(optionToQuoteID, axiosInstance),
        {
            onSuccess: onSuccessCallback,
            onError: onErrorCallback,
            onSettled: () => {
                queryClient.resetQueries([DashboardQueryKeys.BUCKETS.QUOTES]);
                queryClient.resetQueries([DashboardQueryKeys.SNAPSHOT]);
            },
        },
    );
    return { mutate, error, isLoading };
};
