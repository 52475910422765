import React, { useEffect, useState, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';


const OverflowTip = ({ text, component }) => {

    const textElementRef = useRef();
    const compareSize = () => {
        const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
        setHover(compare);
    };

    useEffect(() => {
        compareSize();
        window.addEventListener('mouseover', compareSize);
        return () => {
            window.removeEventListener('mouseover', compareSize);
        }
    }, []);

    // Define state and function to update the value
    const [hoverStatus, setHover] = useState(false);

    return (
        <Tooltip
            placement="bottom-start"
            title={text}
            disableHoverListener={!hoverStatus}
            style={{fontSize: '2em'}}
        >
            {component(textElementRef)}
        </Tooltip>
    );
};

export default OverflowTip;
