import styled from 'styled-components';
import { Box as _Box } from '@material-ui/core';
import { Link as _Link } from 'react-router-dom';

const MenuItem = styled(_Box)`
    &&& {
        position: relative;
        padding: 4px 0 7px 23px;
        font-size: 16px;
        font-family: America;
        color: ${({ color }) => color};

        &:before {
            left: 0;
            transition: 0.2s;
            content: '';
            display: block;
            position: absolute;
            top: calc(50% - 4px);
            width: ${({ selected }) => (selected ? '12px' : '0px')};
            height: 4px;
            background: ${({ linecolor }) => linecolor};
        }

        &:hover::before {
            width: 12px;
        }

        &:hover {
            cursor: pointer;
        }
    }
`;

const EllipsisBox = styled(_Box) `
    &&& {
        .MuiTypography-body2 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 85%;
        }
    }
`;

const Link = styled(_Link)`
    &&& {
        text-decoration-line: none;
    }
`;

export default {
    MenuItem,
    Link,
    EllipsisBox,
};
