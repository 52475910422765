import React, { useState } from 'react';
import { DesktopTablet, Mobile } from './components';
import { useCreateReferralDetails } from 'api/policy/mutations/useCreateReferralDetails';
import { makeToast } from 'utils';
import { Hidden } from '@material-ui/core';


const PendingReview = ({ policyRequest }) => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [noteText, setNoteText] = useState('');
    const [showDetailsSentCard, setShowDetailsSentCard] = useState(false);


    const handleOnSubmitRequestSuccess = () => {
        setUploadedFiles([]);
        setNoteText('');
        setShowDetailsSentCard(true);
    };

    const handleOnSubmitRequestFail = () => {
        makeToast('error', 'Something went wrong.');
    }

    const { mutate: CreateReferralDetails, isLoading: submitLoading } = useCreateReferralDetails(
        handleOnSubmitRequestSuccess,
        handleOnSubmitRequestFail,
    );

    const handleSubmit = async () => {
        const formData = new FormData();

        formData.append('note_text', noteText);
        for (let file of uploadedFiles) {
            formData.append('files', file);
        }

        CreateReferralDetails({policyRequestId: policyRequest.id, CreateReferralDetailsData: formData});
    };

    const handleNoteTextChange = text => {
        setNoteText(text);
    };

    const handleRemoveFile = fileToRemove => {
        const updatedFiles = [...uploadedFiles];
        updatedFiles.splice(updatedFiles.indexOf(fileToRemove), 1);
        setUploadedFiles(updatedFiles);
    };

    const handleNewFiles = acceptedFiles => {
        setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
    };

    const props = {
        policyRequest, handleNewFiles,
        handleRemoveFile, uploadedFiles,
        handleNoteTextChange, noteText,
        handleSubmit, submitLoading,
        showDetailsSentCard, setShowDetailsSentCard,
    };
    return (
        <>
            <Hidden smDown>
                <DesktopTablet {...props} />
            </Hidden>
            <Hidden mdUp>
                <Mobile {...props} />
            </Hidden>
        </>
    );
}

export default PendingReview;
