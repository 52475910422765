import { useAxiosInstance } from '../hooks/useAxios';
import { useBreakpoint } from 'hooks';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Box, Snackbar, SnackbarContent, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { Icon } from 'components';
import { theme } from 'components/theme/styles';

const ErrorBoundary = ({ children }) => {
    const axiosInstance = useAxiosInstance();
    const isSm = useBreakpoint('down', 'sm');
    const isLg = useBreakpoint('up', 'lg');
    const errorRef = useRef({ errorMessage: null, statusCode: null });
    const [displayErrorBanner, setDisplayErrorBanner] = useState(false);

    const extractError = err => {
        const errorMessage = err.response.data.error || 'Internal Server Error';
        const statusCode = err.response.status;
        return {
            errorMessage,
            statusCode,
        };
    };

    const resetErrorBanner = () => {
        errorRef.current = { errorMessage: null, statusCode: null };
        setDisplayErrorBanner(false);
    };

    const buildErrorBanner = error => {
        errorRef.current = extractError(error);
        setDisplayErrorBanner(true);
    };

    useLayoutEffect(() => {
        const reqInterceptor = axiosInstance.interceptors.request.use(req => {
            resetErrorBanner();
            return req;
        });

        const resInterceptor = axiosInstance.interceptors.response.use(
            res => res,
            err => {
                buildErrorBanner(err);
            },
        );
        return () => {
            axiosInstance.interceptors.request.eject(reqInterceptor);
            axiosInstance.interceptors.response.eject(resInterceptor);
        };
    }, [axiosInstance]);

    let { errorMessage, statusCode } = errorRef.current;

    return (
        <>
            <Snackbar
                style={{ minWidth: '100%', top: '80px', bottom: 'auto', maxWidth: '1280px' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={displayErrorBanner}
                onClose={resetErrorBanner}
                autoHideDuration={8000}
                id="innerAppBar">
                <SnackbarContent
                    style={{
                        minWidth: '100%',
                        backgroundColor: '#FFDEDE',
                        justifyContent: isSm ? 'start' : 'center',
                    }}
                    message={
                        <Box
                            fontWeight="fontWeightRegular"
                            color="#F02A26"
                            display="flex"
                            alignItems="center"
                            pl={isSm ? 4 : 0}
                            minWidth={isSm ? 'unset' : isLg ? theme.breakpoints.values.container : '616px'}>
                            {!isSm && (
                                <Box display="flex">
                                    <Icon icon={<ErrorIcon />} typographyProps={{ variant: 'h6' }} />
                                </Box>
                            )}
                            <Typography variant="body1">
                                {`status code ${statusCode}, message: ${errorMessage}`}
                            </Typography>
                        </Box>
                    }
                />
            </Snackbar>
            {children}
        </>
    );
};

export default ErrorBoundary;
