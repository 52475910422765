import { useAxiosInstance } from 'hooks';
import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';
import { ErrorResponseType } from '../../mutationTypes';
import { CompanyPolicies } from 'types/CompanyPolicies';
import { getQueryBySubBucket } from '../../../queries/companies';
import { BucketType, SubBucketType } from 'modules/company/pages/dashboard/components/companies-pagination/types';

interface Props {
    bucket: BucketType;
    subBucket: SubBucketType;
    nextClientId: number;
}

type QueryReturnType = {
    data: GetCompaniesResponseType | undefined;
    error: ErrorResponseType | null;
    isFetching: boolean;
};

export type GetCompaniesResponseType = {
    companies: CompanyPolicies[] | null;
    nextClientId: number | null;
};

const getCompanies = async (
    bucket: BucketType,
    subBucket: SubBucketType,
    nextClientId: number,
    axiosInstance: AxiosInstance,
): Promise<GetCompaniesResponseType> => {
    const query = getQueryBySubBucket(bucket, subBucket);
    const res = await axiosInstance.post('/user/companies', {
        bucket,
        sub_bucket: subBucket,
        next_id: nextClientId,
        query,
    });
    return {
        companies: res.data?.data as CompanyPolicies[],
        nextClientId: res.data?.next_id,
    } as GetCompaniesResponseType;
};

export const useCompanies = (props: Props): QueryReturnType => {
    const { bucket, subBucket, nextClientId } = props;
    const axiosInstance = useAxiosInstance();

    const { data, error, isFetching } = useQuery({
        queryKey: [bucket, subBucket, nextClientId],
        queryFn: () => getCompanies(bucket, subBucket, nextClientId, axiosInstance),
        onError: (err: ErrorResponseType | null) => err,
        keepPreviousData: true,
    });
    return { data, error, isFetching };
};
