import React from 'react';
import {
    Box,
    Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import {Contingency} from '../../../types/PolicyRequest';

const ContingencySectionTitle = styled(Typography)`
  font-weight: bold;
`
interface Props {
    title: string,
    contingencies: Contingency[],
}
const ContingencySection = ({title, contingencies}: Props) => {
    return (
        <>
            <Box>
                <ContingencySectionTitle>
                    {title}
                </ContingencySectionTitle>
            </Box>
            <Box>
                <ul>
                    {contingencies.map((contingency) => {
                        return (
                            <li key={`contingency-${contingency.id}`}>{contingency.text}</li>
                        );
                    })}
                </ul>
            </Box>
        </>
    );
}

export default ContingencySection
