import React, { useEffect, useRef, useState } from 'react';
import QuestionsSection from './QuestionsSection';
import { Box, Hidden, Snackbar, SnackbarContent, Typography } from '@material-ui/core';
import { Icon, Loading, RoundedButton, StyledForm, StyledLink } from 'components';
import GeneralStyled from '../../components/styled-components';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import QuestionnaireLinearProgress from 'components/progress/QuestionnaireLinearProgress';
import { theme } from 'components/theme/styles';
import LockIcon from '@material-ui/icons/Lock';
import { useAxiosClient, useAxiosInstance, useBreakpoint, useEnv } from 'hooks';
import ErrorIcon from '@material-ui/icons/Error';
import {
    addAnswer,
    removeAnswer,
    setInitialState,
    setNumberOfAnswers,
    setNumberOfQuestions,
    setQuestionsAnswered,
    setSectionsData,
    setQuestionnaireStateToLocked,
    setQuestionnaireStateToChangesSaved,
    setQuestionnaireStateToSavingAnswer,
    setQuestionsMetadata,
    setQuestionMetadata,
} from 'stores/questionnaire/questionnaireSlice';
import { setQuestionsToAnswers } from 'stores/questions/questionsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { newQuestionsErrorState, questionsMetadataState } from 'utils/questionnaireSequence';
import { setQuestionsSequenceData, setSubmitFailed, updateQuestionsSequenceData } from 'stores/questionsErrors/questionsErrorsSlice';
import { extractQuestionnaireToStore } from '../utils';
import { makeToast } from 'utils';
import { useSubmitPolicy } from 'api/policy/mutations/useSubmitPolicy';
import { usePermissions } from 'hooks/usePermissions';
import { Permissions } from 'utils/enums';

const NewQuestionnaire = ({ fullPolicyRequest }) => {
    const dispatch = useDispatch();
    const {
        sectionsData,
        numberOfQuestions,
        numberOfAnswers,
        textToDisplay,
        questionsAnswered,
        isCurrentlySavingAnswer,
    } = useSelector(state => state.questionnaire);
    const [numOfQuestion, setNumOfQuestion] = useState(null);
    const history = useHistory();
    const location = useLocation();
    const { apiBaseUrl } = useEnv();
    const axiosClient = useAxiosClient();
    const axiosInstance = useAxiosInstance();
    const { policyRequestId } = useParams();
    const questionsSection = useRef(null);
    const stickyBar = useRef(null);
    const [policyRequest, setPolicyRequest] = useState(false);
    const [sectionsReady, setSectionsReady] = useState(false);
    const [submissionDisabled, setSubmissionDisabled] = useState(true);
    const [editAnswerMode, setEditAnswerMode] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [reSubmitted, setReSubmitted] = useState(false);
    const [stickySection, setStickySection] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [banner, setBanner] = useState(false);
    const [tryToSubmit, setTryToSubmit] = useState(false);
    const [checkTriedToSubmit, setCheckTriedToSubmit] = useState(false);
    const [editApp, setEditApp] = useState(null);
    const [changeMade, setChangeMade] = useState(false);
    const [editAppQuestionToAnswer, setEditAppQuestionToAnswer] = useState({});
    const submissionWriteAuthorized = usePermissions()([Permissions.SUBMISSION_WRITE]);
    const isSm = useBreakpoint('down', 'sm');
    const isLg = useBreakpoint('up', 'lg');

    const handleOnSubmitRequestSuccess = () => {
        history.push(
            {
                pathname: `/company/${policyRequest.company_id}`,
                search: `?policyId=${policyRequest.id}`,
            });
    };

    const handleOnSubmitRequestFail = error => {
        makeToast('error', error.response?.data?.message);
        history.push('/dashboard');
    };

    const [, getQuestionnaireSequence] = axiosClient(
        {
            method: 'post',
            url: `/questionnaire/${policyRequestId}`,
        },
        { manual: true },
    );

    const { mutate: submitPolicyRequest, isLoading } = useSubmitPolicy(
        handleOnSubmitRequestSuccess,
        handleOnSubmitRequestFail,
    );

    const getAndSetPolicyRequest = () => {
        setPolicyRequest(fullPolicyRequest);
        return fullPolicyRequest.broker_submitted;
    };

    const fetchFullPolicyRequest = async () => {
        const broker_submitted = getAndSetPolicyRequest();
        if (broker_submitted && !editApp) {
            dispatch(setQuestionnaireStateToLocked());
        }
        await getAndHandleQuestionnaire();
    };

    const shouldDisableApplication = () => {
        return (policyRequest.broker_submitted && !editApp) || isCurrentlySavingAnswer || !submissionWriteAuthorized;
    }

    useEffect(() => {
        dispatch(setInitialState());
        dispatch(setQuestionsToAnswers(null));
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        // resubmit application
        setReSubmitted(location.state?.resubmit);
        // edit application
        setEditApp(location.state?.editMode || false);
    }, [location]);

    useEffect(() => {
        if (editApp !== null) {
            fetchFullPolicyRequest();
        }
    }, [editApp]);

    useEffect(() => {
        setSubmissionDisabled(numberOfAnswers !== numOfQuestion);
    }, [numberOfAnswers, numOfQuestion]);

    const submitPolicyRequestAndWait = async () => {
        if (policyRequest.broker_submitted) {
            history.push(
                {
                    pathname: `/company/${policyRequest.company_id}`,
                    state: {submitEditApp: true},
                    search: `?policyId=${policyRequest.id}`,
                });
        } else if (!editAnswerMode) {
            setSubmitted(true);
            submitPolicyRequest();
        }
    };

    useEffect(() => {
        if (checkTriedToSubmit) {
            setCheckTriedToSubmit(false);
            if (tryToSubmit && !editAnswerMode) {
                helpSubmit();
            }
        }
    }, [checkTriedToSubmit, tryToSubmit, editAnswerMode]);

    const resubmitApplicationEditApp = async () => {
        let editAppQuestionsToSubmit = {};
        Object.keys(editAppQuestionToAnswer).forEach(questionId => {
            const currentQuestionThatExtract = editAppQuestionToAnswer[questionId];
            const questionToSubmit = {
                value: currentQuestionThatExtract.freeTextValue
                    ? currentQuestionThatExtract.freeTextValue
                    : currentQuestionThatExtract.answerId
                        ? currentQuestionThatExtract.answerId
                        : currentQuestionThatExtract.answersIds,
                answerId: currentQuestionThatExtract.answerId
                    ? currentQuestionThatExtract.answerId
                    : currentQuestionThatExtract.answersIds,
                answerType: currentQuestionThatExtract.answerType,
                questionType: currentQuestionThatExtract.questionType,
                jsonValue: null,
            };
            editAppQuestionsToSubmit[questionId] = questionToSubmit;
        });
        await axiosInstance.post(`/questionnaire/resubmit_edit_application/${policyRequestId}`, {
            questionsToSubmit: editAppQuestionsToSubmit,
        });
    };

    const helpSubmit = async () => {
        if (editAnswerMode) {
            setTryToSubmit(true);
        } else {
            if (submissionDisabled) {
                dispatch(setSubmitFailed(true));
                setBanner(true);
                setScroll(true);
                setTryToSubmit(false);
            } else {
                if (editApp) {
                    setSubmitted(true);
                    await resubmitApplicationEditApp();
                }
                setSubmissionDisabled(true);
                await submitPolicyRequestAndWait();
            }
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        helpSubmit();
    };

    const handleUpdateStore = res => {
        const resSectionData = res.data.sectionsData;
        const resQuestionToAnswer = res.data.answers;
        const questionsSequence = newQuestionsErrorState(resSectionData);
        dispatch(updateQuestionsSequenceData(questionsSequence));
        const [questionToAnswer, numOfQuestions, editAppQuestionToAnswer] = extractQuestionnaireToStore(
            resSectionData,
            resQuestionToAnswer,
        );
        const questionWithAnswer = Object.keys(questionToAnswer).filter(item => questionToAnswer[item] !== null);
        dispatch(setSectionsData(resSectionData));
        dispatch(setQuestionsToAnswers(questionToAnswer));
        dispatch(setQuestionsAnswered(questionWithAnswer));
        dispatch(setNumberOfAnswers(questionWithAnswer.length));
        dispatch(setNumberOfQuestions(numOfQuestions));
        setEditAppQuestionToAnswer(editAppQuestionToAnswer);
        setNumOfQuestion(numOfQuestions);
    };

    const updateAnswersStore = async ({ questionId, value, invalid, required }) => {
        if (required) {
            if (value && !invalid && !questionsAnswered?.includes(questionId)) {
                dispatch(addAnswer(questionId));
            } else if ((!value || invalid) && questionsAnswered?.includes(questionId)) {
                dispatch(removeAnswer(questionId));
            }
        } else {
            if (invalid || !value) {
                if (questionsAnswered.includes(questionId)) {
                    setNumOfQuestion(numOfQuestion - 1);
                }
                dispatch(removeAnswer(questionId));
            } else if (!invalid && value) {
                if (!questionsAnswered.includes(questionId)) {
                    setNumOfQuestion(numOfQuestion + 1);
                }
                dispatch(addAnswer(questionId));
            }
        }
    };

    const updateEditAppQuestionnaire = async ({ questionId, answerId, answerType, value, invalid, questionType }) => {
        // We are in Edit App mode - so we just send the answers without really update them:
        // we need to use axios instance because if we call the function returned by axiosClient more than once at the same time
        // the first request will be canceled
        const jsonValue = typeof value === 'object' ? value : null;
        const editAppQuestionToAnswerCopy = updateEditAppQuestionToAnswer({
            questionId: questionId,
            questionType: questionType,
            value: invalid ? null : jsonValue ? jsonValue : value || null,
            answerId: answerId,
            answerType: answerType,
        });
        setEditAppQuestionToAnswer(editAppQuestionToAnswerCopy);
        try {
            const res = await axiosInstance.post(`/questionnaire/${policyRequestId}/edit_app`, {
                editApp: editAppQuestionToAnswerCopy,
            });
            handleUpdateStore(res);
        } catch (err) {
            console.error(err);
        }
    };

    const updateRegularQuestionnaire = async ({ questionId, answerId, answerType, value, invalid, questionType }) => {
        // we need to use axios instance because if we call the function returned by axiosClient more than once at the same time
        // the first request will be canceled
        const jsonValue = typeof value === 'object' ? value : null;
        try {
            const res = await axiosInstance.post(`/questionnaire/mpl_values/${policyRequestId}/${questionId}`, {
                value: invalid ? null : value || null,
                answerId: answerId,
                answerType: answerType,
                questionType: questionType,
                jsonValue: jsonValue,
            });
            handleUpdateStore(res);
        } catch (err) {
            console.error(err);
        }
    };

    const updateForm = async ({ questionId, answerId, answerType, value, invalid, required, questionType }) => {
        setEditAnswerMode(true);
        dispatch(setQuestionnaireStateToSavingAnswer());
        dispatch(
            setQuestionMetadata({
                questionId,
                metadataToUpdate: {
                    savingQuestion: true,
                },
            }),
        );
        if (Array.isArray(value)) {
            value = value.join(';');
        }
        await updateAnswersStore({ questionId, value, invalid, required });

        if (!editApp) {
            await updateRegularQuestionnaire({ questionId, answerId, answerType, value, invalid, questionType });
        } else {
            await updateEditAppQuestionnaire({ questionId, answerId, answerType, value, invalid, questionType });
        }

        setEditAnswerMode(false);
        setCheckTriedToSubmit(true);
        dispatch(
            setQuestionMetadata({
                questionId,
                metadataToUpdate: {
                    savingQuestion: false,
                },
            }),
        );
        dispatch(setQuestionnaireStateToChangesSaved());
    };

    const handleEditAppFreeTextAnswers = ({
        editAppQuestionToAnswerCopy,
        questionId,
        value,
        answerId,
        answerType,
        questionType,
    }) => {
        if (value) {
            const editAppQuestion = {
                answerId: answerId,
                answerType: answerType,
                questionType: questionType,
                freeTextValue: value,
            };
            editAppQuestionToAnswerCopy[questionId] = editAppQuestion;
        } else if (editAppQuestionToAnswer.hasOwnProperty(questionId)) {
            delete editAppQuestionToAnswerCopy[questionId];
        }
    };

    const handleEditAppMultiSelectQuestion = ({
        editAppQuestionToAnswerCopy,
        questionId,
        answerId,
        answerType,
        questionType,
    }) => {
        if (answerId.length > 0) {
            const editAppQuestion = {
                answersIds: answerId,
                answerType: answerType,
                questionType: questionType,
            };
            editAppQuestionToAnswerCopy[questionId] = editAppQuestion;
        } else if (editAppQuestionToAnswer.hasOwnProperty(questionId)) {
            delete editAppQuestionToAnswerCopy[questionId];
        }
    };

    const handleEditAppSelectionAnswers = ({
        editAppQuestionToAnswerCopy,
        questionId,
        answerId,
        answerType,
        questionType,
    }) => {
        if (Array.isArray(answerId)) {
            handleEditAppMultiSelectQuestion({
                editAppQuestionToAnswerCopy,
                questionId,
                answerId,
                answerType,
                questionType,
            });
        } else if (typeof answerId === 'string') {
            const editAppQuestion = {
                answerId: answerId,
                answerType: answerType,
                questionType: questionType,
            };
            editAppQuestionToAnswerCopy[questionId] = editAppQuestion;
        }
    };

    const updateEditAppQuestionToAnswer = ({ questionId, value, answerId, answerType, questionType }) => {
        const editAppQuestionToAnswerCopy = { ...editAppQuestionToAnswer };
        if (['FreeTextAnswer', 'NumberAnswer', 'IntegerAnswer'].includes(answerType)) {
            handleEditAppFreeTextAnswers({
                editAppQuestionToAnswerCopy,
                questionId,
                value,
                answerId,
                answerType,
                questionType,
            });
        } else if (answerType === 'SelectionAnswer') {
            handleEditAppSelectionAnswers({
                editAppQuestionToAnswerCopy,
                questionId,
                answerId,
                answerType,
                questionType,
            });
        }
        return editAppQuestionToAnswerCopy;
    };

    const getAndHandleQuestionnaire = async () => {
        const res = await getQuestionnaireSequence();
        handleUpdateStore(res);
        const updatedSections = res.data.sectionsData;
        const questionsSequence = newQuestionsErrorState(updatedSections);
        dispatch(setQuestionsSequenceData(questionsSequence));
        const questionsMetadata = questionsMetadataState(updatedSections);

        dispatch(setQuestionsMetadata(questionsMetadata));
        setSectionsReady(true);
    };

    const handleScroll = () => {
        const questionsTop = questionsSection.current?.offsetTop;
        const $root = document.querySelector('#root');
        const offset = $root.children[1]?.clientHeight;
        const afterHeader = questionsTop - offset;
        if (window.scrollY >= afterHeader * 0.75) {
            setStickySection(true);
        }
        if (window.scrollY <= afterHeader / 2) {
            setStickySection(false);
        }
    };

    if (isLoading) {
        return (
            <Box position="relative" minHeight="100px" width="100%">
                <Loading />
            </Box>
        );
    }
    return (
        <Box
            component={GeneralStyled.QuestionnaireContainer}
            data-testid="new_questionnaire"
        >
            {sectionsReady && (
                <>
                    <Hidden mdDown>
                        <Box display="flex" justifyContent="center">
                            <Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    mr={8}
                                    width="200px"
                                    position="sticky"
                                    top="150px">
                                    <Box fontWeight="fontWeightBold" color="#AFAFAF">
                                        <Typography variant="h3">{policyRequest.product?.name}</Typography>
                                    </Box>
                                    <Box fontWeight="fontWeightBold" color="#191919" pb={2.5}>
                                        <Typography variant="h3">Application</Typography>
                                    </Box>

                                    <StyledLink.Anchor
                                        data-track={'questionnaire_download'}
                                        href={`${apiBaseUrl}/questionnaire/render_policy_request/${policyRequest.id}/questionnaire`}
                                        target="_blank">
                                        <GeneralStyled.DownloadBox>
                                            <Typography variant="body2">Download Application</Typography>
                                            <Box display="flex" alignItems="center" ml={0.75} pb="2px">
                                                <Icon
                                                    icon={<VerticalAlignBottomIcon />}
                                                    typographyProps={{ variant: 'body1' }}
                                                />
                                            </Box>
                                        </GeneralStyled.DownloadBox>
                                    </StyledLink.Anchor>

                                    <Box my={2}>
                                        <QuestionnaireLinearProgress
                                            value={(numberOfAnswers / numOfQuestion) * 100}
                                            variant="determinate"
                                            color={
                                                policyRequest.product?.background_color || theme.palette.primary.main
                                            }
                                        />
                                    </Box>

                                    <Box display="flex" color="#AFAFAF" mb={1}>
                                        <Box fontWeight="fontWeightMedium" color="#AFAFAF" display="flex">
                                            <Typography variant="body2">
                                                <span style={{ display: isCurrentlySavingAnswer ? 'none' : 'inline' }}>
                                                    <span style={{ color: '#191919', fontWeight: 700 }}>
                                                        {numberOfAnswers}
                                                    </span>{' '}
                                                    / {numOfQuestion}{' '}
                                                </span>
                                                {textToDisplay}
                                            </Typography>
                                            {policyRequest.broker_submitted && !editApp && (
                                                <Icon icon={<LockIcon />} typographyProps={{ variant: 'body1' }} />
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box justifyContent="space-between">
                                <StyledForm.Form
                                    onSubmit={handleSubmit}
                                    data-track={`questionnaire_product_${policyRequest.product?.id}`}>
                                    {sectionsData.map((section, idx) => (
                                        <GeneralStyled.SectionBox
                                            className={shouldDisableApplication() && 'disabled'}
                                            key={`${idx}_${section.section.id}`}
                                            bgcolor="white"
                                            width="696px"
                                            mb={0.5}
                                            data-track={`questionnaire_section_${section.section.id}`}>
                                            <QuestionsSection
                                                sectionIndex={idx}
                                                width="100%"
                                                updateForm={updateForm}
                                                section={section.section}
                                                background_color={policyRequest.product?.background_color}
                                                text_color={policyRequest.product?.text_color}
                                                questions={section.questions}
                                                policy_request_id={policyRequest.id}
                                                setScroll={setScroll}
                                                scroll={scroll}
                                                setEditAnswerMode={setEditAnswerMode}
                                                setSubmissionDisabled={setSubmissionDisabled}
                                                setCheckTriedToSubmit={setCheckTriedToSubmit}
                                                reSubmitted={reSubmitted}
                                                setChangeMade={setChangeMade}
                                            />
                                        </GeneralStyled.SectionBox>
                                    ))}
                                    <Box textAlign="center" py={4}>
                                        <RoundedButton
                                            data-track={`questionnaire_${
                                                policyRequest.broker_submitted && !editApp
                                                    ? 'View Details'
                                                    : submitted
                                                        ? 'Please wait...'
                                                        : reSubmitted || editApp
                                                            ? 'Resubmit'
                                                            : 'Submit'
                                            }`}
                                            onClick={handleSubmit}
                                            disabled={
                                                submitted ||
                                                (editApp && !changeMade) ||
                                                isCurrentlySavingAnswer ||
                                                !submissionWriteAuthorized
                                            }
                                            color="#E6E6E6"
                                            bgcolor="#E6E6E6"
                                            px={7}
                                            py={1.25}
                                            minWidth="200px"
                                            border="none !important">
                                            <Typography variant="h5">
                                                {policyRequest.broker_submitted && !editApp
                                                    ? 'View Details'
                                                    : submitted || isCurrentlySavingAnswer
                                                        ? 'Please wait...'
                                                        : reSubmitted || editApp
                                                            ? 'Resubmit'
                                                            : 'Submit'}
                                            </Typography>
                                        </RoundedButton>
                                    </Box>
                                </StyledForm.Form>
                            </Box>
                        </Box>
                    </Hidden>

                    <Snackbar
                        style={{ minWidth: '100%', top: '80px', bottom: 'auto', maxWidth: '1280px' }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={banner}
                        onClose={() => setBanner(false)}
                        autoHideDuration={8000}
                        id="innerAppBar">
                        <SnackbarContent
                            style={{
                                minWidth: '100%',
                                backgroundColor: '#FFDEDE',
                                justifyContent: isSm ? 'start' : 'center',
                            }}
                            message={
                                <Box
                                    fontWeight="fontWeightRegular"
                                    color="#F02A26"
                                    display="flex"
                                    alignItems="center"
                                    pl={isSm ? 4 : 0}
                                    minWidth={isSm ? 'unset' : isLg ? theme.breakpoints.values.container : '616px'}>
                                    {!isSm && (
                                        <Box display="flex">
                                            <Icon icon={<ErrorIcon />} typographyProps={{ variant: 'h6' }} />
                                        </Box>
                                    )}
                                    <Typography variant="body1">
                                        Please make sure all questions are answered before submitting. If this persists,
                                        try refreshing the page.
                                    </Typography>
                                </Box>
                            }
                        />
                    </Snackbar>

                    <Hidden lgUp>
                        {!isSm && stickySection && !banner && (
                            <Box component={GeneralStyled.AppBar} ref={stickyBar} id="innerAppBar">
                                <GeneralStyled.Toolbar>
                                    <Box width="100%" display="flex" justifyContent="space-between">
                                        <Box width="75%" display="flex">
                                            <Box display="flex">
                                                <Box fontWeight="fontWeightBold" color="#AFAFAF" pr={0.5}>
                                                    <Typography variant="subtitle2">
                                                        {policyRequest.product?.name}
                                                    </Typography>
                                                </Box>
                                                <Box fontWeight="fontWeightBold" color="#191919">
                                                    <Typography variant="subtitle2">Application</Typography>
                                                </Box>
                                            </Box>
                                            <Box ml={2.875} mt={0.5} mb={1} minWidth="200px" maxHeight="12px">
                                                <QuestionnaireLinearProgress
                                                    value={(numberOfAnswers / numberOfQuestions) * 100}
                                                    variant="determinate"
                                                    color={
                                                        policyRequest.product?.background_color ||
                                                        theme.palette.primary.main
                                                    }
                                                    background_color="white"
                                                />
                                            </Box>
                                        </Box>
                                        <Box display="flex" color="#AFAFAF" mb={1}>
                                            <Box fontWeight="fontWeightMedium" color="#AFAFAF" display="flex">
                                                <Typography variant="body2">
                                                    <span style={{ color: '#191919', fontWeight: 700 }}>
                                                        {numberOfAnswers}
                                                    </span>{' '}
                                                    / {numberOfQuestions} {textToDisplay}
                                                </Typography>
                                                {policyRequest.broker_submitted && !editApp && (
                                                    <Icon icon={<LockIcon />} typographyProps={{ variant: 'body1' }} />
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                </GeneralStyled.Toolbar>
                            </Box>
                        )}
                        {isSm && stickySection && !banner && (
                            <Box component={GeneralStyled.AppBar} ref={stickyBar} id="innerAppBar">
                                <GeneralStyled.Toolbar>
                                    <Box
                                        width="100%"
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="space-between">
                                        <Box display="flex">
                                            <Box fontWeight="fontWeightBold" color="#AFAFAF" pr={0.5}>
                                                <Typography variant="subtitle2">
                                                    {policyRequest.product?.name}
                                                </Typography>
                                            </Box>
                                            <Box fontWeight="fontWeightBold" color="#191919">
                                                <Typography variant="subtitle2">Application</Typography>
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" pt={0.5}>
                                            <Box display="flex" color="#AFAFAF" mb={1}>
                                                <Box fontWeight="fontWeightMedium" color="#AFAFAF" display="flex">
                                                    <Typography variant="body2">
                                                        <span style={{ color: '#191919', fontWeight: 700 }}>
                                                            {numberOfAnswers}
                                                        </span>{' '}
                                                        / {numberOfQuestions} {textToDisplay}
                                                    </Typography>
                                                    {policyRequest.broker_submitted && !editApp && (
                                                        <Icon
                                                            icon={<LockIcon />}
                                                            typographyProps={{ variant: 'body1' }}
                                                        />
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box mb={1} minWidth="45%" maxHeight="12px">
                                                <QuestionnaireLinearProgress
                                                    value={(numberOfAnswers / numberOfQuestions) * 100}
                                                    variant="determinate"
                                                    color={
                                                        policyRequest.product?.background_color ||
                                                        theme.palette.primary.main
                                                    }
                                                    background_color="white"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </GeneralStyled.Toolbar>
                            </Box>
                        )}
                        <Box display="flex" justifyContent="center" flexDirection="column" hidden={stickySection}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                px={isSm ? 4 : 7}
                                mb={isSm ? 4 : 5}
                                mt={isSm ? 4 : 4.75}
                                alignItems="baseline"
                                flexDirection={isSm ? 'column' : 'row'}>
                                <Box display="flex">
                                    <Box fontWeight="fontWeightBold" color="#AFAFAF" pr={0.5}>
                                        <Typography variant="h3">{policyRequest.product?.name}</Typography>
                                    </Box>
                                    <Box fontWeight="fontWeightBold" color="#191919">
                                        <Typography variant="h3">Application</Typography>
                                    </Box>
                                </Box>
                                <StyledLink.Anchor
                                    data-track={'questionnaire_download'}
                                    href={`${apiBaseUrl}/questionnaire/render_policy_request/${policyRequest.id}/questionnaire`}
                                    target="_blank">
                                    <GeneralStyled.DownloadBox>
                                        <Box display="flex">
                                            <Typography variant="body2">Download Application</Typography>
                                            <Box display="flex" ml={0.75} pt="1px">
                                                <Icon
                                                    icon={<VerticalAlignBottomIcon />}
                                                    typographyProps={{ variant: 'body2' }}
                                                />
                                            </Box>
                                        </Box>
                                    </GeneralStyled.DownloadBox>
                                </StyledLink.Anchor>
                            </Box>
                            <Box display="flex" flexDirection="column" width="100%" ref={questionsSection}>
                                <StyledForm.Form
                                    onSubmit={handleSubmit}
                                    data-track={`questionnaire_product_${policyRequest.product?.id}`}>
                                    {sectionsData.map((section, idx) => (
                                        <GeneralStyled.SectionBox
                                            className={shouldDisableApplication() && 'disabled'}
                                            key={`${idx}_${section.section.id}`}
                                            bgcolor="white"
                                            width="100%"
                                            mb={0.5}
                                            data-track={`questionnaire_section_${section.section.id}`}>
                                            <QuestionsSection
                                                sectionIndex={idx}
                                                width="100%"
                                                updateForm={updateForm}
                                                section={section.section}
                                                background_color={policyRequest.product?.background_color}
                                                text_color={policyRequest.product?.text_color}
                                                questions={section.questions}
                                                policy_request_id={policyRequest.id}
                                                setScroll={setScroll}
                                                scroll={scroll}
                                                setEditAnswerMode={setEditAnswerMode}
                                                setSubmissionDisabled={setSubmissionDisabled}
                                                setCheckTriedToSubmit={setCheckTriedToSubmit}
                                                reSubmitted={reSubmitted}
                                                setChangeMade={setChangeMade}
                                            />
                                        </GeneralStyled.SectionBox>
                                    ))}
                                    {isSm ? (
                                        <Box
                                            textAlign="center"
                                            py={3}
                                            px={4}
                                            boxShadow="0px -4px 8px rgba(118, 118, 118, 0.25)"
                                            bgcolor="#FFFFFF">
                                            <RoundedButton
                                                data-track={`questionnaire_${
                                                    policyRequest.broker_submitted && !editApp
                                                        ? 'View Details'
                                                        : submitted
                                                            ? 'Please wait...'
                                                            : reSubmitted || editApp
                                                                ? 'Resubmit'
                                                                : 'Submit'
                                                }`}
                                                onClick={handleSubmit}
                                                width="100%"
                                                disabled={
                                                    submitted ||
                                                    (editApp && !changeMade) ||
                                                    isCurrentlySavingAnswer ||
                                                    !submissionWriteAuthorized
                                                }
                                                color="#E6E6E6"
                                                bgcolor="#E6E6E6"
                                                px={7}
                                                py={0.75}
                                                border="none !important">
                                                <Typography variant="h5">
                                                    {policyRequest.broker_submitted && !editApp
                                                        ? 'View Details'
                                                        : submitted || isCurrentlySavingAnswer
                                                            ? 'Please wait...'
                                                            : reSubmitted || editApp
                                                                ? 'Resubmit'
                                                                : 'Submit'}
                                                </Typography>
                                            </RoundedButton>
                                        </Box>
                                    ) : (
                                        <Box textAlign="center" py={4} px={0}>
                                            <RoundedButton
                                                data-track={`questionnaire_${
                                                    policyRequest.broker_submitted && !editApp
                                                        ? 'View Details'
                                                        : submitted
                                                            ? 'Please wait...'
                                                            : reSubmitted || editApp
                                                                ? 'Resubmit'
                                                                : 'Submit'
                                                }`}
                                                onClick={handleSubmit}
                                                minWidth="195px"
                                                disabled={
                                                    submitted ||
                                                    (editApp && !changeMade) ||
                                                    isCurrentlySavingAnswer ||
                                                    !submissionWriteAuthorized
                                                }
                                                color="#E6E6E6"
                                                bgcolor="#E6E6E6"
                                                px={7}
                                                py={1.25}
                                                border="none !important">
                                                <Typography variant="h5">
                                                    {policyRequest.broker_submitted && !editApp
                                                        ? 'View Details'
                                                        : submitted || isCurrentlySavingAnswer
                                                            ? 'Please wait...'
                                                            : reSubmitted || editApp
                                                                ? 'Resubmit'
                                                                : 'Submit'}
                                                </Typography>
                                            </RoundedButton>
                                        </Box>
                                    )}
                                </StyledForm.Form>
                            </Box>
                        </Box>
                    </Hidden>
                </>
            )}
        </Box>
    );
};

export default NewQuestionnaire;
