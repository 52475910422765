import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { DynamicIcon } from 'components';
import { validatePassword } from 'utils';
import useTheme from '@material-ui/core/styles/useTheme';
import Styled from './styled-components';

const ValidationIcon = ({ passwordRequirements, requirement }) => {
    const [isValid, setIsValid] = useState(null);

    useEffect(() => {
        let valid = null;

        switch (requirement) {
            case 'min':
            case 'uppercase':
            case 'lowercase':
                valid = passwordRequirements[requirement];
                break;
            case 'digits_symbols':
                valid = passwordRequirements.digits || passwordRequirements.symbols;
                break;
            default:
                valid = null;
        }

        setIsValid(valid);
    }, [passwordRequirements]);

    return (
        <Box display="inline-block" marginRight="13px">
            {isValid === null ? (
                <Styled.PasswordBullet />
            ) : isValid ? (
                <DynamicIcon name="check" svgIconStyle={{ fill: '#07AF26' }} typographyProps={{ variant: 'body1' }} />
            ) : (
                <DynamicIcon name="caution" svgIconStyle={{ fill: '#D31D1A' }} typographyProps={{ variant: 'body1' }} />
            )}
        </Box>
    );
};

const PasswordValidation = ({ password }) => {
    const theme = useTheme();
    const [passwordRequirements, setPasswordRequirements] = useState({
        min: null,
        max: null,
        uppercase: null,
        lowercase: null,
        digits: null,
        symbols: null,
    });

    useEffect(() => {
        if (!password) {
            setPasswordRequirements({
                min: null,
                max: null,
                uppercase: null,
                lowercase: null,
                digits: null,
                symbols: null,
            });
            return;
        }
        const validationResult = validatePassword(password);
        const requirements = { ...passwordRequirements };
        for (const req in passwordRequirements) {
            requirements[req] = !validationResult.includes(req);
        }

        setPasswordRequirements(requirements);
    }, [password]);

    return (
        <>
            <Styled.FormText errorState={passwordRequirements.min === false} variant="subtitle2">
                <ValidationIcon passwordRequirements={passwordRequirements} requirement="min" />
                At least 8 characters
            </Styled.FormText>
            <Styled.FormText errorState={passwordRequirements.uppercase === false} variant="subtitle2">
                <ValidationIcon passwordRequirements={passwordRequirements} requirement="uppercase" />
                At least one capital letter
            </Styled.FormText>
            <Styled.FormText errorState={passwordRequirements.lowercase === false} variant="subtitle2">
                <ValidationIcon passwordRequirements={passwordRequirements} requirement="lowercase" />
                At least one lower case letter
            </Styled.FormText>
            <Styled.FormText
                errorState={passwordRequirements.digits === false && passwordRequirements.symbols === false}
                variant="subtitle2">
                <ValidationIcon passwordRequirements={passwordRequirements} requirement="digits_symbols" />
                At least one number or one symbol
                <br />
                <Box style={{ marginLeft: theme.spacing(2.5) }}>(!, @, #, %, &, *)</Box>
            </Styled.FormText>
        </>
    );
};

export default PasswordValidation;
