import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import PaginationLayout from 'components/pagination-layout';
import { useCompanies } from 'api/dashboard/queries/useCompanies';
import CompaniesList from './CompaniesList';
import { BucketType, SubBucketType } from './types';
import { FIRST_PAGE, initialState } from './consts';
import { usePagination } from './hooks/usePagination';

interface Props {
    bucket: BucketType;
    subBucket: SubBucketType;
}

export const CompaniesPagination: FC<Props> = ({ bucket, subBucket }) => {
    const { currentPage, nextClientId, handleNextButtonClick, handlePrevButtonClick } = usePagination(
        initialState,
        bucket,
        subBucket,
    );

    const { data: clientsList, error: clientsListError, isFetching: clientsListLoading } = useCompanies({
        bucket,
        subBucket,
        nextClientId,
    });

    return (
        <Box mb={[4, 4, 4, 10]}>
            <PaginationLayout
                onNextButtonClick={
                    !!clientsList?.nextClientId
                        ? () => handleNextButtonClick(clientsList.nextClientId as number)
                        : undefined
                }
                onPrevButtonClick={currentPage !== FIRST_PAGE ? () => handlePrevButtonClick() : undefined}
                currentPage={currentPage}
                isLoading={clientsListLoading}>
                <CompaniesList companies={clientsList?.companies || []} bucket={bucket} subBucket={subBucket} />
            </PaginationLayout>
        </Box>
    );
};
