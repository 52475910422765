import { Mode } from 'utils/enums';


export const formatPolicyNumber = (mode, policyNumber) => {
    if (policyNumber && mode !== Mode.PROD) {
        const p = policyNumber.split('-')
        if (p.length === 2) {
            return `${p[0].slice(0, 7)}-${p[1]}`;
        } else {
            return `${p[0]}-${p[1].slice(0, 7)}-${p[2]}`;
        }
    }
    return policyNumber;
}
