import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useAxiosClient, useBreakpoint, useTextQuestionStyles } from 'hooks';
import Markdown from 'markdown-to-jsx';
import GeneralStyled from '../../../components/styled-components';
import { Box, FormControl, InputAdornment } from '@material-ui/core';
import InfoTooltip from '../../../components/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setQuestionValue } from 'stores/questions/questionsSlice';
import { setNewQuestionData } from 'stores/questionsErrors/questionsErrorsSlice';
import { questionHasError, scrollToQuestionError } from 'utils/questionnaireSequence';
import { setQuestionnaireStateToAnswerChanging, setQuestionnaireStateToChangesSaved } from 'stores/questionnaire/questionnaireSlice';
import Styled from 'modules/questionnaire/pages/new-questionnaire/components/questions/styled-components';

const DomainQuestion = ({
    question,
    updateForm,
    policy_request_id,
    setScroll,
    scroll,
    setEditAnswerMode,
    setChangeMade,
}) => {
    const axiosClient = useAxiosClient();
    const questionRef = useRef();
    const inputFieldRef = useRef();
    const placeholderLabelRef = useRef();
    const isSm = useBreakpoint('down', 'sm');
    const dispatch = useDispatch();
    const answer = useSelector(state => state.questions.questionsToAnswers[question.id]);
    const questionMetaData = useSelector(
        state => state.questionnaire.questionsMetadata.find(item => item.questionId === question.id)?.metadata,
    );
    const { firstQuestionWithError, submitFailed } = useSelector(state => state.questionErrors);
    const [value, setValue] = useState(answer || '');
    const [shrink, setShrink] = useState(Boolean(value));
    const placeholder = useMemo(() => {
        return value || shrink ? '' : question.question;
    }, [value, shrink, question.question]);
    const [error, setError] = useState('');
    const [firstChange, setFirstChange] = useState(true);
    const [open, setOpen] = useState(false);
    const [requiredError, setRequiredError] = useState(questionHasError(submitFailed, answer, question.required, true));
    const [inputStyles, inputComponentStyles] = useTextQuestionStyles(placeholder, placeholderLabelRef, inputFieldRef);

    const [, validateDomains] = axiosClient(
        {
            method: 'post',
            url: `/questionnaire/validate_domains/${policy_request_id}`,
        },
        { manual: true },
    );

    useEffect(() => {
        dispatch(
            setNewQuestionData({
                questionId: question.id,
                hasError: questionHasError(submitFailed, answer, question.required, true) || error,
            }),
        );
    }, [submitFailed, error]);

    useEffect(() => {
        dispatch(
            setNewQuestionData({
                questionId: question.id,
                hasError: questionHasError(submitFailed, answer, question.required, true) || error,
            }),
        );
    }, []);

    useEffect(() => {
        if (scroll && firstQuestionWithError === question.id) {
            scrollToQuestionError(questionRef);
            inputFieldRef.current.focus();
            setScroll(false);
        }
        if (!scroll) {
            setRequiredError(questionHasError(submitFailed, value, question.required, true));
        }
    }, [firstQuestionWithError, scroll]);

    useEffect(() => {
        setValue(answer || '');
    }, [answer]);

    const handleOnBlur = async e => {
        const hasError = submitFailed && !value && question.required;
        setRequiredError(hasError);
        dispatch(setNewQuestionData({ questionId: question.id, hasError: hasError || error }));
        dispatch(setQuestionnaireStateToChangesSaved());
        setFirstChange(true);
        dispatch(setQuestionValue({ name: question.id, value: value }));
        setShrink(Boolean(value));
        try {
            await validateDomains({ data: { domains: value } });
            setError('');
            updateForm({
                questionId: question.id,
                answerId: question.optional_answers[0].id,
                answerType: question.optional_answers[0].type,
                value: value,
                invalid: false,
                required: question.required,
                questionType: question.type,
            });
        } catch (error) {
            const invalidDomains = error.response?.data?.invalid_domains;
            const existingDomains = error.response?.data?.existing_domains;
            let errorText = '';
            if (invalidDomains?.length === 1) {
                errorText += `The domain ${invalidDomains[0]} is invalid`;
            } else if (invalidDomains?.length > 1) {
                errorText += `The domains: ${invalidDomains.join(', ')} are invalid`;
            }
            if (errorText) {
                errorText += '\n';
            }
            if (existingDomains?.length === 1) {
                errorText += `The domain ${existingDomains[0]} already exists in your Accounts`;
            } else if (existingDomains?.length > 1) {
                errorText += `The domains: ${existingDomains.join(', ')} already exists in your Accounts`;
            }
            setError(errorText);
        }
    };

    return (
        <Box
            ref={questionRef}
            display="flex"
            px={isSm ? 4 : 0}
            key={`${question.id}_question`}
            pb={1.625}
            justifyContent="center">
            <FormControl fullWidth style={{ maxWidth: isSm ? 'unset' : '424px' }}>
                <GeneralStyled.TextField
                    inputRef={inputFieldRef}
                    fullWidth
                    onChange={e => {
                        setEditAnswerMode(true);
                        setValue(e.target.value);
                        setRequiredError(false);
                        dispatch(
                            setNewQuestionData({
                                questionId: question.id,
                                hasError: false || error,
                            }),
                        );
                        setChangeMade(true);
                        if (firstChange) {
                            setFirstChange(false);
                            dispatch(setQuestionnaireStateToAnswerChanging());
                        }
                    }}
                    value={value}
                    type="text"
                    name={question.id}
                    label={
                        <Markdown variant="body1">
                            {(question.required ? '' : '(Optional) ') + question.question}
                        </Markdown>
                    }
                    InputLabelProps={{
                        shrink,
                        ref: placeholderLabelRef,
                    }}
                    inputProps={{
                        'data-track': `questionnaire_question_${question.id}`,
                        style: placeholder ? inputStyles.withPlaceholder : inputStyles.withoutPlaceholder,
                    }}
                    InputProps={{
                        style: placeholder
                            ? inputComponentStyles.withPlaceholder
                            : inputComponentStyles.withoutPlaceholder,
                        endAdornment: (
                            <InputAdornment position="end">
                                {question.application_parameters.help_text && (
                                    <InfoTooltip
                                        dataTrack={`questionnaire_tooltip_${question.id}`}
                                        placement="bottom-end"
                                        open={open}
                                        setOpen={setOpen}
                                        text={question.application_parameters.help_text}
                                        select={false}
                                    />
                                )}
                                {questionMetaData?.savingQuestion && !error && <Styled.CircularProgress size={15} />}
                            </InputAdornment>
                        ),
                    }}
                    placeholder={placeholder}
                    onFocus={() => setShrink(true)}
                    onBlur={handleOnBlur}
                    error={error !== '' || requiredError}
                    helperText={error ? error : requiredError ? 'This field is required' : ''}
                />
            </FormControl>
        </Box>
    );
};

export default DomainQuestion;
