import React from 'react';
import { ProductsAbbreviatedName } from 'utils/enums';
import { StyledTypography } from 'components';


const Actions = ({ policyRequest }) => {
    return (
        <StyledTypography.FadedTypography variant="subtitle2" component="span">
            {policyRequest.product?.abbreviated_name === ProductsAbbreviatedName.MPL ?
                'We\'re processing your quotes now and will get back to you shortly.' :
                'We\'re processing your security scan now and will get back to you shortly.'
            }
        </StyledTypography.FadedTypography>
    )
}

export default Actions;
