import React from 'react';
import { useBreakpoint } from 'hooks';
import { Box, Snackbar, SnackbarContent, Typography } from '@material-ui/core';
import { theme } from '../theme/styles';
import { Icon } from '../index';
import ErrorIcon from '@material-ui/icons/Error';


export default function NotificationBanner({
    openNotification,
    alertText,
    setOpenNotification,
    onMarkedTextClick,
    markedText='',
    withIcon=true,
    isError=true,
    withContainer=false,
}) {
    const isSm = useBreakpoint('down', 'sm');
    const isMd = useBreakpoint('down', 'md');
    const isLg = useBreakpoint('up', 'lg');
    const isContainer = useBreakpoint('down', 'container');

    return (
        <Snackbar
            style={{ minWidth: '100%', top: '80px', bottom: 'auto', maxWidth: '1280px' }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={openNotification}
            onClose={() => setOpenNotification(false)}
            autoHideDuration={10000}
            id="innerAppBar">
            <SnackbarContent
                style={{
                    minWidth: '100%',
                    backgroundColor: isError ? '#FFDEDE' : '#D9EBFF',
                    justifyContent: (withContainer && isContainer) || isSm ? 'start' : 'center',
                }}
                message={
                    <Box
                        fontWeight="fontWeightRegular"
                        color={isError ? '#F02A26' : theme.palette.primary.main}
                        display="flex"
                        alignItems="center"
                        pl={isSm ? 4 : withContainer && isContainer ? 7 : 0}
                        minWidth={(isMd && !isContainer) || isLg ? theme.breakpoints.values.container : isSm ? 'unset' : '616px'}>
                        {!isSm && withIcon && (
                            <Box display="flex" pr={1}>
                                <Icon icon={<ErrorIcon />} typographyProps={{ variant: 'h6' }} />
                            </Box>
                        )}
                        <Typography
                            variant="body1"
                            onClick={onMarkedTextClick}
                            style={{ cursor: onMarkedTextClick && 'pointer' }}>
                            {alertText} <span style={{fontWeight: '500'}}>{markedText}</span>
                        </Typography>
                    </Box>
                }
            />
        </Snackbar>
    );
}
