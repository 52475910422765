import React, { useState, useRef } from 'react';
import { useAxiosClient, useDebounceCallback, useEnv } from 'hooks';
import InputAdornment from '@material-ui/core/InputAdornment';
import DropdownItem from '../dropdown/DropdownItem';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { Loading, StyledAction } from 'components';
import Styled from './styled-components';
import * as Sentry from '@sentry/react';


const SearchBar = ({ hidden }) => {
    const { teamView } = useEnv();
    const [companies, setCompanies] = useState(null);
    const inputRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const axiosClient = useAxiosClient();
    const [{ loading }, searchCompanies] = axiosClient(
        {
            method: 'get',
            url: '/company/search',
        },
        { manual: true }
    );

    const handleOnChange = event => {
        debouncedFetchCompanies(event.target.value);
        setAnchorEl(event.currentTarget);
    };

    const fetchCompanies = async debouncedValue => {
        if (debouncedValue.length >= 3) {
            try {
                const res = await searchCompanies({
                    params: {
                        company_name: debouncedValue,
                    },
                });
                setCompanies(res.data.companies);
            } catch (error) {
                Sentry.captureException(error);
            }
        }
    };

    const debouncedFetchCompanies = useDebounceCallback(fetchCompanies, 500, []);

    const handleOnClose = () => {
        setAnchorEl(null);
    };

    const handleClearInput = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    const renderClearIcon = () => {
        return (
            inputRef.current?.value && (
                <InputAdornment position="end">
                    <StyledAction.Base>
                        <Styled.ClearIconContainer>
                            <ClearIcon onClick={handleClearInput} />
                        </Styled.ClearIconContainer>
                    </StyledAction.Base>
                </InputAdornment>
            )
        );
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <Styled.SearchBarContainer hidden={hidden}>
            <Styled.SearchBarForm>
                <Styled.TextField
                    onChange={e => handleOnChange(e)}
                    variant="outlined"
                    placeholder="Search clients..."
                    onKeyDown={handleKeyPress}
                    inputRef={inputRef}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Styled.SearchIconContainer>
                                    <SearchIcon fontSize={'inherit'} />
                                </Styled.SearchIconContainer>
                            </InputAdornment>
                        ),
                        endAdornment: renderClearIcon(),
                    }}></Styled.TextField>
            </Styled.SearchBarForm>
            {companies && (
                <Styled.Popover
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={open}
                    onClose={handleOnClose}
                    disableAutoFocus
                    disableEnforceFocus>
                    {loading && <Loading />}
                    {companies.length ? (
                        companies.map(company => (
                            <DropdownItem
                                href={`/company/${company.id}`}
                                text={company.name}
                                key={company.id}
                                handleOnClose={handleOnClose}
                                handleClearInput={handleClearInput}
                                companyData={teamView ? {
                                    products: company.policies_product,
                                    brokers_full_name: company.policies_brokers_name,
                                } : null}
                            />
                        ))
                    ) : (
                        <Styled.NoResultsBox>No results</Styled.NoResultsBox>
                    )}
                </Styled.Popover>
            )}
        </Styled.SearchBarContainer>
    );
};

export default SearchBar;
