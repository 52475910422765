import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { StyledTypography, StyledLink } from 'components';
import { Box, Typography } from '@material-ui/core';
import { ProductsAbbreviatedName } from 'utils/enums';

const Actions = ({ policyRequest }) => {
    const { last_update } = useSelector(state => state.company.company);
    const quoteMessage =
        policyRequest.product?.abbreviated_name === ProductsAbbreviatedName.MPL ?
            'We\'re processing your quotes now and will get back to you shortly.' :
            'We\'re processing your security scan now and will get back to you shortly.'
    return (
        <>
            {policyRequest.broker_submitted ? (
                <>
                    {policyRequest.inProgress ? (
                        <StyledTypography.FadedTypography variant="subtitle2" component="span">
                            {quoteMessage}
                        </StyledTypography.FadedTypography>

                    ) : (
                        <>
                            <StyledTypography.FadedTypography variant="subtitle2" component="span">
                                We found something that needs a closer look. We'll get back to you shortly,
                                but if you have any questions, please contact us at
                            </StyledTypography.FadedTypography>
                            <Box component="span" fontWeight="fontWeightMedium">
                                <Typography variant="subtitle2" component="span" color="primary">
                                    <StyledLink.Anchor href={`mailto:${policyRequest.uw_email}`}>
                                        {' '}{policyRequest.uw_email}
                                    </StyledLink.Anchor>
                                </Typography>
                            </Box>
                        </>
                    )}
                </>
            ) : (
                <StyledTypography.FadedTypography variant="subtitle2">
                    Last question was answered {dayjs.utc(last_update).fromNow()}
                </StyledTypography.FadedTypography>
            )}
        </>
    )
}


export default Actions;
