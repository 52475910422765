import React from 'react';
import { StyledTypography } from 'components';


const Actions = () => {
    return (
        <StyledTypography.FadedTypography variant="subtitle2">
            Click on quotes for coverage details, documents, and bind the policy.
        </StyledTypography.FadedTypography>
    )
}


export default Actions;
