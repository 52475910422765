import React from 'react';
import { StyledTypography, StyledTable } from 'components';
import { Box, TableRow } from '@material-ui/core';
import { Hidden } from '@material-ui/core';

const NextSteps = ({ children, text='NEXT STEPS' }) => {

    return (
        <>
            <Hidden smDown>
                <TableRow>
                    <StyledTable.TableCell colSpan={6}>
                        <Box mt={7}>
                            <StyledTypography.FadedTypography variant="overline">
                                {text}
                            </StyledTypography.FadedTypography>
                        </Box>
                    </StyledTable.TableCell>
                </TableRow>
                <TableRow>
                    <StyledTable.TableCell colSpan={6}>
                        <Box borderBottom="2px solid #E0E0E0" my={1} />
                    </StyledTable.TableCell>
                </TableRow>
            </Hidden>
            <Hidden mdUp>
                <TableRow>
                    <StyledTable.TableCell colSpan={1}>
                        <Box mt={2}>
                            <StyledTypography.FadedTypography variant="overline">
                                {text}
                            </StyledTypography.FadedTypography>
                        </Box>
                    </StyledTable.TableCell>
                </TableRow>
                <TableRow>
                    <StyledTable.TableCell colSpan={6}>
                        <Box borderBottom="2px solid #E0E0E0" my={1} />
                    </StyledTable.TableCell>
                </TableRow>
            </Hidden>
            {children}
        </>
    )
}

export default NextSteps;
