import NumberFormat from 'react-number-format';
import React, { FC } from 'react';

interface Props {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    inputRef: React.Ref<HTMLInputElement>;
}

const NumberFormatInput: FC<Props> = ({ inputRef, name, onChange, ...other }) => {
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: name,
                        value: values.value,
                    },
                });
            }}
            allowNegative={false}
            thousandSeparator
        />
    );
};

export default NumberFormatInput;
