import { Mode } from 'utils/enums';
import { env } from '../env';
import * as _ from 'lodash';

const Intercom = {
    init: () => {
        if (env.REACT_APP_MODE === Mode.DEV) {
            window.Intercom = () => {};
            return;
        }

        // this function was taken from https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
        } else {
            var d = document;
            var i = function() {
                i.c(arguments);
            };
            i.q = [];
            i.c = function(args) {
                i.q.push(args);
            };
            w.Intercom = i;
            var l = function() {
                var s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = `https://widget.intercom.io/widget/${env.REACT_APP_INTERCOM_APP_ID}`;
                var x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            };
            if (w.attachEvent) {
                w.attachEvent('onload', l);
            } else {
                w.addEventListener('load', l, false);
            }
        }
    },
    setUserContext: (user, userPermissions) => {
        window.Intercom('boot', {
            app_id: env.REACT_APP_INTERCOM_APP_ID,
            name: user.username,
            email: user.email,
            user_hash: user.user_hash,
            role_type: user.role.name,
            bp_permissions: userPermissions?.join(', '),
            role_description: user.role_description,
            company: user.company && {
                id: user.company.id,
                name: user.company.name,
            },
            team_id: !_.isEmpty(user.team) ? user.team.id : null,
        });
    },
    openChat: () => {
        window.Intercom('show');
    },
    closeChat: () => {
        window.Intercom('hide');
    },
    endChat: () => {
        window.Intercom('shutdown');
    },
    showMessages: () => {
        window.Intercom('showMessages');
    },
    getVisitorId: () => {
        return window.Intercom('getVisitorId');
    },
    sendEvent: (event, data) => {
        window.Intercom('trackEvent', event, data);
    },
};

export default Intercom;
