import * as _Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import SentryFullStory from '@sentry/fullstory';
import { env } from '../env'

const Sentry = {
    init: () => {
        const { REACT_APP_SENTRY_DSN, REACT_APP_MODE, REACT_APP_SENTRY_SLUG } = env;

        if (REACT_APP_SENTRY_DSN && REACT_APP_MODE) {
            _Sentry.init({
                dsn: REACT_APP_SENTRY_DSN,
                integrations: [
                    new Integrations.BrowserTracing(),
                    new SentryFullStory(REACT_APP_SENTRY_SLUG),
                ],
                ignoreErrors: [
                    // this is caused by Microsoft crawlers and fixes our quota issues in Sentry (temporary, I hope)
                    'Non-Error promise rejection captured with value',
                ],
                tracesSampleRate: 0,
                environment: REACT_APP_MODE
            });
        }
    }
}

export default Sentry;
