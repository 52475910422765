import styled from 'styled-components';
import { Typography as _Typography, Box as _Box, Divider as _Divider } from '@material-ui/core';
import { default as _Link } from '@material-ui/core/Link/Link';

const Box = styled(_Box)`
    background-color: #ffffff;
    padding: 16px 32px;
`;

const FadedTypography = styled(_Typography)`
    &&& {
        font-size: 14px;
        opacity: 0.54;

        ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightMedium};
            color: ${theme.palette.text.primary};
        `}
    }
`;

const Divider = styled(_Divider)`
    &&& {
        height: 1px;
        background: #cccccc;
        margin: 16px 0;
    }
`;

const Typography = styled(_Typography)`
    &&& {
        font-size: 14px;

        ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightMedium};
            color: ${theme.palette.text.primary};
        `}
    }
`;

const TypographyBold = styled(_Typography)`
    &&& {
        font-size: 14px;

        ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightBold};
            color: ${theme.palette.text.primary};
        `}
    }
`;

const Row = styled(_Box)`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const Column = styled(_Box)`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const BlueLink = styled(_Link)`
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: underline;
`;

export default {
    Box,
    BlueLink,
    FadedTypography,
    Typography,
    TypographyBold,
    Row,
    Divider,
    Column,
};
