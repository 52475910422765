import React, { useContext } from 'react';
import { StyledTypography } from 'components';
import { Box } from '@material-ui/core';
import { formatDatePickerDate } from 'utils';
import { RenewalType } from 'utils/enums';
import { UponRenewalPolicyContext } from '../../../../UponRenewalPolicyContext';


const Actions = ({ policyRequest }) => {
    const effectiveDate = formatDatePickerDate(policyRequest.effective_date);
    const expirationDate = formatDatePickerDate(policyRequest.expiration_date);
    const { currentPolicyExpirationDate } = useContext(UponRenewalPolicyContext);

    return (
        <>
            {RenewalType.isAuto(policyRequest?.renewal_type.name) ?
                (
                    <StyledTypography.FadedTypography variant="subtitle2">
                        This policy qualifies for rapid renewal and will be effective
                        on {effectiveDate} to {expirationDate}.
                        Please review the rapid renewal eligibility questions and terms below.
                        <Box fontWeight="500" mt={[0.5,0.5,1]}>
                            If unchanged, the policy will be automatically issued approximately 15 days from the current policy’s expiration.
                        </Box>
                    </StyledTypography.FadedTypography>
                ) : (
                    <StyledTypography.FadedTypography variant="subtitle2">
                        Click on quotes for coverage details, documents, and bind the policy.
                        <Box fontWeight="fontWeightMedium">
                        The current policy will expire on {formatDatePickerDate(currentPolicyExpirationDate)}
                        </Box>
                    </StyledTypography.FadedTypography>
                )
            }
        </>
    )
}


export default Actions;
