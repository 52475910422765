import styled from 'styled-components';
import {
    Icon as _Icon,
    IconButton as _IconButton,
} from '@material-ui/core';

const BaseIcon = styled(_Icon)`
    &&& {
        font-family: i;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const SearchIcon = styled(_IconButton)`
    &&& {
        font-size: 1.5rem;
        cursor: pointer;

        &:after {
            cursor: pointer;
            padding: 0 6px;
            font-family: i;
            content: '\\ea04';
        }
    }
`;

const ClearIcon = styled(_IconButton)`
    &&& {
        font-size: 1.5rem;
        cursor: pointer;
        width: 60px;
        height: 60px;

        &:before {
            z-index: 2;
            color: #fff;
            font-family: i;
            content: '\\ea06';
            position: absolute;
            padding: 0 6px;
        }
        &:after {
            font-family: i;
            content: "\\e9d1";
            text-shadow: 0 1px 4px rgba(128,128,128,.5);
            position: absolute;
            padding: 0 6px;
        }
    }
`;

const CloseCircleIcon = styled(_IconButton)`
    &&& {
        font-size: 1.1rem;
        cursor: pointer;
        width: 64px;
        height: 80px;
        color: #fff;
        line-height: 80px;

        &:after {
            cursor: pointer;
            padding: 0 6px;
            font-family: i;
            content: '\\e992';
        }
    }
`;

const CloseIcon = styled(_IconButton)`
    &&& {
        font-family: i;
        &::before {
            font-size: 12px;
            content: '\\e926';
        }
        &.small {
            font-size: 8px;
            padding: 0px;
        }
    }
`;

const WarningIcon = styled(BaseIcon)`
    &&& {
        color: #cc6f23;
        &::before {
            content: '\\e91e';
        }
    }
`;

const FenceIcon = styled(BaseIcon)`
    &&& {
        width: unset;
        height: unset;
        &::before {
            font-size: 13em;
            content: "\\e911";
            ${({ theme }) => `
                ${theme.breakpoints.down('md')} {
                    font-size: 9em;
                }
            `}
        }
    }
`;

const ExpandAllIcon = styled(BaseIcon)`
    &&& {
        &::before {
            font-size: 0.6em;
            font-weight: bold;
            content: '\\e9e4';
        }
    }
`;

const CollapseAllIcon = styled(BaseIcon)`
    &&& {
        &::before {
            font-size: 0.6em;
            font-weight: bold;
            content: '\\e9e6';
        }
    }
`;

export default {
    ClearIcon,
    CloseIcon,
    CloseCircleIcon,
    CollapseAllIcon,
    ExpandAllIcon,
    SearchIcon,
    WarningIcon,
    FenceIcon,
};
