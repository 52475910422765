import React, { useMemo } from 'react';
import Styled from './styled-components';
import { Icon, StyledLink, StyledAction } from 'components';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { ReactComponent as ClipboardIcon } from 'components/icons/clipboard.svg';
import { ReactComponent as SecurityShieldIcon } from 'components/icons/shield-security.svg';
import { ReactComponent as BuildingIcon } from 'components/icons/building.svg';
import { ReactComponent as PaperBillIcon } from 'components/icons/paper-bill.svg';
import { ReactComponent as ManIcon } from 'components/icons/man-avatar.svg';
import { Typography, Hidden, Box } from '@material-ui/core';
import { ProductsAbbreviatedName, SubmissionsBucket } from 'utils/enums';
import { totalHumanize } from 'utils';
import _ from 'lodash';

const truncate = (text, maxLength) => {
    if (text && text.length > maxLength) {
        return `${text.substring(0, maxLength)}...`;
    } else {
        return text;
    }
};

const numBoundPolicies = policies => {
    return policies.filter(policy => policy.athena_submissions_bucket === SubmissionsBucket.BOUND).length;
};

const CompanyInfo = ({ client, renewals }) => {
    const displayIndustryInfo = useMemo(() => {
        return (
            client.data.filter(policy => policy.product?.abbreviated_name !== ProductsAbbreviatedName.MPL).length >= 1
        );
    }, [client.data]);

    const companyPath = renewals
        ? `/company/${_.head(client.data).company_id}/?tab=renewals`
        : `/company/${_.head(client.data).company_id}`;

    return (
        <StyledLink.Default to={companyPath}>
            <StyledAction.Base>
                <Styled.InformationBox borderTop={'none'}>
                    <Box display="flex" mr={1.5} p={0.125}>
                        <Box mr={0.5}>
                            <Icon icon={<ClipboardIcon />} typographyProps={{ variant: 'body1' }} />
                        </Box>
                        <Box component={Typography} variant="body2">
                            {client.num_of_quotes} {client.num_of_quotes === 1 ? 'Quote' : 'Quotes'}
                        </Box>
                    </Box>
                    <Box display="flex" mr={1.5} p={0.125}>
                        <Box mr={0.5}>
                            <Icon icon={<SecurityShieldIcon />} typographyProps={{ variant: 'body1' }} />
                        </Box>
                        <Box component={Typography} variant="body2">
                            {numBoundPolicies(client.data)}{' '}
                            {numBoundPolicies(client.data) === 1 ? 'Policy' : 'Policies'}
                        </Box>
                    </Box>
                    <Hidden smDown>
                        {displayIndustryInfo && (
                            <Box display="flex" mr={1.5} p={0.125}>
                                <Box mr={0.5}>
                                    <Icon icon={<BuildingIcon />} typographyProps={{ variant: 'body1' }} />
                                </Box>
                                <Box component={Typography} variant="body2">
                                    {truncate(client.company?.industry?.naics_title, 30)}
                                </Box>
                            </Box>
                        )}
                        {!!client.company?.latest_submitted_policy_request?.revenue && (
                            <Box display="flex" mr={1.5} p={0.125}>
                                <Box mr={0.5}>
                                    <Icon icon={<PaperBillIcon />} typographyProps={{ variant: 'body1' }} />
                                </Box>
                                <Box component={Typography} variant="body2">{`$${totalHumanize(
                                    client.company.latest_submitted_policy_request.revenue,
                                )} Revenue`}</Box>
                            </Box>
                        )}
                        {client.company?.indirect_broker_name && (
                            <Box display="flex" mr={1.5} p={0.125}>
                                <Box mr={0.5}>
                                    <Icon icon={<ManIcon />} typographyProps={{ variant: 'body1' }} />
                                </Box>
                                <Box component={Typography} variant="body2">
                                    Retail Broker
                                </Box>
                            </Box>
                        )}
                    </Hidden>
                    <Box display="flex" ml="auto" p={0.125}>
                        <Typography variant="body2">View All Details</Typography>
                        <Box ml={0.5}>
                            <Icon icon={<KeyboardArrowRightIcon />} typographyProps={{ variant: 'body1' }} />
                        </Box>
                    </Box>
                </Styled.InformationBox>
            </StyledAction.Base>
        </StyledLink.Default>
    );
};

export default CompanyInfo;
