import React, { FC, Dispatch, SetStateAction } from 'react';
import PolicyTableHeader from './PolicyTabHeader';
import PolicyTableRow from './PolicyTableRow';
import CommissionTable from './CommissionTable';
import { Quote } from 'types/optionToQuote';
import { PolicyDataDialog, RoundedButton } from 'components';
import { Box, Typography } from '@material-ui/core';
import { PolicyRequest } from 'types/PolicyRequest';

interface Props {
    isToggled: boolean;
    setIsToggled: Dispatch<SetStateAction<boolean>>;
    quote: Quote;
    policyRequest: PolicyRequest;
    commissionPercentageString: string;
}

const FullTableMobile: FC<Props> = ({ isToggled, setIsToggled, quote, policyRequest, commissionPercentageString }) => {
    return (
        <PolicyDataDialog
            open={isToggled}
            headerExtraText="Commission"
            policyRequest={policyRequest}
            quote={quote}
            centerContent={false} // add a default value
            showDisclaimer={false} // add a default value
            label="" // add a default value
            footer={
                <RoundedButton fullWidth onClick={() => setIsToggled(false)}>
                    <Typography variant="subtitle2">Done</Typography>
                </RoundedButton>
            }>
            <Box mt={2} />
            <PolicyTableHeader totalPremium={quote.total_premium} />
            {quote.premium !== quote.total_premium && (
                <>
                    <Box mt={2} />
                    <Box borderBottom="3px solid #F2F2F2" />
                    <Box mt={2} />
                    <PolicyTableRow title={'Premium'} value={quote.premium} />{' '}
                    {quote.embedded_security_fee && (
                        <PolicyTableRow title={'Embedded Security'} value={quote.embedded_security_fee} />
                    )}
                </>
            )}
            <Box borderBottom="2px solid #F2F2F2" mx={-4} my={2} />
            <CommissionTable commissionPercentageString={commissionPercentageString} />{' '}
            <Box borderBottom="1px solid #F2F2F2" mx={-4} my={2} />
        </PolicyDataDialog>
    );
};

export default FullTableMobile;
