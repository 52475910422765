import React, { useEffect, useState, useRef, useMemo } from 'react';
import { InputAdornment, FormControl, Box } from '@material-ui/core';
import GeneralStyled from '../components/styled-components';
import Markdown from 'markdown-to-jsx';
import InfoTooltip from '../components/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setQuestionValue } from 'stores/questions/questionsSlice';
import { conditionedNodeNeedToBeVisible } from 'utils/questionsConditions';
import { questionHasError, scrollToQuestionError } from 'utils/questionnaireSequence';
import { setQuestionData } from 'stores/questionsErrors/questionsErrorsSlice';
import { useBreakpoint, useTextQuestionStyles } from 'hooks';
import {
    addAnswer,
    addVisibleQuestion,
    removeAnswer,
    removeVisibleQuestion,
    setQuestionnaireStateToAnswerChanging,
    setQuestionnaireStateToChangesSaved,
} from 'stores/questionnaire/questionnaireSlice';
import NumberFormat from 'react-number-format';
import { ValueType } from 'utils/enums';

function NumberFormatCustom(props) {
    const { type, inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            allowNegative={false}
            decimalScale={props.type === ValueType.INTEGER ? 0 : undefined}
            thousandSeparator
        />
    );
}

const NumberQuestion = ({ question, updateForm, setScroll, scroll, reSubmitted, setChangeMade }) => {
    const questionRef = useRef();
    const inputFieldRef = useRef();
    const placeholderLabelRef = useRef();
    const dispatch = useDispatch();
    const { questionsToAnswers } = useSelector(state => state.questions);
    const { sectionsData } = useSelector(state => state.questionnaire);
    const answer = useSelector(state => state.questions.questionsToAnswers[question.datapoint.datapoint_id]);
    const { firstQuestionWithError, submitFailed } = useSelector(state => state.questionErrors);
    const isSm = useBreakpoint('down', 'sm');
    const [visible, setVisible] = useState(
        !question.datapoint.display_condition ||
            conditionedNodeNeedToBeVisible(question.datapoint.display_condition, sectionsData, questionsToAnswers)
    );
    const [firstChange, setFirstChange] = useState(true);
    const [values, setValues] = useState(answer || '');
    const [focus, setFocus] = useState(Boolean(values));
    const placeholder = useMemo(() => {
        return values || focus ? '' : question.datapoint.text_to_display;
    }, [values, focus, question.datapoint.text_to_display]);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(questionHasError(submitFailed, answer, question.datapoint.required, visible));
    const [inputStyles, inputComponentStyles] = useTextQuestionStyles(placeholder, placeholderLabelRef, inputFieldRef);

    useEffect(() => {
        if (question.datapoint.display_condition) {
            setVisible(
                conditionedNodeNeedToBeVisible(question.datapoint.display_condition, sectionsData, questionsToAnswers),
            );
        }
    }, [questionsToAnswers]);

    useEffect(() => {
        dispatch(
            setQuestionData({
                datapointID: question.datapoint.datapoint_id,
                hasError: questionHasError(submitFailed, answer, question.datapoint.required, visible),
            }),
        );
    }, [submitFailed]);

    useEffect(() => {
        if (scroll && firstQuestionWithError === question.datapoint.datapoint_id) {
            scrollToQuestionError(questionRef);
            inputFieldRef.current.focus();
            setScroll(false);
        }
        if (!scroll) {
            setError(questionHasError(submitFailed, values, question.datapoint.required, visible));
        }
    }, [firstQuestionWithError, scroll]);

    useEffect(() => {
        dispatch(
            setQuestionData({
                datapointID: question.datapoint.datapoint_id,
                hasError: questionHasError(submitFailed, values, question.datapoint.required, visible),
            }),
        );
        if (visible) {
            if (question.datapoint.required || values) {
                dispatch(addVisibleQuestion(question.datapoint.datapoint_id));
            }
            if (values) {
                dispatch(addAnswer(question.datapoint.datapoint_id));
            }
        } else {
            dispatch(removeVisibleQuestion(question.datapoint.datapoint_id));
            dispatch(removeAnswer(question.datapoint.datapoint_id));
        }
    }, [visible]);

    const handleBlur = e => {
        setError(questionHasError(submitFailed, values, question.datapoint.required, visible));
        dispatch(
            setQuestionData({
                datapointID: question.datapoint.datapoint_id,
                hasError: questionHasError(submitFailed, values, question.datapoint.required, visible),
            }),
        );
        dispatch(setQuestionnaireStateToChangesSaved());
        setFirstChange(true);
        setFocus(Boolean(values));
        dispatch(setQuestionValue({ name: question.datapoint.datapoint_id, value: values }));
        updateForm(question.datapoint.datapoint_id, values, false, question.datapoint.required);
    };

    return (
        visible && (
            <Box
                ref={questionRef}
                display="flex"
                px={isSm ? 4 : 0}
                key={`${question.datapoint.id}_question`}
                pb={1.625}
                justifyContent="center">
                <FormControl fullWidth style={{ maxWidth: isSm ? 'unset' : '424px' }}>
                    <GeneralStyled.TextField
                        inputRef={inputFieldRef}
                        autoFocus={Boolean(question.datapoint.datapoint_id === 'Q2136' && reSubmitted)}
                        fullWidth
                        name={question.datapoint.datapoint_id}
                        label={<Markdown variant="body1">{question.datapoint.text_to_display}</Markdown>}
                        placeholder={placeholder}
                        onFocus={() => setFocus(true)}
                        multiline={true}
                        className="number"
                        onChange={e => {
                            setValues(e.target.value);
                            setError(false);
                            dispatch(
                                setQuestionData({ datapointID: question.datapoint.datapoint_id, hasError: false }),
                            );
                            setChangeMade(true);
                            if (firstChange) {
                                setFirstChange(false);
                                dispatch(setQuestionnaireStateToAnswerChanging());
                            }
                        }}
                        onBlur={handleBlur}
                        value={values}
                        type={question.type}
                        error={error}
                        helperText={
                            error
                                ? 'This field is required'
                                : question.datapoint.datapoint_id === 'Q2136' && reSubmitted
                                    ? 'Please ensure the Applicant’s revenue is up to date and reflects the latest financial year'
                                    : null
                        }
                        InputLabelProps={{
                            shrink: focus,
                            ref: placeholderLabelRef,
                        }}
                        inputProps={{
                            'data-track': `questionnaire_question_${question.datapoint.datapoint_id}`,
                            style: placeholder ? inputStyles.withPlaceholder : inputStyles.withoutPlaceholder,
                        }}
                        InputProps={{
                            style: placeholder ? inputComponentStyles.withPlaceholder : inputComponentStyles.withoutPlaceholder,
                            inputComponent: NumberFormatCustom,
                            startAdornment: focus && (
                                <InputAdornment
                                    position={
                                        (question.datapoint.application_parameters.unit_position === 'left' &&
                                        'start') ||
                                        (question.datapoint.application_parameters.unit_position === 'right' && 'end')
                                    }
                                    style={{ marginTop: 0.5 }}>
                                    {question.datapoint.application_parameters.unit}
                                </InputAdornment>
                            ),
                            endAdornment: question.datapoint.application_parameters.help_text && (
                                <InputAdornment position="end" style={{ marginTop: 8}}>
                                    <InfoTooltip
                                        dataTrack={`questionnaire_tooltip_${question.datapoint.datapoint_id}`}
                                        placement="bottom-end"
                                        open={open}
                                        setOpen={setOpen}
                                        text={question.datapoint.application_parameters.help_text}
                                        select={false}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </Box>
        )
    );
};

export default NumberQuestion;
