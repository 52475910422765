import styled from 'styled-components';
import {
    AppBar as _AppBar,
    Drawer as _Drawer,
    List as _List,
    ListItem as _ListItem,
    ListItemIcon as _ListItemIcon,
    ListItemText as _ListItemText,
    Toolbar as _Toolbar,
    Popover as _Popover,
    MenuItem as _MenuItem,
    IconButton as _IconButton,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const AppBar = styled(_AppBar)`
    &&& {
        box-shadow: none;
        border-bottom: 1px solid #e6e6e6;
    }
`;

const MobileMenuItem = styled(_MenuItem)`
    &&& {
        padding: 7px 0 7px;
        font-size: 18px;
        font-family: America;
        font-weight: 300;
        color: #191919;

        ${({ theme }) => `
            ${theme.breakpoints.up('md')} {
                font-size: 28px;
            }
        `}
    }
`;

const MobileMenuItemBar = styled.div`
    display: ${props => (props.isActive ? 'block' : 'none')};
    width: 24px;
    height: 3px;
    position: relative;
    right: 34px;
    top: 20px;
    background: #191919;

    ${({ theme }) => `
        ${theme.breakpoints.up('md')} {
            top: 24px;
        }
    `}
`;

const MobileMenuHeader = styled.div`
    padding: 7px 0;
    font-size: 18px;
    font-family: America;
    color: #919191;

    ${({ theme }) => `
        ${theme.breakpoints.up('md')} {
            font-size: 28px;
        }
    `}

    &&& {
        > .active {
            color: red;
            border-bottom: solid #191919 3px;
        }
    }
`;

const Spacer = styled.div`
    height: 18px;
`;

const MobileNavClearIcon = styled(ClearIcon)`
    position: absolute;
    right: 24px;
    top: 28px;
    font-size: 24px;
`;

const List = styled(_List)`
    &&& {
        padding: 83px 0 0 68px;

        ${({ theme }) => `
            ${theme.breakpoints.up('md')} {
                font-size: 28px;
                padding-left: 100px;
            }
        `}
    }
`;

const Drawer = styled(_Drawer)`
    &&& {
        width: 100%;
        background-color: #ffffff;
        left: auto;
        right: 0;
    }
    && > div {
        width: 100%;
        background-color: #ffffff;
    }
`;

const ListItem = styled(_ListItem)`
    &&& {
        color: rgba(255, 255, 255, 0.66);

        &:hover > div,
        &:hover > span {
            color: #fff;
        }
    }
`;

const ListItemIcon = styled(_ListItemIcon)`
    &&& {
        color: inherit;
        &.active {
            color: #fff;
        }
    }
`;

const ListItemText = styled(_ListItemText)`
    &&& {
        font-size: 12px;
        color: inherit;
        &.active {
            color: #fff;
        }
    }
`;

const BottomList = styled(_List)`
    &&& {
        position: absolute;
        bottom: 0;
        color: #fff;
    }
`;

const Toolbar = styled(_Toolbar)`
    &&& {
        height: 80px;
        background-color: #fff;
    }
`;

const UserBox = styled.div`
    float: ${props => (props.isClearIcon ? 'initial' : 'right')};
    display: ${props => (props.hidden ? 'none' : props.isClearIcon ? 'inline-block' : 'block')};
    width: ${props => (props.isClearIcon ? '65px' : '38px')};
    vertical-align: ${props => (props.isClearIcon ? 'top' : 'initial')};
    text-align: ${props => (props.isClearIcon ? 'center' : 'initial')};
    margin-top: 5px;

    svg {
        width: 1.5em;
        height: 1.5em;
    }
`;

const DesktopMenuButton = styled(_IconButton)`
    &&& {
        margin: -6px -2px 0px -9px;
    }
`;

const AccountIconButton = styled.div`
    position: absolute;
    top: 21px;
    height: 28px;
    width: 28px;
    padding: 6px;
    line-height: 28px;
    text-align: center;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.primary.main};
    color: #ffffff;

    :hover {
        cursor: pointer;
    }
`;

const AccountIconButtonBorder = styled.div`
    display: ${props => (props.hidden ? 'none' : 'block')};
    position: absolute;
    top: 17px;
    right: 33px;
    height: 36px;
    width: 36px;
    padding: 6px;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.primary.main};
    opacity: 0.15;
`;

const DesktopNavList = styled.ul`
    width: 100%;
    list-style: none;
    padding: 11px 24px 0 0;
    margin: ${({ guest }) => (guest ? '10px 0 0 0' : '10px 0 0 140px')};
    float: right;

    > li {
        position: relative;
        float: left;
        color: #919191;
        font-size: 1.6em;
        font-weight: 400;
        height: 55px;
        cursor: pointer;
        margin: ${({ guest }) => (guest ? '0' : '0 35px 0 0')};
    }

    > li:hover,
    > li.active {
        color: #191919;
    }

    > li:hover::before,
    > li.active::before {
        content: '';
        position: absolute;
        height: 3px;
        width: 100%;
        bottom: -2px;
        background: #191919;
    }

    > li > a {
        font-size: 16px;
        color: #919191;
        text-decoration: none;
        padding-bottom: 33px;
    }

    > li > a:hover,
    > li.active > a {
        color: #191919;
    }
`;

const AccountPopover = styled(_Popover)`
    &&& {
        .MuiPopover-paper {
            width: 312px;
            position: absolute;
            left: auto !important;
            right: 35px;
            padding: 7px 0;
            border-radius: 1px;
        }
    }
`;

const AdminNavPopover = styled(_Popover)`
    &&& {
        .MuiPopover-paper {
            width: 312px;
            position: absolute;
            top: 65px !important;
            padding: 7px 0;
            border-radius: 1px;
        }
    }
`;

const Logo = styled.div`
    display: ${({ hidden }) => (hidden ? 'none' : 'block')};
    position: absolute;
    top: 24px;
    left: 32px;
    &&& {
        & > li {
            list-style: none;
        }

        & > li > a {
            text-decoration: none;
        }

        & > li > a > i {
            color: #fff;
            font-size: 32px;
        }
    }
`;

const TeamName = styled.div`
    display: ${({ hidden }) => (hidden ? 'none' : 'block')};
    position: absolute;
    color: black;
    top: 34px;
    left: 150px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    width: 30%;
    ${({ theme }) => `
        ${theme.breakpoints.down('md')} {
            left: 130px;
            width: 70%;
        }

        ${theme.breakpoints.down('sm')} {
            left: 130px;
            width: 60%;
        }
    `}
    &&& {
        .MuiTypography-subtitle2 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 90%;
            @media (max-width: 1250px) {
                max-width: 45%;
            }
        }

        & > li {
            list-style: none;
        }

        & > li > a {
            text-decoration: none;
        }

        & > li > a > i {
            color: #fff;
            font-size: 32px;
        }
    }
`;

const MobileMenuIcon = styled(MenuIcon)`
    color: #191919;
`;

const MobileSearchIcon = styled(SearchIcon)`
    color: #191919;
`;

const MobileSearchClearIcon = styled(ClearIcon)`
    color: #191919;
`;

export default {
    AppBar,
    Toolbar,
    Drawer,
    BottomList,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MobileMenuItem,
    MobileMenuItemBar,
    MobileMenuHeader,
    Spacer,
    DesktopNavList,
    Logo,
    MobileNavClearIcon,
    AccountIconButton,
    AccountIconButtonBorder,
    MobileSearchClearIcon,
    MobileMenuIcon,
    MobileSearchIcon,
    AccountPopover,
    AdminNavPopover,
    UserBox,
    DesktopMenuButton,
    TeamName,
};
