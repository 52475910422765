import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { Error } from 'components';
import { Company, CompanyCalculator } from './pages';

const CompanyRoutes = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}/my_accounts`}>
                <Redirect to="/dashboard" />
            </Route>
            <Route exact path={`${path}/calculator/:companyId`}>
                <CompanyCalculator />
            </Route>
            <Route exact path={`${path}/dashboard`}>
                <Redirect to="/dashboard" />
            </Route>
            <Route exact path={`${path}/:companyId`}>
                <Company />
            </Route>
            <Route>
                <Error errorStatus="404" />
            </Route>
        </Switch>
    );
};

export default CompanyRoutes;
