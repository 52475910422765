import React, { useState } from 'react';
import { useBreakpoint } from 'hooks';
import { RoundedButton, CompanyPopup } from 'components';
import { Typography } from '@material-ui/core';

const InProgress = ({ policyRequest, company }) => {
    const [openPopup, setOpenPopup] = useState(false);
    const isSm = useBreakpoint('down', 'sm');

    return (
        <>
            <RoundedButton
                onClick={() => setOpenPopup(true)}
                fullWidth={isSm}
                py={0.3}>
                <Typography variant="subtitle2">
                    Start Application
                </Typography>
            </RoundedButton>
            <CompanyPopup
                open={openPopup}
                handleClose={() => setOpenPopup(false)}
                company={company}
                policyRequestID={policyRequest.id}
            />
        </>
    );
}


export default InProgress;
