import { useQuery } from 'react-query';
import { ErrorResponseType } from '../../mutationTypes';
import { useAxiosInstance } from 'hooks';
import { AxiosInstance } from 'axios';

type SuccessResponseType = {
    html: string,
};

type QueryReturnType = {
    data: SuccessResponseType | undefined;
    error: ErrorResponseType | null;
    refetch: () => Promise<unknown>;
};

export const getRenderedEmailTemplate = async (
    policyRequestId: number,
    templateName: string,
    axiosInstance: AxiosInstance
): Promise<SuccessResponseType> => {
    const res = await axiosInstance.get(
        `/policyRequest/${policyRequestId}/get_rendered_email_template/${templateName}`
    );
    return res.data as SuccessResponseType;
};

export const useRenderedEmailTemplate = (
    policyRequestId: number,
    templateName: string,
    enabled: boolean = true,
): QueryReturnType => {
    const axiosInstance = useAxiosInstance();
    const { data, error, refetch } = useQuery({
        queryFn: () => getRenderedEmailTemplate(policyRequestId, templateName, axiosInstance),
        onError: (err: ErrorResponseType | null) => err,
        enabled: enabled,
    });
    return { data, error, refetch };
};
