import { TabTypeMap } from '@material-ui/core/Tab/Tab';

export enum IndirectBrokerActiveStatus {
    INACTIVE = '0',
    ACTIVE = '1',
}

export interface DirectBrokerDetails {
    id: number;
    indirect_broker_user: IndirectBrokerUserDetails;
    partnership_fee: number | undefined;
    active: boolean;
    color: string;
}

interface IndirectBrokerUserDetails {
    id: number;
    email: string;
    username: string;
}

export type NewIndirectBrokerFormData = Pick<DirectBrokerDetails, 'partnership_fee'> &
    Pick<IndirectBrokerUserDetails, 'email' | 'username'>;

export type IndirectBrokerFormData = Pick<DirectBrokerDetails, 'active'> &
    Pick<IndirectBrokerUserDetails, 'id'> &
    NewIndirectBrokerFormData;

export type IndirectBrokersDetailsByActiveStatus = Record<IndirectBrokerActiveStatus, DirectBrokerDetails[] | []>;

export type RetailPartnersTab = { value: IndirectBrokerActiveStatus } & Pick<TabTypeMap['props'], 'label'>;

export type IndirectBrokerId = Pick<IndirectBrokerUserDetails, 'id'>['id'];

export const TABS: RetailPartnersTab[] = [
    { value: IndirectBrokerActiveStatus.ACTIVE, label: 'Partners' },
    { value: IndirectBrokerActiveStatus.INACTIVE, label: 'Deactivated' },
];
