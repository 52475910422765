import styled from 'styled-components';
import {
    Box as _Box,
} from '@material-ui/core';

const OuterBox = styled(_Box)`
    &&& {
        &:hover {
            cursor: pointer;
        }
    }
`;

const TotalPremiumBox = styled(_Box)`
    &&& {
        color: #919191;
        ${({ theme }) => `
            margin-left: ${theme.spacing(2)}px;
            font-weight: ${theme.typography.fontWeightLight};
        `}
    }
`;

const TextBox = styled(_Box)`
    &&& {
        ${({ theme }) => `
            margin-left: ${theme.spacing(2)}px;
            font-weight: ${theme.typography.fontWeightBold};
            color: ${theme.palette.primary.main};
            ${theme.breakpoints.up('lg')} {
                margin-top: ${theme.spacing(2)}px;
            }
            ${theme.breakpoints.down('md')} {
                margin-top: ${theme.spacing(0.75)}px;
            }
            ${theme.breakpoints.down('sm')} {
                margin-top: ${theme.spacing(0)}px;
            }
        `}
    }
`;

export default {
    OuterBox,
    TextBox,
    TotalPremiumBox,
};
