import React from 'react';
import { StyledTable } from 'components';
import { useBreakpoint } from 'hooks';
import { getIsAnnualPolicy } from 'stores/selectors';
import accounting from 'accounting';
import { totalHumanize } from 'utils';
import { displayClaimLimit } from 'utils/policyRequest';
import { Box, Hidden, Typography, TableRow, CircularProgress } from '@material-ui/core';

const QuoteDetails = ({ optionToQuote, premiumIsLoading, policyRequest }) => {
    const isMd = useBreakpoint('down', 'md');
    const isAnnualPolicy = getIsAnnualPolicy(optionToQuote.quote);

    return (
        <>
            <TableRow>
                <Hidden smDown>
                    <StyledTable.TableCell variant="body" padding="none">
                        <Box fontWeight="fontWeightLight" pl={isMd ? 0 : 7}>
                            {premiumIsLoading ? (
                                <CircularProgress color="primary" size={32} />
                            ) : (
                                <Typography variant="h3">
                                    ${accounting.formatNumber(optionToQuote.quote.original_total_premium)}
                                    {!isAnnualPolicy && '*'}
                                </Typography>
                            )}
                        </Box>
                    </StyledTable.TableCell>
                    <StyledTable.TableCell variant="body" padding="none">
                        <Box fontWeight="fontWeightLight">
                            <Typography variant="h3">
                                ${totalHumanize(optionToQuote.quote.aggregate_limit)}
                                {displayClaimLimit(policyRequest, optionToQuote) &&
                                    ` / $${totalHumanize(optionToQuote.quote.per_claim_limit)}`}
                            </Typography>
                        </Box>
                    </StyledTable.TableCell>
                    <StyledTable.TableCell variant="body" padding="none">
                        <Box fontWeight="fontWeightLight">
                            <Typography variant="h3">
                                ${totalHumanize(optionToQuote.quote.aggregate_retention)}
                            </Typography>
                        </Box>
                    </StyledTable.TableCell>
                </Hidden>
                <Hidden mdUp>
                    <StyledTable.TableCell variant="body" padding="none">
                        <Box fontWeight="fontWeightLight">
                            <Typography variant="h5">
                                ${totalHumanize(optionToQuote.quote.aggregate_limit)}
                                {displayClaimLimit(policyRequest, optionToQuote) &&
                                    ` / $${totalHumanize(optionToQuote.quote.per_claim_limit)}`}
                            </Typography>
                        </Box>
                    </StyledTable.TableCell>
                    <StyledTable.TableCell variant="body" padding="none">
                        <Box fontWeight="fontWeightLight">
                            <Typography variant="h5">
                                ${totalHumanize(optionToQuote.quote.aggregate_retention)}
                            </Typography>
                        </Box>
                    </StyledTable.TableCell>
                </Hidden>
            </TableRow>
            {!isAnnualPolicy && (
                <TableRow>
                    <Hidden smDown>
                        <StyledTable.TableCell padding="none">
                            <Box
                                fontWeight="fontWeightRegular"
                                mt={1}
                                ml={isMd ? 0 : 7}
                                style={{
                                    display: 'inline-block',
                                    backgroundColor: '#696969',
                                    color: 'white',
                                    padding: '8px 10px',
                                    borderRadius: '4px',
                                }}>
                                <Typography variant="body2" component="span">
                                    * Based off annual premium of $
                                    {accounting.formatNumber(optionToQuote.quote.total_annual_premium)}.
                                </Typography>
                            </Box>
                        </StyledTable.TableCell>
                        <StyledTable.TableCell></StyledTable.TableCell>
                        <StyledTable.TableCell></StyledTable.TableCell>
                    </Hidden>
                    <Hidden mdUp>
                        <StyledTable.TableCell padding="none" colSpan="2">
                            <Box
                                fontWeight="fontWeightRegular"
                                marginTop={1}
                                style={{
                                    display: 'inline-block',
                                    backgroundColor: '#696969',
                                    color: 'white',
                                    padding: '8px 10px',
                                    borderRadius: '4px',
                                    lineHeight: '12px',
                                }}>
                                <Typography variant="body2" component="span">
                                    * Based off annual premium of $
                                    {accounting.formatNumber(optionToQuote.quote.total_annual_premium)}.
                                </Typography>
                            </Box>
                        </StyledTable.TableCell>
                    </Hidden>
                </TableRow>
            )}
        </>
    );
};

export default QuoteDetails;
