import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Box, Typography, FormControl, FormGroup } from '@material-ui/core';
import generalStyled from '../../../components/styled-components';
import Markdown from 'markdown-to-jsx';
import CustomCheckbox from 'components/checkbox/CustomCheckbox';
import InfoTooltip from '../../../components/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setQuestionValue } from 'stores/questions/questionsSlice';
import { questionHasError, scrollToQuestionError } from 'utils/questionnaireSequence';
import { useBreakpoint, useDebounceCallback, useIsMounted } from 'hooks';
import { setNewQuestionData } from 'stores/questionsErrors/questionsErrorsSlice';
import Styled from 'modules/questionnaire/pages/new-questionnaire/components/questions/styled-components';
import { getSortedAnswers } from '../../utils';

const MultipleChoicesQuestion = ({ question, updateForm, firstInSection, setScroll, scroll, setChangeMade }) => {
    const questionRef = useRef(null);
    const isSm = useBreakpoint('down', 'sm');
    const isLg = useBreakpoint('up', 'lg');
    const dispatch = useDispatch();
    const answer = useSelector(state => state.questions?.questionsToAnswers[question.id]);
    const [checkedAnswers, setCheckedAnswers] = useState(answer || []);
    const { firstQuestionWithError, submitFailed } = useSelector(state => state.questionErrors);
    const [value, setValue] = useState(checkedAnswers || '');
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [currentProcessingQuestions, setCurrentProcessingQuestions] = useState(new Set());
    const questionMetaData = useSelector(
        state => state.questionnaire.questionsMetadata.find(item => item.questionId === question.id)?.metadata,
    );
    const isMounted = useIsMounted();

    const debouncedUpdateForm = useDebounceCallback(updateForm, 1000, []);

    const sortedOptionalAnswers = useMemo(() => {
        return getSortedAnswers(question);
    }, [question.optional_answers]);

    useEffect(() => {
        dispatch(
            setNewQuestionData({
                questionId: question.id,
                hasError: questionHasError(submitFailed, answer, question.required, true),
            }),
        );
    }, [submitFailed]);

    useEffect(() => {
        if (scroll && firstQuestionWithError === question.id) {
            scrollToQuestionError(questionRef);
            setScroll(false);
        }
        if (!scroll) {
            setError(questionHasError(submitFailed, answer, question.required, true));
        }
    }, [firstQuestionWithError, scroll]);

    useEffect(() => {
        dispatch(
            setNewQuestionData({
                questionId: question.id,
                hasError: questionHasError(submitFailed, answer, question.required, true),
            }),
        );
    }, []);

    useEffect(() => {
        if (!questionMetaData?.savingQuestion && isMounted()) {
            setCurrentProcessingQuestions(new Set());
        }
    }, [questionMetaData?.savingQuestion]);

    useEffect(() => {
        setCheckedAnswers(answer || []);
    }, [answer]);

    const handleOnChange = e => {
        const checkedAnswer = e.target.value;
        setValue(checkedAnswer);
        setError(false);
        setChangeMade(true);
        let currentChoices = [...checkedAnswers];
        let questionsWithLoader = new Set(currentProcessingQuestions);
        questionsWithLoader = questionsWithLoader.add(checkedAnswer);
        setCurrentProcessingQuestions(questionsWithLoader);

        if (currentChoices.includes(checkedAnswer)) {
            currentChoices = currentChoices.filter(item => item !== checkedAnswer);
        } else {
            currentChoices.push(checkedAnswer);
        }
        dispatch(setQuestionValue({ name: question.id, value: currentChoices }));
        setCheckedAnswers(currentChoices);
        debouncedUpdateForm({
            questionId: question.id,
            answerId: currentChoices,
            answerType: question.optional_answers[0].type,
            value: currentChoices,
            invalid: false,
            required: question.required,
            questionType: question.type,
        });
    };

    return (
        <Box
            ref={questionRef}
            display="flex"
            px={isSm ? 4 : 0}
            key={`${question.id}_question`}
            pb={0.5}
            justifyContent="center">
            <FormControl fullWidth style={{ maxWidth: isSm ? 'unset' : '424px' }} error={error}>
                <Box
                    component={generalStyled.FormLabel}
                    display="flex"
                    alignItems="center"
                    className={firstInSection ? 'nospace' : null}>
                    <Typography variant="body1">
                        <Markdown>{(question.required ? '' : '(Optional) ') + question.question}</Markdown>
                        {question.application_parameters.help_text && (
                            <InfoTooltip
                                dataTrack={`questionnaire_tooltip_${question.id}`}
                                placement="bottom"
                                open={open}
                                setOpen={setOpen}
                                text={question.application_parameters.help_text}
                                select={true}
                            />
                        )}
                    </Typography>
                </Box>
                <FormGroup onChange={handleOnChange} value={value} data-track={`questionnaire_question_${question.id}`}>
                    {sortedOptionalAnswers.map(choiceAnswer => {
                        return (
                            <Box display="flex" width="100%">
                                <generalStyled.FormControlLabel
                                    style={{ width: '100%' }}
                                    onClick={e => e.preventDefault()}
                                    key={choiceAnswer.id}
                                    label={
                                        <Typography
                                            variant="body1"
                                            style={{
                                                paddingBottom: isLg ? 1 : 0,
                                                paddingTop: isLg ? 0 : 3,
                                                paddingLeft: 12,
                                            }}
                                            onClick={e => e.stopPropagation()}>
                                            <Box
                                                component="span"
                                                color={
                                                    checkedAnswers?.includes(choiceAnswer.id) ? '#191919' : '#696969'
                                                }>
                                                {choiceAnswer.display_name}
                                            </Box>
                                        </Typography>
                                    }
                                    control={
                                        <CustomCheckbox
                                            onClick={e => e.stopPropagation()}
                                            name={choiceAnswer.id}
                                            value={choiceAnswer.id}
                                            checked={checkedAnswers?.includes(choiceAnswer.id) || false}
                                            variant={isLg ? 'subtitle2' : 'h4'}
                                        />
                                    }
                                    value={choiceAnswer.id}
                                />
                                {currentProcessingQuestions.has(choiceAnswer.id) &&
                                    questionMetaData?.savingQuestion &&
                                    !error && (
                                        <Styled.MultipleChoiceCircularProgress
                                            style={{ alignSelf: 'end', marginTop: '-1px' }}
                                            size={18}
                                        />
                                    )}
                            </Box>
                        );
                    })}
                </FormGroup>
            </FormControl>
        </Box>
    );
};

export default MultipleChoicesQuestion;
