import React from 'react';
import Styled from './styled-components';
import { StyledLink } from 'components';
import AdditionalCompanyInfo from './AdditionalCompanyInfo';
import { Typography } from '@material-ui/core';


const DropdownItem = ({
    href,
    text,
    lineColor = '#191919',
    color = '#191919',
    onClickHandler,
    handleOnClose,
    handleClearInput,
    companyData = null,
}) => {
    const menuItem = (
        <Styled.MenuItem
            linecolor={lineColor}
            color={color}
            onClick={onClickHandler}>
            {text}
        </Styled.MenuItem>
    );

    const handleOnClick = () => {
        if (handleOnClose) {
            handleOnClose();
        }

        if (handleClearInput) {
            handleClearInput();
        }
    };

    return (
        <>
            {href ? (
                <Typography
                    variant="subtitle2"
                    component="span"
                    onClick={handleOnClick}>
                    <StyledLink.Default to={href}>
                        {menuItem}
                        {companyData && <AdditionalCompanyInfo companyData={companyData} />}
                    </StyledLink.Default>
                </Typography>
            ) : (
                menuItem
            )}
        </>
    );
};

export default DropdownItem;
