import React, { useEffect, useRef, useState, useMemo } from 'react';
import Script from 'react-load-script';
import { Box, InputAdornment } from '@material-ui/core';
import GeneralStyled from '../components/styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setQuestionValue } from 'stores/questions/questionsSlice';
import { setQuestionData } from 'stores/questionsErrors/questionsErrorsSlice';
import Markdown from 'markdown-to-jsx';
import InfoTooltip from '../components/Tooltip';
import { addAnswer, setQuestionnaireStateToAnswerChanging, setQuestionnaireStateToChangesSaved } from 'stores/questionnaire/questionnaireSlice';
import { useEnv, useTextQuestionStyles } from 'hooks';
import { questionHasError, scrollToQuestionError } from 'utils/questionnaireSequence';
import { usePermissions } from 'hooks/usePermissions';
import { Permissions } from 'utils/enums';

const Autocomplete = ({ question, updateForm, visible, setScroll, scroll, setEditAnswerMode, setSubmissionDisabled, setCheckTriedToSubmit, setChangeMade }) => {
    const questionRef = useRef();
    const inputFieldRef = useRef();
    const placeholderLabelRef = useRef();
    const autocomplete = useRef();
    const dispatch = useDispatch();
    const answer = useSelector(state => state.questions.questionsToAnswers[question.datapoint.datapoint_id]);
    const { firstQuestionWithError, submitFailed } = useSelector(state => state.questionErrors);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState('');
    const [value, setValue] = useState(answer ? JSON.parse(answer).formatted_address : '');
    const [firstChange, setFirstChange] = useState(true);
    const [selected, setSelected] = useState(Boolean(answer));
    const [shrink, setShrink] = useState(Boolean(value));
    const [open, setOpen] = useState(false);
    const [requiredError, setRequiredError] = useState(questionHasError(submitFailed, value, question.datapoint.required, visible));
    const { googleMapPlacesKey } = useEnv();
    const placeholder = useMemo(() => {
        return value || shrink ? '' : question.datapoint.text_to_display;
    }, [value, shrink, question.datapoint.text_to_display]);
    const [inputStyles, inputComponentStyles] = useTextQuestionStyles(placeholder, placeholderLabelRef, inputFieldRef);
    const submissionWriteAuthorized = usePermissions()([Permissions.SUBMISSION_WRITE]);
    const url = `https://maps.googleapis.com/maps/api/js?key=${googleMapPlacesKey}&libraries=places`;

    useEffect(() => {
        if (value) {
            dispatch(addAnswer(question.datapoint.datapoint_id));
        }
    }, []);

    useEffect(() => {
        dispatch(
            setQuestionData({
                datapointID: question.datapoint.datapoint_id,
                hasError: questionHasError(submitFailed, value, question.datapoint.required, visible) || error,
            }),
        );
    }, [submitFailed, error, visible]);

    useEffect(() => {
        if (scroll && firstQuestionWithError === question.datapoint.datapoint_id) {
            scrollToQuestionError(questionRef);
            inputFieldRef.current.focus();
            setScroll(false);
        } else if (!scroll) {
            setRequiredError(questionHasError(submitFailed, value, question.datapoint.required, visible));
        }
    }, [firstQuestionWithError, scroll]);

    const handleScriptLoad = () => {
        setLoaded(true);
        const options = {
            types: ['address'],
        };
        /*global google*/
        autocomplete.current = new google.maps.places.Autocomplete(
            document.getElementById(`autocomplete_${question.datapoint.datapoint_id}`),
            options,
        );
        autocomplete.current.setFields(['address_components', 'formatted_address']);
        autocomplete.current.addListener('place_changed', handlePlaceSelect);
    };

    const handlePlaceSelect = () => {
        const addressObject = autocomplete.current.getPlace();
        const address = addressObject.address_components;
        if (!address) {
            return;
        }
        setError('');
        setSubmissionDisabled(false);
        setSelected(true);
        // Check if address is valid
        const postalCodes = addressObject.address_components
            .filter(it => it.types.indexOf('postal_code') !== -1)
            .map(it => it.long_name);
        if (address) {
            const answer = JSON.stringify({
                formatted_address: addressObject.formatted_address,
                component_address: address,
            });
            const newValue = JSON.parse(answer).formatted_address || address;
            setValue(newValue);
            dispatch(setQuestionValue({ name: question.datapoint.datapoint_id, value: answer }));
            if (postalCodes.length > 0) {
                updateForm(question.datapoint.datapoint_id, answer, false, question.datapoint.required);
            } else {
                setError('Addresses without a zip code are not supported.');
            }
        }
    };

    const handleOnChange = newValue => {
        setEditAnswerMode(true);
        setRequiredError(false);
        dispatch(setQuestionData({datapointID: question.datapoint.datapoint_id, hasError: false || error}));
        let address = newValue;
        try {
            address = JSON.parse(newValue).formatted_address || newValue;
        } catch {}
        setValue(address);
        setSelected(false);
        setChangeMade(true);
        if (firstChange) {
            setFirstChange(false);
            dispatch(setQuestionnaireStateToAnswerChanging());
        }
    };

    const handleBlur = val => {
        setRequiredError(questionHasError(submitFailed, val, question.datapoint.required, visible));
        dispatch(
            setQuestionData({
                datapointID: question.datapoint.datapoint_id,
                hasError: questionHasError(submitFailed, val, question.datapoint.required, visible) || error,
            }),
        );
        dispatch(setQuestionnaireStateToChangesSaved());
        setFirstChange(true);
        if (val) {
            if (!selected) {
                setError('Please select an address from the list of suggested addresses.');
                setEditAnswerMode(false);
                setSubmissionDisabled(true);
                setCheckTriedToSubmit(true);
            }
        } else {
            setError('');
            setShrink(false);
            updateForm(question.datapoint.datapoint_id, null, false, question.datapoint.required);
        }
    };

    return (
        <Box width="100%" ref={questionRef}>
            {!loaded && <Script url={url} onLoad={handleScriptLoad} />}
            <GeneralStyled.TextField
                inputRef={inputFieldRef}
                autoFocus={submissionWriteAuthorized ? Boolean(!value) : false}
                label={<Markdown variant="body1">{question.datapoint.text_to_display}</Markdown>}
                fullWidth
                id={`autocomplete_${question.datapoint.datapoint_id}`}
                type="text"
                value={value}
                onFocus={() => setShrink(true)}
                onChange={e => handleOnChange(e.target.value)}
                onBlur={e => handleBlur(e.target.value)}
                error={Boolean(error) || requiredError}
                helperText={error ? error : requiredError ? 'This field is required' : ''}
                placeholder={placeholder}
                InputLabelProps={{
                    shrink,
                    ref: placeholderLabelRef,
                }}
                inputProps={{
                    'data-track': `questionnaire_question_${question.datapoint.datapoint_id}`,
                    style: placeholder ? inputStyles.withPlaceholder : inputStyles.withoutPlaceholder,
                }}
                InputProps={{
                    style: placeholder ? inputComponentStyles.withPlaceholder : inputComponentStyles.withoutPlaceholder,
                    endAdornment: question.datapoint.application_parameters.help_text && (
                        <InputAdornment position="end">
                            <InfoTooltip
                                dataTrack={`questionnaire_tooltip_${question.datapoint.datapoint_id}`}
                                placement="bottom-end"
                                open={open}
                                setOpen={setOpen}
                                text={question.datapoint.application_parameters.help_text}
                                select={false}
                            />
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
};

export default Autocomplete;
