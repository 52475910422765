import React, {useCallback, useState, useEffect, useRef, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NoteIdentifier } from 'utils/enums';
import { getSignatureStatusText, isValidFileTypes, fileTypesToString } from 'utils';
import Dropzone from 'react-dropzone';
import { useBreakpoint, useAxiosClient, useEnv } from 'hooks';
import { addFilesToContingency, removeFileFromContingency, setPolicyRequestAttribute } from 'stores/company/companySlice';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DoneIcon from '@material-ui/icons/Done';
import CustomCheckbox from 'components/checkbox/CustomCheckbox';
import {
    Box,
    Typography,
    Hidden,
    FormControlLabel,
} from '@material-ui/core';
import {
    StyledLink,
    StyledAction,
    StyledAlert,
    StyledIcons,
    StyledBox,
    StyledTypography,
    RoundedButton,
} from 'components';
import { theme } from 'components/theme/styles';


const Contingency = ({ policyRequestId, openSignaturePopup, checked, text, id, files, last, identifier, is_pre_bind, uploadedFile }) => {
    const isSm = useBreakpoint('down', 'sm');
    const isLg = useBreakpoint('up', 'lg');
    const dispatch = useDispatch();
    const selectFile = useRef(null);
    const { apiBaseUrl } = useEnv();
    const axiosClient = useAxiosClient();
    const [uploading, setUploading] = useState(false);
    const [uploadFailedError, setUploadFailedError] = useState(null);
    const {
        optionToQuote: {
            quote: {
                policy_request: policyRequest
            },
        },
        submitting
    } = useSelector(state => state.company);
    const { contingencies, app_disclaimer_checked } = policyRequest;
    const [disclaimerCheck, setDisclaimerCheck] = useState(app_disclaimer_checked);
    const displayDisclaimer = useMemo(() => {
        return contingencies.every(
            contingency =>
                contingency.checked ||
                (!contingency.is_pre_bind && contingency.identifier === NoteIdentifier.SIGNATURE),
        ) && contingencies.find(
            contingency =>
                !contingency.is_pre_bind && contingency.identifier === NoteIdentifier.SIGNATURE && !contingency.checked,
        ) && files.length > 0
    }, [contingencies, files]);
    const signatureStatusText = getSignatureStatusText(policyRequest);
    const [, uploadPolicyFiles] = axiosClient({
        method: 'post',
        url: `/policyRequest/${policyRequestId}/policyFile/${id}`,
    },
    { manual: true });

    const [, deleteFile] = axiosClient({
        method: 'delete',
        url: `/policyRequest/${policyRequestId}/policyFile/${id}`,
    },
    { manual: true });

    const [, setPolicyDisclaimerChecked] = axiosClient({
        method: 'put',
        url: `/policyRequest/${policyRequestId}`,
    }, { manual: true });

    const handleFiles = useCallback(acceptedFiles => {
        if (checked) {
            return;
        }
        const uploadFiles = async () => {
            setUploading(true);

            if (!isValidFileTypes(Array.from((acceptedFiles)))) {
                setUploading(false);
                setUploadFailedError(`Invalid file type(s), must be one of: ${fileTypesToString()}`);
                return;
            }

            const formData = new FormData();
            for (let file of acceptedFiles) {
                formData.append('files', file);
            }

            try {
                const { data } = await uploadPolicyFiles({
                    data: formData
                });
                dispatch(addFilesToContingency({
                    id,
                    files: data
                }));
                setUploadFailedError(null);
                setUploading(false);
            } catch(error) {
                setUploading(false);
                setUploadFailedError(error.response?.data?.message);
            }
        }
        uploadFiles();
    }, [contingencies]);

    useEffect(() => {
        if (uploadedFile) {
            handleFiles(uploadedFile);
        }
    }, [uploadedFile]);

    useEffect(() => {
        if (identifier === NoteIdentifier.SIGNATURE && !displayDisclaimer && disclaimerCheck) {
            setDisclaimer();
        }
    }, [displayDisclaimer]);

    const setDisclaimer = () => {
        const newDisclaimerCheck = !disclaimerCheck;
        setDisclaimerCheck(newDisclaimerCheck);
        dispatch(setPolicyRequestAttribute({ name: 'app_disclaimer_checked', value: newDisclaimerCheck }));
        setPolicyDisclaimerChecked({ data: { 'app_disclaimer_checked': newDisclaimerCheck } });
    }

    const handleRemoveFile = file => {
        dispatch(removeFileFromContingency({ id, fileID: file.id }));
        deleteFile({
            data: {
                policy_request_file_id: file.id
            }
        });
    }

    const TypographyComponent = checked ? Typography : StyledTypography.FadedTypography;

    const upload = () => {
        selectFile.current.value = null;
        selectFile.current.click();
    }

    return (
        <>
            <Box display="flex" alignItems="flex-start">
                <TypographyComponent variant="subtitle2" component="span">
                    <Box mr={1}>{checked ? <DoneIcon /> : <ReportOutlinedIcon />}</Box>
                </TypographyComponent>
                <Box width="100%">
                    <TypographyComponent variant="subtitle2">
                        {checked ? '' : is_pre_bind ? '(Pre-Bind)' : '(Post-Bind)'} {text}
                    </TypographyComponent>
                    {files.map(file => (
                        <Box
                            mt={2}
                            width={['100%', '100%', '80%']}
                            fontWeight="fontWeightMedium"
                            key={file.id}
                            style={{ overflowWrap: 'anywhere' }}>
                            <StyledAlert.ItemAlert
                                className="no-border-radius"
                                severity="info"
                                style={{ backgroundColor: '#F2F2F2' }}
                                icon={
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        p={0}
                                        component={props => (
                                            <Typography variant="subtitle2" component="span" {...props} />
                                        )}>
                                        <DescriptionOutlinedIcon />
                                    </Box>
                                }
                                action={
                                    file.locked ? null : (
                                        <StyledIcons.CloseIcon
                                            className="small"
                                            data-testid="remove-contingency-file"
                                            color="primary"
                                            onClick={() => handleRemoveFile(file)}
                                        />
                                    )
                                }>
                                <Typography variant="subtitle2">
                                    <StyledLink.Anchor
                                        style={{ color: 'inherit' }}
                                        href={`${apiBaseUrl}/submission/download_policy_file/${file.id}/${file.file_name}`}>
                                        {file.file_name}
                                    </StyledLink.Anchor>
                                </Typography>
                            </StyledAlert.ItemAlert>
                        </Box>
                    ))}
                    { identifier === NoteIdentifier.SIGNATURE && displayDisclaimer && (
                        <Box marginLeft={-18.5} marginRight={-4} mt='11px'>
                            <Box style={{ backgroundColor: 'rgba(32, 32, 238, 0.1)' }} fontWeight="fontWeightLight" pl={18.25} pt={2}>
                                <Box fontWeight="fontWeightMedium">
                                    <Typography variant={isSm ? 'body1' : 'body2'}>
                                        Want your policy immediately? If so, confirm the following statement:
                                    </Typography>
                                </Box>
                                <Box  pb={1.5}>
                                    <Typography variant={isSm ? 'body2' : 'caption'}>
                                        If not, leave the box unchecked. An underwriter will have to review materials. Policies
                                        are often issued in 3-5 business days.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box style={{ backgroundColor: '#f6f6fe' }} fontWeight="fontWeightLight" pl={18.25} pt={1.5} display="flex" alignItems="flex-start">
                                <FormControlLabel
                                    style={{ marginLeft: 0 }}
                                    control={
                                        <CustomCheckbox
                                            value={disclaimerCheck}
                                            variant={isLg ? 'subtitle2' : 'subtitle2'}
                                            onClick={e => setDisclaimer()}
                                            checked={disclaimerCheck}
                                            disabled={submitting}
                                            color='white'
                                            data-track="disclaimer_checkbox"
                                        />
                                    }
                                />
                                <Box>
                                    <Box color={disclaimerCheck ? '#191919' : '#696969'} fontWeight="fontWeightLight" fontSize="9px" pb={1}>
                                        <Typography variant={isSm ? 'body1' : 'body2'}>
                                        I understand that by checking this box I am confirming that the document
                                                    uploaded:
                                        </Typography>
                                    </Box>
                                    <Box ml={3} display="list-item" color={disclaimerCheck ? '#191919' : '#696969'} fontWeight="fontWeightLight" fontSize="9px" pb={1}>
                                        <Typography variant={isSm ? 'body1' : 'body2'}>
                                            Is the application used to obtain the Quote and / or Conditional Binder,
                                        </Typography>
                                    </Box>
                                    <Box ml={3} display="list-item" color={disclaimerCheck ? '#191919' : '#696969'} fontWeight="fontWeightLight" fontSize="9px" pb={1}>
                                        <Typography variant={isSm ? 'body1' : 'body2'}>
                                            Includes the insured’s signature, and
                                        </Typography>
                                    </Box>
                                    <Box ml={3} display="list-item" color={disclaimerCheck ? '#191919' : '#696969'} fontWeight="fontWeightLight" fontSize="9px" pb={1}>
                                        <Typography variant={isSm ? 'body1' : 'body2'}>
                                            Makes no changes to the{' '}
                                            <Box component={StyledLink.Anchor}
                                                style={{ color: theme.palette.primary.main }}
                                                fontWeight="fontWeightMedium"
                                                target="_blank"
                                                href={`${apiBaseUrl}/questionnaire/render_policy_request/${policyRequestId}/questionnaire`}
                                                data-track="disclaimer_preview_app">
                                                application used to obtain the Quote and / or Conditional Binder.
                                            </Box>
                                        </Typography>
                                    </Box>
                                    <Box color={disclaimerCheck ? '#191919' : '#696969'} fontWeight="fontWeightLight" fontSize="9px" pb={1}>
                                        <Typography variant={isSm ? 'body1' : 'body2'}>
                                            I also understand that At-Bay may change the coverage offered in the
                                            Quote or insurance Policy if the document uploaded has additional
                                            information, or changes information submitted in the application,
                                            between the time of the Quote and binding of the insurance Policy.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}
                    <Box display="flex" flexDirection="column">
                        <Box alignItems="center">
                            {!checked ? (
                                <>
                                    <Hidden mdDown>
                                        <Dropzone onDrop={handleFiles} multiple={false} disabled={uploading} noClick>
                                            {({ getRootProps }) => (
                                                <StyledBox.NoFocus fontWeight="fontWeightMedium" {...getRootProps()}
                                                    ml={-18.5} pl={18.5} mr={-4} pr={4} pt={2} mb={-3} pb={3} display="flex">
                                                    <RoundedButton
                                                        style={{ background: 'rgba(32, 32, 238, 0.1)' }}
                                                        py={0.25}
                                                        fontWeight="fontWeightMedium" onClick={upload}
                                                        data-track={identifier === NoteIdentifier.SIGNATURE ? 'contingency_upload_doc_signed_app' : 'contingency_upload_doc'}>
                                                        <Typography variant="body2" color="primary">
                                                            <StyledAction.Base className={uploading ? 'disabled' : ''}>
                                                                {uploading
                                                                    ? 'Please wait...'
                                                                    : 'Tap to upload / Drag document here'}
                                                                <input ref={selectFile} type="file" hidden
                                                                    onChange={e => e.target.files.length > 0 && handleFiles(e.target.files)} />
                                                            </StyledAction.Base>
                                                        </Typography>
                                                    </RoundedButton>
                                                    {identifier === NoteIdentifier.SIGNATURE && !files.length && !signatureStatusText && (
                                                        <>
                                                            <Box mx={1}>
                                                                <StyledTypography.FadedTypography variant="body2" component="span">
                                                                    {' '}or{' '}
                                                                </StyledTypography.FadedTypography>
                                                            </Box>
                                                            <Box
                                                                component={StyledAction.Base}
                                                                onClick={openSignaturePopup}
                                                                fontWeight="fontWeightMedium"
                                                                data-track={`${window.location.pathname.includes('bind') ? 'binder' : 'policy'}_request_e_signature`}>
                                                                <Typography variant="body2" color="primary" component="span">
                                                                    {files.length > 0
                                                                        ? ''
                                                                        : 'Invite your client to sign the application online'}
                                                                </Typography>
                                                            </Box>
                                                        </>
                                                    )}
                                                </StyledBox.NoFocus>
                                            )}
                                        </Dropzone>
                                    </Hidden>
                                    <Hidden lgUp>
                                        <Dropzone onDrop={handleFiles} multiple={false} disabled={uploading} noClick>
                                            {({ getRootProps }) => (
                                                <StyledBox.NoFocus fontWeight="fontWeightMedium" {...getRootProps()} display="flex" alignItems="center"
                                                    ml={-18.5} pl={18.5} mr={-4} pr={4} pt={2} mb={-3} pb={3}>
                                                    <Typography variant="body2" color="primary" onClick={upload}
                                                        data-track={identifier === NoteIdentifier.SIGNATURE ? 'contingency_upload_doc_signed_app' : 'contingency_upload_doc'}>
                                                        <StyledAction.Base className={uploading ? 'disabled' : ''}>
                                                            {uploading
                                                                ? 'Please wait...'
                                                                : identifier === NoteIdentifier.SIGNATURE && files.length === 0
                                                                    ? 'Upload signed app'
                                                                    : 'Upload attachment'}
                                                            <input ref={selectFile} type="file" hidden
                                                                onChange={e => e.target.files.length > 0 && handleFiles(e.target.files)} />
                                                        </StyledAction.Base>
                                                    </Typography>
                                                    {identifier === NoteIdentifier.SIGNATURE && !files.length && !signatureStatusText && (
                                                        <>
                                                            <Box mx={1}>
                                                                <StyledTypography.FadedTypography variant="body2" component="span">
                                                                    {' '}or{' '}
                                                                </StyledTypography.FadedTypography>
                                                            </Box>
                                                            <Box
                                                                component={StyledAction.Base}
                                                                onClick={openSignaturePopup}
                                                                fontWeight="fontWeightMedium"
                                                                data-track={`${window.location.pathname.includes('bind') ? 'binder' : 'policy'}_request_e_signature`}>
                                                                <Typography variant="body2" color="primary" component="span">
                                                                    {files.length > 0
                                                                        ? ''
                                                                        : isSm
                                                                            ? 'Request e-signature'
                                                                            : 'Request client sign the application online'}
                                                                </Typography>
                                                            </Box>
                                                        </>
                                                    )}
                                                </StyledBox.NoFocus>
                                            )}
                                        </Dropzone>
                                    </Hidden>
                                </>
                            ) : (identifier === NoteIdentifier.SIGNATURE && signatureStatusText && (
                                <Box
                                    component={props => (
                                        <StyledTypography.FadedTypography {...props} component="span" />
                                    )}
                                    variant="body2"
                                    fontWeight="fontWeightMedium">
                                    {signatureStatusText}
                                </Box>

                            ))}
                        </Box>
                        {uploadFailedError && (
                            <Box fontWeight="fontWeightLight" mt={1}>
                                <Typography variant="body2" color="error" component="span">
                                    {uploadFailedError}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
            {(!last || isSm) && <Box borderBottom="2px solid #F2F2F2" my={3} mx={isSm ? -4 : 0} />}
        </>
    );
}

export default Contingency;
