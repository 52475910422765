import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from 'stores/user/userSlice';
import { useAxiosClient, useEnv } from 'hooks';
import Styled from './styled-components';
import { Header, Footer, MonitorPathChanges, MessageInterceptor } from 'components';
import { BrowserRouter as Router } from 'react-router-dom';
import MainRoutes from 'modules/MainRoutes';
import ReactGA from 'react-ga';
import { FullStory } from 'services';


function MainPage() {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.newAuth);
    const { showFooter, showHeader } = useSelector(state => state.footer);
    const axiosClient = useAxiosClient();
    const [, getCurrentUser] = axiosClient(
        {
            method: 'get',
            url: '/auth/my_user',
        },
        { manual: true },
    );
    const { gaApiKey, gaDebugMode, appPrefix } = useEnv();

    ReactGA.initialize(gaApiKey, {
        debug: gaDebugMode === 'true',
        titleCase: false,
    });

    useEffect(() => {
        const getUser = async () => {
            const res = await getCurrentUser();
            const user = res.data.user_dump;
            const userPermissions = res.data.permissions.join(', ');
            dispatch(setUser(res.data));
            ReactGA.set({ userId: user.id });
            FullStory.identify(user.id, {
                displayName: user.username,
                email: user.email,
                role_type: user.role.name,
                bp_permissions: userPermissions,
                roleDescription: user.role_description,
                teamId: user.team?.id,
            })
        };

        if (isAuthenticated) {
            getUser();
        }
    }, [isAuthenticated]);

    return (
        <Router basename={appPrefix}>
            <MonitorPathChanges />
            <MessageInterceptor />
            {showHeader && <Header />}
            <Styled.MainPage extend={!showFooter}>
                <MainRoutes />
            </Styled.MainPage>
            {showFooter && <Footer />}
        </Router>

    );
}

export default MainPage;
