import React, { useEffect, useState, useRef, useMemo } from 'react';
import Markdown from 'markdown-to-jsx';
import GeneralStyled from '../components/styled-components';
import { Box, FormControl, InputAdornment } from '@material-ui/core';
import InfoTooltip from '../components/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setQuestionValue } from 'stores/questions/questionsSlice';
import { setQuestionData } from 'stores/questionsErrors/questionsErrorsSlice';
import { conditionedNodeNeedToBeVisible } from 'utils/questionsConditions';
import { questionHasError, scrollToQuestionError } from 'utils/questionnaireSequence';
import { useBreakpoint, useTextQuestionStyles } from 'hooks';
import {
    setQuestionnaireStateToAnswerChanging,
    setQuestionnaireStateToChangesSaved,
    addVisibleQuestion,
    removeVisibleQuestion,
    addAnswer,
    removeAnswer,
} from 'stores/questionnaire/questionnaireSlice';

const FreeTextQuestion = ({ question, updateForm, setScroll, scroll, setEditAnswerMode, setChangeMade }) => {
    const questionRef = useRef();
    const inputFieldRef = useRef();
    const placeholderLabelRef = useRef();
    const isSm = useBreakpoint('down', 'sm');
    const dispatch = useDispatch();
    const { questionsToAnswers } = useSelector(state => state.questions);
    const { sectionsData } = useSelector(state => state.questionnaire);
    const answer = useSelector(state => state.questions?.questionsToAnswers[question.datapoint.datapoint_id]);
    const { firstQuestionWithError, submitFailed } = useSelector(state => state.questionErrors);
    const [visible, setVisible] = useState(
        !question.datapoint.display_condition ||
            conditionedNodeNeedToBeVisible(question.datapoint.display_condition, sectionsData, questionsToAnswers)
    );
    const [value, setValue] = useState(answer || '');
    const [focus, setFocus] = useState(Boolean(value));
    const placeholder = useMemo(() => {
        return value || focus ? '' : question.datapoint.text_to_display;
    }, [value, focus, question.datapoint.text_to_display]);
    const [firstChange, setFirstChange] = useState(true);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(questionHasError(submitFailed, answer, question.datapoint.required, visible));
    const [inputStyles, inputComponentStyles] = useTextQuestionStyles(placeholder, placeholderLabelRef, inputFieldRef);

    useEffect(() => {
        if (question.datapoint.display_condition) {
            setVisible(
                conditionedNodeNeedToBeVisible(question.datapoint.display_condition, sectionsData, questionsToAnswers),
            );
        }
    }, [questionsToAnswers]);

    useEffect(() => {
        dispatch(
            setQuestionData({
                datapointID: question.datapoint.datapoint_id,
                hasError: questionHasError(submitFailed, answer, question.datapoint.required, visible),
            }),
        );
    }, [submitFailed]);

    useEffect(() => {
        if (scroll && firstQuestionWithError === question.datapoint.datapoint_id) {
            scrollToQuestionError(questionRef);
            inputFieldRef.current.focus();
            setScroll(false);
        }
        if (!scroll) {
            setError(questionHasError(submitFailed, value, question.datapoint.required, visible));
        }
    }, [firstQuestionWithError, scroll]);

    useEffect(() => {
        dispatch(
            setQuestionData({
                datapointID: question.datapoint.datapoint_id,
                hasError: questionHasError(submitFailed, answer, question.datapoint.required, visible),
            }),
        );
        if (visible) {
            if (question.datapoint.required || value) {
                dispatch(addVisibleQuestion(question.datapoint.datapoint_id));
            }
            if (value) {
                dispatch(addAnswer(question.datapoint.datapoint_id));
            }
        } else {
            dispatch(removeVisibleQuestion(question.datapoint.datapoint_id));
            dispatch(removeAnswer(question.datapoint.datapoint_id));
        }
    }, [visible]);

    const handleOnBlur = () => {
        const hasError = questionHasError(submitFailed, value, question.datapoint.required, visible);
        setFocus(false);
        setError(hasError);
        dispatch(setQuestionData({datapointID: question.datapoint.datapoint_id, hasError: hasError}));
        dispatch(setQuestionnaireStateToChangesSaved());
        setFirstChange(true);
        dispatch(setQuestionValue({ name: question.datapoint.datapoint_id, value: value }));
        updateForm(question.datapoint.datapoint_id, value, false, question.datapoint.required);
    }

    return (
        visible && (
            <Box ref={questionRef} display="flex" px={isSm ? 4 : 0} key={`${question.datapoint.id}_question`} pb={1.625} justifyContent="center">
                <FormControl fullWidth style={{maxWidth: isSm ? 'unset' : '424px' }}>
                    <GeneralStyled.TextField
                        inputRef={inputFieldRef}
                        fullWidth
                        onChange={e => {
                            setEditAnswerMode(true);
                            setValue(e.target.value);
                            setError(false);
                            dispatch(setQuestionData({datapointID: question.datapoint.datapoint_id, hasError: false}));
                            setChangeMade(true);
                            if (firstChange) {
                                setFirstChange(false);
                                dispatch(setQuestionnaireStateToAnswerChanging());
                            }
                        }}
                        value={value}
                        type="text"
                        name={question.datapoint.datapoint_id}
                        label={<Markdown variant="body1">{question.datapoint.text_to_display}</Markdown>}
                        error={error}
                        helperText={error && 'This field is required'}
                        InputLabelProps={{
                            ref: placeholderLabelRef,
                        }}
                        inputProps={{
                            'data-track': `questionnaire_question_${question.datapoint.datapoint_id}`,
                            style: placeholder ? inputStyles.withPlaceholder : inputStyles.withoutPlaceholder,
                        }}
                        InputProps={{
                            style: placeholder ? inputComponentStyles.withPlaceholder : inputComponentStyles.withoutPlaceholder,
                            endAdornment: question.datapoint.application_parameters.help_text && (
                                <InputAdornment position="end">
                                    <InfoTooltip
                                        dataTrack={`questionnaire_tooltip_${question.datapoint.datapoint_id}`}
                                        placement="bottom-end"
                                        open={open}
                                        setOpen={setOpen}
                                        text={question.datapoint.application_parameters.help_text}
                                        select={false}
                                    />
                                </InputAdornment>
                            )
                        }}
                        placeholder={placeholder}
                        onFocus={() => setFocus(true)}
                        onBlur={handleOnBlur}
                    />
                </FormControl>
            </Box>
        )
    );
};

export default FreeTextQuestion;
