import React, { FC } from 'react';
import { IconButton, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { DirectBrokerDetails } from '../../utils';
import { useBreakpoint } from 'hooks';
import Styled from './styled-components';

interface Props {
    onEditClick: () => void;
    onActivateClick: () => void;
    onDeactivateClick: () => void;
    onResendLinkClick: () => void;
    onCopyLinkClick: () => void;
    partner: DirectBrokerDetails;
}

const ActionsDropdown: FC<Props> = ({
    onEditClick,
    onActivateClick,
    onDeactivateClick,
    onResendLinkClick,
    onCopyLinkClick,
    partner,
}) => {
    const isSm = useBreakpoint('down', 'md');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEditButtonClick = () => {
        handleClose();
        onEditClick();
    };

    const handleActivateButtonClick = () => {
        handleClose();
        onActivateClick();
    };

    const handleDeactivateButtonClick = () => {
        handleClose();
        onDeactivateClick();
    };

    const handleResendLink = () => {
        handleClose();
        onResendLinkClick();
    };

    const handleCopyLink = () => {
        handleClose();
        onCopyLinkClick();
    };

    return (
        <>
            <IconButton data-testid="action-menu" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: 150,
                    },
                }}>
                <Styled.MenuItem onClick={handleEditButtonClick}>Edit details</Styled.MenuItem>

                {partner.active ? (
                    <>
                        <Styled.MenuItem onClick={handleDeactivateButtonClick}>Deactivate</Styled.MenuItem>
                        {isSm && (
                            <>
                                <Styled.MenuItem onClick={handleResendLink}>Resend Link</Styled.MenuItem>
                                <Styled.MenuItem onClick={handleCopyLink}>Copy Link</Styled.MenuItem>
                            </>
                        )}
                    </>
                ) : (
                    <Styled.MenuItem onClick={handleActivateButtonClick}>Activate</Styled.MenuItem>
                )}
            </Menu>
        </>
    );
};

export default React.memo(ActionsDropdown);
