import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { RoundedButton } from 'components';
import { useBreakpoint, useEnv } from 'hooks';

const Actions = ({ policyRequest }) => {
    const isSm = useBreakpoint('down', 'sm');
    const { apiBaseUrl } = useEnv();

    const redirectToPolicyView = () => {
        window.open(`${apiBaseUrl}/questionnaire/render_policy_request/${policyRequest.id}/questionnaire`, '_blank');
    };

    return (
        <>
            <Box width="100%" height="4px" bgcolor="#FFEB00" my={3} />
            <RoundedButton py={1} px={4} onClick={redirectToPolicyView} fullWidth={isSm}>
                <Typography variant="subtitle2">Review Application</Typography>
            </RoundedButton>
        </>
    );
};

export default Actions;
