import React, { FC } from 'react';
import { StyledTable } from 'components/index';
import { Box, TableRow, Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
interface Props {
    variant: Variant | 'inherit';
    firstColSpan: number;
    firstColPadding: number;
    commissionPercentageString: string;
}

const CommissionTable: FC<Props> = ({ variant, firstColSpan, firstColPadding, commissionPercentageString }) => {
    return (
        <Box component={TableRow} fontWeight="fontWeightLight" className="head">
            <StyledTable.TableCell className="head" colSpan={firstColSpan} padding="none">
                <Typography variant={variant} component="span">
                    <Box mb={1.5} pl={firstColPadding} fontSize={16} fontWeight={'bold'}>
                        Commission
                    </Box>
                </Typography>
            </StyledTable.TableCell>
            <StyledTable.TableCell className="head" padding="none">
                <Typography variant={variant} component="span">
                    <Box fontSize={16}> </Box>
                </Typography>
            </StyledTable.TableCell>
            <StyledTable.TableCell className="head" padding="none">
                <Typography variant={variant} component="span">
                    <Box fontSize={16} fontWeight={'bold'} mb={1.5}>
                        {commissionPercentageString}
                    </Box>
                </Typography>
            </StyledTable.TableCell>
        </Box>
    );
};

export default CommissionTable;
