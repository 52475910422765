import passwordValidator from 'password-validator';
import { EMAIL_REGEX } from 'utils';

export const ValidMatchesPasswords = data => {
    return isValidPassword(data) && isValidPassword2(data);
};

export const isValidPassword = data => {
    return data.password.length >= 8;
};

export const isValidPassword2 = data => {
    return data.password2.length >= 8 && data.password === data.password2;
};

export const isValidName = name => {
    return name.length > 0 && name.match(/^[A-Za-z]*$/);
};

export const validatePassword = password => {
    const passwordValidation = new passwordValidator()
        .is().min(8)
        .is().max(30)
        .has().uppercase()
        .has().lowercase()
        .has().digits()
        .has().symbols()

    return passwordValidation.validate(password, { list: true });
};

export const isValidEmail = email => {
    return EMAIL_REGEX.test(email);
};

export const isAgencyEmail = email => {
    const personalDomains = ['gmail', 'yahoo', 'outlook', 'hotmail', 'icloud', 'aol', 'msn', 'wanadoo', 'orange',
        'xfinity', 'live', 'rediffmail.com', 'free.fr', 'gmx.de', 'web.de', 'yandex.ru', 'ymail', 'libero.it', 'uol.com.br',
        'bol.com.br', 'mail.ru', 'cox.net', 'sbcglobal.net', 'sfr.fr', 'verizon.net'];
    const index = email.indexOf('@');
    const userDomain = email.slice(index + 1);

    return !personalDomains.some(domain => userDomain.toLowerCase().includes(domain));
};

export const validFileTypes = {
    'application/pdf': 'pdf',
    'image/jpeg': 'jpeg, jpg',
    'image/png': 'png',
};

export const isValidFileTypes = files => {
    return files.every(file => Object.keys(validFileTypes).includes(file.type));
};

export const fileTypesToString = () => Object.values(validFileTypes).join(', ');
