import React from 'react';
import Styled from '../styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import {Typography} from '@material-ui/core';
import {RoundedButton} from '../../../../../components';
import {useHistory} from 'react-router-dom';

const NewAgencyPage = ({
    email,
}) => {
    const theme = useTheme();
    const history = useHistory();

    const handleReturnToLogin = () => {
        history.push('/user/login');
    };

    return (
        <>
            <Styled.FormHeader>Thank you for your interest in working with At-Bay!</Styled.FormHeader>
            <Styled.FormText variant="subtitle2">
                The email address you provided <span style={{ fontWeight: theme.typography.fontWeightMedium }}> {email} </span>
                is not associated with any of our appointed brokerages.
                If your firm aligns with our partnership criteria,
                we will contact you at the provided email address to initiate the appointment process.
            </Styled.FormText>
            <Styled.FormText variant="subtitle2">
                Once completed, someone from At-Bay will review your request and be in touch!
            </Styled.FormText>
            <Styled.SignUpLink>
                <RoundedButton
                    onClick={handleReturnToLogin}
                    py={0.3}
                    width="220px"
                    fontWeight={500}
                    ml={4}
                    mt={4}>
                    <Typography variant="subtitle2">Return to homepage</Typography>
                </RoundedButton>
            </Styled.SignUpLink>
        </>
    );
};

export default NewAgencyPage;
