import { FormControl, FormLabel, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React, { ChangeEvent, FC, useState } from 'react';
import { isValidEmail, isValidName } from '../../../../../../utils';
import { NewIndirectBrokerFormData } from '../../utils';
import NumberFormatInput from '../number-format-input';

interface Props {
    formState: NewIndirectBrokerFormData;
    updateFormState: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    isEditMode?: boolean;
}

const IndirectPartnerForm: FC<Props> = ({ formState, updateFormState, isEditMode = false }) => {
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidName, setInvalidName] = useState(false);

    const validateEmail = () => {
        setInvalidEmail(!isValidEmail(formState.email));
    };

    const validateName = () => {
        let isValid = false;

        if (formState.username) {
            const fullName = formState.username.split(' ').filter(x => x !== '');
            isValid = fullName.length >= 2 && isValidName(fullName[0]) && isValidName(fullName[1]);
        }

        setInvalidName(!isValid);
    };

    return (
        <Box display="flex" flexDirection="column" style={{ gap: 15 }}>
            <FormLabel>Who would you like to invite as a Retail Partner?</FormLabel>
            <FormControl fullWidth>
                <TextField
                    required
                    error={invalidName}
                    onBlur={validateName}
                    name="username"
                    label="Name of partner"
                    value={formState.username}
                    onChange={updateFormState}
                    helperText={invalidName ? 'Please enter a valid full name' : ''}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    disabled={isEditMode}
                    required
                    error={invalidEmail}
                    onBlur={validateEmail}
                    name="email"
                    label="Email of partner"
                    value={formState.email}
                    onChange={updateFormState}
                    helperText={invalidEmail ? 'Please enter a valid email address' : ''}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    required
                    onChange={updateFormState}
                    InputProps={{
                        inputComponent: NumberFormatInput as any,
                    }}
                    inputProps={{ prefix: '$' }}
                    name="partnership_fee"
                    label="Broker fee ($)"
                    value={formState.partnership_fee}
                />
            </FormControl>
        </Box>
    );
};

export default React.memo(IndirectPartnerForm);
