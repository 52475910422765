import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { StyledIcons } from 'components';
import Styled from './styled-components';
import { useHistory } from 'react-router-dom';
import { Container } from '@material-ui/core';

const Error = ({errorStatus, errorMessage = '', enableLinks=true}) => {
    const history = useHistory();
    const goBack = (e) => {
        e.preventDefault();
        history.goBack();
    }
    return(
        <Container>
            <Box mr={[0, 0, 5]} mt={20} display="flex" justifyContent="space-evenly">
                <Box fontWeight="fontWeightRegular" textAlign="left">
                    {!errorMessage ?
                        <Box>
                            <Box fontSize="16px" fontWeight="fontWeightLight" fontFamily="Pressura" letterSpacing="1.8px">ERROR {errorStatus}</Box>
                            <Box fontSize={['90px', '90px', '110px', '155px']} fontWeight="fontWeightBold" mt={1}>Oops!</Box>
                        </Box>
                        : <Box height="50px"/>
                    }
                    <Box fontSize={['28px', '28px', '34px', '45px']} fontWeight="fontWeightBold" color="#333" mb={5}
                        maxWidth={['unset', 'unset', '460px', '650px']}>
                        {!errorMessage ? 'We can’t seem to find the page you’re looking for.' : errorMessage}
                    </Box>
                    {enableLinks && <Box lineHeight="1.5">
                        <Typography variant="body1">Here are some helpful links instead:</Typography>
                        <Styled.LinkBack onClick={goBack}>
                            <Typography variant="body1">Go Back</Typography>
                        </Styled.LinkBack>
                        <Styled.ExternalLink color="#5583F7" href="http://help.at-bay.com/en/">
                            <Typography variant="body1">Help Center</Typography>
                        </Styled.ExternalLink>
                        <Styled.Link to="">
                            <Typography variant="body1">At-Bay Homepage</Typography>
                        </Styled.Link>
                    </Box>}
                </Box>
                <Hidden smDown>
                    <Box textAlign="end" pt={5}>
                        <StyledIcons.FenceIcon/>
                    </Box>
                </Hidden>
            </Box>
        </Container>
    );
}

export default Error;
