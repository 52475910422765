import React from 'react';
import { useHistory } from 'react-router-dom';
import { useBreakpoint } from 'hooks';
import { RoundedButton } from 'components'
import { Typography } from '@material-ui/core';


const DesktopTabletMobile = ({ policyRequest }) => {
    const isSm = useBreakpoint('down', 'sm');
    const history = useHistory();
    const redirectToEditQuestionnaire = () => history.push({ pathname: `/questionnaire/policy/${policyRequest.id}`, state: { editMode: true } });
    
    return (
        <RoundedButton onClick={redirectToEditQuestionnaire} fullWidth={isSm} py={0.3}>
            <Typography variant="subtitle2">
                Complete Application
            </Typography>
        </RoundedButton>
    );
}


export default DesktopTabletMobile;
