import React from 'react';
import { useParams } from 'react-router-dom';
import { IFrame } from 'components';
import { useEnv } from 'hooks';

const Policy = () => {
    const { policyRequestId } = useParams();
    const { apiBaseUrl } = useEnv();
    const url = `${apiBaseUrl}/questionnaire/policy/${policyRequestId}`;

    return <IFrame src={url} />;
};

export default Policy;
