import React, { createContext, useEffect, useReducer, useState } from 'react';
import {
    DirectBrokerDetails,
    IndirectBrokerFormData,
    IndirectBrokersDetailsByActiveStatus,
    NewIndirectBrokerFormData,
    IndirectBrokerId,
} from '../utils';
import { useAxiosClient, useAxiosInstance } from '../../../../../hooks';
import { makeToast } from '../../../../../utils';
import * as Sentry from '@sentry/react';
import { retailPartnersContextReducer } from './RetailPartnersContextReducer';
import { initialBrokersDataState, initialBrokersDetailsByActiveStatus } from './types';

const ERROR_TEXT = 'Something went wrong, please try again later.';
const EMPTY_STRING = '';

interface RetailPartnersContextInterface {
    brokerListByActiveStatus: IndirectBrokersDetailsByActiveStatus;
    brokersList: DirectBrokerDetails[];
    isLoading: boolean;
    addBroker: (value: NewIndirectBrokerFormData) => void;
    editBroker: (value: IndirectBrokerFormData, oldData: DirectBrokerDetails) => void;
    resendBrokerMail: (indirectBrokerId: IndirectBrokerId) => void;
    getIndirectBrokerInvitationLink: (indirectBrokerId: IndirectBrokerId) => Promise<string>;
    searchFilterText: string;
    setSearchFilterText: (text: string) => void;
}

const initialContextState: RetailPartnersContextInterface = {
    brokerListByActiveStatus: initialBrokersDetailsByActiveStatus,
    brokersList: [],
    isLoading: false,
    addBroker: () => undefined,
    editBroker: () => undefined,
    resendBrokerMail: (indirectBrokerId: IndirectBrokerId) => undefined,
    getIndirectBrokerInvitationLink: (indirectBrokerId: IndirectBrokerId) => new Promise(() => EMPTY_STRING),
    searchFilterText: '',
    setSearchFilterText: (text: string) => undefined,
};

interface Props {
    children: JSX.Element;
}

export const RetailPartnersContext = createContext<RetailPartnersContextInterface>(initialContextState);

export const RetailPartnersContextProvider = ({ children }: Props) => {
    const axiosClient = useAxiosClient();
    const axiosInstance = useAxiosInstance();

    const [brokersData, dispatchBrokersData] = useReducer(retailPartnersContextReducer, initialBrokersDataState);
    const [searchFilterText, setSearchFilterText] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [, getIndirectBrokerDetails] = axiosClient(
        {
            method: 'get',
            url: '/partnership_management/',
        },
        { manual: true },
    );

    const [, addIndirectBrokerServer] = axiosClient(
        {
            method: 'post',
            url: '/partnership_management/indirect_broker/new',
        },
        { manual: true },
    );

    const setBrokersData = async () => {
        try {
            setIsLoading(true);
            const res = await getIndirectBrokerDetails();
            dispatchBrokersData({ type: 'set', payload: res.data.direct_broker_indirect_brokers });
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            makeToast('error', ERROR_TEXT);
            Sentry.captureException(e);
        }
    };

    const addBroker = async (formData: NewIndirectBrokerFormData) => {
        try {
            const { data } = await addIndirectBrokerServer({ data: formData });
            const newBroker: DirectBrokerDetails = {
                id: data.id,
                indirect_broker_user: {
                    id: data.indirect_broker_user_id,
                    email: formData.email,
                    username: formData.username,
                },
                active: true,
                partnership_fee: formData.partnership_fee,
                color: data.color,
            };

            dispatchBrokersData({ type: 'add', payload: newBroker });
        } catch (e) {
            makeToast('error', ERROR_TEXT);
            Sentry.captureException(e);
        }
    };

    const editBroker = async (formData: IndirectBrokerFormData, brokerDetails: DirectBrokerDetails) => {
        try {
            await editBrokerServer(formData);

            const updatedData: DirectBrokerDetails = {
                ...brokerDetails,
                indirect_broker_user: {
                    ...brokerDetails.indirect_broker_user,
                    username: formData.username,
                },
                active: formData.active,
                partnership_fee: formData.partnership_fee,
            };

            dispatchBrokersData({ type: 'edit', payload: updatedData });
        } catch (e) {
            makeToast('error', ERROR_TEXT);
            Sentry.captureException(e);
        }
    };

    const editBrokerServer = (formData: IndirectBrokerFormData) => {
        return axiosInstance.put(`/partnership_management/indirect_broker/${formData.id}`, { ...formData });
    };

    const resendBrokerMail = async (indirectBrokerId: IndirectBrokerId) => {
        try {
            await axiosInstance.post(`/partnership_management/resend_link/${indirectBrokerId}`);
        } catch (e) {
            makeToast('error', ERROR_TEXT);
            Sentry.captureException(e);
        }
    };

    const getIndirectBrokerInvitationLink: (indirectBrokerId: IndirectBrokerId) => Promise<string> = async (
        indirectBrokerId: IndirectBrokerId,
    ) => {
        try {
            const res = await axiosInstance.get(`/partnership_management/invitation_link/${indirectBrokerId}`);
            return res.data.invitation_link as string;
        } catch (e) {
            makeToast('error', ERROR_TEXT);
            Sentry.captureException(e);
            return EMPTY_STRING;
        }
    };

    useEffect(() => {
        setBrokersData();
    }, []);

    return (
        <RetailPartnersContext.Provider
            value={{
                brokerListByActiveStatus: brokersData.brokersListByActiveStatus,
                brokersList: brokersData.brokersList,
                isLoading,
                addBroker,
                editBroker,
                resendBrokerMail,
                getIndirectBrokerInvitationLink,
                searchFilterText,
                setSearchFilterText,
            }}>
            {children}
        </RetailPartnersContext.Provider>
    );
};
