import styled from 'styled-components';
import { default as _LinearProgress } from '@material-ui/core/LinearProgress';
import { default as _Box } from '@material-ui/core/Box';

const LinearProgressBox = styled(_Box)`
    &&& {
        flex-grow: 0.5;
        font-size: 9.5px;
        font-weight: ${({ theme }) => theme.typography.fontWeightLight};
        text-align: right;
        color: ${({ value }) => value < 50 ? '#cc6f23' : (value < 100 ? '#ff9c00' : '#ff7300')};
    }
`;

const LinearProgress = styled(_LinearProgress)`
    &&& {
        background-color: #EEEEEE;
        & .MuiLinearProgress-barColorSecondary {
            background-color: ${({ value }) => value < 50 ? '#cc6f23' : (value < 100 ? '#ff9c00' : '#ff7300')};
        }
    }
`;

export default {
    LinearProgressBox,
    LinearProgress
};
