import React from 'react';
import Styled from '../../styled-components';
import { Box, Hidden } from '@material-ui/core';


const LoadingCoverages = () => {
    return (
        <>
            <Hidden smDown>
                <Box mt={3}>
                    <Box display="flex">
                        <Box width="100%" />
                        <Box display="flex" alignItems="center">
                            <Box width="120px" mr={1}>
                                <Box component={Styled.Skeleton} height="19px" width="80px" animation={false} sx={{transform: 'scale(1,1)'}}/>
                            </Box>
                            <Box width="120px" mr={1}>
                                <Box component={Styled.Skeleton} height="19px" width="80px" animation={false} sx={{transform: 'scale(1,1)'}}/>
                            </Box>
                            <Box width="120px" mr={1}>
                                <Box component={Styled.Skeleton} height="19px" width="80px" animation={false} sx={{transform: 'scale(1,1)'}}/>
                            </Box>
                        </Box>
                    </Box>
                    {[...Array(8)].map((_, i) => {
                        return (
                            <Box key={`skeleton-${i}-desktop`} mt='15px' display="flex" alignItems="center">
                                <Box width="90%">
                                    <Box component={Styled.Skeleton} height="19px" width="153px" animation={false} sx={{transform: 'scale(1,1)'}}/>
                                </Box>
                                <Box display="flex" height="34px" mt={0}>
                                    <Box component={Styled.Skeleton} mr={1} variant="text" width="120px" animation="wave" />
                                    <Box component={Styled.Skeleton} mr={1} variant="text" width="120px" animation="wave" />
                                    <Box component={Styled.Skeleton} mr={1} variant="text" width="120px" animation="wave" />
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            </Hidden>
            <Hidden mdUp>
                <Box>
                    {[...Array(8)].map((_, i) => {
                        return (
                            <Box key={`skeleton-${i}-mobile`} mb={2} display="flex" flexDirection="column">
                                <Box component={Styled.Skeleton} height="19px" width="140px" animation={false} sx={{transform: 'scale(1,1)'}}/>
                                <Box display="flex" height="34px" mt={1}>
                                    <Box component={Styled.Skeleton} mr={1} variant="text" width="100px" animation="wave" />
                                    <Box component={Styled.Skeleton} mr={1} variant="text" width="100px" animation="wave" />
                                    <Box component={Styled.Skeleton} variant="text" width="100px" animation="wave" />
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            </Hidden>
        </>
    )
}

export default LoadingCoverages;
