import React from 'react';
import { useHistory } from 'react-router-dom';
import { StyledTypography, StyledTable, RoundedButton } from 'components';
import accounting from 'accounting';
import { totalHumanize, formatDatePickerDate } from 'utils';
import { SubmissionsBucket } from 'utils/enums';
import { Box, Table, TableHead, TableBody, TableRow, Typography } from '@material-ui/core';
import NextSteps from 'components/policy/next-steps/BoundNextSteps';

const DesktopTablet = ({ policyRequest }) => {
    const history = useHistory();
    const isBindRequested = policyRequest.athena_submissions_bucket === SubmissionsBucket.BIND_REQUESTED;
    const TypographyComponent = isBindRequested ? StyledTypography.FadedTypography : Typography;
    const preferredOptionToQuote = policyRequest.option_to_quotes.find(optionToQuote => optionToQuote.quote.preferred);
    return (
        <Box component={props => <Table padding="none" {...props} />} fontWeight="fontWeightLight">
            <TableHead>
                <Box component={TableRow} borderBottom={`4px solid ${policyRequest.product.background_color}`}>
                    <StyledTable.TableCell padding="none" className="py-1">
                        <StyledTypography.FadedTypography variant="overline">TOTAL</StyledTypography.FadedTypography>
                    </StyledTable.TableCell>
                    <StyledTable.TableCell padding="none" className="py-1">
                        <StyledTypography.FadedTypography variant="overline">
                            AG. LIMIT
                        </StyledTypography.FadedTypography>
                    </StyledTable.TableCell>
                    <StyledTable.TableCell padding="none" className="py-1">
                        <StyledTypography.FadedTypography variant="overline">
                            POLICY PERIOD
                        </StyledTypography.FadedTypography>
                    </StyledTable.TableCell>
                    <StyledTable.TableCell padding="none" className="py-1" />
                </Box>
            </TableHead>
            <TableBody>
                <TableRow>
                    <StyledTable.TableCell padding="none" className="py-1">
                        <Box mt={2} />
                        <TypographyComponent variant="body1">
                            ${accounting.formatNumber(preferredOptionToQuote.quote.original_total_premium)}
                        </TypographyComponent>
                    </StyledTable.TableCell>
                    <StyledTable.TableCell padding="none" className="py-1">
                        <Box mt={2} />
                        <TypographyComponent variant="body1">
                            ${totalHumanize(preferredOptionToQuote.quote.aggregate_limit)}
                        </TypographyComponent>
                    </StyledTable.TableCell>
                    <StyledTable.TableCell padding="none" className="py-1">
                        <Box mt={2} />
                        <TypographyComponent variant="body1">
                            {formatDatePickerDate(policyRequest.effective_date)} -{' '}
                            {formatDatePickerDate(policyRequest.expiration_date)}
                        </TypographyComponent>
                    </StyledTable.TableCell>
                    <StyledTable.TableCell align="right">
                        <Box mt={2} />
                        <RoundedButton
                            py={0.5}
                            onClick={() => history.push(`/submission/policy_details/${preferredOptionToQuote.id}`)}>
                            <Typography variant="subtitle2">View Details</Typography>
                        </RoundedButton>
                    </StyledTable.TableCell>
                </TableRow>
                <NextSteps policyRequest={policyRequest} />
            </TableBody>
        </Box>
    );
};

export default DesktopTablet;
