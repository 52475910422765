import { env } from '../env'

const Hubspot = {
    init() {
        const { REACT_APP_HUBSPOT_URL, REACT_APP_HUBSPOT_FF } = env;
        if (REACT_APP_HUBSPOT_FF === 'true') {
            const scriptElement = document.createElement('script');
            scriptElement.type = 'text/javascript';
            scriptElement.async = true;
            scriptElement.defer = true
            scriptElement.id = 'hs-script-loader';
            scriptElement.src = ('https:' === document.location.protocol ? 'https' : 'http') + `://${REACT_APP_HUBSPOT_URL}`;
            const head = document.getElementsByTagName('head')[0];
            head.appendChild(scriptElement);
        }
    }
}

export default Hubspot