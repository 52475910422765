import styled from 'styled-components';
import {
    Container as _Container,
    TextField as _TextField,
    Typography as _Typography,
    Box as _Box,
} from '@material-ui/core';

const Header = styled(_Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 32px 0 24px 0;

    ${({ theme }) => `
            ${theme.breakpoints.up('sm')} {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 32px 0px 24px 0px;
            }
            ${theme.breakpoints.down('sm')} {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 16px 32px 32px 32px;
    `}
`;

const Container = styled(_Container)`
    &&& {
        max-width: 960px;
        width: inherit;
        align-self: center;
        ${({ theme }) => `
            ${theme.breakpoints.down('md')} {
                display: flex;
                margin: ${theme.spacing(0.5)}px;
                flex-wrap: nowrap;
                overflow-x: auto;
                flex-direction: column;
                padding-left:0;
                padding-right:0;
            }
        `}
    }
`;

const Box = styled(_Box)`
    background-color: #ffffff;
    padding-top: 24px;
`;

const TabsContainer = styled(_Container)`
  &&& {
    width: 100%;
    padding: 0;
`;

const Typography = styled(_Typography)`
    &&& {
        margin-bottom: 10px;
        color: #191919;
        text-align: left;
        font-size: 24px;

        ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightBold};
        `}
        &.sticky {
            ${({ theme }) => `
                font-weight: ${theme.typography.fontWeightBold};
                margin-top: ${theme.spacing(2)}px;
            `}
        }
    }
`;

const TextField = styled(_TextField)`
    .MuiOutlinedInput-root {
        width: 400px;
    }

    .MuiInputBase-input {
        width: 400px;
        padding: 10px 10px;
        font-size: 16px;
    }

    ${({ theme }) => `
        ${theme.breakpoints.down('md')} {
            .MuiOutlinedInput-root {
                width: 100%;
                border-radius: 21px;
            }

            .MuiInputBase-input {
                width: 100%;
                padding: 10px 10px;
                font-size: 16px;
            }

        }
    `}
`;

const Row = styled(_Box)`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

const Column = styled(_Box)`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export default {
    Header,
    Container,
    Box,
    Typography,
    TextField,
    TabsContainer,
    Row,
    Column,
};
