import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../stores/rootReducer';

interface CheckPermissionsCallback {
    (requiredPermissions: string[]): boolean;
}

export const usePermissions: () => CheckPermissionsCallback = () => {
    const { permissions: userPermissions } = useSelector((state: RootState) => state.user);
    return useCallback(
        (requiredPermissions: string[]) => {
            return requiredPermissions.every((permission: string) => userPermissions?.includes(permission));
        },
        [userPermissions]
    );
};
