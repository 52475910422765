import { PolicyRequest } from 'types/PolicyRequest';
import { Company } from 'types/company';
import { OptionToQuote } from 'types/optionToQuote';

export enum QuotesSubBuckets {
    OPEN = 'Open',
    INCOMPLETE = 'Incomplete',
    PENDING = 'Pending',
    BIND_REQUEST = 'Bind_Request',
    INACTIVE = 'Inactive',
}

export interface BrokerDetails {
    id: number;
    initials: string;
}
export interface PolicyRequestRow {
    header: string;
    id: string;
    broker: BrokerDetails;
    optionsToQuote: OptionToQuote[];
}
export interface Client {
    clientName: string;
    company?: Partial<Company>;
    data: PolicyRequest[];
    num_of_quotes: number;
    policiesRequestRows: PolicyRequestRow[];
}

export enum PoliciesSubBuckets {
    ACTIVE = 'Active',
    RENEWING = 'Renewing',
    INACTIVE = 'Inactive',
}

export enum CompaniesBuckets {
    QUOTES = 'quotes',
    POLICIES = 'policies',
    RENEWALS = 'renewals',
}

export type BucketType = 'quotes' | 'policies' | 'renewals';
export type SubBucketType =
    | 'Open'
    | 'Incomplete'
    | 'Pending'
    | 'Bind_Request'
    | 'Inactive'
    | 'Active'
    | 'Renewing'
    | 'Rapid'
    | 'Manual'
    | 'Not_Renewing';
export type BucketSubBucketType =
    | 'quotesOpen'
    | 'quotesIncomplete'
    | 'quotesPending'
    | 'quotesBind_Request'
    | 'quotesInactive'
    | 'policiesActive'
    | 'policiesRenewing'
    | 'policiesInactive'
    | 'renewalsRapid'
    | 'renewalsManual'
    | 'renewalsNot_Renewing';
export type PaginationDetails = { currentPage: number; idsHistory: number[] };

export type State = Record<BucketSubBucketType, PaginationDetails>;
