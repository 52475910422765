import React, { FC, Fragment } from 'react';
import PolicyTableHeader from './PolicyTabHeader';
import PolicyTableRow from './PolicyTableRow';
import { PolicyPageTableDotDivider } from 'modules/company/pages/company/components/policy-requests-tab/components/quotes-are-ready/desktop-tablet/table-divider';
import CommissionTable from './CommissionTable';

import { Quote } from 'types/optionToQuote';

interface Props {
    quote: Quote;
    commissionPercentageString: string;
}

const FullTableDesktop: FC<Props> = ({ quote, commissionPercentageString }) => {
    const firstColSpan = 1,
        variant = 'subtitle2',
        subVariant = 'body2',
        firstColPadding = 7,
        subCoverageSpacing = 0.5;
    return (
        <Fragment>
            <PolicyTableHeader
                variant={variant}
                firstColSpan={firstColSpan}
                firstColPadding={firstColPadding}
                subCoverageSpacing={subCoverageSpacing}
                totalPremium={quote.total_premium}
            />
            {quote.premium !== quote.total_premium && (
                <>
                    <PolicyTableRow
                        variant={variant}
                        subVariant={subVariant}
                        firstColSpan={firstColSpan}
                        firstColPadding={firstColPadding}
                        subCoverageSpacing={subCoverageSpacing}
                        title={'Premium'}
                        value={quote.premium}
                    />
                    {quote.embedded_security_fee && (
                        <PolicyTableRow
                            variant={variant}
                            subVariant={subVariant}
                            firstColSpan={firstColSpan}
                            firstColPadding={firstColPadding}
                            subCoverageSpacing={subCoverageSpacing}
                            title={'Embedded Security'}
                            value={quote.embedded_security_fee}
                        />
                    )}
                </>
            )}
            <PolicyPageTableDotDivider />
            <CommissionTable
                variant={variant}
                firstColSpan={firstColSpan}
                firstColPadding={firstColPadding}
                commissionPercentageString={commissionPercentageString}
            />
        </Fragment>
    );
};

export default FullTableDesktop;
