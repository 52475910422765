import React from 'react';


const ProductText = () => {
    return (
        <>
            Renewal pending review
        </>
    )
}


export default ProductText;
