import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useBreakpoint } from 'hooks';

const Notice = ({ question }) => {
    const isSm = useBreakpoint('down', 'sm');

    return (
        <Box
            px={isSm ? 4 : 0}
            pt={isSm ? 0.5 : 1.5}
            pb={isSm ? 2 : 1}
            pl={isSm ? 4 : 17}
            pr={isSm ? 4 : 17}
            textAlign="left"
            lineHeight="15px"
            display="flex"
            justifyContent="start"
            key={`${question.id}_question`}>
            <Typography variant="body2" style={{ maxWidth: isSm ? 'unset' : '424px' }}>
                {question.question}
            </Typography>
        </Box>
    );
};

export default Notice;
