import React from 'react';
import { Typography } from '@material-ui/core';
import Styled from '../styled-components';


const CustomTextField = ({value, setValue, name, validationError, setValidationError, type='text'}) => {
    return (
        <Styled.TextField
            value={value}
            onChange={e => {
                setValidationError('');
                setValue(e.target.value);
            }}
            type={type}
            name={name}
            placeholder={`Enter ${name}`}
            required={true}
            label={
                value ? (
                    <Typography variant="body2" component="span">
                        Enter {name}
                    </Typography>
                ) : (
                    ''
                )
            }
            InputLabelProps={{
                shrink: true,
            }}
            error={Boolean(validationError)}
            helperText={validationError}
        />
    )
}

export default CustomTextField;
