import styled from 'styled-components';
import {
    TextField as _TextField,
    Typography as _Typography,
    Button as _Button,
    Container as _Container,
} from '@material-ui/core';

const FormContainer = styled(_Container)`
    &&& {
        max-width: 320px;
        box-sizing: content-box;

        .Mui-error {
            font-size: 12px;
        }

        ${({ theme }) => `
            ${theme.breakpoints.down('sm')} {
                margin-top: ${theme.spacing(4)}px;
            }            
        `}
    }
`;

const TextField = styled(_TextField)`
    &&& {
        width: 100%;
        margin-bottom: 35px;

        .MuiInputBase-input {
            font-size: 16px;

            ${({ theme }) => `
                ${theme.breakpoints.down('sm')} {
                    font-size: 14px;
                }            
            `}
        }

        ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightLight};
        `}

        .MuiInput-underline:before {
            border-bottom: 1px solid #e6e6e6;
        }
    }
`;

const FormHeader = styled(_Typography)`
    &&& {
        font-size: 24px;
        font-family: America;

        ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightBold};
            
            ${theme.breakpoints.up('md')} {
                font-size: 32px;
            }
        `}
    }
`;

const FormText = styled(_Typography)`
    &&& {
        ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightLight};
            margin-bottom: ${theme.spacing(3)}px;
        `}
    }
`;

const Button = styled(_Button)`
    &&& {
        ${({ theme }) => `
            color: ${theme.palette.primary.contrastText};
            background-color: ${theme.palette.primary.main};
            font-weight: ${theme.typography.fontWeightBold};
        `}
        
        width: 117px;
        height: 32px;
        margin-bottom: 46px;
        font-size: 16px;
        border-radius: 40px;
        text-transform: none;

        &[disabled] {
            background: #cccccc;
        }
    }
`;

const SignUpText = styled(_Typography)`
    &&& {
        color: #191919;

        ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightBold};
        `}
    }
`;

const SignUpLink = styled(_Typography)`
    &&& {
        ${({ theme }) => `
            margin-bottom: ${theme.spacing(3)}px;
            font-weight: ${theme.typography.fontWeightMedium};
        `}
    }
`;

const CancelButton = styled(_Button)`
    &&& {
        background-color: transparent;
        text-transform: none;

        ${({ theme }) => `
            color: ${theme.palette.primary.main};
            font-weight: ${theme.typography.fontWeightBold};
            font-size: ${theme.typography.subtitle2.fontSize};
        `}
    }
`;

const LogoContainer = styled.div`
    position: absolute;
    top: 20px;
    left: 32px;
`;

const InputLabel = styled.span`
    &&& {
        font-size: 12px;

        ${({ theme }) => `
            font-weight: ${theme.typography.fontWeightMedium};
        `}
    }
`;

const ProgressCircle = styled.div`
    display: inline-block;
    margin: 0 4px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: ${props => (props.active ? props.theme.palette.primary.main : '#cccccc')};

    ${({ theme }) => `
        color: ${theme.palette.primary.contrastText};
        font-size: ${theme.typography.body2.fontSize};
        font-weight: ${theme.typography.fontWeightBold};
    `}
`;

const ProgressCircleDivider = styled.div`
    display: inline-block;
    position: relative;
    width: 16px;
    height: 1px;
    background: #cccccc;
`;

const NewLocation = styled(_Typography)`
  &&& {
        ${({ theme }) => `
            margin-top: ${theme.spacing(3)}px;
            font-weight: ${theme.typography.fontWeightMedium};
        `}
    }
`;


export default {
    FormContainer,
    TextField,
    FormHeader,
    Button,
    SignUpText,
    SignUpLink,
    LogoContainer,
    InputLabel,
    CancelButton,
    FormText,
    ProgressCircle,
    ProgressCircleDivider,
    NewLocation,
};
