import { getPolicyRequestQuery } from './policyRequest';

export const generatedPacketQuery = {
    generated_documents: {
        document_config: {
            id: {},
            is_specimen: {},
            code: {},
            document_template_edition: {
                edition_name: {},
                document_template_name: {},
            },
        },
    },
};

export const getOptionToQuoteQuery = ({ includePolicyRequest }) => {
    const query = {
        id: {},
        pending: {},
        quote: {
            preferred: {},
            quote_builder_id: {},
            aggregate_limit: {},
            aggregate_retention: {},
            ...premiumQuery,
            annual_premium: {},
            per_claim_limit: {},
            is_split_limit: {},
            coverages: {},
            binder_generated_packet: generatedPacketQuery,
            policy_generated_packet: generatedPacketQuery,
            quote_generated_packet: generatedPacketQuery,
        },
        generating_binder_packet: {},
        generating_policy_packet: {},
        generating_quote_packet: {},
    };
    if (includePolicyRequest) {
        query.quote.policy_request = getPolicyRequestQuery({ includeOptionToQuotes: false });
    }
    return query;
};

export const premiumQuery = {
    premium: {},
    total_premium: {},
    total_annual_premium: {},
    embedded_security_fee: {},
    original_total_premium: {},
};
