import React, { useState } from 'react';
import { TableRow, Collapse, TableCell, Typography, Box, TableBody, Hidden } from '@material-ui/core';
import { useBreakpoint } from 'hooks';
import StyledTable from 'components/table/styled-components';
import Table from '@material-ui/core/Table';
import { useHistory } from 'react-router-dom';
import { QuotesSubBuckets, ProductsAbbreviatedName } from 'utils/enums';
import { displayClaimLimit } from 'utils/policyRequest';
import InnerTableOpenBucket from './InnerTableOpenBucket';
import { MainRowMobile, MainRowTabletDesktop } from 'components/table/QuotesSharedComponents';
import { getBrokerInitialsColor } from '../../utils';
import CompanyInfo from 'components/table/CompanyInfo';
import { useSelector } from 'react-redux';

const QuoteOpenRow = ({ client, companyId }) => {
    const [open, setOpen] = useState(false);
    var optionsToQuoteIdPerPolicy = {};
    for (const i in client.policiesRequestRows) {
        const policy_request_id = client.policiesRequestRows[i].id;
        optionsToQuoteIdPerPolicy[policy_request_id] = client.policiesRequestRows[i]?.optionsToQuote[0]?.id || null;
    }
    const [optionsToQuoteIdForPolicy, setOptionsToQuoteIdForPolicy] = useState(optionsToQuoteIdPerPolicy);
    const companyPolicies = client.data;
    const numOfPolicies = companyPolicies.length;
    const multiplePolicies = numOfPolicies > 1;
    const isSm = useBreakpoint('down', 'sm');
    const history = useHistory();
    const companyWithClaim = companyPolicies.find(companyPolicy => displayClaimLimit(companyPolicy));
    const { user } = useSelector(state => state.user);
    const teamMembersIds = user.team_members_ids;

    const handleButtonBar = buttonID => {
        const [dataType, action] = buttonID.split(':');
        switch (action) {
            case 'customize':
                history.push(`/submission/quote_customization/${dataType}`);
                break;
            case 'bind':
                history.push(`/submission/bind/${dataType}`);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Hidden smDown>
                <MainRowTabletDesktop
                    multiplePolicies={multiplePolicies}
                    setOpen={setOpen}
                    open={open}
                    numOfPolicies={numOfPolicies}
                    client={client}
                    companyPolicies={companyPolicies}
                    companyId={companyId}
                />
            </Hidden>
            <Hidden mdUp>
                <MainRowMobile
                    multiplePolicies={multiplePolicies}
                    setOpen={setOpen}
                    open={open}
                    numOfPolicies={numOfPolicies}
                    client={client}
                    companyPolicies={companyPolicies}
                    companyId={companyId}
                />
            </Hidden>
            <TableRow>
                <TableCell
                    style={{
                        padding: 0,
                        borderBottom: 'none',
                        backgroundColor: open ? '#F8F8F8' : '#F2F2F2',
                        borderTop: open && client.policiesRequestRows ? '1px solid #F2F2F2' : 'none',
                    }}
                    colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {client.policiesRequestRows &&
                            client.policiesRequestRows.map((policyRequest, index) => (
                                <Box
                                    key={`inner_row_${policyRequest.id}_${companyId}`}
                                    borderBottom="2px solid #f2f2f2"
                                    my={2}
                                    px={4}
                                    fontWeight="fontWeightBold"
                                    mb={0}
                                    pb={2}>
                                    <Box
                                        border="none"
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                        gridGap={12}>
                                        <StyledTable.Avatar
                                            hidden={!policyRequest?.broker?.initials}
                                            style={{
                                                backgroundColor: getBrokerInitialsColor(
                                                    policyRequest?.broker,
                                                    teamMembersIds,
                                                ),
                                                height: !isSm && '32px',
                                                width: !isSm && '32px',
                                                fontSize: !isSm && '14px',
                                            }}>
                                            {policyRequest?.broker?.initials}
                                        </StyledTable.Avatar>
                                        <Typography variant="subtitle2" component="div">
                                            {policyRequest.header}
                                        </Typography>
                                    </Box>
                                    <Hidden mdUp>
                                        {policyRequest.optionsToQuote.length > 1 && (
                                            <Box display="flex" pt={1} borderBottom="1px solid #e6e6e6">
                                                {policyRequest.optionsToQuote.map(optionToQuoteData => (
                                                    <StyledTable.SmBox
                                                        align="left"
                                                        className="inner"
                                                        color={
                                                            optionsToQuoteIdForPolicy[policyRequest.id] ===
                                                            optionToQuoteData.id
                                                                ? '#191919'
                                                                : '#919191'
                                                        }
                                                        mr={2}
                                                        checked={
                                                            optionsToQuoteIdForPolicy[policyRequest.id] ===
                                                            optionToQuoteData.id
                                                        }
                                                        onClick={() => {
                                                            let copyOptionIdForPolicy = {
                                                                ...optionsToQuoteIdForPolicy,
                                                            };
                                                            copyOptionIdForPolicy[policyRequest.id] =
                                                                optionToQuoteData.id;
                                                            setOptionsToQuoteIdForPolicy(copyOptionIdForPolicy);
                                                        }}
                                                        key={`sm_inner_header_${optionToQuoteData.id}`}
                                                        fontWeight="fontWeightLight">
                                                        <Typography variant="subtitle2">
                                                            {optionToQuoteData.limit}
                                                        </Typography>
                                                    </StyledTable.SmBox>
                                                ))}
                                            </Box>
                                        )}
                                    </Hidden>
                                    <Table size="small">
                                        <StyledTable.TableHead className="inner">
                                            {!isSm && (
                                                <StyledTable.TableRow bottom="1px solid #F2F2F2" _bordertop="0" />
                                            )}
                                            <StyledTable.TableRow
                                                _bordertop={
                                                    isSm && policyRequest.optionsToQuote.length > 1
                                                        ? '1px solid #F2F2F2'
                                                        : 'none'
                                                }>
                                                {displayClaimLimit(companyPolicies[index]) ? (
                                                    <>
                                                        <StyledTable.TableCellHead
                                                            className="inner"
                                                            width={isSm ? '33%' : '12%'}>
                                                            <Typography variant="overline">TOTAL</Typography>
                                                        </StyledTable.TableCellHead>
                                                        <StyledTable.TableCellHead
                                                            className="inner"
                                                            width={isSm ? '33%' : '20%'}>
                                                            <Typography variant="overline">
                                                                AG. / CLAIM LIMIT
                                                            </Typography>
                                                        </StyledTable.TableCellHead>
                                                        <StyledTable.TableCellHead
                                                            className="inner"
                                                            width={isSm ? '33%' : '12%'}>
                                                            <Typography variant="overline">RETENTION</Typography>
                                                        </StyledTable.TableCellHead>
                                                    </>
                                                ) : (
                                                    <>
                                                        <StyledTable.TableCellHead
                                                            className="inner"
                                                            width={isSm ? '33%' : companyWithClaim ? '12%' : '15%'}>
                                                            <Typography variant="overline">TOTAL</Typography>
                                                        </StyledTable.TableCellHead>
                                                        <StyledTable.TableCellHead
                                                            className="inner"
                                                            width={isSm ? '33%' : companyWithClaim ? '20%' : '15%'}>
                                                            <Typography variant="overline">AG. LIMIT</Typography>
                                                        </StyledTable.TableCellHead>
                                                        <StyledTable.TableCellHead
                                                            className="inner"
                                                            width={isSm ? '33%' : companyWithClaim ? '12%' : '15%'}>
                                                            <Typography variant="overline">RETENTION</Typography>
                                                        </StyledTable.TableCellHead>
                                                    </>
                                                )}
                                                {isSm ? (
                                                    <StyledTable.TableCellHead className="inner" width="0%">
                                                        <Typography variant="overline" />
                                                    </StyledTable.TableCellHead>
                                                ) : (
                                                    <StyledTable.TableCellHead className="inner" width="56%" />
                                                )}
                                            </StyledTable.TableRow>
                                        </StyledTable.TableHead>
                                        <TableBody>
                                            {policyRequest.optionsToQuote.map(optionToQuoteData => (
                                                <InnerTableOpenBucket
                                                    key={`inner_${QuotesSubBuckets.OPEN}_data_${optionToQuoteData.id}`}
                                                    optionToQuoteData={optionToQuoteData}
                                                    policyRequest={companyPolicies[index]}
                                                    handleButton={
                                                        companyPolicies[index].product?.abbreviated_name !==
                                                        ProductsAbbreviatedName.MPL
                                                            ? handleButtonBar
                                                            : () =>
                                                                  history.push(
                                                                      `/submission/bind/${optionToQuoteData.id}`,
                                                                  )
                                                    }
                                                    optionToQuoteId={optionsToQuoteIdForPolicy[policyRequest.id]}
                                                />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            ))}
                        <CompanyInfo client={client} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default QuoteOpenRow;
