import React, { FC, ReactElement } from 'react';
import { BucketSubBucketType, BucketType, Client, State, SubBucketType } from './types';
import { PolicyAndRenewalsTableHead } from 'components/table/PoliciesAndRenewalsSharedComponents';
import { QuoteTableHead } from 'components/table/QuotesSharedComponents';
import QuoteOpenRow from './list-items/open-quotes/QuoteOpenRow';
import QuoteIncompleteRow from './list-items/incomplete-quotes/QuoteIncompleteRow';
import QuotePendingRow from './list-items/pending-quotes/QuotePendingRow';
import QuoteBindRequestRow from './list-items/bind-request-quotes/QuoteBindRequestRow';
import QuoteInactiveRow from './list-items/inactive-quotes/QuoteInactiveRow';
import PolicyActiveRow from './list-items/active-policies/PolicyActiveRow';
import PolicyRenewingRow from './list-items/renewing-policies/PolicyRenewingRow';
import PolicyInactiveRow from './list-items/inactive-policies/PolicyInactiveRow';
import RenewalRow from './list-items/all-renewals/RenewalRow';
import { ReactComponent as ClipboardClosedIcon } from 'components/icons/clipboard-closed.svg';
import { isAxa } from 'components/icons/logo';

export const FIRST_PAGE = 1;
export const FIRST_QUERY_ID = 0;

export const subBucketToEmptyTextMap: Record<
    BucketSubBucketType,
    {
        title: string;
        subTitle: string;
        text?: string;
        icon?: ReactElement;
    }
> = {
    quotesOpen: {
        title: 'No Open Quotes',
        subTitle:
            'Your open quotes will automatically show up here. Start a new quote any time—they are often available within seconds.',
    },
    quotesIncomplete: {
        title: 'No Incomplete Applications',
        subTitle:
            'Any quotes with incomplete applications will appear here. Simply complete the application to get the quote.',
    },
    quotesPending: {
        title: 'No Pending Applications',
        subTitle:
            'Quotes that are under review with our underwriters appear here. Contact them for further instructions.',
    },
    quotesBind_Request: {
        title: 'No Bind Requests',
        subTitle:
            'Our underwriters are reviewing your request to bind this quote. Contact your underwriter for further information.',
    },
    quotesInactive: {
        title: 'No Inactive Quotes',
        subTitle:
            'Any declined or expired quotes will automatically appear here. View client details page for more information.',
    },
    policiesActive: {
        title: 'No Active Policies',
        subTitle:
            'Your active policies will appear here. Start a new quote any time—they are often available within an hour.',
    },
    policiesRenewing: {
        title: 'No Renewals',
        subTitle:
            'Renewing policies will appear here when they are within 120 days of renewing.\n' +
            '                                You’ll also be able to see which are rapid, and which are manual.',
    },
    policiesInactive: {
        title: 'No Inactive Policies',
        subTitle: 'Any policies that are declined or cancelled will automatically appear here.',
    },
    renewalsRapid: {
        title: 'No policies up rapidly renewing yet!',
        subTitle: 'keep track of all your rapidly renewing policies here!',
        text:
            'Rapid renewals will automatically bind 15 days before your policy expires, unless you report any exposure changes. If you need to submit a renewal application, you’ll be able to do so through your At-Bay broker platform account - just don’t forget to bind your renewal quote before the policy expires!',
        icon: <ClipboardClosedIcon />,
    },
    renewalsManual: {
        title: 'No manually renewing policies in progress!',
        subTitle: 'keep track of all your manual renewals here!',
        text:
            'Manual renewals require an updated application and an At-Bay underwriter to review. You’ll be able to submit renewal applications and bind renewal quotes through your At-Bay broker platform.',
        icon: <ClipboardClosedIcon />,
    },
    renewalsNot_Renewing: {
        title: 'No non-renewing policies',
        subTitle: 'You currently don’t have any non-renewing policies.',
        icon: <ClipboardClosedIcon />,
    },
};
export const bucketToHeaderComponentMap: Record<BucketType, FC<{ subBucket: SubBucketType }>> = {
    policies: PolicyAndRenewalsTableHead,
    quotes: QuoteTableHead,
    renewals: PolicyAndRenewalsTableHead,
};
export const subBucketToRowComponentMap: Record<
    BucketSubBucketType,
    FC<{ client: Client; companyId: number | undefined; subBucket: SubBucketType }>
> = {
    quotesOpen: QuoteOpenRow,
    quotesIncomplete: QuoteIncompleteRow,
    quotesPending: QuotePendingRow,
    quotesBind_Request: QuoteBindRequestRow,
    quotesInactive: QuoteInactiveRow,
    policiesActive: PolicyActiveRow,
    policiesRenewing: PolicyRenewingRow,
    policiesInactive: PolicyInactiveRow,
    renewalsRapid: RenewalRow,
    renewalsManual: RenewalRow,
    renewalsNot_Renewing: RenewalRow,
};
export const initialState: State = {
    quotesOpen: { currentPage: FIRST_PAGE, idsHistory: [FIRST_QUERY_ID] },
    quotesIncomplete: { currentPage: FIRST_PAGE, idsHistory: [FIRST_QUERY_ID] },
    quotesPending: { currentPage: FIRST_PAGE, idsHistory: [FIRST_QUERY_ID] },
    quotesBind_Request: { currentPage: FIRST_PAGE, idsHistory: [FIRST_QUERY_ID] },
    quotesInactive: { currentPage: FIRST_PAGE, idsHistory: [FIRST_QUERY_ID] },
    policiesActive: { currentPage: FIRST_PAGE, idsHistory: [FIRST_QUERY_ID] },
    policiesRenewing: { currentPage: FIRST_PAGE, idsHistory: [FIRST_QUERY_ID] },
    policiesInactive: { currentPage: FIRST_PAGE, idsHistory: [FIRST_QUERY_ID] },
    renewalsRapid: {
        currentPage: FIRST_PAGE,
        idsHistory: [FIRST_QUERY_ID],
    },
    renewalsManual: {
        currentPage: FIRST_PAGE,
        idsHistory: [FIRST_QUERY_ID],
    },
    renewalsNot_Renewing: {
        currentPage: FIRST_PAGE,
        idsHistory: [FIRST_QUERY_ID],
    },
};
export const brokerInitialsColors = [
    '#3C64FF',
    isAxa ? '#00008F' : '#2020EE',
    '#00005F',
    '#42EAF5',
    '#1FBDC6',
    '#148D92',
    '#4C4C4C',
    '#191919',
    '#73AAFF',
    '#09109E',
    '#B2FDFF',
    '#FFFF00',
    '#FFEB00',
    '#EADB03',
    '#C8B702',
];
