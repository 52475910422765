import React, { useEffect, useState, useRef, useMemo } from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Box, Typography, FormControl } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GeneralStyled from '../../../components/styled-components';
import Styled from './styled-components';
import Markdown from 'markdown-to-jsx';
import InfoTooltip from '../../../components/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setQuestionValue } from 'stores/questions/questionsSlice';
import { questionHasError, scrollToQuestionError } from 'utils/questionnaireSequence';
import { useBreakpoint, useIsFirstRender, useIsMounted, useDebounceCallback } from 'hooks';
import { setNewQuestionData } from 'stores/questionsErrors/questionsErrorsSlice';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { handleMultipleChoiceWithSearchBarRenderOption } from 'modules/questionnaire/pages/new-questionnaire/utils';

const filterOptions = createFilterOptions();

const MultipleChoicesWithSearchBarQuestion = ({
    question,
    updateForm,
    firstInSection,
    setScroll,
    scroll,
    setChangeMade,
}) => {
    const questionRef = useRef(null);
    const isSm = useBreakpoint('down', 'sm');
    const dispatch = useDispatch();
    const answer = useSelector(state => state.questions?.questionsToAnswers[question.id]);
    const { firstQuestionWithError, submitFailed } = useSelector(state => state.questionErrors);
    const [open, setOpen] = useState(false);
    const [textError, setTextError] = useState(false);
    const [multiSelectValue, setMultiSelectValue] = useState(
        question.optional_answers.filter(optional_answer => {
            return answer ? answer.includes(optional_answer.id) : false;
        }),
    );
    const questionMetaData = useSelector(
        state => state.questionnaire.questionsMetadata.find(item => item.questionId === question.id)?.metadata,
    );

    const isMounted = useIsMounted();

    const isFirstRendered = useIsFirstRender();

    const updateNewMultiSelectValue = () => {
        let currentChoicesIds = multiSelectValue.map(obj => obj.id);
        dispatch(setQuestionValue({ name: question.id, value: currentChoicesIds }));
        if (!isFirstRendered) {
            updateForm({
                questionId: question.id,
                answerId: currentChoicesIds,
                answerType: question.optional_answers[0].type,
                value: currentChoicesIds,
                invalid: false,
                required: question.required,
                questionType: question.type,
            });
        }
    };
    const updateMultiSelectValueWithDebounce = useDebounceCallback(updateNewMultiSelectValue, 500, [multiSelectValue]);

    useEffect(() => {
        updateMultiSelectValueWithDebounce();
    }, [multiSelectValue]);

    const options_alphabetic = useMemo(
        () => [...question.optional_answers].sort((a, b) => a.display_name.localeCompare(b.display_name)),
        [question.optional_answers],
    );

    useEffect(() => {
        dispatch(
            setNewQuestionData({
                questionId: question.id,
                hasError: questionHasError(submitFailed, answer, question.required, true) || textError,
            }),
        );
    }, [submitFailed]);

    useEffect(() => {
        if (scroll && firstQuestionWithError === question.id) {
            scrollToQuestionError(questionRef);
            setScroll(false);
        }
        if (!scroll) {
            setTextError(questionHasError(submitFailed, answer, question.required, true));
        }
    }, [firstQuestionWithError, scroll]);

    useEffect(() => {
        dispatch(
            setNewQuestionData({
                questionId: question.id,
                hasError: questionHasError(submitFailed, answer, question.required, true) || textError,
            }),
        );
    }, []);

    const handleFilterOptions = (options, state) => {
        const results = filterOptions(options, state);
        setTextError(results.length === 0);
        return results;
    };

    const onDeleteAutocomplete = id => {
        setMultiSelectValue(value => value.filter(v => v.id !== id));
    };

    const handleOnChange = (e, newValue) => {
        setMultiSelectValue(newValue);
        setChangeMade(true);
    };

    return (
        <Box
            ref={questionRef}
            display="flex"
            px={isSm ? 4 : 0}
            key={`${question.id}_question`}
            pb={0.5}
            justifyContent="center">
            <FormControl fullWidth style={{ maxWidth: isSm ? 'unset' : '424px' }}>
                <Box
                    component={GeneralStyled.FormLabel}
                    display="flex"
                    alignItems="center"
                    className={firstInSection ? 'nospace' : null}>
                    <Typography variant="body1">
                        <Markdown>{(question.required ? '' : '(Optional) ') + question.question}</Markdown>
                        {question.application_parameters.help_text && (
                            <InfoTooltip
                                dataTrack={`questionnaire_tooltip_${question.id}`}
                                placement="bottom"
                                open={open}
                                setOpen={setOpen}
                                text={question.application_parameters.help_text}
                                select={true}
                            />
                        )}
                    </Typography>
                </Box>
                <Autocomplete
                    PopperComponent={Styled.MultipleChoicesWithSearchBarQuestionPopper}
                    multiple
                    disableClearable
                    filterSelectedOptions
                    options={options_alphabetic}
                    getOptionLabel={option => option.display_name}
                    onChange={handleOnChange}
                    filterOptions={handleFilterOptions}
                    renderOption={handleMultipleChoiceWithSearchBarRenderOption}
                    value={multiSelectValue}
                    getOptionSelected={(option, value) => {
                        return option.id === value.id;
                    }}
                    renderTags={() => null}
                    popupIcon={<KeyboardArrowDownIcon />}
                    renderInput={params => (
                        // TODO replace with variables instead of hard coding
                        <GeneralStyled.TextField
                            {...params}
                            variant="standard"
                            error={textError}
                            helperText={textError ? 'Service does not exist.' : ''}
                            placeholder="Type or select from dropdown"
                        />
                    )}
                />
                <Box mt={1} textAlign="left" fontWeight="fontWeightMedium">
                    {multiSelectValue.map(selectedValue => (
                        <Typography variant="body1" display="inline">
                            <Styled.Chip
                                key={selectedValue.id}
                                label={selectedValue.display_name}
                                size="small"
                                deleteIcon={<CloseIcon />}
                                onDelete={() => onDeleteAutocomplete(selectedValue.id)}
                            />
                        </Typography>
                    ))}
                    {questionMetaData?.savingQuestion && isMounted() && (
                        <Styled.MultipleChoicesWithSearchBarCircularProgress size={18} />
                    )}
                </Box>
            </FormControl>
        </Box>
    );
};

export default MultipleChoicesWithSearchBarQuestion;
