import React, {useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {totalHumanize} from 'utils';
import {getRelevantPacketTemplateType, displayClaimLimit} from 'utils/policyRequest';
import {useBreakpoint} from 'hooks';
import { setOptionToQuote, setOptionToQuoteAttributes } from 'stores/company/companySlice';
import {
    Icon,
    IconBox,
    StyledTable,
    StyledAction,
    StyledTypography,
} from 'components';
import {CoveragesTable} from 'components/quote';
import {DocumentMonitor} from 'components/option-to-quote';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import accounting from 'accounting';
import {Box, Hidden, TableRow, Typography} from '@material-ui/core';
import BindAndCustomizeButton from '../BindAndCustomizeButton';
import {CommissionDetails} from './commission-details';
import {TableDivider} from './table-divider';
import {DocumentsDetails} from './documents-details';


const OptionToQuote = ({optionToQuote, policyRequest, index}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMd = useBreakpoint('down', 'md');
    const dispatchOptionToQuoteAttribute = (attribute, value) => {
        dispatch(setOptionToQuoteAttributes({
            policyRequestID: policyRequest.id,
            optionToQuoteID: optionToQuote.id,
            attributes: [
                {
                    name: attribute,
                    value: value,
                }
            ]
        }))
    }
    const packetTemplateType = getRelevantPacketTemplateType(policyRequest);
    const generatedPacket = optionToQuote.quote[`${packetTemplateType}_generated_packet`];
    const TypographyComponent = optionToQuote.pending ? StyledTypography.FadedTypography : Typography;
    const hasSubCoverages = useMemo(() => {
        for (const coverage of (optionToQuote?.quote?.coverages?.line_items || [])) {
            if (coverage.children.length > 0) {
                return true;
            }
        }
    }, [optionToQuote?.quote?.coverages?.line_items])
    const handleButtonBar = buttonID => {
        switch (buttonID) {
            case 'bind':
                dispatch(setOptionToQuote({
                    ...optionToQuote,
                    quote: {
                        ...optionToQuote.quote,
                        policy_request: policyRequest
                    }
                }));
                history.push(`/submission/bind/${optionToQuote.id}`);
                break;
            case 'customize':
                history.push(`/submission/quote_customization/${optionToQuote.id}`);
                break;
            default:
                throw new Error('Invalid button ID');
        }
    }
    const handleButton = () => {
        dispatch(setOptionToQuote({
            ...optionToQuote,
            quote: {
                ...optionToQuote.quote,
                policy_request: policyRequest
            }
        }));
        history.push(`/submission/bind/${optionToQuote.id}`);
    }

    return (
        <>
            <DocumentMonitor
                optionToQuote={optionToQuote}
                policyRequest={policyRequest}
                monitor={optionToQuote.expanded && !generatedPacket} />
            <TableRow style={{ cursor: 'pointer'}} onClick={() => dispatchOptionToQuoteAttribute('expanded', !optionToQuote.expanded)}>
                <Hidden mdDown>
                    <Box component={StyledTable.TableCell} className="py-1">
                        <TypographyComponent variant="subtitle2">
                            Option {index + 1}
                        </TypographyComponent>
                    </Box>
                    <Box component={StyledTable.TableCell} className="py-1">
                        <Box component={TypographyComponent} fontWeight={optionToQuote.pending ? 'fontWeightLight': 'fontWeightMedium'} variant="subtitle2">
                            {optionToQuote.pending ? (
                                'Pending'
                            ) : (
                                `$${accounting.formatNumber(optionToQuote.quote.premium)}`
                            )}
                        </Box>
                    </Box>
                </Hidden>
                <Hidden lgUp>
                    <StyledTable.TableCell className="py-1">
                        <Box component={props => <TypographyComponent {...props} variant="subtitle2" component="span" />}
                            fontWeight="fontWeightLight">
                            Option {index + 1}{' / '}
                        </Box>
                        <Box component={props => <TypographyComponent {...props} variant="subtitle2" component="span" />}
                            fontWeight={optionToQuote.pending ? 'fontWeightLight': 'fontWeightMedium'}>
                            {optionToQuote.pending ? (
                                'Pending'
                            ) : (
                                `$${accounting.formatNumber(optionToQuote.quote.premium)}`
                            )}
                        </Box>
                    </StyledTable.TableCell>
                </Hidden>
                <StyledTable.TableCell className="py-1">
                    <TypographyComponent variant="subtitle2">
                        {displayClaimLimit(policyRequest, optionToQuote) ? (
                            `$${totalHumanize(optionToQuote.quote.aggregate_limit)} / $${totalHumanize(optionToQuote.quote.per_claim_limit)}`
                        ) : (
                            `$${totalHumanize(optionToQuote.quote.aggregate_limit)}`
                        )}
                    </TypographyComponent>
                </StyledTable.TableCell>
                <StyledTable.TableCell className="py-1">
                    <TypographyComponent variant="subtitle2">
                        ${totalHumanize(optionToQuote.quote.aggregate_retention)}
                    </TypographyComponent>
                </StyledTable.TableCell>
                {!optionToQuote.pending && (
                    <>
                        <StyledTable.TableCell className="py-1" align="right">
                            <StyledAction.Base onClick={() => dispatchOptionToQuoteAttribute('expanded', !optionToQuote.expanded)}>
                                <Box align="right">
                                    <IconBox sizes="32px" bgcolor="rgba(32, 32, 238, 0.1)" borderRadius="50%">
                                        <Icon icon={optionToQuote.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            typographyProps={{ variant: 'h6', color: 'primary' }}
                                        />
                                    </IconBox>
                                </Box>
                            </StyledAction.Base>
                        </StyledTable.TableCell>
                        <StyledTable.TableCell className="py-1" align="right">
                            <BindAndCustomizeButton
                                productName={policyRequest.product.abbreviated_name}
                                handleButton={handleButton}
                                handleButtonBar={handleButtonBar}
                                uncheckedPreBindContingencies={policyRequest.unchecked_pre_bind_contingencies_count}
                            />
                        </StyledTable.TableCell>
                    </>
                )}
            </TableRow>
            {!optionToQuote.pending && optionToQuote.expanded && (
                <>
                    {hasSubCoverages && (
                        <TableRow>
                            <StyledTable.TableCell colSpan={isMd ? 5 : 6}>
                                <Box component={StyledAction.ExpandableRowControl}
                                    display="flex"
                                    alignItems="center"
                                    onClick={() => dispatchOptionToQuoteAttribute('coveragesToggled', !optionToQuote.coveragesToggled)}
                                    fontWeight="fontWeightLight">
                                    <StyledTypography.FadedTypography variant="subtitle1">
                                        <Box mr={2} display="flex">
                                            {optionToQuote.coveragesToggled ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </Box>
                                    </StyledTypography.FadedTypography>
                                    <StyledTypography.FadedTypography variant="body2" align="justify">
                                        {optionToQuote.coveragesToggled ? 'Hide' : 'Review'} coverage details
                                    </StyledTypography.FadedTypography>
                                </Box>
                            </StyledTable.TableCell>
                        </TableRow>
                    )}
                    <CoveragesTable
                        quote={optionToQuote.quote}
                        firstColSpan={isMd ? 1 : 2}
                        numOfExtraCols={2}
                        variant="body2"
                        subCoverageSpacing={1}
                        childrenToggled={optionToQuote.coveragesToggled || false}
                    />
                    <TableDivider/>
                    <DocumentsDetails optionToQuote={optionToQuote} policyRequest={policyRequest}/>
                    <TableDivider/>
                    <CommissionDetails commission={policyRequest.commission}/>
                    <TableDivider/>

                </>
            )}
        </>
    );
}


export default OptionToQuote;
