import styled from 'styled-components';


const Base = styled.span`
    &&& {
        &:hover {
            cursor: pointer;
        }
        &.disabled:hover {
            cursor: default;
        }
    }
`;

const ExpandableRowControl = styled(Base)`
    &&& {
        &:hover {
            * {
                opacity: 1;
            }
        }
    }
`;

export default {
    Base,
    ExpandableRowControl,
};
