import React from 'react';
import { StyledTypography } from 'components';
import { Box } from '@material-ui/core';
import { formatDatePickerDate } from 'utils';
import { SubmissionsBucket } from 'utils/enums';


const Actions = ({ policyRequest }) => {
    const numberOfUncheckedContingencies = policyRequest.unchecked_post_bind_contingencies_count + policyRequest.unchecked_pre_bind_contingencies_count;

    return (
        <StyledTypography.SecondaryFadedTypography variant="subtitle2">
            {policyRequest.athena_submissions_bucket === SubmissionsBucket.BIND_REQUESTED ? (
                'All outstanding contingencies must be fulfilled within 30 days from issuance of the binder and validated in order to receive the policy.'
            ) : policyRequest.conditionally_bound ? (
                numberOfUncheckedContingencies ?
                    <>Please complete {policyRequest.unchecked_post_bind_contingencies_count} contingency requirements to receive the policy.</>
                    :
                    <>Policy issuance is in progress and documents will be available shortly</>
            ) : (
                <Box>
                    This policy has been bound and will be&nbsp;
                    <Box component="span" fontWeight="500">
                        effective on {formatDatePickerDate(policyRequest.effective_date)}
                    </Box>
                </Box>
            )}
        </StyledTypography.SecondaryFadedTypography>
    )
}


export default Actions;
