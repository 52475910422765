/* eslint-disable no-unused-vars */
import React from 'react';
import './App.css';
import MainPage from 'modules/MainPage';
import { BaseWrapper } from 'components';

// import i18n needs to be bundled: https://react.i18next.com/latest/using-with-hooks
import i18n from 'i18n/i18n';


const App = () => {
    return (
        <BaseWrapper>
            <MainPage />
        </BaseWrapper>
    );
}

export default App;
