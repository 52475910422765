import React from 'react';
import {default as BaseMobile} from 'modules/company/pages/company/components/policy-requests-tab/components/quotes-are-ready/mobile/index'


const Mobile = ({ policyRequest }) => {
    return <BaseMobile policyRequest={policyRequest}/>;
}


export default Mobile;
