import { createSelector } from 'reselect';
import {Quote} from '../types/optionToQuote';

const getPremium = (quote: Quote) => quote.premium;
const getAnnualPremium = (quote: Quote) => quote.annual_premium;

export const getIsAnnualPolicy = createSelector(
    getPremium,
    getAnnualPremium,
    (premium, annualPremium) => premium === annualPremium
);

