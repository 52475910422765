import React, { useState } from 'react';
import { TableRow, Collapse, TableCell, Typography, Box, TableBody, Hidden } from '@material-ui/core';
import CompanyInfo from 'components/table/CompanyInfo';
import { useBreakpoint } from 'hooks';
import StyledTable from 'components/table/styled-components';
import { MainRowTabletDesktop, MainRowMobile } from 'components/table/PoliciesAndRenewalsSharedComponents';
import Table from '@material-ui/core/Table';
import { CompaniesBuckets, PoliciesSubBuckets } from 'utils/enums';
import InnerTableInactiveBucket from './InnerTableInactiveBucket';
import { getBrokerInitialsColor } from '../../utils';
import { useSelector } from 'react-redux';

const PolicyInactiveRow = ({ client, companyId }) => {
    const [open, setOpen] = useState(false);
    const companyPolicies = client.data;
    const numOfPolicies = companyPolicies.length;
    const multiplePolicies = numOfPolicies > 1;
    const isSm = useBreakpoint('down', 'sm');
    const { user } = useSelector(state => state.user);
    const teamMembersIds = user.team_members_ids;
    return (
        <>
            <Hidden smDown>
                <MainRowTabletDesktop
                    multiplePolicies={multiplePolicies}
                    setOpen={setOpen}
                    open={open}
                    numOfPolicies={numOfPolicies}
                    client={client}
                    companyPolicies={companyPolicies}
                    companyId={companyId}
                    bucket={CompaniesBuckets.POLICIES}
                />
            </Hidden>
            <Hidden mdUp>
                <MainRowMobile
                    multiplePolicies={multiplePolicies}
                    setOpen={setOpen}
                    open={open}
                    numOfPolicies={numOfPolicies}
                    client={client}
                    companyPolicies={companyPolicies}
                    companyId={companyId}
                    bucket={CompaniesBuckets.POLICIES}
                />
            </Hidden>
            <TableRow>
                <TableCell
                    style={{
                        padding: 0,
                        borderBottom: 'none',
                        backgroundColor: open ? '#F8F8F8' : '#F2F2F2',
                        borderTop: open && client.policiesRequestRows ? '1px solid #F2F2F2' : 'none',
                    }}
                    colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {client.policiesRequestRows &&
                            client.policiesRequestRows.map((policyRequest, index) => (
                                <Box
                                    key={`inner_row_${policyRequest.id}_${companyId}`}
                                    borderBottom="2px solid #f2f2f2"
                                    my={2}
                                    px={4}
                                    fontWeight="fontWeightBold"
                                    mb={0}
                                    pb={2}>
                                    <Box
                                        border="none"
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                        gridGap={12}>
                                        <StyledTable.Avatar
                                            hidden={!policyRequest?.broker?.initials}
                                            style={{
                                                backgroundColor: getBrokerInitialsColor(
                                                    policyRequest?.broker,
                                                    teamMembersIds,
                                                ),
                                                height: !isSm && '32px',
                                                width: !isSm && '32px',
                                                fontSize: !isSm && '14px',
                                            }}>
                                            {policyRequest?.broker?.initials}
                                        </StyledTable.Avatar>
                                        <Typography variant="subtitle2" gutterBottom component="div">
                                            {policyRequest.header}
                                        </Typography>
                                    </Box>
                                    <Hidden mdUp>
                                        <StyledTable.TableRow _bordertop="0">
                                            <StyledTable.TableCell width="100%" style={{ backgroundColor: '#F8F8F8' }}>
                                                <Typography variant="subtitle2">
                                                    {policyRequest.policy_period}
                                                </Typography>
                                            </StyledTable.TableCell>
                                        </StyledTable.TableRow>
                                    </Hidden>
                                    <Table size="small">
                                        <StyledTable.TableHead className="inner">
                                            {!isSm && (
                                                <StyledTable.TableRow bottom="1px solid #F2F2F2" _bordertop="0" />
                                            )}
                                            <StyledTable.TableRow
                                                _bordertop={
                                                    isSm && policyRequest.optionsToQuote.length > 1
                                                        ? '1px solid #F2F2F2'
                                                        : 'none'
                                                }>
                                                <StyledTable.TableCellHead
                                                    className="inner"
                                                    width={isSm ? '33%' : '15%'}>
                                                    <Typography variant="overline">TOTAL</Typography>
                                                </StyledTable.TableCellHead>
                                                <StyledTable.TableCellHead
                                                    className="inner"
                                                    width={isSm ? '33%' : '15%'}>
                                                    <Typography variant="overline">AG. LIMIT</Typography>
                                                </StyledTable.TableCellHead>
                                                <StyledTable.TableCellHead
                                                    className="inner"
                                                    width={isSm ? '33%' : '15%'}>
                                                    <Typography variant="overline">RETENTION</Typography>
                                                </StyledTable.TableCellHead>
                                                {isSm ? (
                                                    <StyledTable.TableCellHead className="inner" width="0%">
                                                        <Typography variant="overline" />
                                                    </StyledTable.TableCellHead>
                                                ) : (
                                                    <StyledTable.TableCellHead className="inner" width="55%" />
                                                )}
                                            </StyledTable.TableRow>
                                        </StyledTable.TableHead>
                                        <TableBody>
                                            {policyRequest.optionsToQuote.map(optionToQuoteData => (
                                                <InnerTableInactiveBucket
                                                    key={`inner_${PoliciesSubBuckets.INACTIVE}_data_${optionToQuoteData.id}`}
                                                    optionToQuoteData={optionToQuoteData}
                                                    policyRequest={companyPolicies[index]}
                                                />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            ))}
                        <CompanyInfo client={client} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default PolicyInactiveRow;
