import React from 'react';
import { useHistory } from 'react-router-dom';
import Styled from './styled-components';
import { IFrame } from 'components';
import { useEnv } from 'hooks';

const BrokerValidateDialog = ({ open, handleClose, brokerId }) => {
    const history = useHistory();
    const { athenaHost } = useEnv();

    const navigateToPendingUsers = () => {
        handleClose();
        history.push('/admin/pending_users');
    };

    return (
        <Styled.WideDialog open={open} onClose={navigateToPendingUsers} fullWidth>
            <Styled.WideDialogContent style={{ height: 950, width: '100%' }}>
                <IFrame src={`${athenaHost}/athena/views/update_broker/${brokerId}`} />
            </Styled.WideDialogContent>
        </Styled.WideDialog>
    );
};

export default BrokerValidateDialog;
