import React, { useEffect, useState } from 'react';
import * as FS from '@fullstory/browser';
import { useParams, useHistory } from 'react-router-dom';
import { useAxiosClient, useBreakpoint } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setShowHeader, setShowFooter } from 'stores/footer/footerSlice';
import {
    setOptionToQuote,
    setCustomizedOptionToQuote,
    setCustomizedOptionToQuoteAttributes,
    setCustomizationLoading,
    resetCustomizationLoadingState,
} from 'stores/company/companySlice';
import { Close as CloseIcon } from '@material-ui/icons';
import { getOptionToQuoteQuery } from 'queries/optionToQuote';
import AggregateWindow from './components/AggregateWindow/index';
import CoveragesWindow from './components/CoveragesWindow/index';
import PremiumWindow from './components/PremiumWindow/index';
import ErrorPopup from './components/ErrorPopup';
import ExitCustomizationPopup from './components/ExitCustomizationPopup';
import Footer from './components/FooterWindow/index';
import { Box, IconButton, Grid, Typography, Hidden } from '@material-ui/core';
import { totalHumanize, deepCopy } from 'utils';
import { useDataTrackBase } from 'hooks/customization';
import Styled from './components/styled-components';
import { Intercom } from 'services';
import { useCalculateQuote } from '../../../../api/policy/mutations/useCalculateQuote';

const QuoteCustomization = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const axiosClient = useAxiosClient();
    const isMd = useBreakpoint('down', 'md');
    const isSm = useBreakpoint('down', 'sm');
    const dataTrackBase = useDataTrackBase();
    const { optionToQuoteId } = useParams();
    const { user } = useSelector(state => state.user);
    const { optionToQuote, customizedOptionToQuote, customizationLoading } = useSelector(state => state.company);
    const [exitCustomizationPopupState, setExitCustomizationPopupState] = useState(false);
    const [errorPopupState, setErrorPopupState] = useState(false);
    const [requestedReviewPopup, setRequestedReviewPopup] = useState(false);

    const redirectToCompany = () => {
        history.push(
            {
                pathname: `/company/${optionToQuote.quote.policy_request.company_id}`,
                search: `?policyId=${optionToQuote.quote.policy_request.id}`,
            }
        )
    };

    const { mutateAsync: calculateQuote } = useCalculateQuote();

    const [, getOptionToQuote] = axiosClient(
        {
            method: 'post',
            url: `/optionToQuote/${optionToQuoteId}`,
            data: {
                query: getOptionToQuoteQuery({ includePolicyRequest: true }),
            },
        },
        { manual: true },
    );

    const [, createQuoteBuilder] = axiosClient(
        {
            method: 'post',
            url: `/optionToQuote/${optionToQuoteId}/create_quote_builder_id`,
        },
        { manual: true },
    );

    const handleExitCustomizationPopup = () => {
        setExitCustomizationPopupState(false);
    };

    const handleExitErrorPopup = () => {
        setErrorPopupState(false);
    };

    const handleReset = () => {
        dispatch(
            setCustomizationLoading({
                loadingCoverages: true,
                skeletonPremium: true,
                loadingAggregate: true,
            }),
        );
        setTimeout(() => {
            dispatch(setCustomizedOptionToQuote({ quote: optionToQuote.quote }));
            dispatch(
                setCustomizationLoading({
                    loadingCoverages: false,
                    skeletonPremium: false,
                    loadingAggregate: false,
                }),
            );
        }, 300);
    };

    const handleCalculation = async (value, type, lineItemCode, saveQuote = false) => {
        let newLineItems;
        let isRequiredReview = customizationLoading.requiredReview;
        let perClaimLimit = customizedOptionToQuote.quote.per_claim_limit;

        if (saveQuote) {
            dispatch(
                setCustomizationLoading({
                    loadingCoverages: true,
                    skeletonPremium: true,
                    loadingSaveQuote: true,
                    loadingAggregate: true,
                    disableSaveQuote: true,
                }),
            );
        } else {
            dispatch(
                setCustomizationLoading({
                    loadingCoverages: true,
                    loadingPremium: true,
                    disableSaveQuote: true,
                    loadingAggregate: true,
                }),
            );

            if (type === 'per_claim_limit') {
                perClaimLimit = value;
                dispatch(
                    setCustomizedOptionToQuoteAttributes({
                        nestedAttributes: [
                            [
                                'quote',
                                {
                                    name: 'per_claim_limit',
                                    value: value,
                                },
                            ],
                        ],
                    }),
                );
            } else {
                const lineItems = deepCopy(customizedOptionToQuote.quote.coverages.line_items);
                newLineItems = lineItems.map(lineItem => {
                    if (lineItem.code === lineItemCode)
                        if (type !== 'other_parameter') lineItem[type] = value;
                        else lineItem[type].value = value;
                    return lineItem;
                });
            }
        }

        const data = {
            line_items: newLineItems || customizedOptionToQuote.quote.coverages.line_items,
            per_claim_limit: perClaimLimit,
            persist_calculation_state: saveQuote,
            quote_builder_id: customizedOptionToQuote.quote.quote_builder_id,
        };
        try {
            const res = await calculateQuote({ ...data, optionToQuoteId });

            if (saveQuote) {
                if (customizationLoading.requiredReview) {
                    dispatch(
                        setCustomizationLoading({
                            loadingSaveQuote: false,
                        }),
                    );
                    setRequestedReviewPopup(true);
                } else {
                    redirectToCompany();
                    return;
                }
            }

            const nestedAttributes = [
                [
                    'quote',
                    {
                        name: 'quote_builder_id',
                        value: res.quote_builder_id,
                    },
                ],
                [
                    'quote',
                    {
                        name: 'premium',
                        value: res.premium,
                    },
                    {
                        name: 'total_premium',
                        value: res.total_premium,
                    },
                ],
            ];

            if (newLineItems) {
                nestedAttributes.push([
                    'quote',
                    {
                        name: 'coverages',
                        value: {
                            ...customizedOptionToQuote.quote.coverages,
                            line_items: newLineItems,
                        },
                    },
                ]);
            }

            dispatch(setCustomizedOptionToQuoteAttributes({ nestedAttributes }));
            isRequiredReview = !res.premium;
        } catch (error) {
            setErrorPopupState(true);
        }

        dispatch(
            setCustomizationLoading({
                loadingCoverages: false,
                loadingPremium: false,
                disableSaveQuote: false,
                loadingAggregate: false,
                loadingSaveQuote: false,
                skeletonPremium: false,
                requiredReview: isRequiredReview,
            }),
        );

        if (saveQuote) {
            FS.event('Quote Customization', {
                eventName: 'Save quote',
                aggregateLimit: customizedOptionToQuote.quote.aggregate_limit,
                aggregateRetention: customizedOptionToQuote.quote.aggregate_retention,
                perClaimLimit: customizedOptionToQuote.quote.per_claim_limit,
                isRequiredReview,
            });
        } else {
            FS.event('Quote Customization', {
                eventName: 'Modify coverage',
                coverageType: type,
                lineItemCode,
                value,
            });
        }
    };

    const attributesToQuoteStateDispatchData = attributes => {
        return Object.entries(attributes).map(([attribute, value]) => ['quote', { name: attribute, value }]);
    };

    const handleCreateQuoteBuilder = async (aggregate_name, value) => {
        let isRequiredReview = customizationLoading.requiredReview;
        const attributes = {
            [aggregate_name]: value,
        };

        if (aggregate_name === 'aggregate_limit') {
            attributes.per_claim_limit = value;
        }

        dispatch(
            setCustomizationLoading({
                loadingCoverages: true,
                loadingPremium: true,
                disableSaveQuote: true,
                loadingAggregate: true,
            }),
        );

        const data = {
            aggregate_limit: customizedOptionToQuote.quote.aggregate_limit,
            aggregate_retention: customizedOptionToQuote.quote.aggregate_retention,
            existing_quote_builder_id: customizedOptionToQuote.quote.quote_builder_id,
            [aggregate_name]: value,
            line_items: customizedOptionToQuote.quote.coverages.line_items,
        };
        try {
            const res = await createQuoteBuilder({ data: data });
            const attributes = {
                coverages: res.data.coverages,
                quote_builder_id: res.data.quote_builder_id,
                premium: res.data.premium,
                total_premium: res.data.total_premium,
                per_claim_limit: res.data.aggregate_limit,
                aggregate_limit: res.data.aggregate_limit,
                aggregate_retention: res.data.aggregate_retention,
            };
            isRequiredReview = !res.data.premium;
            dispatch(
                setCustomizedOptionToQuoteAttributes({
                    nestedAttributes: attributesToQuoteStateDispatchData(attributes),
                }),
            );
        } catch (error) {
            setErrorPopupState(true);
        }

        dispatch(
            setCustomizationLoading({
                loadingCoverages: false,
                loadingPremium: false,
                disableSaveQuote: false,
                loadingAggregate: false,
                requiredReview: isRequiredReview,
            }),
        );

        FS.event('Quote Customization', {
            eventName: 'Modify aggregate',
            [aggregate_name]: value,
        });
    };

    useEffect(() => {
        const initializeStore = async () => {
            const get_otq_response = await getOptionToQuote();
            const optionToQuoteData = get_otq_response.data;
            dispatch(setOptionToQuote(optionToQuoteData.optionToQuote));
            dispatch(setCustomizedOptionToQuote(optionToQuoteData.optionToQuote));
            dispatch(
                setCustomizationLoading({
                    loadingCoverages: false,
                    loadingAggregate: false,
                    skeletonPremium: false,
                }),
            );
        };
        initializeStore();
    }, [optionToQuoteId]);

    useEffect(() => {
        if (user && optionToQuote) {
            const intercomEventMetadata = {
                userEmail: user.email,
                product_type: optionToQuote.quote.policy_request.product.name,
                team_id: user.team?.id,
                is_owner: user.id === optionToQuote.quote.policy_request.broker_user.id,
            };
            const productAbbrName = optionToQuote.quote.policy_request.product.abbreviated_name;
            Intercom.sendEvent('customization-started', intercomEventMetadata);
            Intercom.sendEvent(`${productAbbrName.toLowerCase()}-customization-started`, intercomEventMetadata);
        }
    }, [user, optionToQuote]);

    useEffect(() => {
        dispatch(setShowHeader(false));
        dispatch(setShowFooter(false));
        return () => {
            dispatch(setShowHeader(true));
            dispatch(setShowFooter(true));
            dispatch(setOptionToQuote(null));
            dispatch(setCustomizedOptionToQuote(null));
            dispatch(resetCustomizationLoadingState());
        };
    }, []);

    return (
        <Box style={{ overflowX: 'hidden' }}>
            <Box textAlign="right">
                <IconButton
                    color="inherit"
                    onClick={() => setExitCustomizationPopupState(true)}
                    data-track={`${dataTrackBase}_open_exit_popup`}
                    aria-label="exit page">
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box
                height="75%"
                mt="1em"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    overflow: 'hidden',
                }}>
                <Box
                    style={{
                        minWidth: isSm ? '0' : isMd ? '700px' : '1020px',
                        width: isSm ? '100%' : isMd ? '50%' : '70%',
                        maxWidth: '1050px',
                    }}>
                    <Box ml={isSm ? 2 : 0}>
                        <Box fontWeight={700}>
                            {optionToQuote ? (
                                <Box display="flex" minWidth="385px">
                                    <Box component="span" color="#606060" mr={1}>
                                        <Typography variant={isSm ? 'h4' : 'h3'}>
                                            {optionToQuote.quote.policy_request.product.name}
                                        </Typography>
                                    </Box>
                                    <Typography variant="h3">Quote Customization</Typography>
                                </Box>
                            ) : (
                                <Box component={Styled.Skeleton} variant="text" width="400px" animation={false} />
                            )}
                        </Box>
                        <Box fontWeight={700} color="#919191" mb={3}>
                            {optionToQuote ? (
                                <Box component={Typography} variant="subtitle2">
                                    Based on ${totalHumanize(optionToQuote.quote.aggregate_limit)} Option ($
                                    {optionToQuote.quote.total_premium.toLocaleString()})
                                </Box>
                            ) : (
                                <Box component={Styled.Skeleton} variant="text" width="200px" animation={false} />
                            )}
                        </Box>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item lg={8} sm={12}>
                            <AggregateWindow
                                handleCalculation={handleCalculation}
                                handleCreateQuoteBuilder={handleCreateQuoteBuilder}
                            />
                        </Grid>
                        <Hidden mdDown>
                            <Grid item lg={4}>
                                <Box maxWidth="290px">
                                    <PremiumWindow />
                                </Box>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: '20px' }}>
                        <Grid item lg={8} sm={12}>
                            <Box mb={isMd ? 20 : 13}>
                                <CoveragesWindow handleCalculation={handleCalculation} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Footer
                handleReset={handleReset}
                handleCalculation={handleCalculation}
                requestedReviewPopup={requestedReviewPopup}
            />
            <ExitCustomizationPopup
                exitCustomizationPopupState={exitCustomizationPopupState}
                handleClose={handleExitCustomizationPopup}
            />
            <ErrorPopup open={errorPopupState} handleClose={handleExitErrorPopup} />
        </Box>
    );
};

export default QuoteCustomization;
