import React from 'react';
import {
    Box,
    Hidden,
    Typography,
} from '@material-ui/core';
import { StyledBox } from 'components';
import { useEnv } from 'hooks';


const IndustryAndServices = ({ policyRequest, bgcolor }) => {
    const { newBpRenewal } = useEnv();

    return (
        <Box display="flex" fontWeight="fontWeightRegular" pb={newBpRenewal ? 0 : [4, 4, 0]}>
            {policyRequest.services.length ? (
                <>
                    <Hidden mdUp>
                        <StyledBox.ScrollXBox display="flex" style={{overflowX: 'scroll'}} position="absolute" maxWidth="100%">
                            {policyRequest.services.map(service =>
                                <Box
                                    key={`service_${service}`}
                                    sizes={['48px', '48px', '56px']}
                                    bgcolor={bgcolor ? bgcolor : '#F8F8F8'}
                                    color="#696969"
                                    borderRadius="300px"
                                    width="fit-content"
                                    px={2}
                                    py={0.5}
                                    mt={0}
                                    mr={1}>
                                    <Typography variant="body1">{service.name}</Typography>
                                </Box>
                            )}
                        </StyledBox.ScrollXBox>
                    </Hidden>
                    <Hidden smDown>
                        <Box display="flex" flexWrap="wrap">
                            {policyRequest.services.map(service =>
                                <Box
                                    key={`service_${service}`}
                                    sizes={['48px', '48px', '56px']}
                                    bgcolor={bgcolor ? bgcolor : '#F8F8F8'}
                                    color="#696969"
                                    borderRadius="300px"
                                    position="relative"
                                    width="fit-content"
                                    px={2}
                                    py={0.5}
                                    mt={0}
                                    mb={0.5}
                                    mr={1}>
                                    <Typography variant="body1">{service.name}</Typography>
                                </Box>
                            )}
                        </Box>
                    </Hidden>
                </>
            ) : (
                policyRequest.industries.industry &&
                <Box
                    sizes={['48px', '48px', '56px']}
                    bgcolor={bgcolor ? bgcolor : '#F8F8F8'}
                    color="#696969"
                    borderRadius="300px"
                    position="relative"
                    width="fit-content"
                    px={2}
                    py={0.5}
                    mt={0}>
                    <Typography variant="body1">{policyRequest.industries.industry.naics_title}</Typography>
                </Box>
            )}
        </Box>
    )
}


export default IndustryAndServices;
