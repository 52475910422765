import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const MessageInterceptor = () => {
    const history = useHistory();
    useEffect(() => {
        window.addEventListener('message', e => {
            if (e.data?.url) {
                history.push(e.data.url);
            }
        }, false);
    }, [])

    return null;
};

export default MessageInterceptor;
