import React from 'react';
import { Box, Hidden, Typography } from '@material-ui/core';
import { ReactComponent as Upload } from 'components/icons/upload.svg';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Styled from '../styled-components';
import Dropzone from 'react-dropzone'
import { StyledBox, StyledAlert, StyledAction } from 'components';


const validFileTypes = {
    'application/pdf': ['.pdf'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
};

const fileTypesToString = () => {
    let fileTypeList = [];
    for (let key in validFileTypes) {
        fileTypeList = [...fileTypeList, ...validFileTypes[key]];
    }
    const filesTypeInText = fileTypeList.map(fileType => fileType.replaceAll('.', '')).join(', ');
    return filesTypeInText;
}

const UploadDocument = ({ handleNewFiles, handleRemoveFile, uploadedFiles, submitLoading }) => {
    return (
        <Dropzone
            onDrop={handleNewFiles}
            data-track="upload_referral_renewal_file"
            disabled={submitLoading}
            noClick
            accept={validFileTypes}
        >
            {({getRootProps, getInputProps, open, fileRejections}) => (
                <StyledBox.NoFocus fontWeight="fontWeightLight" {...getRootProps()}>
                    <Box p={[2.75, 3.5]} display="flex">
                        <Hidden smDown>
                            <Upload />
                        </Hidden>
                        <Box ml={[0, 0, 3.75]} width={['100%', '100%', '90%']}>
                            <Box mb={0.9} color="#191919">
                                <Typography variant="subtitle2">
                                    Upload any relevant document(s).
                                </Typography>
                            </Box>
                            {uploadedFiles.map((file, ind) => (
                                <Box
                                    mt={0.3}
                                    key={ind}
                                    style={{ overflowWrap: 'anywhere' }}>
                                    <StyledAlert.ItemAlert
                                        className="no-border-radius"
                                        severity="info"
                                        style={{ backgroundColor: 'white' }}
                                        icon={
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                p={0}
                                                component={props => (
                                                    <Typography variant="subtitle2" component="span" {...props} />
                                                )}>
                                                <DescriptionOutlinedIcon />
                                            </Box>
                                        }
                                        action={
                                            file.locked ? null : (
                                                <Styled.CloseIcon
                                                    className="small"
                                                    color="primary"
                                                    customdisable={submitLoading ? 1 : 0}
                                                    onClick={() => submitLoading ? {} : handleRemoveFile(file)}
                                                />
                                            )
                                        }>
                                        <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                                            {file.name}
                                        </Typography>
                                    </StyledAlert.ItemAlert>
                                </Box>
                            ))}
                            <Box>
                                {
                                    fileRejections.length > 0 &&
                                    <Styled.errorMessage mt={1} mb={uploadedFiles.length > 0 ? 0 : 1.5}>
                                        <Typography variant="body2">
                                            Invalid file type(s), must be one of: {fileTypesToString()}
                                        </Typography>
                                    </Styled.errorMessage>
                                }
                            </Box>
                            <Hidden mdUp>
                                <Box mt={1.5} fontWeight="fontWeightMedium">
                                    <Typography variant="body1" color="primary" onClick={open} data-track='renewal-referral-upload-relevant-documents'>
                                        <StyledAction.Base className={submitLoading ? 'disabled' : ''}>
                                            {submitLoading
                                                ? 'Please wait...'
                                                : 'Upload attachment'}
                                            <input hidden {...getInputProps()} />
                                        </StyledAction.Base>
                                    </Typography>
                                </Box>
                            </Hidden>
                            <Hidden smDown>
                                <Styled.RoundedButton
                                    style={{ background: 'rgba(32, 32, 238, 0.1)' }}
                                    customdisable={submitLoading ? 1 : 0}
                                    py={0.25}
                                    px={2}
                                    mt={uploadedFiles.length > 0 ? 1.5 : 0}
                                    fontWeight="fontWeightMedium"
                                    onClick={open}
                                    data-track='renewal-referral-upload-relevant-documents'
                                >
                                    <Typography component={Box} variant="body2" color="primary">
                                        <Box>
                                            Click to upload / Drag document here
                                            <input hidden {...getInputProps()} />
                                        </Box>
                                    </Typography>
                                </Styled.RoundedButton>
                            </Hidden>
                        </Box>
                    </Box>
                </StyledBox.NoFocus>
            )}
        </Dropzone>
    )
}
export default UploadDocument;
