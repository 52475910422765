import React, { FC } from 'react';
import { StyledTypography, StyledTable } from 'components/index';
import { Box, TableRow, Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';

interface Props {
    variant: Variant | 'inherit';
    subVariant: Variant;
    firstColSpan: number;
    firstColPadding: number;
    subCoverageSpacing: number;
    title: string;
    value: number;
}

const PolicyTableRow: FC<Props> = ({
    variant,
    subVariant,
    firstColSpan,
    firstColPadding,
    subCoverageSpacing,
    title,
    value,
}) => {
    return (
        <Box component={TableRow} key={title} fontWeight="fontWeightLight">
            <StyledTable.TableCell colSpan={firstColSpan} padding="none">
                <Box pl={firstColPadding} mb={subCoverageSpacing} display="flex">
                    <StyledTypography.FadedTypography
                        variant={variant}
                        style={{ marginTop: '-0.2rem', marginRight: '0.2rem' }}>
                        {'- '}
                    </StyledTypography.FadedTypography>
                    <StyledTypography.FadedTypography variant={subVariant}>{title}</StyledTypography.FadedTypography>
                </Box>
            </StyledTable.TableCell>
            <StyledTable.TableCell padding="none">
                <Box mt={subCoverageSpacing}>
                    <StyledTypography.FadedTypography variant={subVariant}> </StyledTypography.FadedTypography>
                </Box>
            </StyledTable.TableCell>
            <StyledTable.TableCell padding="none">
                <Box mb={subCoverageSpacing}>
                    <StyledTypography.FadedTypography variant={subVariant}>$ {value}</StyledTypography.FadedTypography>
                </Box>
            </StyledTable.TableCell>
        </Box>
    );
};

export default PolicyTableRow;
