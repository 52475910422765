import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    AddContingencyPayloadAction,
    AddFilesToContingencyPayloadActionType,
    CustomizationLoadingState,
    CompanyState,
    RemoveContingencyPayloadActionType,
    RemoveFileFromContingencyPayloadActionType,
    RemovePolicyRequestPayloadActionType,
    SetCompanyAttributePayloadActionType,
    SetCompanyPayloadActionType,
    SetCustomizationLoadingPayloadActionType,
    SetCustomizedOptionToQuoteAttributesPayloadActionType,
    SetCustomizedOptionToQuotePayloadActionType,
    SetDateValidityPayloadActionType,
    SetOptionToQuoteAttributesPayloadActionType,
    SetOptionToQuotePayloadActionType,
    SetPolicyRequestAttributePayloadActionType,
    SetPolicyRequestDatesPayloadActionType,
    SetPolicyRequestPayloadActionType,
    SetSubmittingPayloadActionType,
    SetUpdatingCompanyNamePayloadActionType,
} from './types';
import { OptionToQuote } from '../../types/optionToQuote';
import { isDefined } from '../../types/utils';
import { Contingency } from '../../types/PolicyRequest';

const customizationLoadingInitialState: CustomizationLoadingState = {
    loadingCoverages: true,
    loadingAggregate: true,
    loadingPremium: false,
    loadingSaveQuote: false,
    skeletonPremium: true,
    disableSaveQuote: true,
    requiredReview: false,
};

const initialState: CompanyState = {
    company: undefined,
    optionToQuote: undefined,
    customizedOptionToQuote: null,
    submitting: false,
    updatingCompanyName: false,
    customizationLoading: customizationLoadingInitialState,
    dateValidity: {},
};

const optionToQuoteFiltering = (optionToQuote: OptionToQuote, payload: any) => {
    for (const attribute of payload.attributes || []) {
        // @ts-ignore - typescript can't define that the typeof the attribute we pass is match to value type, so it throws typescript error
        optionToQuote[attribute.name] = attribute.value;
    }
    for (const [nestedAttribute, attribute] of payload.nestedAttributes || []) {
        // @ts-ignore - typescript can't define that the typeof the attribute we pass is match to value type, so it throws typescript error
        optionToQuote[nestedAttribute][attribute.name] = attribute.value;
    }
};

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        setDateValidity(state, action: PayloadAction<SetDateValidityPayloadActionType>) {
            const { dateType, isValid } = action.payload;
            if (state.dateValidity) {
                state.dateValidity[dateType] = isValid;
            }
        },
        setCompany(state, action: PayloadAction<SetCompanyPayloadActionType>) {
            state.company = action.payload;
        },
        setCompanyAttribute(state, action: PayloadAction<SetCompanyAttributePayloadActionType>) {
            const { name, value } = action.payload;
            if (isDefined(state.company)) {
                state.company = { ...state.company, [name]: value };
            }
        },
        setOptionToQuote(state, action: PayloadAction<SetOptionToQuotePayloadActionType>) {
            state.optionToQuote = action.payload;
        },
        setCustomizationLoading(state, action: PayloadAction<SetCustomizationLoadingPayloadActionType>) {
            const updatedState = { ...state.customizationLoading, ...action.payload };
            state.customizationLoading = updatedState;
        },
        setCustomizedOptionToQuote(state, action: PayloadAction<SetCustomizedOptionToQuotePayloadActionType>) {
            state.customizedOptionToQuote = action.payload;
        },
        resetCustomizationLoadingState(state) {
            state.customizationLoading = customizationLoadingInitialState;
        },
        setPolicyRequest(state, action: PayloadAction<SetPolicyRequestPayloadActionType>) {
            if (isDefined(state.company)) {
                const policyRequests = [...state.company.viewable_policy_requests];
                const index = policyRequests.findIndex(state => state.id === action.payload.id);
                if (index === -1) {
                    policyRequests.push(action.payload);
                } else {
                    policyRequests[index] = action.payload;
                }
                state.company.viewable_policy_requests = policyRequests;
            }
        },
        setPolicyRequestDates(state, action: PayloadAction<SetPolicyRequestDatesPayloadActionType>) {
            if (isDefined(state.optionToQuote)) {
                state.optionToQuote.quote.policy_request = {
                    ...state.optionToQuote.quote.policy_request,
                    ...action.payload,
                };
            }
        },
        setPolicyRequestAttribute(state, action: PayloadAction<SetPolicyRequestAttributePayloadActionType>) {
            const { policyRequestID, name, value } = action.payload;
            if (state.optionToQuote?.quote?.policy_request) {
                // @ts-ignore - typescript can't define that the typeof the attribute we pass is match to value type, so it throws typescript error
                state.optionToQuote.quote.policy_request[name] = value;
            } else if (state.company) {
                const policyRequests = [...state.company.viewable_policy_requests];
                const policyRequest = policyRequests.find(policyRequest => policyRequest.id === policyRequestID);
                // @ts-ignore - typescript can't define that the typeof the attribute we pass is match to value type, so it throws typescript error
                policyRequest[name] = value;
                state.company.viewable_policy_requests = policyRequests;
            }
        },
        setOptionToQuoteAttributes(state, action: PayloadAction<SetOptionToQuoteAttributesPayloadActionType>) {
            const { payload } = action;
            let optionToQuote, policyRequest, policyRequests;
            if (state.company?.viewable_policy_requests) {
                policyRequests = [...state.company.viewable_policy_requests];
                policyRequest = policyRequests.find(policyRequest => policyRequest.id === payload.policyRequestID);
                optionToQuote = policyRequest?.option_to_quotes.find(
                    optionToQuote => optionToQuote.id === payload.optionToQuoteID,
                );
            } else if (isDefined(state.optionToQuote)) {
                optionToQuote = { ...state.optionToQuote };
            }
            if (!optionToQuote) {
                return;
            }
            optionToQuoteFiltering(optionToQuote, payload);

            if (state.company?.viewable_policy_requests) {
                state.company.viewable_policy_requests = policyRequests || [];
            } else {
                state.optionToQuote = optionToQuote;
            }
        },
        setCustomizedOptionToQuoteAttributes(
            state,
            action: PayloadAction<SetCustomizedOptionToQuoteAttributesPayloadActionType>,
        ) {
            const { payload } = action;
            let customizedOptionToQuote;

            if (state.customizedOptionToQuote) {
                customizedOptionToQuote = { ...state.customizedOptionToQuote };
            } else {
                return;
            }
            optionToQuoteFiltering(customizedOptionToQuote, payload);

            state.customizedOptionToQuote = customizedOptionToQuote;
        },
        removePolicyRequest(state, action: PayloadAction<RemovePolicyRequestPayloadActionType>) {
            if (isDefined(state.company)) {
                const policyRequests = [...state.company.viewable_policy_requests];
                const index = policyRequests.findIndex(policyRequest => policyRequest.id === action.payload.id);
                if (index !== -1) {
                    policyRequests.splice(index, 1);
                    state.company.viewable_policy_requests = policyRequests;
                }
            }
        },
        addFilesToContingency(state, action: PayloadAction<AddFilesToContingencyPayloadActionType>) {
            if (isDefined(state.optionToQuote)) {
                const tempContingencies = state.optionToQuote.quote.policy_request.contingencies;
                const contingency = tempContingencies.find(c => c.id === action.payload.id) as Contingency;
                contingency.files = contingency.files.concat(action.payload.files);
                state.optionToQuote.quote.policy_request.contingencies = tempContingencies;
            }
        },
        removeFileFromContingency(state, action: PayloadAction<RemoveFileFromContingencyPayloadActionType>) {
            if (state.optionToQuote?.quote.policy_request.contingencies) {
                const tempContingencies = state.optionToQuote.quote.policy_request.contingencies;
                const contingency = tempContingencies.find(c => c.id === action.payload.id) as Contingency;
                const files = contingency.files;
                const index = files.findIndex(file => file.id === action.payload.fileID);
                files.splice(index, 1);
                contingency.files = files;
                state.optionToQuote.quote.policy_request.contingencies = tempContingencies;
            }
        },
        lockPolicyFiles(state) {
            if (state.optionToQuote?.quote.policy_request.contingencies) {
                const tempContingencies = state.optionToQuote.quote.policy_request.contingencies;
                for (let contingency of tempContingencies) {
                    contingency.files = contingency.files.map(file => ({ ...file, locked: true }));
                }
                state.optionToQuote.quote.policy_request.contingencies = tempContingencies;
            }
        },
        addContingency(state, action: PayloadAction<AddContingencyPayloadAction>) {
            if (state.optionToQuote?.quote.policy_request.contingencies) {
                const tempContingencies = state.optionToQuote.quote.policy_request.contingencies;
                const contingency = tempContingencies.find(c => c.id === action.payload.id);
                if (!contingency) {
                    tempContingencies.push(action.payload);
                }
                state.optionToQuote.quote.policy_request.contingencies = tempContingencies;
            }
        },
        removeContingency(state, action: PayloadAction<RemoveContingencyPayloadActionType>) {
            if (state.optionToQuote?.quote.policy_request.contingencies) {
                const tempContingencies = state.optionToQuote.quote.policy_request.contingencies;
                const index = tempContingencies.findIndex(c => c.id === action.payload.id);
                if (index !== -1) {
                    tempContingencies.splice(index, 1);
                    state.optionToQuote.quote.policy_request.contingencies = tempContingencies;
                }
            }
        },
        setSubmitting(state, action: PayloadAction<SetSubmittingPayloadActionType>) {
            state.submitting = action.payload;
        },
        setUpdatingCompanyName(state, action: PayloadAction<SetUpdatingCompanyNamePayloadActionType>) {
            state.updatingCompanyName = action.payload;
        },
    },
});

export const {
    setDateValidity,
    setCompany,
    setCompanyAttribute,
    setOptionToQuote,
    setCustomizedOptionToQuote,
    setCustomizedOptionToQuoteAttributes,
    setCustomizationLoading,
    setPolicyRequest,
    setPolicyRequestDates,
    setPolicyRequestAttribute,
    setOptionToQuoteAttributes,
    removePolicyRequest,
    addFilesToContingency,
    removeFileFromContingency,
    addContingency,
    removeContingency,
    lockPolicyFiles,
    setSubmitting,
    setUpdatingCompanyName,
    resetCustomizationLoadingState,
} = companySlice.actions;

export default companySlice.reducer;
