import React from 'react';
import { useLocation } from 'react-router-dom';
import { StyledLink } from 'components';
import Styled from './styled-components';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BusinessIcon from '@material-ui/icons/Business';

const mainNav = isAdmin => {
    const nav = [
        {
            text: 'Home',
            icon: <DashboardIcon />,
            path: '/dashboard',
            id: 'home',
        },
    ];
    const retailPartnersNavItem = retailPartnersNav(isAdmin);
    if (retailPartnersNavItem) {
        nav.push(retailPartnersNavItem);
    }
    return nav;
};

const retailPartnersNav = isAdmin => {
    return !isAdmin
        ? {
            text: 'Retail Partners',
            icon: <BusinessIcon />,
            path: '/partnership_management/retail_partners',
            id: 'retail-partners',
        }
        : null;
};

const adminNav = [
    {
        text: 'Pending Users',
        icon: <BusinessIcon />,
        path: '/admin/user/pending_users',
    },
    {
        text: 'Questionnaire Builder',
        icon: <BusinessIcon />,
        path: '/admin/questionnaire_builder',
    },
    { text: 'Export', icon: <BusinessIcon />, path: '/admin/export_all' },
    {
        text: 'White Label',
        icon: <BusinessIcon />,
        path: '/admin/white_label',
    },
];

const MobileNav = ({ open, onClose, userName, isAdmin, onLogout, onChangePassword }) => {
    const location = useLocation();

    return (
        <Styled.Drawer variant="persistent" anchor='right' open={open} onClose={onClose}>
            <Styled.List>
                <Styled.MobileNavClearIcon onClick={onClose} onKeyDown={onClose} />
                <Styled.MobileMenuHeader>{userName}</Styled.MobileMenuHeader>
                <Styled.Spacer />
                {mainNav(isAdmin).map((item, idx) => {
                    return (
                        <div key={idx}>
                            <Styled.MobileMenuItemBar isActive={location.pathname === item?.path} />
                            <StyledLink.Default to={item?.path} onClick={onClose}>
                                <Styled.MobileMenuItem>{item?.text}</Styled.MobileMenuItem>
                            </StyledLink.Default>
                        </div>
                    );
                })}
                <Styled.Spacer />
                {isAdmin &&
                    adminNav.map((item, idx) => {
                        return (
                            <div key={idx}>
                                <Styled.MobileMenuItemBar isActive={location.pathname === item.path} />
                                <StyledLink.Default to={item.path} onClick={onClose}>
                                    <Styled.MobileMenuItem>{item.text}</Styled.MobileMenuItem>
                                </StyledLink.Default>
                            </div>
                        );
                    })}
                <Styled.Spacer />
                <Styled.MobileMenuItemBar isActive={location.pathname === '/user/change_password'} />
                <Styled.MobileMenuItem onClick={onChangePassword}>Change Password</Styled.MobileMenuItem>
                <Styled.MobileMenuItem onClick={onLogout}>Sign Out</Styled.MobileMenuItem>
            </Styled.List>
        </Styled.Drawer>
    );
};

export default MobileNav;
