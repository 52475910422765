import styled from 'styled-components';
import { StyledLink } from 'components';

const Link = styled(StyledLink.Default)`
    &&& {
        color: ${({ theme }) => theme.palette.info.main};
        &:visited {
            color: ${({ theme }) => theme.palette.info.main};
        }
    }
`;

const LinkBack = styled(StyledLink.Anchor)`
    &&& {
        cursor: pointer;
        color: ${({ theme }) => theme.palette.info.main};
        &:visited {
            color: ${({ theme }) => theme.palette.info.main};
        }
    }
`;

const ExternalLink = styled.a`
    &&& {
        color: ${({ theme }) => theme.palette.info.main};
        text-decoration: none;
        &:visited {
            color: ${({ theme }) => theme.palette.info.main};
        }
    }
`;

export default {
    Link,
    ExternalLink,
    LinkBack
};
