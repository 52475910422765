import React from 'react';
import { useHistory } from 'react-router-dom';
import { StyledTypography, RoundedButton } from 'components';
import { formatDatePickerDate, totalHumanize } from 'utils';
import { SubmissionsBucket } from 'utils/enums';
import { Box, Typography, TableBody, Table } from '@material-ui/core';
import NextSteps from 'components/policy/next-steps/BoundNextSteps';


const Mobile = ({ policyRequest }) => {
    const history = useHistory();
    const isBindRequested = policyRequest.athena_submissions_bucket === SubmissionsBucket.BIND_REQUESTED;
    const TypographyComponent = isBindRequested ? StyledTypography.FadedTypography : Typography;
    const preferredOptionToQuote = policyRequest.option_to_quotes.find(optionToQuote => optionToQuote.quote.preferred);
    return (
        <>
            <Box borderBottom={`4px solid ${policyRequest.product.background_color}`} my={2} />
            <Box display="flex" justifyContent="space-between" alignItems="center" fontWeight="fontWeightLight" flexWrap="wrap">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <StyledTypography.FadedTypography variant="overline">
                        AG. LIMIT
                    </StyledTypography.FadedTypography>
                    <TypographyComponent variant="body1">
                        ${totalHumanize(preferredOptionToQuote.quote.aggregate_limit)}
                    </TypographyComponent>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <StyledTypography.FadedTypography variant="overline">
                        POLICY PERIOD
                    </StyledTypography.FadedTypography>
                    <TypographyComponent variant="body1">
                        {formatDatePickerDate(policyRequest.effective_date)} - {formatDatePickerDate(policyRequest.expiration_date)}
                    </TypographyComponent>
                </Box>
                <Box component={RoundedButton} fullWidth  fontWeight="fontWeightBold" mt={2} onClick={() => history.push(`/submission/policy_details/${preferredOptionToQuote.id}`)}>
                    <Typography variant="subtitle2">
                        View Details
                    </Typography>
                </Box>
            </Box>
            <Box component={props => <Table padding="none" {...props} />} fontWeight="fontWeightLight">
                <TableBody>
                    <NextSteps policyRequest={policyRequest} />
                </TableBody>
            </Box>
        </>
    );
}


export default Mobile;
