/* eslint-disable no-unused-vars */
import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import 'react-toastify/dist/ReactToastify.css';
import { EnvContextProvider, AxiosClientContextProvider } from 'context';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from 'components/theme/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJSUtils from '@date-io/dayjs';
import store from 'stores/store';
import { QueryClientProvider } from 'react-query';
import { initQueryClient } from '../../reactQuery/queryClient';
import { ReactQueryDevtools } from 'react-query-devtools';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const BaseWrapper = ({ children, __testStore }) => {
    return (
        <>
            <ToastContainer />
            <EnvContextProvider>
                <Provider store={__testStore || store}>
                    <AxiosClientContextProvider>
                        <MuiThemeProvider theme={theme}>
                            <ThemeProvider theme={theme}>
                                <MuiPickersUtilsProvider utils={DayJSUtils}>
                                    <QueryClientProvider client={initQueryClient()}>
                                        {children}
                                        <ReactQueryDevtools initialIsOpen={false} />
                                    </QueryClientProvider>
                                </MuiPickersUtilsProvider>
                            </ThemeProvider>
                        </MuiThemeProvider>
                    </AxiosClientContextProvider>
                </Provider>
            </EnvContextProvider>
        </>
    );
};

export default BaseWrapper;
