import React from 'react';
import { StyledTable, StyledLink } from 'components';
import { Box, Typography, TableRow } from '@material-ui/core';
import { useEnv } from 'hooks';
import { Hidden } from '@material-ui/core';

const ErpInvoice = ({ policyRequest }) => {
    const { apiBaseUrl } = useEnv();

    return (
        <>
            <Hidden smDown>
                <TableRow>
                    <StyledTable.TableCell colSpan={6}>
                        <Box component="span" fontWeight="fontWeightLight">
                            <Typography variant="subtitle2" component="span">
                                Looking for a ERP invoice?
                            </Typography>
                        </Box>
                        <Box component="span" fontWeight="fontWeightMedium" ml={1}>
                            <Typography variant="subtitle2" component="span" color="primary">
                                <Box
                                    component={StyledLink.Anchor}
                                    fontWeight="fontWeightMedium"
                                    target="_blank"
                                    href={`${apiBaseUrl}/submission/download_erp_file/${policyRequest.id}/INVOICE`}>
                                    – Download ERP Invoice.
                                </Box>
                            </Typography>
                        </Box>
                    </StyledTable.TableCell>
                </TableRow>
            </Hidden>
            <Hidden mdUp>
                <TableRow>
                    <StyledTable.TableCell colSpan={6}>
                        <Box fontWeight="fontWeightLight">
                            <Typography variant="subtitle2" component="span">
                                Looking for a ERP invoice?
                            </Typography>
                        </Box>
                        <Box fontWeight="fontWeightMedium">
                            <Typography variant="subtitle2" component="span" color="primary">
                                <Box
                                    component={StyledLink.Anchor}
                                    fontWeight="fontWeightMedium"
                                    target="_blank"
                                    href={`${apiBaseUrl}/submission/download_erp_file/${policyRequest.id}/INVOICE`}>
                                    – Download ERP Invoice.
                                </Box>
                            </Typography>
                        </Box>
                    </StyledTable.TableCell>
                </TableRow>
            </Hidden>
        </>
    );
};

export default ErpInvoice;
