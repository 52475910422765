import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { ReactComponent as ClipboardTimeIcon } from 'components/icons/clipboard-time.svg';


const RequiredReview = () => {

    return (
        <>
            <Box bg-color="#FFFE86">
                <ClipboardTimeIcon />
            </Box>
            <Box fontWeight={700} my={0.75}>
                <Typography variant="h6">
                    * Requires Review
                </Typography>
            </Box>
            <Box ml={1.5} mb={1.5} color="#919191">
                <Typography variant="body1">
                    Before we can show a premium, an underwriter will need to review your changes.
                </Typography>
            </Box>
        </>
    )
}


export default RequiredReview;