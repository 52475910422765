import React, { useEffect, useState }  from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, Snackbar, SnackbarContent } from '@material-ui/core';
import { useBreakpoint } from 'hooks';
import { DynamicIcon } from 'components';
import { theme } from 'components/theme/styles';


const MonitorPathChanges = () => {
    const { pathname } = useLocation();
    const location = useLocation();
    const [, setRender] = useState(false);
    const [editApp, setEditApp] = useState(false);
    const [banner, setBanner] = useState(false);
    const [submitEditApp, setSubmitEditApp] = useState(false);
    const isSm = useBreakpoint('down', 'sm');
    const isMd = useBreakpoint('down', 'md');
    const isLg = useBreakpoint('up', 'lg');
    const isContainer = useBreakpoint('down', 'container');

    window.onpopstate = () => {
        window.history.scrollRestoration = 'auto';
        setRender(true);
    }

    useEffect(() => {
        window.history.scrollRestoration = 'manual';
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (pathname.match('/questionnaire/policy/*') && location.state?.editMode) {
            setEditApp(true);
            setBanner(true);
        } else if (pathname.match('/company/*') && location.state?.submitEditApp){
            setSubmitEditApp(true);
        } else if (editApp) {
            if (submitEditApp) {
                setSubmitEditApp(false);
                setEditApp(false);
            } else {
                setBanner(false);
                setEditApp(false);
            }
        }
    }, [pathname]);

    return (
        <Snackbar
            style={{ minWidth: '100%', top: '80px', bottom: 'auto', maxWidth: '1280px' }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={banner}
            onClose={() => setBanner(false)}
            autoHideDuration={8000}
            id="innerAppBar">
            <SnackbarContent
                style={{
                    minWidth: '100%',
                    backgroundColor: 'rgba(179,215,255,0.5)',
                    justifyContent: isContainer ? 'start' : 'center',
                    color: theme.palette.primary.main,
                    padding: '4px 16px',
                }}
                message={
                    <Box
                        fontWeight="fontWeightRegular"
                        display="flex"
                        alignItems="center"
                        pl={isSm ? 4 : isContainer ? 7 : 0}
                        minWidth={(isMd && !isContainer) || isLg ? theme.breakpoints.values.container : isSm ? 'unset' : '616px'}>
                        {!isSm && (
                            <Box display="flex" mr={1}>
                                <DynamicIcon name="edit" typographyProps={{ variant: 'h6' }} />
                            </Box>
                        )}
                        <Typography variant="body1" color="primary">
                            Changes will NOT be saved until you hit Resubmit. Upon resubmitting, your quote will be re-evaluated.
                        </Typography>
                    </Box>
                }
            />
        </Snackbar>
    );
}

export default MonitorPathChanges;
