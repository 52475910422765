import { ValueType } from './enums';
const _ = require('lodash');

export const getPartialConditionsFromCondition = nodeCondition => {
    // gets the inner conditions from the full condition
    let startParentheses = null,
        endParentheses = null,
        insideString = false,
        partials = [];
    for (let i = 0; i < nodeCondition.length; i++) {
        // if the parentheses are inside a string, we want to ignore them (it means they are inside an answer
        // and not as a logical operator)
        if (nodeCondition[i] === '"') {
            insideString = !insideString;
        }
        if (nodeCondition[i] === '(' && !insideString) {
            startParentheses = i;
        }
        if (nodeCondition[i] === ')' && !insideString) {
            endParentheses = i;
        }
        if (startParentheses !== null && endParentheses !== null) {
            const partialCondition = nodeCondition.slice(startParentheses, endParentheses + 1);
            partials.push(partialCondition);
            startParentheses = null;
            endParentheses = null;
        }
    }
    return partials;
};

export const conditionedNodeNeedToBeVisible = (nodeCondition, sectionData, questionsData) => {
    const partialConditions = getPartialConditionsFromCondition(nodeCondition);
    if (partialConditions.length === 0) {
        // if there isn't any partial conditions, we can treat the condition as one
        return evaluateCondition(nodeCondition, sectionData, questionsData);
    }
    for (const partialCondition of partialConditions) {
        // evaluates each inner condition separately
        const isPartialConditionFulfilled = evaluateCondition(
            partialCondition.slice(1, -1),
            sectionData,
            questionsData,
        );
        // replaces the inner condition with its evaluation (true / false)
        nodeCondition = nodeCondition.replace(partialCondition, isPartialConditionFulfilled.toString());
    }
    // keep evaluating the condition until there are no inner conditions
    return conditionedNodeNeedToBeVisible(nodeCondition, sectionData, questionsData);
};

export const evaluateCondition = (nodeCondition, sectionData, questionsData) => {
    // if OR not in condition, we can treat the condition as one full condition
    if (!nodeCondition.includes('OR')) {
        return isConditionFulfilled(nodeCondition, sectionData, questionsData);
    } else {
        // we need to split the condition by OR and check each part separately
        const conditions = nodeCondition.split('OR');
        let conditionFulfilled = false;
        for (const partialCondition of conditions) {
            // if one of the conditions returns true, the full condition is true as well
            conditionFulfilled =
                conditionFulfilled || isConditionFulfilled(partialCondition, sectionData, questionsData);
            if (conditionFulfilled) {
                break;
            }
        }
        return conditionFulfilled;
    }
};

export const isConditionFulfilled = (nodeCondition, sectionData, questionsData) => {
    // finds the dependee question ids in the condition
    const dependeeQuestionIds = nodeCondition.match(/Q[0-9]+/g);
    let isConditionedNodeNeedToBeVisible = true;
    if (dependeeQuestionIds) {
        for (const dependeeQuestionId of dependeeQuestionIds) {
            // removes Q from questionId
            const sectionOfQuestion = sectionData.find(section =>
                section.datapoints.find(datapoint => datapoint.datapoint.datapoint_id === dependeeQuestionId),
            );
            const datapoint = sectionOfQuestion.datapoints.find(dp => dp.datapoint.datapoint_id === dependeeQuestionId);
            const choices = datapoint.choices;
            let dependeeAnswer = datapoint.datapoint.display_condition
                ? conditionedNodeNeedToBeVisible(datapoint.datapoint.display_condition, sectionData, questionsData)
                    ? questionsData[dependeeQuestionId]
                    : null
                : questionsData[dependeeQuestionId];
            if ([null, ''].includes(dependeeAnswer)) {
                // if one of the dependee questions wasn't answered, the condition returns false
                isConditionedNodeNeedToBeVisible = false;
            } else {
                if (
                    choices &&
                    [ValueType.MULTIPLE_CHOICES_WITHOUT_NONE, ValueType.MULTIPLE_CHOICES_WITH_NONE].includes(
                        datapoint.type,
                    )
                ) {
                    const allSelectedChoices = [];
                    for (const answer of dependeeAnswer) {
                        const answerData = choices.filter(choice => choice.string_id === answer)[0].choice_value;
                        allSelectedChoices.push(answerData);
                    }
                    dependeeAnswer = allSelectedChoices.join(', ');
                } else if (datapoint.type === ValueType.BOOLEAN) {
                    dependeeAnswer = dependeeAnswer.toLowerCase() === 'true';
                } else if (datapoint.type === ValueType.SINGLE_CHOICE) {
                    dependeeAnswer = choices.find(choice => choice.string_id === dependeeAnswer).choice_value;
                }
                if ([ValueType.BOOLEAN, ValueType.NUMBER, ValueType.INTEGER].includes(datapoint.type)) {
                    nodeCondition = nodeCondition.replace(dependeeQuestionId, dependeeAnswer);
                } else {
                    nodeCondition = nodeCondition.replace(dependeeQuestionId, `"${dependeeAnswer}"`);
                }
            }
        }
    }
    /*********************************
     * NOTE USE OF EVAL()
     *********************************/

    if (isConditionedNodeNeedToBeVisible) {
        // converts the condition and checks if it exists
        nodeCondition = convertLiteralConditionToJsCondition(nodeCondition);
        // eslint-disable-next-line
        isConditionedNodeNeedToBeVisible = eval(nodeCondition);
    }
    return isConditionedNodeNeedToBeVisible;
};

const convertLiteralConditionToJsCondition = condition => {
    // replaces the logical expressions to JS expressions
    const replaceChars = { AND: '&&', OR: '||', DATE: 'new Date', '[': '("', ']': '")' },
        searchString = ' HAS ';
    condition = condition.replace(/AND|OR|\[|]|DATE/g, function(match) {
        return replaceChars[match];
    });
    // replaces HAS expression with _.includes("..") function
    let i = 0,
        endOfHasFullExpression = -1,
        startHas = 0,
        endOfHas = 0,
        fullCondition = '';
    while (i < condition.length) {
        startHas = condition.indexOf(searchString, i);
        if (startHas === -1) {
            i = condition.length;
            fullCondition = fullCondition + condition.slice(endOfHasFullExpression + 1);
        } else {
            fullCondition = fullCondition + condition.slice(endOfHasFullExpression + 1, startHas);

            const containerStringEnd = fullCondition.lastIndexOf('"'),
                containerStringStart = fullCondition.lastIndexOf('"', containerStringEnd - 1);

            fullCondition =
                fullCondition.substring(0, containerStringStart) +
                '_.includes(' +
                fullCondition.substring(containerStringStart, fullCondition.length) +
                ', ';

            endOfHas = startHas + searchString.length;
            endOfHasFullExpression = condition.indexOf('"', endOfHas + 1);
            fullCondition =
                fullCondition + condition.slice(startHas + searchString.length, endOfHasFullExpression + 1) + ')';
            i = endOfHasFullExpression;
        }
    }
    return fullCondition;
};
