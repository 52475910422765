import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { setPolicyRequestAttribute } from 'stores/company/companySlice';
import { useAxiosClient } from 'hooks';
import { EMAIL_REGEX, makeToast } from 'utils';
import Styled from './styled-components';
import { RoundedButton } from 'components';
import CloseIcon from '@material-ui/icons/Close';
import { SignatureContactType } from 'utils/enums';
import {
    Box,
    IconButton,
    FormControl,
    TextField,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@material-ui/core';


const initialFormState = {
    recipient_type: '',
    name: '',
    email: '',
}

const SignaturePopup = ({ policyRequestID, open, handleClose, title = 'Request client e-signature' }) => {
    const dispatch = useDispatch();
    const [formState, setFormState] = useState(initialFormState);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const axiosClient = useAxiosClient();
    const [{ loading }, signatureRequest] = axiosClient({
        method: 'post',
        url: `/submission/${policyRequestID}/signature_request`,
    }, { manual: true });

    useEffect(() => {
        setDisabled(Object.values(formState).some(value => !String(value).trim()) || !isValidEmail(formState.email));
    }, [formState]);

    const isValidEmail = email => {
        return EMAIL_REGEX.test(email);
    }

    const validateEmail= () => {
        setInvalidEmail(!isValidEmail(formState.email));
    }

    const submitPopup = async () => {
        try {
            const res = await signatureRequest({
                data: {
                    recipient_type: formState.recipient_type,
                    recipient_email: formState.email,
                    recipient_name: formState.name
                }
            });
            dispatch(setPolicyRequestAttribute({
                policyRequestID,
                name: 'for_signature_pdf_file_id',
                value: res.data.for_signature_pdf_file_id
            }));
            handleClose();
        } catch (e) {
            makeToast('error', 'Something went wrong, please try again later.');
            handleClose();
            Sentry.captureException(e);
        }
    }

    const updateFormState = e => {
        setFormState({ ...formState, [e.target.name]: e.target.value })
    }

    return (
        <Styled.Dialog
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <Styled.DialogTitle>
                <Box width="100%" display="flex" justifyContent="space-between" fontWeight="fontWeightBold" alignItems="center">
                    <Typography variant="h6">
                        {title}
                    </Typography>
                    <IconButton onClick={handleClose} data-testid="close-popup">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Styled.DialogTitle>
            <Styled.DialogContent className={`expandable ${formState.recipient_type ? '' : 'collapsed'}`}>
                <Box fontWeight="fontWeightLight">
                    <Typography variant="body1">First, select recipient type:</Typography>
                    <Box mt={1}>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="recipient_type" name="recipient_type" value={formState.recipient_type} onChange={updateFormState}>
                                <FormControlLabel value={SignatureContactType.INSURED_CLIENT} control={<Radio color="primary" />} label="Insured Client" />
                                <FormControlLabel value={SignatureContactType.RETAIL_PARTNER} control={<Radio color="primary" />} label="Retail Partner" />
                                {formState.recipient_type === SignatureContactType.RETAIL_PARTNER && (
                                    <Typography variant="body2">
                                        Your retail broker partner will be able to forward the e-signature request to the insured client.
                                    </Typography>
                                )}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    {formState.recipient_type && (
                        <>
                            <Box mt={2}>
                                <FormControl fullWidth>
                                    <TextField
                                        required
                                        name="name"
                                        label={`${_.startCase(formState.recipient_type.replace('_', ' '))} Name`}
                                        value={formState.name}
                                        onChange={updateFormState}
                                    />
                                </FormControl>
                            </Box>
                            <Box mt={2} mb={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        required
                                        error={invalidEmail}
                                        onBlur={validateEmail}
                                        name="email"
                                        label="Email Address"
                                        value={formState.email}
                                        onChange={updateFormState}
                                        helperText={invalidEmail ? 'Please enter a valid email address' : ''}
                                    />
                                </FormControl>
                            </Box>
                        </>
                    )}
                </Box>
            </Styled.DialogContent>
            <Styled.DialogActions>
                <RoundedButton onClick={submitPopup} color="primary" disabled={loading || disabled} py={0.15}>
                    <Typography variant="subtitle2">
                        {loading ? 'Please wait...' : 'Send'}
                    </Typography>
                </RoundedButton>
            </Styled.DialogActions>
        </Styled.Dialog>
    );
}


export default SignaturePopup;
