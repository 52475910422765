import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import Styled from './styled-components';
import GenericDialog from '../../../../../../components/popup/GenericDialog';
import { DirectBrokerDetails, IndirectBrokerFormData } from '../../utils';
import ContactDetails from './ContactDetails';
import IndirectPartnerForm from '../invite-partner-form';
import { Avatar, Hidden } from '@material-ui/core';
import { RetailPartnersContext } from '../../context/RetailPartnersContext';
import ActionsDropdown from './ActionsDropdown';
import SingleActionButton from './SingleActionButton';
import { ReactComponent as ResendMailIcon } from 'components/icons/resend-mail.svg';
import { ReactComponent as CopyLinkIcon } from 'components/icons/copy-link.svg';

interface Props {
    partner: DirectBrokerDetails;
}

const RetailPartnerDetailsRow: FC<Props> = ({ partner }) => {
    const { brokerListByActiveStatus, editBroker, resendBrokerMail, getIndirectBrokerInvitationLink } = useContext(
        RetailPartnersContext,
    );

    const avatarLabel = useMemo(
        () => partner.indirect_broker_user.username.split(' ').map(name => name[0]?.toUpperCase()),
        [partner],
    );

    const initialFormState: IndirectBrokerFormData = {
        id: partner.indirect_broker_user.id,
        username: partner.indirect_broker_user.username,
        email: partner.indirect_broker_user.email,
        partnership_fee: partner.partnership_fee,
        active: partner.active,
    };

    const [dialogOpen, setDialogOpen] = useState(false);
    const [formState, setFormState] = useState<IndirectBrokerFormData>(initialFormState);

    const onEditButtonClick = useCallback(() => {
        setDialogOpen(true);
    }, []);

    const onActivateButtonClick = useCallback(() => {
        editBroker({ ...formState, active: true }, partner);
    }, [formState]);

    const onDeactivateButtonClick = useCallback(() => {
        editBroker({ ...formState, active: false }, partner);
    }, [formState]);

    const onResendLinkButtonClick = useCallback(() => {
        resendBrokerMail(partner.indirect_broker_user.id);
    }, [partner]);

    const onCopyLinkButtonClick = useCallback(async () => {
        const link = await getIndirectBrokerInvitationLink(partner.indirect_broker_user.id);
        await navigator.clipboard.writeText(link);
    }, [partner]);

    const updateFormState = useCallback(
        (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setFormState({ ...formState, [e.target.name]: e.target.value });
        },
        [formState],
    );

    const onDialogClose = useCallback(() => {
        setDialogOpen(false);
    }, []);

    const onDialogSubmit = useCallback(() => {
        editBroker(formState, partner);
        setDialogOpen(false);
    }, [formState, partner]);

    useEffect(() => {
        const initialFormState: IndirectBrokerFormData = {
            id: partner.indirect_broker_user.id,
            username: partner.indirect_broker_user.username,
            email: partner.indirect_broker_user.email,
            partnership_fee: partner.partnership_fee,
            active: partner.active,
        };

        setFormState(initialFormState);
    }, [brokerListByActiveStatus, partner]);

    return (
        <>
            <Box data-testId="retailer-partner-row" mb={1} component={Styled.Container}>
                <Avatar style={{ backgroundColor: `#${partner.color}` }}>{avatarLabel}</Avatar>
                <ContactDetails {...partner.indirect_broker_user} />
                <Box display="flex" flexDirection="row" style={{ gap: 24 }}>
                    {partner.active && (
                        <Hidden mdDown>
                            <SingleActionButton
                                label="Copy Link"
                                labelAfterClick="Link Copied"
                                icon={<CopyLinkIcon />}
                                onClick={onCopyLinkButtonClick}
                            />
                            <SingleActionButton
                                label="Resend Link"
                                labelAfterClick="Link Sent!"
                                icon={<ResendMailIcon />}
                                onClick={onResendLinkButtonClick}
                            />
                        </Hidden>
                    )}

                    <ActionsDropdown
                        partner={partner}
                        onEditClick={onEditButtonClick}
                        onActivateClick={onActivateButtonClick}
                        onDeactivateClick={onDeactivateButtonClick}
                        onResendLinkClick={onResendLinkButtonClick}
                        onCopyLinkClick={onCopyLinkButtonClick}
                    />
                </Box>
            </Box>
            <GenericDialog
                open={dialogOpen}
                title={'Edit Retail Partner Details'}
                body={<IndirectPartnerForm formState={formState} updateFormState={updateFormState} isEditMode={true} />}
                buttonTitle={'Save'}
                handleClose={onDialogClose}
                handleButton={onDialogSubmit}
            />
        </>
    );
};

export default React.memo(RetailPartnerDetailsRow);
