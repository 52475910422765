import React from 'react';
import Styled from './styled-components';
import { Hidden } from '@material-ui/core';
import { RoundedButton } from 'components';
import { Typography, Box } from '@material-ui/core';


const CustomizeButton = ({ handleButtonBar }) => {
    const handleOnClick = () => handleButtonBar('customize');

    return (
        <>
            <Hidden mdUp>
                <Box mt={1} component={Styled.Span} mx={4}>
                    <RoundedButton
                        fullWidth
                        className="secondary"
                        onClick={handleOnClick}
                        disableRipple
                    >
                        <Typography variant="subtitle2">
                            Customize
                        </Typography>
                    </RoundedButton>
                </Box>
            </Hidden>
            <Hidden smDown>
                <Box component={Styled.Span}>
                    <RoundedButton
                        fullWidth
                        className="secondary"
                        onClick={handleOnClick}
                        disableRipple
                    >
                        <Typography variant="subtitle2">
                            Customize
                        </Typography>
                    </RoundedButton>
                </Box>
            </Hidden>
        </>
    )
}


export default CustomizeButton;
