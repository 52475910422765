import React from 'react';
import { IconBox, StyledTypography, DynamicIcon } from 'components';
import { Box, Typography } from '@material-ui/core';


const InfoItem = ({ icon, header, text, link, iconBGColor, ...otherProps }) => {
    return (
        <Box display="flex" mb={3} {...otherProps}>
            <IconBox
                sizes={['48px', '48px', '56px']}
                bgcolor={iconBGColor}
                borderRadius="50%"
                mr={2}>
                <DynamicIcon name={icon} typographyProps={{ variant: 'h5' }} svgIconStyle={{ fill: '#696969' }} />
            </IconBox>
            <Box display="flex" flexDirection="column" justifyContent="center">
                <Box fontWeight="fontWeightBold">
                    <Typography variant="subtitle2">
                        {header}
                    </Typography>
                </Box>
                <Box fontWeight="fontWeightLight">
                    <StyledTypography.SecondaryTypography variant="body2" component="span">
                        {text}
                    </StyledTypography.SecondaryTypography>
                </Box>
                <Box fontWeight="fontWeightMedium">
                    <Typography variant="body2" color="primary">
                        {link}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default InfoItem;
