import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useAxiosClient } from 'hooks';
import { validatePassword } from 'utils';
import { Typography, Hidden, Box } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { StyledForm, RoundedButton, PasswordValidation } from 'components';
import { Logo } from 'components/icons/logo';
import Styled from './styled-components';

const PasswordReset = () => {
    const theme = useTheme();
    const axiosClient = useAxiosClient();
    const history = useHistory();
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [successPasswordReset, setSuccessPasswordReset] = useState(false);
    const [passwordInputType, setPasswordInputType] = useState('password');
    const [resetButtonDisabled, setResetButtonDisabled] = useState(true);
    const [formError, setFormError] = useState('');
    const [, passwordReset] = axiosClient(
        {
            method: 'post',
            url: `/auth/reset/${token}`,
        },
        { manual: true }
    );

    const handleSubmit = async e => {
        e.preventDefault();

        try {
            const res = await passwordReset({ data: { password: password } });
            if (res.data.status === 'success') {
                setSuccessPasswordReset(true);
            }
        } catch (error) {
            if (error.response?.data?.password_not_unique) {
                setFormError('Password too similar to email, first name or last name');
            } else if (error.response?.data?.invalid_password) {
                setFormError('Invalid password, please try again');
            } else if (!error.response?.data?.valid_token) {
                setFormError('Reset link expired, please reset password again');
            } else {
                setFormError('An error occurred, please try again');
            }
        }
    };

    useEffect(() => {
        const validationResult = validatePassword(password);
        const valLength = validationResult.length;

        if (valLength === 0) {
            setResetButtonDisabled(false);
        } else if (valLength === 1) {
            if (validationResult.includes('digits') || validationResult.includes('symbols')) {
                setResetButtonDisabled(false);
            } else if (validationResult.includes('max')) {
                setResetButtonDisabled(true);
            }
        } else {
            setResetButtonDisabled(true);
        }

        if (validationResult.includes('max')) {
            setFormError('Password cannot be more than 30 characters');
        } else {
            setFormError('');
        }
    }, [password]);

    const handlePasswordOnChange = value => {
        setPassword(value);
    };

    const handleReturnToSignIn = () => {
        history.push('/user/login');
    };

    const renderShowHideText = () => {
        return passwordInputType === 'password' ? 'Show' : 'Hide';
    };

    const togglePasswordInputType = () => {
        return passwordInputType === 'password' ? setPasswordInputType('text') : setPasswordInputType('password');
    };

    const renderShowHideButton = () => {
        return (
            <Typography style={{ cursor: 'pointer' }} variant="body2" color="primary" onClick={togglePasswordInputType}>
                {renderShowHideText()}
            </Typography>
        );
    };

    return (
        <Styled.FormContainer>
            <Hidden smDown>
                <Styled.LogoContainer>
                    <Logo />
                </Styled.LogoContainer>
            </Hidden>
            {successPasswordReset ? (
                <>
                    <Styled.FormHeader>Password Saved!</Styled.FormHeader>
                    <Styled.FormText variant="subtitle2">
                        Click below to sign in with your new password.
                    </Styled.FormText>
                    <Box marginLeft="75px" marginTop="25px">
                        <RoundedButton py={0.4} width="185px" onClick={handleReturnToSignIn}>
                            <Typography variant="subtitle2">Sign In</Typography>
                        </RoundedButton>
                    </Box>
                </>
            ) : (
                <>
                    <Styled.FormHeader>Set New Password</Styled.FormHeader>
                    <PasswordValidation password={password} />
                    <StyledForm.Form style={{ marginTop: theme.spacing(5) }} onSubmit={handleSubmit}>
                        <Styled.TextField
                            value={password}
                            onChange={e => handlePasswordOnChange(e.target.value)}
                            required
                            type={passwordInputType}
                            name="password"
                            placeholder="New Password..."
                            label={
                                password ? (
                                    <Typography variant="body2" component="span">
                                        New Password
                                    </Typography>
                                ) : (
                                    ''
                                )
                            }
                            InputLabelProps={{
                                required: false,
                                shrink: true,
                            }}
                            error={Boolean(formError)}
                            helperText={formError ? formError : ''}
                            InputProps={{ endAdornment: renderShowHideButton() }}
                        />
                        <RoundedButton type="submit" py={0.4} width="101px" mb={1} disabled={resetButtonDisabled}>
                            <Typography variant="subtitle2">Save</Typography>
                        </RoundedButton>
                    </StyledForm.Form>
                </>
            )}
        </Styled.FormContainer>
    );
};

export default PasswordReset;
