import styled from 'styled-components';
import _Dialog from '@material-ui/core/Dialog';
import _DialogActions from '@material-ui/core/DialogActions';
import _DialogContent from '@material-ui/core/DialogContent';
import _DialogTitle from '@material-ui/core/DialogTitle';
import _TextField from '@material-ui/core/TextField';

const Dialog = styled(_Dialog)`
    &&& {
        &.MuiDialog-paper {
            box-shadow: none;
            border-radius: unset;
            width: 550px;
        }
    }
`;

const DialogActions = styled(_DialogActions)`
    &&& {
        background: #f2f2f2;
        justify-content: space-between;
    }
`;

const DialogTitle = styled(_DialogTitle)`
    &&& {
        border: 0;
        background: #333;
        h2 {
            overflow: hidden;
            margin: 0;
            coloR: #fff;
            font-weight: 400;
            font-size: 2em;
            font-family: Pressura;
            text-overflow: ellipsis;
            letter-spacing: .0063em;
            white-space: nowrap;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
`;

const DialogContent = styled(_DialogContent)`
    &&& {
        padding: 30px;
    }
`;

const DialogContentTitle = styled.h4`
    &&& {
        margin: 0 0 20px;
        font-family: America;
        font-size: 1.6em;
        font-weight: 300;
    }
`;

const DialogInput = styled(_TextField)`
    &&& {
        margin: 0.8rem 0;

        select {
            color: rgba(0, 0, 0, 0.87);

            &:focus {
                background-color: inherit;
            }
        }
    }
`;

export default {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentTitle,
    DialogInput,
    DialogTitle,
};
