import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { conditionedNodeNeedToBeVisible } from 'utils/questionsConditions';
import { useBreakpoint } from 'hooks';
import { useSelector } from 'react-redux';

const Note = ({ question }) => {
    const { questionsToAnswers } = useSelector(state => state.questions);
    const { sectionsData } = useSelector(state => state.questionnaire);
    const isSm = useBreakpoint('down', 'sm');
    const [visible, setVisible] = useState(
        !question.datapoint.note_condition ||
            conditionedNodeNeedToBeVisible(question.datapoint.note_condition, sectionsData, questionsToAnswers)
    );

    useEffect(() => {
        if (question.datapoint.note_condition) {
            setVisible(
                conditionedNodeNeedToBeVisible(question.datapoint.display_condition, sectionsData, questionsToAnswers),
            );
        }
    }, [questionsToAnswers]);

    return (
        visible && (
            <Box
                px={isSm ? 4 : 0}
                pt={isSm ? 0.5 : 1.5}
                pb={isSm ? 2 : 1}
                textAlign="left"
                lineHeight="15px"
                display="flex"
                justifyContent="center"
                key={`${question.datapoint.id}_question`}>
                <Typography variant="body2" style={{maxWidth: isSm ? 'unset' : '424px' }}>{question.datapoint.text}</Typography>
            </Box>
        )
    );
};

export default Note;
