import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {FooterState, SetShowFooterActionPayloadType, SetShowHeaderActionPayloadType} from './types';

const initialState: FooterState = {
    showFooter: true,
    showHeader: true,
};

const footerSlice = createSlice({
    name: 'footer',
    initialState,
    reducers: {
        setShowFooter(state, action: PayloadAction<SetShowFooterActionPayloadType>) {
            state.showFooter = action.payload;
        },
        setShowHeader(state, action: PayloadAction<SetShowHeaderActionPayloadType>) {
            state.showHeader = action.payload;
        },
    },
});

export const {
    setShowFooter,
    setShowHeader,
} = footerSlice.actions;

export default footerSlice.reducer;
