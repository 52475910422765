import React, { useState } from 'react';
import { StyledAction, StyledTypography, StyledTable } from 'components';
import { Box, Typography, TableRow } from '@material-ui/core';
import { getSignatureStatusText } from 'utils';
import { SignaturePopup } from 'components/popup';
import { Hidden } from '@material-ui/core';

const Signature = ({ policyRequest }) => {
    const [openSignaturePopup, setOpenSignaturePopup] = useState(false);
    const signatureStatusText = getSignatureStatusText(policyRequest);

    return (
        <>
            <Hidden smDown>
                <TableRow>
                    <StyledTable.TableCell colSpan={6}>
                        <Box component="span" fontWeight="fontWeightLight">
                            <Typography variant="subtitle2" component="span">
                                Need a signed application?
                            </Typography>
                        </Box>
                        <Box component="span" fontWeight="fontWeightMedium" ml={1}>
                            {signatureStatusText ? (
                                <StyledTypography.FadedTypography variant="subtitle2" component="span">
                                    – {signatureStatusText}
                                </StyledTypography.FadedTypography>
                            ) : (
                                <StyledAction.Base onClick={() => setOpenSignaturePopup(true)}>
                                    <Typography variant="subtitle2" component="span" color="primary">
                                        – Send the app to your client for e-signature.
                                    </Typography>
                                </StyledAction.Base>
                            )}
                        </Box>
                    </StyledTable.TableCell>
                </TableRow>
            </Hidden>
            <Hidden mdUp>
                <TableRow>
                    <StyledTable.TableCell colSpan={6}>
                        <Box fontWeight="fontWeightLight">
                            <Typography variant="subtitle2" component="span">
                                Need a signed application?
                            </Typography>
                        </Box>
                        <Box fontWeight="fontWeightMedium">
                            {signatureStatusText ? (
                                <StyledTypography.FadedTypography variant="subtitle2">
                                    – {signatureStatusText}
                                </StyledTypography.FadedTypography>
                            ) : (
                                <StyledAction.Base onClick={() => setOpenSignaturePopup(true)}>
                                    <Typography variant="subtitle2" color="primary">
                                        – Send the app to your client for e-signature.
                                    </Typography>
                                </StyledAction.Base>
                            )}
                        </Box>
                    </StyledTable.TableCell>
                </TableRow>
            </Hidden>
            {!signatureStatusText && (
                <SignaturePopup
                    open={openSignaturePopup}
                    handleClose={() => setOpenSignaturePopup(false)}
                    policyRequestID={policyRequest.id}
                />
            )}
        </>
    );
};

export default Signature;
