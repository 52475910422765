import React, {useMemo} from 'react';
import { ProductsAbbreviatedName } from 'utils/enums';
import { Box, Hidden, Typography } from '@material-ui/core';
import { ButtonBar, RoundedButton } from 'components';


const BindAndCustomizeButton = ({ productName, handleButton, handleButtonBar, uncheckedPreBindContingencies }) => {
    let hasUncheckedPreBindContingencies = useMemo(() => uncheckedPreBindContingencies !== undefined && uncheckedPreBindContingencies > 0, [uncheckedPreBindContingencies])
    return (
        <>
            <Hidden mdUp>
                {productName === ProductsAbbreviatedName.MPL ? (
                    <Box mt={1} textAlign="center" fullWidth px={4}>
                        <RoundedButton fullWidth onClick={handleButton}>
                            <Typography variant="subtitle2">{hasUncheckedPreBindContingencies ? 'Review': 'Bind'}</Typography>
                        </RoundedButton>
                    </Box>
                ) : (
                    <Box mt={1} textAlign="center">
                        <ButtonBar
                            py={1.5}
                            clickHandler={handleButtonBar}
                            data={[{
                                content: 'Review / Customize',
                                primary: false,
                                id: 'review'
                            },
                            {
                                content: 'Bind',
                                primary: true,
                                id: 'bind'
                            }]}
                        />
                    </Box>
                )}
            </Hidden>
            <Hidden smDown>
                {productName === ProductsAbbreviatedName.MPL ? (
                    <RoundedButton py={0.25} px={7} onClick={handleButton}>
                        <Typography variant="subtitle2">{hasUncheckedPreBindContingencies ? 'Review': 'Bind'}</Typography>
                    </RoundedButton>
                ) : (
                    <ButtonBar
                        py={0.8}
                        clickHandler={handleButtonBar}
                        variant={!hasUncheckedPreBindContingencies ? 'body1' : 'subtitle2'}
                        data={[{
                            content: 'Customize',
                            primary: false,
                            id: 'customize'
                        },
                        {
                            content: !hasUncheckedPreBindContingencies ? 'Review' : 'Bind',
                            primary: true,
                            id: 'bind'
                        }]}
                    />
                )}
            </Hidden>
        </>
    )
}



export default BindAndCustomizeButton;
