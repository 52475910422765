import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAxiosClient } from 'hooks';
import { isValidDomain } from 'utils';
import { CreateSubmissionFormType } from 'utils/enums';
import { setAvailableProducts } from 'stores/globalData/globalDataSlice';
import Styled from './styled-components';
import { RoundedButton, Loading, StyledAction, StyledSelectField, StyledInput } from 'components';
import CloseIcon from '@material-ui/icons/Close';
import { Box, IconButton, FormControl, Typography, FormControlLabel } from '@material-ui/core';
import CustomCheckbox from 'components/checkbox/CustomCheckbox';


const initialFormState = {
    product_id: '',
    name: '',
    domain: '',
    has_website: true,
};

const HAS_WEBSITE_KEY = 'has_website';

const CompanyPopup = ({
    company,
    policyRequestID,
    open,
    handleClose,
    title = 'Start Application',
    setOpenNotification,
    setAlertText
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { availableProducts } = useSelector(state => state.globalData);
    const [formState, setFormState] = useState(initialFormState);
    const [invalidDomain, setInvalidDomain] = useState(false);
    const [websiteError, setWebsiteError] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [wasClicked, setWasClicked] = useState(false);
    const [companyViewable, setCompanyViewable] = useState(false);
    const [existingCompanyId, setExistingCompanyId] = useState(null);
    const axiosClient = useAxiosClient();
    const [labelProduct, setLabelProduct] = useState('Select Product...');
    const [labelCompany, setLabelCompany] = useState('Company Name...');
    const [productExistsError, setProductExistsError] = useState(false);
    const [matchCompaniesError, setMatchCompaniesError] = useState(false);
    const [companyNameError, setCompanyNameError] = useState(false);

    const mainError = useMemo(() => {
        return productExistsError
            ? 'Submission already exists for product type'
            : matchCompaniesError
                ? 'Name and Website match different accounts'
                : '';
    }, [productExistsError, matchCompaniesError]);

    const isDomainRequired = useMemo(() => {
        if (availableProducts) {
            const product = availableProducts.find(product => product.id === formState.product_id);
            return !product?.is_support_no_domain;
        }
    }, [formState.product_id, availableProducts]);
    const isHasDomainQuestionSupported = useMemo(() => {
        if (availableProducts) {
            const product = availableProducts.find(product => product.id === formState.product_id);
            return product?.is_support_no_domain && product?.name === 'Cyber';
        }

        return false;
    }, [formState.product_id, availableProducts]);
    const [hasDomainRequiredError, setHasDomainRequiredError] = useState(
        isHasDomainQuestionSupported && !formState.domain && formState.has_website);
    const [labelWebsite, setLabelWebsite] = useState(isDomainRequired ? 'Website...' : 'Website (optional)');
    const [, createPolicyRequest] = axiosClient(
        {
            method: 'post',
            url: '/company/create_submission',
        },
        { manual: true },
    );

    const [, getProducts] = axiosClient(
        {
            method: 'post',
            url: '/product/list',
        },
        { manual: true },
    );

    useEffect(() => {
        setLabelWebsite(isDomainRequired ? 'Website...' : 'Website (optional)');
    }, [isDomainRequired]);


    useEffect(() => {
        setHasDomainRequiredError(isHasDomainQuestionSupported && !formState.domain && formState.has_website)
    }, [formState.domain, formState.has_website, isHasDomainQuestionSupported]);

    useEffect(() => {
        setFormState({
            ...formState,
            name: company?.name || '',
            domain: company?.domain || '',
            has_website: company?.has_website || true,
        });

        const fetchProducts = async () => {
            const res = await getProducts({
                data: {
                    query: { id: {}, name: {}, abbreviated_name: {}, is_technological: {}, is_support_no_domain: {} },
                },
            });
            dispatch(setAvailableProducts(res.data));
        };
        if (!availableProducts) {
            fetchProducts();
        }
    }, []);

    useEffect(() => {
        setDisabled(Object.entries(formState).some(([key, value]) =>
            (!String(value).trim() && (key !== 'domain' || isDomainRequired)) || (formState.domain && !isValidDomain(formState.domain))
            || (hasDomainRequiredError)
        ))
    }, [formState, hasDomainRequiredError]);

    const validateDomain = () => {
        updateFormState(HAS_WEBSITE_KEY, true);

        if (formState.domain) {
            setInvalidDomain(!isValidDomain(formState.domain));
            setLabelWebsite('Website');
        } else {
            if (!isDomainRequired) {
                setInvalidDomain(false);
            }
            setLabelWebsite(isDomainRequired ? 'Website...' : 'Website (optional)');
        }
    };

    const submitPopup = async () => {
        setDisabled(true);
        setWasClicked(true);
        try {
            const data = {
                policy_request_id: policyRequestID || null,
                company_id: company?.id || null,
                product_id: formState.product_id,
                url: formState.domain,
                company_name: formState.name,
                form_name: CreateSubmissionFormType.QUOTE,
            };

            if (isHasDomainQuestionSupported) {
                data[HAS_WEBSITE_KEY] = formState.has_website ? 'True' : 'False';
            }

            const res = await createPolicyRequest({data});
            history.push({
                pathname: `/questionnaire/policy/${res.data.policy_request_id}`,
                state: { resubmit: res.data.resubmit },
            });
        } catch (error) {
            setDisabled(false);
            setWasClicked(false);
            if (error.response?.data?.url) {
                setWebsiteError(true);
            }
            setExistingCompanyId(error.response.data?.existing_company_id);
            setCompanyViewable(error.response.data?.company_viewable);
            setProductExistsError(error.response?.data?.product_id === 'product_already_exists');
            setMatchCompaniesError(
                error.response?.data?.match_different_accounts > 1 ||
                (error.response?.data?.url === 'domain_exists' &&
                    error.response.data?.invalid_company_name &&
                    error.response?.data?.product_id !== 'product_already_exists'),
            );
            setCompanyNameError(error.response.data?.invalid_company_name ? true : false);
        }
    };
    const handleSubmit = () => {
        if (productExistsError) {
            if (companyViewable) {
                history.push(`/company/${existingCompanyId}`);
            } else {
                setAlertText('This account is not currently accessible. Please contact your underwriter.');
                setOpenNotification(true);
            }
        } else {
            submitPopup();
        }
    };

    const updateFormState = (name, value) => {
        setWebsiteError(false);
        setMatchCompaniesError(false);
        setProductExistsError(false);
        setFormState({ ...formState, [name]: value });
        setCompanyNameError(false);
    };

    if (!availableProducts) {
        return <Loading />;
    }

    const getDomainHasError = () => {
        return invalidDomain || websiteError || hasDomainRequiredError;
    }

    return (
        <Styled.Dialog open={open} onClose={handleClose} fullWidth>
            <Styled.DialogTitle>
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    fontWeight="fontWeightBold"
                    alignItems="center">
                    <Typography variant="h6">{title}</Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Styled.DialogTitle>
            <Styled.DialogContent>
                <Box>
                    <FormControl required fullWidth>
                        <StyledSelectField.InputLabel id="product-selection">
                            {labelProduct}
                        </StyledSelectField.InputLabel>
                        <StyledSelectField.Select
                            required
                            name="product_id"
                            onFocus={() => setLabelProduct('Product')}
                            onBlur={() =>
                                formState.product_id ? setLabelProduct('Product') : setLabelProduct('Select Product...')
                            }
                            labelId="product-selection"
                            value={formState.product_id}
                            onChange={e => {updateFormState(e.target.name, e.target.value)}}
                            displayEmpty
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                            }}>
                            {availableProducts.map(product => (
                                <StyledSelectField.MenuItem key={product.id} value={product.id}>
                                    {product.name}
                                </StyledSelectField.MenuItem>
                            ))}
                        </StyledSelectField.Select>
                    </FormControl>
                </Box>
                <Box mt={2}>
                    <FormControl fullWidth>
                        <StyledInput.FormTextField
                            required
                            name="name"
                            label={labelCompany}
                            onFocus={() => setLabelCompany('Company Name')}
                            onBlur={() =>
                                formState.name ? setLabelCompany('Company Name') : setLabelCompany('Company Name...')
                            }
                            value={formState.name}
                            onChange={e => {updateFormState(e.target.name, e.target.value)}}
                            error={companyNameError}
                        />
                    </FormControl>
                </Box>
                <Box mt={2} mb={3}>
                    <FormControl fullWidth>
                        <StyledInput.FormTextField
                            required={isDomainRequired}
                            disabled={isHasDomainQuestionSupported && !formState.has_website}
                            error={getDomainHasError()}
                            onBlur={validateDomain}
                            name="domain"
                            label={labelWebsite}
                            value={formState.domain}
                            onChange={e => {
                                updateFormState(e.target.name, e.target.value);
                            }}
                            helperText={
                                invalidDomain
                                    ? 'Please enter a valid address'
                                    : hasDomainRequiredError
                                        ? 'Please complete this field or check the box below'
                                        : ''
                            }
                            onFocus={() => setLabelWebsite('Website')}
                        />
                    </FormControl>
                    { (isHasDomainQuestionSupported) && (
                        <FormControl
                            fullWidth
                            onChange={() => {
                                updateFormState(HAS_WEBSITE_KEY, !formState.has_website);
                            }}
                            disabled={Boolean(formState.domain)}
                            name={HAS_WEBSITE_KEY}>
                            <FormControlLabel
                                onClick={e => e.preventDefault()}
                                style={{ marginLeft: 0, marginRight: 0, marginTop: getDomainHasError() ? 5 : 0 }}
                                label={
                                    <Typography
                                        variant="body1"
                                        style={{ paddingLeft: 10 }}
                                        onClick={e => e.stopPropagation()}>
                                        <Box
                                            component="span"
                                            color={!formState.has_website ? '#191919' : '#696969'}
                                            fontWeight="fontWeightLight">
                                            { 'Applicant does not have a website' }
                                        </Box>
                                    </Typography>
                                }
                                control={
                                    <CustomCheckbox
                                        onClick={e => e.stopPropagation()}
                                        name={HAS_WEBSITE_KEY}
                                        value={formState.has_website}
                                        checked={!formState.has_website}
                                    />
                                }
                                value={formState.has_website}
                            />

                        </FormControl>
                    )}
                </Box>
            </Styled.DialogContent>
            <Box display="flex" flexDirection="column">
                <Typography color="error" align="center" variant="body2">
                    {mainError}
                </Typography>
                <Styled.DialogActions>
                    <Box component={StyledAction.Base} mr={2} fontWeight="fontWeightBold">
                        <Typography color="primary" onClick={handleClose} variant="subtitle2">
                            Cancel
                        </Typography>
                    </Box>
                    <RoundedButton onClick={handleSubmit} color="#CCCCCC" disabled={disabled} py={0.3} className={productExistsError ? 'black' : null}>
                        <Typography variant="subtitle2">
                            {productExistsError ? 'Go to Client Details' : wasClicked ? 'Please wait...' : 'Next'}
                        </Typography>
                    </RoundedButton>
                </Styled.DialogActions>
            </Box>
        </Styled.Dialog>
    );
};

export default CompanyPopup;
