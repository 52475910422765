import React from 'react';
import { StyledTypography, StyledTable } from 'components';
import { Box, TableRow, Typography } from '@material-ui/core';
import { useBreakpoint } from 'hooks';

const ErpInfo = ({ policyRequest }) => {
    const isSm = useBreakpoint('down', 'sm');
    return (
        <>
            {isSm && (
                <TableRow>
                    <StyledTable.TableCell colSpan={6}>
                        <Box borderBottom="2px solid #E0E0E0" my={1} />
                    </StyledTable.TableCell>
                </TableRow>
            )}
            <TableRow>
                <StyledTable.TableCell colSpan={isSm ? 1 : 6}>
                    <Box display="flex" fontWeight="fontWeightLight" mt={2}>
                        <Box
                            borderRadius="50%"
                            height="12px"
                            width="12px"
                            mt={0.5}
                            mr={3}
                            bgcolor={policyRequest.product.background_color}
                        />
                        <StyledTypography.FadedTypography variant="subtitle2" component="span">
                            ERP Expiration date:
                        </StyledTypography.FadedTypography>
                        <Box component="span" pl={0.5}>
                            <Typography variant="subtitle2">
                                {policyRequest.erp_info.expiration_date}
                                {policyRequest.erp_info.duration
                                    ? policyRequest.erp_info.duration === 1
                                        ? ` (${policyRequest.erp_info.duration} year)`
                                        : ` (${policyRequest.erp_info.duration} years)`
                                    : ''}
                            </Typography>
                        </Box>
                    </Box>
                </StyledTable.TableCell>
            </TableRow>
        </>
    );
};

export default ErpInfo;
