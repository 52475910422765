import React from 'react';
import { useBreakpoint, useEnv } from 'hooks';
import { useDispatch } from 'react-redux';
import {
    PolicyDataDialog,
    StyledAction,
    StyledTypography,
    StyledTable,
    StyledDivider,
    StyledLink,
    RoundedButton,
} from 'components';
import { DocumentMonitor } from 'components/option-to-quote';
import { getRelevantPacketTemplateType } from 'utils/policyRequest';
import { DocumentList } from 'components/option-to-quote';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Box, Hidden, TableRow, Typography, CircularProgress } from '@material-ui/core';
import { setOptionToQuoteAttributes } from 'stores/company/companySlice';

const Documents = ({ optionToQuote, readOnly, policyRequest }) => {
    const isSm = useBreakpoint('down', 'sm');
    const isMd = useBreakpoint('down', 'md');
    const { apiBaseUrl } = useEnv();
    const dispatch = useDispatch();
    const packetTemplateType = getRelevantPacketTemplateType(policyRequest);
    const generatedPacket = optionToQuote.quote[`${packetTemplateType}_generated_packet`];
    const dispatchOptionToQuoteAttribute = (attribute, value) => {
        dispatch(
            setOptionToQuoteAttributes({
                policyRequestID: policyRequest.id,
                optionToQuoteID: optionToQuote.id,
                attributes: [
                    {
                        name: attribute,
                        value: value,
                    },
                ],
            }),
        );
    };

    return (
        <>
            <TableRow>
                <StyledTable.TableCell padding="none" colSpan={2}>
                    <Box fontWeight="fontWeightLight" component="span">
                        <Box
                            display="flex"
                            alignItems="center"
                            flexDirection={isSm ? 'row-reverse' : 'row'}
                            justifyContent={['space-between', 'space-between', 'inherit']}
                            width={isSm ? '100%' : 'fit-content'}
                            paddingBottom={optionToQuote.documentsToggled ? '8px' : 0}
                            pl={isMd ? 0 : 2}>
                            <DocumentMonitor
                                optionToQuote={optionToQuote}
                                policyRequest={policyRequest}
                                monitor={!generatedPacket}
                            />
                            {generatedPacket ? (
                                <>
                                    <Box
                                        component={StyledAction.ExpandableRowControl}
                                        display="flex"
                                        alignItems="center"
                                        flexDirection={isSm ? 'row-reverse' : 'row'}
                                        justifyContent="space-between"
                                        onClick={() =>
                                            dispatchOptionToQuoteAttribute(
                                                'documentsToggled',
                                                !optionToQuote.documentsToggled,
                                            )
                                        }>
                                        <StyledTypography.FadedTypography variant="subtitle1">
                                            <Box mr={[0, 0, 2.5, 3]} display="flex">
                                                {isSm ? (
                                                    <KeyboardArrowRightIcon />
                                                ) : optionToQuote.documentsToggled ? (
                                                    <ExpandLessIcon />
                                                ) : (
                                                    <ExpandMoreIcon />
                                                )}
                                            </Box>
                                        </StyledTypography.FadedTypography>
                                        <StyledTypography.FadedTypography variant="subtitle2" align="justify">
                                            {optionToQuote.documentsToggled ? 'Hide' : readOnly ? 'View' : 'Review'}{' '}
                                            {!isSm && 'documents'}
                                        </StyledTypography.FadedTypography>
                                    </Box>
                                    <Hidden smDown>
                                        <StyledDivider.VerticalDivider orientation="vertical" />
                                    </Hidden>
                                    <StyledTypography.FadedTypography variant="subtitle2" className="hoverable">
                                        <StyledLink.Anchor
                                            style={{ color: 'inherit' }}
                                            href={`${apiBaseUrl}/company/download_packet_merged_file/${
                                                optionToQuote.id
                                            }/${packetTemplateType.toUpperCase()}`}>
                                            Download all documents
                                        </StyledLink.Anchor>
                                    </StyledTypography.FadedTypography>
                                </>
                            ) : (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    flexDirection={isSm ? 'row-reverse' : 'row'}
                                    justifyContent="space-between"
                                    width="100%">
                                    <StyledTypography.FadedTypography variant="subtitle1">
                                        <Box mr={[0, 2, 2.5, 3]} display="flex">
                                            <CircularProgress color="inherit" size={15} />
                                        </Box>
                                    </StyledTypography.FadedTypography>
                                    <StyledTypography.FadedTypography variant="subtitle2" align="justify">
                                        Generating documents
                                    </StyledTypography.FadedTypography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </StyledTable.TableCell>
            </TableRow>
            {optionToQuote.documentsToggled && (
                <Hidden smDown>
                    <DocumentList
                        firstColSpan={3}
                        firstColPadding={isMd ? '3px' : '17px'}
                        firstColMarginRight={isMd ? '16px' : '22px'}
                        optionToQuote={optionToQuote}
                        packetTemplateType={packetTemplateType}
                        policyRequest={policyRequest}
                    />
                </Hidden>
            )}
            <Hidden mdUp>
                <PolicyDataDialog
                    open={optionToQuote.documentsToggled || false}
                    headerExtraText={readOnly ? 'View coverages' : 'Review coverages'}
                    footer={
                        <RoundedButton
                            fullWidth
                            onClick={() => dispatchOptionToQuoteAttribute('documentsToggled', false)}>
                            <Typography variant="subtitle2">Done</Typography>
                        </RoundedButton>
                    }>
                    <Box mt={2} />
                    <DocumentList
                        optionToQuote={optionToQuote}
                        packetTemplateType={packetTemplateType}
                        policyRequest={policyRequest}
                    />
                </PolicyDataDialog>
            </Hidden>
            {optionToQuote.documentsToggled && (
                <TableRow>
                    <StyledTable.TableCell padding="none">
                        <Box mb={5}></Box>
                    </StyledTable.TableCell>
                </TableRow>
            )}
        </>
    );
};

export default Documents;
