import styled from 'styled-components';
import { default as _Box } from '@material-ui/core/Box';

const A = styled.a`
    &&& {
        text-decoration: none;
        color: inherit;
        &:visited {
            color: inherit;
        }
    }
`;

const CompanyNameBox = styled(_Box)`
    &&& {
        display: table;
        cursor: pointer;
        svg {
            fill: #AFAFAF;
        }
        :hover {
            color:  ${({ theme }) => theme.palette.primary.main};
            svg {
                fill: ${({ theme }) => theme.palette.primary.main};
            }
        }
    }
`;

const IconSpan = styled.span`
    &&& {
        ${({ theme }) => `
            padding-left: ${theme.spacing(1)}px;
            vertical-align: middle;
            ${theme.breakpoints.down('sm')} {
                padding-left: ${theme.spacing(0.5)}px;
            }
        `}
    }
`;

export default {
    A,
    CompanyNameBox,
    IconSpan,
}
