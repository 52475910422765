import React, { useEffect, useMemo, useState } from 'react';
import Styled from './styled-components';
import store from 'stores/store';
import { useSelector, useDispatch } from 'react-redux';
import { isPolicyRequestInProgress, makeToast } from 'utils';
import { SubmissionsBucket } from 'utils/enums';
import { useAxiosClient, useAxiosInstance, useEnv } from 'hooks';
import { partialPolicyRequestQuery } from 'queries/policyRequest';
import { policyRequestQuery } from 'queries/company';
import { setPolicyRequest } from 'stores/company/companySlice';
import dayjs from 'dayjs';
import PolicyRequestSkeleton from './components/policy-request-skeleton';
import PolicyHeader from './components/PolicyHeader';
import QuotesAreReady from './components/quotes-are-ready';
import { QuotesAreReadyActions, QuotesAreReadyProductText } from './components/quotes-are-ready/components';
import Completed from './components/completed';
import { CompletedActions, CompletedProductText } from './components/completed/components';
import InProgress from './components/in-progress';
import { InProgressActions, InProgressProductText } from './components/in-progress/components';
import { ClosedActions, ClosedProductText } from './components/closed/components';
import PreProduct from './components/pre-product';
import { PreProductActions, PreProductProductText } from './components/pre-product/components';
import { DeletePolicyRequestPopup } from 'components/popup';
import { StyledAction } from 'components';

import { ReactComponent as ClipboardCheckIcon } from 'components/icons/clipboard-check.svg';
import { ReactComponent as ClipboardIcon } from 'components/icons/clipboard.svg';
import { ReactComponent as ClipboardClosedIcon } from 'components/icons/clipboard-closed.svg';
import { ReactComponent as LoadingIcon } from 'components/icons/loading.svg';
import { ReactComponent as ClockIcon } from 'components/icons/clock.svg';
import { ReactComponent as CautionIcon } from 'components/icons/caution.svg';
import { Box, Typography } from '@material-ui/core';
import Notice from './components/Notice';
import { BorErrorActions, BorErrorHeader, BorErrorMessage } from './components/borError';
import { PolicyOwner } from 'components/policy';


const bucketToData = {
    [SubmissionsBucket.QUOTED]: {
        component: QuotesAreReady,
        getIcon: () => ClipboardCheckIcon,
        productTextComponent: QuotesAreReadyProductText,
        actions: QuotesAreReadyActions,
    },
    [SubmissionsBucket.PRE_QUOTE]: {
        component: InProgress,
        getIcon: policyRequest => {
            return policyRequest.inProgress ? LoadingIcon : ClockIcon;
        },
        productTextComponent: InProgressProductText,
        actions: InProgressActions,
        setMargin: 7,
    },
    [SubmissionsBucket.BOUND]: {
        component: Completed,
        getIcon: () => ClipboardCheckIcon,
        productTextComponent: CompletedProductText,
        actions: CompletedActions,
        removeMargin: true,
    },
    [SubmissionsBucket.BIND_REQUESTED]: {
        component: Completed,
        getIcon: () => ClipboardCheckIcon,
        productTextComponent: CompletedProductText,
        actions: CompletedActions,
    },
    [SubmissionsBucket.DECLINED]: {
        component: null,
        getIcon: () => ClipboardClosedIcon,
        productTextComponent: ClosedProductText,
        actions: ClosedActions,
        removeMargin: true,
    },
    [SubmissionsBucket.EXPIRED]: {
        component: Completed,
        getIcon: () => ClipboardClosedIcon,
        productTextComponent: ClosedProductText,
        actions: ClosedActions,
        removeMargin: true,
    },
    [SubmissionsBucket.LOST]: {
        component: null,
        getIcon: () => ClipboardClosedIcon,
        productTextComponent: ClosedProductText,
        actions: ClosedActions,
        removeMargin: true,
    },
    [SubmissionsBucket.CANCELED]: {
        component: Completed,
        getIcon: () => ClipboardIcon,
        productTextComponent: ClosedProductText,
        actions: ClosedActions,
        removeMargin: true,
    },
};

const preProductData = {
    component: PreProduct,
    getIcon: () => ClipboardIcon,
    productTextComponent: PreProductProductText,
    actions: PreProductActions,
};

const borConflictData = {
    component: BorErrorActions,
    getIcon: () => CautionIcon,
    iconColor: '#191919',
    iconBgColor: '#FFEB00',
    productTextComponent: BorErrorHeader,
    actions: BorErrorMessage,
    removeMargin: true,
};

const PolicyRequest = ({ policyRequestID, company }) => {
    const axiosClient = useAxiosClient();
    const axiosInstance = useAxiosInstance();
    const { teamView } = useEnv();
    const dispatch = useDispatch();
    const [openPopup, setOpenPopup] = useState(false);
    const { viewable_policy_requests, id } = useSelector(state => state.company.company);
    const policyRequest = viewable_policy_requests.find(policyRequest => policyRequest.id === policyRequestID);
    const singlePolicyRequest = viewable_policy_requests.length === 1;
    const firstRequestTime = useMemo(() => dayjs.utc().unix(), []);

    const [, getFullPolicyRequest] = axiosClient(
        {
            method: 'post',
            url: `/policyRequest/${policyRequestID}`,
            data: { query: policyRequestQuery },
        },
        { manual: true },
    );

    const getAndSetPolicyRequest = async () => {
        const res = await getFullPolicyRequest();
        const policyRequest = {
            ...res.data,
            inProgress: isPolicyRequestInProgress(res.data, firstRequestTime),
        };
        dispatch(setPolicyRequest(policyRequest));
        return policyRequest;
    };

    const fetchFullPolicyRequest = async () => {
        const policyRequest = await getAndSetPolicyRequest();
        if (policyRequest.inProgress) {
            setTimeout(monitorPolicyRequest, 2000);
        }
    };

    const monitorPolicyRequest = async () => {
        try {
            const companyStore = store.getState().company.company;
            if (!companyStore) {
                return;
            }
            const { viewable_policy_requests } = companyStore;
            const res = await axiosInstance.post(`/policyRequest/${policyRequestID}`, {
                query: partialPolicyRequestQuery,
            });
            const partialPolicyRequest = res.data;
            const policyRequest = viewable_policy_requests.find(policyRequest => policyRequest.id === policyRequestID);
            let gotFullPolicyRequest = false;
            if (isPolicyRequestInProgress(partialPolicyRequest, firstRequestTime)) {
                setTimeout(monitorPolicyRequest, 2000);
            } else {
                getAndSetPolicyRequest();
                gotFullPolicyRequest = true;
            }
            if (!gotFullPolicyRequest && partialPolicyRequest?.quotes_from_auto_quote?.length) {
                const finishedQuotes = partialPolicyRequest.quotes_from_auto_quote.filter(
                    quote => quote.option_to_quote?.published,
                );
                if (finishedQuotes.length > (policyRequest.option_to_quotes?.length || 0)) {
                    getAndSetPolicyRequest();
                }
            }
        } catch (error) {
            if (error.response?.status !== 403) {
                makeToast('error', 'Something went wrong. Try coming back later.');
            }
        }
    };

    useEffect(() => {
        fetchFullPolicyRequest();
    }, []);

    useEffect(() => {
        if (policyRequest.forceReload) {
            fetchFullPolicyRequest();
        }
    }, [policyRequest.forceReload]);

    if (!policyRequest?.athena_submissions_bucket) {
        return <PolicyRequestSkeleton />;
    }

    const data = policyRequest.product
        ? policyRequest.blocked_by_bor
            ? borConflictData
            : bucketToData[policyRequest.athena_submissions_bucket]
        : preProductData;
    const Component = data.component;

    return (
        <>
            {teamView ? (
                <Box mb={1} pt={0.5}>
                    <PolicyOwner userName={policyRequest?.broker_user?.username} policyRequest={policyRequest} />
                </Box>
            ) : (
                policyRequest.is_deletable &&
                policyRequest.athena_submissions_bucket !== SubmissionsBucket.DECLINED && (
                    <Styled.DeleteSubmissionBox mr={[4, 4, 6.825, 0]}>
                        <Typography variant="body2" onClick={() => setOpenPopup(true)}>
                            <StyledAction.Base>Delete Submission</StyledAction.Base>
                        </Typography>
                    </Styled.DeleteSubmissionBox>
                )
            )}
            <Notice policyRequest={policyRequest} />
            <Box p={[0, 4, 7, 10]} bgcolor="white" mb={3}>
                <PolicyHeader
                    removeMargin={data.removeMargin}
                    policyRequest={policyRequest}
                    company={company}
                    getIcon={data.getIcon}
                    iconColor={data.iconColor}
                    iconBgColor={data.iconBgColor}
                    ProductTextComponent={data.productTextComponent}
                    Actions={data.actions}
                    setMargin={data.setMargin}
                />
                {Component && <Component policyRequest={policyRequest} company={company} />}
            </Box>
            {/* Todo:  after deleting REACT_APP_TEAM_VIEW feature flag - we can delete this component from here*/}
            <DeletePolicyRequestPopup
                open={openPopup}
                handleClose={() => setOpenPopup(false)}
                policyRequestID={policyRequest.id}
                companyID={id}
                singlePolicyRequest={singlePolicyRequest}
            />
        </>
    );
};

export default PolicyRequest;
