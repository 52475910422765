import React from 'react';
import { useParams } from 'react-router-dom';
import { IFrame } from 'components';
import { useEnv } from 'hooks';

const Preview = () => {
    const { questionnaireId } = useParams();
    const { apiBaseUrl } = useEnv();
    const url = `${apiBaseUrl}/questionnaire/preview/${questionnaireId}`;

    return <IFrame src={url} />;
};

export default Preview;
