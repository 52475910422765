import React from 'react';
import Styled from '../../styled-components'
import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import AutorenewIcon from '@material-ui/icons/Autorenew';


const ResetButton = ({ handleReset }) => {
    const { customizationLoading } = useSelector(state => state.company);

    return (
        <Styled.ResetButton
            onClick={handleReset}
            disabled={customizationLoading.disableSaveQuote}
            arial-label="reset"
        >
            <Box mr={0.7} display="flex" alignItems="center">
                <AutorenewIcon />
            </Box>
            <Typography variant="subtitle2">
                Reset
            </Typography>
        </Styled.ResetButton>
    )
}

export default ResetButton;
