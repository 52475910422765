import React, { useEffect, useState } from 'react';
import CustomForm from './CustomForm';
import CustomTextField from './CustomField';
import { NotificationBanner } from 'components/banner';
import { useCreateTeam } from 'api/manage-teams';


const CreateTeam = () => {
    const [teamName, setTeamName] = useState('');
    const [ownerUserEmail, setOwnerUserEmail] = useState('');
    const [ownerUserEmailValidationError, setOwnerUserEmailValidationError] = useState('');
    const [openNotification, setOpenNotification] = useState(false);
    const [{ data, loading, error }, createTeam] = useCreateTeam();

    useEffect(() => {
        if (!loading && data) {
            setOpenNotification(true);
            setOwnerUserEmailValidationError('');
            setTeamName('');
            setOwnerUserEmail('');
        } else if (error) {
            setOwnerUserEmailValidationError(error.response.data.message);
        }
    }, [data, error]);

    const submitCreateTeam = e => {
        e.preventDefault();
        createTeam({
            data: {
                team_name: teamName,
                owner_email: ownerUserEmail,
            },
        });
    };

    return (
        <>
            <CustomForm
                handleSubmit={submitCreateTeam}
                header="Create team"
                buttonText="Create team"
                fieldsList={[
                    <CustomTextField
                        value={teamName}
                        setValue={setTeamName}
                        name="team name"
                        validationError=""
                        setValidationError={() => {}}
                    />,
                    <CustomTextField
                        value={ownerUserEmail}
                        setValue={setOwnerUserEmail}
                        name="owner user email"
                        validationError={ownerUserEmailValidationError}
                        setValidationError={setOwnerUserEmailValidationError}
                        type="email"
                    />,
                ]}
            />
            <NotificationBanner
                openNotification={openNotification} alertText="New team was created."
                setOpenNotification={setOpenNotification} isError={false}
            />
        </>
    );
};

export default CreateTeam;
