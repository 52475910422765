import React from 'react';
import Styled from 'modules/submission/pages/quote-customization/components/styled-components';
import { useBreakpoint, useReconstructOtherParameterList } from 'hooks';
import { Box, Typography } from '@material-ui/core';
import { AggregateType } from 'utils/enums';
import { totalHumanize } from 'utils';
import { useStyles } from 'utils/customization';

const SelectLimit = ({ lineItem, handleChangeLineItem }) => {
    const isSm = useBreakpoint('down', 'sm');
    const allowedLimits = useReconstructOtherParameterList(
        lineItem.allowed_limits,
        lineItem.code,
        lineItem.type,
        AggregateType.LIMIT,
    );
    const classes = useStyles();

    return (
        <Box mr={1}>
            {lineItem.limit ? (
                <Styled.Select
                    value={lineItem.limit}
                    onChange={event =>
                        handleChangeLineItem(
                            event,
                            AggregateType.LIMIT,
                            lineItem.code
                        )
                    }
                    className={isSm ? classes.SelectMobile : classes.Select}
                >
                    <Box component={Styled.SelectTitle} py={1} px={1.5}>
                        <Typography variant="overline">LIMIT</Typography>
                    </Box>
                    {allowedLimits.map(limit => {
                        if (limit.id === 'fakeId') {
                            return (
                                <Box
                                    component={Styled.SelectTitle}
                                    py={1}
                                    px={1.5}
                                    borderTop="1px solid #F2F2F2"
                                    value={limit.value}
                                    key={`${lineItem.coverage_name}-aggregate-limit-${limit.id}`}
                                >
                                    <Typography variant="overline">
                                        {limit.value}
                                    </Typography>
                                </Box>
                            );
                        }
                        return (
                            <Box
                                component={Styled.MenuItem}
                                key={`${lineItem.coverage_name}-aggregate-limit-${limit.id}`}
                                value={limit.value}
                            >
                                <Typography variant="body1">
                                    ${totalHumanize(limit.value)}
                                </Typography>
                            </Box>
                        );
                    })}
                </Styled.Select>
            ) : (
                <Typography
                    component={Box}
                    className={classes.DisabledSelect}
                    variant="body1"
                    height="21px"
                    width={isSm ? 82 : 102}
                    border="1px solid #E6E6E6"
                    p={1}
                >
                    $0
                </Typography>
            )}
        </Box>
    );
};

export default SelectLimit;
