import styled from 'styled-components';

const IFrame = styled.iframe`
    &&& {
        width: 100%;
        height: 100%;
        border: none;
    }
`;

export default {
    IFrame,
};
