import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { StyledLink, StyledTypography } from 'components';
import { useBreakpoint } from 'hooks';
import { useSelector } from 'react-redux';
import { BrokerRole } from '../../../../../../../../../../../utils/enums';

const BorError = ({ policyRequest }) => {
    const isSm = useBreakpoint('down', 'sm');
    const { user } = useSelector((state) => state.user);
    const isRetailUser = user?.role.name === BrokerRole.RETAIL;

    const uwEmailLink = (
        <Box fontWeight="fontWeightMedium" component="span">
            <Typography color="primary" variant="subtitle2" component="span">
                <StyledLink.Anchor href={`mailto:${policyRequest.uw_email}`} target="_blank">
                    {policyRequest.uw_email}
                </StyledLink.Anchor>
            </Typography>
        </Box>
    );

    const borDesktopTabletMessage = (
        isRetailUser
            ?
            <StyledTypography.FadedTypography variant="subtitle2" component="span">
                We have already received a submission for this insured from another broker.
            </StyledTypography.FadedTypography>
            :
            <>
                <StyledTypography.FadedTypography variant="subtitle2" component="span">
                    We have already received a submission for this insured from another broker. Please verify that
                    you have
                    provided a full address (including suite, floor, etc).
                    <br/>
                    <br/>
                    To be recognized as the Broker of Record, send a letter signed by the insured to{' '}
                </StyledTypography.FadedTypography>
                {uwEmailLink}
                <StyledTypography.FadedTypography variant="subtitle2" component="span">
                    {'. '}Please note there is a five-day waiting period before terms are released.
                </StyledTypography.FadedTypography>
            </>
    );

    const borMobileMessage = (
        isRetailUser ?
            <StyledTypography.FadedTypography variant="subtitle2" component="span">
                We have already received a submission for this insured from another broker.
            </StyledTypography.FadedTypography>
            :
            <>
                <StyledTypography.FadedTypography variant="subtitle2" component="span">
                    We found a submission from another broker. Please verify the full address is provided (including
                    suite,
                    etc).
                    <br/>
                    <br/>
                    To be Broker of Record, send a letter signed by the insured to{' '}
                </StyledTypography.FadedTypography>
                {uwEmailLink}
                <StyledTypography.FadedTypography variant="subtitle2" component="span">
                    {'. '}Terms are released after a 5-day waiting period.
                </StyledTypography.FadedTypography>
            </>
    );

    return !isSm ? borDesktopTabletMessage : borMobileMessage;
};

export default BorError;
