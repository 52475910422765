import { UseMutateAsyncFunction, useMutation, useQueryClient } from 'react-query';
import { useAxiosInstance } from '../../../hooks';
import { DashboardQueryKeys } from '../../queryKeys';
import { AxiosInstance } from 'axios';

type SuccessResponseType = {
    premium: number;
    total_premium: number;
    quote_builder_id: number;
};

type RequestCalculateQuoteDataType = {
    optionToQuoteId: string;
    line_items: any[];
    per_claim_limit: number;
    persist_calculation_state: boolean;
    quote_builder_id: number;
};

type MutationReturnType = {
    mutateAsync: UseMutateAsyncFunction<SuccessResponseType, unknown, RequestCalculateQuoteDataType, unknown>;
    error: unknown;
    isLoading: boolean;
};

const calculateQuote = async (quote: RequestCalculateQuoteDataType, axiosInstance: AxiosInstance) => {
    const { optionToQuoteId, ...data } = quote;
    const res = await axiosInstance.post(`/optionToQuote/${quote.optionToQuoteId}/calculate`, data);
    return res.data as SuccessResponseType;
};

export const useCalculateQuote = (): MutationReturnType => {
    const queryClient = useQueryClient();
    const axiosInstance = useAxiosInstance();
    const { mutateAsync, error, isLoading } = useMutation(
        (quote: RequestCalculateQuoteDataType) => calculateQuote(quote, axiosInstance),
        {
            onSettled: () => {
                queryClient.resetQueries([DashboardQueryKeys.BUCKETS.QUOTES, DashboardQueryKeys.SUB_BUCKETS.OPEN]);
            },
        },
    );
    return { mutateAsync, error, isLoading };
};
