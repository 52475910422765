import React, { useState, useReducer, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dateDifference, formatDatePickerDate, makeDatePickerDate, makeToast } from 'utils';
import accounting from 'accounting';
import { ProductsAbbreviatedName, MplRetroactiveDateType, PolicyType } from 'utils/enums';
import { getIsAnnualPolicy } from 'stores/selectors';
import {
    setPolicyRequestDates,
    addContingency,
    removeContingency,
    setOptionToQuoteAttributes,
    setDateValidity,
} from 'stores/company/companySlice';
import { useBreakpoint, useAxiosClient, useHasInvalidDates, useIsFirstRender } from 'hooks';
import {
    PolicyDataDialog,
    StyledAction,
    StyledTypography,
    StyledCard,
    RoundedButton,
    NotificationBanner,
} from 'components';
import { DateTextInput, DateRadioInput } from 'components/policy';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Box, Hidden, Typography } from '@material-ui/core';

const initialShownDatesState = {
    expiration_effective_dates: false,
};

const shownDatesReducer = (state, action) => {
    switch (action.type) {
        case 'toggle':
            return { ...state, [action.payload.name]: action.payload.value };
        case 'reset':
            return {
                ...initialShownDatesState,
                ...(action.payload ? { [action.payload.name]: action.payload.value } : {}),
            };
        default:
            return state;
    }
};

const Dates = ({ readOnly, getUpdatedPremium, setSavingDates = () => {} }) => {
    const dispatch = useDispatch();
    const axiosClient = useAxiosClient();
    const isSm = useBreakpoint('down', 'sm');
    const isMd = useBreakpoint('down', 'md');
    const {
        id: policyRequestId,
        effective_date,
        expiration_date,
        continuity_date,
        prior_and_pending_litigation_date,
        teo_retroactive_date,
        mpl_retroactive_date,
        mpl_retroactive_date_type,
        product,
        last_option_to_quote_publish_date,
        type,
    } = useSelector(state => state.company.optionToQuote.quote.policy_request);
    const tempDatesInitialState = {
        effective_date: makeDatePickerDate(effective_date || new Date()),
        expiration_date: makeDatePickerDate(expiration_date || new Date()),
        continuity_date: makeDatePickerDate(continuity_date || new Date()),
        prior_and_pending_litigation_date: makeDatePickerDate(prior_and_pending_litigation_date || new Date()),
    };
    const isTEO = product.abbreviated_name === ProductsAbbreviatedName.CYBER_TEO;
    if (isTEO) {
        tempDatesInitialState.teo_retroactive_date = makeDatePickerDate(teo_retroactive_date || new Date());
    }
    const isMPL = product.abbreviated_name === ProductsAbbreviatedName.MPL;
    if (isMPL) {
        tempDatesInitialState.mpl_retroactive_date = mpl_retroactive_date
            ? makeDatePickerDate(mpl_retroactive_date)
            : null;
        tempDatesInitialState.mpl_retroactive_date_type = { name: {}, value: {} };
        tempDatesInitialState.mpl_retroactive_date_type = mpl_retroactive_date_type;
    }
    const hasInvalidDates = useHasInvalidDates();
    const [shownDates, shownDatesReducerDispatch] = useReducer(shownDatesReducer, initialShownDatesState);
    const [tempDates, setTempDates] = useState(tempDatesInitialState);
    const [showPrimaryDates, setShownPrimaryDates] = useState(false);
    const [showOtherDates, setShowOtherDates] = useState(false);
    const [disabled, setDisabled] = useState(hasInvalidDates);
    const [dateTypeValue, setDateTypeValue] = useState(
        mpl_retroactive_date_type?.name !== MplRetroactiveDateType.TO_MATCH_EXPIRING || mpl_retroactive_date
            ? mpl_retroactive_date_type?.name
            : null,
    );
    const [openNotification, setOpenNotification] = useState(false);
    const { quote } = useSelector(state => state.company.optionToQuote);
    const isAnnualPolicy = getIsAnnualPolicy(quote);

    useEffect(() => {
        setDisabled(hasInvalidDates);
    }, [hasInvalidDates]);

    const isFirstRendered = useIsFirstRender();

    useEffect(() => {
        if (isMPL && mpl_retroactive_date_type?.name !== MplRetroactiveDateType.POLICY_INCEPTION && !isFirstRendered) {
            handleShowOtherDates();
        }
    }, [expiration_date]);

    const [, setPolicyDate] = axiosClient(
        {
            method: 'put',
            url: `/policyRequest/${policyRequestId}`,
        },
        { manual: true },
    );

    const [, getPolicyRequestDates] = axiosClient(
        {
            method: 'post',
            url: `/policyRequest/${policyRequestId}`,
            data: {
                query: {
                    effective_date: {},
                    expiration_date: {},
                    continuity_date: {},
                    prior_and_pending_litigation_date: {},
                    retroactive_date: {},
                    mpl_retroactive_date: {},
                    mpl_retroactive_date_type: {
                        name: {},
                        value: {},
                    },
                },
            },
        },
        { manual: true },
    );

    const dateColors = useMemo(() => {
        const [primaryText, clickableText] = ['textPrimary', 'primary'];
        if (readOnly) {
            return {
                effective_date: primaryText,
                expiration_date: primaryText,
            };
        }
        return {
            effective_date:
                (showPrimaryDates && shownDates.expiration_effective_dates) || type.name === PolicyType.RENEWAL
                    ? primaryText
                    : clickableText,
            expiration_date: showPrimaryDates && shownDates.expiration_effective_dates ? primaryText : clickableText,
        };
    }, [readOnly, showPrimaryDates, shownDates.expiration_effective_dates]);

    const minExpirationDate = useMemo(() => {
        return effective_date ? makeDatePickerDate(effective_date).add(6, 'month') : undefined;
    }, [effective_date]);

    const maxExpirationDate = useMemo(() => {
        return effective_date ? makeDatePickerDate(effective_date).add(18, 'month') : undefined;
    }, [effective_date]);

    const primaryDatesHidden = dates => !dates.expiration_effective_dates;

    const handleShowPrimaryDates = (dateType, fromTitle = false) => {
        if (!readOnly) {
            const currentValues = {
                ...shownDates,
                [dateType]: !shownDates[dateType],
            };
            // if the title was clicked and both dates are not shown, hide the whole section
            let primaryDatesShown = !(
                fromTitle &&
                ((showPrimaryDates && primaryDatesHidden(shownDates)) || primaryDatesHidden(currentValues))
            );
            const dateTypeShown = currentValues[dateType];
            setShownPrimaryDates(primaryDatesShown);
            // if the whole section is hidden, the specific date should also be hidden
            shownDatesReducerDispatch({
                type: 'toggle',
                payload: { name: dateType, value: primaryDatesShown ? dateTypeShown : false },
            });
            setShowOtherDates(false);
        }
    };

    const handleShowOtherDates = () => {
        setShowOtherDates(!showOtherDates);
        setShownPrimaryDates(false);
    };

    const updateDate = async (dateType, date, dateTextType = null, dateText = '') => {
        setSavingDates(true);
        const previousEffectiveDate = effective_date ? makeDatePickerDate(effective_date) : null;
        const previousContinuityDate = continuity_date ? makeDatePickerDate(continuity_date) : null;
        const previousPlpDate = prior_and_pending_litigation_date
            ? makeDatePickerDate(prior_and_pending_litigation_date)
            : null;
        let effectiveDate;
        let expirationDate;
        const payload = {};
        if (dateType) {
            payload[dateType] = date && formatDatePickerDate(date);
        }
        if (dateTextType) {
            payload[dateTextType] = dateText;
        }
        if (dateType === 'effective_date') {
            effectiveDate = makeDatePickerDate(date);
            expirationDate = effectiveDate.add('1', 'year');
            payload.expiration_date = formatDatePickerDate(expirationDate);
        }
        dispatch(setPolicyRequestDates(payload));
        if (dateType === 'effective_date') {
            const tempDatesState = {
                ...tempDates,
                effective_date: effectiveDate,
                expiration_date: expirationDate,
                continuity_date:
                    previousEffectiveDate && previousContinuityDate
                        ? previousContinuityDate.add(dateDifference(effectiveDate, previousEffectiveDate), 'day')
                        : effectiveDate,
                prior_and_pending_litigation_date:
                    previousEffectiveDate && previousPlpDate
                        ? previousPlpDate.add(dateDifference(effectiveDate, previousEffectiveDate), 'day')
                        : effectiveDate,
            };
            if (isTEO) {
                tempDatesState.teo_retroactive_date = effectiveDate;
            }
            if (isMPL) {
                if (mpl_retroactive_date_type.name === MplRetroactiveDateType.POLICY_INCEPTION) {
                    tempDatesState.mpl_retroactive_date = effectiveDate;
                } else {
                    if (tempDatesState.mpl_retroactive_date) {
                        tempDatesState.mpl_retroactive_date = null;
                    }
                }
            }
            setTempDates(tempDatesState);
            const finalDatesState = {};
            for (const dateType in tempDatesState) {
                finalDatesState[dateType] =
                    dateType !== 'mpl_retroactive_date_type' && tempDatesState[dateType]
                        ? formatDatePickerDate(tempDatesState[dateType])
                        : tempDatesState[dateType];
            }
            dispatch(setPolicyRequestDates(finalDatesState));
        }
        try {
            const res = await setPolicyDate({ data: payload });
            setSavingDates(false);
            const contingency = res.data?.contingency;
            if (contingency) {
                if (contingency.deleted) {
                    dispatch(removeContingency(contingency));
                } else {
                    dispatch(addContingency(contingency));
                }
            }

            const { data } = await getUpdatedPremium();
            dispatch(
                setOptionToQuoteAttributes({
                    nestedAttributes: [
                        [
                            'quote',
                            {
                                name: 'premium',
                                value: data.optionToQuote.quote.premium,
                            },
                        ],
                        [
                            'quote',
                            {
                                name: 'total_premium',
                                value: data.optionToQuote.quote.total_premium,
                            },
                        ],
                        [
                            'quote',
                            {
                                name: 'embedded_security_fee',
                                value: data.optionToQuote.quote.embedded_security_fee,
                            },
                        ],
                    ],
                }),
            );
        } catch (e) {
            console.error(e);
            // display error when dates was not saved and load the current dates
            makeToast('error', 'There was a problem choosing the date, please try again.');
            const res = await getPolicyRequestDates();
            dispatch(setPolicyRequestDates(res.data));
            setSavingDates(false);
        }
    };

    const dateValidityHandler = (dateType, isValid) => () =>
        dispatch(
            setDateValidity({
                dateType,
                isValid,
            }),
        );

    const changeRadioFunction = value => {
        if (value === MplRetroactiveDateType.TO_MATCH_EXPIRING && !mpl_retroactive_date) {
            updateDate('mpl_retroactive_date', null, 'mpl_retroactive_date_type', value);
        }
        if (value === MplRetroactiveDateType.FULL_PRIOR_ACTS) {
            updateDate('mpl_retroactive_date', null, 'mpl_retroactive_date_type', value);
            dateValidityHandler('mpl_retroactive_date', true)();
        }
    };

    return (
        <>
            <Box component={StyledCard.Partial} className="pt-0" mt={0.5}>
                <Hidden mdDown>
                    <Box fontWeight="fontWeightLight" mt={[2, 2, 3]}>
                        <Typography variant="subtitle2">
                            {readOnly ? 'Policy Dates' : '02 Set policy effective and expiration dates'}
                        </Typography>
                    </Box>
                </Hidden>
                <Box
                    mx={[0, 0, 0, 7]}
                    mt={[2, 2, 4, 3]}
                    mb={isSm ? 2 : showPrimaryDates ? 3 : effective_date ? 2 : 4}
                    display="flex"
                    alignItems="center"
                    fontWeight="fontWeightLight">
                    <StyledTypography.FadedTypography variant="subtitle1">
                        <Box mr={2} display="flex">
                            <CalendarTodayIcon />
                        </Box>
                    </StyledTypography.FadedTypography>
                    <Box width="100%">
                        {effective_date || readOnly ? (
                            <Box display="flex" alignItems="center">
                                <StyledAction.Base
                                    onClick={() => handleShowPrimaryDates('expiration_effective_dates', true)}>
                                    <Typography variant="h3" color={dateColors.effective_date}>
                                        {formatDatePickerDate(effective_date)}
                                    </Typography>
                                </StyledAction.Base>
                                <Box
                                    component={StyledTypography.FadedTypography}
                                    fontWeight="fontWeightLight"
                                    variant="h3">
                                    <Box component="span" mx={2}>
                                        -
                                    </Box>
                                </Box>
                                <StyledAction.Base
                                    onClick={() => handleShowPrimaryDates('expiration_effective_dates', true)}>
                                    <Typography variant="h3" color={dateColors.expiration_date}>
                                        {formatDatePickerDate(expiration_date)}
                                    </Typography>
                                </StyledAction.Base>
                            </Box>
                        ) : (
                            <StyledAction.Base
                                onClick={() => handleShowPrimaryDates('expiration_effective_dates', true)}>
                                <Typography variant={isSm ? 'h5' : 'h3'} color="primary">
                                    Select effective date
                                </Typography>
                            </StyledAction.Base>
                        )}
                    </Box>
                </Box>
            </Box>
            <Hidden mdUp>
                <PolicyDataDialog
                    open={showPrimaryDates}
                    footer={
                        <RoundedButton fullWidth onClick={() => setShownPrimaryDates(false)} disabled={disabled}>
                            <Typography variant="subtitle2">Done</Typography>
                        </RoundedButton>
                    }
                    headerExtraText="Effective & expiration dates"
                    boxShadow={false}>
                    <DateTextInput
                        editable={
                            shownDates.expiration_effective_dates && !readOnly && type.name !== PolicyType.RENEWAL
                        }
                        initialDate={effective_date}
                        text="Select effective date"
                        changeHandler={date => {
                            if (last_option_to_quote_publish_date) {
                                updateDate('effective_date', date);
                            } else {
                                setOpenNotification(true);
                            }
                        }}
                        label="Effective Date"
                        minDate={makeDatePickerDate().subtract(1, 'weeks')}
                        maxDate={makeDatePickerDate(last_option_to_quote_publish_date).add(75, 'day')}
                        onInvalidDate={dateValidityHandler('effective_date', false)}
                        onValidDate={dateValidityHandler('effective_date', true)}
                    />
                    <DateTextInput
                        editable={shownDates.expiration_effective_dates && !readOnly}
                        initialDate={expiration_date}
                        text="Select expiration date"
                        changeHandler={date => updateDate('expiration_date', date)}
                        border={false}
                        label="Expiration Date"
                        minDate={minExpirationDate}
                        maxDate={maxExpirationDate}
                        onInvalidDate={dateValidityHandler('expiration_date', false)}
                        onValidDate={dateValidityHandler('expiration_date', true)}
                    />
                </PolicyDataDialog>
            </Hidden>
            {showPrimaryDates && (
                <Hidden smDown>
                    <Box
                        px={[0, 0, 7, 11]}
                        pb={2}
                        pt={1}
                        style={{ backgroundColor: '#f8f8f8' }}
                        display="flex"
                        flexDirection="column">
                        <Box display="flex" flexDirection={isSm ? 'column' : 'row'}>
                            <DateTextInput
                                editable={
                                    shownDates.expiration_effective_dates &&
                                    !readOnly &&
                                    type.name !== PolicyType.RENEWAL
                                }
                                initialDate={effective_date}
                                text="Select effective date"
                                changeHandler={date => {
                                    if (last_option_to_quote_publish_date) {
                                        updateDate('effective_date', date);
                                    } else {
                                        setOpenNotification(true);
                                    }
                                }}
                                first
                                border={false}
                                label="Effective Date"
                                minDate={makeDatePickerDate().subtract(1, 'weeks')}
                                maxDate={makeDatePickerDate(last_option_to_quote_publish_date).add(75, 'day')}
                                onInvalidDate={dateValidityHandler('effective_date', false)}
                                onValidDate={dateValidityHandler('effective_date', true)}
                            />
                            <DateTextInput
                                editable={shownDates.expiration_effective_dates && !readOnly}
                                initialDate={expiration_date}
                                text="Select expiration date"
                                changeHandler={date => updateDate('expiration_date', date)}
                                border={false}
                                label="Expiration Date"
                                minDate={minExpirationDate}
                                maxDate={maxExpirationDate}
                                onInvalidDate={dateValidityHandler('expiration_date', false)}
                                onValidDate={dateValidityHandler('expiration_date', true)}
                            />
                        </Box>
                        {!isAnnualPolicy && shownDates.expiration_effective_dates && (
                            <Box display="flex" fontWeight="fontWeightLight" ml={isSm ? 0 : 4.5} mt={1} mb={2}>
                                <Box mr={2}>
                                    <ReportOutlinedIcon style={{ color: '#696969' }} />
                                </Box>
                                <Box mr={2}>
                                    <StyledTypography.FadedTypography variant="body2">
                                        Please review the pro-rated total cost for any non-annual policy period.
                                        Modifications are based off an annual total cost of $
                                        {accounting.formatNumber(quote.total_annual_premium)}.
                                    </StyledTypography.FadedTypography>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Hidden>
            )}
            {effective_date && (
                <StyledCard.Partial className="pt-0">
                    <Box ml={[4.5, 4, 0, 7]} mb={3} pt={isSm || !showPrimaryDates ? 0 : 2}>
                        <Box
                            component={StyledAction.ExpandableRowControl}
                            display="flex"
                            alignItems="center"
                            justifyContent={isSm ? 'space-between' : null}
                            flexDirection={isSm ? 'row-reverse' : 'row'}
                            onClick={handleShowOtherDates}
                            borderTop={isMd ? '2px solid #F2F2F2' : 'none'}
                            pt={[2, 2, 2, 1]}>
                            <Box mr={isSm ? 0 : 2} component="span" display="flex" alignItems="center">
                                <StyledTypography.FadedTypography variant="subtitle2">
                                    {isSm ? (
                                        <KeyboardArrowRightIcon />
                                    ) : showOtherDates ? (
                                        <ExpandLessIcon />
                                    ) : (
                                        <ExpandMoreIcon />
                                    )}
                                </StyledTypography.FadedTypography>
                            </Box>
                            <StyledTypography.FadedTypography variant="subtitle2">
                                {showOtherDates ? 'Hide' : readOnly ? 'View' : 'Review'} claims made dates
                            </StyledTypography.FadedTypography>
                        </Box>
                    </Box>
                </StyledCard.Partial>
            )}
            <Hidden mdUp>
                <PolicyDataDialog
                    open={showOtherDates}
                    footer={
                        <RoundedButton fullWidth onClick={() => setShowOtherDates(false)} disabled={disabled}>
                            <Typography variant="subtitle2">Done</Typography>
                        </RoundedButton>
                    }
                    headerExtraText="Claims made dates">
                    {isMPL ? (
                        <DateRadioInput
                            label="MPL RETROACTIVE"
                            shown={
                                mpl_retroactive_date_type.name === MplRetroactiveDateType.POLICY_INCEPTION
                                    ? false
                                    : showOtherDates && !readOnly
                            }
                            initialDate={mpl_retroactive_date}
                            text={
                                mpl_retroactive_date_type.name === MplRetroactiveDateType.POLICY_INCEPTION
                                    ? 'Policy Inception'
                                    : 'Full Prior Acts'
                            }
                            changeHandler={date => {
                                setDateTypeValue(MplRetroactiveDateType.TO_MATCH_EXPIRING);
                                updateDate(
                                    'mpl_retroactive_date',
                                    date,
                                    'mpl_retroactive_date_type',
                                    MplRetroactiveDateType.TO_MATCH_EXPIRING,
                                );
                            }}
                            maxDate={makeDatePickerDate(effective_date).add(-1, 'day')}
                            first
                            initialValue={dateTypeValue}
                            setValue={setDateTypeValue}
                            changeFunc={value => changeRadioFunction(value)}
                            onInvalidDate={dateValidityHandler('mpl_retroactive_date', false)}
                            onValidDate={dateValidityHandler('mpl_retroactive_date', true)}
                        />
                    ) : (
                        <>
                            <DateTextInput label="RETROACTIVE" text="Full Prior Acts" empty editable={false} />
                            <DateTextInput
                                label="CONTINUITY"
                                subLabel="Inception"
                                showDisclaimer
                                editable={false}
                                initialDate={continuity_date}
                                changeHandler={date => updateDate('continuity_date', date)}
                                maxDate={makeDatePickerDate(effective_date)}
                                onInvalidDate={dateValidityHandler('continuity_date', false)}
                                onValidDate={dateValidityHandler('continuity_date', true)}
                            />
                        </>
                    )}
                    <DateTextInput
                        label="PRIOR & PENDING LITIGATION"
                        subLabel="Inception"
                        showDisclaimer
                        editable={false}
                        initialDate={prior_and_pending_litigation_date}
                        border={product.abbreviated_name === ProductsAbbreviatedName.CYBER_TEO}
                        changeHandler={date => updateDate('prior_and_pending_litigation_date', date)}
                        maxDate={makeDatePickerDate(effective_date)}
                        onInvalidDate={dateValidityHandler('prior_and_pending_litigation_date', false)}
                        onValidDate={dateValidityHandler('prior_and_pending_litigation_date', true)}
                    />
                    {isTEO && (
                        <DateTextInput
                            label="TECH E&O RETROACTIVE"
                            showDisclaimer
                            editable={showOtherDates && !readOnly}
                            initialDate={teo_retroactive_date}
                            text="Update Tech E&O Retroactive Date"
                            changeHandler={date => updateDate('teo_retroactive_date', date)}
                            first
                            border={false}
                            maxDate={makeDatePickerDate(effective_date)}
                            onInvalidDate={dateValidityHandler('teo_retroactive_date', false)}
                            onValidDate={dateValidityHandler('teo_retroactive_date', true)}
                        />
                    )}
                </PolicyDataDialog>
            </Hidden>
            {showOtherDates && (
                <Hidden smDown>
                    <Box px={[0, 0, 7, 11]} pb={2} pt={1} style={{ backgroundColor: '#f8f8f8' }}>
                        {isMPL ? (
                            <DateRadioInput
                                label="MPL RETROACTIVE"
                                shown={
                                    mpl_retroactive_date_type.name === MplRetroactiveDateType.POLICY_INCEPTION
                                        ? false
                                        : showOtherDates && !readOnly
                                }
                                initialDate={mpl_retroactive_date}
                                text={
                                    mpl_retroactive_date_type.name === MplRetroactiveDateType.POLICY_INCEPTION
                                        ? 'Policy Inception'
                                        : 'Full Prior Acts'
                                }
                                changeHandler={date => {
                                    updateDate(
                                        'mpl_retroactive_date',
                                        date,
                                        'mpl_retroactive_date_type',
                                        MplRetroactiveDateType.TO_MATCH_EXPIRING,
                                    );
                                    setDateTypeValue(MplRetroactiveDateType.TO_MATCH_EXPIRING);
                                }}
                                maxDate={makeDatePickerDate(effective_date).add(-1, 'day')}
                                first
                                initialValue={dateTypeValue}
                                setValue={setDateTypeValue}
                                changeFunc={value => changeRadioFunction(value)}
                                onInvalidDate={dateValidityHandler('mpl_retroactive_date', false)}
                                onValidDate={dateValidityHandler('mpl_retroactive_date', true)}
                            />
                        ) : (
                            <>
                                <DateTextInput label="RETROACTIVE" text="Full Prior Acts" empty editable={false} />
                                <DateTextInput
                                    label="CONTINUITY"
                                    subLabel={type.name === PolicyType.RENEWAL ? null : 'Inception'}
                                    showDisclaimer
                                    editable={false}
                                    initialDate={continuity_date}
                                    changeHandler={date => updateDate('continuity_date', date)}
                                    maxDate={makeDatePickerDate(effective_date)}
                                    first
                                    onInvalidDate={dateValidityHandler('continuity_date', false)}
                                    onValidDate={dateValidityHandler('continuity_date', true)}
                                />
                            </>
                        )}
                        <DateTextInput
                            label="PRIOR & PENDING LITIGATION"
                            subLabel={type.name === PolicyType.RENEWAL ? null : 'Inception'}
                            showDisclaimer
                            editable={false}
                            initialDate={prior_and_pending_litigation_date}
                            changeHandler={date => updateDate('prior_and_pending_litigation_date', date)}
                            border={product.abbreviated_name === ProductsAbbreviatedName.CYBER_TEO}
                            maxDate={makeDatePickerDate(effective_date)}
                            first
                            onInvalidDate={dateValidityHandler('prior_and_pending_litigation_date', false)}
                            onValidDate={dateValidityHandler('prior_and_pending_litigation_date', true)}
                        />
                        {isTEO && (
                            <DateTextInput
                                label="TECH E&O RETROACTIVE"
                                showDisclaimer
                                editable={showOtherDates && !readOnly}
                                initialDate={teo_retroactive_date}
                                text="Update Tech E&O Retroactive Date"
                                changeHandler={date => updateDate('teo_retroactive_date', date)}
                                border={false}
                                maxDate={makeDatePickerDate(effective_date)}
                                first
                                onInvalidDate={dateValidityHandler('teo_retroactive_date', false)}
                                onValidDate={dateValidityHandler('teo_retroactive_date', true)}
                            />
                        )}
                    </Box>
                </Hidden>
            )}
            <NotificationBanner
                openNotification={openNotification}
                alertText="dates can not be saved. this submission has no published quotes."
                setOpenNotification={setOpenNotification}
                withContainer={true}
            />
        </>
    );
};

export default Dates;
