import React, { useContext, useEffect, useState, useRef } from 'react';
import store from 'stores/store';
import { useDispatch } from 'react-redux';
import { useBreakpoint, useAxiosInstance, useQuery } from 'hooks';
import Styled from '../styled-components';
import { SubmissionsBucket } from 'utils/enums';
import { isPolicyRequestInProgress, makeToast } from 'utils';
import { partialPolicyRequestQuery } from 'queries/policyRequest';
import { policyRequestQuery } from 'queries/company';
import { setPolicyRequest } from 'stores/company/companySlice';
import TabPanel from './TabPanel';
import { PolicyOwner } from 'components/policy';
import Box from '@material-ui/core/Box';
import CurrentPolicyRequest from './current-policy-request';
import UpcomingRenewalPolicy from './upon-renewal-policy';
import { UponRenewalPolicyContext, UponRenewalPolicyContextProvider } from './UponRenewalPolicyContext';
import { EditApplicationPopup } from 'components/popup';
import { theme } from 'components/theme/styles';

function PolicyInformationBase({ renewalPolicy, currentPolicy, company }) {
    const backgroundColor = currentPolicy?.product?.background_color || theme.palette.primary.main;
    const styles = {
        paperContainer: {
            backgroundImage: `linear-gradient(${backgroundColor}, ${backgroundColor} 100%)`,
        },
    };
    const axiosInstance = useAxiosInstance();
    const dispatch = useDispatch();
    const query = useQuery();
    const focusedPolicyId = query.get('policyId');
    const renewalsFocus = query.get('tab') === 'renewals';
    const policyRef = useRef(null);
    const executeScroll = () => policyRef.current.scrollIntoView();
    const scrollToPolicy = [currentPolicy.id, renewalPolicy?.id].includes(Number(focusedPolicyId));
    const currentPolicyIndex = renewalPolicy ? 1 : 0;
    const [indexValue, setIndexValue] = useState(currentPolicyIndex);
    const isSm = useBreakpoint('down', 'sm');
    const {
        setOpenEditApplicationPopup,
        openEditApplicationPopup,
        policyRequestId,
        setPolicyRequestId,
        setCurrentPolicyExpirationDate,
    } = useContext(UponRenewalPolicyContext);

    const getCurrentSubmissionTabName = () => {
        switch (currentPolicy.athena_submissions_bucket) {
            case SubmissionsBucket.PRE_QUOTE:
            case SubmissionsBucket.QUOTED:
            case SubmissionsBucket.BIND_REQUESTED:
                return isSm ? 'Submission' : 'Current submission';
            case SubmissionsBucket.BOUND:
                return isSm ? 'Policy' : 'Current Policy';
            case SubmissionsBucket.CANCELED:
            case SubmissionsBucket.EXPIRED:
                return isSm ? 'Policy' : 'Inactive Policy';
            case SubmissionsBucket.LOST:
            case SubmissionsBucket.DECLINED:
                return isSm ? 'Submission' : 'Inactive submission';
            default:
                return 'Current submission';
        }
    };

    const getAndSetPolicyRequest = async (policyRequestId, firstRequestTime) => {
        const res = await axiosInstance.post(`/policyRequest/${policyRequestId}`, {
            query: policyRequestQuery,
        });
        const policyRequest = {
            ...res.data,
            inProgress: isPolicyRequestInProgress(res.data, firstRequestTime),
        };
        dispatch(setPolicyRequest(policyRequest));
    };

    const monitorPolicyRequest = async (policyRequestId, firstRequestTime) => {
        try {
            const companyStore = store.getState().company.company;
            if (!companyStore) {
                return;
            }
            const { viewable_policy_requests } = companyStore;
            const res = await axiosInstance.post(`/policyRequest/${policyRequestId}`, {
                query: partialPolicyRequestQuery,
            });
            const partialPolicyRequest = res.data;
            const policyRequest = viewable_policy_requests.find(policyRequest => policyRequest.id === policyRequestId);
            let gotFullPolicyRequest = false;
            if (isPolicyRequestInProgress(partialPolicyRequest, firstRequestTime)) {
                setTimeout(() => monitorPolicyRequest(policyRequestId, firstRequestTime), 2000);
            } else {
                getAndSetPolicyRequest(policyRequestId, firstRequestTime);
                gotFullPolicyRequest = true;
            }
            if (!gotFullPolicyRequest && partialPolicyRequest?.quotes_from_auto_quote?.length) {
                const finishedQuotes = partialPolicyRequest.quotes_from_auto_quote.filter(
                    quote => quote.option_to_quote?.published,
                );
                if (finishedQuotes.length > (policyRequest.option_to_quotes?.length || 0)) {
                    getAndSetPolicyRequest(policyRequestId, firstRequestTime);
                }
            }
        } catch (error) {
            if (error.response?.status !== 403) {
                makeToast('error', 'Something went wrong. Try coming back later.');
            }
        }
    };

    const handleChange = (_, newValue) => {
        setIndexValue(newValue);
    };

    useEffect(() => {
        if (currentPolicyIndex === indexValue) {
            setPolicyRequestId(currentPolicy.id);
        } else if (renewalPolicy) {
            setPolicyRequestId(renewalPolicy.id);
        }
    }, [indexValue]);

    useEffect(() => {
        setCurrentPolicyExpirationDate(currentPolicy.expiration_date);
        if (scrollToPolicy) {
            executeScroll();
        }
    }, []);

    useEffect(() => {
        if ((focusedPolicyId && Number(focusedPolicyId) === renewalPolicy?.id) || renewalsFocus) {
            setIndexValue(0);
        }
    }, [focusedPolicyId]);

    return (
        <>
            <Box mb={3}>
                <Styled.Paper style={styles.paperContainer} ref={scrollToPolicy ? policyRef : null}>
                    <Styled.Tabs value={indexValue} onChange={handleChange}>
                        {renewalPolicy && <Styled.Tab label={isSm ? 'Renewal' : 'Upcoming Renewal'} />}
                        <Styled.Tab label={getCurrentSubmissionTabName()} />
                    </Styled.Tabs>
                    <PolicyOwner userName={currentPolicy.broker_user.username} policyRequest={currentPolicy} />
                </Styled.Paper>
                {renewalPolicy && (
                    <TabPanel value={indexValue} index={0}>
                        <UpcomingRenewalPolicy
                            policyRequestData={renewalPolicy}
                            company={company}
                            monitorPolicyRequest={monitorPolicyRequest}
                        />
                    </TabPanel>
                )}
                <TabPanel value={indexValue} index={currentPolicyIndex}>
                    <CurrentPolicyRequest
                        policyRequestData={currentPolicy}
                        company={company}
                        monitorPolicyRequest={monitorPolicyRequest}
                    />
                </TabPanel>
            </Box>
            <EditApplicationPopup
                open={openEditApplicationPopup}
                handleClose={() => setOpenEditApplicationPopup(false)}
                policyRequestID={policyRequestId}
            />
        </>
    );
}

function PolicyInformation({ renewalPolicy, currentPolicy, company }) {
    return (
        <UponRenewalPolicyContextProvider>
            <PolicyInformationBase renewalPolicy={renewalPolicy} currentPolicy={currentPolicy} company={company} />
        </UponRenewalPolicyContextProvider>
    );
}

export default PolicyInformation;
