import React from 'react';
import Styled from './styled-components';
import CoverIcons from './CoverIcons';
import { Hidden } from '@material-ui/core'
import { ReactComponent as Logo } from 'components/icons/atbay-logo-yellow.svg';
import AxaLogo from 'components/icons/logoAxa';
import { isAxa } from 'components/icons/logo';

const Cover = ({ backgroundImage }) => {
    return (
        <Styled.CoverContainer backgroundImage={backgroundImage}>
            <Styled.CoverLayer>
                <Styled.LogoContainer>{isAxa ? <AxaLogo textColor='#ffffff' /> : <Logo />}</Styled.LogoContainer>
                <Hidden smDown>
                    <CoverIcons />
                </Hidden>
            </Styled.CoverLayer>
        </Styled.CoverContainer>
    );
};

export default Cover;
