import {combineReducers} from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import companyReducer from './company/companySlice';
import authReducer from './auth/authSlice';
import newAuthReducer from './auth/auth';
import footerReducer from './footer/footerSlice';
import globalDataSlice from './globalData/globalDataSlice';
import questionnaireReducer from './questionnaire/questionnaireSlice';
import questionsReducer from './questions/questionsSlice';
import questionsErrorsReducer from './questionsErrors/questionsErrorsSlice';

export const rootReducer = combineReducers({
    user: userReducer,
    company: companyReducer,
    auth: authReducer,
    newAuth: newAuthReducer,
    footer: footerReducer,
    globalData: globalDataSlice,
    questionnaire: questionnaireReducer,
    questions: questionsReducer,
    questionErrors: questionsErrorsReducer,
});

export type RootState = ReturnType<typeof rootReducer>
