import React from 'react';
import Styled from '../styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import { DynamicIcon } from 'components';
import {Box, CircularProgress} from '@material-ui/core';

const ConfirmationPage = ({
    email,
    handleResendActivationEmail,
    resendActivationEmailSuccess,
    activationEmailError,
    loadingState,
}) => {
    const theme = useTheme();

    return (
        <>
            <Styled.FormHeader>Check Your Email!</Styled.FormHeader>
            <Styled.FormText variant="subtitle2">
                We’re sending an activation email to
                <span style={{ fontWeight: theme.typography.fontWeightMedium }}> {email}</span> with a link to sign in.
            </Styled.FormText>
            <Styled.SignUpText variant="subtitle1" color="primary">
                Didn't get the email?
            </Styled.SignUpText>
            {resendActivationEmailSuccess ? (
                <Styled.FormText variant="subtitle1" style={{ display: 'inline-block' }}>
                    Activation email resent!
                    <div style={{ display: 'inline-block', marginLeft: theme.spacing(1) }}>
                        <DynamicIcon
                            name="check"
                            svgIconStyle={{ fill: '#07AF26' }}
                            typographyProps={{ variant: 'subtitle1' }}
                        />
                    </div>
                </Styled.FormText>
            ) : (
                <Styled.SignUpLink variant="subtitle1" color="primary">
                    {loadingState
                        ?
                        <Box style={{color: 'gray', cursor: 'default'}}>
                            Resend activation email <CircularProgress size={13} color="primary" />
                        </Box>
                        :
                        <Box
                            type="button"
                            style={{ cursor: 'pointer' }}
                            onClick={handleResendActivationEmail}>
                                Resend activation email
                        </Box>
                    }

                    {activationEmailError && (
                        <Styled.FormText variant="subtitle1" style={{ color: 'red' }}>An error occured, please try again</Styled.FormText>
                    )}
                </Styled.SignUpLink>
            )}
        </>
    );
};

export default ConfirmationPage;
