import React from 'react';
import { DesktopTablet, Mobile } from './components';
import { Hidden } from '@material-ui/core';

const AutoRenew = ({ policyRequest }) => {
    return (
        <>
            <Hidden smDown>
                <DesktopTablet policyRequest={policyRequest} />
            </Hidden>
            <Hidden mdUp>
                <Mobile policyRequest={policyRequest} />
            </Hidden>
        </>
    );
}

export default AutoRenew;
