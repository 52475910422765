import React from 'react';
import { StyledTable, StyledLink } from 'components';
import { Box, Typography, TableRow } from '@material-ui/core';
import { Hidden } from '@material-ui/core';
import { SubmissionsBucket } from 'utils/enums';

const CompleteContingencies = ({ policyRequest }) => {
    const hasUncheckedContingencies = (policyRequest.unchecked_post_bind_contingencies_count + policyRequest.unchecked_pre_bind_contingencies_count) > 0;
    const isBindRequested = policyRequest.athena_submissions_bucket === SubmissionsBucket.BIND_REQUESTED;
    const preferredOptionToQuote = policyRequest.option_to_quotes.find(optionToQuote => optionToQuote.quote.preferred);

    return (
        <>
            <Hidden smDown>
                <TableRow>
                    <StyledTable.TableCell colSpan={6}>
                        <Box component="span" fontWeight="fontWeightLight">
                            <Typography variant="subtitle2" component="span">
                                {isBindRequested ?
                                    'Bind request under review'
                                    :
                                    hasUncheckedContingencies ?
                                        'Conditional binder will expire 30 days after your initial request'
                                        : 'Policy issuance in progress'
                                }
                            </Typography>
                        </Box>
                        <Box component="span" fontWeight="fontWeightMedium" ml={1}>
                            {!isBindRequested && !hasUncheckedContingencies ? (
                                <Typography variant="subtitle2" component="span">
                                    – Outstanding contingencies completed.
                                </Typography>
                            ) : (
                                <Typography variant="subtitle2" component="span" color="primary">
                                    <Box
                                        component={StyledLink.Default}
                                        fontWeight="fontWeightMedium"
                                        to={`/submission/policy_details/${preferredOptionToQuote.id}`}>
                                        {isBindRequested ? '– Complete pre-bind contingencies.' : '- Complete outstanding contingencies.'}
                                    </Box>
                                </Typography>
                            )}
                        </Box>
                    </StyledTable.TableCell>
                </TableRow>
            </Hidden>
            <Hidden mdUp>
                <TableRow>
                    <StyledTable.TableCell colSpan={6}>
                        <Box fontWeight="fontWeightLight">
                            <Typography variant="subtitle2" component="span">
                                {isBindRequested ?
                                    'Bind request under review'
                                    :
                                    hasUncheckedContingencies ?
                                        'Conditional binder will expire 30 days after your initial request'
                                        : 'Policy issuance in progress'
                                }
                            </Typography>
                        </Box>
                        <Box fontWeight="fontWeightMedium">
                            {!isBindRequested && !hasUncheckedContingencies ? (
                                <Typography variant="subtitle2" component="span">
                                    – Outstanding contingencies completed.
                                </Typography>
                            ) : (
                                <Typography variant="subtitle2" component="span" color="primary">
                                    <Box
                                        component={StyledLink.Default}
                                        fontWeight="fontWeightMedium"
                                        to={`/submission/policy_details/${preferredOptionToQuote.id}`}>
                                        {isBindRequested ? '– Complete pre-bind contingencies.' : '- Complete outstanding contingencies.'}
                                    </Box>
                                </Typography>
                            )}
                        </Box>
                    </StyledTable.TableCell>
                </TableRow>
            </Hidden>
        </>
    );
};

export default CompleteContingencies;
