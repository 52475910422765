import React, { useEffect, useState } from 'react';
import { useEnv } from 'hooks';
import Styled from './styled-components';
import { Loading } from 'components';
import { useDispatch } from 'react-redux';
import { setShowFooter } from 'stores/footer/footerSlice';

const IFrame = ({ src }) => {
    const { appPrefix } = useEnv();
    const newSrc = appPrefix ? src.replace(appPrefix, '/svc') : src;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(setShowFooter(false));
        return () => {
            dispatch(setShowFooter(true));
        }
    }, [])

    return (
        <>
            {loading && <Loading />}
            <Styled.IFrame src={newSrc} onLoad={() => setLoading(false)} />
        </>
    );
};

export default IFrame;
