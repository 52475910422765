import React, { useState, useEffect, useCallback } from 'react';
import { useBreakpoint } from 'hooks';
import { formatDatePickerDate, makeDatePickerDate, dateDifference } from 'utils';
import { StyledTypography, StyledInput } from 'components';
import { Box, Hidden, Typography } from '@material-ui/core';
import _ from 'lodash';

const DateTextInput = ({
    editable,
    label,
    subLabel = undefined,
    initialDate = null,
    text,
    changeHandler,
    showDisclaimer,
    border = true,
    first = false,
    minDate = undefined,
    maxDate = undefined,
    onInvalidDate = () => {},
    onValidDate = () => {},
}) => {
    const isSm = useBreakpoint('down', 'sm');
    const [date, setDate] = useState(initialDate);
    const [updateFromCalendar, setUpdateFromCalendar] = useState(false);
    const [error, setError] = useState('MM/DD/YYYY');
    useEffect(() => {
        setDate(initialDate && makeDatePickerDate(initialDate));
    }, [initialDate]);

    const dateIsValid = date => {
        // check if date is between the max date and min date
        return (
            (!minDate || Math.ceil(dateDifference(makeDatePickerDate(date), makeDatePickerDate(minDate))) >= 0) &&
            (!maxDate || Math.floor(dateDifference(makeDatePickerDate(maxDate), makeDatePickerDate(date))) >= 0)
        );
    };

    const getDateText = useCallback(() => {
        let dateText = '';

        if (!_.isEmpty(subLabel)) {
            dateText = dateText.concat(subLabel, ' - ');
        }

        if (date) {
            dateText = dateText.concat(formatDatePickerDate(date));
        } else {
            dateText = dateText.concat(text);
        }
        return dateText;
    }, [subLabel, date, text]);

    return (
        <>
            <Box
                width={isSm ? 'auto' : '50%'}
                ml={isSm ? 0 : first && editable ? 4.5 : editable ? 10 : 0}
                my={[2, 2, 4]}>
                {!editable && (
                    <>
                        <Box pl={isSm ? 0 : 4.5} display="flex" alignItems="center" component="span">
                            <StyledTypography.FadedTypography variant="body2">{label}</StyledTypography.FadedTypography>
                        </Box>
                        <Box pl={isSm ? 0 : 4.5} pt={0.5}>
                            <Box display="flex" alignItems="end" fontWeight="fontWeightLight">
                                <Typography variant="body1" color="inherit">
                                    {getDateText()}
                                </Typography>
                            </Box>
                        </Box>
                    </>
                )}
                <Hidden mdUp>
                    <Box width="100%" py={2} display={editable ? 'flex' : 'none'}>
                        <StyledInput.KeyboardDatePicker
                            label={label}
                            value={date}
                            onChange={date => {
                                setDate(date);
                                if (updateFromCalendar) {
                                    if (dateIsValid(date)) {
                                        changeHandler(date);
                                    }
                                }
                            }}
                            onBlur={() => {
                                setDate(date);
                                if (dateIsValid(date)) {
                                    changeHandler(date);
                                }
                            }}
                            onOpen={() => setUpdateFromCalendar(true)}
                            onClose={() => setUpdateFromCalendar(false)}
                            minDate={minDate}
                            maxDate={maxDate}
                            format="MM/DD/YYYY"
                            helperText={error}
                            fullWidth
                            onError={error => {
                                if (error) {
                                    setError(error);
                                    onInvalidDate();
                                } else {
                                    setError('MM/DD/YYYY');
                                    onValidDate();
                                }
                            }}
                        />
                    </Box>
                </Hidden>
                <Hidden smDown>
                    <Box fontWeight="fontWeightLight" display={editable ? 'block' : 'none'}>
                        <StyledInput.KeyboardDatePicker
                            fullWidth
                            label={label}
                            value={date}
                            onChange={date => {
                                setDate(date);
                                if (updateFromCalendar) {
                                    if (dateIsValid(date)) {
                                        changeHandler(date);
                                    }
                                }
                            }}
                            onBlur={() => {
                                setDate(date);
                                if (dateIsValid(date)) {
                                    changeHandler(date);
                                }
                            }}
                            onOpen={() => setUpdateFromCalendar(true)}
                            onClose={() => setUpdateFromCalendar(false)}
                            minDate={minDate}
                            maxDate={maxDate}
                            format="MM/DD/YYYY"
                            helperText={error}
                            onError={error => {
                                if (error) {
                                    setError(error);
                                    onInvalidDate();
                                } else {
                                    setError('MM/DD/YYYY');
                                    onValidDate();
                                }
                            }}
                        />
                    </Box>
                </Hidden>
            </Box>
            {editable && showDisclaimer && (
                <Hidden smDown>
                    <Box fontWeight="fontWeightLight" mb={4} px={[0, 4.5]}>
                        <StyledTypography.FadedTypography variant="body2">
                            Updates to the {label.replace(/_/g, ' ').toLowerCase()} date may result in additional
                            contingencies. Please carefully review all contingencies prior to binding or requesting to
                            bind online.
                        </StyledTypography.FadedTypography>
                    </Box>
                </Hidden>
            )}
            {(border || isSm) && <Box px={[0, 4.5]} mx={[-4, -4, 0]} borderBottom="2px solid #E0E0E0" />}
        </>
    );
};

export default DateTextInput;
