import { Tab } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { IndirectBrokerActiveStatus, RetailPartnersTab } from '../../utils';
import Styled from './styled-components';
import { RetailPartnersContext } from '../../context/RetailPartnersContext';

interface Props {
    tabs: RetailPartnersTab[];
    handleTabChange: (event: React.ChangeEvent<{}>, value: IndirectBrokerActiveStatus) => void;
}

const RetailPartnersTabs: FC<Props> = ({ tabs, handleTabChange }) => {
    const { brokerListByActiveStatus } = useContext(RetailPartnersContext);

    const isVisible = (status: IndirectBrokerActiveStatus) => {
        return status !== IndirectBrokerActiveStatus.INACTIVE || brokerListByActiveStatus[status].length > 0;
    };

    return (
        <Styled.TabList onChange={handleTabChange} TabIndicatorProps={{ children: <span /> }}>
            {tabs.map((tab, index) => (
                <Tab style={{ display: !isVisible(tab.value) ? 'none' : 'block' }} key={index} {...tab} />
            ))}
        </Styled.TabList>
    );
};

export default RetailPartnersTabs;
