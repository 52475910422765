import React, { ChangeEvent, FC, useCallback, useContext, useState } from 'react';
import Styled from './styled-components';
import Box from '@material-ui/core/Box';
import { TabContext } from '@material-ui/lab';
import { NewIndirectBrokerFormData, IndirectBrokerActiveStatus, TABS } from './utils';
import GenericDialog from 'components/popup/GenericDialog';
import { Loading, RoundedButton } from 'components';
import RetailPartnerDetailsWrapper from './components/retail-partners-details-wrapper';
import RetailPartnersTabs from './components/retail-partners-tabs';
import IndirectPartnerForm from './components/invite-partner-form';
import { RetailPartnersContext } from './context/RetailPartnersContext';
import SearchInput from './components/search-input';
import { useBreakpoint } from '../../../../hooks';
import NoResults from './components/no-results';

const initialFormState: NewIndirectBrokerFormData = {
    username: '',
    email: '',
    partnership_fee: undefined,
};

const RetailerPartners: FC = () => {
    const { brokersList, addBroker, setSearchFilterText, searchFilterText, isLoading } = useContext(
        RetailPartnersContext,
    );
    const [tab, setTab] = useState<IndirectBrokerActiveStatus>(IndirectBrokerActiveStatus.ACTIVE);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [formState, setFormState] = useState<NewIndirectBrokerFormData>(initialFormState);
    const isSm = useBreakpoint('down', 'sm');

    const onTabChange = useCallback((event: ChangeEvent<{}>, newTab: IndirectBrokerActiveStatus) => {
        setTab(newTab);
        setSearchFilterText('');
    }, []);

    const onInviteButtonClick = useCallback(() => {
        setDialogOpen(true);
    }, []);

    const onDialogSubmit = useCallback(async () => {
        addBroker(formState);
        setDialogOpen(false);
        setFormState(initialFormState);
    }, [formState]);

    const onDialogClose = useCallback(() => {
        setDialogOpen(false);
        setFormState(initialFormState);
    }, []);

    const updateFormState = useCallback(
        (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setFormState({ ...formState, [e.target.name]: e.target.value });
        },
        [formState],
    );

    const handleSearchChanged = useCallback((value: string) => {
        setSearchFilterText(value || '');
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Box mb={2} pt={12} component={Styled.Container}>
            <Styled.Header>
                <Styled.Typography variant="subtitle1">My Retail Partners</Styled.Typography>
                <RoundedButton maxWidth="300px" onClick={onInviteButtonClick} fullWidth={isSm}>
                    Invite
                </RoundedButton>
            </Styled.Header>

            {brokersList.length === 0 ? (
                <NoResults />
            ) : (
                <Styled.Box>
                    <SearchInput handleOnChange={handleSearchChanged} value={searchFilterText} />
                    <Box component={Styled.TabsContainer}>
                        <TabContext value={tab}>
                            <RetailPartnersTabs handleTabChange={onTabChange} tabs={TABS} />
                            <RetailPartnerDetailsWrapper tabs={TABS} />
                        </TabContext>
                    </Box>
                </Styled.Box>
            )}

            <GenericDialog
                open={dialogOpen}
                title={'Grant Access'}
                buttonTitle={'Send link'}
                handleClose={onDialogClose}
                handleButton={onDialogSubmit}
                body={<IndirectPartnerForm formState={formState} updateFormState={updateFormState} />}
            />
        </Box>
    );
};

export default React.memo(RetailerPartners);
