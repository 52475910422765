import React from 'react';
import { Box, FormControl } from '@material-ui/core';
import MplAutoComplete from './AutoComplete';
import { useBreakpoint } from 'hooks';

const AddressQuestion = ({
    question,
    updateForm,
    submitFailed,
    setScroll,
    scroll,
    setEditAnswerMode,
    setSubmissionDisabled,
    setCheckTriedToSubmit,
    setChangeMade,
}) => {
    const isSm = useBreakpoint('down', 'sm');

    return (
        <Box display="flex" px={isSm ? 4 : 0} key={`${question.id}_question`} pb={1.625} justifyContent="center">
            <FormControl fullWidth style={{ maxWidth: isSm ? 'unset' : '424px' }}>
                <Box>
                    <MplAutoComplete
                        id={`label-${question.id}`}
                        name={question.id}
                        question={question}
                        updateForm={updateForm}
                        submitFailed={submitFailed}
                        visible
                        setScroll={setScroll}
                        scroll={scroll}
                        setEditAnswerMode={setEditAnswerMode}
                        setSubmissionDisabled={setSubmissionDisabled}
                        setCheckTriedToSubmit={setCheckTriedToSubmit}
                        setChangeMade={setChangeMade}
                    />
                </Box>
            </FormControl>
        </Box>
    )
};

export default AddressQuestion;
