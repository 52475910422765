import React from 'react';
import { useSelector } from 'react-redux';
import StyledDialog from 'components/popup/styled-components';
import Styled from './styled-components';
import { Box, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useDataTrackBase } from 'hooks/customization';

const ExitCustomizationPopup = ({ exitCustomizationPopupState, handleClose }) => {
    const { optionToQuote } = useSelector(state => state.company);
    const dataTrackBase = useDataTrackBase(optionToQuote);

    return (
        <StyledDialog.Dialog open={exitCustomizationPopupState}>
            <Box>
                <StyledDialog.DialogTitle>
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        fontWeight="fontWeightBold"
                        alignItems="flex-start"
                    >
                        <Box width="30em">Exit Quote Customization?</Box>
                        <IconButton
                            onClick={handleClose}
                            data-track={`${dataTrackBase}_exit_popup_cancel`}
                            aria-label="exit popup cancel icon"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </StyledDialog.DialogTitle>
                <Styled.DialogContent>
                    <Box mt={2.5} mb={8.125}>
                        Your customized quote option is not yet saved. By
                        exiting, any changes will be discarded.
                    </Box>
                    <Box
                        mx="auto"
                        bgcolor="#E6E6E6"
                        height="1px"
                        width="100%"
                    />
                    <Box
                        textAlign="right"
                        display="flex"
                        alignItems="center"
                        mt={1.875}
                        justifyContent="flex-end"
                    >
                        <Box mr={4} fontWeight="700">
                            <Styled.PointerBox
                                onClick={handleClose}
                                data-track={`${dataTrackBase}_exit_popup_cancel`}
                                aria-label="exit popup cancel button"
                            >
                                Cancel
                            </Styled.PointerBox>
                        </Box>
                        <Styled.Link
                            to={`/company/${optionToQuote?.quote.policy_request.company_id}`}
                        >
                            <Styled.CloseButton
                                data-track={`${dataTrackBase}_exit_popup_confirm`}
                                aria-label="exit popup confirm button"
                            >
                                Exit
                            </Styled.CloseButton>
                        </Styled.Link>
                    </Box>
                </Styled.DialogContent>
            </Box>
        </StyledDialog.Dialog>
    );
};

export default ExitCustomizationPopup;
