import React, { Fragment } from 'react';
import { RenewalsSubBuckets } from 'utils/enums';
import { Typography, Box, Table, TableBody, TableRow } from '@material-ui/core';
import Styled from './styled-components';
import { FirstPolicyInfo, FirstPolicyCell, ArrowCell } from './SharedComponents';
import { getBrokerInitialsColor } from '../../modules/company/pages/dashboard/components/companies-pagination/utils';
import { useSelector } from 'react-redux';
import { formatDatePickerDate } from 'utils';

export const MainRowTabletDesktop = ({
    multiplePolicies,
    setOpen,
    open,
    numOfPolicies,
    client,
    companyPolicies,
    companyId,
    bucket,
    subBucket,
}) => {
    const { user } = useSelector(state => state.user);
    const teamMembersIds = user.team_members_ids;

    return (
        <>
            {multiplePolicies ? (
                <Fragment>
                    <Styled.TableRow className="expanded" onClick={() => setOpen(!open)} open={open}>
                        <FirstPolicyCell
                            open={open}
                            multiplePolicies={multiplePolicies}
                            numOfPolicies={numOfPolicies}
                            bucket={bucket}
                            client={client}
                        />
                    </Styled.TableRow>
                    <Styled.TableRow className="expanded" onClick={() => setOpen(!open)} open={open}>
                        <FirstPolicyInfo
                            bucket={bucket}
                            open={open}
                            multiplePolicies={multiplePolicies}
                            companyPolicies={companyPolicies}
                            subBucket={subBucket}
                        />
                    </Styled.TableRow>
                    {companyPolicies.slice(1).map((nextPolicy, idx) => (
                        <Styled.TableRow
                            className="expanded"
                            onClick={() => setOpen(!open)}
                            open={open}
                            _bordertop="0"
                            key={`next_row_${nextPolicy.id}_${companyId}`}>
                            <Styled.TableCell
                                className="grey"
                                style={{ paddingBottom: idx === numOfPolicies - 2 ? '12px' : '4px' }}>
                                <Box border="none">
                                    <Typography variant="subtitle2">
                                        {subBucket === RenewalsSubBuckets.NOT_RENEWING
                                            ? formatDatePickerDate(nextPolicy['expiration_date']) || null
                                            : nextPolicy['policy_period'] || null}
                                    </Typography>
                                </Box>
                            </Styled.TableCell>
                            <Styled.TableCell
                                className="grey"
                                style={{
                                    paddingBottom: idx === numOfPolicies - 2 ? '12px' : '0px',
                                    paddingTop: '6px',
                                }}>
                                <Box
                                    border="none"
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    gridGap={12}>
                                    <Styled.Avatar
                                        style={{
                                            backgroundColor: getBrokerInitialsColor(
                                                nextPolicy?.broker_user,
                                                teamMembersIds,
                                            ),
                                        }}>
                                        {nextPolicy?.broker_user?.initials}
                                    </Styled.Avatar>
                                    <Typography variant="subtitle2">{nextPolicy['product']?.name || ''}</Typography>
                                </Box>
                            </Styled.TableCell>
                            <Styled.TableCell
                                className="grey"
                                style={{ paddingBottom: idx === numOfPolicies - 2 ? '9.5px' : '2.5px' }}>
                                <Styled.TagBox className={nextPolicy['status'][0]?.type}>
                                    <Typography variant="overline">{nextPolicy['status'][0]?.text}</Typography>
                                </Styled.TagBox>
                            </Styled.TableCell>
                            <Styled.TableCell
                                className="alignEnd grey"
                                style={{ paddingTop: '12px', paddingBottom: '4px' }}
                            />
                        </Styled.TableRow>
                    ))}
                </Fragment>
            ) : (
                <Styled.TableRow className="expanded" open={open} onClick={() => setOpen(!open)}>
                    <FirstPolicyCell
                        open={open}
                        multiplePolicies={multiplePolicies}
                        numOfPolicies={numOfPolicies}
                        bucket={bucket}
                        client={client}
                    />
                    <FirstPolicyInfo
                        bucket={bucket}
                        open={open}
                        multiplePolicies={multiplePolicies}
                        companyPolicies={companyPolicies}
                        subBucket={subBucket}
                    />
                </Styled.TableRow>
            )}
        </>
    );
};

export const MainRowMobile = ({
    multiplePolicies,
    setOpen,
    open,
    numOfPolicies,
    client,
    companyPolicies,
    companyId,
    bucket,
}) => {
    return (
        <Styled.TableRow open={open}>
            <Styled.TableCell>
                <Table>
                    <TableBody>
                        <Styled.TableRow _bordertop="0" onClick={() => setOpen(!open)}>
                            <FirstPolicyCell
                                open={open}
                                multiplePolicies={multiplePolicies}
                                numOfPolicies={numOfPolicies}
                                bucket={bucket}
                                client={client}
                            />
                            <ArrowCell bucket={bucket} open={open} multiplePolicies={multiplePolicies} />
                        </Styled.TableRow>
                        {companyPolicies.map((policyRequest, idx) => (
                            <Styled.TableRow
                                _bordertop="0"
                                key={`sm_data_${policyRequest.id}_${companyId}`}
                                onClick={() => setOpen(!open)}>
                                <Styled.TableCell open={open} className="inner grey" colSpan={1} width="94px">
                                    <Box pl={4} pr={2} pb={idx === numOfPolicies - 1 ? 1.5 : 0.5} width="94px">
                                        <Typography variant="subtitle2">{policyRequest.product?.name}</Typography>
                                    </Box>
                                </Styled.TableCell>
                                <Styled.TableCell open={open} className="inner grey" colSpan={2} width="100%">
                                    <Styled.TagBox
                                        className={policyRequest.status[0]?.type}
                                        mb={idx === numOfPolicies - 1 ? 1.5 : 0.5}>
                                        <Typography variant="overline">{policyRequest.status[0]?.text}</Typography>
                                    </Styled.TagBox>
                                </Styled.TableCell>
                            </Styled.TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Styled.TableCell>
        </Styled.TableRow>
    );
};

export const PolicyAndRenewalsTableHead = ({ subBucket }) => {
    return (
        <Styled.TableHead>
            <TableRow>
                <Styled.TableCellHead colSpan={3} width="410px">
                    <Box pl={4}>
                        <Typography variant="overline">client</Typography>
                    </Box>
                </Styled.TableCellHead>
                <Styled.TableCellHead colSpan={1} width="28.2552083%">
                    <Box pl={0}>
                        <Typography variant="overline">
                            {subBucket === RenewalsSubBuckets.NOT_RENEWING ? 'expiration date' : 'policy period'}
                        </Typography>
                    </Box>
                </Styled.TableCellHead>
                <Styled.TableCellHead colSpan={1} width="12.5%">
                    <Box pl={0}>
                        <Typography variant="overline">product</Typography>
                    </Box>
                </Styled.TableCellHead>
                <Styled.TableCellHead colSpan={1} width="19.921875%">
                    <Box pl={0}>
                        <Typography variant="overline">status</Typography>
                    </Box>
                </Styled.TableCellHead>
                <Styled.TableCellHead colSpan={1} width="6.51041667%">
                    <Box pl={0}>
                        <Typography variant="overline" />
                    </Box>
                </Styled.TableCellHead>
            </TableRow>
        </Styled.TableHead>
    );
};
