import React, { useState, useEffect, useReducer } from 'react';
import { useAxiosClient, useEnv } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga';
import { Box, Hidden } from '@material-ui/core';
import 'react-multi-carousel/lib/styles.css';
import {
    QuotesSubBuckets,
    PoliciesSubBuckets,
    CompaniesBuckets,
    SnapshotsBuckets,
    RenewalsSubBuckets,
    Permissions,
} from 'utils/enums';
import DesktopDashboard from './desktopDashboard';
import TabletMobileDashboard from './tabletMobileDashboard';
import { setAvailableProducts } from 'stores/globalData/globalDataSlice';
import { StyledContainer, Loading } from 'components';
import { NotificationBanner } from 'components/banner';
import { useSnapshot } from 'api/dashboard/queries/useSnapshot';
import { CompaniesPagination } from './components/companies-pagination/CompaniesPagination';
import {
    quotesSubBuckets,
    oldPoliciesSubBuckets,
    policiesSubBuckets,
    renewalsSubBuckets,
    quotesSubBucketsLimitedPermissions,
} from './consts';
import { usePermissions } from '../../../../hooks/usePermissions';

const initialState = {
    product: '',
    company: '',
    website: '',
    open_quotes: { submissions: 0, premiums: 0 },
    pending_quotes: { submissions: 0, premiums: 0 },
    active_policies: { submissions: 0, premiums: 0 },
    renewing_policies: { submissions: 0, premiums: 0 },
    manual_renewals: { submissions: 0, premiums: 0 },
    bind_requested_policies: { submissions: 0, premiums: 0 },
    has_website: true
};

const dashboardReducer = (state, action) => {
    switch (action.type) {
        case 'reset':
            return initialState;
        default:
            return { ...state, [action.type]: action.payload };
    }
};

const HomePage = () => {
    const dispatch = useDispatch();
    const { newRenewalsTab } = useEnv();
    const axiosClient = useAxiosClient();
    const submissionWriteAuthorized = usePermissions()([Permissions.SUBMISSION_WRITE]);
    const visibleQuotesSubBuckets = submissionWriteAuthorized ? quotesSubBuckets : quotesSubBucketsLimitedPermissions;
    const [state, reducerDispatch] = useReducer(dashboardReducer, initialState);
    const [snapshots, setSnapshots] = useState(QuotesSubBuckets.OPEN);
    const [bucket, setBucket] = useState(CompaniesBuckets.QUOTES);
    const [subBucket, setSubBucket] = useState(QuotesSubBuckets.OPEN);
    const [subBucketsList, setSubBucketsList] = useState(visibleQuotesSubBuckets);
    const { availableProducts } = useSelector(state => state.globalData);
    const [openNotification, setOpenNotification] = useState(false);
    const [alertText, setAlertText] = useState('');

    const [, getProducts] = axiosClient(
        {
            method: 'post',
            url: '/product/list',
        },
        { manual: true },
    );

    const { data: snapshotData, error: snapshotError, isLoading: snapshotLoading } = useSnapshot();

    useEffect(() => {
        if (!snapshotLoading && snapshotData) {
            for (const key in snapshotData) {
                reducerDispatch({ type: key, payload: snapshotData[key] });
            }
        }
        if (!snapshotLoading && snapshotError) {
            ReactGA.exception({ description: 'Failed to fetch snapshot', fatal: true });
        }
    }, [snapshotData, snapshotLoading, snapshotError]);

    useEffect(() => {
        if (snapshots === QuotesSubBuckets.OPEN ||
            snapshots === QuotesSubBuckets.PENDING ||
            snapshots === QuotesSubBuckets.BIND_REQUEST) {
            bucketChange('quotes', snapshots);
        } else if (snapshots === PoliciesSubBuckets.ACTIVE || snapshots === PoliciesSubBuckets.RENEWING) {
            bucketChange('policies', snapshots);
        } else if (snapshots === RenewalsSubBuckets.MANUAL) {
            bucketChange('renewals', snapshots);
        }
    }, [snapshots]);

    useEffect(() => {
        if (Object.values(SnapshotsBuckets).includes(subBucket)) {
            setSnapshots(subBucket);
        } else {
            setSnapshots(null);
        }
    }, [subBucket]);

    useEffect(() => {
        const fetchProducts = async () => {
            const res = await getProducts({
                data: {
                    query: { id: {}, name: {}, abbreviated_name: {}, is_technological: {}, is_support_no_domain: {} },
                },
            });
            dispatch(setAvailableProducts(res.data));
        };
        if (!availableProducts) {
            fetchProducts();
        }
    }, []);

    const bucketChange = (bucketName, subBucketName = null) => {
        setBucket(bucketName);
        if (bucketName === CompaniesBuckets.QUOTES) {
            setSubBucketsList(visibleQuotesSubBuckets);
            setSubBucket(subBucketName || visibleQuotesSubBuckets[0].name);
        } else if (bucketName === CompaniesBuckets.POLICIES) {
            setSubBucketsList(newRenewalsTab ? policiesSubBuckets : oldPoliciesSubBuckets);
            setSubBucket(
                subBucketName || (newRenewalsTab ? policiesSubBuckets[0].name : oldPoliciesSubBuckets[0].name),
            );
        } else {
            setSubBucketsList(renewalsSubBuckets);
            setSubBucket(subBucketName ? subBucketName : renewalsSubBuckets[0].name);
        }
    };

    if (!availableProducts) {
        return <Loading />;
    }

    return (
        <StyledContainer.BasicFlexContainer id="root-homepage" disableGutters>
            <NotificationBanner
                openNotification={openNotification}
                alertText={alertText}
                setOpenNotification={setOpenNotification}
                withContainer={true}
            />
            <Box display="flex">
                <Hidden mdDown>
                    <DesktopDashboard
                        state={state}
                        reducerDispatch={reducerDispatch}
                        subBucket={subBucket}
                        setSubBucket={setSubBucket}
                        bucket={bucket}
                        snapshots={snapshots}
                        setSnapshots={setSnapshots}
                        subBucketsList={subBucketsList}
                        setOpenNotification={setOpenNotification}
                        setAlertText={setAlertText}
                        bucketChange={bucketChange}
                    />
                </Hidden>
                <Hidden lgUp>
                    <TabletMobileDashboard
                        state={state}
                        reducerDispatch={reducerDispatch}
                        subBucket={subBucket}
                        setSubBucket={setSubBucket}
                        bucket={bucket}
                        snapshots={snapshots}
                        setSnapshots={setSnapshots}
                        subBucketsList={subBucketsList}
                        setOpenNotification={setOpenNotification}
                        setAlertText={setAlertText}
                        bucketChange={bucketChange}
                    />
                </Hidden>
            </Box>
            <CompaniesPagination bucket={bucket} subBucket={subBucket} />
        </StyledContainer.BasicFlexContainer>
    );
};

export default HomePage;
