import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
    AuthState,
    SetAuthenticatedActionPayloadType,
    SetMfaTokenActionPayloadType,
    SetTokenActionPayloadType
} from './types';

const initialState: AuthState = {
    token: undefined,
    tokenExpiration: 3600,
    mfaToken: undefined,
    authenticated: undefined,
    role: undefined,
    retailPartners: undefined
};
const jwtDecode = require('jwt-decode');
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken(state, action: PayloadAction<SetTokenActionPayloadType>) {
            state.token = action.payload.token;
            state.tokenExpiration = action.payload.expiration;
            state.role = state.token ? jwtDecode(state.token).role : null;
            state.retailPartners = state.token ? jwtDecode(state.token).extra_data?.manage_partnership : null;
        },
        setMfaToken(state, action: PayloadAction<SetMfaTokenActionPayloadType>) {
            state.mfaToken = action.payload;
        },
        setAuthenticated(state, action: PayloadAction<SetAuthenticatedActionPayloadType>) {
            state.authenticated = action.payload;
        },
    },
});

export const {
    setToken,
    setMfaToken,
    setAuthenticated,
} = authSlice.actions;

export default authSlice.reducer;
