import { useEffect } from 'react';
import store from 'stores/store';
import { setOptionToQuoteAttributes } from 'stores/company/companySlice';
import { useDispatch } from 'react-redux';
import { useAxiosClient } from 'hooks';
import { getRelevantPacketTemplateType } from 'utils/policyRequest';
import { generatedPacketQuery } from 'queries/optionToQuote';


const DocumentMonitor = ({ policyRequest, optionToQuote, monitor }) => {
    const axiosClient = useAxiosClient();
    const dispatch = useDispatch();
    const packetTemplateType = getRelevantPacketTemplateType(policyRequest);
    const generatedPacketField = `${packetTemplateType}_generated_packet`;

    const [, getGenerationStatus] = axiosClient({
        method: 'post',
        url: `/optionToQuote/${optionToQuote.id}`,
    }, { manual: true });

    const checkDocumentsGenerationStatus = async () => {
        // since this is a recursive function, we can get here even after the component has been unmounted
        // therefore, we need to check that it's still relevant to run the function by checking the store state
        const state = store.getState();
        if (!state.company.company && !state.company.optionToQuote) {
            return;
        }
        const query = {
            quote: {
                [`${packetTemplateType}_generated_packet`]: generatedPacketQuery,
            }
        };

        const res = await getGenerationStatus({ data: { query } });
        const { optionToQuote: optionToQuoteData } = res.data;
        if (optionToQuoteData.quote[generatedPacketField]) {
            dispatch(setOptionToQuoteAttributes({
                policyRequestID: policyRequest.id,
                optionToQuoteID: optionToQuote.id,
                nestedAttributes: [
                    [
                        'quote',
                        {
                            name: `${packetTemplateType}_generated_packet`,
                            value: optionToQuoteData.quote[`${packetTemplateType}_generated_packet`],
                        }
                    ]
                ]
            }));
        } else {
            setTimeout(checkDocumentsGenerationStatus, 5000);
        }
    }

    useEffect(() => {
        let mounted = true;
        if (monitor && mounted) {
            checkDocumentsGenerationStatus();
        }
        return () => { mounted = false };
    }, [monitor, optionToQuote])

    return null;
}

export default DocumentMonitor;
