import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setOptionToQuote } from 'stores/company/companySlice';
import accounting from 'accounting';
import { totalHumanize } from 'utils';
import { PacketTemplateType } from 'utils/enums';
import { useEnv } from 'hooks';
import { StyledAction, StyledTypography, StyledLink, RoundedButton, PolicyDataDialog } from 'components';
import { DocumentList } from 'components/option-to-quote';
import { CoveragesTable } from 'components/quote';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { getRelevantPacketTemplateType } from 'utils/policyRequest';
import BindAndCustomizeButton from '../BindAndCustomizeButton';

const OptionToQuote = ({ policyRequest, optionToQuote }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { apiBaseUrl } = useEnv();
    const [coveragesToggled, setCoveragesToggled] = useState(false);
    const [documentsToggled, setDocumentsToggled] = useState(false);
    const [commissionToggled, setCommissionToggled] = useState(false);
    const packetTemplateType = getRelevantPacketTemplateType(policyRequest);
    const generatedPacket = optionToQuote.quote[`${packetTemplateType}_generated_packet`];
    const TypographyComponent = optionToQuote.pending ? StyledTypography.FadedTypography : Typography;
    const commissionPercentageString = `${Number(policyRequest.commission * 100).toLocaleString()}%`;

    const handleButtonBar = buttonID => {
        switch (buttonID) {
            case 'review':
                setCoveragesToggled(true);
                break;
            case 'bind':
                dispatch(
                    setOptionToQuote({
                        ...optionToQuote,
                        quote: {
                            ...optionToQuote.quote,
                            policy_request: policyRequest,
                        },
                    }),
                );
                history.push(`/submission/bind/${optionToQuote.id}`);
                break;
            default:
                throw new Error('Invalid button ID');
        }
    };
    const handleButton = () => {
        dispatch(
            setOptionToQuote({
                ...optionToQuote,
                quote: {
                    ...optionToQuote.quote,
                    policy_request: policyRequest,
                },
            }),
        );
        history.push(`/submission/bind/${optionToQuote.id}`);
    };

    return (
        <Box mx={-4} bgcolor="#FAFAFA">
            <Box py={3}>
                <Box display="flex" justifyContent="space-evenly" fontWeight="fontWeightLight" px={4}>
                    <Box textAlign="center">
                        <StyledTypography.FadedTypography variant="overline">PREMIUM</StyledTypography.FadedTypography>
                        <TypographyComponent variant="subtitle1">
                            {optionToQuote.pending
                                ? 'Pending'
                                : `$${accounting.formatNumber(optionToQuote.quote.premium)}`}
                        </TypographyComponent>
                    </Box>
                    <Box textAlign="center">
                        <StyledTypography.FadedTypography variant="overline">
                            AG. LIMIT
                        </StyledTypography.FadedTypography>
                        <TypographyComponent variant="subtitle1">
                            ${totalHumanize(optionToQuote.quote.aggregate_limit)}
                        </TypographyComponent>
                    </Box>
                    <Box textAlign="center">
                        <StyledTypography.FadedTypography variant="overline">
                            RETENTION
                        </StyledTypography.FadedTypography>
                        <TypographyComponent variant="subtitle1">
                            ${totalHumanize(optionToQuote.quote.aggregate_retention)}
                        </TypographyComponent>
                    </Box>
                </Box>
                {!optionToQuote.pending && (
                    <BindAndCustomizeButton
                        productName={policyRequest.product.abbreviated_name}
                        handleButton={handleButton}
                        handleButtonBar={handleButtonBar}
                        uncheckedPreBindContingencies={policyRequest.unchecked_pre_bind_contingencies_count}
                    />
                )}
                <PolicyDataDialog
                    open={coveragesToggled}
                    headerExtraText="Review coverages"
                    policyRequest={policyRequest}
                    quote={optionToQuote.quote}
                    footer={
                        <Box
                            width="100%"
                            display="flex"
                            justifyContent="space-evenly"
                            alignItems="center"
                            fontWeight="fontWeightBold">
                            <Box
                                component={props => <Typography {...props} variant="subtitle2" color="primary" />}
                                textAlign="center"
                                width="156px">
                                <StyledLink.Default to={`/submission/quote_customization/${optionToQuote.id}`}>
                                    Customize
                                </StyledLink.Default>
                            </Box>
                            <Box component={RoundedButton} onClick={() => setCoveragesToggled(false)} width="156px">
                                <Typography variant="subtitle2">Done</Typography>
                            </Box>
                        </Box>
                    }>
                    <CoveragesTable quote={optionToQuote.quote} firstColSpan={1} numOfExtraCols={1} childrenToggled />
                </PolicyDataDialog>
                <Box px={4} my={2}>
                    <Box borderBottom="2px solid #E0E0E0" />
                </Box>
                {!optionToQuote.pending && !generatedPacket ? (
                    <Box display="flex" mx={4}>
                        <Box display="flex" alignItems="center" component="span" mr={2}>
                            <CircularProgress color="inherit" size={15} />
                        </Box>
                        <StyledTypography.FadedTypography variant="subtitle2" component="span">
                            Generating documents
                        </StyledTypography.FadedTypography>
                    </Box>
                ) : (
                    !optionToQuote.pending && (
                        <Box display="flex" justifyContent="space-between" alignItems="center" mx={4}>
                            <StyledTypography.FadedTypography variant="subtitle2">
                                <StyledLink.Anchor
                                    style={{ color: 'inherit' }}
                                    href={`${apiBaseUrl}/company/download_packet_merged_file/${
                                        optionToQuote.id
                                    }/${PacketTemplateType.QUOTE.toUpperCase()}`}>
                                    Download all documents
                                </StyledLink.Anchor>
                            </StyledTypography.FadedTypography>
                            <Box
                                component={StyledAction.Base}
                                onClick={() => setDocumentsToggled(true)}
                                display="flex"
                                alignItems="center">
                                <StyledTypography.FadedTypography variant="subtitle2">
                                    Review
                                </StyledTypography.FadedTypography>
                                <Box
                                    component={StyledTypography.FadedTypography}
                                    display="flex"
                                    alignItems="center"
                                    variant="subtitle1">
                                    <KeyboardArrowRightIcon />
                                </Box>
                            </Box>
                        </Box>
                    )
                )}
                <PolicyDataDialog
                    open={documentsToggled}
                    headerExtraText="Review documents"
                    policyRequest={policyRequest}
                    quote={optionToQuote.quote}
                    footer={
                        <RoundedButton fullWidth onClick={() => setDocumentsToggled(false)}>
                            <Typography variant="subtitle2">Done</Typography>
                        </RoundedButton>
                    }>
                    <Box mt={2} />
                    <DocumentList
                        optionToQuote={optionToQuote}
                        policyRequest={policyRequest}
                        packetTemplateType={PacketTemplateType.QUOTE}
                    />
                </PolicyDataDialog>

                <Box px={4} my={2}>
                    <Box borderBottom="2px solid #E0E0E0" />
                </Box>

                <Box display="flex" justifyContent="flex-end" alignItems="center" mx={4}>
                    <Box
                        component={StyledAction.Base}
                        onClick={() => setCommissionToggled(true)}
                        display="flex"
                        alignItems="center">
                        <StyledTypography.FadedTypography variant="subtitle2">
                            Review commission
                        </StyledTypography.FadedTypography>
                        <Box
                            component={StyledTypography.FadedTypography}
                            display="flex"
                            alignItems="center"
                            variant="subtitle1">
                            <KeyboardArrowRightIcon />
                        </Box>
                    </Box>
                </Box>

                <PolicyDataDialog
                    open={commissionToggled}
                    headerExtraText="Commission"
                    policyRequest={policyRequest}
                    quote={optionToQuote.quote}
                    footer={
                        <RoundedButton fullWidth onClick={() => setCommissionToggled(false)}>
                            <Typography variant="subtitle2">Done</Typography>
                        </RoundedButton>
                    }>
                    <Box mt={2} />
                    <Box display="flex" flexDirection="row" style={{ gap: 8 }}>
                        <Typography variant="subtitle2">Commission</Typography>
                        <Typography variant="subtitle2">{commissionPercentageString}</Typography>
                    </Box>
                    <Box borderBottom="1px solid #F2F2F2" mx={-4} my={2} />
                </PolicyDataDialog>
            </Box>
        </Box>
    );
};

export default OptionToQuote;
