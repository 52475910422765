import styled from 'styled-components';
import { TextField as _TextField, Popover as _Popover, Box as _Box } from '@material-ui/core';

const SearchBarContainer = styled.div`
    display: ${({ hidden }) => hidden ? 'none' : 'inline-block'};
    width: calc(100% - 65px);

    ${({ theme }) => `
        ${theme.breakpoints.up('lg')} {
            display: block;
            float: right;
            width: auto;
        }
    `}
`;

const ClearIconContainer = styled(_Box)`
    &&& {
        .MuiSvgIcon-root {
            position: relative;
            top: 2px;
        }
    }
`;

const SearchIconContainer = styled(_Box)`
    margin-top: 5px;

    svg {
        font-size: 24px;
    }
`;

const SearchBarForm = styled.form`
    display: inline-block;

    ${({ theme }) => `
        ${theme.breakpoints.down('md')} {
            display: block;
        }
    `}

    .MuiFormControl-root {
        width: 100%;
    }
`;

const TextField = styled(_TextField)`
    .MuiOutlinedInput-root {
        width: 400px;
        border-radius: 21px;
    }

    .MuiInputBase-input {
        width: 400px;
        padding: 10px 10px;
        font-size: 16px;
    }

    ${({ theme }) => `
        ${theme.breakpoints.down('md')} {
            .MuiOutlinedInput-root {
                width: 100%;
                border-radius: 21px;
            }

            .MuiInputBase-input {
                width: 100%;
                padding: 10px 10px;
                font-size: 16px;
            }

        }
    `}
`;

const Popover = styled(_Popover)`
    &&& {
        .MuiPopover-paper {
            width: calc(100% - 150px);
            position: absolute;
            left: auto !important;
            right: 106px;
            padding: 7px 0;
            border-radius: 1px;
        }
    }

    ${({ theme }) => `
        ${theme.breakpoints.up('lg')} {
            &&& {
                .MuiPopover-paper {
                    width: 360px;
                    right: 118px;
                }
            }
        `}
`;

const NoResultsBox = styled(_Box)`
    padding: 5px 0 5px 10px;
    box-sizing: border-box;
`;

export default {
    SearchBarForm,
    TextField,
    Popover,
    SearchBarContainer,
    SearchIconContainer,
    ClearIconContainer,
    NoResultsBox
};
