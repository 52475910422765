import React, { useState, useEffect, useRef } from 'react';
import { useAxiosClient, useBreakpoint, useEnv } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import useTheme from '@material-ui/core/styles/useTheme';
import {Box, Typography} from '@material-ui/core';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CompaniesBuckets, Permissions } from 'utils/enums';
import Snapshot from './components/snapshot/Snapshot';
import { setAvailableProducts } from 'stores/globalData/globalDataSlice';
import {
    RoundedButton,
    CompanyPopup,
    Loading,
    StyledAction,
} from 'components';
import { ReactComponent as ClipboardIcon } from 'components/icons/clipboard.svg';
import { ReactComponent as SecurityShieldIcon } from 'components/icons/shield-security.svg';
import { ReactComponent as RecycleShieldIcon } from 'components/icons/recycle-shield.svg';
import { ReactComponent as TriangleClipboardIcon } from 'components/icons/triangle-clipboard.svg';
import { PermissionDenied, PermissionGranted, PermissionRequired } from '../../../../components/permission-required';
import Styled from './styled-components';


const quotes_headers = [
    { text: 'client', width: '60.8040201%' },
    {
        text: 'product',
        width: '12.5628141%',
    },
    { text: 'status', width: '20.1005025%' },
    { text: '', width: '6.53266332%' },
];
const policies_headers = [
    { text: 'client', width: '32.5028313%' },
    {
        text: 'policy period',
        width: '28.4258211%',
    },
    { text: 'product', width: '12.4575311%' },
    { text: 'status', width: '20.0453001%' },
    { text: '', width: '6.56851642%' },
];

const TabletMobileDashboard = ({
    state,
    reducerDispatch,
    subBucket,
    setSubBucket,
    bucket,
    snapshots,
    setSnapshots,
    subBucketsList,
    setOpenNotification,
    setAlertText,
    bucketChange,
}) => {
    const dispatch = useDispatch();
    const { newRenewalsTab } = useEnv();
    const carousel = useRef(null);
    const snapshotSection = useRef(null);
    const tableHeaderSection = useRef(null);
    const axiosClient = useAxiosClient();
    const theme = useTheme();
    const [openPopup, setOpenPopup] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const [stickySection, setStickySection] = useState(false);
    const [itemsToShow, setItemsToShow] = useState(1);
    const { availableProducts } = useSelector(state => state.globalData);
    const isSm = useBreakpoint('down', 'sm');

    const responsive = {
        all: {
            breakpoint: { max: 4000, min: 1 },
            items: itemsToShow,
        },
    };

    const [, getProducts] = axiosClient(
        {
            method: 'post',
            url: '/product/list',
        },
        { manual: true },
    );

    const handleScroll = () => {
        const snapshotTop = snapshotSection.current?.offsetTop;
        const $root = document.querySelector('#root');
        const offset = $root.children[1]?.clientHeight;
        const afterSnapshotHeader = snapshotTop - offset;
        const snapshotSectionHeight = snapshotSection.current.getBoundingClientRect().height;
        const tableHeaderSectionHeight = tableHeaderSection.current.getBoundingClientRect().height;
        if (window.scrollY >= afterSnapshotHeader) {
            setExpanded(false);
        } else if (!window.scrollY) {
            setExpanded(true);
        }
        if (window.scrollY >= snapshotSectionHeight) {
            setStickySection(true);
        }
        if (window.scrollY + offset <= snapshotSectionHeight + tableHeaderSectionHeight) {
            setStickySection(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleResize = () => {
        let boxSize = 314;
        let size = window.innerWidth;

        if (carousel.current) {
            size = carousel.current.containerRef.current.offsetWidth;
        }

        if (window.innerWidth < theme.breakpoints.values.md) {
            boxSize = 315;
        }
        setItemsToShow(size / boxSize);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        handleResize();
    }, [carousel.current]);

    const handleNewQuote = e => {
        setOpenPopup(true);
    };

    const handleExpandClick = () => {
        if (!expanded) {
            setExpanded(!expanded);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const fetchProducts = async () => {
            const res = await getProducts({
                data: {
                    query: { id: {}, name: {}, abbreviated_name: {}, is_technological: {}, is_support_no_domain: {} },
                },
            });
            dispatch(setAvailableProducts(res.data));
        };
        if (!availableProducts) {
            fetchProducts();
        }
    }, []);

    if (!availableProducts) {
        return <Loading />;
    }

    return (
        <>
            {!expanded && (
                <Box component={Styled.AppBar} height="56px">
                    <Styled.Toolbar onClick={handleExpandClick}>
                        <Box width="100%" display="flex" borderBottom="1px solid #E6E6E6" pb={1}>
                            <Box component={StyledAction.Base}>
                                <Styled.Typography variant="subtitle2" className="sticky">
                                    Snapshots
                                    <ExpandMoreIcon />
                                </Styled.Typography>
                            </Box>
                            <PermissionRequired requiredPermissions={[Permissions.SUBMISSION_WRITE]}>
                                <PermissionGranted>
                                    <Box position="absolute" bottom="0" right="32px" top="12px">
                                        <RoundedButton
                                            py={[0.5, 0.5, 0.25]}
                                            minWidth={{sm: '122px', md: '148px'}}
                                            maxWidth={{sm: '122px', md: '148px'}}
                                            maxHeight="36px"
                                            px={[1, 1, 4]}
                                            onClick={() => handleNewQuote()}>
                                            <Typography variant="subtitle2">New Quote</Typography>
                                        </RoundedButton>
                                    </Box>
                                </PermissionGranted>
                                <PermissionDenied>
                                    <></>
                                </PermissionDenied>
                            </PermissionRequired>
                        </Box>
                    </Styled.Toolbar>
                </Box>
            )}
            <Box display="flex" flexDirection="column" width="100%">
                <Box mx={4}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        position="relative"
                        width="100%"
                        alignItems="flex-end"
                        mb={2}>
                        <Box>
                            <Styled.Typography variant="h5">Snapshots</Styled.Typography>
                        </Box>
                        <PermissionRequired requiredPermissions={[Permissions.SUBMISSION_WRITE]}>
                            <PermissionGranted>
                                <Box>
                                    <RoundedButton
                                        py={[0.5, 0.5, 0.25]}
                                        minWidth={{ sm: '122px', md: '148px' }}
                                        maxWidth={{ sm: '122px', md: '148px' }}
                                        maxHeight="36px"
                                        px={[1, 1, 4]}
                                        onClick={() => handleNewQuote()}>
                                        <Typography variant="subtitle2">New Quote</Typography>
                                    </RoundedButton>
                                </Box>
                            </PermissionGranted>
                            <PermissionDenied>
                                <></>
                            </PermissionDenied>
                        </PermissionRequired>
                    </Box>
                </Box>
                <Box ml={4} ref={snapshotSection}>
                    <Carousel responsive={responsive} ref={carousel} arrows={false} partialVisible={true}>
                        <Snapshot
                            icon={<ClipboardIcon />}
                            setHandler={setSnapshots}
                            snapshotsText="Open"
                            text={state.open_quotes.submissions === 1 ? 'Open Quote' : 'Open Quotes'}
                            submissions={state.open_quotes.submissions}
                            snapshots={snapshots}
                        />

                        <Snapshot
                            icon={<TriangleClipboardIcon />}
                            setHandler={setSnapshots}
                            snapshotsText="Pending"
                            text={state.pending_quotes.submissions === 1 ? 'Pending Quote' : 'Pending Quotes'}
                            submissions={state.pending_quotes.submissions}
                            snapshots={snapshots}
                        />

                        <Snapshot
                            icon={<SecurityShieldIcon />}
                            setHandler={setSnapshots}
                            snapshotsText="Active"
                            text={state.active_policies.submissions === 1 ? 'Active Policy' : 'Active Policies'}
                            submissions={state.active_policies.submissions}
                            snapshots={snapshots}
                        />
                        {newRenewalsTab ? (
                            <Snapshot
                                icon={<RecycleShieldIcon />}
                                setHandler={setSnapshots}
                                snapshotsText="Manual"
                                text={state.manual_renewals.submissions === 1 ? 'Manual Renewal' : 'Manual Renewals'}
                                submissions={state.manual_renewals.submissions}
                                snapshots={snapshots}
                            />
                        ) : (
                            <Snapshot
                                icon={<RecycleShieldIcon />}
                                setHandler={setSnapshots}
                                snapshotsText="Renewing"
                                text={
                                    state.renewing_policies.submissions === 1 ? 'Renewing Policy' : 'Renewing Policies'
                                }
                                submissions={state.renewing_policies.submissions}
                                snapshots={snapshots}
                            />
                        )}
                    </Carousel>
                </Box>
                {stickySection && (
                    <Box component={Styled.AppBar} mt={7}>
                        <Styled.Toolbar className="header">
                            <Box left="32px" right="0" mt={4} width="100%">
                                <Box display="flex" fontWeight="fontWeightBold" mb={[1, 1, 1, 1.5]} pl={4}>
                                    <Box
                                        component={StyledAction.Base}
                                        mr={[1, 1, 1.5, 2]}
                                        color={bucket !== CompaniesBuckets.QUOTES ? '#919191' : '#191919'}
                                        onClick={() => bucketChange('quotes')}>
                                        <Typography variant="h3">Quotes</Typography>
                                    </Box>
                                    <Box
                                        component={StyledAction.Base}
                                        mr={2}
                                        color={bucket !== CompaniesBuckets.POLICIES ? '#919191' : '#191919'}
                                        onClick={() => bucketChange('policies')}>
                                        <Typography variant="h3">Policies</Typography>
                                    </Box>
                                    {newRenewalsTab && (
                                        <Box
                                            component={StyledAction.Base}
                                            mr={2}
                                            color={bucket !== CompaniesBuckets.RENEWALS ? '#919191' : '#191919'}
                                            onClick={() => bucketChange('renewals')}>
                                            <Typography variant="h3">Renewals</Typography>
                                        </Box>
                                    )}
                                </Box>
                                <Box display="flex" borderBottom="1px solid #E6E6E6" mb={{ sm: 1.5 }} pl={4}>
                                    {subBucketsList.map((bucket, idx) => (
                                        <Box
                                            component={StyledAction.Base}
                                            mr={{
                                                sm: idx === subBucketsList.length - 1 ? 0 : 1.5,
                                                md: idx === subBucketsList.length - 1 ? 0 : 2,
                                            }}
                                            key={bucket.name}>
                                            <Styled.Typography
                                                className="sticky bucket"
                                                variant="subtitle2"
                                                selected={subBucket === bucket.name}
                                                onClick={() => setSubBucket(bucket.name)}>
                                                {bucket.text}
                                            </Styled.Typography>
                                        </Box>
                                    ))}
                                </Box>
                                {!isSm && (
                                    <Box display="flex" color="#919191" pt={1.5} pb={1}>
                                        {bucket === CompaniesBuckets.QUOTES
                                            ? quotes_headers.map(header => (
                                                  <Box key={`sticky_header_${header.text}`} width={header.width}>
                                                      <Box pl={header.text === 'client' ? 4 : 0}>
                                                          <Typography variant="overline">
                                                              {header.text.toUpperCase()}
                                                          </Typography>
                                                      </Box>
                                                  </Box>
                                              ))
                                            : policies_headers.map(header => (
                                                  <Box key={`sticky_header_${header.text}`} width={header.width}>
                                                      <Box pl={header.text === 'client' ? 4 : 0}>
                                                          <Typography variant="overline">
                                                              {header.text.toUpperCase()}
                                                          </Typography>
                                                      </Box>
                                                  </Box>
                                              ))}
                                    </Box>
                                )}
                            </Box>
                        </Styled.Toolbar>
                    </Box>
                )}
                <Box left="32px" right="0" mt={4} ref={tableHeaderSection}>
                    <Box display="flex" fontWeight="fontWeightBold" mb={[1, 1, 1, 1.5]} ml={[4, 4, 4, 0]}>
                        <Box
                            component={StyledAction.Base}
                            mr={[1, 1, 1.5, 2]}
                            color={bucket !== CompaniesBuckets.QUOTES ? '#919191' : null}
                            onClick={() => bucketChange('quotes')}>
                            <Typography variant="h3">Quotes</Typography>
                        </Box>
                        <Box
                            component={StyledAction.Base}
                            mr={2}
                            color={bucket !== CompaniesBuckets.POLICIES ? '#919191' : null}
                            onClick={() => bucketChange('policies')}>
                            <Typography variant="h3">Policies</Typography>
                        </Box>
                        {newRenewalsTab && (
                            <Box
                                component={StyledAction.Base}
                                mr={2}
                                color={bucket !== CompaniesBuckets.RENEWALS ? '#919191' : '#191919'}
                                onClick={() => bucketChange('renewals')}>
                                <Typography variant="h3">Renewals</Typography>
                            </Box>
                        )}
                    </Box>
                    <Box pl={[4, 4, 4, 0]} display="flex" borderBottom="1px solid #E6E6E6" mb={{ sm: 1.5 }}>
                        {subBucketsList.map((bucket, idx) => (
                            <Box
                                component={StyledAction.Base}
                                mr={{
                                    sm: idx === subBucketsList.length - 1 ? 0 : 1.5,
                                    md: idx === subBucketsList.length - 1 ? 0 : 2,
                                }}
                                key={bucket.name}>
                                <Styled.Typography
                                    className="sticky bucket"
                                    variant="subtitle2"
                                    selected={subBucket === bucket.name}
                                    onClick={() => setSubBucket(bucket.name)}>
                                    {bucket.text}
                                </Styled.Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
            <PermissionRequired requiredPermissions={[Permissions.SUBMISSION_WRITE]}>
                <PermissionGranted>
                    <CompanyPopup
                        open={openPopup}
                        handleClose={() => setOpenPopup(false)}
                        title="Start New Quote"
                        setOpenNotification={setOpenNotification}
                        setAlertText={setAlertText}
                    />
                </PermissionGranted>
                <PermissionDenied>
                    <></>
                </PermissionDenied>
            </PermissionRequired>
        </>
    );
};

export default TabletMobileDashboard;
