import React, { useState } from 'react';
import { TableRow, Collapse, TableCell, Typography, Box, TableBody, Hidden } from '@material-ui/core';
import CompanyInfo from 'components/table/CompanyInfo';
import { useBreakpoint } from 'hooks';
import StyledTable from 'components/table/styled-components';
import { MainRowTabletDesktop, MainRowMobile } from 'components/table/PoliciesAndRenewalsSharedComponents';
import Table from '@material-ui/core/Table';
import { useHistory } from 'react-router-dom';
import { CompaniesBuckets, RenewalsSubBuckets } from 'utils/enums';
import InnerTableRenewals from './InnerTableRenewals';
import { getBrokerInitialsColor } from '../../utils';
import { useSelector } from 'react-redux';
import { formatDatePickerDate } from 'utils';
import _ from 'lodash';

const RenewalRow = ({ client, companyId, subBucket }) => {
    const [open, setOpen] = useState(false);
    const companyPolicies = client.data;
    const numOfPolicies = companyPolicies.length;
    const multiplePolicies = numOfPolicies > 1;
    const isSm = useBreakpoint('down', 'sm');
    const history = useHistory();
    const { user } = useSelector(state => state.user);
    const teamMembersIds = user.team_members_ids;
    var optionsToQuoteIdPerPolicy = {};
    for (const i in client.policiesRequestRows) {
        const policy_request_id = client.policiesRequestRows[i].id;
        optionsToQuoteIdPerPolicy[policy_request_id] =
            (client.policiesRequestRows[i]?.optionsToQuote &&
                _.head(client.policiesRequestRows[i]?.optionsToQuote)?.id) ||
            null;
    }
    const [optionsToQuoteIdForPolicy, setOptionsToQuoteIdForPolicy] = useState(optionsToQuoteIdPerPolicy);
    const handleButtonBar = buttonID => {
        const [dataType, action] = buttonID.split(':');
        switch (action) {
            case 'customize':
                history.push(`/submission/quote_customization/${dataType}`);
                break;
            case 'bind':
                history.push(`/submission/bind/${dataType}`);
                break;
            default:
                break;
        }
    };

    const handleOnClick = (policyRequestId, optionToQuoteDataId) => {
        let copyOptionIdForPolicy = {
            ...optionsToQuoteIdForPolicy,
        };
        copyOptionIdForPolicy[policyRequestId] = optionToQuoteDataId;
        setOptionsToQuoteIdForPolicy(copyOptionIdForPolicy);
    };

    return (
        <>
            <Hidden smDown>
                <MainRowTabletDesktop
                    multiplePolicies={multiplePolicies}
                    setOpen={setOpen}
                    open={open}
                    numOfPolicies={numOfPolicies}
                    client={client}
                    companyPolicies={companyPolicies}
                    companyId={companyId}
                    bucket={CompaniesBuckets.RENEWALS}
                    subBucket={subBucket}
                />
            </Hidden>
            <Hidden mdUp>
                <MainRowMobile
                    multiplePolicies={multiplePolicies}
                    setOpen={setOpen}
                    open={open}
                    numOfPolicies={numOfPolicies}
                    client={client}
                    companyPolicies={companyPolicies}
                    companyId={companyId}
                    bucket={CompaniesBuckets.RENEWALS}
                />
            </Hidden>
            <TableRow>
                <TableCell
                    style={{
                        padding: 0,
                        borderBottom: 'none',
                        backgroundColor: open ? '#F8F8F8' : '#F2F2F2',
                        borderTop: open && client.policiesRequestRows ? '1px solid #F2F2F2' : 'none',
                    }}
                    colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {client.policiesRequestRows &&
                            client.policiesRequestRows.map((policyRequest, index) => (
                                <Box
                                    key={`inner_row_${policyRequest.id}_${companyId}`}
                                    borderBottom="2px solid #f2f2f2"
                                    my={2}
                                    px={4}
                                    fontWeight="fontWeightBold"
                                    mb={0}
                                    pb={2}>
                                    <Box
                                        border="none"
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        gridGap={12}>
                                        <StyledTable.Avatar
                                            hidden={!policyRequest?.broker?.initials}
                                            style={{
                                                backgroundColor: getBrokerInitialsColor(
                                                    policyRequest?.broker,
                                                    teamMembersIds,
                                                ),
                                                height: !isSm && '32px',
                                                width: !isSm && '32px',
                                                fontSize: !isSm && '14px',
                                            }}>
                                            {policyRequest?.broker?.initials}
                                        </StyledTable.Avatar>
                                        <Typography variant="subtitle2" gutterBottom component="div">
                                            {policyRequest.header}
                                        </Typography>
                                    </Box>
                                    <Hidden mdUp>
                                        <StyledTable.TableRow _bordertop="0">
                                            <StyledTable.TableCell width="100%" style={{ backgroundColor: '#F8F8F8' }}>
                                                <Typography variant="subtitle2">
                                                    {subBucket === RenewalsSubBuckets.NOT_RENEWING
                                                        ? formatDatePickerDate(policyRequest.expiration_date)
                                                        : policyRequest.policy_period}
                                                </Typography>
                                            </StyledTable.TableCell>
                                        </StyledTable.TableRow>
                                    </Hidden>
                                    <Hidden mdUp>
                                        {policyRequest.optionsToQuote && policyRequest.optionsToQuote.length > 1 && (
                                            <Box display="flex" pt={1} borderBottom="1px solid #e6e6e6">
                                                {policyRequest.optionsToQuote.map(optionToQuoteData => (
                                                    <StyledTable.SmBox
                                                        align="left"
                                                        className="inner"
                                                        color={
                                                            optionsToQuoteIdForPolicy[policyRequest.id] ===
                                                            optionToQuoteData.id
                                                                ? '#191919'
                                                                : '#919191'
                                                        }
                                                        mr={2}
                                                        checked={
                                                            optionsToQuoteIdForPolicy[policyRequest.id] ===
                                                            optionToQuoteData.id
                                                        }
                                                        onClick={() =>
                                                            handleOnClick(policyRequest.id, optionToQuoteData.id)
                                                        }
                                                        key={`sm_inner_header_${optionToQuoteData.id}`}
                                                        fontWeight="fontWeightLight">
                                                        <Typography variant="subtitle2">
                                                            {optionToQuoteData.limit}
                                                        </Typography>
                                                    </StyledTable.SmBox>
                                                ))}
                                            </Box>
                                        )}
                                    </Hidden>
                                    <Table size="small">
                                        {policyRequest.isPreQuote ? (
                                            <StyledTable.TableHead className="inner">
                                                {!isSm && (
                                                    <StyledTable.TableRow bottom="1px solid #F2F2F2" _bordertop="0" />
                                                )}
                                                <StyledTable.TableRow _bordertop="none">
                                                    <StyledTable.TableCellHead
                                                        className="inner"
                                                        width={isSm ? '33%' : '15%'}>
                                                        <Typography variant="overline">NOTE</Typography>
                                                    </StyledTable.TableCellHead>
                                                    {isSm ? (
                                                        <StyledTable.TableCellHead className="inner" width="0%">
                                                            <Typography variant="overline" />
                                                        </StyledTable.TableCellHead>
                                                    ) : (
                                                        <StyledTable.TableCellHead className="inner" width="55%" />
                                                    )}
                                                </StyledTable.TableRow>
                                            </StyledTable.TableHead>
                                        ) : (
                                            <StyledTable.TableHead className="inner">
                                                {!isSm && (
                                                    <StyledTable.TableRow bottom="1px solid #F2F2F2" _bordertop="0" />
                                                )}
                                                <StyledTable.TableRow _bordertop="none">
                                                    <StyledTable.TableCellHead
                                                        className="inner"
                                                        width={isSm ? '33%' : '15%'}>
                                                        <Typography variant="overline">PREMIUM</Typography>
                                                    </StyledTable.TableCellHead>
                                                    <StyledTable.TableCellHead
                                                        className="inner"
                                                        width={isSm ? '33%' : '15%'}>
                                                        <Typography variant="overline">AG. LIMIT</Typography>
                                                    </StyledTable.TableCellHead>
                                                    <StyledTable.TableCellHead
                                                        className="inner"
                                                        width={isSm ? '33%' : '15%'}>
                                                        <Typography variant="overline">RETENTION</Typography>
                                                    </StyledTable.TableCellHead>
                                                    {isSm ? (
                                                        <StyledTable.TableCellHead className="inner" width="0%">
                                                            <Typography variant="overline" />
                                                        </StyledTable.TableCellHead>
                                                    ) : (
                                                        <StyledTable.TableCellHead className="inner" width="55%" />
                                                    )}
                                                </StyledTable.TableRow>
                                            </StyledTable.TableHead>
                                        )}
                                        <TableBody>
                                            {policyRequest.isPreQuote ? (
                                                <InnerTableRenewals
                                                    policyRequest={policyRequest}
                                                    handleButton={() =>
                                                        history.push({
                                                            pathname: policyRequest.button.path,
                                                            state: policyRequest.button.state,
                                                            search: policyRequest.button.query,
                                                        })
                                                    }
                                                    buttonText={policyRequest.button.text}
                                                />
                                            ) : (
                                                policyRequest.optionsToQuote.map(optionToQuoteData => (
                                                    <InnerTableRenewals
                                                        key={`inner_${RenewalsSubBuckets.MANUAL}_data_${optionToQuoteData.id}`}
                                                        optionToQuoteData={optionToQuoteData}
                                                        optionToQuoteId={optionsToQuoteIdForPolicy[policyRequest.id]}
                                                        policyRequest={companyPolicies[index]}
                                                        handleButton={
                                                            policyRequest.button.text
                                                                ? () =>
                                                                      history.push({
                                                                          pathname: policyRequest.button.path,
                                                                          state: policyRequest.button.state,
                                                                          search: policyRequest.button.query,
                                                                      })
                                                                : handleButtonBar
                                                        }
                                                        buttonText={policyRequest.button.text}
                                                    />
                                                ))
                                            )}
                                        </TableBody>
                                    </Table>
                                </Box>
                            ))}
                        <CompanyInfo client={client} renewals />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default RenewalRow;
