import styled from 'styled-components';
import { Box as _Box } from '@material-ui/core';

const SplitScreenContainer = styled(_Box)`
    &&& {
        display: flex;
        flex-direction: row;
        width: 100%;

        ${({ theme }) => `
            ${theme.breakpoints.down('sm')} {
                flex-direction: column;
            }
        `}
    }
`;

const LeftPanel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    height: 100vh;
    background: ${({ theme }) => theme.palette.primary.contrastText};

    ${({ theme }) => `
        ${theme.breakpoints.down('sm')} {
            height: 100%;
            order: 1;
        }
    `}
`;

const RightPanel = styled.div`
    flex: 1;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${({ theme }) => `
        ${theme.breakpoints.down('sm')} {
            height: 100%;

            &.push-right-panel-bottom {
                order: 2;
            }
        }
    `}
`;

export default {
    SplitScreenContainer,
    LeftPanel,
    RightPanel,
};
