import React from 'react';
import ReferralDetailsForm from '../ReferralDetailsForm';
import DetailsSentCard from '../DetailsSentCard';


const DesktopTablet = props => {
    return (
        <>
            {props.showDetailsSentCard
                ?
                <DetailsSentCard
                    policyRequest={props.policyRequest}
                    setShowDetailsSentCard={props.setShowDetailsSentCard}
                />
                :
                <ReferralDetailsForm {...props} />
            }
        </>
    )
}


export default DesktopTablet;
