import React, {useMemo} from 'react';
import Styled from '../styled-components';
import {RoundedButton, StyledLink} from 'components';
import CloseIcon from '@material-ui/icons/Close';
import {
    Box,
    IconButton,
    Typography,
} from '@material-ui/core';
import ContingencySection from './ContingencySection';
import styled from 'styled-components';
import {useEnv} from '../../../hooks';
import {useSelector} from 'react-redux';
import {Contingency, PolicyRequest} from '../../../types/PolicyRequest';
import {CompanyState} from '../../../stores/company/types';

const ContingencyTitle = styled(Typography)`
  margin-bottom: 10px;
`

interface Props {
    policyRequest: PolicyRequest,
    isOpen: boolean,
    handleClose: () => void,
}

const ContingencyPopup = ({ policyRequest, isOpen, handleClose }: Props) => {
    const contingencies = policyRequest.contingencies;
    const { company } = useSelector<{ company: CompanyState }, CompanyState>(state => state.company);
    const product = policyRequest.product;

    const {apiBaseUrl} = useEnv();
    const [preBindContingencies, numPreBindChecked, postBindContingencies, numPostBindChecked] = useMemo(() => {
        let preBind: Contingency[] = []
        let preBindChecked = 0
        let postBind: Contingency[] = []
        let postBindChecked = 0
        contingencies.forEach((contingency) => {
            if (contingency.is_pre_bind) {
                preBind.push(contingency)
                if (contingency.checked) {
                    preBindChecked += 1;
                }
            } else {
                postBind.push(contingency)
                if (contingency.checked) {
                    postBindChecked += 1;
                }
            }
        });
        return [preBind, preBindChecked, postBind, postBindChecked]
    }, [contingencies])

    const totalPreBindContingencies = preBindContingencies.length
    const totalPostBindContingencies = postBindContingencies.length

    return (
        <Styled.Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
        >
            <Styled.DialogTitle>
                <Box width="100%" display="flex" justifyContent="space-between" fontWeight="fontWeightBold" alignItems="center">
                    <Typography variant="h6">
                        Outstanding Contingencies
                    </Typography>
                    <IconButton onClick={handleClose} data-testid="close-popup">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Styled.DialogTitle>
            <Styled.DialogContent>
                <Box fontWeight="fontWeightLight">
                    <ContingencyTitle variant="subtitle2">Please review the following contingencies in order to process the quote: </ContingencyTitle>
                    {preBindContingencies.length > 0 && <ContingencySection title={`Pre-bind (${numPreBindChecked}/${totalPreBindContingencies})`} contingencies={preBindContingencies}/>}
                    {postBindContingencies.length > 0 && <ContingencySection title={`Post-bind (${numPostBindChecked}/${totalPostBindContingencies})`} contingencies={postBindContingencies}/>}
                </Box>
            </Styled.DialogContent>
            <Styled.DialogActions>
                <RoundedButton onClick={handleClose} color="primary" py={0.15}>
                    <Typography variant="subtitle2">
                        {product.abbreviated_name === 'MPL' ?
                            'Done'
                            :
                            <StyledLink.Anchor
                                href={`${apiBaseUrl}/company/security_score_report/${company?.id}/${company?.security_score_filename}`}
                                target="_blank">
                                View Security Report
                            </StyledLink.Anchor>
                        }
                    </Typography>
                </RoundedButton>
            </Styled.DialogActions>
        </Styled.Dialog>
    );
}


export default ContingencyPopup;
