import React from 'react';
import { useHistory } from 'react-router-dom';
import { DesktopTablet, Mobile } from './components';
import { Hidden } from '@material-ui/core';


const Completed = ({ policyRequest }) => {
    const history = useHistory();
    const preferredOptionToQuote = policyRequest.option_to_quotes.find(optionToQuote => optionToQuote.quote.preferred);
    const redirectToPolicy = () => {
        history.push(`/submission/policy_details/${preferredOptionToQuote.id}`);
    }

    return (
        <>
            <Hidden smDown>
                <DesktopTablet policyRequest={policyRequest} redirectToPolicy={redirectToPolicy} />
            </Hidden>
            <Hidden mdUp>
                <Mobile policyRequest={policyRequest} redirectToPolicy={redirectToPolicy} />
            </Hidden>
        </>
    );
}

export default Completed;
