import React from 'react';
import { useLocation } from 'react-router-dom';
import Styled from './styled-components';
import { StyledLink } from 'components';
import { Typography } from '@material-ui/core';
import { uniqueId } from 'lodash';

const NavLink = ({ endpoint, text, children, hasSubNav, handleMouseOver }) => {
    const location = useLocation();
    const endpointObj = { pathname: endpoint, key: uniqueId() };
    return (
        <Styled.NavItem
            onMouseOver={handleMouseOver ? handleMouseOver : () => {}}
            className={location.pathname === endpoint ? 'active' : ''}>
            <StyledLink.Default to={endpointObj}>
                <Typography variant="subtitle2" component="span">
                    {text ? text : children}
                </Typography>
            </StyledLink.Default>
        </Styled.NavItem>
    );
};

export default NavLink;
