import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import accounting from 'accounting';
import { ReactComponent as AtbayCustomizationIcon } from 'components/icons/atbay-customization.svg'


const LoadedPremium = () => {
    const { customizedOptionToQuote } = useSelector(state => state.company);

    return (
        <>
            <AtbayCustomizationIcon />
            <Box fontWeight={700} mt={1} mb={0.2}>
                <Typography component={Box} variant="h6">
                    Total
                </Typography>
            </Box>
            {customizedOptionToQuote ?
                <Box color="#5489FF" fontWeight="700">
                    <Typography variant="h1">
                        ${accounting.formatNumber(customizedOptionToQuote.quote.total_premium)}
                    </Typography>
                </Box>
                : <Box></Box>}
        </>
    )
}


export default LoadedPremium;