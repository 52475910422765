import React from 'react';
import { useParams } from 'react-router-dom';
import { IFrame } from 'components';
import { useEnv } from 'hooks';

const CompanyCalculator = () => {
    const { companyId } = useParams();
    const { apiBaseUrl } = useEnv();
    const url = `${apiBaseUrl}/company/calculator/${companyId}`;

    return <IFrame src={url} />;
};

export default CompanyCalculator;
