import React, { useEffect, useState, useRef, useMemo } from 'react';
import Markdown from 'markdown-to-jsx';
import generalStyled from '../../../components/styled-components';
import { Box, FormControl, InputAdornment } from '@material-ui/core';
import InfoTooltip from '../../../components/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setQuestionValue } from 'stores/questions/questionsSlice';
import { setNewQuestionData } from 'stores/questionsErrors/questionsErrorsSlice';
import { questionHasError, scrollToQuestionError } from 'utils/questionnaireSequence';
import { useBreakpoint, useTextQuestionStyles, useAxiosClient } from 'hooks';
import { setQuestionnaireStateToAnswerChanging, setQuestionnaireStateToChangesSaved } from 'stores/questionnaire/questionnaireSlice';
import Styled from 'modules/questionnaire/pages/new-questionnaire/components/questions/styled-components';


const CompanyNameQuestion = ({
    question,
    updateForm,
    policy_request_id,
    setScroll,
    scroll,
    setEditAnswerMode,
    setChangeMade,
}) => {
    const axiosClient = useAxiosClient();
    const questionRef = useRef();
    const inputFieldRef = useRef();
    const placeholderLabelRef = useRef();
    const isSm = useBreakpoint('down', 'sm');
    const dispatch = useDispatch();
    const answer = useSelector(state => state.questions?.questionsToAnswers[question.id]);
    const questionMetaData = useSelector(
        state => state.questionnaire.questionsMetadata.find(item => item.questionId === question.id)?.metadata,
    );
    const { firstQuestionWithError, submitFailed } = useSelector(state => state.questionErrors);
    const [value, setValue] = useState(answer || '');
    const [focus, setFocus] = useState(Boolean(value));
    const placeholder = useMemo(() => {
        return value || focus ? '' : question.question;
    }, [value, focus, question.question]);
    const [firstChange, setFirstChange] = useState(true);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [inputStyles, inputComponentStyles] = useTextQuestionStyles(placeholder, placeholderLabelRef, inputFieldRef);
    const [requiredError, setRequiredError] = useState(questionHasError(submitFailed, answer, question.required, true));


    useEffect(() => {
        dispatch(
            setNewQuestionData({
                questionId: question.id,
                hasError: questionHasError(submitFailed, answer, question.required, true),
            }),
        );
    }, [submitFailed, error]);

    useEffect(() => {
        if (scroll && firstQuestionWithError === question.id) {
            scrollToQuestionError(questionRef);
            inputFieldRef.current.focus();
            setScroll(false);
        }
        if (!scroll) {
            setRequiredError(questionHasError(submitFailed, value, question.required, true));
        }
    }, [firstQuestionWithError, scroll]);

    useEffect(() => {
        dispatch(
            setNewQuestionData({
                questionId: question.id,
                hasError: questionHasError(submitFailed, answer, question.required, true),
            }),
        );
    }, []);

    const [, validateCompanyName] = axiosClient(
        {
            method: 'post',
            url: `/questionnaire/validate_company_name/${policy_request_id}`,
        },
        { manual: true },
    );

    const handleOnBlur = async () => {
        const hasError = questionHasError(submitFailed, value, question.required, true);
        setFocus(false);
        setRequiredError(hasError);
        dispatch(setNewQuestionData({ questionId: question.id, hasError: hasError }));
        dispatch(setQuestionnaireStateToChangesSaved());
        setFirstChange(true);
        dispatch(setQuestionValue({ name: question.id, value: value }));
        try {
            await validateCompanyName({ data: { name: value } });
            setError('');
            updateForm({
                questionId: question.id,
                answerId: question.optional_answers[0].id,
                answerType: question.optional_answers[0].type,
                value: value,
                invalid: false,
                required: question.required,
                questionType: question.type,
            });
        } catch (error) {
            updateForm({
                questionId: question.id,
                answerId: question.optional_answers[0].id,
                answerType: question.optional_answers[0].type,
                value: value,
                invalid: true,
                required: question.required,
                questionType: question.type,
            });
            const invalidCompanyName = error.response?.data?.invalid_company_name;
            let errorText = '';
            if (invalidCompanyName) {
                errorText += 'A similar name is already used by another insured in your Accounts';
            }
            if (errorText) {
                errorText += '\n';
            }
            setError(errorText);
            updateForm({
                questionId: question.id,
                answerId: question.optional_answers[0].id,
                answerType: question.optional_answers[0].type,
                value: null,
                invalid: true,
                required: question.required,
                questionType: question.type,
            });
        }
    };

    return (
        <Box
            ref={questionRef}
            display="flex"
            px={isSm ? 4 : 0}
            key={`${question.id}_question`}
            pb={1.625}
            justifyContent="center">
            <FormControl fullWidth style={{ maxWidth: isSm ? 'unset' : '424px' }}>
                <generalStyled.TextField
                    inputRef={inputFieldRef}
                    fullWidth
                    onChange={e => {
                        setEditAnswerMode(true);
                        setValue(e.target.value);
                        setRequiredError(false);
                        dispatch(setNewQuestionData({ questionId: question.id, hasError: false }));
                        setChangeMade(true);
                        if (firstChange) {
                            setFirstChange(false);
                            dispatch(setQuestionnaireStateToAnswerChanging());
                        }
                    }}
                    value={value}
                    type="text"
                    name={question.name}
                    label={
                        <Markdown variant="body1">
                            {(question.required ? '' : '(Optional) ') + question.question}
                        </Markdown>
                    }
                    error={Boolean(error) || requiredError}
                    helperText={error ? error : requiredError ? 'This field is required' : ''}
                    InputLabelProps={{
                        ref: placeholderLabelRef,
                    }}
                    inputProps={{
                        'data-track': `questionnaire_question_${question.id}`,
                        style: placeholder ? inputStyles.withPlaceholder : inputStyles.withoutPlaceholder,
                    }}
                    InputProps={{
                        style: placeholder
                            ? inputComponentStyles.withPlaceholder
                            : inputComponentStyles.withoutPlaceholder,
                        endAdornment: (
                            <InputAdornment position="end">
                                {question.application_parameters.help_text && (
                                    <InfoTooltip
                                        dataTrack={`questionnaire_tooltip_${question.id}`}
                                        placement="bottom-end"
                                        open={open}
                                        setOpen={setOpen}
                                        text={question.application_parameters.help_text}
                                        select={false}
                                    />
                                )}
                                {questionMetaData?.savingQuestion && !error && <Styled.CircularProgress size={15} />}
                            </InputAdornment>
                        ),
                    }}
                    placeholder={placeholder}
                    onFocus={() => setFocus(true)}
                    onBlur={handleOnBlur}
                />
            </FormControl>
        </Box>
    );
};

export default CompanyNameQuestion;
