import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isPolicyRequestInProgress } from 'utils';
import { SubmissionsBucket } from 'utils/enums';
import { setPolicyRequest } from 'stores/company/companySlice';
import dayjs from 'dayjs';
import PolicyRequestSkeleton from '../policy-request-skeleton';
import PolicyHeader from '../PolicyHeader';
import QuotesAreReady from './components/quotes-are-ready';
import { QuotesAreReadyActions, QuotesAreReadyProductText } from './components/quotes-are-ready/components';
import Completed from './components/completed';
import { CompletedActions, CompletedProductText } from './components/completed/components';
import InProgress from './components/in-progress';
import { InProgressActions, InProgressProductText } from './components/in-progress/components';
import { ClosedActions, ClosedProductText } from './components/closed/components';
import PreProduct from './components/pre-product';
import { PreProductActions, PreProductProductText } from './components/pre-product/components';
import { ReactComponent as ClipboardCheckIcon } from 'components/icons/clipboard-check.svg';
import { ReactComponent as ClipboardIcon } from 'components/icons/clipboard.svg';
import { ReactComponent as ClipboardClosedIcon } from 'components/icons/clipboard-closed.svg';
import { ReactComponent as LoadingIcon } from 'components/icons/loading.svg';
import { ReactComponent as ClockIcon } from 'components/icons/clock.svg';
import { ReactComponent as CautionIcon } from 'components/icons/caution.svg';
import { Box } from '@material-ui/core';
import { BorErrorActions, BorErrorHeader, BorErrorMessage } from './components/borError';
import Closed from './components/closed';


const bucketToData = {
    [SubmissionsBucket.QUOTED]: {
        component: QuotesAreReady,
        getIcon: () => ClipboardCheckIcon,
        productTextComponent: QuotesAreReadyProductText,
        actions: QuotesAreReadyActions,
    },
    [SubmissionsBucket.PRE_QUOTE]: {
        component: InProgress,
        getIcon: policyRequest => {
            return policyRequest.inProgress ? LoadingIcon : ClockIcon;
        },
        productTextComponent: InProgressProductText,
        actions: InProgressActions,
        setMargin: 7,
    },
    [SubmissionsBucket.BOUND]: {
        component: Completed,
        getIcon: () => ClipboardCheckIcon,
        productTextComponent: CompletedProductText,
        actions: CompletedActions,
    },
    [SubmissionsBucket.BIND_REQUESTED]: {
        component: Completed,
        getIcon: () => ClipboardCheckIcon,
        productTextComponent: CompletedProductText,
        actions: CompletedActions,
    },
    [SubmissionsBucket.DECLINED]: {
        component: null,
        getIcon: () => ClipboardClosedIcon,
        productTextComponent: ClosedProductText,
        actions: ClosedActions,
        removeMargin: true,
    },
    [SubmissionsBucket.EXPIRED]: {
        component: Closed,
        getIcon: () => ClipboardClosedIcon,
        productTextComponent: ClosedProductText,
        actions: ClosedActions,
        removeMargin: true,
    },
    [SubmissionsBucket.LOST]: {
        component: null,
        getIcon: () => ClipboardClosedIcon,
        productTextComponent: ClosedProductText,
        actions: ClosedActions,
        removeMargin: true,
    },
    [SubmissionsBucket.CANCELED]: {
        component: Closed,
        getIcon: () => ClipboardIcon,
        productTextComponent: ClosedProductText,
        actions: ClosedActions,
        removeMargin: true,
    },
};

const preProductData = {
    component: PreProduct,
    getIcon: () => ClipboardIcon,
    productTextComponent: PreProductProductText,
    actions: PreProductActions,
};

const borConflictData = {
    component: BorErrorActions,
    getIcon: () => CautionIcon,
    iconColor: '#191919',
    iconBgColor: '#FFEB00',
    productTextComponent: BorErrorHeader,
    actions: BorErrorMessage,
    removeMargin: true,
};

const PolicyRequest = ({ policyRequestData, company, monitorPolicyRequest }) => {
    const dispatch = useDispatch();
    const { viewable_policy_requests } = useSelector(state => state.company.company);
    const policyRequest = viewable_policy_requests.find(policyRequest => policyRequest.id === policyRequestData.id);
    const firstRequestTime = useMemo(() => dayjs.utc().unix(), []);


    const dispatchPolicyRequest = () => {
        const policyRequest = {
            ...policyRequestData,
            inProgress: isPolicyRequestInProgress(policyRequestData, firstRequestTime),
        };
        dispatch(setPolicyRequest(policyRequest));
        if (policyRequest.inProgress) {
            setTimeout(() => monitorPolicyRequest(policyRequest.id, firstRequestTime), 2000);
        }
    };


    useEffect(() => {
        dispatchPolicyRequest();
    }, []);

    useEffect(() => {
        if (policyRequest.forceReload) {
            dispatchPolicyRequest();
        }
    }, [policyRequest.forceReload]);

    if (!policyRequest?.athena_submissions_bucket) {
        return <PolicyRequestSkeleton />;
    }

    const data = policyRequest.product
        ? policyRequest.blocked_by_bor
            ? borConflictData
            : bucketToData[policyRequest.athena_submissions_bucket]
        : preProductData;
    const Component = data.component;

    return (
        <Box p={[0, 4, 7, 10]} bgcolor="white" mb={3}>
            <PolicyHeader
                removeMargin={data.removeMargin}
                policyRequest={policyRequest}
                company={company}
                getIcon={data.getIcon}
                iconColor={data.iconColor}
                iconBgColor={data.iconBgColor}
                ProductTextComponent={data.productTextComponent}
                Actions={data.actions}
                setMargin={data.setMargin}
            />
            {Component && <Component policyRequest={policyRequest} company={company} />}
        </Box>
    );
};

export default PolicyRequest;
