import React from 'react';
import { ProductsAbbreviatedName } from 'utils/enums';


const ProductText = ({ policyRequest }) => {
    return (
        <>
            {policyRequest.product.abbreviated_name === ProductsAbbreviatedName.MPL
                ? 'Quote calculation in progress'
                : 'Security scan in progress'
            }
        </>
    )
}

export default ProductText;
