import React from 'react';
import { useEnv } from 'hooks';
import EditApplication from './components/EditApplication';
import NextSteps from './index';

const PendingNextSteps = ({ policyRequest }) => {
    const { editApplication } = useEnv();
    return <NextSteps>{editApplication && <EditApplication policyRequest={policyRequest} />}</NextSteps>;
};

export default PendingNextSteps;
