import React from 'react';
import { useHistory } from 'react-router-dom';
import { useEnv } from 'hooks';
import { formatPolicyNumber, totalHumanize } from 'utils';
import Styled from './styled-components';
import {
    RoundedButton,
    StyledTypography,
    StyledAction,
    Icon,
    IconBox,
} from 'components';
import { ReactComponent as ClipboardCheckIcon } from 'components/icons/clipboard-check.svg'
import CloseIcon from '@material-ui/icons/Close';
import {
    Box,
    IconButton,
    Typography,
} from '@material-ui/core';


const BoundPopup = ({ optionToQuote, open, handleClose, data }) => {
    const history = useHistory();
    const { mode } = useEnv();
    const { quote } = optionToQuote;
    const { policy_request: policyRequest } = quote;

    const navigateToDashboard = () => {
        handleClose();
        history.push('');
    }

    const navigateToPolicyDetails = () => {
        handleClose();
        history.push(`/submission/policy_details/${optionToQuote.id}`);
    }

    if (!data) {
        return null;
    }

    return (
        <Styled.Dialog
            open={open}
            onClose={navigateToPolicyDetails}
            fullWidth
        >
            <Styled.DialogTitle>
                <Box width="100%" display="flex" justifyContent="space-between" fontWeight="fontWeightBold" alignItems="flex-start">
                    <Box>
                        <Typography variant="h6">
                            {data.conditionally_bound ? 'Conditional Binder' : 'Policy Bound'}
                        </Typography>
                        <StyledTypography.FadedTypography variant="h6">
                            ${totalHumanize(quote.aggregate_limit)} {policyRequest.product.name}
                        </StyledTypography.FadedTypography>
                    </Box>
                    <IconButton onClick={navigateToDashboard} data-testid="close-popup">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Styled.DialogTitle>
            <Styled.DialogContent>
                <Box px={[0, 0, 4]} my={[0, 0, 2]} mb={[5, 5, 10]}>
                    <IconBox
                        sizes={['48px', '48px', '56px']}
                        bgcolor={policyRequest.product.background_color}
                        color={policyRequest.product.text_color}
                        borderRadius="50%"
                        mr={2}>
                        <Icon
                            icon={<ClipboardCheckIcon />}
                            typographyProps={{ variant: 'h5' }}
                        />
                    </IconBox>
                    <Box borderBottom={`4px solid ${policyRequest.product.background_color}`} my={2} />
                    <Box fontWeight="fontWeightBold">
                        <Typography variant="h6">
                            {policyRequest.company_name}
                        </Typography>
                    </Box>
                    <Box mb={1} fontWeight="fontWeightLight">
                        <StyledTypography.FadedTypography variant="body1">
                            #{formatPolicyNumber(mode, data.hsb_policy_number)}
                        </StyledTypography.FadedTypography>
                    </Box>
                    <Box component={Typography} variant="body1" fontWeight="fontWeightLight">
                        {data.conditionally_bound ? (
                            'This conditional binder will expire in 30 days. Please review binder details to complete the outstanding contingency requirements.'
                        ) : (
                            'Thanks for the bind order! Go to the Policy page to view details of the At-Bay cyber policy, binder, and agency billing statement.'
                        )}
                    </Box>
                </Box>
            </Styled.DialogContent>
            <Box component={Styled.DialogActions} display="flex" justifyContent={['space-between', 'space-between', 'flex-end']}>
                <Box component={StyledAction.Base} onClick={navigateToDashboard} mr={2} fontWeight="fontWeightBold">
                    <Typography color="primary" variant="subtitle2">
                        Dashboard
                    </Typography>
                </Box>
                <RoundedButton onClick={navigateToPolicyDetails} color="primary" py={0.3}>
                    <Typography variant="subtitle2">
                        {data.conditionally_bound ? 'Conditional Binder' : 'Policy'}
                    </Typography>
                </RoundedButton>
            </Box>
        </Styled.Dialog>
    );
}


export default BoundPopup;
