import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useBreakpoint } from 'hooks';
import { StyledAction, StyledTypography, StyledTable } from 'components/index';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Box, Hidden, TableRow } from '@material-ui/core';
import { RootState } from '../../../stores/rootReducer';
import { PolicyRequest } from 'types/PolicyRequest';
import { OptionToQuote } from 'types/optionToQuote';
import FullTableDesktop from './desktop/FullTableDesktop';
import FullTableMobile from './mobile/FullTableMobile';

interface Props {
    policyRequest: PolicyRequest;
    readOnly: boolean;
}

const CommissionAndPolicyTotal: FC<Props> = ({ readOnly, policyRequest }) => {
    const { quote } = useSelector((state: RootState) => state.company.optionToQuote as OptionToQuote);
    const isSm = useBreakpoint('down', 'sm');
    const isMd = useBreakpoint('down', 'md');
    const [isToggled, setIsToggled] = useState(false);
    const commissionPercentageString = `${Number(policyRequest.commission * 100).toLocaleString()}%`;

    return (
        <>
            <TableRow>
                <StyledTable.TableCell padding="none" colSpan={2}>
                    <Box fontWeight="fontWeightLight" component="span">
                        <Box
                            display="flex"
                            alignItems="center"
                            flexDirection={isSm ? 'row-reverse' : 'row'}
                            justifyContent={['space-between', 'space-between', 'inherit']}
                            width={isSm ? '100%' : 'fit-content'}
                            pb={isToggled ? 0 : 2}
                            pl={isMd ? 0 : 2}>
                            <Box
                                component={StyledAction.ExpandableRowControl}
                                display="flex"
                                alignItems="center"
                                flexDirection={isSm ? 'row-reverse' : 'row'}
                                justifyContent="space-between"
                                onClick={() => setIsToggled(!isToggled)}>
                                <StyledTypography.FadedTypography variant="subtitle1">
                                    <Box mr={[0, 0, 2.5, 2.5]} display="flex">
                                        {isSm ? (
                                            <KeyboardArrowRightIcon />
                                        ) : isToggled ? (
                                            <ExpandLessIcon />
                                        ) : (
                                            <ExpandMoreIcon />
                                        )}
                                    </Box>
                                </StyledTypography.FadedTypography>
                                <StyledTypography.FadedTypography variant="subtitle2" align="justify">
                                    {isToggled ? 'Hide' : readOnly ? 'View' : 'Review'}
                                    {/*
                                        This is annoyingly complicated but here are the rules:
                                        1. In anything other than mobile we'll always show "commission"
                                        2. If we're read only, we also don't show the "customize" button, so we need the text to make sense and not be just "view/review", which is why we include the word "coverages"
                                    */}
                                    {(!isSm || readOnly) && ' policy total & commission'}
                                </StyledTypography.FadedTypography>
                            </Box>
                            {!readOnly && (
                                <>
                                    <Hidden mdUp>
                                        <StyledTypography.FadedTypography variant="subtitle2" className="hoverable">
                                            Commission
                                        </StyledTypography.FadedTypography>
                                    </Hidden>
                                </>
                            )}
                        </Box>
                    </Box>
                </StyledTable.TableCell>
            </TableRow>
            {isToggled && (
                <Hidden smDown>
                    <FullTableDesktop quote={quote} commissionPercentageString={commissionPercentageString} />
                </Hidden>
            )}
            <Hidden mdUp>
                {/*TODO: Remove ts-ignore after PolicyDialog refactored to Typescript*/}
                {/*//@ts-ignore*/}
                <FullTableMobile
                    isToggled={isToggled}
                    setIsToggled={setIsToggled}
                    quote={quote}
                    policyRequest={policyRequest}
                    commissionPercentageString={commissionPercentageString}
                />
            </Hidden>
        </>
    );
};

export default CommissionAndPolicyTotal;
