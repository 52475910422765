import React, { useState } from 'react';
import Styled from './styled-components';
import { Box } from '@material-ui/core';
import DropdownItem from 'components/dropdown/DropdownItem';
import { ReactComponent as ThreeDotsGrey } from 'components/icons/three-dots-grey.svg';
import { ReactComponent as ThreeDotsBlue } from 'components/icons/three-dots-blue.svg';


const ToolsButton = ({ dropdownItems, handleSelectDropdownItem }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [toolsActive, setToolsActive] = useState(false);

    const handleToolsToggle = event => {
        setAnchorEl(event.currentTarget);
        setToolsActive(!toolsActive);
    }

    return (
        <Box alignSelf="center" style={{ cursor: 'pointer' }} onClick={handleToolsToggle}>
            {toolsActive ?
                <Box position="relative">
                    <ThreeDotsBlue />
                    <Styled.Popover
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={toolsActive}
                    >
                        {dropdownItems.map((item, ind) => {
                            return (
                                <DropdownItem
                                    key={`item.id-${ind}`}
                                    href={item.href}
                                    text={item.text}
                                    onClickHandler={() => handleSelectDropdownItem(item)}
                                />
                            )
                        })}
                    </Styled.Popover>
                </Box>
                :
                <ThreeDotsGrey />
            }
        </Box>
    );
};

export default ToolsButton;
