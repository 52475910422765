import React from 'react';
import { Box, TableBody, Table } from '@material-ui/core';
import NextSteps from 'components/policy/next-steps/BoundNextSteps';


const Mobile = ({ policyRequest }) => {
    return (
        <Box component={props => <Table padding="none" {...props} />} fontWeight="fontWeightLight">
            <TableBody>
                <NextSteps policyRequest={policyRequest} />
            </TableBody>
        </Box>
    );
}


export default Mobile;
